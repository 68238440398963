// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, onMessage, getToken } from "firebase/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBbTwJweacm5C_xuHmZmBeDueGdQit02Gw",
  authDomain: "extsy-firebase.firebaseapp.com",
  projectId: "extsy-firebase",
  storageBucket: "extsy-firebase.appspot.com",
  messagingSenderId: "825210999433",
  appId: "1:825210999433:web:2a497a43b11d41eb7e2c58",
  measurementId: "G-25KK35NBYB",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

const messaging = getMessaging();

export const requestForToken = () => {
  return new Promise((resolve, reject) => {
    getToken(messaging, {
      vapidKey:
        "BJdrjJOjypa6BZ7gE755biOyzs2V-nzXl4CxCJ8uUBozEFUuH3BXzlMDjxPR0oNgBVL17VZ_boeKGXTczrKdNKA",
    })
      .then((currentToken) => {
        if (currentToken) {
          console.log("Current token for client:", currentToken);
          resolve(currentToken);
        } else {
          console.log(
            "No registration token available. Request permission to generate one."
          );
          reject(new Error("No registration token available"));
        }
      })
      .catch((err) => {
        console.log("An error occurred while retrieving token.", err);
        reject(err);
      });
  });
};

export const onMessageListener = () => {
  return new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("Payload", payload);
      resolve(payload);
    });
  });
};
