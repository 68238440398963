export const NOTIFICATION_TYPES: any = {
  TRX_SUCCESS: "Transaction Successful!",
  SUBSCRIPTION: "Subscription",
  WITHDRAW: "Withdraw",
  DEPOSIT: "Deposit",
  SWAP_CRYPTO: "Swap Crypto",
  RAFFLE: "Raffle of the month",
  WELCOME: "Welcome, Partner!",
  CARD_ACTIVATION: "Card Activated!",
  PASSWORD_CHANGE: "Password Changed!",
  KYC_APPROVED: "KYC Approved",
  IN_TOP_3: "You're in the Top 3!",
  PAYOUT_PROCESSED: "Payout Processed!",
  ORDER_CONFIRMED: "Order Confirmed!",
};
