import {
  Box,
  Flex,
  Image,
  Input,
  SkeletonCircle,
  SkeletonText,
  Spinner,
  Tooltip,
  useMediaQuery,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import CustomText from "../text/CustomText";
import { useTranslation } from "react-i18next";
import SwapAmountInput from "../form/SwapAmountInput";
import AmountRaising from "../../assets/images/amount-rising.svg";
import AmountDecrease from "../../assets/images/amount-down.svg";
import {
  IconAlertTriangleFilled,
  IconChevronDown,
  IconCircleCheck,
} from "@tabler/icons-react";
import PrimaryButton from "../button/PrimaryButton";
import UnlockIco from "../../assets/images/unlock-ico.svg";
import InfoIco from "../../assets/images/info-ico.svg";
import SwapIcon from "../../assets/images/swap-icon.svg";
import LockIcon from "../../assets/images/lock-green.svg";
import CryptoCurrencyDropdown from "../form/CryptoCurrencyDropdown";
import CopyClipboard from "../../assets/images/copy-clipboard.svg";
import AgreeCheckbox from "../../assets/images/agree-checkbox.svg";
import {
  useEstimateCashBack,
  useGetCoins,
  useGetSwapPairs,
  useGetSwapQuote,
  useGetSwapQuoteDetails,
  useSwap,
  useSwapFixed,
  useValidateAddress,
} from "../../utils/api/swap.api";
import { formatNumber } from "../../utils/functions/formatNumber";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CryptoAsset } from "../../utils/types/types";
import { Tooltip as ReactTooltip } from "react-tooltip";
import OutsideClickHandler from "../../hooks/CustomOutsideClick";

function SwapCard({ swap }: any) {
  // Hooks for i18n translation and navigation
  const { t } = useTranslation();
  const navigate = useNavigate();

  // Search parameters for the swap page
  const [searchParams] = useSearchParams();
  const fromCoin = searchParams.get("from");
  const toCoin = searchParams.get("to");
  const amount = searchParams.get("amount");
  const fixedQuoteId = searchParams.get("quoteId");

  // Media query for responsive UI
  const [isLessThan1242] = useMediaQuery("(max-width: 1242px)");

  // States for managing swap details and error handling
  const [isFromSwap, setIsFromSwap] = useState(swap || false);
  const [isFixedPrice, setIsFixedPrice] = useState(false);
  const [swapAmount, setSwapAmount] = useState<string>("0.1");
  const [swapReceiever, setSwapReceiever] = useState("");
  const [quoteId, setQuoteId] = useState("");
  const [isAddressValid, setIsAddressValid] = useState<boolean>(false);
  const [coinA, setCoinA] = useState<CryptoAsset | undefined>(undefined);
  const [coinB, setCoinB] = useState<CryptoAsset | undefined>(undefined);
  const [isCurrency1Open, setIsCurrency1Open] = useState(false);
  const [isCurrency2Open, setIsCurrency2Open] = useState(false);
  const [errors, setErrors] = useState({
    swapAmount: "",
    swapReceiever: "",
    swapError: "",
  });

  // API hooks for fetching coin data, swap quotes, and swap transactions
  const { data: coins, isLoading: coinsLoading } = useGetCoins(true);
  const { data: pairCoins, isLoading: pairCoinsLoading } = useGetSwapPairs(
    coinA?.id || "btc"
  );
  const {
    data: quoteDetails,
    isLoading: quoteDetailsLoading,
    refetch,
  } = useGetSwapQuoteDetails(quoteId);
  const swapQuote = useGetSwapQuote();
  const fixedSwap = useSwapFixed();
  const swapNow = useSwap();
  const validateAddress = useValidateAddress();
  const estimateCashback = useEstimateCashBack();

  // function for validating address
  const checkValidateAddress = () => {
    if (!swapReceiever) {
      setIsAddressValid(false);
    }
    if (!coinB || !swapReceiever) {
      return;
    }
    validateAddress
      .mutateAsync({
        coin_id: coinB?.id,
        address: swapReceiever,
      })
      .then((result) => {
        if (result?.result) {
          setErrors({
            ...errors,
            swapReceiever: "",
          });
          setIsAddressValid(true);
        } else {
          setErrors({
            ...errors,
            swapReceiever: "Invalid Address",
          });
          setIsAddressValid(false);
        }
      })
      .catch((err) => {
        setErrors({
          ...errors,
          swapReceiever: err.message,
        });
        setIsAddressValid(false);
      });
  };

  // Function to get a regular swap quote
  const getQuote = () => {
    const payload = {
      from_coin_id: coinA?.id,
      to_coin_id: coinB?.id,
      amount: Number(swapAmount),
      otherPlatforms: true,
      isFixed: false,
    };
    swapQuote?.mutateAsync(payload);
  };

  // check cashback getting received
  const callEstimateCashback = () => {
    const payload = {
      from_coin_id: coinA?.id,
      to_coin_id: coinB?.id,
      amount: Number(swapAmount),
      toAddress: swapReceiever,
      isDummy: false,
    };
    estimateCashback.mutateAsync(payload);
  };

  // Function to get a fixed swap quote
  const getFixedQuote = () => {
    const payload = {
      from_coin_id: coinA?.id,
      to_coin_id: coinB?.id,
      amount: Number(swapAmount),
      otherPlatforms: true,
      isFixed: true,
    };
    swapQuote?.mutateAsync(payload).then((result) => {
      setQuoteId(result?.id);
    });
  };

  // Function to exchange the "from" and "to" currencies
  const exchangeCoins = () => {
    setCoinA(coinB);
    setCoinB(coinA);
  };

  // Function to handle the swap transaction
  const handleSwap = () => {
    if (!swap) {
      if (isFixedPrice) {
        navigate(
          `/swap?from=${coinA?.id}&to=${coinB?.id}&amount=${swapAmount}&quoteId=${quoteId}`
        );
        return;
      } else {
        navigate(
          `/swap?from=${coinA?.id}&to=${coinB?.id}&amount=${swapAmount}`
        );
        return;
      }
    }
    if (!swapAmount) {
      setErrors({
        ...errors,
        swapAmount: "Amount is required",
      });
      return;
    } else if (!swapReceiever) {
      setErrors({
        ...errors,
        swapReceiever: "Recipient Wallet Address is required",
      });
      return;
    } else if (
      swapQuote?.error?.message ||
      errors?.swapReceiever ||
      errors?.swapAmount ||
      !isAddressValid
    ) {
      return;
    }

    // Clear errors before proceeding
    setErrors({
      swapAmount: "",
      swapReceiever: "",
      swapError: "",
    });

    // Determine whether it's a fixed swap or regular swap
    if (isFixedPrice && quoteId) {
      const payload = {
        swapQuoteId: quoteId,
        toAddress: swapReceiever,
        isDummy: false,
      };
      // Execute fixed swap
      fixedSwap
        .mutateAsync(payload)
        .then((result) => {
          window.open(
            `${process.env.REACT_APP_EXTSY_PRE_RELEASE_DOMAIN}/pages/swap/${result?.id}`,
            "_blank"
          );
          setSwapAmount("0.1");
          setSwapReceiever("");
        })
        .catch(handleSwapError);
    } else {
      const payload = {
        from_coin_id: coinA?.id,
        to_coin_id: coinB?.id,
        amount: Number(swapAmount),
        toAddress: swapReceiever,
        isDummy: false,
      };
      // Execute normal swap
      swapNow
        .mutateAsync(payload)
        .then((result: any) => {
          window.open(
            `${process.env.REACT_APP_EXTSY_PRE_RELEASE_DOMAIN}/pages/swap/${result?.id}`,
            "_blank"
          );
          setSwapAmount("0.1");
          setSwapReceiever("");
        })
        .catch(handleSwapError);
    }
  };

  // Function to handle swap errors
  const handleSwapError = (error: any) => {
    setErrors({
      swapAmount: "",
      swapReceiever: "",
      swapError: error.message,
    });
    setTimeout(() => {
      setErrors({
        swapAmount: "",
        swapReceiever: "",
        swapError: "",
      });
    }, 3000);
  };

  // Effect to adjust state based on screen size
  useEffect(() => {
    setIsFromSwap(isLessThan1242 ? false : swap);
  }, [isLessThan1242, swap]);

  // Effect to initialize active currencies if none selected
  useEffect(() => {
    if (coins && !coinA) {
      const btcCoin = coins.find((coin: CryptoAsset) => coin.id === "btc");
      if (btcCoin) {
        setCoinA(btcCoin);
      } else {
        setCoinA(coins[0]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coins]);

  //Effect to fetch pairs based on active currency1
  useEffect(() => {
    if (
      pairCoins &&
      !pairCoins.some((coin: CryptoAsset) => coin.id === coinB?.id)
    ) {
      const ethCoin = pairCoins.find((coin: CryptoAsset) => coin.id === "eth");
      setCoinB(ethCoin || pairCoins[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pairCoins]);

  // Effect to get a quote whenever active currencies or swap amount change
  useEffect(() => {
    if (coinA && coinB) {
      getQuote();
    }
    if (coinA && coinB && swapAmount) {
      callEstimateCashback();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coinA, coinB, swapAmount]);

  // Effect to set active currencies and amount based on query params
  useEffect(() => {
    if (fromCoin && toCoin) {
      setCoinA(coins?.find((coin: CryptoAsset) => coin.id === fromCoin));
      setCoinB(coins?.find((coin: CryptoAsset) => coin.id === toCoin));
    }
    if (amount) {
      setSwapAmount(amount);
    }
    if (fixedQuoteId) {
      setIsFixedPrice(true);
      setQuoteId(fixedQuoteId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromCoin, toCoin, coins, amount, fixedQuoteId]);

  // Effect for address validation
  useEffect(() => {
    const debounceInterval = setTimeout(() => {
      checkValidateAddress();
    }, 1000);
    return () => {
      clearTimeout(debounceInterval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [swapReceiever, coinB]);

  useEffect(() => {
    if (isFixedPrice) {
      setIsFixedPrice(false);
      setQuoteId("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coinA, coinB]);

  useEffect(() => {
    if (isFixedPrice) {
      getFixedQuote();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [swapAmount]);

  useEffect(() => {
    if (isFixedPrice && quoteId) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quoteId]);

  return (
    <Box position="relative">
      <Flex flexDir={"column"} gap="10px">
        {/* Amount swapping */}
        <Box
          p={{ base: "16px", lg: "30px 27px 35px 27px" }}
          borderRadius={{ base: "5px", lg: "5px" }}
          border="0.857px solid"
          borderColor={
            errors?.swapAmount || swapQuote?.error?.message
              ? "#ffd85c80"
              : "#585858"
          }
          backdropBlur="blur(5.39px)"
          pos="relative"
          width={isFromSwap ? "100%" : "auto"}
        >
          {/* Error Handling */}
          <Flex
            display={
              errors?.swapAmount || swapQuote?.error?.message ? "flex" : "none"
            }
            gap="2px"
            align="center"
            borderRadius={{ base: "4px", md: "8px" }}
            h={{ base: "18px", md: "24px" }}
            bg="#b39225"
            px="12px"
            pos="absolute"
            top="0"
            mt="-13px"
          >
            <IconAlertTriangleFilled size="12px" color="#fff" />
            <CustomText
              text={swapQuote?.error?.message || errors?.swapError}
              size={{ base: "10px", md: "12px" }}
              color="extsy.white"
              wrap="nowrap"
            />
          </Flex>
          <Flex
            align="center"
            justify="space-between"
            gap="16px"
            pos="relative"
          >
            <Flex flexDir="column" gap="9px" maxW="164px">
              {coinsLoading ? (
                <SkeletonText w="120px" h="10px" mt="30px" noOfLines={1} />
              ) : (
                <SwapAmountInput
                  fontSize={{ base: "14px", lg: "27px" }}
                  value={swapAmount}
                  onChange={(e: any) => {
                    const value = e.target.value;
                    // Allow only empty string, numbers, or valid decimal values
                    if (/^\d*\.?\d*$/.test(value)) {
                      setSwapAmount(value);
                    }
                  }}
                  color={"extsy.white"}
                  borderColor={"extsy.white"}
                />
              )}
              <Flex align="center" gap="7px">
                {coinsLoading ? (
                  <SkeletonText w="120px" h="10px" mt="11px" noOfLines={1} />
                ) : (
                  <>
                    <Image
                      src={
                        coinA?.price_change ?? 0 < 0
                          ? AmountDecrease
                          : AmountRaising
                      }
                      alt="up"
                    />
                    <CustomText
                      text={` ${formatNumber(
                        coinA?.price ?? 0,
                        6
                      )} (${formatNumber(coinA?.price_change ?? 0 ?? 0, 2)}%)`}
                      size={{ base: "12px", lg: "15.4px" }}
                      family="Metropolis"
                      color="extsy.white"
                    />
                  </>
                )}
              </Flex>
            </Flex>
            <Flex
              align="center"
              justify="end"
              gap="10px"
              minW={{ base: "140px", lg: "160px" }}
            >
              <PayingCrypto
                onClick={() => {
                  setIsCurrency1Open(!isCurrency1Open);
                }}
                activeCurrency={coinA}
                isLoading={coinsLoading}
              />
            </Flex>
          </Flex>
          <OutsideClickHandler
            isOpen={isCurrency1Open}
            onClose={() => {
              setIsCurrency1Open(false);
              setIsCurrency2Open(false);
            }}
          >
            <CryptoCurrencyDropdown
              activeCurrency={coinA}
              onClick={(currency: any) => {
                setCoinA(currency);
                setIsCurrency1Open(false);
                setIsCurrency2Open(false);
              }}
              coins={coins}
              isLoading={coinsLoading}
            />
          </OutsideClickHandler>
        </Box>
        <Image
          src={SwapIcon}
          alt="swap"
          cursor="pointer"
          mx="auto"
          mt={{ base: "-30px", lg: isFromSwap ? "-30px" : "-35px" }}
          zIndex="1000"
          onClick={() => {
            setIsCurrency1Open(false);
            setIsCurrency2Open(false);
            exchangeCoins();
          }}
        />
        {/* Amount receiving */}
        <Box
          mt={{ base: "-30px", lg: isFromSwap ? "-28px" : "-35px" }}
          p={{ base: "16px", lg: "30px 27px 35px 27px" }}
          borderRadius={{ base: "5px", lg: "5px" }}
          border="0.857px solid"
          borderColor="#585858"
          backdropBlur="blur(5.39px)"
          pos="relative"
          width={isFromSwap ? "100%" : "auto"}
        >
          <Flex align="center" justify="space-between" gap="16px">
            <Flex flexDir="column" gap="9px" maxW="164px">
              {pairCoinsLoading ? (
                <SkeletonText w="120px" h="10px" mt="30px" noOfLines={1} />
              ) : (
                <SwapAmountInput
                  fontSize={{ base: "14px", lg: "27px" }}
                  value={
                    swapAmount
                      ? isFixedPrice && quoteId
                        ? quoteDetails?.swapQuote?.to_amount
                        : swapQuote?.data?.to_amount_estimated || 0
                      : 0
                  }
                  readOnly={true}
                  color={isFixedPrice ? "#2DC24E" : "extsy.white"}
                  borderColor={isFixedPrice ? "#2DC24E" : "extsy.neutral400"}
                  isLoading={swapQuote?.isPending || quoteDetailsLoading}
                />
              )}
              <Flex align="center" gap="7px">
                {pairCoinsLoading ? (
                  <SkeletonText w="120px" h="10px" mt="11px" noOfLines={1} />
                ) : (
                  <>
                    <Image
                      src={
                        coinB?.price_change ?? 0 < 0
                          ? AmountDecrease
                          : AmountRaising
                      }
                      alt="up"
                    />
                    <CustomText
                      text={`${formatNumber(
                        coinB?.price ?? 0,
                        6
                      )} (${formatNumber(coinB?.price_change ?? 0 ?? 0, 2)}%)`}
                      size={{ base: "12px", lg: "15.4px" }}
                      family="Metropolis"
                      color="extsy.white"
                    />
                  </>
                )}
              </Flex>
            </Flex>
            <Flex
              align="center"
              gap="10px"
              minW={{ base: "140px", lg: "160px" }}
              justify="end"
            >
              <Image
                src={isFixedPrice ? LockIcon : UnlockIco}
                alt="unlock"
                pr="8px"
                height="30px"
                width="30px"
                borderRight="1px solid"
                borderColor="extsy.text"
                cursor="pointer"
                onClick={() => {
                  if (!isFixedPrice) {
                    getFixedQuote();
                  }
                  setIsFixedPrice(!isFixedPrice);
                }}
              />
              <ReceivingCrypto
                onClick={() => {
                  setIsCurrency2Open(!isCurrency2Open);
                }}
                activeCurrency={coinB}
                isLoading={pairCoinsLoading}
              />
            </Flex>
          </Flex>
          <OutsideClickHandler
            isOpen={isCurrency2Open}
            onClose={() => {
              setIsCurrency2Open(false);
              setIsCurrency1Open(false);
            }}
          >
            <CryptoCurrencyDropdown
              activeCurrency={coinB}
              onClick={(currency: any) => {
                setCoinB(currency);
                setIsCurrency1Open(false);
                setIsCurrency2Open(false);
              }}
              coins={pairCoins}
              isLoading={pairCoinsLoading}
            />
          </OutsideClickHandler>
        </Box>
      </Flex>
      {swap && (
        <Box pos="relative" mt={isLessThan1242 ? "12px" : "24px"}>
          {/* Error Handling */}
          <Flex
            display={errors?.swapReceiever ? "flex" : "none"}
            gap="2px"
            align="center"
            borderRadius={{ base: "4px", md: "8px" }}
            h={{ base: "18px", md: "24px" }}
            bg="#b39225"
            px="12px"
            pos="absolute"
            top="0"
            mt="-13px"
            ml="28px"
          >
            <IconAlertTriangleFilled size="12px" color="#fff" />
            <CustomText
              text={errors?.swapReceiever}
              size={{ base: "10px", md: "12px" }}
              color="extsy.white"
              wrap="nowrap"
            />
          </Flex>
          <Input
            p="24px 56px 24px 24px"
            height="70px"
            type="text"
            borderRadius="5px"
            border="1px solid"
            borderColor={
              errors?.swapReceiever
                ? "#ffd85c80"
                : isAddressValid
                ? "#22BB33"
                : "#585858"
            }
            placeholder={t("receipientWalletAddress")}
            fontSize="18px"
            fontWeight="400"
            fontFamily="Metropolis"
            color="extsy.white"
            value={swapReceiever}
            onChange={(e) => {
              if (errors?.swapReceiever) {
                setErrors({
                  ...errors,
                  swapReceiever: "",
                });
              }
              setSwapReceiever(e.target.value);
            }}
            _hover={{
              borderColor: errors?.swapReceiever
                ? "#ffd85c80"
                : isAddressValid
                ? "#22BB33"
                : "#585858",
            }}
            _focus={{
              borderColor: errors?.swapReceiever
                ? "#ffd85c80"
                : isAddressValid
                ? "#22BB33"
                : "#585858",
            }}
            _focusVisible={{
              borderColor: errors?.swapReceiever
                ? "#ffd85c80"
                : isAddressValid
                ? "#22BB33"
                : "#585858",
            }}
          />
          <Box pos="absolute" right="0" top="0" mr="24px" mt="24px">
            {validateAddress?.isPending && swapReceiever ? (
              <Spinner size="sm" color="#fff" />
            ) : isAddressValid ? (
              <IconCircleCheck size={"24px"} color="#22BB33" />
            ) : (
              <Image
                src={CopyClipboard}
                alt="copy"
                cursor="pointer"
                onClick={() => {
                  navigator.clipboard.readText().then((text) => {
                    setSwapReceiever(text);
                  });
                }}
              />
            )}
          </Box>
        </Box>
      )}
      <Flex
        flexDir={isFromSwap ? { base: "column", md: "row" } : "column"}
        align={isFromSwap ? { base: "left", md: "center" } : "left"}
        mb="20px"
        mt={isFromSwap ? "24px" : "8px"}
        gap="10px"
        px={isFromSwap ? { base: "43px", md: "0px" } : { lg: "43px" }}
      >
        <Flex align="center" gap="10px" justify="space-between">
          <Tooltip
            hasArrow
            label="Network fees and all exchange charges are built into the rate. No hidden costs, guaranteed."
            bg="extsy.bg"
            fontSize="12px"
            maxW="160px"
            p="6px"
          >
            <Flex align="center" gap="6px">
              <CustomText
                text={t("noHiddenFee")}
                size="10px"
                family="Metropolis"
                color="extsy.white"
                underline
                cursor="pointer"
              />
              <Image src={InfoIco} alt="info" />
            </Flex>
          </Tooltip>

          <Flex align="center" gap="6px" id="compare-prices">
            <CustomText
              text={t("comparePrices")}
              size="10px"
              family="Metropolis"
              color="extsy.white"
              underline
              cursor="pointer"
            />
            <Image src={InfoIco} alt="info" />
          </Flex>
        </Flex>
        <Flex align="center" gap="10px" justify="space-between">
          <Flex align="center" gap="6px">
            <Tooltip
              hasArrow
              label="This is an estimated rate. EXTSY will secure the best available rate for your exchange."
              bg="extsy.bg"
              fontSize="12px"
              maxW="160px"
              p="6px"
            >
              <Flex align="center" gap="6px">
                <CustomText
                  text={t("estimatedRate")}
                  size="10px"
                  family="Metropolis"
                  color="extsy.white"
                  underline
                  cursor="pointer"
                />
                <Image src={InfoIco} alt="info" />
              </Flex>
            </Tooltip>
            <CustomText
              text={`1 ${coinA?.symbol_short} ~ 
              ${formatNumber(swapQuote?.data?.rate_estimated, 6)}
              ${coinB?.symbol_short}`}
              size="10px"
              family="Metropolis"
              color="extsy.white"
            />
          </Flex>
          <Tooltip
            hasArrow
            label={` You will get ${formatNumber(
              estimateCashback?.data?.amountInUsd,
              6
            )} USD
          cashback of your swap to your wallet.`}
            bg="extsy.bg"
            fontSize="12px"
            maxW="160px"
            p="6px"
          >
            <Flex align="center" gap="6px">
              <CustomText
                text={t("cashback")}
                size="10px"
                family="Metropolis"
                color="extsy.white"
                underline
                cursor="pointer"
              />
              <Image src={InfoIco} alt="info" />
            </Flex>
          </Tooltip>
        </Flex>
      </Flex>
      {swap && (
        <Flex my={isLessThan1242 ? "12px" : "17px"} gap="8px" align="center">
          <Image src={AgreeCheckbox} alt="agree" />
          <CustomText
            text={t("readAgreeTerms")}
            family="Metropolis"
            size="12px"
            lh="16px"
            color="extsy.white"
          />
        </Flex>
      )}

      <PrimaryButton
        title={t("swap")}
        width="100%"
        height="47px"
        isLoading={swapNow.isPending || fixedSwap.isPending}
        onClick={handleSwap}
      />
      <ReactTooltip
        anchorSelect="#compare-prices"
        className="custom-prices-tooltip"
        clickable
      >
        <strong>Compare Prices</strong>
        <p className="my-2">
          <strong>EXTSY:</strong> {swapAmount} {coinA?.symbol_short} ~{" "}
          {formatNumber(swapQuote?.data?.to_amount_estimated, 6)}{" "}
          {coinB?.symbol_short}
          <br />
          <strong>ChangeNow:</strong> {swapAmount} {coinA?.symbol_short} ~{" "}
          {formatNumber(swapQuote?.data?.to_amount_changenow, 6)}{" "}
          {coinB?.symbol_short}
        </p>
      </ReactTooltip>
    </Box>
  );
}

const PayingCrypto = ({ onClick, activeCurrency, isLoading }: any) => {
  return (
    <Flex gap="10px" align="center" onClick={onClick}>
      <Flex flexDir="column" gap="2px" align="flex-end" cursor="pointer">
        <Flex align="center" gap="8px">
          <IconChevronDown color="#fff" size="16px" />
          {isLoading ? (
            <SkeletonText w="60px" h="10px" my="5px" noOfLines={1} />
          ) : (
            <CustomText
              text={activeCurrency?.symbol_short}
              size={{ base: "14px", lg: "20px" }}
              weight="700"
              family="Metropolis"
              color="extsy.white"
              cursor="pointer"
            />
          )}
        </Flex>
        {isLoading ? (
          <SkeletonText w="60px" h="5px" my="5px" noOfLines={1} />
        ) : (
          <CustomText
            text={activeCurrency?.name_short}
            size={{ base: "12px", lg: "15px" }}
            color="extsy.white"
            family="Metropolis"
            cursor="pointer"
          />
        )}
      </Flex>
      {isLoading ? (
        <SkeletonCircle size={{ base: "40px", lg: "48px" }} />
      ) : (
        <Image
          src={activeCurrency?.logo_url}
          alt="btc"
          cursor="pointer"
          height={{ base: "40px", lg: "48px" }}
          width={{ base: "40px", lg: "48px" }}
        />
      )}
    </Flex>
  );
};

const ReceivingCrypto = ({ onClick, activeCurrency, isLoading }: any) => {
  return (
    <Flex align="center" gap="10px" cursor="pointer" onClick={onClick}>
      <Flex flexDir="column" gap="2px" align="flex-end">
        <Flex align="center" gap="8px">
          <IconChevronDown color="#fff" size="16px" />
          {isLoading ? (
            <SkeletonText w="60px" h="10px" my="5px" noOfLines={1} />
          ) : (
            <CustomText
              text={activeCurrency?.symbol_short}
              size={{ base: "14px", lg: "20px" }}
              weight="700"
              family="Metropolis"
              color="extsy.white"
              cursor="pointer"
            />
          )}
        </Flex>
        {isLoading ? (
          <SkeletonText w="60px" h="5px" my="5px" noOfLines={1} />
        ) : (
          <CustomText
            text={activeCurrency?.name_short}
            size={{ base: "12px", lg: "15px" }}
            color="extsy.white"
            family="Metropolis"
            cursor="pointer"
          />
        )}
      </Flex>
      {isLoading ? (
        <SkeletonCircle size={{ base: "40px", lg: "48px" }} />
      ) : (
        <Image
          src={activeCurrency?.logo_url}
          alt="eth"
          cursor="pointer"
          height={{ base: "40px", lg: "48px" }}
          width={{ base: "40px", lg: "48px" }}
        />
      )}
    </Flex>
  );
};

export default SwapCard;
