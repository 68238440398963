import { Box, Divider, Flex, Skeleton, SkeletonText } from "@chakra-ui/react";
import React from "react";

function PlanCardSkeleton({ plan }: any) {
  return (
    <Flex
      pos="relative"
      px={{ base: "16px", lg: "32px" }}
      py={{ base: "16px", lg: "40px" }}
      borderRadius={{ base: "16px", lg: "24px" }}
      borderTop="4px solid"
      borderColor={plan?.isActive ? "extsy.primary500" : "transparent"}
      bg="extsy.neutral1000"
      //   minW="490.66px"
      //   maxW="490.66px"
      width="100%"
      flexDir="column"
      justify="space-between"
      gap={{ base: "16px", lg: "55px" }}
      overflow={plan?.isPopular ? "hidden" : ""}
    >
      <Box zIndex="1000">
        <Flex align="center" justify="space-between">
          <Skeleton height="40px" width="80px" borderRadius="8px" />
          <Skeleton width="85px" h="28px" borderRadius="8px" />
        </Flex>
        <Flex mt="24px" align="center" justify="space-between">
          <SkeletonText noOfLines={1} width="120px" h="25px" mt="10px" />
          <SkeletonText noOfLines={1} width="120px" h="25px" mt="10px" />
        </Flex>
        <Divider my="16px" borderColor="extsy.neutral900" height="1px" />
        <SkeletonText noOfLines={1} width="150px" />
        <Flex mt="24px" gap="24px" flexDir="column">
          {new Array(5)?.fill(1)?.map((_, idx) => (
            <Flex align="center" gap="16px" key={idx}>
              <Skeleton height="24px" width="24px" borderRadius="6px" />
              <SkeletonText noOfLines={2} width="180px" h="25px" />
            </Flex>
          ))}
        </Flex>
      </Box>
      <Skeleton
        h={{ base: "48px", lg: "56px" }}
        borderRadius="8px"
        w={{ base: "100%", lg: "250px" }}
        mx="auto"
      />
    </Flex>
  );
}

export default PlanCardSkeleton;
