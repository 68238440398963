import {
  Circle,
  Flex,
  Image,
  SkeletonText,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import CustomText from "../text/CustomText";
import PrimaryButton from "../button/PrimaryButton";
import CustomButton from "../button/CustomButton";
import {
  IconArrowDownLeft,
  IconArrowRight,
  IconArrowUpRight,
  IconHexagonFilled,
} from "@tabler/icons-react";
import CustomModal from "../modal/customModal";
import CustomSelect from "../form/CustomSelect";
import VCardInput from "../form/vCardInput";
import IconSwap from "../../assets/images/swap-icon.svg";
import { useGetCoins } from "../../utils/api/swap.api";
import { CoinOption, CryptoAsset } from "../../utils/types/types";
import {
  useDeposit,
  useGetCoinBalance,
  useTransfer,
  useWithdraw,
} from "../../utils/api/trade.api";
import { useForm } from "react-hook-form";
import { formatNumber } from "../../utils/functions/formatNumber";
import { useTranslation } from "react-i18next";
import DashboardSlider from "../dashboard/dashboardSlider";
import { socket } from "../../utils/sockets";
import { useQuery } from "@tanstack/react-query";
import { useCustomAlert } from "../../utils/api/alert";
import ScanQR from "./ScanQR";
import CopyIcon from "../../assets/svgs/CopyIcon";

function TradingEstimateBalanceCard({ activeTab }: any) {
  const { t } = useTranslation();

  const [isLessThan992] = useMediaQuery("(max-width: 992px)");

  const [isSpotFuture, setIsSpotFuture] = useState(true);
  const [depositCoin, setDepositCoin] = useState<CoinOption | undefined>(
    undefined
  );
  const [withdrawCoin, setWithdrawCoin] = useState<CoinOption | undefined>(
    undefined
  );
  const [transferCoin, setTransferCoin] = useState<CoinOption | undefined>(
    undefined
  );
  const [depositResponse, setDepositResponse] = useState<any | undefined>(
    undefined
  );

  const { data: coins, isLoading: coinsLoading } = useGetCoins();
  const {
    data: coinBalance,
    isLoading: coinBalanceLoading,
    refetch: refetchCoinBalance,
  } = useGetCoinBalance(transferCoin?.value);
  const { data: balance, isLoading: balanceLoading }: any = useQuery({
    queryKey: ["getBalance"],
  });
  const depositCryptoCoin = useDeposit();
  const withdrawCrypto = useWithdraw();
  const transferCrypto = useTransfer();
  const alert = useCustomAlert();

  const {
    isOpen: isWithdrawOpen,
    onOpen: onWithdrawOpen,
    onClose: onWithdrawClose,
  } = useDisclosure();
  const {
    isOpen: isTransferOpen,
    onOpen: onTransferOpen,
    onClose: onTransferClose,
  } = useDisclosure();
  const {
    isOpen: isDepositOpen,
    onOpen: onDepositOpen,
    onClose: onDepositClose,
  } = useDisclosure();
  const {
    isOpen: isConfirmationOpen,
    onOpen: onConfirmationOpen,
    onClose: onConfirmationClose,
  } = useDisclosure();

  const {
    register: withdrawRegister,
    handleSubmit: handleWithdrawSubmit,
    reset: withdrawReset,
    formState: { errors: withdrawErrors },
  } = useForm();

  const {
    register: transferRegister,
    handleSubmit: handleTransferSubmit,
    reset: transferReset,
    formState: { errors: transferErrors },
  } = useForm();

  const handleDeposit = () => {
    depositCryptoCoin
      ?.mutateAsync({
        coin_id: depositCoin?.value,
        isDummy: false,
      })
      .then((result) => {
        setDepositResponse({
          ...result?.tadeTransaction,
          status: "Pending",
        });
        socket.emit("tradeDepositStatus", {
          tradeTransactionId: result?.tadeTransaction?.id,
        });
        onDepositClose();
        onConfirmationOpen();
      })
      .catch((error) => {
        alert?.mutateAsync({
          message: error?.message,
          status: "danger",
        });
      });
  };

  const handleWithdraw = (data: any) => {
    withdrawCrypto
      ?.mutateAsync({
        coin_id: withdrawCoin?.value,
        address: data.address,
        amount: Number(data.amount),
        isDummy: false,
      })
      .then((result) => {
        onWithdrawClose();
        withdrawReset();
      })
      .catch((error) => {
        alert?.mutateAsync({
          message: error?.message,
          status: "danger",
        });
      });
  };

  const handleTransfer = (data: any) => {
    transferCrypto
      ?.mutateAsync({
        coin_id: transferCoin?.value,
        amount: Number(data.amount),
        isDummy: false,
        from_account: isSpotFuture ? "Spot" : "Future",
        to_account: isSpotFuture ? "Future" : "Spot",
      })
      .then((result) => {
        onTransferClose();
        transferReset();
      })
      .catch((error) => {
        alert?.mutateAsync({
          message: error?.message,
          status: "danger",
        });
      });
  };

  useEffect(() => {
    refetchCoinBalance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transferCoin]);

  useEffect(() => {
    if (coins && coins.length > 0) {
      if (!depositCoin) {
        setDepositCoin({
          value: coins[0]?.id,
          label: coins[0]?.symbol_short,
          icon: coins[0]?.logo_url,
        });
      }

      if (!withdrawCoin) {
        setWithdrawCoin({
          value: coins[0]?.id,
          label: coins[0]?.symbol_short,
          icon: coins[0]?.logo_url,
        });
      }
      if (!transferCoin) {
        setTransferCoin({
          value: coins[0]?.id,
          label: coins[0]?.symbol_short,
          icon: coins[0]?.logo_url,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coins]);

  useEffect(() => {
    function onSwapStatusUpdate(status: any) {
      console.log("status----------", status);
      if (status?.status?.toLowerCase() !== "pending") {
        setDepositResponse((prevState: any) => ({
          ...prevState,
          status: status?.status,
        }));
      }
    }

    socket.on("tradeDeposit", onSwapStatusUpdate);

    return () => {
      socket.off("tradeDeposit", onSwapStatusUpdate);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log("balance--------", depositResponse);

  return (
    <Flex
      justify="space-between"
      align="center"
      overflow="hidden"
      p={{ base: "16px", lg: "40px" }}
      borderRadius={{ base: "16px", lg: "24px" }}
      bg="extsy.neutral1000"
      pos="relative"
      flexDir={{ base: "column", lg: "row" }}
      gap={{ base: "32px", lg: "16px" }}
    >
      <Flex
        flexDir="column"
        align={{ base: "center", lg: "flex-start" }}
        gap={{ base: "12px", lg: "23px" }}
        zIndex="1000"
        minW="200px"
      >
        <CustomText text="Estimated Balance" color="extsy.baseWhite" />
        <Flex gap="12px" align="flex-end">
          {balanceLoading ? (
            <SkeletonText w="160px" h="16px" mt="40px" noOfLines={1} />
          ) : (
            <>
              <CustomText
                text={
                  activeTab === "spot"
                    ? formatNumber(balance?.totalBalanceSpot, 2)
                    : formatNumber(balance?.totalBalanceFuture, 2)
                }
                size={{ base: "32px", lg: "56px" }}
                family="Metropolis"
                color="extsy.white"
                ls="2.8px"
              />
              <CustomText
                text="USD"
                size="24px"
                color="extsy.neutral500"
                family="Metropolis"
                ls="1.2px"
                lh={{ base: "24px", lg: "34px" }}
              />
            </>
          )}
        </Flex>
        <Flex gap="10px" align="flex-end">
          {balanceLoading ? (
            <SkeletonText w="120px" h="10px" mt="25px" noOfLines={1} />
          ) : (
            <>
              <CustomText
                text="Today’s PnL"
                family="Metropolis"
                color="extsy.baseWhite"
                lh="22px"
              />
              {activeTab === "spot" ? (
                <CustomText
                  text={`${balance?.pnlSpot < 0 ? "-" : "+"}$${formatNumber(
                    balance?.pnlSpot,
                    2
                  )}(${formatNumber(balance?.pnlSpotPercentage, 2)}%)`}
                  color={balance?.pnlSpot < 0 ? "extsy.danger" : "#0ECB81"}
                  family="Metropolis"
                  lh="22px"
                />
              ) : (
                <CustomText
                  text={`${balance?.pnlFuture < 0 ? "-" : "+"}$${formatNumber(
                    balance?.pnlFuture,
                    2
                  )}(${formatNumber(balance?.pnlFuturePercentage, 2)}%)`}
                  color={balance?.pnlFuture < 0 ? "extsy.danger" : "#0ECB81"}
                  family="Metropolis"
                  lh="22px"
                />
              )}
            </>
          )}
        </Flex>
      </Flex>
      <Flex gap="16px" align="center" w="100%" zIndex="1000" justify="flex-end">
        <PrimaryButton
          title="Deposit"
          maxW="193px"
          height={{ base: "38px", lg: "56px" }}
          size={{ base: "12px", lg: "16px" }}
          onClick={onDepositOpen}
          icon={
            <IconArrowDownLeft
              size={isLessThan992 ? "18px" : "24px"}
              color="#0B0A0A"
            />
          }
        />
        <CustomButton
          title="Withdraw"
          maxW="193px"
          color="extsy.white"
          height={{ base: "38px", lg: "56px" }}
          size={{ base: "12px", lg: "16px" }}
          bg="extsy.neutral700"
          icon={
            <IconArrowUpRight
              size={isLessThan992 ? "18px" : "24px"}
              color="#fff"
            />
          }
          onClick={onWithdrawOpen}
        />
        <CustomButton
          title="Transfer"
          maxW="193px"
          color="extsy.white"
          height={{ base: "38px", lg: "56px" }}
          size={{ base: "12px", lg: "16px" }}
          bg="extsy.neutral700"
          onClick={onTransferOpen}
          icon={
            <IconArrowRight
              size={isLessThan992 ? "18px" : "24px"}
              color="#fff"
            />
          }
        />
      </Flex>

      <CustomModal
        isOpen={isWithdrawOpen}
        onClose={() => {
          onWithdrawClose();
          withdrawReset();
        }}
        onSubmit={handleWithdrawSubmit((data) => handleWithdraw(data))}
        headerText={"Withdraw"}
        btnText={"Withdraw"}
        closeBtntext={"Cancel"}
        width={"700px"}
        bgtopBlur="extsy.bgCardTopBlur"
        blurTop="-308px"
        isForm
        isLoading={withdrawCrypto?.isPending}
      >
        <Flex
          p={{ base: "16px", lg: "32px" }}
          flexDir="column"
          gap={{ base: "16px", lg: "32px" }}
          w="100%"
        >
          <Flex flexDir="column" gap="12px">
            <CustomText text="Coin" size="14px" />
            <CustomSelect
              placeholder={withdrawCoin?.label}
              value={withdrawCoin?.value}
              ico={withdrawCoin?.icon}
              options={coins?.map((coin: CryptoAsset) => ({
                value: coin.id,
                label: coin.symbol_short,
                icon: coin.logo_url,
              }))}
              onSelect={(option: CoinOption) => {
                setWithdrawCoin(option);
              }}
              isLoading={coinsLoading}
            />
          </Flex>
          <Flex flexDir="column" gap="12px">
            <CustomText text="Address" size="14px" />
            <VCardInput
              placeholder="0x000.00000"
              bg="extsy.neutral1000"
              type="text"
              register={withdrawRegister}
              name="address"
              validation={{
                required: true,
              }}
              invalid={withdrawErrors.address}
              errorMsg={withdrawErrors.address && "Address is required"}
            />
          </Flex>
          <Flex flexDir="column" gap="12px">
            <CustomText text="Amount" size="14px" />
            <VCardInput
              placeholder="0.00"
              bg="extsy.neutral1000"
              type="number"
              register={withdrawRegister}
              name="amount"
              validation={{
                required: true,
                min: 0,
                pattern: /^[0-9]*\.?[0-9]+$/,
              }}
              invalid={withdrawErrors.amount}
              errorMsg={withdrawErrors.amount && "Amount is required"}
            />
          </Flex>
        </Flex>
      </CustomModal>
      <CustomModal
        isOpen={isTransferOpen}
        onClose={() => {
          onTransferClose();
          transferReset();
        }}
        onSubmit={handleTransferSubmit((data) => handleTransfer(data))}
        headerText={"Transfer"}
        btnText={"Transfer"}
        closeBtntext={"Cancel"}
        width={"800px"}
        bgtopBlur="transparent"
        isForm
        isLoading={transferCrypto?.isPending}
      >
        <Flex
          p={{ base: "16px", lg: "24px" }}
          flexDir="column"
          gap={{ base: "24px", lg: "32px" }}
          w="100%"
        >
          <Flex
            align="center"
            justify="space-between"
            minH={{ lg: "150px" }}
            p={{ base: "16px", lg: "24px" }}
            borderRadius="16px"
            bg="extsy.neutral1000"
            pos="relative"
            overflow="hidden"
          >
            <Flex
              zIndex="1000"
              flexDir="column"
              gap="16px"
              maxW="120px"
              w="100%"
            >
              <CustomText
                text="From"
                size="14px"
                transform="uppercase"
                weight="600"
                color="extsy.baseWhite"
              />
              {isSpotFuture ? (
                <SpotFlex isSpotFurute={isSpotFuture} />
              ) : (
                <FutureFlex isSpotFurute={isSpotFuture} />
              )}
            </Flex>
            <Image
              src={IconSwap}
              alt="swap"
              cursor="pointer"
              zIndex="1000"
              onClick={() => {
                setIsSpotFuture(!isSpotFuture);
              }}
            />

            <Flex
              flexDir="column"
              zIndex="1000"
              gap="16px"
              maxW="120px"
              w="100%"
            >
              <CustomText
                text="To"
                size="14px"
                transform="uppercase"
                weight="600"
                color="extsy.baseWhite"
                align="end"
              />
              {isSpotFuture ? (
                <FutureFlex isSpotFurute={isSpotFuture} />
              ) : (
                <SpotFlex isSpotFurute={isSpotFuture} />
              )}
            </Flex>
          </Flex>
          <Flex flexDir="column" gap="12px">
            <CustomText text="Coin" size="14px" />
            <CustomSelect
              placeholder={transferCoin?.label}
              value={transferCoin?.value}
              ico={transferCoin?.icon}
              options={coins?.map((coin: CryptoAsset) => ({
                value: coin.id,
                label: coin.symbol_short,
                icon: coin.logo_url,
              }))}
              onSelect={(option: CoinOption) => {
                setTransferCoin(option);
              }}
              isLoading={coinsLoading}
            />
          </Flex>
          <Flex flexDir="column" gap="12px">
            <Flex justify="space-between" align="center">
              <CustomText text="Amount" size={{ base: "12px", lg: "14px" }} />
              {coinBalanceLoading ? (
                <SkeletonText noOfLines={1} w="120px" />
              ) : (
                <CustomText
                  text={
                    <>
                      <span>
                        {isSpotFuture
                          ? formatNumber(
                              coinBalance?.data?.[0]?.balance_spot_available
                            )
                          : formatNumber(
                              coinBalance?.data?.[0]?.balance_future_available
                            )}
                         available
                      </span>
                      <span
                        style={{
                          color: "#383434",
                        }}
                      >
                        {" "}
                        /{" "}
                      </span>
                      <span>
                        {isSpotFuture
                          ? formatNumber(
                              coinBalance?.data?.[0]?.balance_spot_available -
                                coinBalance?.data?.[0]?.balance_spot
                            )
                          : formatNumber(
                              coinBalance?.data?.[0]?.balance_future_available -
                                coinBalance?.data?.[0]?.balance_future
                            )}
                         in order
                      </span>
                    </>
                  }
                  size={{ base: "12px", lg: "14px" }}
                  color="extsy.baseWhite"
                />
              )}
            </Flex>
            <VCardInput
              placeholder="0.00"
              bg="extsy.neutral1000"
              maxW="100%"
              type="number"
              register={transferRegister}
              name="amount"
              validation={{
                required: true,
                min: 0,
                pattern: /^[0-9]*\.?[0-9]+$/,
              }}
              invalid={transferErrors.amount}
              errorMsg={transferErrors.amount && "Amount is required"}
            />
          </Flex>
        </Flex>
      </CustomModal>
      <CustomModal
        isOpen={isDepositOpen}
        onClose={() => {
          onDepositClose();
        }}
        onSubmit={() => {
          handleDeposit();
        }}
        headerText={"Deposit"}
        btnText={"Deposit"}
        closeBtntext={"Cancel"}
        width={"700px"}
        bgtopBlur="transparent"
        isLoading={depositCryptoCoin?.isPending}
      >
        <Flex
          p={{ base: "16px", lg: "32px 24px" }}
          flexDir="column"
          gap="32px"
          w="100%"
        >
          <Flex flexDir="column" gap="12px">
            <CustomText text="Coin" size="14px" />
            <CustomSelect
              placeholder={depositCoin?.label}
              ico={depositCoin?.icon}
              value={depositCoin?.value}
              options={coins?.map((coin: CryptoAsset) => ({
                value: coin.id,
                label: coin.symbol_short,
                icon: coin.logo_url,
              }))}
              onSelect={(option: CoinOption) => {
                setDepositCoin(option);
              }}
              isLoading={coinsLoading}
            />
          </Flex>
        </Flex>
      </CustomModal>
      <CustomModal
        isOpen={isConfirmationOpen}
        onClose={onConfirmationClose}
        onSubmit={() => onConfirmationClose()}
        headerText={"Confirm Transaction"}
        noFooter={true}
        width={"780px"}
        bgtopBlur={"extsy.bgCardTopBlur"}
      >
        <Flex
          flexDir={"column"}
          gap={"12px"}
          py={{ base: "16px", lg: "32px" }}
          px={{ base: "16px", lg: "24px" }}
        >
          <Flex
            justify={"space-between"}
            align={{ lg: "center" }}
            gap={{ base: "16", lg: "56px" }}
            pb={"24px"}
            flexDir={{
              base: "column",
              lg: "row",
            }}
          >
            <Flex flexDir={"column"} gap={{ base: "16px", lg: "40px" }}>
              <Flex gap={"12px"} align={"center"}>
                <Image
                  src={depositResponse?.coin_logo_url}
                  alt="icon"
                  width={"40px"}
                  height={"40px"}
                />
                <Flex flexDir={"column"} gap={"5px"}>
                  <CustomText
                    text="Coin"
                    size="18px"
                    color="extsy.neutral300"
                    family="Metropolis"
                  />
                  <CustomText
                    text={` ${depositResponse?.coin_symbol_short || ""}`}
                    size="24px"
                    color="extsy.baseWhite"
                    weight="700"
                    family="Metropolis"
                  />
                </Flex>
              </Flex>
              <Flex
                flexDir={{ base: "row", lg: "column" }}
                justify={"space-between"}
                gap={{ base: "16px", lg: "40px" }}
              >
                <Flex flexDir={"column"} gap={"5px"}>
                  <CustomText
                    text={t("To this wallet")}
                    size="18px"
                    color="extsy.neutral300"
                    family="Metropolis"
                  />
                  <Flex gap="12px" align="center">
                    <CustomText
                      text={
                        depositResponse?.deposit_address?.slice(0, 6) +
                        "***" +
                        depositResponse?.deposit_address?.slice(-4)
                      }
                      size="24px"
                      color="extsy.baseWhite"
                      weight="700"
                      family="Metropolis"
                    />
                    <CopyIcon
                      onClick={() => {
                        navigator.clipboard.writeText(
                          depositResponse?.deposit_address
                        );
                        alert?.mutateAsync({
                          message: "Address copied to clipboard",
                          status: "success",
                        });
                      }}
                    />
                  </Flex>
                </Flex>
                {/* <Flex
                  flexDir={"column"}
                  gap={"5px"}
                  align={{ base: "flex-end", lg: "flex-start" }}
                >
                  <CustomText
                    text={t("Time left for the transaction")}
                    size="18px"
                    color="extsy.neutral300"
                    family="Metropolis"
                  />
                  <CustomText
                    text={t("20:53:26 ")}
                    size="24px"
                    color="extsy.baseWhite"
                    weight="700"
                    family="Metropolis"
                  />
                </Flex> */}
              </Flex>
            </Flex>
            <Flex justify="center">
              <ScanQR
                address={depositResponse?.deposit_address}
                amount={depositResponse?.amount}
                coin={depositResponse?.coin_symbol_short}
                isAmount={false}
              />
            </Flex>
          </Flex>
          <Flex pt={"16px"}>
            <DashboardSlider active={depositResponse?.status?.toLowerCase()} />
          </Flex>
        </Flex>
      </CustomModal>
    </Flex>
  );
}

const SpotFlex = ({ isSpotFurute }: any) => {
  return (
    <Flex
      align="center"
      gap={{ base: "8px", lg: "24px" }}
      justify={isSpotFurute ? "flex-start" : "flex-end"}
    >
      {!isSpotFurute && <Circle size="18px" bg="#CC750B" />}
      <CustomText
        text="Spot"
        size={{ base: "18px", lg: "22px" }}
        weight="600"
        color="#CC750B"
      />
      {isSpotFurute && <Circle size="18px" bg="#CC750B" />}
    </Flex>
  );
};

const FutureFlex = ({ isSpotFurute }: any) => {
  return (
    <Flex
      align="center"
      gap={{ base: "8px", lg: "24px" }}
      justify={!isSpotFurute ? "flex-start" : "flex-end"}
    >
      {isSpotFurute && <IconHexagonFilled size="18px" color="#C4FFF5" />}
      <CustomText
        text="Future"
        size={{ base: "18px", lg: "22px" }}
        weight="600"
        color="#15B097"
        order={isSpotFurute ? 0 : 1}
      />
      {!isSpotFurute && <IconHexagonFilled size="18px" color="#C4FFF5" />}
    </Flex>
  );
};

export default TradingEstimateBalanceCard;
