export const formatNumber = (
  number: number,
  maxFrac?: number,
  minFrac?: number
) => {
  return Number(number || 0)?.toLocaleString(undefined, {
    minimumFractionDigits: minFrac || 0,
    maximumFractionDigits: maxFrac || 2,
  });
};
