import { Box, Flex, Image } from "@chakra-ui/react";
import React, { useState } from "react";
import AMLCard from "./AMLCard";
import CheckCounter from "./CheckCounter";
import CustomText from "../../components/text/CustomText";
import AMLHistory from "./AMLHistory";
import { useGetAmlCheckHistory } from "../../utils/api/dashboard.api";
import EmptyTableIco from "../../assets/images/empty-trx-ico.svg";

function AmlCheck() {
  const limit = 10;
  const [offset, setOffset] = useState(0);
  const [search] = useState("");
  const [sortKey, setSortKey] = useState("");
  const [sortDirection, setSortDirection] = useState("ASC");

  const { data: amlHistory, isLoading } = useGetAmlCheckHistory(
    limit,
    offset,
    search,
    sortKey,
    sortDirection
  );

  return (
    <div>
      <Flex
        gap={{ base: "16px", lg: "24px" }}
        width="100%"
        flexDir={{
          base: "column",
          lg: "row",
        }}
      >
        <AMLCard />
        <CheckCounter />
      </Flex>
      <Box
        p={{ base: "16px", lg: "24px" }}
        bg="extsy.neutral1000"
        boxShadow=" 0px 3px 4px 0px rgba(0, 0, 0, 0.03)"
        borderRadius={{ base: "16px", lg: "24px" }}
        mt="24px"
      >
        <Flex flexDir="column" gap={{ base: "16px", lg: "24px" }}>
          <CustomText
            text="Request History"
            size={{ base: "18px", lg: "24px" }}
            lh={{ lg: "34px" }}
            weight={{ base: "600", lg: "700" }}
            family="Metropolis"
            color="extsy.baseWhite"
          />
          {amlHistory?.data?.length <= 0 ? (
            <Flex
              flexDir="column"
              align="center"
              justify="center"
              gap="8px"
              h="100%"
              minH="326px"
            >
              <Image
                src={EmptyTableIco}
                alt="empty"
                height="32px"
                width="30.43px"
              />
              <CustomText
                text="No History Available"
                size="14px"
                color="extsy.neutral400"
              />
            </Flex>
          ) : (
            <AMLHistory
              amlHistory={amlHistory}
              isLoading={isLoading}
              limit={limit}
              offset={offset}
              setOffset={setOffset}
              sortKey={sortKey}
              setSortKey={setSortKey}
              sortDirection={sortDirection}
              setSortDirection={setSortDirection}
            />
          )}
        </Flex>
      </Box>
    </div>
  );
}

export default AmlCheck;
