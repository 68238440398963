import {
  Flex,
  Image,
  SkeletonText,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import CustomText from "../../components/text/CustomText";
import { IconChevronDown, IconChevronUp } from "@tabler/icons-react";
import ArrowBack from "../../assets/images/black-left.svg";
import ArrowForward from "../../assets/images/black-right.svg";
import { useTranslation } from "react-i18next";
import ReactPaginate from "react-paginate";

function AMLHistory({
  amlHistory,
  limit,
  offset,
  setOffset,
  sortKey,
  sortDirection,
  setSortKey,
  setSortDirection,
  isLoading,
}: any) {
  const [pageCount, setPageCount] = useState(0);

  const { t } = useTranslation();

  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * limit) % amlHistory?.total;
    setOffset(newOffset);
  };
  useEffect(() => {
    if (amlHistory?.total) {
      setPageCount(Math.ceil(amlHistory?.total / limit));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amlHistory, limit]);

  return (
    <Flex flexDir="column" gap={{ base: "16px", lg: "24px" }}>
      <TableContainer>
        <Table border="1px solid" color="extsy.neutral900">
          <Thead bg="extsy.neutral900" height="40px">
            <Tr>
              <Th borderColor="extsy.neutral900" maxW="383px" minW="160px">
                <Flex align="center" gap="4px">
                  <CustomText
                    text={"Date"}
                    size="13px"
                    color="extsy.baseWhite"
                    weight="500"
                    ls="-0.13px"
                  />
                  <Flex
                    flexDir="column"
                    cursor={"pointer"}
                    onClick={() => {
                      setSortKey("created_at");
                      if (sortDirection === "ASC") {
                        setSortDirection("DESC");
                      } else {
                        setSortDirection("ASC");
                      }
                    }}
                  >
                    <IconChevronUp
                      cursor="pointer"
                      color="#FAFAFA"
                      size="14px"
                    />
                    <IconChevronDown
                      color="#FAFAFA"
                      size="14px"
                      cursor="pointer"
                      style={{
                        marginTop: -7,
                      }}
                    />
                  </Flex>
                </Flex>
              </Th>
              <Th
                borderColor="extsy.neutral900"
                maxW="583px"
                minW={{ base: "160px", "1xl": "260px" }}
              >
                <Flex align="center" gap="4px">
                  <CustomText
                    text={"Currency"}
                    size="13px"
                    color="extsy.baseWhite"
                    weight="500"
                    ls="-0.13px"
                  />
                  <Flex
                    flexDir="column"
                    cursor={"pointer"}
                    onClick={() => {
                      setSortKey("currency");
                      if (sortDirection === "ASC") {
                        setSortDirection("DESC");
                      } else {
                        setSortDirection("ASC");
                      }
                    }}
                  >
                    <IconChevronUp
                      cursor="pointer"
                      color="#FAFAFA"
                      size="14px"
                    />
                    <IconChevronDown
                      color="#FAFAFA"
                      size="14px"
                      cursor="pointer"
                      style={{
                        marginTop: -7,
                      }}
                    />
                  </Flex>
                </Flex>
              </Th>
              <Th
                borderColor="extsy.neutral900"
                maxW="583px"
                minW={{ base: "160px", "1xl": "260px" }}
              >
                <Flex align="center" gap="4px">
                  <CustomText
                    text={"Address"}
                    size="13px"
                    color="extsy.baseWhite"
                    weight="500"
                    ls="-0.13px"
                  />
                  <Flex
                    flexDir="column"
                    cursor={"pointer"}
                    onClick={() => {
                      setSortKey("address");
                      if (sortDirection === "ASC") {
                        setSortDirection("DESC");
                      } else {
                        setSortDirection("ASC");
                      }
                    }}
                  >
                    <IconChevronUp
                      cursor="pointer"
                      color="#FAFAFA"
                      size="14px"
                    />
                    <IconChevronDown
                      color="#FAFAFA"
                      size="14px"
                      cursor="pointer"
                      style={{
                        marginTop: -7,
                      }}
                    />
                  </Flex>
                </Flex>
              </Th>
              <Th borderColor="extsy.neutral900" maxW="283px" minW="160px">
                <Flex align="center" gap="4px">
                  <CustomText
                    text={"Result"}
                    size="13px"
                    color="extsy.baseWhite"
                    weight="500"
                    ls="-0.13px"
                  />
                  <Flex
                    flexDir="column"
                    cursor={"pointer"}
                    onClick={() => {
                      setSortKey("result");
                      if (sortDirection === "ASC") {
                        setSortDirection("DESC");
                      } else {
                        setSortDirection("ASC");
                      }
                    }}
                  >
                    <IconChevronUp
                      cursor="pointer"
                      color="#FAFAFA"
                      size="14px"
                    />
                    <IconChevronDown
                      color="#FAFAFA"
                      size="14px"
                      cursor="pointer"
                      style={{
                        marginTop: -7,
                      }}
                    />
                  </Flex>
                </Flex>
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {isLoading
              ? [1, 2, 3, 4, 5].map((_, index) => <LoadingRow key={index} />)
              : amlHistory?.data?.map((aml: any, index: any) => (
                  <Tr key={index}>
                    <Td
                      height="54px"
                      borderColor="extsy.neutral900"
                      borderRight="1px solid"
                      py="0"
                    >
                      <CustomText
                        text={formatTransactionDate(aml?.created_at)}
                        size="14px"
                      />
                    </Td>
                    <Td borderColor="extsy.neutral900" borderRight="1px solid">
                      <CustomText
                        text={aml?.currency}
                        transform={"uppercase"}
                        size="14px"
                      />
                    </Td>
                    <Td borderColor="extsy.neutral900" borderRight="1px solid">
                      <CustomText text={aml?.address} size="14px" />
                    </Td>
                    <Td borderColor="extsy.neutral900" borderRight="1px solid">
                      <Flex
                        align="center"
                        justify="center"
                        bg="#15B0971A"
                        w="44px"
                        h="20px"
                        borderRadius="30px"
                      >
                        <CustomText
                          color="#15B097"
                          size="10px"
                          weight="500"
                          text={aml?.result}
                        />
                      </Flex>
                    </Td>
                  </Tr>
                ))}
          </Tbody>
        </Table>
      </TableContainer>
      <Flex gap="16px" px="20px" align="center" justify="flex-end">
        {isLoading ? (
          <SkeletonText noOfLines={1} w="120px" />
        ) : (
          <>
            <CustomText
              text={`${offset + 1}-${offset + amlHistory?.data?.length} ${t(
                "of"
              )} ${amlHistory?.total}`}
              size="13px"
              weight="500"
              color="extsy.neutral300"
            />
            <ReactPaginate
              breakLabel="..."
              nextLabel={
                <Image
                  src={ArrowForward}
                  alt="arrow-forward"
                  cursor="pointer"
                />
              }
              previousLabel={
                <Image src={ArrowBack} alt="arrow-back" cursor="pointer" />
              }
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              pageCount={pageCount}
              renderOnZeroPageCount={null}
              containerClassName="react-pagination"
              activeClassName="active-pagination"
            />
          </>
        )}
      </Flex>
    </Flex>
  );
}

export default AMLHistory;

const LoadingRow = () => {
  return (
    <Tr>
      <Td borderColor="extsy.neutral900" borderRight="1px solid">
        <SkeletonText noOfLines={1} w="40px" />
      </Td>
      <Td borderColor="extsy.neutral900" borderRight="1px solid">
        <SkeletonText noOfLines={1} w="40px" />
      </Td>

      <Td borderColor="extsy.neutral900" borderRight="1px solid">
        <SkeletonText noOfLines={1} w="80px" />
      </Td>
      <Td borderColor="extsy.neutral900" borderRight="1px solid">
        <SkeletonText noOfLines={1} w="40px" />
      </Td>
    </Tr>
  );
};

const formatTransactionDate = (isoDateString: any) => {
  const date = new Date(isoDateString);

  // Extract day, month, and year
  const day = String(date.getUTCDate()).padStart(2, "0");
  const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are zero-based
  const year = date.getUTCFullYear();

  // Format time as HH:MM in UTC
  const hours = String(date.getUTCHours()).padStart(2, "0");
  const minutes = String(date.getUTCMinutes()).padStart(2, "0");

  // Combine formatted date and time
  const formattedDate = `${day}-${month}-${year}`;
  const formattedTime = `${hours}:${minutes}`;

  return `${formattedDate} ${formattedTime}`;
};
