import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import React, { useState } from "react";
import CustomText from "../text/CustomText";
import GradientText from "../text/GradientText";
import { useTranslation } from "react-i18next";
import SwapCard from "../cards/SwapCard";
import BuySellCard from "../cards/BuySellCard";

function SwapWidget() {
  const { t } = useTranslation();

  const [tabIndex, setTabIndex] = useState(0);

  const tabs = [t("swapCrypto"), t("buySellCrypto")];

  return (
    <Box
      bg="extsy.g3"
      border="1px solid"
      borderColor="extsy.neutral900"
      minHeight={{ lg: "487px" }}
      borderRadius="16px"
      backdropFilter={"blur(5px)"}
    >
      <Tabs onChange={(index) => setTabIndex(index)} defaultIndex={0}>
        <TabList border="none" overflow="hidden">
          {tabs?.map((tab, index) => (
            <Tab
              key={index}
              height="50px"
              width="50%"
              border="none"
              bg={tabIndex === index ? "extsy.dark" : "extsy.dark100"}
              borderTopLeftRadius={index === 0 ? "24px" : "0"}
              borderTopRightRadius={index === 1 ? "24px" : "0"}
              borderBottomLeftRadius={
                tabIndex === 0 && index === 1 ? "24px" : "0"
              }
              borderBottomRightRadius={
                tabIndex === 1 && index === 0 ? "24px" : "0"
              }
            >
              {tabIndex === index ? (
                <GradientText
                  text={tab}
                  family="Metropolis"
                  weight="700"
                  size={{ base: "14px", lg: "15.4px" }}
                  lh="22px"
                  bg="extsy.g1"
                  cursor="pointer"
                />
              ) : (
                <CustomText
                  text={tab}
                  family="Metropolis"
                  size={{ base: "14px", lg: "15.4px" }}
                  lh="22px"
                  cursor="pointer"
                  color="extsy.white"
                />
              )}
            </Tab>
          ))}
        </TabList>
        <TabPanels px="16.28px" pt="24.5px" pb="17px">
          <TabPanel p="0">
            <SwapCard />
          </TabPanel>
          <TabPanel p="0">
            <BuySellCard />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}

export default SwapWidget;
