import { Flex, Image } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import CustomText from "../text/CustomText";

function FiatCurrencyDropdown({ activeCurrency, onClick, currencies }: any) {
  const { t } = useTranslation();
  return (
    <Flex
      flexDir="column"
      p="15px 12px 16px 16px"
      bg="extsy.neutral900"
      borderRadius="16px"
      border="1px solid"
      borderColor="extsy.neutral800"
      flexDirection="column"
      position={"absolute"}
      width="90%"
      maxW="214px"
      right="0"
      mr="25px"
      zIndex="10000"
      maxH="372px"
      overflowY="auto"
      gap="12px"
    >
      <Flex align="center" justify="space-between">
        <CustomText
          text={t("currency")}
          color="extsy.white"
          family="Metropolis"
        />
      </Flex>
      <Flex flexDir="column" gap="4px">
        {currencies?.map((currency: any, index: any) => (
          <Flex
            key={index}
            justify="space-between"
            align="center"
            p="8px"
            justifyContent="space-between"
            cursor="pointer"
            onClick={() => {
              onClick(currency);
            }}
            borderRadius="4px"
            bg={activeCurrency?.icon === currency.icon ? "extsy.baseWhite" : ""}
          >
            <Flex alignItems="center" gap="10px">
              <Image
                src={currency.icon}
                alt={currency.icon}
                height="24px"
                width="24px"
              />
              <CustomText
                text={currency?.code}
                color={
                  activeCurrency?.code === currency?.code
                    ? "extsy.dark300"
                    : "extsy.neutral300"
                }
                family="Metropolis"
                cursor="pointer"
              />
            </Flex>
          </Flex>
        ))}{" "}
      </Flex>
    </Flex>
  );
}

export default FiatCurrencyDropdown;
