import { Flex, Image, Stack } from "@chakra-ui/react";
import React from "react";
import CustomText from "../../../components/text/CustomText";
import CautionIcon from "../../../assets/images/caution-icon.svg";
import CustomButton from "../../../components/button/CustomButton";

const StepSection = ({ step }: any) => {
  return (
    <>
      {step === 1 && (
        <Flex
          gap={"10px"}
          align={"center"}
          borderRadius={"8px"}
          bg={"#383434"}
          maxW={"811px"}
          p={"16px 24px"}
        >
          <Image src={CautionIcon} alt="caution" />
          <CustomText
            color="#BAB8B8"
            size="14px"
            lh="120%"
            text="The seller has submitted an appeal stating that the payment has not been received in their account. If you haven’t made the payment, you can choose to “Cancel the Order.” Once the order is canceled, the appeal will be automatically closed."
          />
        </Flex>
      )}

      <CustomText
        color="#EDA145"
        size="12px"
        weight="500"
        lh="22px"
        text={
          step === 1
            ? "Please respond within 01:20:27"
            : step === 2
            ? "Awaiting response from the respondent. Remaining time: 01:20:27"
            : "Please wait for Extsy customer support’s assistance"
        }
      />

      <Stack gap={"16px"}>
        <CustomText
          color="#BAB8B8"
          size="12px"
          lh="130%"
          text={
            step === 1
              ? '1. If an agreement has been reached, click "Consensus Reached" to confirm and cancel the appeal.'
              : '1. If an agreement has been reached, click "Cancel Appeal" to cancel appeal and complete the trade.'
          }
        />
        <CustomText
          color="#BAB8B8"
          size="12px"
          lh="130%"
          text={
            step === 3
              ? "2. Customer support is handling the case, please make sure you are accessible via your registered email or phone number."
              : '2. If you are unable to reach an agreement with the other party, click "Negotiation Failed," and Customer Support will step in to mediate.'
          }
        />
        <CustomText
          color="#BAB8B8"
          size="12px"
          lh="130%"
          text="3. For more information, please contact us."
        />
      </Stack>

      {step === 3 && (
        <Flex
          gap={"10px"}
          align={"center"}
          borderRadius={"8px"}
          bg={"#383434"}
          maxW={"811px"}
          p={"16px 24px"}
        >
          <Image src={CautionIcon} alt="caution" />
          <CustomText
            color="#BAB8B8"
            size="14px"
            lh="120%"
            text="Your case is under investigation. As we are experiencing a high volume of inquiries globally, your patience is appreciated. It can take 4-8 hours for our CS team to review your appeal."
          />
        </Flex>
      )}

      <Flex
        align={{ sm: "center" }}
        gap={"8px"}
        flexDirection={{ base: "column", sm: "row" }}
      >
        <CustomButton
          title={step === 1 ? "Consensus Reached" : "Cancel Appeal"}
          padding="12px 24px"
          radius="8px"
          bg="#383434"
          size="12px"
          height="48px"
          color="#BAB8B8"
          maxW="165px"
        />
        <CustomButton
          title={
            step === 1
              ? "Negotiation Failed"
              : step === 3
              ? "Proceed Further"
              : ""
          }
          padding="12px 24px"
          radius="8px"
          bg="#383434"
          height="48px"
          size="12px"
          maxW="165px"
          color={step === 1 ? "#F0C2DB" : "#F0C2DB"}
        />
      </Flex>
    </>
  );
};

export default StepSection;
