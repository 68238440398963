import { createSlice } from "@reduxjs/toolkit";

// Define a type for the slice state
interface CounterState {
  tickerStats: any;
  pairSymbol: any;
}

// Define the initial state using that type
const initialState: CounterState = {
  tickerStats: null,
  pairSymbol: "",
};

export const spotSlice = createSlice({
  name: "spotSlice",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setTickerStats: (state, action) => {
      state.tickerStats = action.payload;
    },
    setPairSymbol: (state, action) => {
      state.pairSymbol = action.payload;
    },
  },
});

export const { setTickerStats, setPairSymbol } = spotSlice.actions;

export default spotSlice.reducer;
