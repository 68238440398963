import {
  Box,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import CustomText from "../../components/text/CustomText";
import Spot from "../../components/trading/Spot";
import Future from "../../components/trading/Future";
import { useSearchParams } from "react-router-dom";

function Trading() {
  const [tabIndex, setTabIndex] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();

  const TABS = ["Spot"];

  useEffect(() => {
    const trade = searchParams.get("trade");
    if (trade === "futures") {
      setTabIndex(1);
    }
  }, [searchParams]);

  return (
    <Flex gap="24px" flexDir="column">
      <Box bg="extsy.neutral1000" borderRadius="16px" pt="20px">
        <Tabs
          onChange={(index) => {
            setTabIndex(index);
            setSearchParams({
              trade: TABS[index].toLowerCase(),
              pair: "TRXUSDT",
            });
          }}
          defaultIndex={0}
        >
          <TabList borderBottom="1px solid" borderColor="extsy.neutral900">
            {TABS?.map((tab, index) => (
              <Tab
                key={index}
                px="32px"
                pt="10px"
                pb="14px"
                w={{ base: "50%", lg: "auto" }}
                color="extsy.baseWhite"
              >
                <CustomText
                  text={tab}
                  size="18px"
                  weight="600"
                  color={
                    index === tabIndex ? "extsy.baseWhite" : "extsy.neutral300"
                  }
                  cursor="pointer"
                />
              </Tab>
            ))}
          </TabList>
          <TabPanels>
            <TabPanel pe="0" pt="28px" ps="0px">
              <Spot />
            </TabPanel>
            <TabPanel pe="0" pt="28px" ps="0px">
              <Future />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Flex>
  );
}

export default Trading;
