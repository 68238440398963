import {
  Avatar,
  Flex,
  Image,
  SkeletonText,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import CustomText from "../../../components/text/CustomText";
import { IconChevronDown, IconChevronUp } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import ArrowBack from "../../../assets/images/black-left.svg";
import ArrowForward from "../../../assets/images/black-right.svg";
import CustomButton from "../../../components/button/CustomButton";
import CustomModal from "../../../components/modal/customModal";
import EthIco from "../../../assets/images/eth-ico.svg";
import ReactPaginate from "react-paginate";
import { useCancelOrder } from "../../../utils/api/p2p.api";
import { useCustomAlert } from "../../../utils/api/alert";
import ViewIcon from "../../../assets/images/view-icon.svg";
import EditIcon from "../../../assets/images/edit-icon.svg";
import { useNavigate } from "react-router-dom";

function MyListing({
  data,
  isLoading,
  sortDirection,
  setSortBy,
  setSortDirection,
  offset,
  setPageCount,
  limit,
  pageCount,
  setOffset,
}: any) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    isOpen: isDelistOpen,
    onClose: onDelistClose,
    onOpen: onDelistOpen,
  } = useDisclosure();
  const [selectedCoin, setSelectedCoin] = useState<any>();
  const cancelOrderMutation = useCancelOrder();
  const alert = useCustomAlert();

  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * limit) % data?.data?.length;
    setOffset(newOffset);
  };
  useEffect(() => {
    if (data?.total) {
      setPageCount(Math.ceil(data?.total / limit));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, limit]);

  return (
    <Flex flexDir="column" gap="24px" mt={{ base: "60px", md: "0px" }}>
      <TableContainer minH="250px">
        <Table border="1px solid" color="extsy.neutral900">
          <Thead bg="extsy.neutral900" height="40px">
            <Tr>
              <Th borderColor="extsy.neutral900">
                <Flex align="center" gap="4px">
                  <CustomText
                    text={"Coin Type"}
                    size="13px"
                    color="extsy.baseWhite"
                    weight="500"
                    ls="-0.13px"
                  />
                  <Flex
                    flexDir="column"
                    cursor={"pointer"}
                    onClick={() => {
                      setSortBy("coin");
                      if (sortDirection === "ASC") {
                        setSortDirection("DESC");
                      } else {
                        setSortDirection("ASC");
                      }
                    }}
                  >
                    <IconChevronUp
                      cursor="pointer"
                      color="#FAFAFA"
                      size="14px"
                    />
                    <IconChevronDown
                      color="#FAFAFA"
                      size="14px"
                      cursor="pointer"
                      style={{
                        marginTop: -7,
                      }}
                    />
                  </Flex>
                </Flex>
              </Th>
              <Th borderColor="extsy.neutral900">
                <Flex align="center" gap="4px">
                  <CustomText
                    text={"Chain"}
                    size="13px"
                    color="extsy.baseWhite"
                    weight="500"
                    ls="-0.13px"
                  />
                  <Flex
                    flexDir="column"
                    cursor={"pointer"}
                    onClick={() => {
                      setSortBy("chain");
                      if (sortDirection === "ASC") {
                        setSortDirection("DESC");
                      } else {
                        setSortDirection("ASC");
                      }
                    }}
                  >
                    <IconChevronUp
                      cursor="pointer"
                      color="#FAFAFA"
                      size="14px"
                    />
                    <IconChevronDown
                      color="#FAFAFA"
                      size="14px"
                      cursor="pointer"
                      style={{
                        marginTop: -7,
                      }}
                    />
                  </Flex>
                </Flex>
              </Th>
              <Th borderColor="extsy.neutral900">
                <Flex align="center" gap="4px" justify="end">
                  <CustomText
                    text="Total Amount"
                    size="13px"
                    color="extsy.baseWhite"
                    weight="500"
                    ls="-0.13px"
                  />
                  <Flex
                    flexDir="column"
                    cursor={"pointer"}
                    onClick={() => {
                      setSortBy("amount");
                      if (sortDirection === "ASC") {
                        setSortDirection("DESC");
                      } else {
                        setSortDirection("ASC");
                      }
                    }}
                  >
                    <IconChevronUp
                      cursor="pointer"
                      color="#FAFAFA"
                      size="14px"
                    />
                    <IconChevronDown
                      color="#FAFAFA"
                      size="14px"
                      cursor="pointer"
                      style={{
                        marginTop: -7,
                      }}
                    />
                  </Flex>
                </Flex>
              </Th>
              <Th borderColor="extsy.neutral900">
                <Flex align="center" gap="4px" justify="end">
                  <CustomText
                    text="Available"
                    size="13px"
                    color="extsy.baseWhite"
                    weight="500"
                    ls="-0.13px"
                  />
                  <Flex
                    flexDir="column"
                    cursor={"pointer"}
                    onClick={() => {
                      setSortBy("price");
                      if (sortDirection === "ASC") {
                        setSortDirection("DESC");
                      } else {
                        setSortDirection("ASC");
                      }
                    }}
                  >
                    <IconChevronUp
                      cursor="pointer"
                      color="#FAFAFA"
                      size="14px"
                    />
                    <IconChevronDown
                      color="#FAFAFA"
                      size="14px"
                      cursor="pointer"
                      style={{
                        marginTop: -7,
                      }}
                    />
                  </Flex>
                </Flex>
              </Th>
              <Th borderColor="extsy.neutral900">
                <Flex align="center" gap="4px">
                  <CustomText
                    text="BTC Value"
                    size="13px"
                    color="extsy.baseWhite"
                    weight="500"
                    ls="-0.13px"
                  />
                  <Flex
                    flexDir="column"
                    cursor={"pointer"}
                    onClick={() => {
                      // setSortBy("btcValue");
                      if (sortDirection === "ASC") {
                        setSortDirection("DESC");
                      } else {
                        setSortDirection("ASC");
                      }
                    }}
                  >
                    <IconChevronUp
                      cursor="pointer"
                      color="#FAFAFA"
                      size="14px"
                    />
                    <IconChevronDown
                      color="#FAFAFA"
                      size="14px"
                      cursor="pointer"
                      style={{
                        marginTop: -7,
                      }}
                    />
                  </Flex>
                </Flex>
              </Th>
              <Th borderColor="extsy.neutral900">
                <Flex align="center" gap="4px">
                  <CustomText
                    text={t("actions")}
                    size="13px"
                    color="extsy.baseWhite"
                    weight="500"
                    ls="-0.13px"
                  />
                </Flex>
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {isLoading
              ? [1, 2, 3, 4, 5]?.map((_, index) => <LoadingRow key={index} />)
              : data?.data?.map((order: any, index: any) => {
                  return (
                    <Tr key={index}>
                      <Td
                        height="64px"
                        borderColor="extsy.neutral900"
                        borderRight="1px solid"
                        py="0"
                      >
                        <Flex gap="12px" align="center">
                          <Avatar src={order?.coin_logo_url} boxSize="24px" />
                          <Flex gap="8px" flexDir="column">
                            <CustomText
                              text={order?.coin_symbol_short}
                              size="14px"
                              color="extsy.baseWhite"
                            />
                            <CustomText
                              text={`${order?.coin_name}`}
                              size="12px"
                              color="extsy.text5"
                            />
                          </Flex>
                        </Flex>
                      </Td>
                      <Td
                        height="64px"
                        borderColor="extsy.neutral900"
                        borderRight="1px solid"
                        py="0"
                      >
                        <Flex gap="12px" align="center">
                          <Avatar
                            src={order?.order_chain_logo}
                            boxSize="24px"
                          />
                          <Flex gap="8px" flexDir="column">
                            <CustomText
                              text={order?.order_chain}
                              size="14px"
                              color="extsy.baseWhite"
                            />
                          </Flex>
                        </Flex>
                      </Td>
                      <Td
                        borderColor="extsy.neutral900"
                        borderRight="1px solid"
                      >
                        <CustomText
                          text={`${order?.order_totalTradingAmount || 0} USDT`}
                          size="14px"
                          weight="500"
                          align="end"
                        />
                      </Td>
                      <Td
                        borderColor="extsy.neutral900"
                        borderRight="1px solid"
                      >
                        <CustomText
                          text={`${
                            order?.order_availableTradingAmount || 0
                          } USDT`}
                          size="14px"
                          weight="500"
                          align="end"
                        />
                      </Td>
                      <Td
                        borderColor="extsy.neutral900"
                        borderRight="1px solid"
                      >
                        <Flex align="center" justify="space-between">
                          <CustomText
                            text={order?.order_btcValue || 0}
                            size="14px"
                            weight="500"
                          />
                          <CustomText
                            text={`≈ $${order?.btc_usd || 0}`}
                            size="14px"
                            weight="500"
                            color="extsy.neutral500"
                          />
                        </Flex>
                      </Td>
                      <Td
                        borderColor="extsy.neutral900"
                        borderRight="1px solid"
                        maxW="124px"
                      >
                        <Flex gap={"6px"} align={"center"}>
                          <Image
                            src={ViewIcon}
                            alt="view"
                            cursor={"pointer"}
                            onClick={() =>
                              navigate(`/p2p/ad-detail`, { state: order })
                            }
                          />
                          <Image src={EditIcon} alt="edit" />
                          <CustomButton
                            title="Delist"
                            bg="#ED3D4E"
                            color="extsy.baseWhite"
                            height="28px"
                            width="64px"
                            size="12px"
                            onClick={() => {
                              console.log(order);
                              setSelectedCoin(order);
                              onDelistOpen();
                            }}
                          />
                        </Flex>
                      </Td>
                    </Tr>
                  );
                })}
          </Tbody>
        </Table>
      </TableContainer>
      <Flex gap="16px" px="20px" align="center" justify="flex-end">
        {isLoading ? (
          <SkeletonText noOfLines={1} w="120px" />
        ) : (
          <>
            <CustomText
              text={`${offset + 1}-${offset + data?.data?.length} ${t("of")} ${
                data?.data?.length
              }`}
              size="13px"
              weight="500"
              color="extsy.neutral300"
            />
            <ReactPaginate
              breakLabel="..."
              nextLabel={
                <Image
                  src={ArrowForward}
                  alt="arrow-forward"
                  cursor="pointer"
                />
              }
              previousLabel={
                <Image src={ArrowBack} alt="arrow-back" cursor="pointer" />
              }
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              pageCount={pageCount}
              renderOnZeroPageCount={null}
              containerClassName="react-pagination"
              activeClassName="active-pagination"
            />
          </>
        )}
      </Flex>
      <CustomModal
        isOpen={isDelistOpen}
        onClose={onDelistClose}
        onSubmit={async () => {
          try {
            await cancelOrderMutation.mutateAsync(selectedCoin?.order_id);
            onDelistClose();
            alert.mutate({
              message: "Order cancelled Successfully",
              status: "success",
            });
          } catch (error: any) {
            alert.mutate({
              message: error.message,
              status: "danger",
            });
          }
        }}
        headerText={"Delisting"}
        btnText={"Confirm, Delist"}
        closeBtntext={"Cancel"}
        width={"600px"}
        isLoading={cancelOrderMutation?.isPending}
        isDisabled={cancelOrderMutation?.isPending}
      >
        <Flex
          flexDir={"column"}
          gap={"32px"}
          py={"32px"}
          px={"24px"}
          align="center"
        >
          <CustomText text="Are you sure you want to delist?" />
          <Flex align="center" gap="16px">
            <Image
              src={selectedCoin?.coin_logo_url || EthIco}
              alt="eth-ico"
              boxSize="32px"
            />
            <Flex align="center" gap="8px">
              <CustomText
                text={selectedCoin?.coin_symbol_short}
                color="extsy.baseWhite"
              />
              <CustomText text={selectedCoin?.coin_name} />
            </Flex>
          </Flex>
        </Flex>
      </CustomModal>
    </Flex>
  );
}

export default MyListing;

const LoadingRow = () => {
  return (
    <Tr>
      <Td
        height="54px"
        borderColor="extsy.neutral900"
        borderRight="1px solid"
        py="0"
        pos="relative"
        overflow="hidden"
      >
        <SkeletonText noOfLines={1} w="80px" />
      </Td>
      <Td borderColor="extsy.neutral900" borderRight="1px solid">
        <Flex align="center" gap="12px">
          <SkeletonText noOfLines={1} w="40px" />
        </Flex>
      </Td>
      <Td borderColor="extsy.neutral900" borderRight="1px solid">
        <Flex align="center" gap="12px">
          <SkeletonText noOfLines={1} w="40px" />
        </Flex>
      </Td>
      <Td borderColor="extsy.neutral900" borderRight="1px solid">
        <SkeletonText noOfLines={1} w="80px" />
      </Td>
      <Td borderColor="extsy.neutral900" borderRight="1px solid">
        <SkeletonText noOfLines={1} w="40px" />
      </Td>

      <Td borderColor="extsy.neutral900" borderRight="1px solid">
        <SkeletonText noOfLines={1} w="40px" />
      </Td>
    </Tr>
  );
};
