import { Box, Flex, Image } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import CustomText from "../text/CustomText";
import PlusIcoYellow from "../../assets/images/plus-yellow.svg";
import TradingInput from "../form/TradingInput";
import CustomTradingSlider from "../form/CustomTradingSlider";
import CustomButton from "../button/CustomButton";
import {
  useCreateSpotOrder,
  useGetAssetTradeBalance,
  useGetBaseTradeBalance,
  useGetPairInfo,
} from "../../utils/api/trade.api";
import { socket } from "../../utils/sockets";
import { formatNumber } from "../../utils/functions/formatNumber";
import { useCustomAlert } from "../../utils/api/alert";
import { useSelector } from "react-redux";

function BuySell() {
  const alert = useCustomAlert();

  const { tickerStats, pairSymbol } = useSelector((state: any) => state.spot);

  const { data: pairSymbolInfo, refetch: refetchTradePairs } =
    useGetPairInfo(pairSymbol);
  const { data: baseAssetBalance, refetch: refetchBaseTrade } =
    useGetBaseTradeBalance(pairSymbolInfo?.[0]?.to_coin_id);
  const { data: assetBalance, refetch: refetchAssetTrade } =
    useGetAssetTradeBalance(pairSymbolInfo?.[0]?.from_coin_id);

  const [activeTab, setActiveTab] = useState("Limit");
  const [buyResponse, setBuyResponse] = useState<any | undefined>(undefined);
  const [formData, setFormData] = useState({
    pairSymbol: "",
    side: "",
    spotOrderType: activeTab?.toUpperCase(),
    price: 0,
    priceSell: 0,
    amountLeft: 0,
    amountLeftSell: 0,
    totalAmount: 0,
    totalAmountSell: 0,
  });
  const createSpotOrderMutation = useCreateSpotOrder();
  const [loadingFor, setLoadingFor] = useState("buy");

  const leftCoin = pairSymbolInfo?.[0]?.pair_ticker?.split("-")[0];
  const rightCoin = pairSymbolInfo?.[0]?.pair_ticker?.split("-")[1];

  const clearState = () => {
    setFormData({
      pairSymbol: "",
      side: "",
      spotOrderType: activeTab?.toUpperCase(),
      price: 0,
      priceSell: 0,
      amountLeft: 0,
      amountLeftSell: 0,
      totalAmount: 0,
      totalAmountSell: 0,
    });
  };

  const handleBuy = () => {
    setLoadingFor("buy");
    createSpotOrderMutation
      ?.mutateAsync({
        pairSymbol: pairSymbol,
        side: "BUY",
        spotOrderType: activeTab?.toUpperCase(),
        price: activeTab === "Limit" ? formData?.price : tickerStats?.lastPrice,
        amountLeft: formData?.amountLeft,
        amountRight:
          activeTab === "Limit"
            ? formData?.totalAmount
            : Number(formData?.amountLeft) * Number(tickerStats?.lastPrice),
        isDummy: false,
      })
      .then((result) => {
        alert?.mutateAsync({
          message: "Order placed successfully",
          status: "success",
        });
        clearState();
      })
      .catch((error) => {
        alert?.mutateAsync({ message: error?.message, status: "danger" });
      });
  };

  const handleSell = () => {
    setLoadingFor("sell");
    createSpotOrderMutation
      ?.mutateAsync({
        pairSymbol: pairSymbol,
        side: "SELL",
        spotOrderType: activeTab?.toUpperCase(),
        price:
          activeTab === "Limit" ? formData?.priceSell : tickerStats?.lastPrice,
        amountLeft: formData?.amountLeftSell,
        amountRight:
          activeTab === "Limit"
            ? formData?.totalAmountSell
            : Number(formData?.amountLeftSell) * Number(tickerStats?.lastPrice),
        isDummy: false,
      })
      .then((result) => {
        alert?.mutateAsync({
          message: "Order placed successfully",
          status: "success",
        });
        clearState();
      })
      .catch((error) => {
        alert?.mutateAsync({ message: error?.message, status: "danger" });
      });
  };

  useEffect(() => {
    if (pairSymbolInfo) {
      refetchBaseTrade();
      refetchAssetTrade();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pairSymbolInfo]);

  useEffect(() => {
    refetchTradePairs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pairSymbol]);

  useEffect(() => {
    if (tickerStats?.lastPrice && (!formData.price || !formData.priceSell)) {
      setFormData({
        ...formData,
        price: tickerStats?.lastPrice,
        priceSell: tickerStats?.lastPrice,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tickerStats]);

  useEffect(() => {
    function onBuyStatusUpdate(status: any) {
      setBuyResponse({
        ...buyResponse,
        status: status?.status,
      });
    }

    socket.on("tradeBuy", onBuyStatusUpdate);

    return () => {
      socket.off("tradeBuy", onBuyStatusUpdate);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box p={{ base: "16px", lg: "16px 24px 24px 24px" }}>
      <Flex align="center" gap="21px">
        <CustomText
          text="Limit"
          size="16.6px"
          cursor="pointer"
          color={activeTab === "Limit" ? "#F0B90B" : "extsy.neutral400"}
          onClick={() => {
            clearState();
            setActiveTab("Limit");
          }}
        />

        <CustomText
          text="Market"
          size="16.6px"
          cursor="pointer"
          color={activeTab === "Market" ? "#F0B90B" : "extsy.neutral400"}
          onClick={() => {
            clearState();
            setActiveTab("Market");
          }}
        />
      </Flex>
      <Flex
        mt="11px"
        gap="32px"
        flexDir={{
          base: "column",
          lg: "row",
        }}
      >
        <Flex
          gap="16px"
          flexDir="column"
          justify="space-between"
          width="100%"
          minH={{ lg: "422px" }}
        >
          <Flex gap="16px" flexDir="column">
            <Flex gap="8px" align="center">
              <CustomText text="Avbl" size="14.3px" color="extsy.neutral400" />
              <CustomText
                text={`${
                  formatNumber(baseAssetBalance?.totalBalanceSpot, 6) || 0
                } ${rightCoin}`}
                size="13.2px"
                color="extsy.text2"
              />
              <Image
                src={PlusIcoYellow}
                alt="add"
                height="14px"
                width="14px"
                cursor="pointer"
              />
            </Flex>
            <TradingInput
              label="Price"
              currency={rightCoin}
              value={formData?.price}
              onChange={(e: any) =>
                setFormData({
                  ...formData,
                  price: e.target.value,
                })
              }
              isMarketPrice={activeTab === "Market" ? true : false}
            />
            <TradingInput
              label="Amount"
              currency={leftCoin}
              value={formData?.amountLeft}
              onChange={(e: any) =>
                setFormData({
                  ...formData,
                  amountLeft: e.target.value,
                  totalAmount: e.target.value * formData?.price,
                })
              }
            />
            <CustomTradingSlider
              value={formData?.totalAmount}
              min={0}
              max={baseAssetBalance?.totalBalanceSpot}
              onChange={(value: any) =>
                setFormData({
                  ...formData,
                  amountLeft: Number(
                    formatNumber(value / formData?.price, 6) || 0
                  ),
                  totalAmount: value,
                })
              }
            />
            {activeTab === "Limit" && (
              <TradingInput
                label="Total"
                value={formData?.totalAmount}
                onChange={(e: any) =>
                  setFormData({
                    ...formData,
                    totalAmount: e.target.value,
                    amountLeft: Number(
                      formatNumber(e.target.value / formData?.price, 6) || 0
                    ),
                  })
                }
                currency={rightCoin}
              />
            )}{" "}
            <Flex gap="8px" align="center">
              <CustomText text="Max Buy" size="14px" color="extsy.neutral400" />
              <CustomText
                text={`${formatNumber(
                  Number(baseAssetBalance?.totalBalanceSpot) /
                    Number(formData?.price),
                  6
                )} ${leftCoin}`}
                size="13px"
                color="extsy.text2"
                mt="2px"
              />
            </Flex>
            <Flex gap="8px" align="center">
              <CustomText text="Est Fee" size="14px" color="extsy.neutral400" />
              <CustomText
                text={`${
                  activeTab === "Limit"
                    ? formatNumber(
                        Number(pairSymbolInfo?.[0]?.maker_fee) *
                          Number(formData?.amountLeft),
                        6
                      )
                    : formatNumber(
                        Number(pairSymbolInfo?.[0]?.taker_fee) *
                          Number(formData?.amountLeft),
                        6
                      )
                } ${leftCoin}`}
                size="13px"
                color="extsy.text2"
                mt="2px"
              />
            </Flex>
            {/* {activeTab === "Limit" && (
              <CustomCheckbox
                isChecked={isBuyChecked}
                setChecked={setIsBuyChecked}
                label="TP/SL"
              />
            )} */}
          </Flex>
          <CustomButton
            title={`Buy ${leftCoin}`}
            isLoading={
              createSpotOrderMutation?.isPending && loadingFor === "buy"
            }
            height="48px"
            radius="5px"
            bg="#0ECB81"
            color="extsy.white"
            onClick={handleBuy}
          />
        </Flex>

        {/* -------------- SELL COMPONENT------ */}
        <Flex
          gap="16px"
          flexDir="column"
          justify="space-between"
          width="100%"
          minH={{ lg: "422px" }}
        >
          <Flex flexDir="column" gap="16px">
            <Flex gap="8px" align="center">
              <CustomText text="Avbl" size="14.3px" color="extsy.neutral400" />
              <CustomText
                text={`${formatNumber(
                  assetBalance?.totalBalanceSpot,
                  6
                )} ${leftCoin}`}
                size="13.2px"
                color="extsy.text2"
              />
              <Image
                src={PlusIcoYellow}
                alt="add"
                height="14px"
                width="14px"
                cursor="pointer"
              />
            </Flex>
            <TradingInput
              label="Price"
              currency={rightCoin}
              value={formData?.priceSell}
              onChange={(e: any) =>
                setFormData({
                  ...formData,
                  priceSell: e.target.value,
                })
              }
              isMarketPrice={activeTab === "Market" ? true : false}
            />
            <TradingInput
              label="Amount"
              currency={leftCoin}
              value={formData?.amountLeftSell}
              onChange={(e: any) =>
                setFormData({
                  ...formData,
                  amountLeftSell: e.target.value,
                  totalAmountSell: e.target.value * formData?.priceSell,
                })
              }
            />
            <CustomTradingSlider
              value={formData?.amountLeftSell}
              min={0}
              max={assetBalance?.totalBalanceSpot}
              onChange={(value: any) =>
                setFormData({
                  ...formData,
                  amountLeftSell: value,
                  totalAmountSell: value * formData?.priceSell,
                })
              }
            />
            {activeTab === "Limit" && (
              <TradingInput
                label="Total"
                value={formData?.totalAmountSell}
                onChange={(e: any) =>
                  setFormData({
                    ...formData,
                    totalAmountSell: e.target.value,
                    amountLeftSell: Number(
                      formatNumber(e.target.value / formData?.priceSell, 6) || 0
                    ),
                  })
                }
                currency={rightCoin}
              />
            )}{" "}
            <Flex gap="8px" align="center">
              <CustomText
                text="Max Sell"
                size="14px"
                color="extsy.neutral400"
              />
              <CustomText
                text={`${formatNumber(
                  Number(assetBalance?.totalBalanceSpot) *
                    Number(formData?.priceSell),
                  6
                )} ${rightCoin}`}
                size="13px"
                color="extsy.text2"
                mt="2px"
              />
            </Flex>
            <Flex gap="8px" align="center">
              <CustomText text="Est Fee" size="14px" color="extsy.neutral400" />
              <CustomText
                text={`${
                  activeTab === "Limit"
                    ? formatNumber(
                        Number(pairSymbolInfo?.[0]?.maker_fee) *
                          Number(formData?.totalAmountSell),
                        6
                      )
                    : formatNumber(
                        Number(pairSymbolInfo?.[0]?.taker_fee) *
                          Number(formData?.totalAmountSell),
                        6
                      )
                } ${rightCoin}`}
                size="13px"
                color="extsy.text2"
                mt="2px"
              />
            </Flex>
            {/* {activeTab === "Limit" && (
              <CustomCheckbox
                isChecked={isBuyChecked}
                setChecked={setIsBuyChecked}
                label="TP/SL"
              />
            )} */}
          </Flex>
          <CustomButton
            title={`Sell ${leftCoin}`}
            height="48px"
            radius="5px"
            bg="#F6465D"
            color="extsy.white"
            isLoading={
              createSpotOrderMutation?.isPending && loadingFor === "sell"
            }
            onClick={handleSell}
          />
        </Flex>
      </Flex>
    </Box>
  );
}

export default BuySell;
