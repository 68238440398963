import React, { useEffect, useMemo } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import DashboardLayout from "./layouts/DashboardLayout";
import AuthLayout from "./layouts/AuthLayout";
import ForgotLayout from "./layouts/forgotLayout";
import Login from "./pages/auth/Login";
import ForgotPassword from "./pages/auth/forgotPassword";
import Dashboard from "./pages/dashboard";
import { routes } from "./routes";
import ComingSoon from "./pages/comingSoon";
import { app } from "./config/firebaseConfig";
import { loadStripe } from "@stripe/stripe-js";
import { WalletProvider } from "@tronweb3/tronwallet-adapter-react-hooks";
import { useToast } from "@chakra-ui/react";
import {
  WalletDisconnectedError,
  WalletError,
  WalletNotFoundError,
} from "@tronweb3/tronwallet-abstract-adapter";
import { WalletModalProvider } from "@tronweb3/tronwallet-adapter-react-ui";
import {
  BitKeepAdapter,
  LedgerAdapter,
  OkxWalletAdapter,
  TokenPocketAdapter,
  TronLinkAdapter,
} from "@tronweb3/tronwallet-adapters";
import { tronWallet } from "./config/tronConfig";

export const stripePromise = loadStripe(
  "pk_test_51Q7iaKRuVBSZB56cmQLgBofrE7vjIR35ZOUkJTUdK2L1Zv50SSIwNLfmt3EJzHc8Woro3kL7vq3zh5Mwq9rJjIRW00UkZ5HeMi"
);
function App() {
  const location = useLocation();

  const toast = useToast();

  const onError = (e: WalletError) => {
    if (e instanceof WalletNotFoundError) {
      toast({
        description: e.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } else if (e instanceof WalletDisconnectedError) {
      toast({
        description: e.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } else
      toast({
        description: e.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
  };

  const adapters = useMemo(function () {
    const tronLinkAdapter = new TronLinkAdapter();
    const walletConnectAdapter = tronWallet;
    const ledger = new LedgerAdapter({
      accountNumber: 2,
    });
    const bitKeepAdapter = new BitKeepAdapter();
    const tokenPocketAdapter = new TokenPocketAdapter();
    const okxwalletAdapter = new OkxWalletAdapter();
    return [
      tronLinkAdapter,
      bitKeepAdapter,
      tokenPocketAdapter,
      okxwalletAdapter,
      walletConnectAdapter,
      ledger,
    ];
  }, []);

  // Initialize Firebase
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    app;
  }, []);

  return (
    <WalletProvider onError={onError} adapters={adapters}>
      <WalletModalProvider>
        <Routes>
          {routes.map((route, index) =>
            route?.isPrivate ? (
              <Route key={index} path="/" element={<DashboardLayout />}>
                <Route path={route.path} element={<route.component />} />
                <Route path="*" element={<Dashboard />} />
              </Route>
            ) : location.pathname === "/auth/forgot-password" ||
              location.pathname === "/auth/forgot-password/verify-email" ||
              location.pathname === "/auth/forgot-password/update-password" ? (
              <Route key={index} path="/auth" element={<ForgotLayout />}>
                <Route path={route.path} element={<route.component />} />
                <Route path="*" element={<ForgotPassword />} />
              </Route>
            ) : location.pathname === "/coming-soon" ? (
              <Route path="/coming-soon" element={<ComingSoon />} />
            ) : (
              <Route key={index} path="/auth" element={<AuthLayout />}>
                <Route path={route.path} element={<route.component />} />
                <Route path="*" element={<Login />} />
              </Route>
            )
          )}
        </Routes>
      </WalletModalProvider>
    </WalletProvider>
  );
}

export default App;
