import { Box, Flex, Input } from "@chakra-ui/react";
import React from "react";
import CustomText from "../text/CustomText";
import { IconCaretDownFilled } from "@tabler/icons-react";

function TradingInput({
  label,
  currency,
  isCurrnecySelect,
  value,
  onChange,
  isMarketPrice,
}: any) {
  return (
    <Box pos="relative" w="100%">
      <Box
        display={isMarketPrice ? "none" : "block"}
        pos="absolute"
        zIndex="1"
        top="0"
        left="0"
        mt="16px"
        ml="12px"
      >
        <CustomText text={label} color="extsy.neutral400" />
      </Box>
      <Flex
        pos="absolute"
        zIndex="1"
        top="0"
        right="0"
        mt="18px"
        mr="12px"
        align="center"
        gap="8px"
        display={isMarketPrice ? "none" : "flex"}
      >
        <CustomText text={currency} color="extsy.text2" size="14px" />
        {isCurrnecySelect && (
          <IconCaretDownFilled
            size={20}
            color="#848E9C"
            style={{ marginTop: -2 }}
          />
        )}
      </Flex>
      <Input
        w="100%"
        type="number"
        isReadOnly={isMarketPrice}
        height="48px"
        p="0px 55px 0px 55px"
        borderRadius="5px"
        textAlign={isMarketPrice ? "center" : "right"}
        border="1px solid"
        borderColor="extsy.dark600"
        bg="extsy.dark600"
        color={"extsy.text2"}
        fontSize="14px"
        fontFamily="Metroplis"
        placeholder={isMarketPrice ? "Market Price" : "0.00"}
        value={!isMarketPrice && value}
        onChange={onChange}
        _placeholder={{
          color: "extsy.text2",
          letterSpacing: "0.5px",
        }}
        _hover={{
          borderColor: "extsy.primary",
        }}
        _focus={{
          borderColor: "extsy.primary",
        }}
        _focusVisible={{
          borderColor: "extsy.primary",
        }}
      />
    </Box>
  );
}

export default TradingInput;
