import { Flex } from "@chakra-ui/react";
import React from "react";
import CustomText from "../text/CustomText";

function CustomRadio({ isChecked, setChecked, label }: any) {
  return (
    <Flex align="center" gap="16px">
      <Flex
        height="24px"
        width="24px"
        align="center"
        justify="center"
        border="1px solid"
        borderRadius="50%"
        borderColor={isChecked ? "extsy.primary" : "#4E4949"}
        onClick={setChecked}
      >
        {isChecked && (
          <Flex height="18px" width="18px" borderRadius="50%" bg="extsy.g1" />
        )}
      </Flex>
      <CustomText
        text={label}
        color={isChecked ? "extsy.baseWhite" : ""}
        cursor
      />
    </Flex>
  );
}

export default CustomRadio;
