import { Box, Input, Tooltip } from "@chakra-ui/react";
import { IconAlertCircle } from "@tabler/icons-react";
import React from "react";

function CustomInput({
  type,
  placeholder,
  rightIco,
  invalid,
  errorMsg,
  validation,
  register,
  name,
}: any) {
  return (
    <Box pos="relative">
      <Input
        autoComplete="off"
        type={type}
        placeholder={placeholder}
        borderBottom="1px solid"
        borderColor={invalid ? "extsy.danger" : "extsy.neutral900"}
        borderTop="none"
        borderLeft="none"
        borderRight="none"
        borderRadius="0"
        color="extsy.baseWhite"
        fontSize="16px"
        fontFamily="Metropolis"
        onPaste={(e) => {
          if (type === "password") {
            e.preventDefault();
          }
        }}
        pl="0"
        pr={rightIco || invalid ? "30px" : "auto"}
        _placeholder={{
          color: "extsy.text",
          fontSize: "16px",
          fontWeight: "400",
          fontFamily: "Metropolis",
        }}
        _hover={{
          borderColor: "extsy.secondary",
        }}
        _focusVisible={{
          border: "none",
          borderBottom: "1px solid",
          borderColor: "extsy.secondary",
        }}
        {...register(name, validation)}
      />
      {rightIco && (
        <Box
          pos="absolute"
          right="0"
          top="0"
          mt="8px"
          mr={invalid ? "30px" : "0px"}
        >
          {rightIco}
        </Box>
      )}
      {invalid && (
        <Tooltip label={errorMsg}>
          <Box pos="absolute" right="0" top="0" mt="8px">
            <IconAlertCircle size="18px" color="#ED3D4E" />
          </Box>
        </Tooltip>
      )}
    </Box>
  );
}

export default CustomInput;
