import {
  Box,
  Center,
  Divider,
  Flex,
  Image,
  Skeleton,
  SkeletonText,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import CustomText from "../../components/text/CustomText";
import PrimeIco from "../../assets/images/prime-ico.svg";
import PrimaryButton from "../../components/button/PrimaryButton";
import VerifySuccessImg from "../../assets/images/verify-kyc-icon.svg";
import {
  useGetLoggedInUser,
  useGetSumsubToken,
} from "../../utils/api/auth.api";
import {
  useCreatePaymentIntentForSubscription,
  useGetPaymentMethod,
  useGetSubscriptionPlans,
  useUpdatePaymentMethod,
} from "../../utils/api/settings.api";
import CustomModal from "../../components/modal/customModal";
import { stripePromise } from "../../App";
import SupremeIco from "../../assets/images/superme-ico.svg";
import QuantlaneIco from "../../assets/images/quantlane-ico.svg";
import { Elements } from "@stripe/react-stripe-js";
import StripePayment from "../../components/cards/StripePayment";
import { OnboardModal } from "../../components/modal/onBoardModal";
import SumsubWebSdk from "@sumsub/websdk-react";
import { useQueryClient } from "@tanstack/react-query";
import { formatNumber } from "../../utils/functions/formatNumber";
import { useCustomAlert } from "../../utils/api/alert";
import VerifyIdentity from "../../assets/images/verify-identity.svg";
import CustomButton from "../../components/button/CustomButton";
import SubscriptionFrame from "../../assets/images/subscription-frame.svg";
import VCardInput from "../../components/form/vCardInput";

function WalletPlan() {
  const queryClient: any = useQueryClient();

  const alert = useCustomAlert();

  const { data: loggedInUserData, isLoading } = useGetLoggedInUser();
  const { data: subscriptionPlans } = useGetSubscriptionPlans();
  const { data: paymentInfo, isLoading: paymentInfoLoading } =
    useGetPaymentMethod();
  const createPayment = useCreatePaymentIntentForSubscription();
  const updatePaymentDetails = useUpdatePaymentMethod();
  const [isVerificationModalOpen, setIsVerificationModalOpen] = useState(false);
  const [isVerifySuccessOpen, setIsVerifySuccessOpen] = useState(false);
  const { data: sumsubToken } = useGetSumsubToken();
  const [paymentIntentInfo, setPaymentInfoIntent] = useState<any>(null);
  const [id, setId] = useState("");
  const [nextPlan, setNextPlan] = useState<any>(null);
  const [editCardPayload, setEditCardPayload] = useState<any>({
    card_number: "",
    expiration_date: "",
    card_holder_name: "",
    card_type: "",
  });

  const getPlanByName = (name: any) => {
    return subscriptionPlans?.find((plan: any) => plan?.name === name);
  };

  const createPaymentIntent = async () => {
    try {
      const res = await createPayment?.mutateAsync(id);

      if (res?.data) {
        setPaymentInfoIntent({
          payment: res?.data,
          paymentIntent: res?.data,
        });
        onUpgradeOpen();
        if (typeof window !== "undefined") {
          window?.localStorage?.setItem(
            "paymentInfo",
            JSON.stringify({
              payment: res?.data,
              paymentIntent: res?.data,
            })
          );
        }
      }
      if (res?.error) {
      }
    } catch (error: any) {
      alert?.mutateAsync({
        message: error?.message,
        status: "danger",
      });
    }
  };

  const {
    isOpen: isUpgradeOpen,
    onOpen: onUpgradeOpen,
    onClose: onUpgradeClose,
  } = useDisclosure();

  const {
    isOpen: isEditBankDetailOpen,
    onOpen: oneditBankDetailOpen,
    onClose: onEditBankDetailClose,
  } = useDisclosure();

  const getIco = () => {
    switch (nextPlan?.name) {
      case "Standard":
        return PrimeIco;
      case "Premium":
        return SupremeIco;
      case "Ultra":
        return QuantlaneIco;
      default:
        return PrimeIco;
    }
  };

  useEffect(() => {
    if (!loggedInUserData || !subscriptionPlans) return; // Exit if data is not loaded

    const currentPlanName = loggedInUserData?.user_subscription?.plan?.name;
    if (currentPlanName === "Standard") {
      setNextPlan(getPlanByName("Premium"));
    } else if (currentPlanName === "Premium") {
      setNextPlan(getPlanByName("Ultra"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedInUserData, subscriptionPlans]); // Add dependencies here

  useEffect(() => {
    if (nextPlan) {
      setId(nextPlan?.id);
    }
  }, [nextPlan]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const paymentInfo = window?.localStorage?.getItem("paymentInfo");
      if (paymentInfo) {
        const payment = JSON.parse(paymentInfo);
        setPaymentInfoIntent(payment);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return loggedInUserData?.kyc_status !== "APPROVED" ? (
    <Flex
      justify="space-between"
      p={{ base: "16px", lg: "32px" }}
      borderRadius={"16px"}
      bg="extsy.neutral900"
      minH="333px"
      gap="40px"
      flexDir={{ base: "column", lg: "row" }}
    >
      <Flex flexDir="column" gap="40px" justify="space-between">
        <Flex gap="40px" flexDir="column">
          <CustomText
            text="Subscription Level"
            size="24px"
            weight="700"
            color="extsy.white"
          />
          <CustomText text="To upgrade to Standard Plan, please verify your identity" />
        </Flex>
        <PrimaryButton
          title="Verify Your Identity"
          size="14px"
          maxW="216px"
          onClick={() => {
            setIsVerificationModalOpen(true);
          }}
        />
      </Flex>
      <Image src={VerifyIdentity} alt="verify" />
    </Flex>
  ) : (
    <Flex gap="24px" w="100%" flexDir={{ base: "column", "2xl": "row" }}>
      <Flex
        gap={{ base: "24px", lg: "40px" }}
        p={{ base: "16px", lg: "32px" }}
        flexDir="column"
        borderRadius={{ base: "16px", lg: "24px" }}
        bg="extsy.neutral900"
        border="1px solid"
        borderColor="extsy.neutral900"
        w="100%"
        pos="relative"
        overflow="hidden"
      >
        <Flex align="center" justify="space-between" zIndex="100">
          <CustomText
            text="Added Bank Detail"
            size={{ base: "18px", lg: "24px" }}
            lh={{ lg: "34px" }}
            weight={{ base: "600", lg: "700" }}
            family="Metropolis"
            color="extsy.white"
          />
          {paymentInfoLoading ? (
            <Skeleton h="36px" w="123px" borderRadius="8px" />
          ) : (
            <CustomButton
              title="Edit Bank Detail"
              height="36px"
              bg="extsy.neutral800"
              p="12px"
              size="13px"
              maxW="123px"
              color="extsy.white"
              weight="400"
              onClick={() => {
                setEditCardPayload({
                  card_number: paymentInfo?.data?.card_number,
                  expiration_date: paymentInfo?.data?.expiration_date,
                  card_holder_name: paymentInfo?.data?.card_holder_name,
                  card_type: paymentInfo?.data?.card_type,
                });
                oneditBankDetailOpen();
              }}
            />
          )}
        </Flex>
        <Flex flexDir="column" gap="24px" zIndex="100" h="100%">
          <Flex flexDir="column" gap="8px">
            <CustomText text="Account Holder Name" size="13px" />
            <CustomText
              text={paymentInfo?.data?.card_holder_name || "---"}
              color="extsy.baseWhite"
            />
          </Flex>
          <Divider bg="extsy.neutral800" borderColor="extsy.neutral800" />
          <Flex align="center" justify="space-between">
            <Flex flexDir="column" gap="8px">
              <CustomText text="Account Number" size="13px" />
              {paymentInfoLoading ? (
                <SkeletonText noOfLines={1} w={"120px"} />
              ) : (
                <CustomText
                  text={paymentInfo?.data?.card_number}
                  color="extsy.baseWhite"
                />
              )}
            </Flex>
            <Flex flexDir="column" gap="8px">
              <CustomText text="Card Type" size="13px" />
              {paymentInfoLoading ? (
                <SkeletonText noOfLines={1} w={"60px"} />
              ) : (
                <CustomText
                  text={paymentInfo?.data?.card_type}
                  color="extsy.baseWhite"
                  transform="uppercase"
                />
              )}
            </Flex>
            <Flex flexDir="column" gap="8px">
              <CustomText text="Valid Till" size="13px" />
              {paymentInfoLoading ? (
                <SkeletonText noOfLines={1} w={"60px"} />
              ) : (
                <CustomText
                  text={paymentInfo?.data?.expiration_date}
                  color="extsy.baseWhite"
                />
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        gap={{ base: "24px", lg: "40px" }}
        p={{ base: "16px", lg: "32px" }}
        flexDir="column"
        borderRadius={{ base: "16px", lg: "24px" }}
        bg="extsy.neutral900"
        border="1px solid"
        borderColor="extsy.neutral900"
        w="100%"
        pos="relative"
        overflow="hidden"
      >
        <CustomText
          text="Subscription Level"
          size={{ base: "18px", lg: "24px" }}
          lh={{ lg: "34px" }}
          weight={{ base: "600", lg: "700" }}
          family="Metropolis"
          color="extsy.white"
        />
        <Flex
          align={{ lg: "center" }}
          // justify="space-between"
          gap={{ base: "16px", lg: "24px" }}
          zIndex="100"
          flexDir={{ base: "column", lg: "row" }}
        >
          <CustomText
            text="Your Current Plan:"
            size={{ base: "14px", lg: "18px" }}
            color="extsy.baseWhite"
          />
          <Flex
            display={!loggedInUserData?.user_subscription ? "flex" : "none"}
            justify="flex-end"
          >
            <CustomText
              text="Free"
              size={{ base: "14px", lg: "18px" }}
              color="extsy.baseWhite"
            />
          </Flex>
          <Flex
            gap="16px"
            align="center"
            justify={{ base: "space-between", lg: "flex-start" }}
            display={loggedInUserData?.user_subscription ? "flex" : "none"}
          >
            <Flex
              cursor="pointer"
              bg="rgba(250, 250, 250, 0.10)"
              align="center"
              gap="12px"
              px="16px"
              h={{ base: "36px", lg: "43px" }}
              borderRadius="999px"
            >
              <Image
                src={
                  loggedInUserData?.user_subscription?.plan?.name === "Standard"
                    ? PrimeIco
                    : loggedInUserData?.user_subscription?.plan?.name ===
                      "Premium"
                    ? SupremeIco
                    : QuantlaneIco
                }
                alt="prime"
                height={{ base: "20px", lg: "22.5px" }}
                width={{ base: "23px", lg: "26.4px" }}
              />
              {isLoading ? (
                <SkeletonText noOfLines={1} w={"60px"} />
              ) : (
                <CustomText
                  text={loggedInUserData?.user_subscription?.plan?.name}
                  color="extsy.baseWhite"
                  size={{ base: "16px", lg: "22px" }}
                  cursor="pointer"
                />
              )}
            </Flex>
            <CustomText
              text={
                <>
                  {isLoading ? (
                    <SkeletonText noOfLines={1} w={"60px"} />
                  ) : (
                    <span>
                      ${loggedInUserData?.user_subscription?.plan?.price}
                    </span>
                  )}
                  <span
                    style={{
                      fontWeight: 600,
                      fontSize: "14px",
                      color: "#BAB8B8",
                    }}
                  >
                    /month
                  </span>
                </>
              }
              color="extsy.baseWhite"
              size={{ base: "18px", lg: "24px" }}
              weight="600"
            />
          </Flex>
        </Flex>
        {loggedInUserData?.user_subscription?.plan?.name !== "Ultra" && (
          <>
            {loggedInUserData?.kyc_status !== "APPROVED" && (
              <CustomText text="To upgrade your level, please verify your identity" />
            )}
            <Flex
              align="center"
              gap={{ base: "24px", lg: "40px" }}
              zIndex="100"
            >
              <PrimaryButton
                // isDisabled={loggedInUserData?.kyc_level <= 0}
                isLoading={createPayment?.isPending}
                title={
                  loggedInUserData?.kyc_status !== "APPROVED"
                    ? "Upgrade Kyc Level"
                    : loggedInUserData?.user_subscription?.plan?.name ===
                      "Premium"
                    ? "Upgrade to Ultra"
                    : "Upgrade to Premium"
                }
                size="14px"
                maxW="216px"
                onClick={() => {
                  if (loggedInUserData?.kyc_status !== "APPROVED") {
                    setIsVerificationModalOpen(true);
                  } else {
                    createPaymentIntent();
                  }
                }}
              />

              <Flex
                flexDir="column"
                gap="4px"
                display={loggedInUserData?.user_subscription ? "flex" : "none"}
              >
                <CustomText text="for" color="extsy.baseWhite" />
                <Flex align="center" gap="4px">
                  {isLoading ? (
                    <SkeletonText noOfLines={1} w={"60px"} />
                  ) : (
                    <CustomText
                      text={`$${
                        loggedInUserData?.user_subscription?.plan?.name ===
                        "Standard"
                          ? formatNumber(getPlanByName("Premium")?.price, 2, 2)
                          : formatNumber(getPlanByName("Ultra")?.price, 2, 2)
                      }`}
                      color="extsy.baseWhite"
                      size={{ base: "18px", lg: "24px" }}
                      weight="600"
                    />
                  )}
                  <CustomText text="/month" size="14px" weight="600" />
                </Flex>
              </Flex>
            </Flex>
          </>
        )}
        <Image
          src={SubscriptionFrame}
          alt="subscription"
          pos="absolute"
          right={0}
          bottom={0}
        />
      </Flex>
      <CustomModal
        isOpen={isUpgradeOpen}
        onClose={() => {
          onUpgradeClose();
        }}
        onSubmit={() => {
          onUpgradeClose();
        }}
        headerText={"Purchase Plan"}
        btnText={"Purchase Plan"}
        width={"600px"}
        noFooter={true}
        closeBtntext="Close"
        bgtopBlur={"extsy.bgCardTopBlur"}
      >
        <Box position={"relative"}>
          <Flex
            p={{ base: "24px 16px", lg: "32px 24px" }}
            flexDir="column"
            gap="32px"
          >
            <Flex
              gap="19.2px"
              align="center"
              height={{ base: "56px", lg: "80px" }}
              width="100%"
              justify="center"
              bg="extsy.g1"
              borderRadius="16px"
            >
              <Image
                src={getIco()}
                alt="prime"
                height={{ base: "24px", lg: "36px" }}
                width={{ base: "24px", lg: "42px" }}
              />
              <CustomText
                text={nextPlan?.name}
                color="extsy.bg"
                size={{ base: "24px", lg: "32px" }}
                weight="500"
              />
            </Flex>

            {stripePromise && paymentIntentInfo?.payment?.client_secret && (
              <Box width={"100%"} height={"100%"} minW={"400px"} minH={"100px"}>
                <Elements
                  stripe={stripePromise}
                  options={{
                    clientSecret: paymentIntentInfo?.payment?.client_secret,
                    appearance: {
                      theme: "night",
                      rules: {
                        ".Input": {
                          color: "extsy.baseWhite",
                          backgroundColor: "#383434",
                          border: "none",
                          fontSize: "16px",
                          height: "122px",
                          padding: "19.5px 19px",
                          borderRadius: "16px",
                          boxShadow: "none",
                        },
                        ".Label": {
                          color: "extsy.neutral300",
                          fontSize: "16px",
                          marginBottom: "12px",
                        },
                      },
                    },
                  }}
                >
                  <StripePayment
                    task={nextPlan}
                    onClose={onUpgradeClose}
                    callBackUrl={`${window.location.origin}/settings?wallet`}
                  />
                </Elements>
              </Box>
            )}
          </Flex>
        </Box>
      </CustomModal>
      <OnboardModal
        isOpen={isVerificationModalOpen}
        onClose={() => {
          setIsVerificationModalOpen(false);
        }}
        onSubmit={() => {
          setIsVerifySuccessOpen(true);
        }}
        headerDivider
        title={
          <>
            <CustomText
              text="Complete Verification"
              weight="600"
              size={{ base: "20px", md: "30px" }}
              isTruncated
              maxW={{ base: "86%", sm: "95%" }}
              pb="16px"
              color="extsy.white"
            />
            <CustomText
              text="Start your verification through Sumsub."
              size={{ base: "14px", md: "16px" }}
            />
          </>
        }
        isFooter={false}
      >
        {/* <Center py="40px" borderRadius={"20px"} mb="24px" w={"100%"}> */}
        <SumsubWebSdk
          accessToken={sumsubToken?.token}
          expirationHandler={() => sumsubToken?.token}
          config={{
            lang: "en",
          }}
          // options={options}
          onMessage={async (data: any, payload: any) => {
            // console.log("kyc---------sumsub", data, payload?.reviewStatus);
            if (
              payload?.reviewStatus === "completed" &&
              payload?.reviewResult?.reviewAnswer === "GREEN"
            ) {
              setIsVerificationModalOpen(false);
              setIsVerifySuccessOpen(true);
            }
          }}
          onError={(data: any) => console.log("onError", data)}
        />
        {/* <Image src={SumsubImg} /> */}
        {/* </Center> */}
      </OnboardModal>
      <OnboardModal
        isOpen={isVerifySuccessOpen}
        onClose={() => {
          setIsVerifySuccessOpen(false);
          queryClient.invalidateQueries(["getLoggedInUser"]);
        }}
        onSubmit={() => {
          setIsVerifySuccessOpen(false);
          queryClient.invalidateQueries(["getLoggedInUser"]);
        }}
        headerDivider
        size="sm"
        noHeader={true}
        // btnText="Create a Community"

        isFooter={false}
      >
        <Center
          py="30px"
          px={"20px"}
          borderRadius={"20px"}
          mb="24px"
          gap={"24px"}
          w={"100%"}
          flexDirection={"column"}
        >
          <Image src={VerifySuccessImg} m="24px auto 0px" mb={"24px"} />
          <CustomText
            text="Congratulation!"
            fontFamily="Power Grotesk"
            color="extsy.white"
            size={{ base: "22px", md: "24px" }}
          />
          <CustomText
            text="Your account has been verified successfully."
            size={{ base: "14px", md: "16px" }}
          />
          <PrimaryButton
            width="182px"
            height="56px"
            onClick={() => {
              setIsVerifySuccessOpen(false);
              queryClient.invalidateQueries(["getLoggedInUser"]);
            }}
            title={"Done"}
          />
        </Center>
      </OnboardModal>
      <CustomModal
        isOpen={isEditBankDetailOpen}
        isForm={false}
        onClose={() => {
          onEditBankDetailClose();
        }}
        onSubmit={() => {
          if (
            !editCardPayload.card_number ||
            !editCardPayload.card_holder_name ||
            !editCardPayload.card_type ||
            !editCardPayload.expiration_date
          ) {
            alert?.mutateAsync({
              message: "Please fill all fields",
              status: "danger",
            });
            return;
          }
          updatePaymentDetails
            .mutateAsync(editCardPayload)
            .then((result) => {
              onEditBankDetailClose();
              alert?.mutateAsync({
                message: result?.message,
                status: "success",
              });
            })
            .catch((err) => {
              if (Array.isArray(err?.exception)) {
                alert?.mutateAsync({
                  message: err?.exception[0],
                  status: "danger",
                });
              } else {
                alert?.mutateAsync({
                  message: err?.message,
                  status: "danger",
                });
              }
            });
        }}
        headerText={"Edit Bank Detail"}
        btnText={"Update Detail"}
        width={"600px"}
        closeBtntext="Close"
        bgtopBlur={"extsy.bgCardTopBlur"}
        isLoading={updatePaymentDetails.isPending}
      >
        <Flex
          p={{ base: "16px", lg: "24px" }}
          gap={{ base: "16px", lg: "32px" }}
          flexDir="column"
        >
          <Flex flexDir="column" gap="12px" w="100%">
            <CustomText text="Card Holder Name" size="14px" />
            <VCardInput
              placeholder="Card Holder Name"
              bg="extsy.neutral1000"
              type="text"
              register={() => {}}
              value={editCardPayload?.card_holder_name}
              onChange={(e: any) =>
                setEditCardPayload({
                  ...editCardPayload,
                  card_holder_name: e.target.value,
                })
              }
              name="address"
            />
          </Flex>
          <Flex flexDir="column" gap="12px" w="100%">
            <CustomText text="Card Number" size="14px" />
            <VCardInput
              placeholder="Card No"
              bg="extsy.neutral1000"
              type="text"
              register={() => {}}
              name="address"
              value={editCardPayload?.card_number}
              onChange={(e: any) =>
                setEditCardPayload({
                  ...editCardPayload,
                  card_number: e.target.value,
                })
              }
            />
          </Flex>
          <Flex gap={{ base: "16px", lg: "32px" }}>
            <Flex flexDir="column" gap="12px" w="100%">
              <CustomText text="Card Type" size="14px" />
              <VCardInput
                placeholder="Card Type"
                bg="extsy.neutral1000"
                type="text"
                register={() => {}}
                name="address"
                value={editCardPayload?.card_type}
                onChange={(e: any) =>
                  setEditCardPayload({
                    ...editCardPayload,
                    card_type: e.target.value,
                  })
                }
              />
            </Flex>
            <Flex flexDir="column" gap="12px" w="100%">
              <CustomText text="Expiry" size="14px" />
              <VCardInput
                placeholder="MM - YYYY"
                bg="extsy.neutral1000"
                type="text"
                register={() => {}}
                name="address"
                value={editCardPayload?.expiration_date}
                onChange={(e: any) =>
                  setEditCardPayload({
                    ...editCardPayload,
                    expiration_date: e.target.value,
                  })
                }
              />
            </Flex>
          </Flex>
        </Flex>
      </CustomModal>
    </Flex>
  );
}

export default WalletPlan;
