import {
  Box,
  Flex,
  Grid,
  GridItem,
  Image,
  SkeletonText,
  Switch,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useState } from "react";
import CustomText from "../../components/text/CustomText";
import GoogleIco from "../../assets/images/google-ico.svg";
import {
  IconArrowUpRight,
  IconCopy,
  IconEye,
  IconEyeOff,
} from "@tabler/icons-react";
import CustomModal from "../../components/modal/customModal";
import QRCode from "react-qr-code";
import SecurityImg from "../../assets/images/auth-security-Img.svg";
import CustomVerificationInput from "../../components/form/customVerificationInput";
import CustomInput from "../../components/form/CustomInput";
import CustomButton from "../../components/button/CustomButton";
import { useForm } from "react-hook-form";
import { useChangePassword } from "../../utils/api/auth.api";
import { useEnable2FA, useVerify2FA } from "../../utils/api/settings.api";
import { useQuery } from "@tanstack/react-query";
import { useCustomAlert } from "../../utils/api/alert";

function PrivacySecurity() {
  const changePassword = useChangePassword();
  const enable2FA = useEnable2FA();
  const verify2FA = useVerify2FA();

  const { data: userInfo, isLoading: userInfoLoading }: any = useQuery({
    queryKey: ["getLoggedInUser"],
  });

  const alert = useCustomAlert();

  const {
    isOpen: is2FAOpen,
    onOpen: on2FAOpen,
    onClose: on2FAClose,
  } = useDisclosure();
  const {
    isOpen: isVerifyAuthOpen,
    onOpen: onVerifyAuthOpen,
    onClose: onVerifyAuthClose,
  } = useDisclosure();
  const {
    isOpen: isUpdatePasswordOpen,
    onOpen: onUpdatePasswordOpen,
    onClose: onUpdatePasswordClose,
  } = useDisclosure();

  const [isPasswordVisible, setIsPasswordVisible] = useState<any>({
    currentPassword: false,
    newPassword: false,
    confirmPassword: false,
  });
  const [isAuthSecretCopied, setIsAuthSecretCopied] = useState<boolean>(false);
  const [twoFACode, setTwoFACode] = useState<string>("");

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const togglePassword = (name: string) => {
    setIsPasswordVisible({
      ...isPasswordVisible,
      [name]: !isPasswordVisible[name],
    });
  };

  const handleChangePassword = (payload: any) => {
    if (payload.new_password !== payload.confirm_password) {
      alert.mutate({
        message: "Password and confirm password must be same",
        status: "danger",
      });
      return;
    }
    changePassword
      .mutateAsync(payload)
      .then((result) => {
        alert.mutate({
          message: "Password Updated Successfully",
          status: "success",
        });
        onUpdatePasswordClose();
        reset();
      })
      .catch((error) => {
        const errorMessage = Array.isArray(error?.exception)
          ? error?.exception[0]
          : error?.message;
        alert.mutate({
          message: errorMessage,
          status: "danger",
        });
      });
  };

  const handle2FA = () => {
    if (userInfo?.is_2fa_enabled && userInfo?.is_2fa_verified) {
      // setTwoFACode("");
      onVerifyAuthOpen();
      return;
    }
    if (!userInfo?.is_2fa_enabled && userInfo?.is_2fa_verified) {
      // setTwoFACode("");
      onVerifyAuthOpen();
      return;
    }
    enable2FA
      .mutateAsync({
        status: true,
      })
      .then((result) => {
        on2FAOpen();
      })
      .catch((error) => {
        alert.mutate({
          message: error?.message,
          status: "danger",
        });
      });
  };

  const handleEnable2FA = () => {
    enable2FA
      .mutateAsync({
        status: true,
        otp: twoFACode,
      })
      .then((result) => {
        alert.mutate({
          message: "2FA Enabled Successfully",
          status: "success",
        });
        onVerifyAuthClose();
        setTwoFACode("");
        // on2FAOpen();
      })
      .catch((error) => {
        alert.mutate({
          message: error?.message,
          status: "danger",
        });
      });
  };

  const disable2FA = () => {
    enable2FA
      .mutateAsync({
        status: false,
        otp: twoFACode,
      })
      .then((result) => {
        alert.mutate({
          message: "2FA Disabled Successfully",
          status: "success",
        });
        setTwoFACode("");
        on2FAClose();
        onVerifyAuthClose();
      })
      .catch((error) => {
        alert.mutate({
          message: error?.message,
          status: "danger",
        });
      });
  };

  const handleVerify2FA = () => {
    verify2FA
      .mutateAsync({
        otp: twoFACode,
      })
      .then((result) => {
        if (result?.isValid) {
          alert.mutate({
            message: "Otp Verified Successfully",
            status: "success",
          });
          onVerifyAuthClose();
          if (userInfo?.is_2fa_enabled && userInfo?.is_2fa_verified) {
            on2FAOpen();
          }
        } else {
          alert.mutate({
            message: "OTP is invalid",
            status: "danger",
          });
        }
      })
      .catch((error) => {
        alert.mutate({
          message: error?.message,
          status: "danger",
        });
      });
  };

  return (
    <>
      <Grid
        gap="24px"
        templateRows="repeat(1, 1fr)"
        templateColumns="repeat(12, 1fr)"
      >
        <GridItem colSpan={{ base: 12, lg: 7 }}>
          <Flex
            gap={{ base: "16px", lg: "32px" }}
            p={{ base: "16px", lg: "32px" }}
            flexDir="column"
            borderRadius={{ base: "16px", lg: "24px" }}
            bg="extsy.neutral900"
            border="1px solid"
            borderColor="extsy.neutral1000"
          >
            <CustomText
              text="2FA"
              size="24px"
              lh="34px"
              weight="700"
              family="Metropolis"
              color="extsy.white"
            />
            <Flex align="center" justify="space-between" mt="8px">
              <Flex flexDir="column" gap="8px">
                <CustomText text="Authenticator" color="extsy.baseWhite" />
                <CustomText text="6-digits one-time code" size="12px" />
              </Flex>
              <Switch
                colorScheme="green"
                size="md"
                onChange={handle2FA}
                isChecked={
                  userInfo?.is_2fa_verified && userInfo?.is_2fa_enabled
                }
              />
            </Flex>
            <Box h="1px" bg="extsy.neutral1000" />
            <Flex gap="16px" flexDir="column">
              <CustomText text="Supported App" color="extsy.baseWhite" />
              <Flex align="center" justify="space-between">
                <Flex align="center" gap="10px">
                  <Image src={GoogleIco} alt="google" boxSize="24px" />
                  <CustomText
                    text="Google Authenticator"
                    color="extsy.baseWhite"
                  />
                </Flex>
                <IconArrowUpRight
                  size="24px"
                  color="#E35CAD"
                  cursor={"pointer"}
                  onClick={() =>
                    window.open(
                      "https://support.google.com/accounts/answer/1066447?hl=en&co=GENIE.Platform%3DAndroid&oco=0",
                      "_blank" // To open in a new tab
                    )
                  }
                />
              </Flex>
            </Flex>
          </Flex>
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 5 }}>
          <Flex
            gap={{ base: "16px", lg: "32px" }}
            p={{ base: "16px", lg: "32px" }}
            flexDir="column"
            borderRadius={{ base: "16px", lg: "24px" }}
            bg="extsy.neutral900"
            border="1px solid"
            borderColor="extsy.neutral1000"
            minH={{ lg: "286px" }}
            h="100%"
            justify="space-between"
          >
            <CustomText
              text="Login Info"
              size="24px"
              lh="34px"
              weight="700"
              family="Metropolis"
              color="extsy.white"
            />
            <Flex gap="24px" flexDir="column">
              <Flex
                ps="22px"
                pe="16px"
                h="56px"
                align="center"
                bg="extsy.neutral1000"
                borderRadius="8px"
              >
                {userInfoLoading ? (
                  <SkeletonText w="120px" noOfLines={1} />
                ) : (
                  <CustomText
                    text={userInfo?.email}
                    color="extsy.baseWhite"
                    family="Metropolis"
                    lh="20px"
                  />
                )}
              </Flex>
              <Box height="1px" bg="extsy.neutral1000" />
              {userInfo?.auth_type === "EMAIL" && (
                <>
                  <Flex
                    ps="22px"
                    pe="16px"
                    h="56px"
                    align="center"
                    justify="space-between"
                    bg="extsy.neutral1000"
                    borderRadius="8px"
                  >
                    <CustomText
                      text="**************"
                      color="extsy.baseWhite"
                      family="Metropolis"
                    />
                    <Flex
                      align="center"
                      justify="center"
                      h="32px"
                      w="130px"
                      cursor="pointer"
                      borderRadius="41px"
                      bg="extsy.neutral900"
                      onClick={onUpdatePasswordOpen}
                      display={{ base: "none", lg: "flex" }}
                    >
                      <CustomText
                        text="Change Password"
                        size="12px"
                        lh="22px"
                        family="Metropolis"
                        weight="600"
                        cursor
                      />
                    </Flex>
                  </Flex>
                  <Box display={{ base: "block", lg: "none" }}>
                    <CustomButton
                      title="Change Password"
                      bg="extsy.neutral500"
                      color="extsy.baseWhite"
                      onClick={onUpdatePasswordOpen}
                    />
                  </Box>
                </>
              )}
            </Flex>
          </Flex>
        </GridItem>
      </Grid>
      <CustomModal
        isOpen={is2FAOpen}
        onClose={() => {
          on2FAClose();
          setTwoFACode("");
          setIsAuthSecretCopied(false);
        }}
        onSubmit={() => {
          if (userInfo?.is_2fa_enabled && userInfo?.is_2fa_verified) {
            disable2FA();
          } else {
            on2FAClose();
            setTwoFACode("");
            onVerifyAuthOpen();
          }
        }}
        headerText={"2 Factor Authentication"}
        btnText={
          userInfo?.is_2fa_enabled && userInfo?.is_2fa_verified
            ? "Disable Now"
            : "Enable Now"
        }
        closeBtntext={"Cancel"}
        width={"508px"}
        bgtopBlur="extsy.primary"
      >
        <Flex p={{ base: "16px", lg: "24px" }} flexDir="column" gap="24px">
          <CustomText
            text="Security QR Code"
            size="14px"
            color="extsy.baseWhite"
            align="center"
          />
          <Flex
            bg="extsy.bg"
            height={{ base: "150px", lg: "260px" }}
            width={{ base: "150px", lg: "260px" }}
            borderRadius="16px"
            align="center"
            justify="center"
            p="18px"
            mx="auto"
          >
            <QRCode
              value={userInfo?.auth_url || enable2FA?.data?.authUrl}
              bgColor="transparent"
              fgColor="#fafafa"
            />
          </Flex>
          <CustomText
            text="For more security, enable on authenticator app"
            size="14px"
            color="extsy.baseWhite"
            align="center"
          />
          <Flex mt="8px" gap="10px" flexDir="column">
            <CustomText text="Security Key" size="12px" />

            <Flex
              align="center"
              gap="20px"
              justify="space-between"
              h="56px"
              px="16px"
              bg="extsy.bg"
              borderRadius="12px"
            >
              <CustomText
                text={
                  userInfo?.auth_secret_text || enable2FA?.data?.authSecretText
                }
                color="extsy.baseWhite"
              />
              <IconCopy
                size="24px"
                color={isAuthSecretCopied ? "#3ED598" : "#F6DBD4"}
                cursor="pointer"
                onClick={() => {
                  if (userInfo?.auth_secret_text) {
                    navigator.clipboard.writeText(userInfo?.auth_secret_text);
                    alert.mutate({
                      message: "Copied to clipboard",
                      status: "success",
                    });
                  } else {
                    navigator.clipboard.writeText(
                      enable2FA?.data?.authSecretText
                    );
                    alert.mutate({
                      message: "Copied to clipboard",
                      status: "success",
                    });
                  }
                  setIsAuthSecretCopied(true);
                }}
              />
            </Flex>
          </Flex>
        </Flex>
      </CustomModal>
      <CustomModal
        isOpen={isVerifyAuthOpen}
        onClose={onVerifyAuthClose}
        onSubmit={() => {
          if (!userInfo?.is_2fa_enabled && userInfo?.is_2fa_verified) {
            handleEnable2FA();
          } else {
            handleVerify2FA();
          }
        }}
        headerText={"Verify Authentication"}
        btnText={"Verify Security Code"}
        closeBtntext={"Cancel"}
        isDisabled={twoFACode.length < 6}
        width={"508px"}
        bgtopBlur="extsy.primary"
        isLoading={verify2FA.isPending || enable2FA?.isPending}
      >
        <Flex p="24px" flexDir="column" align="center" gap="32px">
          <Image src={SecurityImg} alt="security" w="139px" h="120px" />
          <Box py="24px">
            <CustomVerificationInput
              placeholder={0}
              w="63px"
              gap="6px"
              value={twoFACode}
              onChange={setTwoFACode}
            />
          </Box>
        </Flex>
      </CustomModal>
      <CustomModal
        isOpen={isUpdatePasswordOpen}
        onClose={() => {
          onUpdatePasswordClose();
          reset();
        }}
        onSubmit={handleSubmit((data) => {
          handleChangePassword(data);
        })}
        headerText={"Update Password"}
        btnText={"Update Password"}
        closeBtntext={"Cancel"}
        width={"600px"}
        bgtopBlur="extsy.primary"
        isLoading={changePassword.isPending}
        isForm
      >
        <Flex
          p={{ base: "16px", lg: "40px 24px" }}
          flexDir="column"
          align="center"
          gap={{ base: "16px", lg: "50px" }}
        >
          <CustomText
            text="Choose a strong & secure alpha-numeric password with special characters minimum length of 8."
            align="center"
          />
          <Flex flexDir="column" gap="24px" w="100%">
            <Flex flexDir="column">
              <CustomText
                text="Current Password"
                color="extsy.neutral500"
                size="12px"
              />
              <CustomInput
                placeholder="**************"
                register={register}
                name="old_password"
                invalid={errors.old_password}
                validation={{
                  required: {
                    value: true,
                    message: "Old Password is required",
                  },
                  minLength: {
                    value: 8,
                    message: "Password must be at least 8 characters",
                  },
                  pattern: {
                    value:
                      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                    message:
                      "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character",
                  },
                }}
                errorMsg={errors.old_password?.message}
                type={isPasswordVisible?.currentPassword ? "text" : "password"}
                rightIco={
                  isPasswordVisible?.currentPassword ? (
                    <IconEyeOff
                      onClick={() => {
                        togglePassword("currentPassword");
                      }}
                      color="#fff"
                      cursor="pointer"
                    />
                  ) : (
                    <IconEye
                      onClick={() => {
                        togglePassword("currentPassword");
                      }}
                      color="#fff"
                      cursor="pointer"
                    />
                  )
                }
              />
            </Flex>
            <Flex flexDir="column">
              <CustomText
                text="New Password"
                color="extsy.neutral500"
                size="12px"
              />
              <CustomInput
                placeholder="**************"
                register={register}
                name="new_password"
                invalid={errors.new_password}
                validation={{
                  required: {
                    value: true,
                    message: "New Password is required",
                  },
                  minLength: {
                    value: 8,
                    message: "Password must be at least 8 characters",
                  },
                  pattern: {
                    value:
                      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                    message:
                      "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character",
                  },
                }}
                errorMsg={errors.new_password?.message}
                type={isPasswordVisible?.newPassword ? "text" : "password"}
                rightIco={
                  isPasswordVisible?.newPassword ? (
                    <IconEyeOff
                      onClick={() => {
                        togglePassword("newPassword");
                      }}
                      color="#fff"
                      cursor="pointer"
                    />
                  ) : (
                    <IconEye
                      onClick={() => {
                        togglePassword("newPassword");
                      }}
                      color="#fff"
                      cursor="pointer"
                    />
                  )
                }
              />
            </Flex>
            <Flex flexDir="column">
              <CustomText
                text="Repeat New Password"
                color="extsy.neutral500"
                size="12px"
              />
              <CustomInput
                placeholder="**************"
                register={register}
                name="confirm_password"
                invalid={errors.confirm_password}
                validation={{
                  required: {
                    value: true,
                    message: "Old Password is required",
                  },
                  minLength: {
                    value: 8,
                    message: "Password must be at least 8 characters",
                  },
                  pattern: {
                    value:
                      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                    message:
                      "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character",
                  },
                }}
                errorMsg={errors.confirm_password?.message}
                type={isPasswordVisible?.confirmPassword ? "text" : "password"}
                rightIco={
                  isPasswordVisible?.confirmPassword ? (
                    <IconEyeOff
                      onClick={() => {
                        togglePassword("confirmPassword");
                      }}
                      color="#fff"
                      cursor="pointer"
                    />
                  ) : (
                    <IconEye
                      onClick={() => {
                        togglePassword("confirmPassword");
                      }}
                      color="#fff"
                      cursor="pointer"
                    />
                  )
                }
              />
            </Flex>
          </Flex>
        </Flex>
      </CustomModal>
    </>
  );
}

export default PrivacySecurity;
