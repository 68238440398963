import {
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import React from "react";
import CrossIcon from "../../assets/images/cross.svg";
import CloseButton from "../button/closeButton";
import PrimaryButton from "../button/PrimaryButton";
import { useTranslation } from "react-i18next";

interface CustomModalProps {
  isOpen: boolean;
  isLoading?: boolean;
  onClose: () => void;
  headerText?: string;

  onSubmit: () => void;
  children: any;
  footerText?: string;
  closeText?: string;
  btnDirection?: boolean;
  btnBgColor?: string;
  btnColor?: string;
  btnBorderColor?: string;
  isDisabled?: boolean;
  noFooter?: boolean;
  noHeader?: boolean;
  noCloseFooter?: boolean;
  height?: string;
  padding?: string;
  btnText?: string;
  closeBtntext?: string;
  width?: string;
  btnContinueWidth?: any;
  btnCloseWidth?: any;
  bgtopBlur?: string;
  bg?: string;
  borderColor?: string;
  bgFilter?: string;
  blurPos?: string;
  blurTop?: string;
  isForm?: boolean;
  noBox?: boolean;
}

const CustomModal: React.FC<CustomModalProps> = ({
  isOpen,
  onClose,
  noHeader,
  headerText,
  isLoading,
  onSubmit,
  children,
  isDisabled,
  noFooter,
  noCloseFooter,
  padding,
  btnText,
  closeBtntext,
  width,
  btnContinueWidth,
  btnCloseWidth,
  bg,
  borderColor,
  isForm,
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      // size={["xs", "sm", "md", "lg"]}
      isCentered
    >
      <ModalOverlay backdropFilter={"blur(0.9px)"} />
      <form onSubmit={onSubmit}>
        <ModalContent
          bg={bg || "extsy.neutral900"}
          color="extsy.baseWhite"
          border="1px solid"
          borderColor={borderColor || "extsy.neutral800"}
          padding={padding ? padding : ""}
          // h={height?height:"420px"}
          minW={{ base: "auto", lg: width || "600px" }}
          borderRadius="16px"
          pos="relative"
          overflow="hidden"
          mx="16px"
          // backdropFilter={bgFilter || "blur(17px)"}
        >
          {noHeader ? (
            <></>
          ) : (
            <ModalHeader
              padding={"16px 24px"}
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              borderBottom={`1px solid `}
              borderColor={"extsy.neutral900"}
            >
              <Text
                fontSize={{ base: "16px", lg: "18px" }}
                fontWeight={"700"}
                lineHeight={"22px"}
                letterSpacing={"0.08px"}
                fontFamily={"Metropolis"}
              >
                {headerText}
              </Text>
              <Image
                src={CrossIcon}
                cursor={"pointer"}
                onClick={onClose}
                w={"18px"}
              />
            </ModalHeader>
          )}
          <ModalBody padding="0px">{children}</ModalBody>
          {noFooter ? (
            <></>
          ) : (
            <ModalFooter
              display={"flex"}
              padding={"0px"}
              borderTop={`1px solid `}
              borderColor={"extsy.neutral900"}
            >
              <Flex
                padding={"16px 24px "}
                width={"100%"}
                gap={"16px"}
                justifyContent={"center"}
                alignItems={"center"}
                flexDir={{ base: "column", lg: "row" }}
              >
                {!noCloseFooter && (
                  <CloseButton
                    title={closeBtntext || t("remindMeLater")}
                    maxW={btnCloseWidth || "100%"}
                    onClick={onClose}
                    order={{ base: "2", lg: "1" }}
                    height={{ base: "48px", lg: "56px" }}
                    isDisabled={isLoading}
                  />
                )}
                <PrimaryButton
                  title={btnText || t("confirmNow")}
                  padding="10px 32px "
                  maxW={btnContinueWidth || "100%"}
                  onClick={!isForm ? onSubmit : () => {}}
                  type={isForm ? "submit" : "button"}
                  order={{ base: "1", lg: "2" }}
                  height={{ base: "48px", lg: "56px" }}
                  isLoading={isLoading}
                  isDisabled={isDisabled}
                />
              </Flex>
            </ModalFooter>
          )}
        </ModalContent>
      </form>
    </Modal>
  );
};

export default CustomModal;
