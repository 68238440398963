import { Box, Flex } from "@chakra-ui/react";
import { IconInfoCircleFilled } from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
import CustomText from "../../../components/text/CustomText";
import CustomSelect from "../../../components/form/CustomSelect";
import CustomArea from "../../../components/form/CustomTextArea";
import { useGetCountries, useGetTermsTags } from "../../../utils/api/p2p.api";
import { CoinOption } from "../../../utils/types/types";

function Step3({ adData, setAdData }: any) {
  const { data: countries, isLoading: countriesLoading } = useGetCountries();
  const { data: tagsData, isLoading: tagsLoading } = useGetTermsTags();

  const [region, setRegion] = useState<CoinOption | undefined>(undefined);
  const [tag, setTag] = useState<CoinOption | undefined>(undefined);

  useEffect(() => {
    if (countries && countries.length > 0) {
      if (!region) {
        const firstChain = {
          value: countries[0]?.id,
          label: countries[0]?.name,
        };
        setRegion(firstChain);
      }
    }
    if (tagsData && tagsData.length > 0) {
      if (!tag) {
        const firstTag = {
          value: tagsData[0]?.id,
          label: tagsData[0]?.tag,
        };
        setTag(firstTag);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countries, tag]);
  return (
    <Flex flexDir="column" gap={{ base: "16px", md: "40px" }}>
      <Flex
        p={{ base: "16px", md: "24px" }}
        gap="10px"
        borderRadius="16px"
        bg="extsy.neutral900"
        flexDir={{ base: "column", md: "row" }}
      >
        <Box>
          <IconInfoCircleFilled size={20} color="#E35CAD" />
        </Box>
        <Flex flexDir="column" gap="8px">
          <CustomText
            text="Please ensure that you comply with p2p rules to avoid account suspension or expulsion from the Extsy Merchant Program.espacially:"
            color="extsy.baseWhite"
          />
          <CustomText
            transform="lowercase"
            color="extsy.baseWhite"
            text="1. IF YOU REQUIRE TAKER’S DOCUMENT FOR VERIFICATION, IT’S NECESSARY TO INDICATE THE REQUIREMENT IN THE REMARK’S SECTION OF YOUR ADVERTISEMENT"
          />
          <CustomText
            transform="lowercase"
            color="extsy.baseWhite"
            text="2. IMPOSING EXTRA FEE ON TAKERS IS NOT ALLOWED IN ALL SCENARIOS"
          />
        </Flex>
      </Flex>
      <Flex
        gap={{ base: "16px", md: "32px" }}
        flexDir={{
          base: "column",
          md: "row",
        }}
      >
        <Flex flexDir="column" gap="8px" w="100%">
          <CustomText text="Terms Tags (Optional)" size="14px" />

          <CustomSelect
            placeholder={"Select Tag"}
            // value={tag?.value}
            options={tagsData?.map((coin: any) => ({
              value: coin?.id,
              label: coin?.tag,
            }))}
            onSelect={(option: CoinOption) => {
              setTag(option);

              // Initialize `tags` array if not already in `adData`
              const currentTags = adData.tags || [];

              // Check if selected tag is already in the array
              const isSelected = currentTags.includes(option.value);

              setAdData((prevAdData: any) => ({
                ...prevAdData,
                tags: isSelected
                  ? currentTags.filter((tag: string) => tag !== option.value) // Remove if selected
                  : [...currentTags, option.value], // Add if not selected
              }));
            }}
            isLoading={tagsLoading}
          />

          <CustomText text="Select up to 3 tags." size="14px" />

          {/* Display selected tags */}
          <Flex wrap="wrap" gap="8px" mt="4">
            {adData?.tags?.map((tagId: string) => {
              const tagLabel = tagsData.find(
                (tag: any) => tag.id === tagId
              )?.tag;
              return (
                <Box
                  key={tagId}
                  px="8px"
                  py="4px"
                  bg="gray.200"
                  borderRadius="md"
                  fontSize="14px"
                  color="gray.800"
                >
                  {tagLabel}
                </Box>
              );
            })}
          </Flex>
        </Flex>
        <Flex flexDir="column" gap="8px" w="100%">
          <CustomText text="Select Region's Availability" size="14px" />

          <CustomSelect
            placeholder={"Select Regions"}
            // value={tag?.value}
            options={countries?.map((coin: any) => ({
              value: coin?.id,
              label: coin?.name,
            }))}
            onSelect={(option: CoinOption) => {
              setRegion(option);

              // Initialize `tags` array if not already in `adData`
              const currentRegions = adData.regions || [];

              // Check if selected tag is already in the array
              const isSelected = currentRegions.includes(option.value);

              setAdData((prevAdData: any) => ({
                ...prevAdData,
                regions: isSelected
                  ? currentRegions.filter((tag: string) => tag !== option.value) // Remove if selected
                  : [...currentRegions, option.value], // Add if not selected
              }));
            }}
            isLoading={countriesLoading}
          />
          {/* Display selected tags */}
          <Flex wrap="wrap" gap="8px" mt="4">
            {adData?.regions?.map((regionId: string) => {
              const regionLable = countries.find(
                (region: any) => region.id === regionId
              )?.name;
              return (
                <Box
                  key={regionId}
                  px="8px"
                  py="4px"
                  bg="gray.200"
                  borderRadius="md"
                  fontSize="14px"
                  color="gray.800"
                >
                  {regionLable}
                </Box>
              );
            })}
          </Flex>
        </Flex>
      </Flex>
      <Flex
        gap={{ base: "16px", md: "32px" }}
        flexDir={{
          base: "column",
          md: "row",
        }}
      >
        <Flex flexDir="column" gap="8px" w="100%">
          <CustomText text="Terms Tags (Optional)" size="14px" />
          <CustomArea
            placeholder="15 mins"
            value={adData?.remarks}
            onChange={(e: any) =>
              setAdData({ ...adData, remarks: e.target.value })
            }
          />
        </Flex>
        <Flex flexDir="column" gap="8px" w="100%">
          <CustomText text="Auto Reply (Optional)" size="14px" />
          <CustomArea
            placeholder="15 mins"
            value={adData?.autoReply}
            onChange={(e: any) =>
              setAdData({ ...adData, autoReply: e.target.value })
            }
          />
        </Flex>
      </Flex>
    </Flex>
  );
}

export default Step3;
