import {
  Box,
  Button,
  Center,
  Circle,
  Divider,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Image,
  SkeletonText,
  Stack,
  Step,
  StepIndicator,
  Stepper,
  StepSeparator,
  StepStatus,
  useDisclosure,
  useMediaQuery,
  useSteps,
  VStack,
} from "@chakra-ui/react";
import { IconInfoCircleFilled, IconX } from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
import CustomText from "../../../components/text/CustomText";
import ExtsyGuard from "../../../assets/images/extsy-guard.svg";
import GradientText from "../../../components/text/GradientText";
import CustomButton from "../../../components/button/CustomButton";
import PrimaryButton from "../../../components/button/PrimaryButton";
import ActiveStepperIcon from "../../../assets/images/stepper-active-circle.svg";
import ChatIcon from "../../../assets/images/appeal.svg";
import AppealIcon from "../../../assets/images/chat-img.svg";
import WarningIcon from "../../../assets/images/warning-icon.svg";
import CustomModal from "../../../components/modal/customModal";
import CheckedBox from "../../../assets/images/checked-box.svg";
import UnCheckedBox from "../../../assets/images/unchecked-box.svg";
import JpgIcon from "../../../assets/images/jpg-icon.svg";
import PdfIcon from "../../../assets/images/pdf-icon.svg";
import ImageAddIcon from "../../../assets/images/img-add-icon.svg";
import {
  useCancelOrderItem,
  useMarkAsPaid,
  useOrderDetails,
  useReleaseCoin,
} from "../../../utils/api/p2p.api";
import { useCustomAlert } from "../../../utils/api/alert";

import CustomSelect from "../../../components/form/CustomSelect";
import CustomArea from "../../../components/form/CustomTextArea";
import { useDropzone } from "react-dropzone";
import { useNavigate } from "react-router-dom";
import ChatModule from "./ChatModule";

function OrderDetails({ isOpen, onClose, onOpen = () => {}, orderData }: any) {
  const navigate = useNavigate();
  const { activeStep } = useSteps({
    index: 2,
    count: 3,
  });
  const {
    isOpen: isCancelOrderOpen,
    onOpen: onOpenCancelOrder,
    onClose: onCloseCancelOrder,
  } = useDisclosure();
  const {
    isOpen: isTipsOpen,
    onOpen: onOpenTips,
    onClose: onCloseTips,
  } = useDisclosure();
  const {
    isOpen: isInitAppealOpen,
    onOpen: onOpenInitAppeal,
    onClose: onCloseInitAppeal,
  } = useDisclosure();
  const [isLessThan992] = useMediaQuery("(max-width: 992px)");
  const {
    data: orderDetailData,
    isLoading: orderDetailLoading,
    refetch,
  } = useOrderDetails(orderData?.id);
  const markAsPaidMutation = useMarkAsPaid();
  const releaseCoinsMutation = useReleaseCoin();
  const cancleOrderItemMutation = useCancelOrderItem();
  const alert = useCustomAlert();

  const [selectedReason, setSelectedReason] = useState("");
  const [reasonText, setReasonText] = useState(
    "I have not paid the seller / have received seller’s refund"
  );

  const [appealCreated, setAppealCreated] = useState(false);
  const [collapses, setCollapses] = useState<any>({
    orderDetails: true,
    chat: true,
  });
  const [selectedOption, setSelectedOption] = useState<any>(null); // Initialize selectedOption as null

  const options = [
    { value: "extra_payment", label: "I paid extra money to the seller" },
    { value: "wrong_item", label: "Received the wrong item" },
    { value: "item_not_as_described", label: "Item not as described" },
    { value: "item_damaged", label: "Item arrived damaged" },
  ];

  const BUYER_REASONS = [
    "I do not want to trade anymore.",
    "I do not meet the requirements of the advertiser’s trading terms and condition",
    "There is technical error with the payment platform",
    "I have not paid but clicked ‘Transfered’",
    "Other reason",
  ];
  const SELLER_REASONS = [
    "Seller is asking for extra fee",
    "Problem with seller’s payment method result in unsuccessful payments.",
  ];

  const handleCancelOrder = async () => {
    try {
      const payload = {
        dueTo: selectedReason,
        reason: reasonText,
        orderItemID: orderDetailData?.id,
      };
      cancleOrderItemMutation?.mutateAsync(payload);
      alert.mutate({
        message: "Order canceled successfully",
        status: "success",
      });
      onCloseCancelOrder();
    } catch (error: any) {
      alert.mutate({
        message: error?.message,
        status: "danger",
      });
    }
  };

  useEffect(() => {
    if (isLessThan992) {
      setCollapses({
        orderDetails: true,
        chat: false,
      });
    } else {
      setCollapses({
        orderDetails: true,
        chat: true,
      });
    }
  }, [isLessThan992]);

  useEffect(() => {
    if (orderData) {
      refetch();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderData]);

  return (
    <>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent
          bg="extsy.neutral1000"
          p={{ base: "16px", lg: "24px" }}
          maxW="1045px"
          w="100%"
          overflowY="auto"
        >
          <Flex flexDir="column" gap="16px" h="100%">
            <Flex h="32px" align="center">
              <IconX
                size="24px"
                color="#FAFAFA"
                cursor="pointer"
                onClick={onClose}
              />
            </Flex>
            <Flex
              gap="24px"
              h="100%"
              align={{ base: "center", lg: "flex-start" }}
              flexDir={{
                base: "column",
                lg: "row",
              }}
            >
              <Flex
                p="16px"
                borderRadius="16px"
                bg="extsy.neutral800"
                display={
                  collapses?.orderDetails
                    ? "none"
                    : { base: "block", lg: "none" }
                }
                w="100%"
                onClick={() =>
                  setCollapses({
                    chat: false,
                    orderDetails: !collapses?.orderDetails,
                  })
                }
              >
                <CustomText
                  text="Order Details"
                  weight="600"
                  color="extsy.baseWhite"
                />
              </Flex>
              <Flex
                p={{ base: "16px", lg: "25px" }}
                bg="extsy.neutral900"
                borderRadius="16px"
                boxShadow="0px 2px 10px 0px rgba(0, 0, 0, 0.10)"
                maxW={{ lg: "425px" }}
                w="100%"
                h={{ lg: "100%" }}
                flexDir="column"
                justify="space-between"
                gap="12px"
                display={collapses?.orderDetails ? "flex" : "none"}
              >
                <Box>
                  <CustomText
                    text="Order Details"
                    size="24px"
                    weight="600"
                    color="extsy.baseWhite"
                  />
                  <Flex flexDir="column" gap="15px" mt="15px" py="25px">
                    <Flex align="center" gap="8px">
                      <CustomText
                        text="Order Created, Pay the seller with in"
                        weight="600"
                        color="extsy.baseWhite"
                      />
                      {orderDetailLoading ? (
                        <SkeletonText w="60px" h="5px" my="5px" noOfLines={1} />
                      ) : (
                        <GradientText
                          text={`${
                            orderDetailData?.o_paymentTimeInMin || "00"
                          }:00`}
                          weight="600"
                          bg="extsy.g1"
                        />
                      )}
                    </Flex>
                    <Flex align="flex-start" gap="15px">
                      <Image
                        src={ExtsyGuard}
                        alt="extsy-guard"
                        w="15px"
                        h="20px"
                      />
                      <CustomText
                        text="Extsy is holding the seller’s crpto in the escrow account"
                        weight="600"
                        size="14px"
                      />
                    </Flex>
                    <Flex align="center" gap="15px">
                      <Image
                        src={ExtsyGuard}
                        alt="extsy-guard"
                        w="15px"
                        h="20px"
                      />
                      <CustomText
                        text="Extsy 24/7 customer support"
                        weight="600"
                        size="14px"
                      />
                    </Flex>
                  </Flex>
                  <Flex my="25px" py="25px" maxH="400px" overflowY={"scroll"}>
                    <Stepper
                      index={activeStep}
                      orientation="vertical"
                      gap="0"
                      w="100%"
                      maxW="375px"
                      ml={{ base: "-10px", lg: "auto" }}
                    >
                      <Step key={1}>
                        <StepIndicator
                          zIndex="10"
                          sx={{
                            "[data-status=complete] &": {
                              background: "transparent",
                              borderColor: "transparent",
                            },
                            "[data-status=active] &": {
                              background: "transparent",
                              borderColor: "transparent",
                            },
                            "[data-status=incomplete] &": {
                              background: "transparent",
                              borderColor: "transparent",
                            },
                          }}
                        >
                          <StepStatus
                            complete={
                              <Image src={ActiveStepperIcon} alt="complete" />
                            }
                            incomplete={
                              <Image src={ActiveStepperIcon} alt="complete" />
                            }
                            active={
                              <Image src={ActiveStepperIcon} alt="complete" />
                            }
                          />
                        </StepIndicator>

                        <Box
                          flexShrink="0"
                          mt="10px"
                          minW={{ base: "250px", sm: "339px" }}
                          mb="25px"
                        >
                          <CustomText
                            text="Order Created"
                            weight="600"
                            color="extsy.baseWhite"
                          />
                          <Flex
                            p="16px"
                            borderRadius="16px"
                            border="1px solid"
                            borderColor="extsy.neutral800"
                            mt="15px"
                            flexDir="column"
                            gap="15px"
                          >
                            <Flex
                              align="center"
                              justify="space-between"
                              gap="12px"
                              w="100%"
                            >
                              <CustomText
                                text="Fiat Amount"
                                size="14px"
                                weight="600"
                              />
                              <GradientText
                                text={`$ ${orderDetailData?.fiat_amount}`}
                                bg="extsy.g1"
                                size="14px"
                              />
                            </Flex>
                            <Flex
                              align="center"
                              justify="space-between"
                              gap="12px"
                              w="100%"
                            >
                              <CustomText
                                text="Amount"
                                size="14px"
                                weight="600"
                              />
                              <GradientText
                                text={`${orderDetailData?.amount} USDT`}
                                bg="extsy.g1"
                                size="14px"
                              />
                            </Flex>
                            <Flex
                              align="center"
                              justify="space-between"
                              gap="12px"
                              w="100%"
                            >
                              <CustomText
                                text="Provider"
                                size="14px"
                                weight="600"
                              />
                              <GradientText
                                text="Cybil"
                                bg="extsy.g1"
                                size="14px"
                              />
                            </Flex>
                          </Flex>
                        </Box>

                        <StepSeparator
                          style={{
                            width: 0,
                            border: "1px solid",
                            borderColor: "#F0C2DB",
                            top: 0,
                            maxHeight: "100%",
                            marginTop: "10px",
                            background: "transparent",
                          }}
                        />
                      </Step>
                      <Step key={2}>
                        <StepIndicator
                          sx={{
                            "[data-status=complete] &": {
                              background: "transparent",
                              borderColor: "transparent",
                            },
                            "[data-status=active] &": {
                              background: "transparent",
                              borderColor: "transparent",
                            },
                            "[data-status=incomplete] &": {
                              background: "transparent",
                              borderColor: "transparent",
                            },
                          }}
                          zIndex="10"
                        >
                          <StepStatus
                            complete={
                              <Image src={ActiveStepperIcon} alt="complete" />
                            }
                            incomplete={
                              <Image src={ActiveStepperIcon} alt="complete" />
                            }
                            active={
                              <Image src={ActiveStepperIcon} alt="complete" />
                            }
                          />
                        </StepIndicator>
                        <Box
                          flexShrink="0"
                          mt="10px"
                          minW={{ base: "250px", sm: "339px" }}
                          mb="25px"
                        >
                          <CustomText
                            text={`Payment Method: Bank Account`}
                            weight="600"
                            color="extsy.baseWhite"
                          />
                          <CustomText
                            text="Transfer the funds to the seller’s account provided below."
                            mt="8px"
                            family="Metropolis"
                            size="12px"
                          />
                          <Flex
                            p="16px"
                            borderRadius="16px"
                            border="1px solid"
                            borderColor="extsy.neutral800"
                            mt="15px"
                            flexDir="column"
                            gap="15px"
                          >
                            <Flex
                              align="center"
                              justify="space-between"
                              gap="12px"
                              w="100%"
                            >
                              <CustomText
                                text={"Full Name"}
                                size="14px"
                                weight="600"
                              />
                              <GradientText
                                text={`${orderDetailData?.bank_info?.[0]?.fullName}`}
                                bg="extsy.g1"
                                size="14px"
                              />
                            </Flex>
                            <Flex
                              align="center"
                              justify="space-between"
                              gap="12px"
                              w="100%"
                            >
                              <CustomText
                                text="Bank"
                                size="14px"
                                weight="600"
                              />
                              <GradientText
                                text={orderDetailData?.bank_info?.[0]?.bankName}
                                bg="extsy.g1"
                                size="14px"
                              />
                            </Flex>
                            <Flex
                              align="center"
                              justify="space-between"
                              gap="12px"
                              w="100%"
                            >
                              <CustomText
                                text="Bank Account"
                                size="14px"
                                weight="600"
                              />
                              <GradientText
                                text={
                                  orderDetailData?.bank_info?.[0]?.bankAccount
                                }
                                bg="extsy.g1"
                                size="14px"
                              />
                            </Flex>
                            <Flex
                              align="center"
                              justify="space-between"
                              gap="12px"
                              w="100%"
                            >
                              <CustomText
                                text="Bank of Deposit"
                                size="14px"
                                weight="600"
                              />
                              <GradientText
                                text="Bank of America"
                                bg="extsy.g1"
                                size="14px"
                              />
                            </Flex>
                          </Flex>
                        </Box>
                        <StepSeparator
                          style={{
                            width: 0,
                            border: orderDetailData?.isMarkedAsPaid
                              ? "1px solid"
                              : "1px dashed",
                            borderColor: orderDetailData?.isMarkedAsPaid
                              ? "#F0C2DB"
                              : "#908B8B",
                            top: 0,
                            maxHeight: "100%",
                            marginTop: "10px",
                            background: "transparent",
                          }}
                        />{" "}
                      </Step>
                      <Step key={3}>
                        <StepIndicator
                          sx={{
                            "[data-status=complete] &": {
                              background: "transparent",
                              borderColor: "transparent",
                            },
                            "[data-status=active] &": {
                              background: "transparent",
                              borderColor: "transparent",
                            },
                            "[data-status=incomplete] &": {
                              background: "transparent",
                              borderColor: "transparent",
                            },
                          }}
                        >
                          <StepStatus
                            complete={
                              <Image src={ActiveStepperIcon} alt="complete" />
                            }
                            incomplete={<Circle bg="#626262" size="20px" />}
                            active={
                              <Image src={ActiveStepperIcon} alt="complete" />
                            }
                          />
                        </StepIndicator>

                        <Box
                          flexShrink="0"
                          mt="10px"
                          minW={{ base: "250px", sm: "339px" }}
                        >
                          <CustomText
                            text="Notify Seller"
                            weight="600"
                            color="extsy.baseWhite"
                          />
                          <Box mt="15px" maxW="339px">
                            <CustomText
                              text="After payment, remember to click the ‘Transferred, Notify Seller’ button to facilitate the crypto release by the seller."
                              family="Metropolis"
                              size="12px"
                            />
                          </Box>
                        </Box>
                      </Step>
                    </Stepper>
                  </Flex>
                  {!appealCreated && (
                    <Center
                      p={"16px"}
                      cursor={"pointer"}
                      gap={"10px"}
                      bg={"#221F1F"}
                      onClick={() => {
                        // onClose();
                        onClose();
                        onOpenTips();
                      }}
                    >
                      <CustomText
                        text="Transaction Issue I want to appeal"
                        color="#F0C2DB"
                        size="14px"
                        weight="500"
                        lh="22px"
                        cursor="pointer"
                      />
                    </Center>
                  )}
                </Box>
                {appealCreated ? (
                  <Stack
                    p={"24px"}
                    gap={"24px"}
                    borderRadius={"16px"}
                    bg={"#221F1F"}
                  >
                    <Flex justify={"space-between"} align={"center"}>
                      <CustomText
                        text="Pending Response"
                        color="#EDA145"
                        size="12px"
                        weight="500"
                        lh="22px"
                      />
                      <CustomText
                        text="Remaining Time: 01:20:27"
                        color="#FAFAFA"
                        size="10px"
                        weight="500"
                        lh="22px"
                      />
                    </Flex>
                    <Stack gap={"12px"}>
                      <CustomText
                        size="12px"
                        color="#BAB8B8"
                        lh="130%"
                        text="1. if both parties have reached an agreement. you can cancel the appeal and proceed to complete the trade."
                      />
                      <CustomText
                        size="12px"
                        color="#BAB8B8"
                        lh="130%"
                        text="2. if the respondent does not respond in time, CS will get involved and arbitrate."
                      />
                    </Stack>
                    <Flex align={"center"} gap={"8px"}>
                      <CustomButton
                        title="Cancel Appeal"
                        padding="16px"
                        radius="8px"
                        bg="#383434"
                        size="12px"
                        height="32px"
                        color="#BAB8B8"
                      />
                      <CustomButton
                        title="View Appeal Process"
                        padding="16px"
                        radius="8px"
                        bg="#383434"
                        height="32px"
                        size="12px"
                        color="#F0C2DB"
                        onClick={() => navigate("/p2p/appeal-detail")}
                      />
                    </Flex>
                  </Stack>
                ) : (
                  <>
                    {!orderDetailData?.isMarkedAsPaid && (
                      <Flex align="center" gap={{ base: "8px", lg: "16px" }}>
                        <CustomButton
                          size="14px"
                          color="extsy.baseWhite"
                          bg="extsy.neutral800"
                          title="Cancel Order"
                          height="48px"
                          onClick={onOpenCancelOrder}
                        />
                        <PrimaryButton
                          title={
                            orderDetailData?.user_type === "Seller"
                              ? "Mark As Paid"
                              : "Release Coins"
                          }
                          size="14px"
                          isDisabled={markAsPaidMutation?.isPending}
                          isLoading={markAsPaidMutation?.isPending}
                          height="48px"
                          onClick={async () => {
                            if (orderDetailData?.user_type === "Seller") {
                              try {
                                const formData = {
                                  orderItemID: orderData?.id,
                                };
                                await markAsPaidMutation.mutateAsync(formData);
                                alert.mutate({
                                  status: "success",
                                  message: "Marked As Paid",
                                });
                                onClose();
                              } catch (error: any) {
                                alert.mutate({
                                  status: "danger",
                                  message: error?.message,
                                });
                              }
                            } else {
                              try {
                                await releaseCoinsMutation.mutateAsync({
                                  orderItemID: orderData?.id,
                                });
                                alert.mutate({
                                  status: "success",
                                  message: "Coins Released",
                                });
                                onClose();
                              } catch (error: any) {
                                alert.mutate({
                                  status: "danger",
                                  message: error?.message,
                                });
                              }
                            }
                          }}
                        />
                      </Flex>
                    )}
                  </>
                )}
              </Flex>
              <Box
                w="1px"
                bg="extsy.bg"
                display={{ base: "none", lg: "block" }}
              />
              <ChatModule
                collapses={collapses}
                orderDetailData={orderDetailData}
                setCollapses={setCollapses}
              />
            </Flex>
          </Flex>
        </DrawerContent>
      </Drawer>
      <CustomModal
        isOpen={isCancelOrderOpen}
        onClose={() => {
          onCloseCancelOrder();
        }}
        onSubmit={() => {
          handleCancelOrder();
        }}
        noCloseFooter
        headerText={"Cancel Order"}
        btnText={"Confirm Cancellation"}
        width={"600px"}
        isLoading={cancleOrderItemMutation?.isPending}
        isDisabled={selectedReason === "" || cancleOrderItemMutation?.isPending}
      >
        <Flex p="32px 24px" flexDir="column" gap="32px">
          <Flex
            bg="rgba(34, 31, 31, 0.30)"
            p="16px"
            border="1px solid"
            borderColor="extsy.neutral800"
            borderRadius="16px"
            gap="10px"
            align="flex-start"
          >
            <Box>
              <IconInfoCircleFilled size="20px" color="#E35CAD" />
            </Box>
            <CustomText
              text={
                <>
                  <span>
                    If you voluntarily cancel your order or exceed the time
                    limit 3 times in a day, your personal completion rate will
                    be affected However, if the order is cancelled due to the
                    seller’s reasons, the system will re-evaluate the situation.{" "}
                  </span>
                  <span
                    style={{
                      color: "#E35CAD",
                    }}
                  >
                    View more
                  </span>
                </>
              }
              color="extsy.baseWhite"
              lh="120%"
            />
          </Flex>
          <Flex
            py="16px"
            borderTop="1px solid"
            borderBottom="1px solid"
            borderColor="extsy.neutral700"
            flexDir="column"
            gap="16px"
          >
            <CustomText text="Due to buyer" color="extsy.baseWhite" />
            {BUYER_REASONS.map((reason, index) => (
              <Flex
                key={index}
                align="flex-start"
                gap="10px"
                cursor="pointer"
                onClick={() => setSelectedReason(reason)}
              >
                <Flex
                  borderColor="extsy.neutral300"
                  border="1px solid"
                  p="2px"
                  borderRadius="50%"
                  height="18px"
                  width="18px"
                  align="center"
                  justify="center"
                >
                  <Circle
                    size="10px"
                    bg={reason === selectedReason ? "extsy.g1" : "transparent"}
                  />
                </Flex>
                <CustomText text={reason} cursor />
              </Flex>
            ))}
          </Flex>
          <Flex flexDir="column" gap="16px">
            <CustomText text="Due to seller" color="extsy.baseWhite" />
            {SELLER_REASONS.map((reason, index) => (
              <Flex
                key={index}
                align="flex-start"
                gap="10px"
                cursor="pointer"
                onClick={() => setSelectedReason(reason)}
              >
                <Flex
                  borderColor="extsy.neutral300"
                  border="1px solid"
                  p="2px"
                  borderRadius="50%"
                  height="18px"
                  width="18px"
                  align="center"
                  justify="center"
                >
                  <Circle
                    size="10px"
                    bg={reason === selectedReason ? "extsy.g1" : "transparent"}
                  />
                </Flex>
                <CustomText text={reason} cursor />
              </Flex>
            ))}
          </Flex>
          <Flex gap="10px" align="center">
            {reasonText === "" ? (
              <Image
                src={UnCheckedBox}
                alt="checkmark"
                cursor={"pointer"}
                onClick={() =>
                  setReasonText(
                    "I have paid the seller / have not received seller’s refund"
                  )
                }
              />
            ) : (
              <Image
                src={CheckedBox}
                alt="checkmark"
                cursor={"pointer"}
                onClick={() => setReasonText("")}
              />
            )}
            <CustomText
              text="I have not paid the seller / have received seller’s refund"
              size="14px"
              color="extsy.white"
            />
          </Flex>
        </Flex>
      </CustomModal>
      <CustomModal
        isOpen={isTipsOpen}
        onClose={() => {
          onCloseTips();
        }}
        onSubmit={() => {
          onCloseTips();

          onOpenInitAppeal();
        }}
        // noCloseFooter
        headerText={"Tips"}
        closeBtntext="Cancel"
        btnText={"Appeal"}
        width={"600px"}
        // bg="#383434"
        noBox={true}
      >
        <Flex p="32px 24px" flexDir="column" gap="32px" my={"10px"}>
          <CustomText
            text="Before appealing"
            color="#FFF"
            size="14px"
            lh="120%"
          />
          <Flex align={"center"} gap={"16px"} borderRadius={"12px"}>
            <Image src={AppealIcon} alt="checkmark" />
            <CustomText
              text="You can upload proof of payment and account info in the chatbox to help both sides to verify the payment."
              size="14px"
              color="#FFF"
              lh="120%"
            />
          </Flex>
          <Divider borderColor={"#383434"} />
          <Flex align={"center"} gap={"16px"} borderRadius={"12px"}>
            <Image src={ChatIcon} alt="checkmark" />
            <CustomText
              text="If you cannot reach the sell/buyer or reach an agreement with the other user, please file an apeal"
              size="14px"
              color="#FFF"
              lh="120%"
            />
          </Flex>
        </Flex>
      </CustomModal>
      <CustomModal
        isOpen={isInitAppealOpen}
        onClose={() => {
          onCloseInitAppeal();
        }}
        onSubmit={() => {
          setAppealCreated(true);
          onCloseInitAppeal();
          onOpen();
        }}
        // noCloseFooter
        headerText={"Initiating Appeal"}
        closeBtntext="Cancel"
        btnText={"Appeal"}
        width={"900px"}
        // bg="#383434"
        noBox={true}
      >
        <Flex p="18px 24px" flexDir="column" gap="24px">
          <Flex
            align={"center"}
            p={"16px 24px"}
            gap={"10px"}
            borderRadius={"8px"}
            bg={"#383434"}
          >
            <Image src={WarningIcon} />
            <CustomText
              text="Submitted detail is visible for all parties"
              size="14px"
              color="#BAB8B8"
              lh="120%"
            />
          </Flex>
          <Stack gap={"20px"}>
            <Stack gap={"12px"}>
              <CustomText text="Reason" size="14px" color="#BAB8B8" lh="120%" />
              <CustomSelect
                placeholder={selectedOption?.label || "Select an option"}
                value={selectedOption?.value} // Set the selectedOption as the current value
                onSelect={(option: any) => setSelectedOption(option)} // Update selectedOption on selection
                options={options}
                isLoading={false} // Set to true if options are loading
              />
            </Stack>
            <Stack gap={"12px"}>
              <CustomText
                text="Description"
                size="14px"
                color="#BAB8B8"
                lh="120%"
              />
              <CustomArea placeholder="Type here..." />
            </Stack>
            <Stack gap={"12px"}>
              <Flex align={"center"} gap={"4px"} flexWrap={"wrap"}>
                <CustomText
                  text="Upload Proof "
                  size="14px"
                  color="#BAB8B8"
                  lh="120%"
                />
                <CustomText
                  text="(Max size 5Mbs, Supported files jpeg, jpg, png & pdf)"
                  size="14px"
                  color="#908B8B"
                  lh="120%"
                />
              </Flex>
              <FileUpload />
            </Stack>
          </Stack>
        </Flex>
      </CustomModal>
    </>
  );
}

export default OrderDetails;

function FileUpload() {
  const [selectedFiles, setSelectedFiles] = useState<any>([]);
  console.log(selectedFiles);
  const onDrop = (acceptedFiles: any) => {
    // Add preview URLs for images for immediate display
    const filesWithPreview = acceptedFiles?.map((file: any) => {
      return {
        ...file,
        preview: file.type?.includes("image")
          ? URL.createObjectURL(file)
          : null,
        size: (file.size / 1024).toFixed(2), // File size in KB
      };
    });
    setSelectedFiles((prevFiles: any) => [...prevFiles, ...filesWithPreview]);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/jpeg": [".jpeg", ".jpg"],
      "image/png": [".png"],
      "application/pdf": [".pdf"],
    },
    maxSize: 5 * 1024 * 1024, // 5MB limit
  });

  const removeFile = (fileName: any) => {
    // Clean up the preview URL to prevent memory leaks
    const updatedFiles = selectedFiles?.filter((file: any) => {
      if (file.path === fileName && file.preview) {
        URL.revokeObjectURL(file.preview);
      }
      return file.path !== fileName;
    });
    setSelectedFiles(updatedFiles);
  };

  return (
    <VStack spacing={4} w="100%">
      {selectedFiles?.length !== 0 ? (
        <Flex wrap="wrap" gap="16px" alignItems={"stretch"} w={"100%"}>
          {selectedFiles?.map((file: any) => (
            <Flex
              key={file.path}
              justify={"space-between"}
              align={"center"}
              padding={"12px 24px 12px 12px"}
              borderRadius={"14px"}
              bg={"rgba(255,255,255,0.05)"}
              w={"250px"}
            >
              <Flex align={"center"} gap={"16px"}>
                <Image
                  src={file.path?.includes("pdf") ? PdfIcon : JpgIcon}
                  alt={file.path?.includes("pdf") ? "pdf" : "image"}
                />
                <Stack justify={"center"} align={"flex-start"} gap={"5px"}>
                  <CustomText text={file.path} noOfLines={2} />
                  <CustomText text={`${file.size} KB`} />
                </Stack>
              </Flex>

              <Button
                size="sm"
                color="#EFA3D1"
                onClick={() => removeFile(file.path)}
                bg="transparent"
                _hover={{ bg: "transparent", color: "#EFA3D1" }}
              >
                ✕
              </Button>
            </Flex>
          ))}

          <Box
            {...getRootProps()}
            p={"12px"}
            border="2px dashed"
            borderColor="#655F5F"
            borderRadius="16px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            cursor="pointer"
            color="#BAB8B8"
            fontSize="14px"
            alignSelf={"stretch"}
            w={"200px"}
            bg={"rgba(11, 10, 10, 0.60)"}
          >
            <input {...getInputProps()} />
            Upload File
          </Box>
        </Flex>
      ) : (
        <Box
          {...getRootProps()}
          w="100%"
          h="160px"
          bg={"rgba(11, 10, 10, 0.60)"}
          borderRadius="16px"
          border="2px dashed"
          borderColor="#655F5F"
          display="flex"
          alignItems="center"
          justifyContent="center"
          cursor="pointer"
          textAlign="center"
        >
          <input {...getInputProps()} />
          <Flex direction="column" align="center" gap={"16px"}>
            <Image src={ImageAddIcon} />
            <CustomText
              text={"Browse or Drag and Drop an image here"}
              color="#BAB8B8"
              size={{ base: "12px", md: "14px" }}
              lh="120%"
            />
            <CustomButton
              title="Upload File"
              height="40px"
              padding="10px 32px"
              radius="8px"
              bg="#383434"
              width="134px"
              size="14px"
              color="rgba(90deg, #FEF9CC 0%, #F6DBD4 40%, #F1C8D9 75%, #F0C2DB 100%)
background-clip: text;"
            />
          </Flex>
        </Box>
      )}
    </VStack>
  );
}
