import { Flex, Image } from "@chakra-ui/react";
import React from "react";
import { Outlet, useNavigate } from "react-router-dom";

import LogoIcon from "../assets/images/logo-icon.svg";
import { useQuery } from "@tanstack/react-query";
import SecondaryValidationAlert from "../components/alerts/SecondaryValidationAlert";

function ForgotLayout() {
  const { data: token } = useQuery({
    queryKey: ["token"],
    queryFn: () => localStorage.getItem("token"),
  });

  const navigate = useNavigate();

  if (token) {
    navigate("/");
  }

  return (
    <Flex bg="extsy.bg" minH="100vh" flexDir={"column"} p="24px 80px">
      <Image
        draggable={false}
        src={LogoIcon}
        alt="logo"
        width="55px"
        height="40px"
        cursor="pointer"
        onClick={() => navigate("/auth/login")}
      />
      <Flex justify={"center"} align={"center"} minH="90vh">
        <Outlet />
      </Flex>
      <SecondaryValidationAlert />
    </Flex>
  );
}

export default ForgotLayout;
