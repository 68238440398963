import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { client } from "./api-client";
// * Raffles

export const useGetRaffleRounds = (limit: number, offset: number) => {
  return useQuery<any, Error>({
    queryKey: ["getRaffleRounds", limit, offset],
    queryFn: async () => {
      const queryParams = new URLSearchParams({
        limit: limit.toString(),
        offset: offset.toString(),
      });
      const response = await client(
        `raffle/getRaffleRounds?${queryParams.toString()}`
      );
      if (response.error) {
        throw new Error(response.error);
      }
      return response;
    },
  });
};

export const useGetCurrentRaffleRound = () => {
  return useQuery<any, Error>({
    queryKey: ["getCurrentRaffleRound"],
    queryFn: async () => {
      const response = await client(`raffle/getCurrentRaffleRound`);
      if (response.error) {
        throw new Error(response.error);
      }
      return response;
    },
    refetchInterval: 1000 * 60 * 2, // 1 minute
    // staleTime: 1000 * 60 * 1, // 5 minutes
  });
};

export const useGetRaffleRound = (raffleRound: any) => {
  return useQuery<any, Error, any>({
    queryKey: ["getRaffleRound"],
    queryFn: async (raffleRound) => {
      const response = await client(`raffle/getRaffleRound/${raffleRound}`);
      if (response.error) {
        throw new Error(response.error);
      }
      return response;
    },
    enabled: !!raffleRound,
  });
};

export const useGetTicketPlans = () => {
  return useQuery<any, Error>({
    queryKey: ["getTicketPlans"],
    queryFn: async () => {
      const response = await client(`raffle/getTicketPlans`);
      if (response.error) {
        throw new Error(response.error);
      }
      return response;
    },
    staleTime: 1000 * 60 * 60 * 24, // 24 hours
  });
};

export const useGetUserTickets = (
  limit: number,
  offset: number,
  filter: string, // "All" | "Won" | "Lost" | "Draw Pending"
  date?: string,
  search?: string,
  sortKey?: string,
  sortOrder?: string
) => {
  return useQuery<any, Error>({
    queryKey: ["getUserTickets", limit, offset, filter, date],
    queryFn: async () => {
      const queryParams = new URLSearchParams({
        limit: limit.toString(),
        offset: offset.toString(),
        filter: filter,
      });
      if (date) {
        const newDate = new Date(date);
        queryParams.append("date", newDate?.toLocaleDateString());
      }
      if (search) {
        queryParams.append("search", search);
      }
      if (sortKey) {
        queryParams.append("sortKey", sortKey);
      }
      if (sortOrder) {
        queryParams.append("sortOrder", sortOrder);
      }
      const response = await client(
        `raffle/getUserTickets?${queryParams.toString()}`
      );
      if (response.error) {
        throw new Error(response.error);
      }
      return response;
    },
  });
};

export const useGetLeaderBoard = (
  limit: number,
  offset: number,
  search?: string
) => {
  return useQuery<any, Error>({
    queryKey: ["getLeaderBoard", limit, offset, search],
    queryFn: async () => {
      const queryParams = new URLSearchParams({
        limit: limit.toString(),
        offset: offset.toString(),
      });
      if (search) {
        queryParams.append("search", search);
      }
      const response = await client(
        `raffle/getLeaderBoard?${queryParams.toString()}`
      );
      if (response.error) {
        throw new Error(response.error);
      }
      return response;
    },
  });
};

export const useAfterTrxSuccess = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async () => {
      const response = await client(`raffle/afterTransactionSuccess`, {
        method: "POST",
      });

      if (response.error) {
        throw new Error(response.error);
      }
      queryClient.invalidateQueries({
        queryKey: ["getUserTickets"],
      });
      queryClient.invalidateQueries({
        queryKey: ["getCurrentRaffleRound"],
      });

      return response;
    },
  });
};
