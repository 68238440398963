import {
  Flex,
  Grid,
  GridItem,
  Image,
  Stack,
  useMediaQuery,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import CustomText from "../../../components/text/CustomText";
import BackBtn from "../../../assets/images/back-btn.svg";
import ChatModule from "../orders/ChatModule";
import { useOrderDetails } from "../../../utils/api/p2p.api";
import { useNavigate } from "react-router-dom";
import HistorySection from "./HistorySection";
import StepSection from "./StepSection";
import AppealStepSection from "./AppealSection";

const AppealDetail = () => {
  const navigate = useNavigate();
  const { data: orderDetailData } = useOrderDetails("2345rrfweqde");
  const [isLessThan992] = useMediaQuery("(max-width: 992px)");
  const [collapses, setCollapses] = useState<any>({
    orderDetails: true,
    chat: false,
  });

  useEffect(() => {
    if (isLessThan992) {
      setCollapses({
        orderDetails: true,
        chat: false,
      });
    } else {
      setCollapses({
        orderDetails: true,
        chat: true,
      });
    }
  }, [isLessThan992]);

  return (
    <Stack
      p={{ base: "12px", md: "32px" }}
      gap={"24px"}
      flex={"1 0 0"}
      alignSelf={"stretch"}
      borderRadius={"24px"}
      border={"1px solid #221F1F"}
      bg={"#221F1F"}
    >
      <Flex align={"center"} gap={"15px"}>
        <Image src={BackBtn} alt="backBtn" onClick={() => navigate("/p2p")} />
        <CustomText
          text="Appeal Detail"
          color="#FAFAFA"
          size="24px"
          weight="600"
          lh="120%"
        />
      </Flex>
      <CustomText
        text="Appeal Pending"
        color="#FEF8C3"
        size="18px"
        weight="600"
        lh="120%"
      />
      <Grid
        gap={{ base: "40px", lg: "24px" }}
        templateRows="repeat(1, 1fr)"
        templateColumns="repeat(12, 1fr)"
      >
        <GridItem colSpan={{ base: 12, lg: 8 }}>
          <Stack
            align={"flex-start"}
            gap={"40px"}
            flex={"1 0 0"}
            alignSelf={"stretch"}
          >
            <AppealStepSection />
            <Stack p={"24px 0px"} gap={"24px"}>
              <StepSection step={1} />
            </Stack>
            <HistorySection />
          </Stack>
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 4 }}>
          <ChatModule
            orderDetailData={orderDetailData}
            setCollapses={setCollapses}
            collapses={collapses}
          />
        </GridItem>
      </Grid>
    </Stack>
  );
};

export default AppealDetail;
