import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { clientP2P } from "./api-client-p2p";

export const useGetCountries = () => {
  return useQuery<any, Error>({
    queryKey: ["countries"], // Query key in the object form
    queryFn: async () => {
      const response = await clientP2P(`p2p/countries`);
      if (response.error) {
        throw new Error(response.error);
      }
      return response;
    },
  });
};

export const useGetBanks = () => {
  return useQuery<any, Error>({
    queryKey: ["banks-information"], // Query key in the object form
    queryFn: async () => {
      const response = await clientP2P(`p2p/banks-information`);
      if (response.error) {
        throw new Error(response.error);
      }
      return response;
    },
  });
};

export const useGetFiats = () => {
  return useQuery<any, Error>({
    queryKey: ["fiats"], // Query key in the object form
    queryFn: async () => {
      const response = await clientP2P(`p2p/fiats`);
      if (response.error) {
        throw new Error(response.error);
      }
      return response;
    },
  });
};

export const useGetTermsTags = () => {
  return useQuery<any, Error>({
    queryKey: ["terms-tags"], // Query key in the object form
    queryFn: async () => {
      const response = await clientP2P(`p2p/terms-tags`);
      if (response.error) {
        throw new Error(response.error);
      }
      return response;
    },
  });
};

export const useUpdateBanks = () => {
  const queryClient = useQueryClient();

  return useMutation<any, Error, any>({
    mutationFn: async (payload) => {
      const response = await clientP2P(`p2p/update-user-bank`, {
        method: "PUT",
        data: payload,
      });

      if (response.error) {
        throw new Error(response.error);
      }
      queryClient.invalidateQueries({ queryKey: ["banks-information"] });
      return response;
    },
  });
};

export const useAddBanks = () => {
  const queryClient = useQueryClient();

  return useMutation<any, Error, any>({
    mutationFn: async (payload) => {
      const response = await clientP2P(`p2p/add-user-bank`, {
        method: "POST",
        data: payload,
      });

      if (response.error) {
        throw new Error(response.error);
      }
      queryClient.invalidateQueries({ queryKey: ["banks-information"] });
      return response;
    },
  });
};

export const useDeleteBanks = () => {
  const queryClient = useQueryClient();

  return useMutation<any, Error, any>({
    mutationFn: async (payload) => {
      const response = await clientP2P(`p2p/delete-banks`, {
        method: "DELETE",
        data: payload,
      });

      if (response.error) {
        throw new Error(response.error);
      }
      queryClient.invalidateQueries({ queryKey: ["banks-information"] });
      return response;
    },
  });
};

export const useAssets = () => {
  return useQuery<any, Error>({
    queryKey: ["assets"], // Query key in the object form
    queryFn: async () => {
      const response = await clientP2P(`p2p/assets`);
      if (response.error) {
        throw new Error(response.error);
      }
      return response;
    },
  });
};

export const useNetworks = () => {
  return useQuery<any, Error>({
    queryKey: ["networks"], // Query key in the object form
    queryFn: async () => {
      const response = await clientP2P(`p2p/networks`);
      if (response.error) {
        throw new Error(response.error);
      }
      return response;
    },
  });
};

export const useNetworkCoins = (networkID: string | undefined) =>
  useQuery({
    queryKey: ["getCoins", networkID],
    queryFn: async () => {
      const response = await clientP2P(
        `p2p/network-coins?networkID=${networkID}`
      );
      return response;
    },
  });

export const useMyP2PBalance = (
  limit: number,
  pageNo: number,
  searchTerm: string,
  sortBy: string,
  sortDirection: string
) => {
  return useQuery<any, Error, any>({
    queryKey: [
      "my-p2p-balance",
      limit,
      pageNo,
      searchTerm,
      sortBy,
      sortDirection,
    ], // Query key in the object form
    queryFn: async () => {
      const queryParams = new URLSearchParams({
        limit: limit.toString(),
        pageNo: pageNo.toString(),
        searchTerm: searchTerm.toString(),
        sortBy: sortBy.toString(),
        sortDirection: sortDirection.toString(),
      });

      const response = await clientP2P(
        `p2p/my-p2p-balance?${queryParams.toString()}`
      );
      if (response.error) {
        throw new Error(response.error);
      }
      return response;
    },
  });
};

export const useBuySellHistory = (
  searchTerm: string,
  searchAmount: string,
  currency: string,
  pageNo: number,
  limit: number,
  sortBy: string,
  sortDirection: string,
  coin: string,
  type: string
) => {
  return useQuery<any, Error, any>({
    queryKey: [
      "buy-sell-history",
      searchTerm,
      searchAmount,
      currency,
      pageNo,
      limit,
      sortBy,
      sortDirection,
      coin,
      type,
    ],
    queryFn: async () => {
      // Initialize URLSearchParams without time and date
      const queryParams = new URLSearchParams({
        searchTerm: searchTerm.toString(),
        searchAmount: searchAmount.toString(),
        currency: currency.toString(),
        pageNo: pageNo.toString(),
        limit: limit.toString(),
        sortBy: sortBy.toString(),
        sortDirection: sortDirection.toString(),
        coin: coin.toString(),
        type: type.toString(),
      });
      const response = await clientP2P(
        `p2p/buy-sell-history?${queryParams.toString()}`
      );
      if (response.error) {
        throw new Error(response.error);
      }
      return response;
    },
  });
};

export const useMyOrders = (
  status: string,
  searchTerm?: string,
  pageNo?: number,
  limit?: number,
  sortBy?: string,
  sortDirection?: string,
  coins?: string,
  currency?: string,
  type?: string,
  startDate?: string,
  endDate?: string
) => {
  return useQuery<any, Error, any>({
    queryKey: [
      "my-orders",
      status,
      searchTerm,
      pageNo,
      limit,
      sortBy,
      sortDirection,
      coins,
      currency,
      type,
      startDate,
      endDate,
    ],
    queryFn: async () => {
      // Initialize URLSearchParams without time and date
      const queryParams = new URLSearchParams({
        status: status.toString(),
      });

      // Conditionally add optional parameters
      if (searchTerm) queryParams.append("searchTerm", searchTerm.toString());
      if (pageNo) queryParams.append("pageNo", pageNo.toString());
      if (limit) queryParams.append("limit", limit.toString());
      if (sortBy) queryParams.append("sortBy", sortBy.toString());
      if (sortDirection)
        queryParams.append("sortDirection", sortDirection.toString());
      if (coins) queryParams.append("coins", coins.toString());
      if (currency) queryParams.append("currency", currency.toString());

      if (type) queryParams.append("type", type.toString());
      if (startDate) queryParams.append("startDate", startDate.toString());
      if (endDate) queryParams.append("endDate", endDate.toString());

      const response = await clientP2P(
        `p2p/my-orders?${queryParams.toString()}`
      );
      if (response.error) {
        throw new Error(response.error);
      }
      return response;
    },
  });
};

export const useMyOrderDetailListings = (
  pageNo?: number,
  limit?: number,
  sortBy?: string,
  sortDirection?: string
) => {
  return useQuery<any, Error, any>({
    queryKey: [
      "my-order-detail-listings",

      pageNo,
      limit,
      sortBy,
      sortDirection,
    ],
    queryFn: async () => {
      // Initialize URLSearchParams without time and date
      const queryParams = new URLSearchParams({});
      if (pageNo) queryParams.append("pageNo", pageNo.toString());
      if (limit) queryParams.append("limit", limit.toString());
      if (sortBy) queryParams.append("sortBy", sortBy.toString());
      if (sortDirection)
        queryParams.append("sortDirection", sortDirection.toString());
      const response = await clientP2P(
        `p2p/my-order-detail-listings?${queryParams.toString()}`
      );
      if (response.error) {
        throw new Error(response.error);
      }
      return response;
    },
  });
};

export const useOrderDetails = (orderID: string) => {
  return useQuery<any, Error, any>({
    queryKey: ["order-item-details", orderID],
    queryFn: async () => {
      // Initialize URLSearchParams without time and date
      const response = await clientP2P(
        `p2p/order-item-details?orderItemID=${orderID}`
      );
      if (response.error) {
        throw new Error(response.error);
      }
      return response;
    },
  });
};

export const useOrder = () => {
  const queryClient = useQueryClient();

  return useMutation<any, Error, any>({
    mutationFn: async (payload) => {
      const response = await clientP2P(`p2p/order`, {
        method: "POST",
        data: payload,
      });

      if (response.error) {
        throw new Error(response.error);
      }
      queryClient.invalidateQueries({ queryKey: ["my-orders"] });
      return response;
    },
  });
};

export const useOrderItem = () => {
  const queryClient = useQueryClient();

  return useMutation<any, Error, any>({
    mutationFn: async (payload) => {
      const response = await clientP2P(`p2p/order-item`, {
        method: "POST",
        data: payload,
      });

      if (response.error) {
        throw new Error(response.error);
      }
      queryClient.invalidateQueries({ queryKey: ["buy-sell-history"] });
      return response;
    },
  });
};

export const useMarkAsPaid = () => {
  const queryClient = useQueryClient();

  return useMutation<any, Error, any>({
    mutationFn: async (payload) => {
      const response = await clientP2P(`p2p/mark-as-paid`, {
        method: "POST",
        data: payload,
      });

      if (response.error) {
        throw new Error(response.error);
      }
      queryClient.invalidateQueries({ queryKey: ["order-item-details"] });
      return response;
    },
  });
};

export const useReleaseCoin = () => {
  const queryClient = useQueryClient();

  return useMutation<any, Error, any>({
    mutationFn: async (payload) => {
      const response = await clientP2P(`p2p/release-coin`, {
        method: "POST",
        data: payload,
      });

      if (response.error) {
        throw new Error(response.error);
      }
      queryClient.invalidateQueries({ queryKey: ["order-item-details"] });
      return response;
    },
  });
};

export const useSetNickname = () => {
  return useMutation<any, Error, any>({
    mutationFn: async (payload) => {
      const response = await clientP2P(`p2p/set-nick-name`, {
        method: "POST",
        data: payload,
      });

      if (response.error) {
        throw new Error(response.error);
      }
      return response;
    },
  });
};

export const useCancelOrder = () => {
  const queryClient = useQueryClient();

  return useMutation<any, Error, any>({
    mutationFn: async (orderID) => {
      const response = await clientP2P(`p2p/cancle-order?orderID=${orderID}`, {
        method: "GET",
      });

      if (response.error) {
        throw new Error(response.error);
      }
      queryClient.invalidateQueries({ queryKey: ["my-orders"] });
      return response;
    },
  });
};

export const useCancelOrderItem = () => {
  const queryClient = useQueryClient();

  return useMutation<any, Error, any>({
    mutationFn: async (payload) => {
      const response = await clientP2P(
        `p2p/cancle-order-item?dueTo=${payload.dueTo}&reason=${payload.reason}&orderItemID=${payload.orderItemId}`,
        {
          method: "GET",
        }
      );

      if (response.error) {
        throw new Error(response.error);
      }
      queryClient.invalidateQueries({ queryKey: ["my-orders"] });
      return response;
    },
  });
};

export const useMyListings = (
  status: string,
  searchTerm?: string,
  pageNo?: number,
  limit?: number,
  sortBy?: string,
  sortDirection?: string,
  coins?: string,
  currency?: string,
  type?: string,
  startDate?: string,
  endDate?: string
) => {
  return useQuery<any, Error, any>({
    queryKey: [
      "my-listings",
      status,
      searchTerm,
      pageNo,
      limit,
      sortBy,
      sortDirection,
      coins,
      currency,
      type,
      startDate,
      endDate,
    ],
    queryFn: async () => {
      // Initialize URLSearchParams without time and date
      const queryParams = new URLSearchParams({
        status: status.toString(),
      });

      // Conditionally add optional parameters
      if (searchTerm) queryParams.append("searchTerm", searchTerm.toString());
      if (pageNo) queryParams.append("pageNo", pageNo.toString());
      if (limit) queryParams.append("limit", limit.toString());
      if (sortBy) queryParams.append("sortBy", sortBy.toString());
      if (sortDirection)
        queryParams.append("sortDirection", sortDirection.toString());
      if (coins) queryParams.append("coins", coins.toString());
      if (currency) queryParams.append("currency", currency.toString());

      if (type) queryParams.append("type", type.toString());
      if (startDate) queryParams.append("startDate", startDate.toString());
      if (endDate) queryParams.append("endDate", endDate.toString());

      const response = await clientP2P(
        `p2p/my-listings?${queryParams.toString()}`
      );
      if (response.error) {
        throw new Error(response.error);
      }
      return response;
    },
  });
};

export const useGetProcessingFee = (assetID: string, chain?: string) => {
  return useQuery<any, Error, any>({
    queryKey: ["processing-fee", assetID, chain],
    queryFn: async () => {
      // Initialize URLSearchParams without time and date
      const queryParams = new URLSearchParams({
        assetID: assetID.toString(),
      });

      // Conditionally add optional parameters
      if (chain) queryParams.append("chain", chain.toString());

      const response = await clientP2P(
        `p2p/processing-fee?${queryParams.toString()}`
      );
      if (response.error) {
        throw new Error(response.error);
      }
      return response;
    },
  });
};

export const useDeposit = () => {
  const queryClient = useQueryClient();

  return useMutation<any, Error, any>({
    mutationFn: async (payload) => {
      const response = await clientP2P(`p2p/deposit`, {
        method: "POST",
        data: payload,
      });

      if (response.error) {
        throw new Error(response.error);
      }
      queryClient.invalidateQueries({ queryKey: ["my-p2p-balance"] });
      return response;
    },
  });
};

export const useWithdraw = () => {
  const queryClient = useQueryClient();

  return useMutation<any, Error, any>({
    mutationFn: async (payload) => {
      const response = await clientP2P(`p2p/withdraw`, {
        method: "POST",
        data: payload,
      });

      if (response.error) {
        throw new Error(response.error);
      }
      queryClient.invalidateQueries({ queryKey: ["my-p2p-balance"] });
      return response;
    },
  });
};
