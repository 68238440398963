import { Flex, Image } from "@chakra-ui/react";
import React from "react";
import EmptyFolder from "../../../assets/images/empty-folder.svg";
import CustomText from "../../../components/text/CustomText";

function NoOrder() {
  return (
    <Flex
      minH="677px"
      flexDir="column"
      align="center"
      justify="center"
      gap="24px"
    >
      <Image src={EmptyFolder} alt="empty" boxSize="100px" />
      <CustomText text="No order available" color="extsy.neutral400" />
      {/* <PrimaryButton title="Order Now" maxW="184px" /> */}
    </Flex>
  );
}

export default NoOrder;
