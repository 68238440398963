import { Flex } from "@chakra-ui/react";
import React from "react";
import CustomText from "../../components/text/CustomText";
import PrimaryButton from "../../components/button/PrimaryButton";
import VCardInput from "../../components/form/vCardInput";
import CustomArea from "../../components/form/CustomTextArea";

function Report() {
  return (
    <Flex
      gap={{ base: "16px", lg: "24px" }}
      w="100%"
      flexDir={{ base: "column", "1xl": "row" }}
    >
      <Flex
        gap={{ base: "16px", lg: "32px" }}
        p={{ base: "16px", lg: "32px" }}
        flexDir="column"
        borderRadius={{ base: "16px", lg: "24px" }}
        bg="extsy.neutral900"
        border="1px solid"
        borderColor="extsy.neutral900"
        w="100%"
        maxW="800px"
        pos="relative"
        overflow="hidden"
      >
        <Flex
          flexDir={{
            base: "column",
            lg: "row",
          }}
          gap={{ base: "16px", lg: "32px" }}
        >
          <Flex flexDir="column" gap="12px" zIndex="100" w="100%">
            <CustomText text="What are you reporting?" size="14px" />
            <VCardInput
              placeholder=""
              bg="extsy.neutral1000"
              type="text"
              register={() => {}}
              name="address"
            />
          </Flex>
          <Flex flexDir="column" gap="12px" zIndex="100" w="100%">
            <CustomText text="Your Email" size="14px" />
            <VCardInput
              placeholder=""
              bg="extsy.neutral1000"
              type="text"
              register={() => {}}
              name="address"
            />
          </Flex>
        </Flex>
        <Flex flexDir="column" gap="12px" zIndex="100">
          <CustomText text="Description" size="14px" />
          <CustomArea
            placeholder=""
            bg="extsy.neutral1000"
            register={() => {}}
            name="address"
            rows={6}
          />
        </Flex>
        <Flex justify="end" mt="32px">
          <PrimaryButton
            title="Submit Report"
            size="14px"
            maxW={{ lg: "251px" }}
          />
        </Flex>
      </Flex>
    </Flex>
  );
}

export default Report;
