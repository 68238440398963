import { Box, Flex, SkeletonText, useDisclosure } from "@chakra-ui/react";
import React from "react";
import CustomText from "../../components/text/CustomText";
import GradientText from "../../components/text/GradientText";
import NextDraw from "./NextDraw";
import MyRaffleHistory from "./MyRafflesHistory";
import LifeTimeEarnings from "./LifeTimeEarnings";
import Tickets from "./Tickets";
import { useGetCurrentRaffleRound } from "../../utils/api/raffle.api";
import moment from "moment";
import PrimaryButton from "../../components/button/PrimaryButton";
import CustomButton from "../../components/button/CustomButton";
import { IconSettings } from "@tabler/icons-react";
import CustomModal from "../../components/modal/customModal";
import RaffleAffliate from "../settings/RaffleAffliate";

function Raffle() {
  const { data: currentRound, isLoading: raffleRoundLoading } =
    useGetCurrentRaffleRound();

  const {
    isOpen: isSettingsOpen,
    onOpen: onSettingsOpen,
    onClose: onSettingsClose,
  } = useDisclosure();

  return (
    <Flex gap={{ base: "16px", lg: "24px" }} flexDir="column">
      <Flex
        flexDir="column"
        justify="space-between"
        gap={{ base: "24px", lg: "40px" }}
        p={{ base: "16px", lg: "40px" }}
        bg="extsy.neutral1000"
        border="1px solid"
        borderColor="extsy.neutral1000"
        borderRadius={{ base: "16px", lg: "24px" }}
        pos="relative"
      >
        <Flex align="center" justify="space-between">
          <CustomText
            text="Your Raffles"
            size={{ base: "18px", lg: "24px" }}
            weight="600"
            color="extsy.baseWhite"
          />
          <CustomButton
            bg="extsy.neutral900"
            weight="400"
            title="Raffle Settings"
            color="extsy.baseWhite"
            icon={<IconSettings size={24} color="#fff" />}
            maxW="167px"
            height="48px"
            onClick={onSettingsOpen}
          />
        </Flex>
        <Flex
          flexDir={{ base: "column", lg: "row" }}
          gap={{ base: "16px", lg: "32px" }}
          w="100%"
          justify="space-between"
        >
          <Flex
            p={{ base: "16px", lg: "32px" }}
            flexDir="column"
            gap="24px"
            bg="extsy.bg"
            borderRadius="16px"
            w="100%"
          >
            <Flex flexDir="column" gap="8px">
              <CustomText
                text="Prize"
                size={{ base: "18px", lg: "24px" }}
                weight="600"
                color="extsy.baseWhite"
              />
              {raffleRoundLoading ? (
                <SkeletonText noOfLines={1} w="120px" h="20px" />
              ) : (
                <CustomText
                  text={`$ ${currentRound?.prize_pool || 0}`}
                  size={{ base: "32px", lg: "40px" }}
                  weight="700"
                  color="extsy.baseWhite"
                  wrap="nowrap"
                />
              )}
            </Flex>
            <Flex
              gap="40px"
              justify={{ base: "space-between", lg: "flex-start" }}
            >
              <Flex flexDir="column" gap="8px">
                {raffleRoundLoading ? (
                  <>
                    <SkeletonText noOfLines={1} w="80px" />
                    <SkeletonText noOfLines={1} w="120px" h="20px" />
                  </>
                ) : (
                  <>
                    <CustomText
                      text="Total Ticket Sold"
                      size="14px"
                      color="extsy.baseWhite"
                      wrap="nowrap"
                    />
                    <GradientText
                      text={currentRound?.total_tickets_sold || 0}
                      size="18px"
                      weight="600"
                      bg="extsy.g1"
                      wrap="nowrap"
                    />
                  </>
                )}
              </Flex>
              <Box bg="extsy.neutral900" w="1px" h="47px" />
              <Flex flexDir="column" gap="8px">
                {raffleRoundLoading ? (
                  <>
                    <SkeletonText noOfLines={1} w="80px" />
                    <SkeletonText noOfLines={1} w="120px" h="20px" />
                  </>
                ) : (
                  <>
                    <CustomText
                      text="Raffle Closes"
                      size="14px"
                      color="extsy.baseWhite"
                      wrap="nowrap"
                    />
                    <GradientText
                      text={moment(currentRound?.start_time).format(
                        "DD-MM-YYYY"
                      )}
                      size="18px"
                      weight="600"
                      bg="extsy.g1"
                      wrap="nowrap"
                    />
                  </>
                )}
              </Flex>
            </Flex>
          </Flex>
          <Flex
            p={{ base: "16px", lg: "32px" }}
            flexDir="column"
            align="center"
            justify="center"
            gap="24px"
            bg="extsy.bg"
            borderRadius="16px"
            w="100%"
          >
            <PrimaryButton title="Join The Next Draw!" />
          </Flex>
          <NextDraw round={currentRound} isLoading={raffleRoundLoading} />
        </Flex>
      </Flex>
      <Flex
        gap={{ base: "16px", lg: "24px" }}
        w="100%"
        flexDir={{ base: "column", "1xl": "row" }}
      >
        <Flex
          p={{ base: "16px", lg: "24px" }}
          bg="extsy.neutral1000"
          borderRadius={{ base: "16px", lg: "24px" }}
          flexDir="column"
          boxShadow="0px 3px 4px 0px rgba(0, 0, 0, 0.03)"
          w="100%"
          maxW="1290px"
          gap={{ base: "16px", lg: "24px" }}
          order={{ base: 2, "1xl": 1 }}
        >
          <MyRaffleHistory />
        </Flex>
        <Box order={{ base: 1, "1xl": 2 }}>
          <Tickets round={currentRound} />
        </Box>
      </Flex>
      <LifeTimeEarnings />
      <CustomModal
        isOpen={isSettingsOpen}
        onSubmit={() => {}}
        onClose={() => onSettingsClose()}
        headerText={"Raffle Name & Avatar"}
        noFooter={true}
        width={"600px"}
        bgtopBlur="transparent"
      >
        <Flex p={{ base: "16px", lg: "32px 24px" }} flexDir="column" gap="32px">
          <RaffleAffliate onClose={onSettingsClose} />
        </Flex>
      </CustomModal>
    </Flex>
  );
}

export default Raffle;
