import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Image,
  Skeleton,
  SkeletonCircle,
  SkeletonText,
  useDisclosure,
  useMediaQuery,
  useOutsideClick,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import CustomText from "../text/CustomText";
import WalletIcon from "../../assets/images/connect-wallet-rect.svg";
import PrimeIco from "../../assets/images/prime-ico.svg";
import SupremeIco from "../../assets/images/superme-ico.svg";
import QuantlaneIco from "../../assets/images/quantlane-ico.svg";
import NotificationIco from "../../assets/images/notification-ico.svg";
import LanguageIco from "../../assets/images/language-ico.svg";
import { useTranslation } from "react-i18next";
import Notifications from "./Notifications";
import {
  IconRosetteDiscountCheckFilled,
  IconShield,
  IconX,
} from "@tabler/icons-react";
import CustomModal from "../modal/customModal";
import Languages from "./Languages";
import HamburgerIco from "../../assets/images/hamburger-ico.svg";
import IconMenuClose from "../../assets/images/icon-menu-close.svg";
import {
  DASHBOARD_MENU,
  DASHBOARD_OTHER_MENU,
} from "../../utils/constants/constants";
import { useLocation, useNavigate } from "react-router-dom";
import GradientText from "../text/GradientText";
import LogoutModal from "../modal/LogoutModal";
import { useGetLoggedInUser } from "../../utils/api/auth.api";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { useAccount, useDisconnect } from "wagmi";
import {
  useCreateDeviceToken,
  useGetDeviceTokens,
  useGetNotifications,
} from "../../utils/api/settings.api";
import { requestForToken } from "../../config/firebaseConfig";
import { deviceType } from "react-device-detect";
import {
  useAddConnectWallet,
  useGetNetworks,
} from "../../utils/api/wallet.api";

function Navbar() {
  const { t } = useTranslation();

  const { disconnect } = useDisconnect();

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const createDeviceToken = useCreateDeviceToken();
  const { data: deviceTokens } = useGetDeviceTokens();
  const { data: userInfo, isLoading: userInfoLoading } = useGetLoggedInUser();
  const { data: notifiactions, isLoading: notificationLoading } =
    useGetNotifications();

  const {
    isOpen: isDisconnectOpen,
    onOpen: onDisconnectOpen,
    onClose: onDisconnectClose,
  } = useDisclosure();
  const {
    isOpen: isLogoutOpen,
    onOpen: onLogoutOpen,
    onClose: onLogoutClose,
  } = useDisclosure();
  const {
    isOpen: isMobileMenuOpen,
    onOpen: onMobileMenuOpen,
    onClose: onMobileMenuClose,
  } = useDisclosure();

  const [isLessThan992] = useMediaQuery("(max-width: 992px)");

  const [isNotificaitonOpen, setIsNotificationOpen] = useState(false);
  const [isLangOpen, setIsLangOpen] = useState(false);
  const [isConnected, setIsConnected] = useState(
    localStorage.getItem("isConnected") || false
  );
  const notificationsRef: any = useRef(null);
  const languagesRef: any = useRef(null);

  const getIco = (plan: any) => {
    switch (plan?.name) {
      case "Standard":
        return PrimeIco;
      case "Premium":
        return SupremeIco;
      case "Ultra":
        return QuantlaneIco;
      default:
        return PrimeIco;
    }
  };

  const fetchDeviceToken = async () => {
    try {
      const token = await requestForToken();
      if (token) {
        // Filter the token based on browser type from the deviceTokens data array before calling the createDeviceToken mutation if it exists already don't call the mutation
        const existingToken = deviceTokens?.data?.find(
          (deviceToken: any) =>
            deviceToken.device_token === token &&
            deviceToken.device_model === navigator.userAgent
        );
        if (!existingToken) {
          await createDeviceToken.mutateAsync({
            device_token: token,
            device_model: navigator.userAgent || "Unknown",
            device_type: deviceType,
          });
        }
      }
    } catch (error: any) {
      console.error("Error fetching device token:", error);
    }
  };

  const isActive = (path: string) => {
    const currentPath = new URL(path, window.location.origin)?.pathname;
    return pathname === currentPath;
  };

  useEffect(() => {
    if (!isLessThan992) {
      onMobileMenuClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLessThan992]);

  useEffect(() => {
    fetchDeviceToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useOutsideClick({
    ref: notificationsRef,
    handler: () => {
      setIsNotificationOpen(false);
    },
  });

  useOutsideClick({
    ref: languagesRef,
    handler: () => {
      setIsLangOpen(false);
    },
  });

  return (
    <>
      <Flex gap="24px" w="100%" flexDir="column">
        {/* Mobile View */}
        <Flex
          justify="space-between"
          align="center"
          w="100%"
          display={{ base: "flex", lg: "none" }}
        >
          <Flex gap="8px" align="center">
            <UserProfile isLoading={userInfoLoading} user={userInfo} />
            <UserConnectWallet
              isConnected={isConnected}
              setIsConnected={setIsConnected}
              onDisconnectOpen={onDisconnectOpen}
            />
          </Flex>
          <Flex gap="8px" align="center">
            <div ref={notificationsRef}>
              <UserNotifications
                isNotificaitonOpen={isNotificaitonOpen}
                setIsNotificationOpen={setIsNotificationOpen}
                setIsLangOpen={setIsLangOpen}
                notifications={notifiactions}
                isLoading={notificationLoading}
              />
            </div>
            <ActionButton onClick={onMobileMenuOpen} img={HamburgerIco} />
          </Flex>
        </Flex>
        {/* For Desktop View */}
        <Flex
          justify={{ base: "flex-end", xl: "space-between" }}
          align="center"
          gap="24px"
          w="100%"
          display={{ base: "none", lg: "flex" }}
        >
          <Box
            display={{
              base: "none",
              xl: "block",
            }}
          >
            <CustomText
              text={t("welcomeText")}
              family="Metropolis"
              size="28px"
              weight="700"
              color="extsy.neutral50"
              lh="36px"
            />
          </Box>

          <Flex gap="16px" align="center">
            <UserConnectWallet
              isConnected={isConnected}
              setIsConnected={setIsConnected}
              onDisconnectOpen={onDisconnectOpen}
            />
            {userInfoLoading ? (
              <Skeleton h="36px" w="96px" borderRadius="12px" />
            ) : (
              <Flex
                cursor="pointer"
                bg="extsy.neutral900"
                align="center"
                gap="8px"
                px="12px"
                h="36px"
                borderRadius="12px"
              >
                {userInfo?.kyc_level === 0 ||
                userInfo?.kyc_status !== "APPROVED" ? (
                  <Flex align="center" gap="4px">
                    <IconShield size="16px" color="#fff" />
                    <CustomText
                      text="Verify Account"
                      size="10px"
                      weight="500"
                    />
                  </Flex>
                ) : (
                  <Flex align="center" gap="4px">
                    <IconRosetteDiscountCheckFilled
                      size="16px"
                      color="#0ECB81"
                    />
                    <CustomText text="Verified" size="10px" weight="500" />
                  </Flex>
                )}
                <Box bg="extsy.neutral800" w="1px" h="20px" />
                <Flex
                  align="center"
                  gap="4px"
                  display={userInfo?.user_subscription ? "flex" : "none"}
                >
                  <Image
                    src={getIco(userInfo?.user_subscription?.plan)}
                    alt="prime"
                    boxSize="12px"
                  />
                  <CustomText
                    text={userInfo?.user_subscription?.plan?.name}
                    size="11px"
                    cursor="pointer"
                  />
                </Flex>
              </Flex>
            )}

            <UserProfile isLoading={userInfoLoading} user={userInfo} />
            <Flex align="center" gap="8px">
              <div ref={languagesRef}>
                <UserLanguages
                  isLangOpen={isLangOpen}
                  setIsLangOpen={setIsLangOpen}
                  setIsNotificationOpen={setIsNotificationOpen}
                />
              </div>
              <div ref={notificationsRef}>
                <UserNotifications
                  isNotificaitonOpen={isNotificaitonOpen}
                  setIsNotificationOpen={setIsNotificationOpen}
                  setIsLangOpen={setIsLangOpen}
                  notifications={notifiactions}
                  isLoading={notificationLoading}
                />
              </div>
            </Flex>
          </Flex>
        </Flex>
        <CustomModal
          isOpen={isDisconnectOpen}
          onClose={() => {
            onDisconnectClose();
          }}
          onSubmit={() => {
            disconnect();
            localStorage.removeItem("isConnected");
            onDisconnectClose();
          }}
          headerText={t("disconnectWallet")}
          btnText={t("yesDisconnect")}
          closeBtntext={t("cancel")}
          width={"600px"}
        >
          <Flex p="32px 24px" flexDir="column" gap="32px" alignItems="center">
            <CustomText
              text={t("areYouSureDisconnectWallet")}
              align="center"
              color="extsy.neutral300"
            />
          </Flex>
        </CustomModal>
      </Flex>
      <Drawer
        isOpen={isMobileMenuOpen}
        placement="left"
        size="full"
        onClose={() => {}}
      >
        <DrawerOverlay />
        <DrawerContent bg="extsy.bg">
          <DrawerBody p="24px" overflowY="auto">
            <Flex flexDir="column" gap="16px">
              <Flex justify="space-between" gap="16px" align="center" pb="8px">
                <UserProfile
                  isMenu={true}
                  isLoading={userInfoLoading}
                  user={userInfo}
                />
                <Flex gap="8px" align="center">
                  <div ref={languagesRef}>
                    <UserLanguages
                      isLangOpen={isLangOpen}
                      setIsLangOpen={setIsLangOpen}
                      setIsNotificationOpen={setIsNotificationOpen}
                    />
                  </div>
                  <ActionButton
                    onClick={onMobileMenuClose}
                    img={IconMenuClose}
                  />
                </Flex>
              </Flex>
              <Divider
                color="extsy.neutral1000"
                borderColor="extsy.neutral1000"
                opacity="1"
              />
              {DASHBOARD_MENU.map((menu, index) => (
                <Flex
                  key={index}
                  gap="8px"
                  align="center"
                  cursor="pointer"
                  onClick={() => {
                    navigate(menu.path);
                    onMobileMenuClose();
                  }}
                >
                  <menu.icon
                    size="24px"
                    color={isActive(menu?.path) ? "#fff" : "#A5A1A1"}
                  />
                  {isActive(menu?.path) ? (
                    <GradientText
                      text={t(menu.key)}
                      bg="extsy.g1"
                      cursor="pointer"
                    />
                  ) : (
                    <CustomText
                      text={t(menu.key)}
                      color="extsy.neutral300"
                      cursor="pointer"
                    />
                  )}
                </Flex>
              ))}
              <Divider
                color="extsy.neutral1000"
                borderColor="extsy.neutral1000"
                opacity="1"
              />
              {DASHBOARD_OTHER_MENU.map((menu, index) => (
                <Flex
                  key={index}
                  gap="8px"
                  align="center"
                  cursor="pointer"
                  onClick={() => {
                    if (menu?.name === "Logout") {
                      onLogoutOpen();
                    } else if (menu?.path) {
                      navigate(menu.path);
                      onMobileMenuClose();
                    }
                  }}
                >
                  <menu.icon
                    size="24px"
                    color={
                      menu?.name === "Logout"
                        ? "#FF727F"
                        : isActive(menu?.path)
                        ? "#fff"
                        : "#A5A1A1"
                    }
                  />
                  {isActive(menu?.path) ? (
                    <GradientText
                      text={t(menu.key)}
                      bg="extsy.g1"
                      cursor="pointer"
                    />
                  ) : (
                    <CustomText
                      text={t(menu.key)}
                      color="extsy.neutral300"
                      cursor="pointer"
                    />
                  )}
                </Flex>
              ))}
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      {/* Logout Modal */}
      <LogoutModal isOpen={isLogoutOpen} onClose={onLogoutClose} />
    </>
  );
}

const UserProfile = ({ isMenu, isLoading, user }: any) => {
  const getIco = (plan: any) => {
    switch (plan?.name) {
      case "Standard":
        return PrimeIco;
      case "Premium":
        return SupremeIco;
      case "Ultra":
        return QuantlaneIco;
      default:
        return PrimeIco;
    }
  };
  return (
    <Flex align="center" gap="8px">
      {isLoading ? (
        <SkeletonCircle boxSize="48px" />
      ) : (
        <Avatar
          src={user?.raffle_avatar_url}
          name={user?.fullname}
          boxSize="48px"
          bg="extsy.g1"
          color="extsy.bg"
        />
      )}
      <Flex
        flexDir="column"
        gap="6px"
        display={{
          base: isMenu ? "flex" : "none",
          lg: "flex",
        }}
      >
        {isLoading ? (
          <SkeletonText noOfLines={1} w="70px" />
        ) : (
          <CustomText
            text={user?.fullname}
            color="extsy.baseWhite"
            weight="600"
            isLoading={isLoading}
          />
        )}
        {isLoading ? (
          <SkeletonText noOfLines={1} w="50px" />
        ) : (
          <CustomText text={`@${user?.short_id}`} size="10px" weight="500" />
        )}
        <Flex
          gap="10px"
          align="center"
          display={{
            base: "flex",
            lg: "none",
          }}
        >
          <Flex
            display={
              user?.user_subscription ? { base: "flex", lg: "none" } : "none"
            }
            align="center"
            gap="4px"
          >
            {isLoading ? (
              <SkeletonText noOfLines={1} w="50px" />
            ) : (
              <>
                <Image
                  src={getIco(user?.user_subscription?.plan)}
                  alt="prime"
                  boxSize="12px"
                />
                <CustomText
                  text={user?.user_subscription?.plan?.name}
                  size="11px"
                  cursor="pointer"
                />
              </>
            )}
          </Flex>
          <Box
            h="10px"
            w="1.5px"
            bg="extsy.neutral1000"
            display={{
              base: "block",
              lg: "none",
            }}
          />
          {isLoading ? (
            <SkeletonText noOfLines={1} w="100px" />
          ) : !user?.kyc_level ||
            user?.kyc_level === 0 ||
            user?.kyc_status !== "APPROVED" ? (
            <Flex align="center" gap="4px">
              <IconShield size="16px" color="#fff" />
              <CustomText text="Verify Account" size="11px" weight="500" />
            </Flex>
          ) : (
            <Flex align="center" gap="4px">
              <IconRosetteDiscountCheckFilled size="16px" color="#0ECB81" />
              <CustomText text="Verified" size="11px" weight="500" />
            </Flex>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

const UserConnectWallet = ({ onDisconnectOpen, setIsConnected }: any) => {
  const { t } = useTranslation();
  const { open } = useWeb3Modal();
  const { isConnected, address, chain } = useAccount();
  const { mutate: addConnectWallet } = useAddConnectWallet();

  // Fetch network data
  const { data: networks } = useGetNetworks(20, 0); // Adjust limit and offset as needed

  // Determine which network ID to use, e.g., Ethereum
  const networkId = networks?.find(
    (network: any) => network?.name === chain?.name
  )?.id;

  // Trigger API call when wallet is connected and network ID is available
  useEffect(() => {
    if (isConnected && networkId) {
      addConnectWallet({ wallet_address: address, network_id: networkId });
      localStorage.setItem("isConnected", "true");
    }
  }, [isConnected, address, networkId, chain, addConnectWallet]);
  return (
    <Button
      bg="extsy.g1"
      borderRadius="12px"
      height={{ base: "34px", lg: "36px" }}
      display="flex"
      alignItems="center"
      ps="4px"
      pe="16px"
      gap="10px"
      _hover={{ bg: "extsy.g1" }}
      onClick={() => {
        if (isConnected) {
          onDisconnectOpen();
        } else {
          open();
        }
      }}
    >
      <Image
        src={WalletIcon}
        alt="wallet"
        boxSize={{
          base: "25px",
          lg: "24px",
        }}
      />

      <CustomText
        text={
          isConnected
            ? address?.substring(0, 4) + "****" + address?.slice(-6)
            : t("connectWallet")
        }
        size={{ base: "12px", lg: "12px" }}
        weight={{ base: "400", lg: "600" }}
        family="Metropolis"
        color="extsy.bg"
        cursor="pointer"
        ls="0.28px"
      />
      {isConnected && <IconX size={18} color="#000" />}
    </Button>
  );
};

const UserNotifications = ({
  isNotificaitonOpen,
  setIsNotificationOpen,
  setIsLangOpen,
  notifications,
  isLoading,
}: any) => {
  return (
    <ActionButton
      onClick={() => {
        setIsNotificationOpen(!isNotificaitonOpen);
        setIsLangOpen(false);
      }}
      img={NotificationIco}
      child={
        isNotificaitonOpen && (
          <Notifications notifications={notifications} isLoading={isLoading} />
        )
      }
    />
  );
};

const UserLanguages = ({
  isLangOpen,
  setIsLangOpen,
  setIsNotificationOpen,
}: any) => {
  return (
    <ActionButton
      onClick={() => {
        setIsNotificationOpen(false);
        setIsLangOpen(!isLangOpen);
      }}
      img={LanguageIco}
      child={isLangOpen && <Languages />}
    />
  );
};

const ActionButton = ({ onClick, img, child }: any) => {
  return (
    <Flex
      flexDir="column"
      justify="center"
      align="center"
      height={{ base: "34px", lg: "48px" }}
      width={{ base: "34px", lg: "48px" }}
      bg="extsy.neutral1000"
      borderRadius="99px"
      border="1px solid"
      borderColor="extsy.neutral900"
      cursor="pointer"
      pos="relative"
      onClick={onClick}
    >
      <Image src={img} alt="menu" boxSize={{ base: "18px", lg: "24px" }} />
      {child}
    </Flex>
  );
};

export default Navbar;
