import {
  Avatar,
  Box,
  Flex,
  Image,
  Skeleton,
  SkeletonCircle,
  SkeletonText,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import CustomText from "../../components/text/CustomText";
import { IconChevronDown, IconChevronUp } from "@tabler/icons-react";
import ArrowBack from "../../assets/images/black-left.svg";
import ArrowForward from "../../assets/images/black-right.svg";
import { useTranslation } from "react-i18next";
import TableSearchInput from "../../components/form/TableSearchInput";
import SilverTrophy from "../../assets/images/silver-trophy.svg";
import BronzeTrophy from "../../assets/images/bronze-trophy.svg";
import GoldTrophy from "../../assets/images/trophy-cup.svg";
import OtherTrophy from "../../assets/images/dummy-trophy-ico.svg";
import { useGetLeaderBoard } from "../../utils/api/raffle.api";
import ReactPaginate from "react-paginate";

function LifeTimeEarnings() {
  const { t } = useTranslation();

  const [search, setSearch] = useState("");
  const [offset, setOffset] = useState(0);
  const [limit] = useState(5);
  const [pageCount, setPageCount] = useState(0);

  const {
    data: leaderboard,
    isLoading: leaderboardLoading,
    refetch,
  } = useGetLeaderBoard(limit, offset, search);

  // Invoke when user click to request another page.
  const handlePageClick = (event: any) => {
    setOffset(event?.selected * limit);
  };

  const getOrdinalSuffix = (number: number) => {
    // Convert number to an integer, just in case it's passed as a string
    const num = parseInt(number?.toString(), 10);

    // Get the last two digits to handle exceptions like 11th, 12th, 13th
    const lastTwoDigits = num % 100;

    // Get the last digit of the number
    const lastDigit = num % 10;

    // Special cases for 11, 12, 13
    if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
      return `${num}th`;
    }

    // General cases
    switch (lastDigit) {
      case 1:
        return `${num}st`;
      case 2:
        return `${num}nd`;
      case 3:
        return `${num}rd`;
      default:
        return `${num}th`;
    }
  };

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset]);

  useEffect(() => {
    const refetchTimeOut = setTimeout(() => {
      refetch();
    }, 1500);
    return () => clearTimeout(refetchTimeOut);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    if (leaderboard?.total) {
      setPageCount(Math.ceil(leaderboard?.total / limit));
    }
  }, [leaderboard, limit]);

  return (
    <Box
      p={{ base: "16px", lg: "24px" }}
      bg="extsy.neutral1000"
      boxShadow="0px 3px 4px 0px rgba(0, 0, 0, 0.03);"
      borderRadius={{ base: "16px", lg: "24px" }}
    >
      <Flex flexDir="column" gap={{ base: "16px", lg: "24px" }} w="100%">
        <Flex
          align="center"
          justify="space-between"
          flexDir={{
            base: "column",
            md: "row",
          }}
          gap="16px"
        >
          <CustomText
            text="Lifetime Earnings Leaderboard: Top EXTSY Raffle Winners"
            size={{ base: "18px", lg: "24px" }}
            weight={{ base: "600", lg: "700" }}
            lh={{ lg: "34px" }}
            family="Metropolis"
            color="extsy.baseWhite"
          />
          <TableSearchInput
            placeholder="Search Raffle"
            minW={{ base: "100%", md: "250px" }}
            maxW={{ base: "100%", md: "250px" }}
            value={search}
            onChange={(e: any) => setSearch(e.target.value)}
          />
        </Flex>
        <TableContainer>
          <Table border="1px solid" color="extsy.neutral900">
            <Thead bg="extsy.neutral900" height="40px">
              <Tr>
                <Th borderColor="extsy.neutral900">
                  <Flex align="center" gap="4px">
                    <CustomText
                      text={"Place"}
                      size="13px"
                      color="extsy.baseWhite"
                      weight="500"
                      ls="-0.13px"
                    />
                    <Flex flexDir="column">
                      <IconChevronUp
                        cursor="pointer"
                        color="#FAFAFA"
                        size="14px"
                      />
                      <IconChevronDown
                        color="#FAFAFA"
                        size="14px"
                        cursor="pointer"
                        style={{
                          marginTop: -7,
                        }}
                      />
                    </Flex>
                  </Flex>
                </Th>
                <Th
                  borderColor="extsy.neutral900"
                  maxW="583px"
                  minW={{ base: "100px", "1xl": "260px" }}
                >
                  <Flex align="center" gap="4px">
                    <CustomText
                      text={"Username"}
                      size="13px"
                      color="extsy.baseWhite"
                      weight="500"
                      ls="-0.13px"
                    />
                    <Flex flexDir="column">
                      <IconChevronUp
                        cursor="pointer"
                        color="#FAFAFA"
                        size="14px"
                      />
                      <IconChevronDown
                        color="#FAFAFA"
                        size="14px"
                        cursor="pointer"
                        style={{
                          marginTop: -7,
                        }}
                      />
                    </Flex>
                  </Flex>
                </Th>
                <Th
                  borderColor="extsy.neutral900"
                  maxW="583px"
                  minW={{ base: "100px", "1xl": "260px" }}
                >
                  <Flex align="center" gap="4px">
                    <CustomText
                      text={"Winning Prize"}
                      size="13px"
                      color="extsy.baseWhite"
                      weight="500"
                      ls="-0.13px"
                    />
                    <Flex flexDir="column">
                      <IconChevronUp
                        cursor="pointer"
                        color="#FAFAFA"
                        size="14px"
                      />
                      <IconChevronDown
                        color="#FAFAFA"
                        size="14px"
                        cursor="pointer"
                        style={{
                          marginTop: -7,
                        }}
                      />
                    </Flex>
                  </Flex>
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {leaderboardLoading
                ? Array.from({ length: 4 }).map((_, index) => (
                    <LoadingRow key={index} />
                  ))
                : leaderboard?.data?.map((earning: any, index: number) => (
                    <Tr key={index}>
                      <Td
                        height="54px"
                        borderColor="extsy.neutral900"
                        borderRight="1px solid"
                        py="0"
                      >
                        <Flex align="center" gap="6px">
                          <Flex
                            height="32px"
                            width="32px"
                            align="center"
                            justify="center"
                            bg="extsy.bg"
                            borderRadius="8px"
                          >
                            <Image
                              src={
                                earning?.position === 1
                                  ? GoldTrophy
                                  : earning?.position === 2
                                  ? SilverTrophy
                                  : earning?.position === 3
                                  ? BronzeTrophy
                                  : OtherTrophy
                              }
                              alt="trophy"
                              h="18px"
                              w="13px"
                            />
                          </Flex>
                          <CustomText
                            text={`${getOrdinalSuffix(earning?.position)}`}
                            size="14px"
                            weight="500"
                          />
                        </Flex>
                      </Td>
                      <Td
                        borderColor="extsy.neutral900"
                        borderRight="1px solid"
                      >
                        <Flex align="center" gap="12px">
                          <Avatar
                            boxSize="32px"
                            src={earning?.raffle_avatar_url}
                          />
                          <CustomText
                            text={earning?.raffle_name || "Anonymous"}
                            size="14px"
                            weight="500"
                          />
                        </Flex>
                      </Td>
                      <Td
                        borderColor="extsy.neutral900"
                        borderRight="1px solid"
                      >
                        <CustomText
                          text={`${earning?.total_won} USDT`}
                          size="14px"
                          weight="500"
                        />
                      </Td>
                    </Tr>
                  ))}
            </Tbody>
          </Table>
        </TableContainer>
        <Flex gap="16px" px="20px" align="center" justify="flex-end">
          {leaderboardLoading ? (
            <SkeletonText noOfLines={1} w="120px" />
          ) : (
            <>
              <CustomText
                text={`${offset + 1}-${offset + leaderboard?.data?.length} ${t(
                  "of"
                )} ${leaderboard?.total}`}
                size="13px"
                weight="500"
                color="extsy.neutral300"
              />
              <ReactPaginate
                breakLabel="..."
                nextLabel={
                  <Image
                    src={ArrowForward}
                    alt="arrow-forward"
                    cursor="pointer"
                  />
                }
                previousLabel={
                  <Image src={ArrowBack} alt="arrow-back" cursor="pointer" />
                }
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                pageCount={pageCount}
                renderOnZeroPageCount={null}
                containerClassName="react-pagination"
                activeClassName="active-pagination"
              />
            </>
          )}
        </Flex>
      </Flex>
    </Box>
  );
}

const LoadingRow = () => {
  return (
    <Tr>
      <Td
        height="54px"
        borderColor="extsy.neutral900"
        borderRight="1px solid"
        py="0"
        pos="relative"
        overflow="hidden"
      >
        <Flex align="center" gap="8px">
          <Skeleton height="32px" w="32px" borderRadius="4px" />
          <SkeletonText noOfLines={1} w="80px" />
        </Flex>
      </Td>
      <Td borderColor="extsy.neutral900" borderRight="1px solid">
        <Flex align="center" gap="12px">
          <SkeletonCircle boxSize="32px" />
          <SkeletonText noOfLines={1} w="120px" />
        </Flex>
      </Td>
      <Td borderColor="extsy.neutral900" borderRight="1px solid">
        <SkeletonText noOfLines={1} w="120px" />
      </Td>
    </Tr>
  );
};

export default LifeTimeEarnings;
