import { Box } from "@chakra-ui/react";
import { IconChevronLeft, IconChevronRight } from "@tabler/icons-react";
import React from "react";
import Calendar from "react-calendar";

function CustomCalendar({
  value,
  onChange,
  isCalendarOpen,
  isRangePicker,
  fromPayment,
  ref,
}: any) {
  return (
    <Box
      pos="absolute"
      borderRadius="8px"
      bg="extsy.bg"
      border="1px solid"
      borderColor="extsy.neutral900"
      width={{ base: "326px", md: "416px" }}
      height={{ base: "360px", md: "416px" }}
      top={fromPayment ? "auto" : "0"}
      bottom={fromPayment ? "0" : "auto"}
      right="0"
      mr={{ base: "-200px", sm: "0px" }}
      mt="45px"
      zIndex="100"
      display={isCalendarOpen ? "block" : "none"}
      color="extsy.white"
      ref={ref}
    >
      <Calendar
        value={value}
        onChange={onChange}
        nextLabel={<IconChevronRight size="20px" color="#fff" />}
        prevLabel={<IconChevronLeft size="20px" color="#fff" />}
        next2Label={null}
        prev2Label={null}
        selectRange={isRangePicker}
      />
    </Box>
  );
}

export default CustomCalendar;
