import {
  Box,
  Flex,
  Image,
  Input,
  SkeletonCircle,
  SkeletonText,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import CustomText from "../text/CustomText";
import { IconSearch } from "@tabler/icons-react";
import { CryptoAsset } from "../../utils/types/types";

const CryptoCurrencyDropdown = ({
  activeCurrency,
  onClick,
  coins,
  isLoading,
}: any) => {
  const { t } = useTranslation();

  const [search, setSearch] = useState<string>("");

  return (
    <Flex
      flexDir="column"
      p="15px 12px 16px 16px"
      bg="extsy.neutral900"
      borderRadius="16px"
      border="1px solid"
      borderColor="extsy.neutral800"
      flexDirection="column"
      position={"absolute"}
      width="90%"
      right="0"
      mr="25px"
      zIndex="10000"
      maxH="372px"
      overflowY="auto"
      gap="12px"
    >
      <Flex align="center" justify="space-between">
        <CustomText text={t("coin")} color="extsy.white" family="Metropolis" />
        <CustomText text={t("price")} color="extsy.white" family="Metropolis" />
      </Flex>
      <Box position="relative">
        <IconSearch
          size="16px"
          color="#fff"
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            marginTop: "8px",
            marginLeft: "8px",
          }}
        />
        <Input
          border="1px solid"
          borderColor="extsy.text"
          borderRadius="999px"
          pl="30px"
          pe="16px"
          py="8px"
          h="33px"
          fontSize="14px"
          fontFamily="Metropolis"
          placeholder="Search Coin"
          color="extsy.white"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          _hover={{
            borderColor: "extsy.primary100",
          }}
          _focus={{
            borderColor: "extsy.primary100",
          }}
          _focusVisible={{
            borderColor: "extsy.primary100",
          }}
        />
      </Box>
      <Flex flexDir="column" gap="4px">
        {isLoading
          ? [...Array(5)].map((_, index) => (
              <Flex
                key={index}
                justify="space-between"
                align="center"
                p="8px"
                justifyContent="space-between"
                cursor="pointer"
                borderRadius="4px"
              >
                <Flex alignItems="center" gap="10px">
                  <SkeletonCircle size="24px" />
                  <SkeletonText noOfLines={1} w="50px" />
                </Flex>
                <SkeletonText w="120px" noOfLines={1} />
              </Flex>
            ))
          : coins
              ?.filter(
                (coin: CryptoAsset) =>
                  coin?.symbol_short
                    ?.toLowerCase()
                    ?.includes(search?.toLowerCase()) ||
                  coin?.name?.toLowerCase()?.includes(search?.toLowerCase())
              )
              ?.map((currency: CryptoAsset, index: number) => (
                <Flex
                  key={index}
                  justify="space-between"
                  align="center"
                  p="8px"
                  justifyContent="space-between"
                  cursor="pointer"
                  onClick={() => {
                    onClick(currency);
                  }}
                  borderRadius="4px"
                  bg={
                    activeCurrency?.id === currency.id ? "extsy.baseWhite" : ""
                  }
                >
                  <Flex alignItems="center" gap="10px">
                    <Image
                      src={currency?.logo_url}
                      alt={currency?.symbol_short}
                      height="24px"
                      width="24px"
                    />
                    <Flex flexDir="column">
                      <CustomText
                        text={currency.symbol_short}
                        color={
                          activeCurrency?.id === currency?.id
                            ? "extsy.dark300"
                            : "extsy.neutral300"
                        }
                        family="Metropolis"
                        cursor="pointer"
                      />
                      <CustomText
                        text={currency.name}
                        color={
                          activeCurrency?.id === currency?.id
                            ? "extsy.dark300"
                            : "extsy.neutral300"
                        }
                        family="Metropolis"
                        cursor="pointer"
                      />
                    </Flex>
                  </Flex>
                  <CustomText
                    text={`$${currency.price}`}
                    color={
                      activeCurrency?.id === currency?.id
                        ? "extsy.dark300"
                        : "extsy.neutral300"
                    }
                    family="Metropolis"
                    cursor="pointer"
                  />
                </Flex>
              ))}{" "}
      </Flex>
    </Flex>
  );
};

export default CryptoCurrencyDropdown;
