import { useEffect, useRef } from "react";

const OutsideClickHandler = ({ isOpen, onClose, children }: any) => {
  const divRef = useRef<any>(null);

  const handleClickOutside = (event: any) => {
    if (divRef.current && !divRef?.current?.contains(event.target)) {
      onClose();
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    // Cleanup the event listener when the component unmounts or isOpen changes
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return isOpen ? <div ref={divRef}>{children}</div> : null;
};

export default OutsideClickHandler;
