import { Box, Input, Tooltip } from "@chakra-ui/react";
import { IconAlertCircle } from "@tabler/icons-react";
import React from "react";

function VCardInput({
  type,
  placeholder,
  value,
  onChange,
  rightIco,
  bg,
  radius,
  maxW,
  color,
  invalid,
  errorMsg,
  validation,
  register,
  name,
}: any) {
  return (
    <Box pos="relative">
      <Input
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        border={invalid ? "1px solid" : "none"}
        borderColor={invalid ? "extsy.danger" : ""}
        borderRadius={radius || "16px"}
        color={color || "extsy.baseWhite"}
        fontSize="16px"
        fontFamily="Metropolis"
        pl="16px"
        maxW={maxW || "700px"}
        bg={bg || "extsy.neutral1000"}
        height={"56px"}
        pr={rightIco ? "30px" : "auto"}
        padding={"9.5px 19px"}
        _placeholder={{
          color: "extsy.text",
          fontSize: "16px",
          fontWeight: "400",
          fontFamily: "Metropolis",
        }}
        _focusVisible={{
          border: "none",
        }}
        {...register(name, validation)}
      />
      {rightIco && (
        <Box pos="absolute" right="0" top="0" mt="24px" mr="16px">
          {rightIco}
        </Box>
      )}
      {invalid && (
        <Tooltip label={errorMsg}>
          <Box pos="absolute" right="0" top="0" mt="18px" mr="16px">
            <IconAlertCircle size="18px" color="#ED3D4E" />
          </Box>
        </Tooltip>
      )}
    </Box>
  );
}

export default VCardInput;
