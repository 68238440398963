import { Avatar, Box, Circle, Flex, Image, Input } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import CustomText from "../../../components/text/CustomText";
import {
  IconChecks,
  IconMessage,
  IconPhotoPlus,
  IconSend,
} from "@tabler/icons-react";
import { Message } from "../../../utils/types/types";
import { useGetLoggedInUser } from "../../../utils/api/auth.api";
import { socket } from "../../../utils/sockets";

const ChatModule = ({ collapses, orderDetailData, setCollapses }: any) => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [message, setMessage] = useState<string>("");
  const [media, setMedia] = useState<File | null>(null);
  const [chatRoomID, setChatRoomID] = useState<number | null>(null);

  const { data: userData } = useGetLoggedInUser();

  const sendMessage = () => {
    if (message.trim() || media) {
      const payload = {
        chatRoomID,
        senderID: userData?.id,
        content: message,
      };
      console.log(payload);
      socket.emit("sendMessage", payload);
      setMessage("");
      setMedia(null);
    }
  };

  const joinChatRoom = (roomID: any) => {
    const payload = { chatRoomID: roomID };
    socket.emit("joinChatRoom", payload);
    setChatRoomID(roomID);
    getMessages(roomID);
  };
  const getMessages = (roomID: any) => {
    const payload = { chatRoomID: roomID };
    socket.emit("getMessages", payload);
  };

  const handleSocketResponse = (response: any) => {
    console.log("Socket response received:", response);
    switch (response.event) {
      case "chatRoomCreated":
        console.log("Chat room created:", response.data);
        joinChatRoom(response.data.chatRoomID);
        break;
      case "newMessage":
        setMessages((prevMessages) => [...prevMessages, response.data]);
        break;
      default:
        console.log("Unhandled event:", response.event);
        break;
    }
  };
  useEffect(() => {
    if (socket?.connected) {
      onConnect();
    }
    function onConnect() {
      console.log("object");
      const payload = {
        orderItemID: orderDetailData?.chat_room_id,
        buyerID: orderDetailData?.buyer_id,
        sellerID: orderDetailData?.seller_id,
      };
      socket.emit("createChatRoom", payload);
    }
    // Handle connection and chat room creation
    socket.on("connect", onConnect);

    // Listen for the chatRoomCreated event
    socket.on("chatRoomCreated", handleSocketResponse);

    // Cleanup function
    return () => {
      socket.off("connect", onConnect);
      socket.off("chatRoomCreated", handleSocketResponse);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderDetailData]);

  return (
    <Flex
      flexDir="column"
      w="100%"
      borderLeft={collapses?.chat ? "1px solid" : "none"}
      borderRight={collapses?.chat ? "1px solid" : "none"}
      borderColor="extsy.neutral800"
      borderRadius="16px"
      mb={{ base: "10px", lg: "0px" }}
    >
      <Flex
        p={{ base: "16px", lg: "16px 24px" }}
        bg="extsy.neutral800"
        borderRadius={collapses?.chat ? "8px 8px 0px 0px" : "8px"}
        align="center"
        gap="16px"
      >
        <Box pos="relative">
          <Avatar
            boxSize={{ base: "40px", lg: "50px" }}
            src={userData?.logo_url}
          />
          <Circle
            size={{ base: "15px", lg: "20px" }}
            bg="#15B097"
            border="3px solid"
            borderColor="extsy.neutral800"
            pos="absolute"
            right="0"
            bottom="0"
            mr="-5px"
            mb="-2px"
          />
        </Box>
        <Flex flexDir="column" gap="4px">
          <CustomText
            text={orderDetailData?.u_fullname}
            size={{ base: "20px", lg: "25px" }}
            weight="500"
            color="extsy.baseWhite"
          />
          <CustomText
            text={userData.fullname}
            size={{ base: "12px", lg: "16px" }}
            color="extsy.baseWhite"
          />
        </Flex>
        <Flex
          align="center"
          justify="center"
          height="36px"
          width="36px"
          bg="extsy.g1"
          borderRadius="8px"
          cursor="pointer"
          ml="auto"
          display={collapses?.chat ? "none" : "flex"}
          onClick={() =>
            setCollapses({
              orderDetails: false,
              chat: !collapses?.chat,
            })
          }
        >
          <IconMessage size="19px" color={"#000"} />
        </Flex>
      </Flex>
      <Flex
        p="16px 16px 30px 16px"
        h="100%"
        flexDir="column"
        gap="16px"
        maxH="72.5vh"
        overflowY="auto"
        display={collapses?.chat ? "flex" : "none"}
      >
        {messages?.map((message: any, index: number) => (
          <Flex
            key={index}
            gap="16px"
            justify={message?.isMe ? "flex-end" : "flex-start"}
            align={message?.attachment ? "flex-end" : "flex-start"}
          >
            <Avatar
              boxSize="40px"
              order={message?.isMe ? "1" : "0"}
              mb="25px"
            />
            <Flex
              flexDir="column"
              gap="8px"
              order={message?.isMe ? "0" : "1"}
              maxW="380px"
              w="100%"
            >
              {message?.media ? (
                <Image
                  src={message?.media}
                  alt="attachemtn"
                  height="228px"
                  width="200px"
                  ml={message?.isMe ? "auto" : ""}
                />
              ) : (
                <Box
                  p="16px"
                  bg="extsy.neutral900"
                  borderRadius={
                    message?.isMe ? "16px 16px 0px 16px" : "0px 16px 16px 16px"
                  }
                  w="100%"
                >
                  <CustomText
                    text={message.message}
                    size="14px"
                    color="extsy.baseWhite"
                  />
                </Box>
              )}
              <Flex
                align="center"
                gap="8px"
                justify={message?.isMe ? "end" : "start"}
              >
                <CustomText
                  text={message?.time}
                  size="12.8px"
                  color="extsy.neutral500"
                />
                {message?.isMe && (
                  <IconChecks
                    color={message?.isRead ? "#E35CAD" : "#BAB8B8"}
                    size="16px"
                  />
                )}
              </Flex>
            </Flex>
          </Flex>
        ))}
        <Box
          bg="extsy.neutral900"
          borderRadius="16px"
          p="16px 24px"
          maxW="470px"
          mx="auto"
        >
          <CustomText
            text="You have marked the order as paid, please wait for seller to confirm and release the asset."
            size="14px"
            family="Metropolis"
            align="center"
          />
        </Box>
        <Box
          bg="extsy.neutral900"
          borderRadius="16px"
          p="16px 24px"
          maxW="470px"
          mx="auto"
        >
          <CustomText
            text="The order has been cancelled. Please contract customer support if you have any questions."
            size="14px"
            family="Metropolis"
            align="center"
          />
        </Box>
      </Flex>
      <Flex
        p="16px"
        cursor="pointer"
        borderRadius="16px"
        bg="extsy.neutral800"
        justify="space-between"
        align="center"
        gap="16px"
        h={{ base: "73px", lg: "93px" }}
        zIndex="100"
        display={collapses?.chat ? "flex" : "none"}
      >
        <Input
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          type="text"
          fontSize="16px"
          fontWeight="400"
          fontFamily="Metropolis"
          color="extsy.baseWhite"
          placeholder="Type your message..."
          pl="0"
          border="none"
          _placeholder={{ color: "extsy.neutral500" }}
          _hover={{
            border: "none",
          }}
          _focus={{
            border: "none",
          }}
          _focusVisible={{ border: "none" }}
        />
        <Flex gap="16px" align="center">
          <Flex
            align="center"
            justify="center"
            height="36px"
            width="36px"
            bg="extsy.neutral700"
            borderRadius="8px"
            cursor="pointer"
            onClick={() => document.getElementById("fileInput")?.click()}
          >
            <IconPhotoPlus size="19px" color={"#FAFAFA"} />
            <input
              type="file"
              id="fileInput"
              style={{ display: "none" }}
              onChange={(e) => e.target.files && setMedia(e.target.files[0])}
            />
          </Flex>
          <Flex
            align="center"
            justify="center"
            height="36px"
            width="36px"
            bg="extsy.g1"
            borderRadius="8px"
            cursor="pointer"
            onClick={sendMessage}
          >
            <IconSend size="19px" color="#0B0A0A" />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ChatModule;
