import {
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import CustomText from "../../components/text/CustomText";
import PrivacySecurity from "./PrivacySecurity";
import WalletPlan from "./WalletPlan";
import { useSearchParams } from "react-router-dom";
import KYCVerification from "./KYCVerification";
import Report from "./Report";

function Settings() {
  const [tabIndex, setTabIndex] = useState(0);
  const [searchParams] = useSearchParams();

  const TABS = [
    "Privacy & Security",
    "KYC Verification",
    "Your Subscription  Plan",
    // "Report Suspicious Activity",
  ];

  useEffect(() => {
    // Check for the presence of the query parameter "wallet"
    const queryParams: any = searchParams?.get("payment_intent");
    if (queryParams) {
      setTabIndex(1); // Set tabIndex to 1 when "wallet" is present
    } else {
      setTabIndex(0); // Optional: reset to the first tab if "wallet" is not present
    }
  }, [searchParams]); // Dependency on location.search

  return (
    <Flex
      p={{ base: "16px", lg: "32px" }}
      flexDir="column"
      gap={{ base: "24px", lg: "40px" }}
      bg="extsy.neutral1000"
      border="1px solid"
      borderColor="extsy.neutral1000"
      borderRadius={{ base: "16px", lg: "24px" }}
    >
      <CustomText
        text="Settings"
        size={{ base: "18px", lg: "24px" }}
        lh={{ lg: "34px" }}
        weight={{ base: "600", lg: "700" }}
        family="Metropolis"
        color="extsy.white"
      />
      <Tabs
        index={tabIndex}
        onChange={(index) => {
          setTabIndex(index);
        }}
      >
        <TabList
          borderBottom="1px solid"
          borderColor="extsy.neutral900"
          overflowX={{ base: "auto", md: "inherit" }}
        >
          {TABS?.map((tab, index) => (
            <Tab
              key={index}
              px={{ base: "16px", md: "32px" }}
              pt="10px"
              pb="14px"
              color="extsy.secondary"
            >
              <CustomText
                text={tab}
                color={
                  index === tabIndex ? "extsy.baseWhite" : "extsy.neutral300"
                }
                cursor="pointer"
                wrap="nowrap"
              />
            </Tab>
          ))}
        </TabList>
        <TabPanels mt={{ base: "16px", lg: "40px" }}>
          <TabPanel p="0">
            <PrivacySecurity />
          </TabPanel>
          <TabPanel p="0">
            <KYCVerification />
          </TabPanel>
          <TabPanel p="0">
            <WalletPlan />
          </TabPanel>
          <TabPanel p="0">
            <Report />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
}

export default Settings;
