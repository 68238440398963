import { Flex, Image } from "@chakra-ui/react";
import React from "react";
import EmptyIco from "../../assets/images/empty-trx-ico.svg";
import CustomText from "../../components/text/CustomText";

function EmptyView({ tagline }: any) {
  return (
    <Flex
      minH={"calc(100vh - 315px)"}
      flexDir="column"
      gap="8px"
      align="center"
      justify="center"
    >
      <Image src={EmptyIco} alt="empty" height="32px" width="30px" />
      <CustomText text={tagline} size="14px" color="extsy.neutral400" />
    </Flex>
  );
}

export default EmptyView;
