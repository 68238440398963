import React, { useState } from "react";
import CustomText from "../text/CustomText";
import {
  Box,
  Circle,
  Flex,
  Image,
  Input,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack,
} from "@chakra-ui/react";
import { IconMinus, IconPlus } from "@tabler/icons-react";
import SliderThumbIco from "../../assets/images/slider-thumb.svg";
import SliderMarker from "../../assets/images/slider-marker.svg";
import PrimaryButton from "../button/PrimaryButton";

function Leverage({ setIsLeverageOpen, isLeverageOpen }: any) {
  const [activeLeverage, setActiveLeverage] = useState("20");

  return (
    <>
      <Flex
        cursor="pointer"
        gap="12px"
        align="center"
        justify="center"
        bg="extsy.neutral900"
        boxShadow="0px 2.667px 26.667px 0px rgba(0, 0, 0, 0.15)"
        borderRadius="999px"
        width="132px"
        h="38px"
        onClick={setIsLeverageOpen}
      >
        <CustomText
          text={`${activeLeverage}x`}
          size="12px"
          color="extsy.baseWhite"
          cursor="pointer"
        />
      </Flex>
      <Flex
        flexDir="column"
        gap="18px"
        p="24px 16px"
        borderRadius="16px"
        border="1px solid"
        borderColor="extsy.neutral800"
        bg="extsy.neutral900"
        pos="absolute"
        top="0"
        mt="45px"
        zIndex="150"
        width="100%"
        minH="300px"
        display={isLeverageOpen ? "flex" : "none"}
      >
        <CustomText
          text="Adjust Leverage"
          size="14px"
          color="extsy.baseWhite"
        />
        <Box pos="relative">
          <IconMinus
            size="16px"
            color="#848E9C"
            cursor="pointer"
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              zIndex: 1,
              marginTop: "13px",
              marginLeft: "12px",
            }}
          />
          <IconPlus
            size="16px"
            color="#EAECEF"
            cursor="pointer"
            style={{
              position: "absolute",
              top: "0",
              right: "0",
              zIndex: 1,
              marginTop: "13px",
              marginRight: "12px",
            }}
          />
          <Input
            value={activeLeverage}
            onChange={(e) => setActiveLeverage(e.target.value)}
            height="43px"
            p="8px 32px"
            textAlign="center"
            fontSize="14px"
            color="extsy.text2"
            bg="extsy.dark600"
            borderRadius="4px"
            border="none"
            _hover={{
              border: "none",
            }}
            _focus={{
              border: "none",
            }}
            _focusVisible={{
              border: "none",
            }}
          />
        </Box>
        <Slider
          value={Number(activeLeverage)}
          onChange={(val: any) => setActiveLeverage(val)}
        >
          <SliderMark value={0} mt="-4.5px" ml="-2px" zIndex="10">
            <Image src={SliderMarker} alt="marker" height="9px" width="9px" />
            <CustomText
              text="0X"
              size="12px"
              color="extsy.neutral400"
              mt="12px"
            />
          </SliderMark>
          <SliderMark value={25} mt="-4.5px" zIndex="10">
            <Image src={SliderMarker} alt="marker" height="9px" width="9px" />
            <CustomText
              text="25X"
              size="12px"
              color="extsy.neutral400"
              mt="12px"
            />
          </SliderMark>
          <SliderMark value={50} mt="-4.5px" zIndex="10">
            <Image src={SliderMarker} alt="marker" height="9px" width="9px" />
            <CustomText
              text="50X"
              size="12px"
              color="extsy.neutral400"
              mt="12px"
            />
          </SliderMark>
          <SliderMark value={75} mt="-4.5px" zIndex="10">
            <Image src={SliderMarker} alt="marker" height="9px" width="9px" />
            <CustomText
              text="75X"
              size="12px"
              color="extsy.neutral400"
              mt="12px"
            />
          </SliderMark>
          <SliderMark value={100} ml="-12px" mt="-4.5px" zIndex="10">
            <Image src={SliderMarker} alt="marker" height="9px" width="9px" />
            <CustomText
              text="100X"
              size="12px"
              color="extsy.neutral400"
              mt="12px"
            />
          </SliderMark>
          <SliderTrack bg="#474D57" height="4px">
            <SliderFilledTrack bg="extsy.neutral300" />
          </SliderTrack>
          <SliderThumb
            bg="transparent"
            p="0"
            boxShadow="none"
            boxSize="20px"
            zIndex="20"
          >
            <Image src={SliderThumbIco} alt="thumb" ml="-10px" />
          </SliderThumb>
        </Slider>
        <Flex mt="18px" gap="10px">
          <Circle size="4px" bg="extsy.neutral300" mt="4px" />
          <CustomText
            text="Minimum position at current leverage: 100,000.00 USDT"
            size="12px"
          />
        </Flex>
        <Flex gap="10px">
          <Circle size="4px" bg="extsy.neutral300" mt="4px" />
          <CustomText
            text="Please note that leverage changing will also apply for open position and open orders. "
            size="12px"
          />
        </Flex>
        <PrimaryButton
          title="Confirm"
          height="30px"
          size="12px"
          onClick={setIsLeverageOpen}
        />
      </Flex>
    </>
  );
}

export default Leverage;
