import { QueryCache } from "@tanstack/react-query";

export const apiURL = process.env.REACT_APP_PRE_RELEASE_APP_URL;
export const APP_LAYER = process.env.REACT_APP_APPLICATION_LAYER;

const queryCache = new QueryCache({
  onError: (error) => {
    console.log(error);
  },
  onSuccess: (data) => {
    console.log(data);
  },
});

async function client(
  endpoint: any,
  { data, headers: customHeaders, ...customConfig }: any = {}
) {
  const token = localStorage?.getItem("token");

  const config = {
    method: data?.method || data ? "POST" : "GET",
    body:
      endpoint === "auth/loginWithToken"
        ? undefined
        : data
        ? data?.access_token
          ? undefined
          : data instanceof FormData
          ? data
          : JSON.stringify(data)
        : undefined,
    headers: {
      Authorization:
        endpoint === "auth/resetPassword" || endpoint === "auth/loginWithToken"
          ? data?.token
          : token
          ? `${token}`
          : "",
      ...(!data || data instanceof FormData
        ? {}
        : { "Content-Type": "application/json" }),
      ...customHeaders,
    },
    ...customConfig,
  };

  return await window
    .fetch(`${apiURL}/${endpoint}`, config)
    .then(async (response) => {
      const contentType = response.headers.get("Content-Type");
      if (contentType?.includes("text/csv")) {
        const data = await response.text();
        return data;
      }
      if (response.status === 401) {
        queryCache.clear();
        const errorData = await response.json();
        const error = new Error(errorData.message || "Unauthorized");
        return Promise.reject(error);
      }

      if (response.ok) {
        const jsonData = await response.json();
        if (!jsonData?.success) {
          return Promise.reject(jsonData);
        } else {
          return jsonData?.data;
        }
      } else {
        const jsonData = await response.json();
        return Promise.reject(jsonData);
      }
    });
}

export { client };
