import { Flex, Text } from "@chakra-ui/react";
import React, { useEffect } from "react";
import CustomText from "../../components/text/CustomText";
import CustomInput from "../../components/form/CustomInput";
import GradientText from "../../components/text/GradientText";
import PrimaryButton from "../../components/button/PrimaryButton";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useForgotPassword } from "../../utils/api/auth.api";
import { useGeeTest } from "react-geetest-v4";
import { useCustomAlert } from "../../utils/api/alert";
import { WHITE_LISTED_EMAILS } from "../../utils/constants/constants";

const Enteremail = ({ onNext }: { onNext: (payload: any) => void }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { captcha, state } = useGeeTest("4cf4ef62613aca348306dce9ba7f62c7", {
    product: "bind",
    protocol: "https://",
    // containerId: 'geetest-captcha',
  });
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm();

  const forgotPassword = useForgotPassword();
  const alert = useCustomAlert();

  const handleForgotPassword = (payload: any) => {
    let captchaData: any = {};
    if (!WHITE_LISTED_EMAILS.includes(payload.email)) {
      captchaData = captcha ? captcha.getValidate() : {};
      if (!captchaData) {
        return;
      }
    }
    const formData = {
      ...payload,
      lot_number: captchaData?.lot_number || "",
      captcha_output: captchaData?.captcha_output || "",
      pass_token: captchaData?.pass_token || "",
      gen_time: captchaData?.gen_time || "",
      captcha_id: captchaData?.captcha_id || "",
      sign_token: captchaData?.sign_token || "",
    };

    forgotPassword
      .mutateAsync(formData)
      .then((result) => {
        alert?.mutate({
          message: result?.message,
          status: "success",
        });
        // setTimeout(() => {
        onNext(payload);
        // }, 1500);
        reset();
      })
      .catch((error) => {
        alert?.mutate({
          message:
            error?.exception && Array.isArray(error.exception)
              ? error.exception[0]
              : error?.message,
          status: "danger",
        });
      });
  };
  const onSubmit = handleSubmit((data) => {
    handleForgotPassword(data);
  });
  useEffect(() => {
    if (state === "success") {
      onSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        if (WHITE_LISTED_EMAILS.includes(getValues("email"))) {
          onSubmit();
          return;
        }
        if (state !== "success") {
          captcha?.showCaptcha();
          onSubmit();
        } else onSubmit();
      }}
    >
      <Flex flexDir="column" gap="16px">
        <Flex w="100%" flexDir={"column"} gap={"64px"}>
          <Flex align="center" flexDir="column" gap="32px">
            <CustomText
              text={t("Enter Your Email ID")}
              family="Metropolis"
              size="24px"
              lh="24px"
              weight="400"
              align="center"
              color="extsy.baseWhite"
            />
            <CustomText
              text={
                <Text color="extsy.neutral300">
                  {t("Enter your registered email ID to receive a")}{" "}
                  <Text as="span" color="extsy.baseWhite">
                    {t("Verification code.")}
                  </Text>
                </Text>
              }
              align="center"
              color="extsy.neutral300"
              fontWeight={"600"}
              mx={"8px"}
              lh={"20px"}
            />
          </Flex>

          <Flex flexDir="column" pb={"50px"}>
            <Flex flexDir="column">
              <CustomText
                text={t("Email")}
                color="extsy.neutral500"
                size="12px"
              />
              <CustomInput
                placeholder={t("Enter your email")}
                type="email"
                register={register}
                validation={{
                  required: true,
                }}
                name="email"
                invalid={errors.email}
                errorMsg={"Email is required"}
              />
            </Flex>
          </Flex>

          <Flex flexDir="column" gap="24px" align="center">
            <PrimaryButton
              title={t("Verification Code")}
              type="submit"
              isLoading={forgotPassword.isPending}
            />
            <Flex align="center" gap="4px">
              <CustomText
                text={t("Remember Password?")}
                color="extsy.neutral300"
                lh="19px"
              />
              <GradientText
                text={t("Sign in")}
                weight="600"
                bg="extsy.g1"
                lh="22px"
                cursor="pointer"
                onClick={() => {
                  navigate("/auth/login");
                }}
              />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </form>
  );
};

export default Enteremail;
