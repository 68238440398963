import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { client2 } from "./api-client2";

export const useGetCoinBalance = (coinId?: string) => {
  return useQuery<any, Error>({
    queryKey: ["getCoinBalance"], // Query key in the object form
    queryFn: async () => {
      const response = await client2(`trade/getBalance?coin_id=${coinId}`);
      if (response.error) {
        throw new Error(response.error);
      }
      return response;
    },
    enabled: !!coinId,
  });
};

export const useGetBalance = (
  limit: number,
  offset: number,
  search?: string,
  sortBy?: string,
  sortOrder?: string
) => {
  return useQuery<any, Error>({
    queryKey: ["getBalance"], // Query key in the object form
    queryFn: async () => {
      const params = new URLSearchParams({
        limit: limit.toString(),
        offset: offset.toString(),
      });

      if (search) params.append("search", search);
      if (sortBy) params.append("sortBy", sortBy);
      if (sortOrder) params.append("sortOrder", sortOrder);

      const response = await client2(`trade/getBalance?${params.toString()}`);
      if (response.error) {
        throw new Error(response.error);
      }
      return response;
    },
  });
};

export const useDeposit = () => {
  const queryClient = useQueryClient();

  return useMutation<any, Error, any>({
    mutationFn: async (payload) => {
      const response = await client2(`trade/deposit`, {
        method: "POST",
        data: payload,
      });

      if (response.error) {
        throw new Error(response.error);
      }
      queryClient.invalidateQueries({ queryKey: ["getBalance"] });
      return response;
    },
  });
};

export const useWithdraw = () => {
  const queryClient = useQueryClient();

  return useMutation<any, Error, any>({
    mutationFn: async (payload) => {
      const response = await client2(`trade/withdraw`, {
        method: "POST",
        data: payload,
      });

      if (response.error) {
        throw new Error(response.error);
      }
      queryClient.invalidateQueries({ queryKey: ["getBalance"] });
      return response;
    },
  });
};

export const useTransfer = () => {
  const queryClient = useQueryClient();

  return useMutation<any, Error, any>({
    mutationFn: async (payload) => {
      const response = await client2(`trade/transfer`, {
        method: "POST",
        data: payload,
      });

      if (response.error) {
        throw new Error(response.error);
      }
      queryClient.invalidateQueries({ queryKey: ["getBalance"] });
      return response;
    },
  });
};

export const useCreateSpotOrder = () => {
  const queryClient = useQueryClient();

  return useMutation<any, Error, any>({
    mutationFn: async (payload) => {
      const response = await client2(`trade/createSpotOrder`, {
        method: "POST",
        data: payload,
      });

      if (response.error) {
        throw new Error(response.error);
      }
      queryClient.invalidateQueries({ queryKey: ["getBalance"] });
      queryClient.invalidateQueries({ queryKey: ["getOrders"] });
      queryClient.invalidateQueries({ queryKey: ["getBaseTradeBalance"] });
      queryClient.invalidateQueries({ queryKey: ["getAssetTradeBalance"] });
      return response;
    },
  });
};

export const useGetTradePairs = (baseAsset?: any, search?: any) =>
  useQuery({
    queryKey: ["getTradePairs"],
    queryFn: async () => {
      const response = await client2(
        `trade/getTradePairs?baseAsset=${baseAsset}&search=${search}`
      );
      return response;
    },
  });

export const useGetPairInfo = (pairTicker?: string) => {
  return useQuery({
    queryKey: ["getPairInfo"],
    queryFn: async () => {
      const response = await client2(
        `trade/getTradePairs?search=${pairTicker}`
      );
      return response;
    },
  });
};

export const useGetTradeBaseAssets = () => {
  return useQuery({
    queryKey: ["getTradeBaseAssets"],
    queryFn: async () => {
      const response = await client2(`trade/getTradeBaseAssets`);
      return response;
    },
  });
};

export const useGetBaseTradeBalance = (coinId?: string) => {
  return useQuery<any, Error>({
    queryKey: ["getBaseTradeBalance"], // Query key in the object form
    queryFn: async () => {
      const response = await client2(`trade/getBalance?coin_id=${coinId}`);
      if (response.error) {
        throw new Error(response.error);
      }
      return response;
    },
    enabled: !!coinId,
  });
};

export const useGetAssetTradeBalance = (coinId?: string) => {
  return useQuery<any, Error>({
    queryKey: ["getAssetTradeBalance"], // Query key in the object form
    queryFn: async () => {
      const response = await client2(`trade/getBalance?coin_id=${coinId}`);
      if (response.error) {
        throw new Error(response.error);
      }
      return response;
    },
    enabled: !!coinId,
  });
};

export const useCancelOrder = () => {
  const queryClient = useQueryClient();

  return useMutation<any, Error, any>({
    mutationFn: async (payload) => {
      const response = await client2(`trade/cancelSpotOrder`, {
        method: "POST",
        data: payload,
      });

      if (response.error) {
        throw new Error(response.error);
      }
      queryClient.invalidateQueries({ queryKey: ["getOpenOrders"] });
      return response;
    },
  });
};

export const useGetOpenOrders = (
  limit: number,
  offset: number,
  isFilled?: boolean,
  sortBy?: string,
  sortOrder?: string
) => {
  return useQuery({
    queryKey: ["getOpenOrders"],
    queryFn: async () => {
      const params = new URLSearchParams({
        limit: limit.toString(),
        offset: offset.toString(),
      });

      if (isFilled) params.append("isFilled", isFilled.toString());
      if (sortBy) params.append("sortBy", sortBy);
      if (sortOrder) params.append("sortOrder", sortOrder);

      const response = await client2(`trade/getOrders?${params.toString()}`);
      return response;
    },
  });
};

export const useGetFilledOrders = (
  limit: number,
  offset: number,
  isFilled?: boolean,
  sortBy?: string,
  sortOrder?: string
) => {
  return useQuery({
    queryKey: ["getFilledOrders"],
    queryFn: async () => {
      const params = new URLSearchParams({
        limit: limit.toString(),
        offset: offset.toString(),
      });

      if (isFilled) params.append("isFilled", isFilled.toString());
      if (sortBy) params.append("sortBy", sortBy);
      if (sortOrder) params.append("sortOrder", sortOrder);

      const response = await client2(`trade/getOrders?${params.toString()}`);
      return response;
    },
  });
};

export const useGetTradeOrders = (
  limit: number,
  offset: number,
  isFilled?: boolean,
  sortBy?: string,
  sortOrder?: string
) => {
  return useQuery({
    queryKey: ["getTradeOrders"],
    queryFn: async () => {
      const params = new URLSearchParams({
        limit: limit.toString(),
        offset: offset.toString(),
      });

      if (isFilled) params.append("isFilled", isFilled.toString());
      if (sortBy) params.append("sortBy", sortBy);
      if (sortOrder) params.append("sortOrder", sortOrder);

      const response = await client2(`trade/getOrders?${params.toString()}`);
      return response;
    },
  });
};
