import {
  Box,
  Flex,
  Image,
  Input,
  Skeleton,
  Tab,
  TabList,
  Tabs,
  useOutsideClick,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import CustomText from "../../../components/text/CustomText";
import TableSearchInput from "../../../components/form/TableSearchInput";
import { IconCheck, IconChevronDown } from "@tabler/icons-react";
import SellTable from "./SellTable";
import {
  useAssets,
  useBuySellHistory,
  useGetFiats,
} from "../../../utils/api/p2p.api";
import EmptyView from "../../transactionHistory/EmptyView";

function Sell() {
  const [isCurrencyOpen, setIsCurrencyOpen] = useState(false);
  const [isRefreshOpen, setIsRefreshOpen] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [refreshAd, setRefreshAd] = useState("Refresh Ad");
  const [selectedTabId, setSelectedTabId] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchAmount, setSearchAmount] = useState("");
  const [offset, setOffset] = useState(0);
  const [limit] = useState(10);
  const [pageNo, setPageNo] = useState(0);
  const [sortBy, setSortBy] = useState("");
  const [sortDirection, setSortDirection] = useState("ASC");
  const [selectedTabName, setSelectedTabName] = useState<any>(null);
  const { data: fiats } = useGetFiats();
  const { data: assetData, isLoading: assetLoading } = useAssets();
  const { data: coinData, isLoading: coinLoading } = useBuySellHistory(
    searchTerm,
    searchAmount,
    selectedCurrency,
    pageNo,
    limit,
    sortBy,
    sortDirection,
    selectedTabId || assetData?.[0]?.id,
    "SELL"
  );
  const TABS = assetData?.map((dat: any) => ({
    id: dat.id, // Assuming each item has a unique 'id'
    name: dat.binance_name,
    logo: dat.logo_url,
  }));

  const REFRESH_ADS = ["Every 5s", "Every 10s", "Every 20s"];

  const dropdownRef: any = useRef(null);
  const dropdownRefAd: any = useRef(null);

  useOutsideClick({
    ref: dropdownRef,
    handler: () => {
      setIsCurrencyOpen(false);
    },
  });

  useOutsideClick({
    ref: dropdownRefAd,
    handler: () => {
      setIsRefreshOpen(false);
    },
  });

  useEffect(() => {
    if (assetData && assetData.length > 0) {
      setSelectedTabId(assetData[0].id);
      setSelectedTabName(assetData[0].binance_name);
    }
  }, [assetData]);

  return (
    <div>
      <Flex
        align="flex-start"
        justify="space-between"
        gap={{
          base: "24px",
          "1xl": "0px",
        }}
        flexDir={{
          base: "column",
          xl: "row",
        }}
      >
        <Tabs
          onChange={(index) => setSelectedTabId(TABS[index]?.id)}
          defaultIndex={0}
        >
          <TabList
            borderBottom="1px solid"
            borderColor="extsy.neutral900"
            maxW={"610px"}
            overflowX={{ base: "auto", sm: "scroll" }}
            position="relative"
          >
            {assetLoading
              ? Array.from({ length: 3 }).map(
                  (
                    _,
                    index // Render 3 skeletons as placeholders
                  ) => (
                    <Skeleton
                      key={index}
                      height="40px"
                      width="100px"
                      mx="8px"
                    />
                  )
                )
              : TABS?.map((tab: any) => (
                  <Tab
                    key={tab.id}
                    px={{ base: "16px", lg: "32px" }}
                    pt="10px"
                    pb="14px"
                    color={
                      selectedTabId === tab.id
                        ? "extsy.baseWhite"
                        : "extsy.secondary100"
                    }
                    onClick={() => {
                      setSelectedTabId(tab.id);
                      setSelectedTabName(tab.name);
                    }}
                    position="relative"
                    _before={{
                      content: '""',
                      position: "absolute",
                      width: "100%",
                      height: "2px",
                      bottom: "0",
                      left: "0",
                      backgroundColor:
                        selectedTabId === tab.id
                          ? "extsy.baseWhite"
                          : "transparent",
                    }}
                    cursor="pointer"
                  >
                    <Flex align={"center"} w={"130px"} gap={"10px"}>
                      <Image src={tab?.logo} w={"16px"} alt="" />
                      <CustomText
                        text={tab?.id?.toUpperCase()}
                        cursor="pointer"
                        color={
                          selectedTabId === tab.id
                            ? "extsy.baseWhite"
                            : "extsy.neutral300"
                        }
                      />
                    </Flex>
                  </Tab>
                ))}
          </TabList>
        </Tabs>
        <Flex
          align="center"
          gap="10px"
          flexDir={{
            base: "column",
            md: "row",
          }}
        >
          <TableSearchInput
            placeholder="Search"
            value={searchTerm}
            onChange={(e: any) => setSearchTerm(e.target.value)}
          />
          <Flex gap="10px" align="center">
            <Box pos="relative">
              <Flex
                align="center"
                gap="32px"
                h="38px"
                ps="24px"
                pe="10px"
                borderRadius="666px"
                bg="extsy.neutral900"
                boxShadow="0px 2.667px 26.667px 0px rgba(0, 0, 0, 0.15)"
              >
                <Input
                  h="38px"
                  py="0"
                  pl="0"
                  value={searchAmount}
                  onChange={(e: any) => setSearchAmount(e.target.value)}
                  border="none"
                  // type="number"
                  placeholder="Transaction Amount"
                  fontSize="12px"
                  fontFamily="Metropolis"
                  color="extsy.baseWhite"
                  _placeholder={{ color: "extsy.neutral500" }}
                  _hover={{
                    border: "none",
                  }}
                  _focus={{
                    border: "none",
                  }}
                  _focusVisible={{ border: "none" }}
                />
                <Flex
                  align="center"
                  gap="8px"
                  cursor="pointer"
                  onClick={() => {
                    setIsCurrencyOpen(!isCurrencyOpen);
                    setIsRefreshOpen(false);
                  }}
                >
                  <CustomText text="|" size="12px" />
                  <CustomText
                    text={selectedCurrency}
                    size="12px"
                    color="extsy.baseWhite"
                  />
                  <IconChevronDown size="16px" color="#fafafa" />
                </Flex>
              </Flex>
              <Flex
                p="16px"
                borderRadius="8px"
                bg="extsy.bg"
                flexDir="column"
                gap="16px"
                w="100px"
                display={isCurrencyOpen ? "flex" : "none"}
                pos="absolute"
                right="0"
                top="0"
                mt="45px"
                ref={dropdownRef}
              >
                {fiats?.map((currency: any, index: any) => (
                  <Flex
                    key={index}
                    align="center"
                    justify="space-between"
                    gap="8px"
                    cursor="pointer"
                    onClick={() => {
                      setSelectedCurrency(currency.symbol);
                      setIsCurrencyOpen(false);
                    }}
                  >
                    <CustomText
                      text={currency.symbol}
                      size="14px"
                      color={
                        currency.symbol === selectedCurrency
                          ? "extsy.baseWhite"
                          : "extsy.neutral300"
                      }
                      cursor
                    />
                    {currency.symbol === selectedCurrency && (
                      <IconCheck size="16px" color="#fafafa" />
                    )}
                  </Flex>
                ))}
              </Flex>
            </Box>
            <Box pos="relative">
              <Flex
                align="center"
                gap="32px"
                h="38px"
                ps="24px"
                pe="10px"
                borderRadius="666px"
                bg="extsy.neutral900"
                boxShadow="0px 2.667px 26.667px 0px rgba(0, 0, 0, 0.15)"
                onClick={() => {
                  setIsRefreshOpen(!isRefreshOpen);
                  setIsCurrencyOpen(false);
                }}
              >
                <CustomText
                  text={refreshAd}
                  size="12px"
                  color="extsy.baseWhite"
                  wrap="nowrap"
                />
                <IconChevronDown size="16px" color="#fafafa" />
              </Flex>
              <Flex
                p="16px"
                borderRadius="8px"
                bg="extsy.bg"
                flexDir="column"
                gap="16px"
                w="100px"
                display={isRefreshOpen ? "flex" : "none"}
                pos="absolute"
                right="0"
                top="0"
                mt="45px"
                ref={dropdownRefAd}
              >
                {REFRESH_ADS.map((interval, index) => (
                  <Flex
                    key={index}
                    align="center"
                    justify="space-between"
                    gap="8px"
                    cursor="pointer"
                    onClick={() => {
                      setRefreshAd(interval);
                      setIsRefreshOpen(false);
                    }}
                  >
                    <CustomText
                      text={interval}
                      size="14px"
                      color={
                        interval === refreshAd
                          ? "extsy.baseWhite"
                          : "extsy.neutral300"
                      }
                      cursor
                    />
                    {interval === refreshAd && (
                      <IconCheck size="16px" color="#fafafa" />
                    )}
                  </Flex>
                ))}
              </Flex>
            </Box>
          </Flex>
        </Flex>
      </Flex>
      <Box mt="40px">
        {!coinLoading && !coinData?.length ? (
          <EmptyView tagline="No Data Available" />
        ) : (
          <SellTable
            data={coinData}
            tabName={selectedTabName}
            isLoading={coinLoading}
            sortBy={sortBy}
            sortDirection={sortDirection}
            setSortBy={setSortBy}
            setSortDirection={setSortDirection}
            offset={offset}
            setPageCount={setPageNo}
            limit={limit}
            pageCount={pageNo}
            setOffset={setOffset}
          />
        )}
      </Box>
    </div>
  );
}

export default Sell;
