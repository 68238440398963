import { Box, Flex, useDisclosure } from "@chakra-ui/react";
import React, { useState } from "react";
import CustomText from "../../../components/text/CustomText";
import VCardInput from "../../../components/form/vCardInput";
import { IconCalendar, IconMinus } from "@tabler/icons-react";
import CustomSelect from "../../../components/form/CustomSelect";
import {
  useAddBanks,
  useGetBanks,
  useGetCountries,
} from "../../../utils/api/p2p.api";
import CustomModal from "../../../components/modal/customModal";
import { useForm } from "react-hook-form";
import { useCustomAlert } from "../../../utils/api/alert";
import CustomCalendar from "../../../components/form/CustomCalendar";

function Step2({ sell, adData, setAdData }: any) {
  const [bank, setBank] = useState<any>(null);
  const {
    register: bankRegister,
    handleSubmit: bankHandleSubmit,
    reset: bankReset,
    formState: { errors: bankErrors, isSubmitting: bankIsSubmitting },
  } = useForm();
  const {
    isOpen: isAddBankOpen,
    onOpen: openAddBank,
    onClose: closeAddBank,
  } = useDisclosure();
  const { data: countriesData } = useGetCountries();
  const [selectedCountry, setSelectedCountry] = useState({ id: "", name: "" });
  const [currentDate, setCurrentDate] = useState<any>();
  const [date, setDate] = useState<any>(null);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const handleCountrySelect = (option: any) => {
    setSelectedCountry({ id: option.value, name: option.label });
  };
  const countryOptions = countriesData?.map((country: any) => ({
    value: country?.id, // Store the ID here
    label: country?.name, // Display the name
  }));
  const addBankMutation = useAddBanks();
  const alert = useCustomAlert();
  const { data: banksData, isLoading: bankLoading } = useGetBanks();
  const onSubmitBank = async (data: any) => {
    try {
      const payload = {
        bankName: data.bankName,
        accountName: data.accountName,
        countryID: selectedCountry?.id,
        zipCode: data.zipCode,
        accountNo: data.accountNumber,
        expiryDate: date,
      };
      await addBankMutation.mutateAsync(payload);
      alert?.mutate({
        message: "Bank added successfully",
        status: "success",
      });

      closeAddBank();
    } catch (error: any) {
      alert?.mutate({
        message:
          error?.exception && Array.isArray(error.exception)
            ? error.exception[0]
            : error?.message,
        status: "danger",
      });
    }
  };

  const formatedDate = () => {
    const dateObj = new Date(date);
    return dateObj.toLocaleDateString();
  };

  return (
    <Flex flexDir="column" gap={{ base: "16px", md: "32px" }}>
      <Flex
        align="center"
        justify="space-between"
        gap={{ base: "16px", md: "86px" }}
        flexDir={{
          base: "column",
          md: "row",
        }}
      >
        <Flex flexDir="column" gap="8px" w="100%">
          <CustomText text="Total Amount" size="14px" />
          <VCardInput
            value={adData.totalTradingAmount}
            onChange={(e: any) => {
              setAdData({
                ...adData,
                totalTradingAmount: Number(e.target.value),
              });
            }}
            placeholder="0.00"
            bg="extsy.neutral900"
            maxW="100%"
            radius="12px"
            register={() => {}}
            color="extsy.neutral300"
            rightIco={<CustomText text="USDT" size="14px" />}
          />
          <CustomText text="≈ $64.10" size="14px" />
        </Flex>
        {sell && (
          <Flex flexDir="column" gap="8px" w="100%">
            <CustomText text="Available" size="14px" />
            <Flex
              align={{ md: "center" }}
              justify="space-between"
              gap={{ base: "16px", md: "0px" }}
              flexDir={{
                base: "column",
                md: "row",
              }}
            >
              <CustomText
                text="20.276372890 USDT"
                size={{ base: "22px", md: "32px" }}
                weight="700"
                color="extsy.secondary"
              />
              <Flex
                h="56px"
                align="center"
                justify="center"
                px="16px"
                bg="extsy.neutral700"
                border="1px dashed"
                borderColor="extsy.neutral500"
                borderRadius="12px"
                maxW={{ md: "152px" }}
                w="100%"
              >
                <CustomText
                  text="Deposit"
                  size="14px"
                  color="extsy.baseWhite"
                />
              </Flex>
            </Flex>
            <CustomText text="≈ $64.10" size="14px" />
          </Flex>
        )}
      </Flex>
      <Flex gap={{ base: "8px", md: "32px" }} align="center">
        <Flex flexDir="column" gap="8px" w="100%">
          <CustomText text="Order Limit" size="14px" />
          <VCardInput
            placeholder="0.00"
            bg="extsy.neutral900"
            maxW="100%"
            value={adData.orderLowerLimit}
            onChange={(e: any) => {
              setAdData({
                ...adData,
                orderLowerLimit: Number(e.target.value),
              });
            }}
            radius="12px"
            register={() => {}}
            color="extsy.neutral300"
            rightIco={<CustomText text="$" size="14px" />}
          />
          <CustomText text="≈ 3.59 USDT" size="14px" />
        </Flex>
        <Box
          display={{
            base: "none",
            md: "block",
          }}
        >
          <IconMinus size={24} color="#FFFFFF" />
        </Box>
        <Flex flexDir="column" gap="8px" w="100%" mt="22px">
          <VCardInput
            placeholder="0.00"
            bg="extsy.neutral900"
            maxW="100%"
            radius="12px"
            value={adData.orderUpperLimit}
            onChange={(e: any) => {
              setAdData({
                ...adData,
                orderUpperLimit: Number(e.target.value),
              });
            }}
            register={() => {}}
            color="extsy.neutral300"
            rightIco={<CustomText text="$" size="14px" />}
          />
          <CustomText text="≈ 2690.50 USDT" size="14px" />
        </Flex>
      </Flex>
      <Flex
        gap={{ base: "16px", md: "85px" }}
        align="center"
        flexDir={{
          base: "column",
          md: "row",
        }}
      >
        <Flex flexDir="column" gap="8px" w="100%">
          <CustomText text="Payment Method" size="14px" />
          <Flex
            gap="16px"
            flexDir={{
              base: "column",
              md: "row",
            }}
          >
            <Box w={"60%"}>
              <CustomSelect
                placeholder={bank?.label || "Select Bank"}
                value={bank?.value}
                options={banksData?.map((bank: any) => ({
                  value: bank?.id,
                  label: bank?.bankName,
                }))}
                onSelect={(option: any) => {
                  console.log(option, "selected option");
                  setBank(option);
                  setAdData({ ...adData, paymentMethod: option.value });
                }}
                isLoading={bankLoading}
              />
            </Box>
            <Flex
              px="16px"
              w="40%"
              h="56px"
              border="1px dashed"
              borderColor="extsy.neutral500"
              bg="extsy.neutral700"
              borderRadius="12px"
              cursor="pointer"
              align="center"
              justify="center"
              onClick={openAddBank}
            >
              <CustomText
                text="Add New Payment Method"
                size="12px"
                color="extsy.baseWhite"
                cursor
              />
            </Flex>
          </Flex>
        </Flex>
        <Flex flexDir="column" gap="8px" w="100%">
          <CustomText text="Payment Time Limit" size="14px" />
          <VCardInput
            placeholder="15 Mins"
            bg="extsy.neutral900"
            maxW="100%"
            radius="12px"
            value={adData.paymentTimeInMin}
            onChange={(e: any) => {
              setAdData({
                ...adData,
                paymentTimeInMin: Number(e.target.value),
              });
            }}
            register={() => {}}
            color="extsy.neutral300"
            rightIco={<CustomText text="$" size="14px" />}
          />
        </Flex>
      </Flex>
      <CustomModal
        isOpen={isAddBankOpen}
        onClose={() => {
          closeAddBank();
          bankReset();
        }}
        onSubmit={bankHandleSubmit(onSubmitBank)}
        isLoading={bankIsSubmitting || addBankMutation.isPending}
        isDisabled={bankIsSubmitting || addBankMutation.isPending}
        headerText={"Add Payment Method"}
        btnText={"Add"}
        closeBtntext={"Cancel"}
        width={"600px"}
        bgtopBlur="extsy.bgCardTopBlur"
        blurPos="right-top"
      >
        <Flex
          flexDir={"column"}
          gap={"32px"}
          py={{ base: "16px", lg: "32px" }}
          px={{ base: "16px", lg: "24px" }}
        >
          <Box>
            <CustomText text="Country Region" />
            <Flex
              mt="16px"
              gap="16px"
              align="center"
              flexDir={{
                base: "column",
                lg: "row",
              }}
            >
              <Flex flexDir="column" gap="12px" w="100%">
                <CustomText text="Country" size="14px" />
                <CustomSelect
                  options={countryOptions}
                  onSelect={handleCountrySelect}
                  placeholder={selectedCountry.name || "Select Country"}
                />
              </Flex>
              <Flex flexDir="column" gap="12px" w="100%">
                <CustomText text="Zip Code" size="14px" />
                <VCardInput
                  placeholder=""
                  type="text"
                  register={bankRegister}
                  name="zipCode"
                  validation={{
                    required: "Zip code is required",
                  }}
                  invalid={!!bankErrors.zipCode}
                  errorMsg={bankErrors.zipCode?.message}
                  bg={"extsy.neutral900"}
                />
              </Flex>
            </Flex>
          </Box>
          <Box>
            <CustomText text="Card Information" />
            <Flex flexDir="column" mt="16px" gap="12px" w="100%">
              <CustomText text="Bank Name" size="14px" />
              <VCardInput
                placeholder=""
                type="text"
                register={bankRegister}
                name="bankName"
                validation={{
                  required: "Bank name is required",
                }}
                invalid={!!bankErrors.bankName}
                errorMsg={bankErrors.bankName?.message}
                bg={"extsy.neutral900"}
              />
            </Flex>
            <Flex flexDir="column" mt="16px" gap="12px" w="100%">
              <CustomText text="Account Name" size="14px" />
              <VCardInput
                placeholder="account name"
                type="text"
                register={bankRegister}
                name="accountName"
                validation={{
                  required: "Account Name is required",
                }}
                invalid={!!bankErrors.accountName}
                errorMsg={bankErrors.accountName?.message}
                bg={"extsy.neutral900"}
              />
            </Flex>
            <Flex flexDir="column" mt="16px" gap="12px" w="100%">
              <CustomText text="Account Number" size="14px" />
              <VCardInput
                placeholder="0000000000"
                type="text"
                register={bankRegister}
                name="accountNumber"
                validation={{
                  required: "Account number is required",
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "Account number must be a number",
                  },
                }}
                invalid={!!bankErrors.accountNumber}
                errorMsg={bankErrors.accountNumber?.message}
                bg={"extsy.neutral900"}
              />
            </Flex>
            <Flex flexDir="column" mt="16px" gap="12px" w="100%">
              <CustomText text="Expiry Date" size="14px" />
              <Box pos="relative">
                <Flex
                  cursor="pointer"
                  gap="16px"
                  align="center"
                  borderRadius="12px"
                  bg="extsy.neutral900"
                  boxShadow="0px 2.667px 26.667px 0px rgba(0, 0, 0, 0.15)"
                  height="56px"
                  ps="24px"
                  pe="10px"
                  onClick={() => setIsCalendarOpen(!isCalendarOpen)}
                >
                  <CustomText
                    cursor
                    text={currentDate ? formatedDate() : "Date"}
                    size="12px"
                    color="extsy.baseWhite"
                  />
                  <IconCalendar size="16px" color="#fafafa" />
                </Flex>

                {isCalendarOpen && (
                  <CustomCalendar
                    isCalendarOpen={isCalendarOpen}
                    value={currentDate}
                    fromPayment={true}
                    onChange={(val: any) => {
                      const selectedDate = new Date(val);
                      // Adjust date to UTC midnight
                      const utcDate = new Date(
                        Date.UTC(
                          selectedDate.getFullYear(),
                          selectedDate.getMonth(),
                          selectedDate.getDate()
                        )
                      );
                      setCurrentDate(utcDate.toISOString());
                      setDate(utcDate.toISOString());
                      setIsCalendarOpen(false);
                    }}
                  />
                )}
              </Box>
            </Flex>
          </Box>
        </Flex>
      </CustomModal>
    </Flex>
  );
}

export default Step2;
