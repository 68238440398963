import {
  Avatar,
  Flex,
  Image,
  SkeletonText,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import CustomText from "../../../components/text/CustomText";
import { IconChevronDown, IconChevronUp } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import ArrowBack from "../../../assets/images/black-left.svg";
import ArrowForward from "../../../assets/images/black-right.svg";
import CustomButton from "../../../components/button/CustomButton";
import OrderDetails from "./OrderDetails";
import ReactPaginate from "react-paginate";

function OrderTable({
  data,
  isLoading,
  sortDirection,
  setSortBy,
  setSortDirection,
  offset,
  setPageCount,
  limit,
  pageCount,
  setOffset,
}: any) {
  const { t } = useTranslation();

  const {
    isOpen: isViewOpen,
    onOpen: onViewOpen,
    onClose: onViewClose,
  } = useDisclosure();

  const [selectedOrder, setSelectedOrder] = useState<any>();

  const getBgColor = (status: string) => {
    switch (status) {
      case "COMPLETED":
        return "#56CE77";
      case "IN_PROCESS":
        return "#FFA333";
      case "CANCELLED":
        return "#FF6574";
      default:
        return "extsy.neutral300";
    }
  };

  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * limit) % data?.data?.length;
    setOffset(newOffset);
  };

  useEffect(() => {
    if (data?.total) {
      setPageCount(Math.ceil(data?.total / limit));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, limit]);

  return (
    <Flex flexDir="column" gap="24px">
      <TableContainer
        minH="55vh"
        maxW={{ base: "1164px", "1xl": "100%" }}
        overflowX="auto"
      >
        <Table border="1px solid" color="extsy.neutral900">
          <Thead bg="extsy.neutral900" height="40px">
            <Tr>
              <Th borderColor="extsy.neutral900">
                <Flex align="center" gap="4px">
                  <CustomText
                    text={"Coin Type"}
                    size="13px"
                    color="extsy.baseWhite"
                    weight="500"
                    ls="-0.13px"
                  />
                  <Flex
                    flexDir="column"
                    cursor={"pointer"}
                    onClick={() => {
                      setSortBy("coin");
                      if (sortDirection === "ASC") {
                        setSortDirection("DESC");
                      } else {
                        setSortDirection("ASC");
                      }
                    }}
                  >
                    <IconChevronUp
                      cursor="pointer"
                      color="#FAFAFA"
                      size="14px"
                    />
                    <IconChevronDown
                      color="#FAFAFA"
                      size="14px"
                      cursor="pointer"
                      style={{
                        marginTop: -7,
                      }}
                    />
                  </Flex>
                </Flex>
              </Th>
              <Th borderColor="extsy.neutral900">
                <Flex align="center" gap="4px">
                  <CustomText
                    text={"Order ID"}
                    size="13px"
                    color="extsy.baseWhite"
                    weight="500"
                    ls="-0.13px"
                  />
                  <Flex
                    flexDir="column"
                    cursor={"pointer"}
                    onClick={() => {
                      setSortBy("orderId");
                      if (sortDirection === "ASC") {
                        setSortDirection("DESC");
                      } else {
                        setSortDirection("ASC");
                      }
                    }}
                  >
                    <IconChevronUp
                      cursor="pointer"
                      color="#FAFAFA"
                      size="14px"
                    />
                    <IconChevronDown
                      color="#FAFAFA"
                      size="14px"
                      cursor="pointer"
                      style={{
                        marginTop: -7,
                      }}
                    />
                  </Flex>
                </Flex>
              </Th>
              <Th borderColor="extsy.neutral900">
                <Flex align="center" gap="4px" justify="end">
                  <CustomText
                    text="Amount"
                    size="13px"
                    color="extsy.baseWhite"
                    weight="500"
                    ls="-0.13px"
                  />
                  <Flex
                    flexDir="column"
                    cursor={"pointer"}
                    onClick={() => {
                      setSortBy("amount");
                      if (sortDirection === "ASC") {
                        setSortDirection("DESC");
                      } else {
                        setSortDirection("ASC");
                      }
                    }}
                  >
                    <IconChevronUp
                      cursor="pointer"
                      color="#FAFAFA"
                      size="14px"
                    />
                    <IconChevronDown
                      color="#FAFAFA"
                      size="14px"
                      cursor="pointer"
                      style={{
                        marginTop: -7,
                      }}
                    />
                  </Flex>
                </Flex>
              </Th>
              <Th borderColor="extsy.neutral900">
                <Flex align="center" gap="4px" justify="end">
                  <CustomText
                    text="Price"
                    size="13px"
                    color="extsy.baseWhite"
                    weight="500"
                    ls="-0.13px"
                  />
                  <Flex
                    flexDir="column"
                    cursor={"pointer"}
                    onClick={() => {
                      setSortBy("price");
                      if (sortDirection === "ASC") {
                        setSortDirection("DESC");
                      } else {
                        setSortDirection("ASC");
                      }
                    }}
                  >
                    <IconChevronUp
                      cursor="pointer"
                      color="#FAFAFA"
                      size="14px"
                    />
                    <IconChevronDown
                      color="#FAFAFA"
                      size="14px"
                      cursor="pointer"
                      style={{
                        marginTop: -7,
                      }}
                    />
                  </Flex>
                </Flex>
              </Th>
              <Th borderColor="extsy.neutral900">
                <Flex align="center" gap="4px" justify="end">
                  <CustomText
                    text="Quantity"
                    size="13px"
                    color="extsy.baseWhite"
                    weight="500"
                    ls="-0.13px"
                  />
                  <Flex
                    flexDir="column"
                    cursor={"pointer"}
                    onClick={() => {
                      setSortBy("quantity");
                      if (sortDirection === "ASC") {
                        setSortDirection("DESC");
                      } else {
                        setSortDirection("ASC");
                      }
                    }}
                  >
                    <IconChevronUp
                      cursor="pointer"
                      color="#FAFAFA"
                      size="14px"
                    />
                    <IconChevronDown
                      color="#FAFAFA"
                      size="14px"
                      cursor="pointer"
                      style={{
                        marginTop: -7,
                      }}
                    />
                  </Flex>
                </Flex>
              </Th>
              <Th borderColor="extsy.neutral900">
                <Flex align="center" gap="4px">
                  <CustomText
                    text="Time / Posted by"
                    size="13px"
                    color="extsy.baseWhite"
                    weight="500"
                    ls="-0.13px"
                  />
                  <Flex
                    flexDir="column"
                    cursor={"pointer"}
                    onClick={() => {
                      setSortBy("createdAt");
                      if (sortDirection === "ASC") {
                        setSortDirection("DESC");
                      } else {
                        setSortDirection("ASC");
                      }
                    }}
                  >
                    <IconChevronUp
                      cursor="pointer"
                      color="#FAFAFA"
                      size="14px"
                    />
                    <IconChevronDown
                      color="#FAFAFA"
                      size="14px"
                      cursor="pointer"
                      style={{
                        marginTop: -7,
                      }}
                    />
                  </Flex>
                </Flex>
              </Th>
              <Th borderColor="extsy.neutral900">
                <Flex align="center" gap="4px">
                  <CustomText
                    text="Status"
                    size="13px"
                    color="extsy.baseWhite"
                    weight="500"
                    ls="-0.13px"
                  />
                  <Flex
                    flexDir="column"
                    cursor={"pointer"}
                    onClick={() => {
                      setSortBy("status");
                      if (sortDirection === "ASC") {
                        setSortDirection("DESC");
                      } else {
                        setSortDirection("ASC");
                      }
                    }}
                  >
                    <IconChevronUp
                      cursor="pointer"
                      color="#FAFAFA"
                      size="14px"
                    />
                    <IconChevronDown
                      color="#FAFAFA"
                      size="14px"
                      cursor="pointer"
                      style={{
                        marginTop: -7,
                      }}
                    />
                  </Flex>
                </Flex>
              </Th>
              <Th borderColor="extsy.neutral900">
                <Flex align="center" gap="4px">
                  <CustomText
                    text={t("actions")}
                    size="13px"
                    color="extsy.baseWhite"
                    weight="500"
                    ls="-0.13px"
                  />
                </Flex>
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {isLoading
              ? [1, 2, 3, 4, 5]?.map((_, index) => <LoadingRow key={index} />)
              : data?.data?.map((order: any, index: any) => {
                  const formattedDate = new Date(
                    order?.created_at
                  ).toLocaleDateString("en-US", {
                    month: "short", // 'Aug'
                    day: "numeric", // '28'
                    year: "numeric", // '2024'
                  });

                  const formattedTime = new Date(
                    order?.created_at
                  ).toLocaleTimeString("en-US", {
                    hour: "2-digit", // '11'
                    minute: "2-digit", // '40'
                    hour12: false, // 24-hour format; remove this line for 12-hour format
                  });
                  return (
                    <Tr key={index}>
                      <Td
                        height="64px"
                        borderColor="extsy.neutral900"
                        borderRight="1px solid"
                        py="0"
                      >
                        <Flex gap="12px" align="center">
                          <Avatar src={order?.coin_logo_url} boxSize="24px" />
                          <Flex gap="8px" flexDir="column">
                            <CustomText
                              text={order?.coin_symbol || "Symbol"}
                              size="14px"
                              color="extsy.baseWhite"
                            />
                            <CustomText
                              text={`${order?.coin_name || "Coin Name"}`}
                              size="12px"
                              color="extsy.text5"
                            />
                          </Flex>
                        </Flex>
                      </Td>
                      <Td
                        borderColor="extsy.neutral900"
                        borderRight="1px solid"
                      >
                        <Flex align="center" gap="6px">
                          <CustomText
                            text={order?.order_type}
                            size="12px"
                            color={
                              order?.order_type === "BUY"
                                ? "#15B097"
                                : "extsy.danger"
                            }
                          />
                          <CustomText
                            text="|"
                            size="12px"
                            color="extsy.neutral900"
                          />
                          <CustomText text={order?.order_id} size="12px" />
                        </Flex>
                      </Td>
                      <Td
                        borderColor="extsy.neutral900"
                        borderRight="1px solid"
                      >
                        <CustomText
                          text={`${order?.amount || 0} USDT`}
                          size="14px"
                          weight="500"
                          align="end"
                        />
                      </Td>
                      <Td
                        borderColor="extsy.neutral900"
                        borderRight="1px solid"
                      >
                        <CustomText
                          text={`$${order?.price || 0} USDT`}
                          size="14px"
                          weight="500"
                          align="end"
                        />
                      </Td>
                      <Td
                        borderColor="extsy.neutral900"
                        borderRight="1px solid"
                      >
                        <CustomText
                          text={`${order?.quantity || 0} USDT`}
                          size="14px"
                          weight="500"
                          align="end"
                        />
                      </Td>
                      <Td
                        borderColor="extsy.neutral900"
                        borderRight="1px solid"
                      >
                        <CustomText
                          text={`${formattedDate} ${formattedTime}`}
                          size="14px"
                          weight="500"
                        />
                      </Td>
                      <Td
                        borderColor="extsy.neutral900"
                        borderRight="1px solid"
                      >
                        <Flex
                          w="90px"
                          h="24px"
                          align="center"
                          justify="center"
                          borderRadius="30px"
                          bg={`${getBgColor(order?.status)}1A`}
                        >
                          <CustomText
                            text={order?.status}
                            size="10px"
                            color={getBgColor(order?.status)}
                          />
                        </Flex>
                      </Td>
                      <Td
                        borderColor="extsy.neutral900"
                        borderRight="1px solid"
                      >
                        <CustomButton
                          title="View Order"
                          bg="extsy.neutral800"
                          color="extsy.baseWhite"
                          height="28px"
                          size="12px"
                          onClick={() => {
                            setSelectedOrder(order);
                            onViewOpen();
                          }}
                        />
                      </Td>
                    </Tr>
                  );
                })}
          </Tbody>
        </Table>
      </TableContainer>
      <Flex gap="16px" px="20px" align="center" justify="flex-end">
        {isLoading ? (
          <SkeletonText noOfLines={1} w="120px" />
        ) : (
          <>
            <CustomText
              text={`${offset + 1}-${offset + data?.data?.length} ${t("of")} ${
                data?.data?.length
              }`}
              size="13px"
              weight="500"
              color="extsy.neutral300"
            />
            <ReactPaginate
              breakLabel="..."
              nextLabel={
                <Image
                  src={ArrowForward}
                  alt="arrow-forward"
                  cursor="pointer"
                />
              }
              previousLabel={
                <Image src={ArrowBack} alt="arrow-back" cursor="pointer" />
              }
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              pageCount={pageCount}
              renderOnZeroPageCount={null}
              containerClassName="react-pagination"
              activeClassName="active-pagination"
            />
          </>
        )}
      </Flex>
      <OrderDetails
        isOpen={isViewOpen}
        onClose={onViewClose}
        onOpen={onViewOpen}
        orderData={selectedOrder}
      />
    </Flex>
  );
}

export default OrderTable;

const LoadingRow = () => {
  return (
    <Tr>
      <Td
        height="54px"
        borderColor="extsy.neutral900"
        borderRight="1px solid"
        py="0"
        pos="relative"
        overflow="hidden"
      >
        <SkeletonText noOfLines={1} w="80px" />
      </Td>
      <Td borderColor="extsy.neutral900" borderRight="1px solid">
        <Flex align="center" gap="12px">
          <SkeletonText noOfLines={1} w="40px" />
        </Flex>
      </Td>
      <Td borderColor="extsy.neutral900" borderRight="1px solid">
        <SkeletonText noOfLines={1} w="80px" />
      </Td>
      <Td borderColor="extsy.neutral900" borderRight="1px solid">
        <SkeletonText noOfLines={1} w="40px" />
      </Td>

      <Td borderColor="extsy.neutral900" borderRight="1px solid">
        <SkeletonText noOfLines={1} w="40px" />
      </Td>
      <Td borderColor="extsy.neutral900" borderRight="1px solid">
        <SkeletonText noOfLines={1} w="40px" />
      </Td>
      <Td borderColor="extsy.neutral900" borderRight="1px solid">
        <SkeletonText noOfLines={1} w="60px" />
      </Td>
      <Td borderColor="extsy.neutral900" borderRight="1px solid">
        <SkeletonText noOfLines={1} w="40px" />
      </Td>
    </Tr>
  );
};
