import {
  Box,
  Flex,
  Image,
  SkeletonText,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import CustomText from "../text/CustomText";
import {
  IconArrowDown,
  IconArrowUp,
  IconCaretDownFilled,
  IconDots,
} from "@tabler/icons-react";
import OrderBookIco1 from "../../assets/images/order-book-ico1.svg";
import OrderBookIco2 from "../../assets/images/order-book-ico2.svg";
import OrderBookIco3 from "../../assets/images/order-book-ico3.svg";
import { formatNumber } from "../../utils/functions/formatNumber";
import moment from "moment";
import { socket } from "../../utils/sockets";
import { useSelector } from "react-redux";

function OrderBook() {
  const { tickerStats, pairSymbol } = useSelector((state: any) => state.spot);

  const [bids, setBids] = useState<any>([]);
  const [asks, setAsks] = useState<any>([]);
  const [activeOrderTab, setActiveOrderTab] = useState("");
  const [isOrderLoading, setIsOrderLoading] = useState(false);

  const handleDataChange = (data: any) => {
    setIsOrderLoading(true);
    const topBids = data?.bidDepth
      ?.filter((bid: any) => bid?.quantity > 0)
      ?.slice(0, 20)
      ?.map((bid: any) => ({
        price: bid?.price,
        quantity: bid?.quantity,
        time: data?.eventTime,
      }));

    const topAsks = data?.askDepth
      ?.filter((ask: any) => ask?.quantity > 0)
      ?.slice(0, 17)
      ?.map((ask: any) => ({
        price: ask?.price,
        quantity: ask?.quantity,
        time: data?.eventTime,
      }));
    setBids(topBids);
    setAsks(topAsks);
    setIsOrderLoading(false);
  };

  useEffect(() => {
    function onConnect() {
      socket.emit("join", { event: "book", symbol: pairSymbol });
    }

    if (socket?.connected) {
      onConnect();
    }

    socket.on("connect", onConnect);
    socket.on(`book${pairSymbol}`, (data) => {
      handleDataChange(data);
    });

    return () => {
      socket.off(`book${pairSymbol}`, (data) => {});
      socket.off("connect", () => {});
    };
  }, [pairSymbol]);

  return (
    <Flex
      flexDir={{ base: "row", xl: "column" }}
      px={{ base: "16px", xl: "auto" }}
    >
      <Box>
        <Flex justify="space-between" align="center" pe="16px">
          <CustomText
            text="Order Book"
            color="extsy.text2"
            size="15px"
            weight="700"
          />
          <IconDots color="#3D4653" size="20px" />
        </Flex>
        <Flex
          mt="16px"
          justify="space-between"
          gap="19px"
          align="center"
          pe="16px"
        >
          <Flex gap="19px">
            <Image
              src={OrderBookIco1}
              alt="ico"
              cursor="pointer"
              onClick={() => {
                setActiveOrderTab("all");
              }}
            />
            <Image
              src={OrderBookIco2}
              alt="ico"
              cursor="pointer"
              onClick={() => {
                setActiveOrderTab("ask");
              }}
            />
            <Image
              src={OrderBookIco3}
              alt="ico"
              cursor="pointer"
              onClick={() => {
                setActiveOrderTab("bid");
              }}
            />
          </Flex>
          <Flex align="center" gap="6px">
            <CustomText text="0.01" size="13px" color="extsy.text2" />
            <IconCaretDownFilled color="#848E9C" size="19px" />
          </Flex>
        </Flex>
        <TableContainer
          mt="11px"
          pe="16px"
          display={activeOrderTab === "ask" ? "none" : "block"}
        >
          <Table size="sm">
            <Thead>
              <Tr>
                <Th minW="125px" border="none" ps="0">
                  <CustomText
                    text="Price"
                    size="13px"
                    color="extsy.neutral400"
                  />
                </Th>
                <Th border="none" minW="90px">
                  <CustomText
                    text="Amount"
                    size="13px"
                    color="extsy.neutral400"
                    align="end"
                  />
                </Th>
                <Th border="none" p="0" minW="80px">
                  <CustomText
                    text="Time"
                    size="13px"
                    color="extsy.neutral400"
                    align="end"
                  />
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {isOrderLoading
                ? [1, 2, 3, 4, 5].map((index) => <LoadingRow key={index} />)
                : bids?.map((bid: any, index: number) => (
                    <Tr key={index}>
                      <Td h="23px" minW="125px" border="none" p="0">
                        <Flex align="center" gap="2px">
                          <CustomText
                            text={formatNumber(bid?.price, 6)}
                            size="13px"
                            color="extsy.danger"
                          />
                        </Flex>
                      </Td>
                      <Td border="none" minW="90px">
                        <Flex align="center" gap="2px" justify="end">
                          <CustomText
                            text={formatNumber(bid?.quantity, 6)}
                            size="13px"
                            color="extsy.text4"
                          />
                        </Flex>
                      </Td>
                      <Td border="none" p="0" minW="80px">
                        <Flex align="center" gap="2px" justify="end">
                          <CustomText
                            text={moment(bid?.time).format("HH:mm:ss")}
                            size="13px"
                            color="extsy.text4"
                          />
                        </Flex>
                      </Td>
                    </Tr>
                  ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
      <Box>
        <Box bg="extsy.neutral900" w="100%" my="9px" height="1px" />
        <Flex
          justify="space-between"
          align="center"
          gap="19px"
          py="6px"
          pe="16px"
        >
          <Flex gap="6px" align="center">
            <CustomText
              text={formatNumber(tickerStats?.lastPrice, 6)}
              size="22px"
              color={tickerStats?.priceColor}
            />
            {tickerStats?.priceColor === "extsy.success" ? (
              <IconArrowUp color="#0ECB81" size="19px" />
            ) : (
              <IconArrowDown color="#F6465D" size="19px" />
            )}
            {/* <IconArrowDown color="#F6465D" size="19px" /> */}
            <CustomText
              text={`$${formatNumber(tickerStats?.lastPrice, 6)}`}
              size="13px"
              color="extsy.neutral400"
            />
          </Flex>
          <CustomText text="More" size="14px" color="extsy.neutral400" />
        </Flex>
        <Box bg="extsy.neutral900" w="100%" my="9px" height="1px" />
        <TableContainer
          pe="16px"
          display={activeOrderTab === "bid" ? "none" : "block"}
        >
          <Table size="sm">
            <Tbody>
              {isOrderLoading
                ? [1, 2, 3, 4, 5].map((index) => <LoadingRow key={index} />)
                : asks?.map((ask: any, index: number) => (
                    <Tr key={index}>
                      <Td h="23px" minW="125px" border="none" p="0">
                        <Flex align="center" gap="2px">
                          <CustomText
                            text={formatNumber(ask?.price, 6)}
                            size="13px"
                            color="#0ECB81"
                          />
                        </Flex>
                      </Td>
                      <Td border="none" minW="90px">
                        <Flex align="center" gap="2px" justify="end">
                          <CustomText
                            text={formatNumber(ask?.quantity, 6)}
                            size="13px"
                            color="extsy.text4"
                          />
                        </Flex>
                      </Td>
                      <Td border="none" p="0" minW="80px">
                        <Flex align="center" gap="2px" justify="end">
                          <CustomText
                            text={moment(ask?.time).format("HH:mm:ss")}
                            size="13px"
                            color="extsy.text4"
                          />
                        </Flex>
                      </Td>
                    </Tr>
                  ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    </Flex>
  );
}

const LoadingRow = () => {
  return (
    <Tr>
      <Td minW="125px" border="none" ps="0">
        <SkeletonText w="70px" noOfLines={1} />
      </Td>
      <Td border="none" minW="90px">
        <SkeletonText w="40px" noOfLines={1} ml="auto" />
      </Td>
      <Td border="none" p="0" minW="80px">
        <SkeletonText w="40px" noOfLines={1} ml="auto" />
      </Td>
    </Tr>
  );
};

export default OrderBook;
