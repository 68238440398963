import {
  Image,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack,
} from "@chakra-ui/react";
import React from "react";
import SliderThumbIco from "../../assets/images/slider-thumb.svg";
import SliderMarker from "../../assets/images/slider-marker.svg";

function CustomTradingSlider({ value, onChange, min, max }: any) {
  return (
    <Slider value={value} onChange={onChange} min={min} max={max}>
      <SliderMark value={0} mt="-4.5px" ml="-2px" zIndex="10">
        <Image src={SliderMarker} alt="marker" height="9px" width="9px" />
      </SliderMark>
      <SliderMark value={max * 0.25} mt="-4.5px" zIndex="10">
        <Image src={SliderMarker} alt="marker" height="9px" width="9px" />
      </SliderMark>
      <SliderMark value={max * 0.5} mt="-4.5px" zIndex="10">
        <Image src={SliderMarker} alt="marker" height="9px" width="9px" />
      </SliderMark>
      <SliderMark value={max * 0.75} mt="-4.5px" zIndex="10">
        <Image src={SliderMarker} alt="marker" height="9px" width="9px" />
      </SliderMark>
      <SliderMark value={max} ml="-8px" mt="-4.5px" zIndex="10">
        <Image src={SliderMarker} alt="marker" height="9px" width="9px" />
      </SliderMark>
      <SliderTrack bg="#474D57" height="4px">
        <SliderFilledTrack bg="extsy.neutral300" />
      </SliderTrack>
      <SliderThumb
        bg="transparent"
        p="0"
        boxShadow="none"
        boxSize="20px"
        zIndex="20"
      >
        <Image src={SliderThumbIco} alt="thumb" ml="-10px" />
      </SliderThumb>
    </Slider>
  );
}

export default CustomTradingSlider;
