import {
  Flex,
  Image,
  SkeletonText,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React from "react";
import CustomText from "../../components/text/CustomText";
import { IconChevronDown, IconChevronUp } from "@tabler/icons-react";
import ArrowBack from "../../assets/images/black-left.svg";
import ArrowForward from "../../assets/images/black-right.svg";
import { useTranslation } from "react-i18next";

function AllTable({
  transactions,
  isLoading,
  limit,
  offset,
  setOffset,
  setSortingKey,
  setSortingOrder,
  sortingOrder,
}: any) {
  const { t } = useTranslation();

  const totalTransactions = transactions?.total || 0; // Total number of transactions
  const totalPages = Math.ceil(totalTransactions / limit); // Total number of pages
  const currentPage = Math.floor(offset / limit) + 1; // Current page number

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setOffset((prevOffset: any) => prevOffset + limit);
    }
  };

  // Handle going to the previous page
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setOffset((prevOffset: any) => prevOffset - limit);
    }
  };

  const formatTransactionDate = (isoDateString: any) => {
    const date = new Date(isoDateString);

    // Extract day, month, and year
    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = date.getUTCFullYear();

    // Format time as HH:MM in UTC
    const hours = String(date.getUTCHours()).padStart(2, "0");
    const minutes = String(date.getUTCMinutes()).padStart(2, "0");

    // Combine formatted date and time
    const formattedDate = `${day}-${month}-${year}`;
    const formattedTime = `${hours}:${minutes}`;

    return `${formattedDate} ${formattedTime}`;
  };

  return (
    <Flex
      flexDir="column"
      gap="24px"
      justify="space-between"
      minH={"calc(100vh - 315px)"}
    >
      <TableContainer overflowX="auto" maxW="100%">
        <Table border="1px solid" color="extsy.neutral900">
          <Thead bg="extsy.neutral900" height="40px">
            <Tr>
              <Th borderColor="extsy.neutral900" maxW="383px" minW="160px">
                <Flex align="center" gap="4px">
                  <CustomText
                    text={"Created"}
                    size="13px"
                    color="extsy.baseWhite"
                    weight="500"
                    ls="-0.13px"
                  />
                  <Flex
                    flexDir="column"
                    onClick={() => {
                      setSortingKey("created_at");
                      if (sortingOrder === "ASC") {
                        setSortingOrder("DESC");
                      } else {
                        setSortingOrder("ASC");
                      }
                    }}
                  >
                    <IconChevronUp
                      cursor="pointer"
                      color="#FAFAFA"
                      size="14px"
                    />
                    <IconChevronDown
                      color="#FAFAFA"
                      size="14px"
                      cursor="pointer"
                      style={{
                        marginTop: -7,
                      }}
                    />
                  </Flex>
                </Flex>
              </Th>
              <Th
                borderColor="extsy.neutral900"
                maxW="583px"
                minW={{ base: "160px", "1xl": "260px" }}
              >
                <Flex align="center" gap="4px">
                  <CustomText
                    text={"Transaction Type"}
                    size="13px"
                    color="extsy.baseWhite"
                    weight="500"
                    ls="-0.13px"
                  />
                  <Flex
                    flexDir="column"
                    onClick={() => {
                      setSortingKey("transactionType");
                      if (sortingOrder === "ASC") {
                        setSortingOrder("DESC");
                      } else {
                        setSortingOrder("ASC");
                      }
                    }}
                  >
                    <IconChevronUp
                      cursor="pointer"
                      color="#FAFAFA"
                      size="14px"
                    />
                    <IconChevronDown
                      color="#FAFAFA"
                      size="14px"
                      cursor="pointer"
                      style={{
                        marginTop: -7,
                      }}
                    />
                  </Flex>
                </Flex>
              </Th>
              <Th
                borderColor="extsy.neutral900"
                maxW="583px"
                minW={{ base: "160px", "1xl": "260px" }}
              >
                <Flex
                  align="center"
                  gap="4px"
                  onClick={() => {
                    setSortingKey("detail");
                    if (sortingOrder === "ASC") {
                      setSortingOrder("DESC");
                    } else {
                      setSortingOrder("ASC");
                    }
                  }}
                >
                  <CustomText
                    text={"Detail"}
                    size="13px"
                    color="extsy.baseWhite"
                    weight="500"
                    ls="-0.13px"
                  />
                  <Flex flexDir="column">
                    <IconChevronUp
                      cursor="pointer"
                      color="#FAFAFA"
                      size="14px"
                    />
                    <IconChevronDown
                      color="#FAFAFA"
                      size="14px"
                      cursor="pointer"
                      style={{
                        marginTop: -7,
                      }}
                    />
                  </Flex>
                </Flex>
              </Th>
              <Th
                borderColor="extsy.neutral900"
                maxW="283px"
                minW={{ base: "80px", "1xl": "160px" }}
              >
                <Flex align="center" gap="4px">
                  <CustomText
                    text={"Amount"}
                    size="13px"
                    color="extsy.baseWhite"
                    weight="500"
                    ls="-0.13px"
                  />
                  <Flex
                    flexDir="column"
                    onClick={() => {
                      setSortingKey("amount");
                      if (sortingOrder === "ASC") {
                        setSortingOrder("DESC");
                      } else {
                        setSortingOrder("ASC");
                      }
                    }}
                  >
                    <IconChevronUp
                      cursor="pointer"
                      color="#FAFAFA"
                      size="14px"
                    />
                    <IconChevronDown
                      color="#FAFAFA"
                      size="14px"
                      cursor="pointer"
                      style={{
                        marginTop: -7,
                      }}
                    />
                  </Flex>
                </Flex>
              </Th>
              <Th borderColor="extsy.neutral900" maxW="283px" minW="160px">
                <Flex align="center" gap="4px">
                  <CustomText
                    text={"Status"}
                    size="13px"
                    color="extsy.baseWhite"
                    weight="500"
                    ls="-0.13px"
                  />
                  <Flex
                    flexDir="column"
                    onClick={() => {
                      setSortingKey("status");
                      if (sortingOrder === "ASC") {
                        setSortingOrder("DESC");
                      } else {
                        setSortingOrder("ASC");
                      }
                    }}
                  >
                    <IconChevronUp
                      cursor="pointer"
                      color="#FAFAFA"
                      size="14px"
                    />
                    <IconChevronDown
                      color="#FAFAFA"
                      size="14px"
                      cursor="pointer"
                      style={{
                        marginTop: -7,
                      }}
                    />
                  </Flex>
                </Flex>
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {isLoading
              ? [1, 2, 3, 4, 5].map((_, index) => <LoadingRow key={index} />)
              : transactions?.data?.map((trx: any, index: any) => (
                  <Tr key={index}>
                    <Td
                      height="54px"
                      borderColor="extsy.neutral900"
                      borderRight="1px solid"
                      py="0"
                    >
                      <CustomText
                        text={formatTransactionDate(trx?.transaction_date)}
                        size="14px"
                      />
                    </Td>
                    <Td borderColor="extsy.neutral900" borderRight="1px solid">
                      <CustomText text={trx?.transaction_type} size="14px" />
                    </Td>
                    <Td borderColor="extsy.neutral900" borderRight="1px solid">
                      <CustomText text={trx?.detail} size="14px" />
                    </Td>

                    <Td borderColor="extsy.neutral900" borderRight="1px solid">
                      <CustomText
                        text={`${trx?.amount} ${trx?.amount_symbol}`}
                        size="14px"
                      />
                    </Td>
                    <Td borderColor="extsy.neutral900" borderRight="1px solid">
                      <CustomText text={trx?.status} size="14px" />
                    </Td>
                  </Tr>
                ))}
          </Tbody>
        </Table>
      </TableContainer>
      <Flex gap="16px" px="20px" pt="14px" align="center" justify="flex-end">
        {/* Showing range of items */}
        <CustomText
          text={`${offset + 1}-${Math.min(
            offset + limit,
            totalTransactions
          )} ${t("of")} ${totalTransactions}`}
          size="13px"
          weight="500"
          color="extsy.neutral300"
        />

        <Flex gap="8px">
          {/* Previous Page Button */}
          <Image
            src={ArrowBack}
            alt="arrow-back"
            cursor={currentPage > 1 ? "pointer" : "not-allowed"}
            opacity={currentPage > 1 ? 1 : 0.5}
            onClick={handlePrevPage}
          />

          {/* Page Numbers */}
          {[...Array(totalPages)].map((_, index) => (
            <Flex
              key={index}
              flexDir="column"
              align="center"
              justify="center"
              bg={
                currentPage === index + 1 ? "extsy.neutral900" : "transparent"
              }
              borderRadius="6px"
              height="30px"
              width="30px"
              cursor="pointer"
              onClick={() => setOffset(index * limit)}
            >
              <CustomText text={index + 1} size="14px" weight="500" />
            </Flex>
          ))}

          {/* Next Page Button */}
          <Image
            src={ArrowForward}
            alt="arrow-forward"
            cursor={currentPage < totalPages ? "pointer" : "not-allowed"}
            opacity={currentPage < totalPages ? 1 : 0.5}
            onClick={handleNextPage}
          />
        </Flex>
      </Flex>
    </Flex>
  );
}

export default AllTable;

const LoadingRow = () => {
  return (
    <Tr>
      <Td
        height="54px"
        borderColor="extsy.neutral900"
        borderRight="1px solid"
        py="0"
        pos="relative"
        overflow="hidden"
      >
        <SkeletonText noOfLines={1} w="80px" />
      </Td>
      <Td borderColor="extsy.neutral900" borderRight="1px solid">
        <Flex align="center" gap="12px">
          <SkeletonText noOfLines={1} w="40px" />
        </Flex>
      </Td>
      <Td borderColor="extsy.neutral900" borderRight="1px solid">
        <SkeletonText noOfLines={1} w="80px" />
      </Td>
      <Td borderColor="extsy.neutral900" borderRight="1px solid">
        <SkeletonText noOfLines={1} w="80px" />
      </Td>
    </Tr>
  );
};
