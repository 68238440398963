import {
  Box,
  Flex,
  Image,
  SkeletonText,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import CustomText from "../text/CustomText";
import TradingBg from "../../assets/images/trading-bg.svg";
import PrimaryButton from "../button/PrimaryButton";
import CustomButton from "../button/CustomButton";
import { IconArrowDownLeft, IconArrowUpRight } from "@tabler/icons-react";
import CustomModal from "../modal/customModal";
import CustomSelect from "../form/CustomSelect";
import VCardInput from "../form/vCardInput";
import { CoinOption, CryptoAsset } from "../../utils/types/types";
import { useForm } from "react-hook-form";
import { formatNumber } from "../../utils/functions/formatNumber";
import { useTranslation } from "react-i18next";
import DashboardSlider from "../dashboard/dashboardSlider";
import { socket } from "../../utils/sockets";
import { useQuery } from "@tanstack/react-query";
import { useCustomAlert } from "../../utils/api/alert";
import ScanQR from "./ScanQR";
import { useNetworkCoins, useNetworks } from "../../utils/api/p2p.api";
import { useDeposit, useWithdraw } from "../../utils/api/trade.api";

const EstimateBalanceCardP2P = () => {
  const { t } = useTranslation();

  const [isLessThan992] = useMediaQuery("(max-width: 992px)");
  const [selectedChain, setSelectedChain] = useState<CoinOption | undefined>(
    undefined
  );
  const [selectedCoin, setSelectedCoin] = useState<CoinOption | undefined>(
    undefined
  );
  const [depositResponse, setDepositResponse] = useState<any | undefined>(
    undefined
  );
  console.log(depositResponse);
  const { data: chains, isLoading: chainLoading } = useNetworks();
  const {
    data: coins,
    isLoading: coinsLoading,
    refetch,
  } = useNetworkCoins(selectedChain?.value);

  const { data: balance, isLoading: balanceLoading }: any = useQuery({
    queryKey: ["getBalance"],
  });
  const depositCrypto = useDeposit();
  const withdrawCrypto = useWithdraw();
  const alert = useCustomAlert();

  const {
    isOpen: isWithdrawOpen,
    onOpen: onWithdrawOpen,
    onClose: onWithdrawClose,
  } = useDisclosure();
  const {
    isOpen: isDepositOpen,
    onOpen: onDepositOpen,
    onClose: onDepositClose,
  } = useDisclosure();
  const {
    isOpen: isConfirmationOpen,
    onOpen: onConfirmationOpen,
    onClose: onConfirmationClose,
  } = useDisclosure();

  const {
    register: withdrawRegister,
    handleSubmit: handleWithdrawSubmit,
    reset: withdrawReset,
    formState: { errors: withdrawErrors },
  } = useForm();

  const handleDeposit = () => {
    depositCrypto
      ?.mutateAsync({
        coin_id: selectedChain?.value,
        isDummy: false,
      })
      .then((result) => {
        console.log(result);
        socket.emit("p2pDepositWithdrawStatus", {
          tradeTransactionId: result?.tadeTransaction?.id,
        });
        setDepositResponse(result?.tadeTransaction);
        onDepositClose();
        setSelectedChain({
          value: chains?.[0]?.id,
          label: chains?.[0]?.symbol,
          icon: chains?.[0]?.logo_url,
        });

        setSelectedCoin({
          value: coins?.[0]?.id,
          label: coins?.[0]?.symbol,
          icon: coins?.[0]?.logo_url,
        });
        onConfirmationOpen();
      })
      .catch((error) => {
        alert?.mutateAsync({
          message: error?.message,
          status: "danger",
        });
      });
  };

  const handleWithdraw = (data: any) => {
    withdrawCrypto
      ?.mutateAsync({
        coin_id: selectedCoin?.value,
        address: data.address,
        amount: Number(data.amount),
        isDummy: false,
      })
      .then((result) => {
        onWithdrawClose();
        setSelectedChain({
          value: chains?.[0]?.id,
          label: chains?.[0]?.symbol,
          icon: chains?.[0]?.logo_url,
        });

        setSelectedCoin({
          value: coins?.[0]?.id,
          label: coins?.[0]?.symbol,
          icon: coins?.[0]?.logo_url,
        });
        withdrawReset();
      })
      .catch((error) => {
        alert?.mutateAsync({
          message: error?.message,
          status: "danger",
        });
      });
  };

  useEffect(() => {
    if (!selectedChain && chains) {
      setSelectedChain({
        value: chains?.[0]?.id,
        label: chains?.[0]?.symbol,
        icon: chains?.[0]?.logo_url,
      });

      setSelectedCoin({
        value: coins?.[0]?.id,
        label: coins?.[0]?.symbol,
        icon: coins?.[0]?.logo_url,
      });
    }

    if (selectedChain && !selectedCoin) {
      refetch();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedChain, chains]);

  useEffect(() => {
    if (coins) {
      setSelectedCoin({
        value: coins?.[0]?.id,
        label: coins?.[0]?.symbol,
        icon: coins?.[0]?.logo_url,
      });
    }
  }, [coins]);

  useEffect(() => {
    function onSwapStatusUpdate(status: any) {
      setDepositResponse({
        ...depositResponse,
        status: status?.status,
      });
    }

    socket.on("p2pDepositWithdraw", onSwapStatusUpdate);

    return () => {
      socket.off("p2pDepositWithdraw", onSwapStatusUpdate);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Flex
      justify="space-between"
      align="center"
      overflow="hidden"
      p={{ base: "16px", lg: "40px" }}
      borderRadius={{ base: "16px", lg: "24px" }}
      bg="extsy.neutral1000"
      pos="relative"
      flexDir={{ base: "column", lg: "row" }}
      gap={{ base: "32px", lg: "16px" }}
    >
      <Flex
        flexDir="column"
        align={{ base: "center", lg: "flex-start" }}
        gap={{ base: "12px", lg: "23px" }}
        zIndex="1000"
        minW="200px"
      >
        <CustomText text="Estimated Balance" color="extsy.baseWhite" />
        <Flex gap="12px" align="flex-end">
          {balanceLoading ? (
            <SkeletonText w="160px" h="16px" mt="40px" noOfLines={1} />
          ) : (
            <>
              <CustomText
                text={formatNumber(balance?.totalBalanceSpot, 2)}
                size={{ base: "32px", lg: "56px" }}
                family="Metropolis"
                color="extsy.secondary100"
                ls="2.8px"
              />
              <CustomText
                text="BTC"
                size="24px"
                color="extsy.neutral500"
                family="Metropolis"
                ls="1.2px"
                lh={{ base: "24px", lg: "34px" }}
              />
            </>
          )}
        </Flex>
        <Flex gap="10px" align="flex-end">
          {balanceLoading ? (
            <SkeletonText w="120px" h="10px" mt="25px" noOfLines={1} />
          ) : (
            <>
              <CustomText
                text="Today’s PnL"
                family="Metropolis"
                color="extsy.baseWhite"
                lh="22px"
              />
              <CustomText
                text={`${balance?.pnlSpot < 0 ? "-" : "+"}$${formatNumber(
                  balance?.pnlSpot,
                  2
                )}(${formatNumber(balance?.pnlSpotPercentage, 2)}%)`}
                color={balance?.pnlSpot < 0 ? "extsy.danger" : "#0ECB81"}
                family="Metropolis"
                lh="22px"
              />
            </>
          )}
        </Flex>
      </Flex>
      <Flex gap="16px" align="center" w="100%" zIndex="1000" justify="flex-end">
        <PrimaryButton
          title="Deposit"
          maxW="159px"
          height={{ base: "38px", lg: "56px" }}
          size={{ base: "12px", lg: "16px" }}
          onClick={onDepositOpen}
          icon={
            <IconArrowDownLeft
              size={isLessThan992 ? "18px" : "24px"}
              color="#0B0A0A"
            />
          }
        />
        <CustomButton
          title="Withdraw"
          maxW="174px"
          height={{ base: "38px", lg: "56px" }}
          size={{ base: "12px", lg: "16px" }}
          icon={
            <IconArrowUpRight
              size={isLessThan992 ? "18px" : "24px"}
              color="#0B0A0A"
            />
          }
          onClick={onWithdrawOpen}
        />
      </Flex>
      <Box
        height="419px"
        width="419px"
        borderRadius="419px"
        bg="#15B097"
        filter="blur(92px)"
        pos="absolute"
        zIndex="1"
        top="0"
        left="0"
        mt="-319px"
        ml={{ base: "-273px", lg: "-73px" }}
      />
      <Image
        src={TradingBg}
        alt="bg"
        pos="absolute"
        right="0"
        filter="blur(92px)"
        zIndex="1"
      />
      <CustomModal
        isOpen={isWithdrawOpen}
        onClose={() => {
          onWithdrawClose();
          withdrawReset();
        }}
        onSubmit={handleWithdrawSubmit((data) => handleWithdraw(data))}
        headerText={"Withdraw"}
        btnText={"Withdraw"}
        closeBtntext={"Cancel"}
        width={"700px"}
        bgtopBlur="extsy.bgCardTopBlur"
        blurTop="-308px"
        isForm
        isLoading={withdrawCrypto?.isPending}
        isDisabled={withdrawCrypto?.isPending}
      >
        <Flex
          p={{ base: "16px", lg: "32px" }}
          flexDir="column"
          gap={{ base: "16px", lg: "32px" }}
          w="100%"
        >
          <Flex flexDir="column" gap="12px">
            <CustomText text="Chain" size="14px" />
            <CustomSelect
              placeholder={selectedChain?.label}
              ico={selectedChain?.icon}
              value={selectedChain?.value}
              options={chains?.map((coin: CryptoAsset) => ({
                value: coin.id,
                label: coin.symbol,
                icon: coin.logo_url,
              }))}
              onSelect={(option: CoinOption) => {
                setSelectedChain(option);
              }}
              isLoading={chainLoading}
            />
          </Flex>
          <Flex flexDir="column" gap="12px">
            <CustomText text="Coin" size="14px" />
            <CustomSelect
              placeholder={selectedCoin?.label}
              value={selectedCoin?.value}
              ico={selectedCoin?.icon}
              options={coins?.map((coin: CryptoAsset) => ({
                value: coin.id,
                label: coin.symbol_short,
                icon: coin.logo_url,
              }))}
              onSelect={(option: CoinOption) => {
                setSelectedCoin(option);
              }}
              isLoading={coinsLoading}
            />
          </Flex>
          <Flex flexDir="column" gap="12px">
            <CustomText text="Address" size="14px" />
            <VCardInput
              placeholder="0x000.00000"
              bg="extsy.neutral1000"
              type="text"
              register={withdrawRegister}
              name="address"
              validation={{
                required: true,
              }}
              invalid={withdrawErrors.address}
              errorMsg={withdrawErrors.address && "Address is required"}
            />
          </Flex>
          <Flex flexDir="column" gap="12px">
            <CustomText text="Amount" size="14px" />
            <VCardInput
              placeholder="0.00"
              bg="extsy.neutral1000"
              type="number"
              register={withdrawRegister}
              name="amount"
              validation={{
                required: true,
                min: 0,
                pattern: /^[0-9]*\.?[0-9]+$/,
              }}
              invalid={withdrawErrors.amount}
              errorMsg={withdrawErrors.amount && "Amount is required"}
            />
          </Flex>
        </Flex>
      </CustomModal>
      <CustomModal
        isOpen={isDepositOpen}
        onClose={() => {
          onDepositClose();
          setSelectedChain({
            value: chains?.[0]?.id,
            label: chains?.[0]?.symbol,
            icon: chains?.[0]?.logo_url,
          });

          setSelectedCoin({
            value: coins?.[0]?.id,
            label: coins?.[0]?.symbol,
            icon: coins?.[0]?.logo_url,
          });
        }}
        onSubmit={() => {
          handleDeposit();
        }}
        headerText={"Deposit"}
        btnText={"Deposit"}
        closeBtntext={"Cancel"}
        width={"700px"}
        bgtopBlur="transparent"
        isDisabled={depositCrypto?.isPending}
        isLoading={depositCrypto?.isPending}
      >
        <Flex
          p={{ base: "16px", lg: "32px 24px" }}
          flexDir="column"
          gap="32px"
          w="100%"
        >
          <Flex flexDir="column" gap="12px">
            <CustomText text="Chain" size="14px" />
            <CustomSelect
              placeholder={selectedChain?.label}
              ico={selectedChain?.icon}
              value={selectedChain?.value}
              options={chains?.map((coin: CryptoAsset) => ({
                value: coin.id,
                label: coin.symbol,
                icon: coin.logo_url,
              }))}
              onSelect={(option: CoinOption) => {
                setSelectedChain(option);
              }}
              isLoading={chainLoading}
            />
          </Flex>
          <Flex flexDir="column" gap="12px">
            <CustomText text="Coin" size="14px" />
            <CustomSelect
              placeholder={selectedCoin?.label}
              ico={selectedCoin?.icon}
              value={selectedCoin?.value}
              options={coins?.map((coin: CryptoAsset) => ({
                value: coin.id,
                label: coin.symbol_short,
                icon: coin.logo_url,
              }))}
              onSelect={(option: CoinOption) => {
                setSelectedCoin(option);
              }}
              isLoading={coinsLoading}
            />
          </Flex>
        </Flex>
      </CustomModal>
      <CustomModal
        isOpen={isConfirmationOpen}
        onClose={() => {
          onConfirmationClose();
          setSelectedChain({
            value: chains?.[0]?.id,
            label: chains?.[0]?.symbol,
            icon: chains?.[0]?.logo_url,
          });

          setSelectedCoin({
            value: coins?.[0]?.id,
            label: coins?.[0]?.symbol,
            icon: coins?.[0]?.logo_url,
          });
        }}
        onSubmit={() => onConfirmationClose()}
        headerText={"Confirm Transaction"}
        noFooter={true}
        width={"780px"}
        bgtopBlur={"extsy.bgCardTopBlur"}
      >
        <Flex
          flexDir={"column"}
          gap={"12px"}
          py={{ base: "16px", lg: "32px" }}
          px={{ base: "16px", lg: "24px" }}
        >
          <Flex
            justify={"space-between"}
            align={{ lg: "center" }}
            gap={{ base: "16", lg: "56px" }}
            pb={"24px"}
            flexDir={{
              base: "column",
              lg: "row",
            }}
          >
            <Flex flexDir={"column"} gap={{ base: "16px", lg: "40px" }}>
              <Flex gap={"12px"} align={"center"}>
                <Image
                  src={depositResponse?.coin_logo_url}
                  alt="icon"
                  width={"40px"}
                  height={"40px"}
                />
                <Flex flexDir={"column"} gap={"5px"}>
                  <CustomText
                    text={t("Amount ")}
                    size="18px"
                    color="extsy.neutral300"
                    family="Metropolis"
                  />
                  <CustomText
                    text={`${depositResponse?.amount} ${
                      depositResponse?.coin_symbol_short || ""
                    }`}
                    size="24px"
                    color="extsy.baseWhite"
                    weight="700"
                    family="Metropolis"
                  />
                </Flex>
              </Flex>
              <Flex
                flexDir={{ base: "row", lg: "column" }}
                justify={"space-between"}
                gap={{ base: "16px", lg: "40px" }}
              >
                <Flex flexDir={"column"} gap={"5px"}>
                  <CustomText
                    text={t("To this wallet")}
                    size="18px"
                    color="extsy.neutral300"
                    family="Metropolis"
                  />
                  <CustomText
                    text={depositResponse?.deposit_address}
                    size="18px"
                    color="extsy.baseWhite"
                    weight="700"
                    family="Metropolis"
                  />
                </Flex>
                {/* <Flex
                  flexDir={"column"}
                  gap={"5px"}
                  align={{ base: "flex-end", lg: "flex-start" }}
                >
                  <CustomText
                    text={t("Time left for the transaction")}
                    size="18px"
                    color="extsy.neutral300"
                    family="Metropolis"
                  />
                  <CustomText
                    text={t("20:53:26 ")}
                    size="24px"
                    color="extsy.baseWhite"
                    weight="700"
                    family="Metropolis"
                  />
                </Flex> */}
              </Flex>
            </Flex>
            <Flex justify="center">
              <ScanQR
                address={depositResponse?.deposit_address}
                amount={depositResponse?.amount}
                coin={depositResponse?.coin_symbol_short}
              />
            </Flex>
          </Flex>
          <Flex pt={"16px"}>
            <DashboardSlider active={depositResponse?.status?.toLowerCase()} />
          </Flex>
        </Flex>
      </CustomModal>
    </Flex>
  );
};

export default EstimateBalanceCardP2P;
