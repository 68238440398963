import React from "react";

function SelectWallet() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41"
      height="33"
      viewBox="0 0 41 33"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.93643 32.4288H39.0748C40.0163 32.4288 40.7799 31.6633 40.7799 30.7195V5.80989C40.7799 4.86367 40.0145 4.09668 39.0709 4.09668H1.03527C0.584985 4.09668 0.220215 4.46234 0.220215 4.91372V30.7084C0.220215 31.6585 0.988325 32.4288 1.93643 32.4288Z"
        fill="#655F5F"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.96199 32.4999H6.59743C6.63298 32.4999 6.66209 32.4719 6.66209 32.4374V1.21186C6.66209 1.1774 6.63298 1.14941 6.59743 1.14941H1.96199C0.999785 1.14941 0.220215 1.8988 0.220215 2.82375V30.8255C0.220215 31.7502 0.999785 32.4999 1.96199 32.4999Z"
        fill="#7A7474"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M34.0083 0.5H1.9544V0.504211C0.993418 0.52737 0.220215 1.31567 0.220215 2.28955C0.220215 3.26343 0.993418 4.05173 1.9544 4.07489V4.0788H35.9449C35.9798 4.0788 36.0082 4.05053 36.0082 4.01534V2.51482C36.0082 1.40199 35.1126 0.5 34.0083 0.5Z"
        fill="#A5A1A1"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M23.655 27.1499C24.7946 27.1499 25.9231 26.9254 26.9759 26.4893C28.0288 26.0532 28.9854 25.414 29.7913 24.6081C30.5971 23.8023 31.2363 22.8457 31.6724 21.7928C32.1085 20.74 32.333 19.6115 32.333 18.4719C32.333 17.3323 32.1085 16.2039 31.6724 15.151C31.2363 14.0981 30.5971 13.1415 29.7913 12.3357C28.9854 11.5298 28.0288 10.8906 26.9759 10.4545C25.9231 10.0184 24.7946 9.79395 23.655 9.79395C21.3535 9.79395 19.1462 10.7082 17.5188 12.3357C15.8913 13.9631 14.9771 16.1704 14.9771 18.4719C14.9771 20.7734 15.8913 22.9807 17.5188 24.6081C19.1462 26.2356 21.3535 27.1499 23.655 27.1499ZM23.4313 21.9817L28.2524 16.1963L26.7714 14.9622L22.6252 19.9365L20.4798 17.7902L19.1164 19.1536L22.0091 22.0463L22.7554 22.7926L23.4313 21.9817Z"
        fill="url(#paint0_linear_2424_109423)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2424_109423"
          x1="32.3184"
          y1="16.0389"
          x2="20.6623"
          y2="9.03393"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F0C2DB" />
          <stop offset="0.25" stop-color="#F1C8D9" />
          <stop offset="0.6" stop-color="#EDE3E1" />
          <stop offset="1" stop-color="white" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SelectWallet;
