import {
  Box,
  Divider,
  Flex,
  Step,
  StepIndicator,
  Stepper,
  StepSeparator,
  StepStatus,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure,
  useSteps,
} from "@chakra-ui/react";
import { IconArrowLeft } from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
import CustomText from "../../components/text/CustomText";
import { useNavigate } from "react-router-dom";
import Step1 from "./buy/Step1";
import PrimaryButton from "../../components/button/PrimaryButton";
import CustomButton from "../../components/button/CustomButton";
import Step2 from "./buy/Step2";
import Step3 from "./buy/Step3";
import CustomModal from "../../components/modal/customModal";
import GradientText from "../../components/text/GradientText";
import { useOrder } from "../../utils/api/p2p.api";
import { useCustomAlert } from "../../utils/api/alert";

const STEPS = [
  {
    title: "Set type & Price",
  },
  {
    title: "Set total amount & payment method",
  },
  {
    title: "Set remarks & automatic response",
  },
];

const TABS = ["I want to buy", "I want to sell"];

function PostNewAdd() {
  const navigate = useNavigate();

  const {
    isOpen: isPostOpen,
    onOpen: onOpenPost,
    onClose: onClosePost,
  } = useDisclosure();
  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: STEPS.length,
  });
  const orderMutation = useOrder();
  const alert = useCustomAlert();

  const [tabIndex, setTabIndex] = useState(0);
  const [adData, setAdData] = useState<any>({
    type: "",
    assetID: "",
    currency: "",
    chain: "",
    priceType: "Fixed",
    price: 240,
    orderLowerLimit: 0,
    orderUpperLimit: 0,
    totalTradingAmount: 0,
    availableTradingAmount: 0,
    estimatedFee: 0,
    paymentMethod: "",
    paymentTimeInMin: 0,
    regions: null,
    tags: null,
    processingFee: 0,
    status: "IN_PROCESS",
    remarks: "",
    autoReply: "",
  });

  console.log(tabIndex, adData);
  useEffect(() => {
    setAdData((prevData: any) => ({
      ...prevData,
      type: tabIndex === 0 ? "BUY" : "SELL",
    }));
  }, [tabIndex]);

  return (
    <Box
      p="32px"
      borderRadius="16px"
      border="1px solid"
      borderColor="extsy.neutral1000"
      bg="extsy.neutral1000"
    >
      <Flex
        align="center"
        gap="8px"
        cursor="pointer"
        w="80px"
        onClick={() => {
          navigate("/p2p");
        }}
      >
        <IconArrowLeft size={20} color="#FEF8C3" />
        <CustomText text="Back" color="extsy.secondary100" cursor />
      </Flex>
      <CustomText
        text="Post Normal Ad"
        mt="16px"
        color="extsy.white"
        size="24px"
        weight="700"
      />
      <Stepper
        size="lg"
        index={activeStep}
        mt="40px"
        colorScheme="extsy.primary"
      >
        {STEPS.map((step, index) => (
          <Step key={index}>
            <StepIndicator border="none" height="32px" width="32px">
              <StepStatus
                complete={
                  <Flex
                    align="center"
                    justify="center"
                    height="32px"
                    width="32px"
                    borderRadius="88px"
                    bg="extsy.primary"
                  >
                    <CustomText
                      text={index + 1}
                      size="12px"
                      weight="700"
                      color="#000"
                    />
                  </Flex>
                }
                incomplete={
                  <Flex
                    align="center"
                    justify="center"
                    height="32px"
                    width="32px"
                    borderRadius="88px"
                    bg="extsy.neutral900"
                  >
                    <CustomText text={index + 1} size="12px" weight="700" />
                  </Flex>
                }
                active={
                  <Flex
                    align="center"
                    justify="center"
                    height="32px"
                    width="32px"
                    borderRadius="88px"
                    bg="extsy.primary"
                  >
                    <CustomText
                      text={index + 1}
                      size="12px"
                      weight="700"
                      color="#000"
                    />
                  </Flex>
                }
              />
            </StepIndicator>
            <Box
              display={{
                base: "none",
                lg: "block",
              }}
            >
              <CustomText
                text={step?.title}
                color={
                  activeStep >= index ? "extsy.baseWhite" : "extsy.neutral600"
                }
              />
            </Box>
            <StepSeparator
              style={{
                height: 1,
                background: activeStep > index ? "#F0C2DB" : "#383434",
              }}
            />
          </Step>
        ))}
      </Stepper>
      <Flex
        align="center"
        justify="space-between"
        mt="10px"
        display={{
          base: "flex",
          lg: "none",
        }}
      >
        {STEPS?.map((step, index) => (
          <Box key={index} maxW="150px">
            <CustomText
              text={step?.title}
              color={
                activeStep >= index ? "extsy.baseWhite" : "extsy.neutral600"
              }
              align={index === 1 ? "center" : index === 2 ? "end" : "start"}
              size={{ base: "10px", md: "16px" }}
            />
          </Box>
        ))}
      </Flex>
      <Tabs
        onChange={(index) => {
          setTabIndex(index);
          setActiveStep(0);
        }}
        defaultIndex={0}
        mt="24px"
      >
        <TabList borderBottom="1px solid" borderColor="extsy.neutral900">
          {TABS?.map((tab, index) => (
            <Tab
              key={index}
              px={{ base: "16px", md: "32px" }}
              pt="10px"
              pb="14px"
              w="100%"
              color="extsy.secondary100"
              bg={index === tabIndex ? "rgba(78, 73, 73, 0.20)" : "transparent"}
            >
              <CustomText
                text={tab}
                color={
                  index === tabIndex ? "extsy.baseWhite" : "extsy.neutral300"
                }
                cursor="pointer"
                wrap="nowrap"
              />
            </Tab>
          ))}
        </TabList>
        <TabPanels p="0" mt={{ base: "24px", lg: "64px" }}>
          <TabPanel p="0">
            <Flex
              minH="500px"
              flexDir="column"
              gap="24px"
              justify="space-between"
            >
              <Box>
                {activeStep === 0 ? (
                  <Step1 adData={adData} setAdData={setAdData} />
                ) : activeStep === 1 ? (
                  <Step2 adData={adData} setAdData={setAdData} />
                ) : (
                  <Step3 adData={adData} setAdData={setAdData} />
                )}
              </Box>
              <Flex justify={{ md: "end" }} align="center" gap="10px">
                {activeStep > 0 && (
                  <CustomButton
                    title="Previous"
                    maxW={{ md: "250px" }}
                    bg="extsy.neutral800"
                    color="extsy.baseWhite"
                    onClick={() => {
                      setActiveStep(activeStep - 1);
                    }}
                    order={{ base: 2, md: 1 }}
                  />
                )}
                <PrimaryButton
                  title={activeStep === 2 ? "Post" : "Next"}
                  maxW={{ md: "250px" }}
                  onClick={() => {
                    if (activeStep === 2) {
                      onOpenPost();
                    } else {
                      if (activeStep === 0) {
                        const isAdDataValid =
                          adData &&
                          adData.type &&
                          adData.assetID &&
                          adData.currency &&
                          adData.priceType &&
                          adData.price;

                        if (!isAdDataValid) {
                          // Optionally, you can provide feedback to the user here, e.g.:
                          alert.mutate({
                            message: "Please fill in all required fields.",
                            type: "danger",
                          });
                          return;
                        }

                        setActiveStep(activeStep + 1);
                      }
                      if (activeStep === 1) {
                        const isAdDataValid =
                          adData &&
                          adData.totalTradingAmount &&
                          adData.orderLowerLimit &&
                          adData.orderUpperLimit &&
                          adData.priceType &&
                          adData.price;

                        if (!isAdDataValid) {
                          // Optionally, you can provide feedback to the user here, e.g.:
                          alert.mutate({
                            message: "Please fill in all required fields.",
                            type: "danger",
                          });
                          return;
                        }

                        setActiveStep(activeStep + 1);
                      }
                    }
                  }}
                  order={{ base: 1, md: 2 }}
                />
              </Flex>
            </Flex>
          </TabPanel>
          <TabPanel p="0">
            <Flex
              minH="500px"
              flexDir="column"
              gap="24px"
              justify="space-between"
            >
              <Box>
                {activeStep === 0 ? (
                  <Step1 adData={adData} setAdData={setAdData} />
                ) : activeStep === 1 ? (
                  <Step2 sell={true} adData={adData} setAdData={setAdData} />
                ) : (
                  <Step3 adData={adData} setAdData={setAdData} />
                )}
              </Box>
              <Flex justify={{ md: "end" }} align="center" gap="10px">
                {activeStep > 0 && (
                  <CustomButton
                    title="Previous"
                    maxW={{ md: "250px" }}
                    bg="extsy.neutral800"
                    color="extsy.baseWhite"
                    onClick={() => {
                      setActiveStep(activeStep - 1);
                    }}
                    order={{ base: 2, md: 1 }}
                  />
                )}
                <PrimaryButton
                  title={activeStep === 2 ? "Post" : "Next"}
                  maxW={{ md: "250px" }}
                  onClick={() => {
                    if (activeStep === 2) {
                      onOpenPost();
                    } else {
                      if (activeStep === 0) {
                        const isAdDataValid =
                          adData &&
                          adData.type &&
                          adData.assetID &&
                          adData.currency &&
                          adData.priceType &&
                          adData.price;

                        if (!isAdDataValid) {
                          // Optionally, you can provide feedback to the user here, e.g.:
                          alert.mutate({
                            message: "Please fill in all required fields.",
                            type: "danger",
                          });
                          return;
                        }

                        setActiveStep(activeStep + 1);
                      }
                      if (activeStep === 1) {
                        const isAdDataValid =
                          adData &&
                          adData.totalTradingAmount &&
                          adData.orderLowerLimit &&
                          adData.orderUpperLimit &&
                          adData.priceType &&
                          adData.price;

                        if (!isAdDataValid) {
                          // Optionally, you can provide feedback to the user here, e.g.:
                          alert.mutate({
                            message: "Please fill in all required fields.",
                            type: "danger",
                          });
                          return;
                        }

                        setActiveStep(activeStep + 1);
                      }
                    }
                  }}
                  order={{ base: 1, md: 2 }}
                />
              </Flex>
            </Flex>
          </TabPanel>
        </TabPanels>
      </Tabs>
      <CustomModal
        isOpen={isPostOpen}
        onClose={onClosePost}
        onSubmit={async () => {
          try {
            await orderMutation.mutateAsync(adData);
            setActiveStep(0);
            alert.mutate({
              message: "Order Placed Successfully",
              status: "success",
            });
            setAdData({
              type: "BUY",
              assetID: "",
              currency: "",
              chain: "",
              priceType: "Fixed",
              price: 240,
              orderLowerLimit: 0,
              orderUpperLimit: 0,
              totalTradingAmount: 0,
              availableTradingAmount: 0,
              estimatedFee: 0,
              paymentMethod: "Bank Transfer",
              paymentTimeInMin: 0,
              regions: null,
              tags: null,
              processingFee: 0,
              status: "In_Process",
              remarks: "",
              autoReply: "",
            });
          } catch (error: any) {
            alert.mutate({
              message:
                error?.exception && Array.isArray(error?.exception)
                  ? error?.exception[0]
                  : error?.message,
              status: "danger",
            });
          }
          onClosePost();
        }}
        isLoading={orderMutation.isPending}
        isDisabled={orderMutation.isPending}
        headerText={"Confirm to Post"}
        btnText={"Confirm to Post"}
        closeBtntext={"Cancel"}
        width={"600px"}
      >
        <Flex flexDir={"column"} gap={"20px"} py={"16px"} px={"24px"}>
          <Flex align="center" justify="space-between">
            <CustomText text="Type" size="14px" weight="600" />
            <GradientText text={adData?.type} size="14px" bg="extsy.g1" />
          </Flex>
          <Flex align="center" justify="space-between">
            <CustomText text="Assets" size="14px" weight="600" />
            <GradientText text={adData?.assetID} size="14px" bg="extsy.g1" />
          </Flex>
          <Flex align="center" justify="space-between">
            <CustomText text="Currency" size="14px" weight="600" />
            <GradientText text={adData?.currency} size="14px" bg="extsy.g1" />
          </Flex>
          <Flex align="center" justify="space-between">
            <CustomText text="Price Type" size="14px" weight="600" />
            <GradientText text={adData?.priceType} size="14px" bg="extsy.g1" />
          </Flex>
          <Flex align="center" justify="space-between">
            <CustomText text="Fixed" size="14px" weight="600" />
            <GradientText
              text={`$${adData?.price}`}
              size="14px"
              bg="extsy.g1"
            />
          </Flex>
          <Flex align="center" justify="space-between">
            <CustomText text="Order Limit" size="14px" weight="600" />
            <GradientText
              text={`$${adData?.orderLowerLimit} - $${adData?.orderUpperLimit}`}
              size="14px"
              bg="extsy.g1"
            />
          </Flex>
          <Flex align="center" justify="space-between">
            <CustomText text="Total Trading Amount" size="14px" weight="600" />
            <GradientText
              text={adData?.totalTradingAmount}
              size="14px"
              bg="extsy.g1"
            />
          </Flex>
          <Flex align="center" justify="space-between">
            <CustomText text="Estimated Fee" size="14px" weight="600" />
            <GradientText
              text={adData?.estimatedFee}
              size="14px"
              bg="extsy.g1"
            />
          </Flex>
          <Divider h="1px" bg="extsy.neutral900" />
          <Flex align="center" justify="space-between">
            <CustomText text="Payment Method" size="14px" weight="600" />
            <GradientText text="Bank Transfer" size="14px" bg="extsy.g1" />
          </Flex>
          <Flex align="center" justify="space-between">
            <CustomText text="Payment Time Limit" size="14px" weight="600" />
            <GradientText
              text={`${adData?.paymentTimeInMin} mins`}
              size="14px"
              bg="extsy.g1"
            />
          </Flex>
          <Flex align="center" justify="space-between">
            <CustomText text="Available Regions" size="14px" weight="600" />
            <GradientText
              text={adData?.regions?.[0]}
              size="14px"
              bg="extsy.g1"
            />
          </Flex>
        </Flex>
      </CustomModal>
    </Box>
  );
}

export default PostNewAdd;
