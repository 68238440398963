import {
  Avatar,
  Box,
  Circle,
  Flex,
  Image,
  Input,
  SkeletonText,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import CustomText from "../../../components/text/CustomText";
import { IconCheck, IconChevronDown, IconChevronUp } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import ArrowBack from "../../../assets/images/black-left.svg";
import ArrowForward from "../../../assets/images/black-right.svg";
import GradientText from "../../../components/text/GradientText";
import PrimaryButton from "../../../components/button/PrimaryButton";
import CustomModal from "../../../components/modal/customModal";
import CustomButton from "../../../components/button/CustomButton";
import { useOrderItem } from "../../../utils/api/p2p.api";
import ReactPaginate from "react-paginate";
import { useCustomAlert } from "../../../utils/api/alert";
import { useGetLoggedInUser } from "../../../utils/api/auth.api";

function BuyTable({
  data,
  tabName,
  isLoading,
  sortDirection,
  setSortBy,
  setSortDirection,
  offset,
  setPageCount,
  limit,
  pageCount,
  setOffset,
}: any) {
  const { t } = useTranslation();

  const [selectedCoin, setSelectedCoin] = useState<any>();
  const [payAmount, setPayAmount] = useState<any>(0);
  const {
    isOpen: isBuyOpen,
    onOpen: onBuyOpen,
    onClose: onBuyClose,
  } = useDisclosure();

  const OrderItemMutation = useOrderItem();
  const alert = useCustomAlert();
  const { data: userData } = useGetLoggedInUser();

  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * limit) % data?.total;
    setOffset(newOffset);
  };

  useEffect(() => {
    if (data?.total) {
      setPageCount(Math.ceil(data?.total / limit));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, limit]);

  return (
    <Flex flexDir="column" gap="24px">
      <TableContainer
        minH="55vh"
        maxW={{ base: "1164px", "1xl": "100%" }}
        overflowX="auto"
      >
        <Table border="1px solid" color="extsy.neutral900">
          <Thead bg="extsy.neutral900" height="40px">
            <Tr>
              <Th borderColor="extsy.neutral900">
                <Flex align="center" gap="4px">
                  <CustomText
                    text={"Advertisers"}
                    size="13px"
                    color="extsy.baseWhite"
                    weight="500"
                    ls="-0.13px"
                  />
                  <CustomText
                    text={"(Completion rate)"}
                    size="13px"
                    color="extsy.neutral500"
                    weight="500"
                    ls="-0.13px"
                  />
                  <Flex
                    flexDir="column"
                    cursor={"pointer"}
                    onClick={() => {
                      setSortBy("fullname");
                      if (sortDirection === "ASC") {
                        setSortDirection("DESC");
                      } else {
                        setSortDirection("ASC");
                      }
                    }}
                  >
                    <IconChevronUp
                      cursor="pointer"
                      color="#FAFAFA"
                      size="14px"
                    />
                    <IconChevronDown
                      color="#FAFAFA"
                      size="14px"
                      cursor="pointer"
                      style={{
                        marginTop: -7,
                      }}
                    />
                  </Flex>
                </Flex>
              </Th>
              <Th borderColor="extsy.neutral900">
                <Flex align="center" gap="4px">
                  <CustomText
                    text={"Price"}
                    size="13px"
                    color="extsy.baseWhite"
                    weight="500"
                    ls="-0.13px"
                  />
                  <Flex
                    flexDir="column"
                    cursor={"pointer"}
                    onClick={() => {
                      setSortBy("price");
                      if (sortDirection === "ASC") {
                        setSortDirection("DESC");
                      } else {
                        setSortDirection("ASC");
                      }
                    }}
                  >
                    <IconChevronUp
                      cursor="pointer"
                      color="#FAFAFA"
                      size="14px"
                    />
                    <IconChevronDown
                      color="#FAFAFA"
                      size="14px"
                      cursor="pointer"
                      style={{
                        marginTop: -7,
                      }}
                    />
                  </Flex>
                </Flex>
              </Th>
              <Th borderColor="extsy.neutral900">
                <Flex align="center" gap="4px">
                  <CustomText
                    text="Available/Limit"
                    size="13px"
                    color="extsy.baseWhite"
                    weight="500"
                    ls="-0.13px"
                  />
                  <Flex
                    flexDir="column"
                    cursor={"pointer"}
                    onClick={() => {
                      setSortBy("orderLimit");
                      if (sortDirection === "ASC") {
                        setSortDirection("DESC");
                      } else {
                        setSortDirection("ASC");
                      }
                    }}
                  >
                    <IconChevronUp
                      cursor="pointer"
                      color="#FAFAFA"
                      size="14px"
                    />
                    <IconChevronDown
                      color="#FAFAFA"
                      size="14px"
                      cursor="pointer"
                      style={{
                        marginTop: -7,
                      }}
                    />
                  </Flex>
                </Flex>
              </Th>
              <Th borderColor="extsy.neutral900">
                <Flex align="center" gap="4px">
                  <CustomText
                    text="Chain"
                    size="13px"
                    color="extsy.baseWhite"
                    weight="500"
                    ls="-0.13px"
                  />
                  <Flex
                    flexDir="column"
                    cursor={"pointer"}
                    onClick={() => {
                      setSortBy("chain");
                      if (sortDirection === "ASC") {
                        setSortDirection("DESC");
                      } else {
                        setSortDirection("ASC");
                      }
                    }}
                  >
                    <IconChevronUp
                      cursor="pointer"
                      color="#FAFAFA"
                      size="14px"
                    />
                    <IconChevronDown
                      color="#FAFAFA"
                      size="14px"
                      cursor="pointer"
                      style={{
                        marginTop: -7,
                      }}
                    />
                  </Flex>
                </Flex>
              </Th>
              <Th borderColor="extsy.neutral900">
                <Flex align="center" gap="4px">
                  <CustomText
                    text="Payment Method"
                    size="13px"
                    color="extsy.baseWhite"
                    weight="500"
                    ls="-0.13px"
                  />
                  {/* <Flex
                    flexDir="column"
                    cursor={"pointer"}
                    onClick={() => {
                      setSortBy("method");
                      if (sortDirection === "ASC") {
                        setSortDirection("DESC");
                      } else {
                        setSortDirection("ASC");
                      }
                    }}
                  >
                    <IconChevronUp
                      cursor="pointer"
                      color="#FAFAFA"
                      size="14px"
                    />
                    <IconChevronDown
                      color="#FAFAFA"
                      size="14px"
                      cursor="pointer"
                      style={{
                        marginTop: -7,
                      }}
                    />
                  </Flex> */}
                </Flex>
              </Th>
              <Th borderColor="extsy.neutral900">
                <Flex align="center" gap="4px">
                  <CustomText
                    text={t("actions")}
                    size="13px"
                    color="extsy.baseWhite"
                    weight="500"
                    ls="-0.13px"
                  />
                </Flex>
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {isLoading
              ? [1, 2, 3, 4, 5].map((_, index) => <LoadingRow key={index} />)
              : data?.data?.map((order: any, index: any) => (
                  <Tr key={index}>
                    <Td
                      height="64px"
                      borderColor="extsy.neutral900"
                      borderRight="1px solid"
                      py="0"
                    >
                      <Flex gap="13px" align="center">
                        <Avatar src="" boxSize="32px" />
                        <Flex gap="10px" flexDir="column">
                          <Flex gap="8px">
                            <CustomText
                              text={order?.u_fullname}
                              size="14px"
                              color="extsy.baseWhite"
                            />
                            <Circle bg="#10B981" size="12px">
                              <IconCheck size="8px" color="#fafafa" />
                            </Circle>
                          </Flex>
                          <Flex gap="6px" align="center">
                            <CustomText
                              text={`${order?.total_orders || 0} Orders`}
                              size="12px"
                            />
                            <CustomText
                              text="|"
                              size="12px"
                              color="extsy.neutral900"
                            />
                            <CustomText
                              text={`${
                                order?.completed_percentage || 0
                              }% completion`}
                              size="12px"
                            />
                          </Flex>
                        </Flex>
                      </Flex>
                    </Td>
                    <Td borderColor="extsy.neutral900" borderRight="1px solid">
                      <Flex align="center" justify="space-between">
                        <CustomText
                          text={`$${order?.o_price || 0}`}
                          size="14px"
                        />
                        <CustomText
                          text={`USD`}
                          size="14px"
                          color="extsy.neutral700"
                        />
                      </Flex>
                    </Td>
                    <Td borderColor="extsy.neutral900" borderRight="1px solid">
                      <Flex align="center" gap="24px">
                        <Image
                          src={order?.currencylogo}
                          w={"24px"}
                          alt="currecyimg"
                        />
                        <Flex flexDir="column">
                          <CustomText
                            text={"Available"}
                            size="12px"
                            color="extsy.baseWhite"
                          />
                          <CustomText
                            text={`${order?.o_availableTradingAmount} USDT`}
                            size="12px"
                          />
                        </Flex>
                        <Flex flexDir="column">
                          <CustomText
                            text={"Limit"}
                            size="12px"
                            color="extsy.baseWhite"
                          />{" "}
                          <Flex align={"center"} gap={"2px"}>
                            <CustomText
                              text={`${order?.o_orderLowerLimit} ${tabName}`}
                              size="12px"
                            />
                            <CustomText text={"-"} size="12px" />
                            <CustomText
                              text={`${order?.o_orderUpperLimit} ${tabName}`}
                              size="12px"
                            />
                          </Flex>
                        </Flex>
                      </Flex>
                    </Td>
                    <Td borderColor="extsy.neutral900" borderRight="1px solid">
                      <CustomText text={order?.o_chain} size="14px" />
                    </Td>
                    <Td borderColor="extsy.neutral900" borderRight="1px solid">
                      <CustomText text={order?.o_paymentMethod} size="14px" />
                    </Td>
                    <Td borderColor="extsy.neutral900" borderRight="1px solid">
                      {userData?.id !== order?.o_userID && (
                        <PrimaryButton
                          title="Buy USDT"
                          maxW="120px"
                          height="28px"
                          size="12px"
                          onClick={() => {
                            onBuyOpen();
                            setSelectedCoin(order);
                          }}
                        />
                      )}
                    </Td>
                  </Tr>
                ))}
          </Tbody>
        </Table>
      </TableContainer>
      <Flex gap="16px" px="20px" align="center" justify="flex-end">
        {isLoading ? (
          <SkeletonText noOfLines={1} w="120px" />
        ) : (
          <>
            <CustomText
              text={`${offset + 1}-${offset + data?.data?.length} ${t("of")} ${
                data?.total
              }`}
              size="13px"
              weight="500"
              color="extsy.neutral300"
            />
            <ReactPaginate
              breakLabel="..."
              nextLabel={
                <Image
                  src={ArrowForward}
                  alt="arrow-forward"
                  cursor="pointer"
                />
              }
              previousLabel={
                <Image src={ArrowBack} alt="arrow-back" cursor="pointer" />
              }
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              pageCount={pageCount}
              renderOnZeroPageCount={null}
              containerClassName="react-pagination"
              activeClassName="active-pagination"
            />
          </>
        )}
      </Flex>
      <CustomModal
        isOpen={isBuyOpen}
        onClose={() => {
          setPayAmount(0);
          onBuyClose();
        }}
        onSubmit={() => {
          onBuyClose();
        }}
        noHeader
        noFooter
        width={"900px"}
        bgtopBlur="transparent"
        bg="extsy.neutral900"
        borderColor="extsy.neutral1000"
        bgFilter="blur(0px)"
      >
        <Flex p={{ base: "16px", lg: "32px" }} flexDir="column" gap="24px">
          <Flex gap="13px" align="center">
            <Avatar src="" boxSize="48px" />
            <Flex flexDir="column" gap="10px">
              <Flex gap="8px" align="center">
                <CustomText
                  text={selectedCoin?.u_fullname}
                  size="18px"
                  weight="600"
                  color="extsy.baseWhite"
                />
                <Circle
                  bg="#10B981"
                  size="12px"
                  display={selectedCoin?.u_is_verified ? "block" : "none"}
                >
                  <IconCheck size="8px" color="#fafafa" />
                </Circle>
              </Flex>
              <Flex gap="6px" align="center">
                <CustomText
                  text={`${selectedCoin?.orders || 0} Orders`}
                  size="12px"
                />
                <CustomText text={"|"} size="12px" color="extsy.neutral700" />
                <CustomText
                  text={`${selectedCoin?.completion || 0}% Completion`}
                  size="12px"
                />
              </Flex>
            </Flex>
          </Flex>
          <Flex
            align={{ lg: "center" }}
            justify="space-between"
            p="16px"
            bg="extsy.neutral1000"
            flexDir={{
              base: "column",
              lg: "row",
            }}
            gap={{ base: "16px", lg: "0px" }}
            borderRadius={{ base: "16px", lg: "0px" }}
          >
            <Flex flexDir="column" gap="6px">
              <CustomText text="Price" size="12px" />
              <GradientText
                text={`${selectedCoin?.o_price} ${tabName}`}
                size="12px"
                bg="extsy.g1"
              />
            </Flex>
            <Box
              w="1px"
              h="24px"
              bg="extsy.neutral900"
              display={{
                base: "none",
                lg: "block",
              }}
            />
            <Flex flexDir="column" gap="6px">
              <CustomText text="Average Pay Time" size="12px" />
              <GradientText
                text={`${selectedCoin?.o_paymentTimeInMin} mins`}
                size="12px"
                bg="extsy.g1"
              />
            </Flex>
            <Box
              w="1px"
              h="24px"
              bg="extsy.neutral900"
              display={{
                base: "none",
                lg: "block",
              }}
            />
            <Flex flexDir="column" gap="6px">
              <CustomText text="Available" size="12px" />
              <GradientText
                text={`${selectedCoin?.o_totalTradingAmount} ${tabName}`}
                size="12px"
                bg="extsy.g1"
              />
            </Flex>
            <Box
              w="1px"
              h="24px"
              bg="extsy.neutral900"
              display={{
                base: "none",
                lg: "block",
              }}
            />
            <Flex flexDir="column" gap="6px">
              <CustomText text="Chain" size="12px" />
              <GradientText
                text={selectedCoin?.chain}
                size="12px"
                bg="extsy.g1"
              />
            </Flex>
            <Box
              w="1px"
              h="24px"
              bg="extsy.neutral900"
              display={{
                base: "none",
                lg: "block",
              }}
            />
            <Flex flexDir="column" gap="6px">
              <CustomText text="Limit" size="12px" />
              <GradientText
                text={`${selectedCoin?.o_orderLowerLimit} ${tabName} - ${selectedCoin?.o_orderUpperLimit} ${tabName}`}
                size="12px"
                bg="extsy.g1"
              />
            </Flex>
            <Box
              w="1px"
              h="24px"
              bg="extsy.neutral900"
              display={{
                base: "none",
                lg: "block",
              }}
            />
            <Flex flexDir="column" gap="6px">
              <CustomText text="Payment Method" size="12px" />
              <GradientText
                text={selectedCoin?.o_paymentMethod}
                size="12px"
                bg="extsy.g1"
              />
            </Flex>
          </Flex>
          <Flex gap="40px" align="center" justify="space-between">
            <Flex
              flexDir="column"
              gap="12px"
              display={{ base: "none", lg: "flex" }}
            >
              <CustomText text={`Advertisers' Terms`} color="extsy.baseWhite" />
              <CustomText text={"🇰🇭 Only Banks in Cambodia"} size="12px" />
              <CustomText text="🇰🇭 Only Traders in Cambodia" size="12px" />
              <CustomText text="⛔️ No International Wire" size="12px" />
              <CustomText text="⛔️ No Visa Direct" size="12px" />
              <CustomText text="⛔️ International Wallet payment" size="12px" />
              <CustomText text="⛔️ No 3rd Party" size="12px" />
              <CustomText
                text="⛔️ No foreigner who rents Binance using other KYC name."
                size="12px"
              />
              <CustomText text="🔸 I will not release or return" size="12px" />
            </Flex>
            <Flex
              flexDir="column"
              gap="24px"
              p="16px"
              borderRadius="16px"
              bg="extsy.neutral1000"
              maxW="463px"
              w="100%"
            >
              <CustomText
                text="How many USDT you want to buy?"
                size="14px"
                weight="600"
                color="extsy.baseWhite"
              />
              <CustomText
                text={`Price ${selectedCoin?.o_price} USD`}
                size="14px"
                color="#15B097"
              />
              <Flex flexDir="column" gap="12px">
                <Flex flexDir="column" gap="8px">
                  <CustomText text="You Pay" size="14px" />
                  <Flex
                    justify="space-between"
                    gap="12px"
                    px="16px"
                    align="center"
                    bg="extsy.neutral900"
                    borderRadius="8px"
                    cursor={"pointer"}
                    h="40px"
                  >
                    <Input
                      fontSize="14px"
                      fontWeight="400"
                      fontFamily="Metropolis"
                      placeholder="0"
                      // type="number"
                      h="40px"
                      pl="0"
                      py="0"
                      border="none"
                      value={payAmount}
                      onChange={(e: any) => {
                        // Only allow numeric input by filtering out non-digit characters
                        const input = e.target.value.replace(/[^0-9.]/g, ""); // Allow digits and a single decimal point
                        // Convert to a number to enforce numeric comparison
                        // const value = Math.max(
                        //   selectedCoin?.o_orderLowerLimit,
                        //   Math.min(
                        //     Number(input),
                        //     selectedCoin?.o_orderUpperLimit || 0
                        //   )
                        // );

                        setPayAmount(input);
                      }}
                      _placeholder={{ color: "extsy.neutral300" }}
                      _hover={{ border: "none" }}
                      _focus={{
                        border: "none",
                      }}
                      _focusVisible={{ border: "none" }}
                    />
                    <Flex align="center" gap="8px">
                      <CustomText text="USD" size="12px" />
                      <CustomText
                        text="|"
                        size="12px"
                        color="extsy.neutral700"
                      />
                      <GradientText
                        text="All"
                        size="12px"
                        bg="extsy.g1"
                        cursor="pointer"
                        onClick={() =>
                          setPayAmount(selectedCoin?.o_totalTradingAmount)
                        }
                      />
                    </Flex>
                  </Flex>
                </Flex>
                <Flex flexDir="column" gap="8px">
                  <CustomText text="You Get" size="14px" />
                  <Flex
                    justify="space-between"
                    gap="12px"
                    px="16px"
                    align="center"
                    bg="extsy.neutral900"
                    borderRadius="8px"
                    cursor={"pointer"}
                    h="40px"
                  >
                    <Input
                      fontSize="14px"
                      fontWeight="400"
                      fontFamily="Metropolis"
                      placeholder="0"
                      type="number"
                      h="40px"
                      pl="0"
                      py="0"
                      border="none"
                      _placeholder={{ color: "extsy.neutral300" }}
                      _hover={{ border: "none" }}
                      _focus={{
                        border: "none",
                      }}
                      value={Math.max(
                        0,
                        payAmount - selectedCoin?.o_processingFee
                      )}
                      _focusVisible={{ border: "none" }}
                    />
                    <Flex align="center" gap="8px">
                      <Avatar src={selectedCoin?.currencylogo} boxSize="16px" />
                      <CustomText text="USDT" size="12px" />
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
              <Flex align={"center"} justify={"space-between"}>
                <CustomText text="Processing Fee" size="12px" />
                <GradientText
                  text={`${selectedCoin?.o_processingFee} ${tabName} `}
                  size="12px"
                  bg="extsy.g1"
                />
              </Flex>
              <Flex align="center" gap="16px">
                <CustomButton
                  title="Cancel"
                  bg="extsy.neutral900"
                  color="extsy.baseWhite"
                  height="40px"
                  size="12px"
                  onClick={() => {
                    setPayAmount(0);
                    onBuyClose();
                  }}
                />
                <PrimaryButton
                  title="Place Order"
                  height="40px"
                  isLoading={OrderItemMutation?.isPending}
                  isDisabled={OrderItemMutation?.isPending || payAmount <= 0}
                  size="12px"
                  onClick={async () => {
                    try {
                      if (payAmount < 0) {
                        return;
                      }
                      const formData = {
                        amount: payAmount,
                        orderID: selectedCoin.o_id,
                      };
                      await OrderItemMutation?.mutateAsync(formData);
                      setPayAmount(0);
                      onBuyClose();
                      alert?.mutate({
                        message: "Item Ordered Successfully",
                        status: "success",
                      });
                    } catch (error: any) {
                      alert?.mutate({
                        message: error?.message,
                        status: "danger",
                      });
                    }
                  }}
                />
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </CustomModal>
    </Flex>
  );
}

export default BuyTable;

const LoadingRow = () => {
  return (
    <Tr>
      <Td
        height="54px"
        borderColor="extsy.neutral900"
        borderRight="1px solid"
        py="0"
        pos="relative"
        overflow="hidden"
      >
        <SkeletonText noOfLines={1} w="80px" />
      </Td>
      <Td borderColor="extsy.neutral900" borderRight="1px solid">
        <Flex align="center" gap="12px">
          <SkeletonText noOfLines={1} w="40px" />
        </Flex>
      </Td>
      <Td borderColor="extsy.neutral900" borderRight="1px solid">
        <SkeletonText noOfLines={1} w="80px" />
      </Td>
      <Td borderColor="extsy.neutral900" borderRight="1px solid">
        <SkeletonText noOfLines={1} w="80px" />
      </Td>

      <Td borderColor="extsy.neutral900" borderRight="1px solid">
        <SkeletonText noOfLines={1} w="80px" />
      </Td>
      <Td borderColor="extsy.neutral900" borderRight="1px solid">
        <SkeletonText noOfLines={1} w="80px" />
      </Td>
    </Tr>
  );
};
