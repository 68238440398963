import {
  Box,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import React, { useState } from "react";
import QRCode from "react-qr-code";
import CustomText from "../text/CustomText";

function ScanQR({ address, amount, coin, isAmount }: any) {
  const TABS = ["Address", "With Amount"];

  const [tabIndex, setTabIndex] = useState(0);
  return (
    <Flex bg="extsy.bg" borderRadius="16px" flexDir="column" align="center">
      <Tabs
        onChange={(index) => {
          setTabIndex(index);
        }}
        defaultIndex={0}
      >
        <TabList border="none" borderTopRadius="16px" bg="extsy.neutral800">
          {TABS?.filter((tab) =>
            !isAmount ? tab !== "With Amount" : tab
          )?.map((tab, index) => (
            <Tab
              key={index}
              px="32px"
              pt="14px"
              pb="14px"
              w={'100%'}
              color="#F0C2DB"
            >
              <CustomText
                text={tab}
                size="12px"
                weight="700"
                color={
                  index === tabIndex ? "extsy.baseWhite" : "extsy.neutral500"
                }
                cursor="pointer"
              />
            </Tab>
          ))}
        </TabList>
        <TabPanels>
          <TabPanel
            p="14px"
            bg="extsy.neutral1000"
            display="flex"
            justifyContent="center"
          >
            <QRCode
              value={`${coin}:${address}` || ""}
              bgColor="transparent"
              fgColor="#fff"
              size={200}
            />
          </TabPanel>
          <TabPanel
            p="14px"
            bg="extsy.neutral1000"
            display="flex"
            justifyContent="center"
          >
            <QRCode
              value={`${coin}:${address}?amount=${amount}` || ""}
              bgColor="transparent"
              fgColor="#fff"
              size={200}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
      <Box py="12px">
        <CustomText
          text="Scan Me"
          color="extsy.white"
          weight="600"
          align="center"
        />
      </Box>
    </Flex>
  );
}

export default ScanQR;
