import { Flex, Fade, Box } from "@chakra-ui/react";
import React from "react";
import CustomText from "../text/CustomText";
import {
  IconAlertCircleFilled,
  IconCircleCheckFilled,
} from "@tabler/icons-react";
import { useQuery } from "@tanstack/react-query";

function SecondaryValidationAlert({ maxW }: any) {
  const { data: alert }: any = useQuery({
    queryKey: ["alert"],
  });
  const getIcon = () => {
    switch (alert?.status) {
      case "success":
        return <IconCircleCheckFilled size="16px" color="#339900" />;
      case "warning":
        return <IconAlertCircleFilled size="16px" color="#ff9966" />;
      default:
        return <IconAlertCircleFilled size="16px" color="#ED3D4E" />;
    }
  };

  const getColor = () => {
    switch (alert?.status) {
      case "success":
        return "#339900";
      case "warning":
        return "#ff9966";
      default:
        return "#ED3D4E";
    }
  };

  const getTitle = () => {
    switch (alert?.status) {
      case "success":
        return "Success";
      case "warning":
        return "Warning";
      default:
        return "Error";
    }
  };

  // Conditionally render only if alert has a message
  if (!alert?.message) return null;

  return (
    <Fade in={!!alert?.message}>
      <Flex
        align="center"
        gap="16px"
        p="24px 16px"
        borderRadius="5px"
        maxW={maxW || { base: "200px", sm: "260px", md: "400px" }}
        w="100%"
        zIndex="100000"
        position="fixed"
        bottom="30px"
        right="80px"
        bg="#000F18"
        border="1px solid #474747"
        transition="all 0.3s ease"
        _hover={{ transform: "scale(1.02)", boxShadow: "lg" }}
        overflow="hidden"
      >
        <Box
          bg="#ED3D4E"
          h="174px"
          w="174px"
          borderRadius="174px"
          pos="absolute"
          top="0"
          mt="-150px"
          ml="-80px"
          left="0"
          filter="blur(117px)"
          zIndex="1"
        />
        <Flex
          align="center"
          justify="center"
          h="24px"
          w="24px"
          borderRadius="999px"
          bg="#000F18"
          zIndex="100"
        >
          {getIcon()}
        </Flex>
        <Flex flexDir="column" gap="4px">
          <CustomText
            text={getTitle()}
            size="18px"
            lh="18px"
            weight="700"
            family="Metropolis"
            color={getColor()}
          />
          <CustomText text={alert.message} size="14px" color="#fafafa" />
        </Flex>
      </Flex>
    </Fade>
  );
}

export default SecondaryValidationAlert;
