import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { client } from "./api-client";

export const useGetNetworks = (limit: number, offset: number) => {
  return useQuery<any, Error>({
    queryKey: ["getNetworks", limit, offset],
    queryFn: async () => {
      const queryParams = new URLSearchParams({
        limit: limit.toString(),
        offset: offset.toString(),
      });
      const response = await client(
        `wallet/getNetworks?${queryParams.toString()}`
      );
      if (response.error) {
        throw new Error(response.error);
      }
      return response;
    },
  });
};

export const useGetConnectedWalletsByAddress = (
  address: string,
  limit: number,
  offset: number,
  search: string,
  sortingKey: string,
  sortingOrder: string
) => {
  return useQuery<any, Error>({
    queryKey: [
      "getConnectedWallets",
      address,
      limit,
      offset,
      search,
      sortingKey,
      sortingOrder,
    ],
    queryFn: async () => {
      const queryParams = new URLSearchParams({
        limit: limit.toString(),
        offset: offset.toString(),
        search: search.toString(),
        sortingKey: sortingKey.toString(),
        sortingOrder: sortingOrder.toString(),
      });
      const response = await client(
        `wallet/getConnectedWalletByAddress/${address}?${queryParams.toString()}`
      );
      if (response.error) {
        throw new Error(response.error);
      }
      return response;
    },
  });
};

export const useAddConnectWallet = () => {
  const queryClient = useQueryClient();

  return useMutation<any, Error, any>({
    mutationFn: async (payload) => {
      const response = await client(`wallet/addConnectWallet`, {
        method: "POST",
        data: payload,
      });

      if (response.error) {
        throw new Error(response.error);
      }
      queryClient.invalidateQueries({ queryKey: ["getConnectedWallets"] });
      return response;
    },
  });
};

export const useGetWalletBalance = () => {
  return useMutation<any, Error, any>({
    mutationFn: async (payload) => {
      const response = await client(`wallet/getWalletBalanceForDashboard`, {
        method: "POST",
        data: payload,
      });

      if (response.error) {
        throw new Error(response.error);
      }
      return response;
    },
  });
};
