import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Image,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import PrimeIco from "../../assets/images/prime-ico.svg";
import SupremeIco from "../../assets/images/superme-ico.svg";
import QuantlaneIco from "../../assets/images/quantlane-ico.svg";
import CustomText from "../text/CustomText";
import { useTranslation } from "react-i18next";
import FeatureCheck from "../../assets/images/feature-check.svg";
import PrimaryButton from "../button/PrimaryButton";
import CustomModal from "../modal/customModal";
// import SupremeIcoDark from "../../assets/images/supreme-ico-dark.svg";
import { Elements } from "@stripe/react-stripe-js";
import { stripePromise } from "../../App";
import StripePayment from "./StripePayment";
import {
  useCancelSubscription,
  useCreatePaymentIntentForSubscription,
} from "../../utils/api/settings.api";
import VerifySuccessImg from "../../assets/images/verify-kyc-icon.svg";
import { useQueryClient } from "@tanstack/react-query";
import { useGetSumsubToken } from "../../utils/api/auth.api";
import SumsubWebSdk from "@sumsub/websdk-react";
import { OnboardModal } from "../modal/onBoardModal";
import { useCustomAlert } from "../../utils/api/alert";

function PlanCard({ plan, user }: any) {
  const { t } = useTranslation();

  const queryClient: any = useQueryClient();
  const [paymentIntentInfo, setPaymentInfoIntent] = useState<any>(null);
  const createPayment = useCreatePaymentIntentForSubscription();
  const cancelSubscriptionMutation = useCancelSubscription();
  const alert = useCustomAlert();
  const { data: sumsubToken } = useGetSumsubToken();
  const [isVerificationModalOpen, setIsVerificationModalOpen] = useState(false);
  const [isVerifySuccessOpen, setIsVerifySuccessOpen] = useState(false);

  const createPaymentIntent = async () => {
    try {
      const res = await createPayment?.mutateAsync(plan?.id);

      if (res?.data) {
        setPaymentInfoIntent({
          payment: res?.data,
          paymentIntent: res?.data,
        });
        if (typeof window !== "undefined") {
          window?.localStorage?.setItem(
            "paymentInfo",
            JSON.stringify({
              payment: res?.data,
              paymentIntent: res?.data,
            })
          );
        }
      }
      if (res?.error) {
      }
    } catch (error: any) {}
  };

  const {
    isOpen: isCancelOpen,
    onOpen: onCancelOpen,
    onClose: onCancelClose,
  } = useDisclosure();

  const {
    isOpen: isUpgradeOpen,
    onOpen: onUpgradeOpen,
    onClose: onUpgradeClose,
  } = useDisclosure();

  const getIco = () => {
    switch (plan?.name) {
      case "Standard":
        return PrimeIco;
      case "Premium":
        return SupremeIco;
      case "Ultra":
        return QuantlaneIco;
      default:
        return PrimeIco;
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      const paymentInfo = window?.localStorage?.getItem("paymentInfo");
      if (paymentInfo) {
        const payment = JSON.parse(paymentInfo);
        setPaymentInfoIntent(payment);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Flex
      pos="relative"
      px={{ base: "16px", lg: "32px" }}
      py={{ base: "16px", lg: "40px" }}
      borderRadius={{ base: "16px", lg: "24px" }}
      borderTop="4px solid"
      borderColor={
        plan?.id === user?.user_subscription?.plan_id
          ? "extsy.baseWhite"
          : "transparent"
      }
      bg="extsy.neutral1000"
      //   minW="490.66px"
      //   maxW="490.66px"
      width="100%"
      flexDir="column"
      justify="space-between"
      gap={{ base: "16px", lg: "55px" }}
      overflow={plan?.is_popular ? "hidden" : ""}
    >
      {plan?.id === user?.user_subscription?.plan_id && (
        <Flex
          justify="center"
          align="center"
          pos="absolute"
          top="0"
          mt="-16px"
          zIndex="1000"
          bg="extsy.g1"
          height="28px"
          width="166px"
          borderRadius="999px"
        >
          <CustomText
            text={t("currentPlan")}
            color="extsy.bg"
            size="14px"
            weight="500"
          />
        </Flex>
      )}
      <Box zIndex="100">
        <Flex align="center" justify="space-between">
          <Flex
            cursor="pointer"
            bg="rgba(250, 250, 250, 0.10)"
            align="center"
            gap="12px"
            pe="24px"
            ps="10px"
            h="40px"
            borderRadius="8px"
          >
            <Image src={getIco()} alt="prime" height="22.5px" width="26.4px" />
            <CustomText
              text={plan?.name}
              color="extsy.baseWhite"
              size="14px"
              cursor="pointer"
            />
          </Flex>
          {plan?.is_popular && (
            <Flex
              bg="extsy.secondary100"
              width="85px"
              h="28px"
              borderRadius="8px"
              align="center"
              justify="center"
            >
              <CustomText
                text={t("popular")}
                size="14px"
                color="extsy.dark1000"
                weight="500"
              />
            </Flex>
          )}
        </Flex>
        <Flex mt="24px" align="center" justify="space-between">
          <CustomText
            text={
              <>
                <span>{`$${plan?.price}`}</span>
                <span
                  style={{
                    fontSize: "24px",
                    fontWeight: "700",
                    color: "#828282",
                  }}
                >
                  .00
                </span>
              </>
            }
            color="extsy.baseWhite"
            size="41px"
            weight="600"
          />
          <CustomText
            text={t("perMonth")}
            color="extsy.baseWhite"
            size="12px"
          />
        </Flex>
        <Divider my="16px" borderColor="extsy.neutral900" height="1px" />
        <CustomText text={t("features")} color="extsy.baseWhite" weight="600" />
        <Flex mt="24px" gap="24px" flexDir="column">
          {plan?.features?.map((feature: any, idx: number) => (
            <Flex align="center" gap="16px" key={idx}>
              <Image
                src={FeatureCheck}
                alt="check"
                draggable={false}
                height="24px"
                width="24px"
              />
              <CustomText text={feature} color="extsy.baseWhite" />
            </Flex>
          ))}
        </Flex>
      </Box>
      {user?.kyc_status !== "APPROVED" ? (
        <Flex justify="center">
          <PrimaryButton
            // isDisabled={user?.kyc_level <= 0}
            isLoading={createPayment?.isPending}
            title={"Upgrade Kyc"}
            maxW={{ lg: "250px" }}
            height={{ base: "48px", lg: "56px" }}
            onClick={() => {
              setIsVerificationModalOpen(true);
            }}
          />
        </Flex>
      ) : (
        plan?.price !== 0 &&
        (plan?.id === user?.user_subscription?.plan_id ? (
          <Button
            maxW={{ lg: "250px" }}
            w="100%"
            mx="auto"
            h={{ base: "48px", lg: "56px" }}
            bg="extsy.neutral800"
            borderRadius="8px"
            fontFamily="Metropolis"
            fontSize="16px"
            fontWeight="600"
            color="extsy.neutral300"
            letterSpacing="0.32px"
            zIndex="100"
            _hover={{
              bg: "extsy.neutral800",
            }}
            onClick={onCancelOpen}
          >
            {t("cancelPlan")}
          </Button>
        ) : (
          <Flex justify="center" zIndex="100">
            <PrimaryButton
              // isDisabled={user?.kyc_level <= 0}
              isLoading={createPayment?.isPending}
              title={
                user?.kyc_status !== "APPROVED"
                  ? "Upgrade Kyc"
                  : user?.user_subscription?.plan?.name === "Ultra"
                  ? "Downgrade"
                  : t("upgrade")
              }
              maxW={{ lg: "250px" }}
              height={{ base: "48px", lg: "56px" }}
              onClick={() => {
                if (user?.kyc_status !== "APPROVED") {
                  setIsVerificationModalOpen(true);
                } else {
                  createPaymentIntent()
                    .then((result) => {
                      onUpgradeOpen();
                    })
                    .catch((err) => {
                      alert?.mutate({
                        message: err?.message,
                        status: "danger",
                      });
                    });
                }
              }}
            />
          </Flex>
        ))
      )}
      <CustomModal
        isOpen={isCancelOpen}
        onClose={() => {
          onCancelClose();
        }}
        isLoading={cancelSubscriptionMutation?.isPending}
        isDisabled={cancelSubscriptionMutation?.isPending}
        onSubmit={async () => {
          try {
            await cancelSubscriptionMutation.mutateAsync();
            alert?.mutate({
              message: "Plan cancelled successfully",
              status: "success",
            });
            onCancelClose();
          } catch (error: any) {
            alert?.mutate({
              message: error?.message,
              status: "danger",
            });
          }
        }}
        headerText={"Cancel Plan"}
        btnText={"Yes, Cancel it"}
        closeBtntext={"Not Now"}
        width={"600px"}
      >
        <Flex
          p={{ base: "24px 16px", lg: "32px 24px" }}
          flexDir="column"
          gap="32px"
          alignItems="center"
        >
          <CustomText
            text={"Are you sure you want to cancel your current plan?"}
            align="center"
            color="extsy.neutral300"
          />
        </Flex>
      </CustomModal>

      <CustomModal
        isOpen={isUpgradeOpen}
        onClose={() => {
          onUpgradeClose();
        }}
        onSubmit={() => {
          onUpgradeClose();
        }}
        headerText={"Purchase Plan"}
        btnText={"Purchase Plan"}
        width={"600px"}
        noFooter={true}
        closeBtntext="Close"
        bgtopBlur={"extsy.bgCardTopBlur"}
      >
        <Box position={"relative"}>
          <Flex
            p={{ base: "24px 16px", lg: "32px 24px" }}
            flexDir="column"
            gap="32px"
          >
            <Flex
              gap="19.2px"
              align="center"
              height={{ base: "56px", lg: "80px" }}
              width="100%"
              justify="center"
              bg="extsy.g1"
              borderRadius="16px"
            >
              <Image
                src={getIco()}
                alt="prime"
                height={{ base: "24px", lg: "36px" }}
                width={{ base: "24px", lg: "42px" }}
              />
              <CustomText
                text={plan?.name}
                color="extsy.bg"
                size={{ base: "24px", lg: "32px" }}
                weight="500"
              />
            </Flex>

            {stripePromise && paymentIntentInfo?.payment?.client_secret && (
              <Box width={"100%"} height={"100%"} minW={"400px"} minH={"100px"}>
                <Elements
                  stripe={stripePromise}
                  options={{
                    clientSecret: paymentIntentInfo?.payment?.client_secret,
                    appearance: {
                      theme: "night",
                      rules: {
                        ".Input": {
                          color: "extsy.baseWhite",
                          backgroundColor: "#161616",
                          border: "none",
                          fontSize: "16px",
                          height: "122px",
                          padding: "19.5px 19px",
                          borderRadius: "16px",
                          boxShadow: "none",
                        },
                        ".Label": {
                          color: "extsy.neutral300",
                          fontSize: "16px",
                          marginBottom: "12px",
                        },
                      },
                    },
                  }}
                >
                  <StripePayment task={plan} onClose={onUpgradeClose} />
                </Elements>
              </Box>
            )}
          </Flex>
        </Box>
      </CustomModal>
      <OnboardModal
        isOpen={isVerificationModalOpen}
        onClose={() => {
          setIsVerificationModalOpen(false);
        }}
        onSubmit={() => {
          setIsVerifySuccessOpen(true);
        }}
        headerDivider
        title={
          <>
            <CustomText
              text="Complete Verification"
              weight="600"
              size={{ base: "20px", md: "30px" }}
              isTruncated
              maxW={{ base: "86%", sm: "95%" }}
              pb="16px"
              color="extsy.white"
            />
            <CustomText
              text="Start your verification through Sumsub."
              size={{ base: "14px", md: "16px" }}
            />
          </>
        }
        isFooter={false}
      >
        {/* <Center py="40px" borderRadius={"20px"} mb="24px" w={"100%"}> */}
        <SumsubWebSdk
          accessToken={sumsubToken?.token}
          expirationHandler={() => sumsubToken?.token}
          config={{
            lang: "en",
          }}
          // options={options}
          onMessage={async (data: any, payload: any) => {
            // console.log("kyc---------sumsub", data, payload?.reviewStatus);
            if (
              payload?.reviewStatus === "completed" &&
              payload?.reviewResult?.reviewAnswer === "GREEN"
            ) {
              setIsVerificationModalOpen(false);
              setIsVerifySuccessOpen(true);
            }
          }}
          onError={(data: any) => console.log("onError", data)}
        />
        {/* <Image src={SumsubImg} /> */}
        {/* </Center> */}
      </OnboardModal>
      <OnboardModal
        isOpen={isVerifySuccessOpen}
        onClose={() => {
          setIsVerifySuccessOpen(false);
          queryClient.invalidateQueries(["getLoggedInUser"]);
        }}
        onSubmit={() => {
          setIsVerifySuccessOpen(false);
          queryClient.invalidateQueries(["getLoggedInUser"]);
        }}
        headerDivider
        size="sm"
        noHeader={true}
        // btnText="Create a Community"

        isFooter={false}
      >
        <Center
          py="30px"
          px={"20px"}
          borderRadius={"20px"}
          mb="24px"
          gap={"24px"}
          w={"100%"}
          flexDirection={"column"}
        >
          <Image src={VerifySuccessImg} m="24px auto 0px" mb={"24px"} />
          <CustomText
            text="Congratulation!"
            fontFamily="Power Grotesk"
            color="extsy.white"
            size={{ base: "22px", md: "24px" }}
          />
          <CustomText
            text="Your account has been verified successfully."
            size={{ base: "14px", md: "16px" }}
          />
          <PrimaryButton
            width="182px"
            height="56px"
            onClick={() => {
              setIsVerifySuccessOpen(false);
              queryClient.invalidateQueries(["getLoggedInUser"]);
            }}
            title={"Done"}
          />
        </Center>
      </OnboardModal>
    </Flex>
  );
}

export default PlanCard;
