import { Box, Collapse, Flex } from "@chakra-ui/react";
import React, { useState } from "react";
import CustomText from "../text/CustomText";
import { IconMinus, IconPlus } from "@tabler/icons-react";

function FAQs() {
  const FAQS_DATA = [
    {
      title: "How do I increase my cashback limit?",
      description:
        "You can increase your cashback limit by upgrading from the Basic Plan to either the Pro Plan or Elite Plan. The Pro Plan offers a monthly cashback limit of 0.015 BTC, while the Elite Plan provides a much higher limit of 0.05 BTC per month.",
    },
    {
      title: "What are the conditions for receiving cashback?",
      description:
        "To receive cashback, you must sign up on the platform, select a subscription plan (Basic, Pro, or Elite), and perform swaps while logged in. Cashback is available for every swap you make, with the percentage and limit depending on your chosen plan. The Basic Plan offers 0.02% cashback with a 0.0015 BTC monthly limit, the Pro Plan gives 0.03% cashback with a 0.015 BTC monthly limit, and the Elite Plan offers 0.05% cashback with a 0.05 BTC monthly limit. A minimum payout limit of 0.01 BTC applies to all plans.",
    },
    {
      title: "How do I access more features of my Pro account?",
      description:
        "To access additional features, you can upgrade your subscription plan. Moving from the Basic Plan to the Pro or Elite Plans unlocks higher cashback, more AML checks, access to virtual cards, and crypto futures trading. The Elite Plan also offers unlimited AML checks and more virtual cards.",
    },
    {
      title: "What are the benefits of upgrading my subscription plan?",
      description:
        "Upgrading your subscription plan unlocks several key benefits. The Pro Plan increases your cashback rate to 0.03%, raises your monthly cashback limit to 0.015 BTC, and provides 100 Anti-Money Laundering (AML) checks along with 2 virtual cards. By upgrading to the Elite Plan, you'll benefit from a 0.05% cashback rate, a monthly cashback limit of 0.05 BTC, unlimited AML checks, and access to 5 virtual cards as well.",
    },
  ];

  const [isOpen, setIsOpen] = useState(-1);

  return (
    <Box
      p={{ base: "16px", lg: "24px" }}
      bg="extsy.neutral1000"
      borderRadius={{ base: "16px", lg: "24px" }}
      boxShadow="0px 3px 4px 0px rgba(0, 0, 0, 0.03)"
    >
      <CustomText
        text="FAQs"
        size={{ base: "18px", lg: "24px" }}
        weight={{ base: "600", lg: "700" }}
        family="Metropolis"
        color="extsy.baseWhite"
      />
      <Flex flexDir="column" gap="16px" mt="24px">
        {FAQS_DATA.map((faq, idx) => (
          <Box
            key={idx}
            py="16px"
            pe={{ lg: "32px" }}
            borderTop="1px solid"
            borderBottom="1px solid"
            borderColor={isOpen === idx ? "extsy.neutral700" : "transparent"}
          >
            <Flex align={{ lg: "center" }} justify="space-between">
              <Flex gap="10px" align={{ lg: "center" }}>
                <Box>
                  <CustomText text={`0${idx + 1}`} wrap="nowrap" />
                </Box>
                <CustomText
                  text={faq.title}
                  color="extsy.baseWhite"
                  size={{ base: "14px", lg: "16px" }}
                />
              </Flex>
              <Box mt={{ base: "-7px", lg: "0px" }}>
                {isOpen === idx ? (
                  <IconMinus
                    color="#fafafa"
                    size="24px"
                    cursor="pointer"
                    onClick={() => setIsOpen(-1)}
                  />
                ) : (
                  <IconPlus
                    color="#fafafa"
                    size="24px"
                    cursor="pointer"
                    onClick={() => setIsOpen(idx)}
                  />
                )}
              </Box>
            </Flex>
            <Collapse in={isOpen === idx} animateOpacity>
              <CustomText
                text={faq.description}
                mt="24px"
                size={{ base: "14px", lg: "16px" }}
              />
            </Collapse>
          </Box>
        ))}
      </Flex>
    </Box>
  );
}

export default FAQs;
