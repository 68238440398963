import {
  Box,
  Flex,
  Image,
  Skeleton,
  SkeletonText,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import CustomText from "../text/CustomText";
import { useTranslation } from "react-i18next";
import SwapAmountInput from "../form/SwapAmountInput";
import {
  IconArrowDown,
  IconArrowUp,
  IconChevronDown,
} from "@tabler/icons-react";
import PrimaryButton from "../button/PrimaryButton";
import InfoIco from "../../assets/images/info-ico.svg";
import FiatCurrencyDropdown from "../form/FiatCurrencyDropdown";
import { useGetQuote, useGetSupported } from "../../utils/api/onramper.api";
import { formatNumber } from "../../utils/functions/formatNumber";
import { CryptoBoxProps } from "../../utils/types/types";
import OutsideClickHandler from "../../hooks/CustomOutsideClick";
import OnRampCurrencyDropdown from "../form/OnRampCurrencyDropdown";

function BuySellCard() {
  const { t } = useTranslation();

  const tabs = [t("buy"), t("sell")];
  const [tabIndex, setTabIndex] = useState(0);
  const [amount, setAmount] = useState("0");
  const [coinA, setCoinA] = useState<any>(null);
  const [coinB, setCoinB] = useState<any>(null);
  const [quoteId, setQuoteId] = useState<string>("");
  const [fiatCurrencies, setFiatCurrencies] = useState<any[]>([]);
  const [cryptoCurrencies, setCryptoCurrencies] = useState<any[]>([]);
  const [bank, setBank] = useState<string>("");
  const [bestPrice, setBestPrice] = useState<number>(0);
  const [payout, setPayout] = useState<number>(0);
  const [errors, setErrors] = useState({
    buySellAmount: "",
    buySellReceiever: "",
    buySellError: "",
    isTermsAgreed: "",
    bank: "",
  });

  const { data: supportedCurrencies, isLoading: coinsLoading } =
    useGetSupported();
  const getQuote = useGetQuote();

  const getBestPriceQuote = () => {
    const findBestPrice = getQuote?.data?.find((quote: any) =>
      quote?.recommendations?.includes("BestPrice")
    );
    return findBestPrice;
  };

  useEffect(() => {
    if (formatNumber(getBestPriceQuote()?.rate, 2) === formatNumber(0)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        buySellAmount: getQuote?.data?.[0]?.errors?.[0]?.message,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount, getQuote?.data]);

  useEffect(() => {
    if (coinA && coinB) {
      getQuote.mutate({
        from: tabIndex === 0 ? coinA?.id : coinB?.id,
        to: tabIndex === 0 ? coinB?.id : coinA?.id,
        amount: amount,
        type: tabIndex === 0 ? "buy" : "sell",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coinA, coinB, amount, tabIndex]);

  useEffect(() => {
    if (supportedCurrencies && !coinA && !coinB) {
      const findUSD = supportedCurrencies?.message?.fiat?.find(
        (coin: any) => coin?.id === "usd"
      );
      const findBtc = supportedCurrencies?.message?.crypto?.find(
        (coin: any) => coin?.id === "btc"
      );
      setCoinA(findUSD);
      setCoinB(findBtc);
    }
  }, [supportedCurrencies, coinA, coinB]);

  useEffect(() => {
    if (supportedCurrencies) {
      setFiatCurrencies(supportedCurrencies?.message?.fiat);
      setCryptoCurrencies(supportedCurrencies?.message?.crypto);
    }
  }, [supportedCurrencies]);

  useEffect(() => {
    if (getQuote?.data) {
      const findBestPrice = getQuote?.data?.find((quote: any) =>
        quote?.recommendations?.includes("BestPrice")
      );
      console.log(findBestPrice?.rate);
      if (findBestPrice) {
        setQuoteId(findBestPrice?.quoteId);
        setBestPrice(findBestPrice?.rate);
        setPayout(findBestPrice?.payout);
        setBank(findBestPrice?.ramp);
      } else {
        setQuoteId(getQuote?.data?.[0]?.quoteId);
        setBestPrice(getQuote?.data?.[0]?.rate);
        setPayout(getQuote?.data?.[0]?.payout);
        setBank(getQuote?.data?.[0]?.ramp);
      }
    }
    if (getQuote?.data?.length === 0) {
      setErrors({
        ...errors,
        buySellAmount: "Currency not supported",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getQuote?.data]);

  return (
    <Box position="relative">
      <Tabs p="0" onChange={(index) => setTabIndex(index)} defaultIndex={0}>
        <TabList
          overflow="hidden"
          width="116px"
          p="2px"
          //   height="28px"
          borderRadius="4px"
          border="1px solid #000"
          bg="extsy.dark300"
          ml="auto"
        >
          {tabs.map((tab, index) => (
            <Tab
              key={index}
              display="flex"
              alignItems="center"
              gap="4px"
              p="6px 10px"
              borderRadius="4px"
              height="24px"
              width="56px"
              bg={tabIndex === index ? "extsy.g1" : "transparent"}
              color="extsy.dark300"
            >
              <CustomText
                text={tab}
                cursor="pointer"
                size="12px"
                family="Metropolis"
                color={tabIndex === index ? "extsy.dark300" : "extsy.white"}
              />
              {tab === "Buy" ? (
                <IconArrowDown
                  size={12}
                  color={tabIndex === index ? "#000F18" : "white"}
                />
              ) : (
                <IconArrowUp
                  size={12}
                  color={tabIndex === index ? "#000F18" : "white"}
                />
              )}
            </Tab>
          ))}
        </TabList>
        <TabPanels mt="14px" p="0">
          <TabPanel p="0" display="flex" flexDir="column" gap="10px">
            <FiatBox
              activeCurrency={coinA}
              setActiveCurrency={setCoinA}
              currencies={fiatCurrencies}
              amount={amount}
              setAmount={setAmount}
              bestPrice={bestPrice}
              errors={errors}
              isLoading={coinsLoading}
            />
            <CryptoBox
              setActiveCurrency={setCoinB}
              activeCurrency={coinB}
              currencies={cryptoCurrencies}
              secondCurrency={coinA}
              bestPrice={bestPrice}
              payout={payout}
              read={true}
              coinsLoading={getQuote?.isPending}
              bank={bank}
              isLoading={coinsLoading}
            />
          </TabPanel>
          <TabPanel p="0" display="flex" flexDir="column" gap="10px">
            <CryptoBox
              setActiveCurrency={setCoinB}
              activeCurrency={coinB}
              currencies={cryptoCurrencies}
              amount={amount}
              secondCurrency={coinA}
              setAmount={setAmount}
              bestPrice={bestPrice}
              errors={errors}
              bank={bank}
              isLoading={coinsLoading}
            />
            <FiatBox
              activeCurrency={coinA}
              setActiveCurrency={setCoinA}
              currencies={fiatCurrencies}
              bestPrice={bestPrice}
              payout={payout}
              read={true}
              coinsLoading={getQuote?.isPending}
              isLoading={coinsLoading}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>

      {/* <Flex my="17px" gap="8px" align="flex-start">
        <Image src={AgreeCheckbox} alt="agree" />
        <CustomText
          text={t("readAgreeTerms")}
          family="Metropolis"
          size="12px"
          color="extsy.white"
        />
      </Flex> */}
      <Box mt={"17px"}>
        <PrimaryButton
          title={tabIndex === 0 ? t("buyNow") : t("sellNow")}
          width="100%"
          height="47px"
          onClick={() => {
            if (tabIndex === 0) {
              window.open(
                `${process.env.REACT_APP_EXTSY_PRE_RELEASE_DOMAIN}/pages/buySell/?from=${coinA?.id}&to=${coinB?.id}&amount=${amount}&tab=buy&quoteId=${quoteId}`
              );
            } else {
              window.open(
                `${process.env.REACT_APP_EXTSY_PRE_RELEASE_DOMAIN}/pages/buySell/?from=${coinA?.id}&to=${coinB?.id}&amount=${amount}&tab=sell&quoteId=${quoteId}`
              );
            }
          }}
        />
      </Box>
    </Box>
  );
}

const CryptoBox: React.FC<CryptoBoxProps> = ({
  activeCurrency,
  setActiveCurrency,
  currencies,
  secondCurrency,
  bestPrice,
  amount,
  setAmount,
  payout,
  errors,
  read,
  coinsLoading,
  bank,
  isLoading,
}: any) => {
  const { t } = useTranslation();
  const [isCurrency2Open, setIsCurrency2Open] = useState(false);
  console.log(coinsLoading);

  return (
    <Box
      p={{ base: "16px", lg: "10px 19px" }}
      borderRadius={{ base: "16px", lg: "20.5px" }}
      border="0.857px solid"
      borderColor="extsy.white"
      backdropBlur="blur(5.39px)"
      pos="relative"
    >
      <Flex align="center" justify="space-between" gap="16px">
        <Flex flexDir="column" gap="9px" maxW="164px">
          {isLoading ? (
            <SkeletonText w="120px" h="10px" mt="30px" noOfLines={1} />
          ) : (
            <SwapAmountInput
              fontSize={{ base: "16px", lg: "18px" }}
              value={read ? formatNumber(payout, 6) : amount}
              onChange={(e: any) => {
                const value = e.target.value;
                // Allow only empty string, numbers, or valid decimal values
                if (/^\d*\.?\d*$/.test(value)) {
                  setAmount(value);
                }
              }}
              isLoading={coinsLoading}
              error={errors?.buySellAmount}
              readOnly={read ? true : false}
            />
          )}
        </Flex>
        <Flex align="center" gap="10px">
          <Flex
            gap="10px"
            align="center"
            onClick={() => {
              setIsCurrency2Open(!isCurrency2Open);
            }}
          >
            {isLoading ? (
              <Skeleton height="24px" width="50px" />
            ) : (
              <Flex
                flexDir="column"
                gap="2px"
                align="flex-end"
                cursor="pointer"
              >
                <Flex align="center" gap="8px">
                  <IconChevronDown color="#fff" size="16px" />
                  <CustomText
                    text={activeCurrency?.code}
                    size="14px"
                    weight="700"
                    family="Metropolis"
                    color="extsy.white"
                    cursor="pointer"
                  />
                </Flex>
                <CustomText
                  text={activeCurrency?.name}
                  size="10px"
                  color="extsy.white"
                  family="Metropolis"
                  cursor="pointer"
                />
              </Flex>
            )}
            <Image
              src={activeCurrency?.icon}
              alt="btc"
              cursor="pointer"
              height="24px"
              width="24px"
            />
          </Flex>
        </Flex>
      </Flex>
      {isCurrency2Open && (
        <OutsideClickHandler
          isOpen={isCurrency2Open}
          onClose={() => {
            setIsCurrency2Open(false);
          }}
        >
          <OnRampCurrencyDropdown
            currencies={currencies}
            activeCurrency={activeCurrency}
            onClick={(currency: any) => {
              setActiveCurrency(currency);
              setIsCurrency2Open(false);
            }}
          />
        </OutsideClickHandler>
      )}
      <Flex
        align="center"
        mt={{ base: "12px", lg: "19px" }}
        gap="10px"
        justify="center"
      >
        <Flex align="center" gap="6px">
          <CustomText
            text={t("estimatedRate")}
            size="10px"
            family="Metropolis"
            color="extsy.white"
            underline
            cursor="pointer"
          />
          <Image src={InfoIco} alt="info" />
        </Flex>
        {isLoading ? (
          <Skeleton height="20px" width="100px" />
        ) : (
          <CustomText
            text={` 1 ${activeCurrency?.code} ~ ${formatNumber(bestPrice, 2)}
                ${secondCurrency?.code}`}
            size="10px"
            family="Metropolis"
            color="extsy.white"
          />
        )}

        <Flex align="center" gap={1}>
          <CustomText
            size="12px"
            family="Metropolis"
            color="extsy.white"
            text="by"
          />
          <Flex align={"center"} gap={1} mb={"4px"}>
            {/* <Image src={BankIcon} alt="" /> */}
            {coinsLoading ? (
              <Skeleton width={12} height={3} />
            ) : (
              <CustomText
                text={bank}
                size="14px"
                family="Metropolis"
                color="extsy.white"
              />
            )}
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};

const FiatBox = ({
  activeCurrency,
  setActiveCurrency,
  currencies,
  amount,
  setAmount,

  payout,
  errors,
  read,
  coinsLoading,
  isLoading,
}: any) => {
  const [isCurrency1Open, setIsCurrency1Open] = useState(false);
  console.log(typeof payout);
  return (
    <Box
      p={{ base: "16px", lg: "10px 19px" }}
      borderRadius={{ base: "16px", lg: "20.5px" }}
      border="0.857px solid"
      borderColor="extsy.white"
      backdropBlur="blur(5.39px)"
      pos="relative"
    >
      <Flex align="center" justify="space-between" gap="16px">
        <Flex flexDir="column" gap="9px" maxW="164px">
          {isLoading ? (
            <SkeletonText w="120px" h="10px" mt="30px" noOfLines={1} />
          ) : (
            <SwapAmountInput
              noBorder
              fontSize={{ base: "16px", lg: "18px" }}
              value={read ? formatNumber(payout, 2) : amount}
              onChange={(e: any) => {
                const value = e.target.value;
                // Allow only empty string, numbers, or valid decimal values
                if (/^\d*\.?\d*$/.test(value)) {
                  setAmount(value);
                }
              }}
              isLoading={read ? coinsLoading : false}
              readOnly={read ? true : false}
              error={errors?.buySellAmount}
            />
          )}
        </Flex>
        <Flex align="center" gap="10px">
          <Flex
            align="center"
            gap="10px"
            cursor="pointer"
            onClick={() => {
              setIsCurrency1Open(!isCurrency1Open);
            }}
          >
            {isLoading ? (
              <Skeleton height="24px" width="50px" />
            ) : (
              <Flex flexDir="column" gap="2px" align="flex-end">
                <Flex align="center" gap="8px">
                  <IconChevronDown color="#fff" size="16px" />
                  <CustomText
                    text={activeCurrency?.code}
                    size="14px"
                    weight="700"
                    family="Metropolis"
                    color="extsy.white"
                    cursor="pointer"
                  />
                </Flex>
              </Flex>
            )}
            <Image
              src={activeCurrency?.icon}
              alt="eth"
              cursor="pointer"
              height="24px"
              width="24px"
            />
          </Flex>
        </Flex>
      </Flex>
      {isCurrency1Open && (
        <OutsideClickHandler
          isOpen={isCurrency1Open}
          onClose={() => {
            setIsCurrency1Open(false);
          }}
        >
          <FiatCurrencyDropdown
            currencies={currencies}
            activeCurrency={activeCurrency}
            onClick={(currency: any) => {
              setActiveCurrency(currency);
              setIsCurrency1Open(false);
            }}
          />
        </OutsideClickHandler>
      )}
    </Box>
  );
};

export default BuySellCard;
