import {
  Box,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import React, { useState } from "react";
import CustomText from "../../components/text/CustomText";
import TableSearchInput from "../../components/form/TableSearchInput";
import PrimaryButton from "../../components/button/PrimaryButton";
import { IconCalendar } from "@tabler/icons-react";
import EmptyView from "./EmptyView";
import HistoryTable from "./HistoryTable";
import CustomCalendar from "../../components/form/CustomCalendar";
import {
  useExportTransactionsInCSV,
  useGetTransactions,
} from "../../utils/api/dashboard.api";
import SwapTable from "./SwapTable";
import RaffleTable from "./RaffleTable";
import PaymentTable from "./PaymentTable";
import CustomButton from "../../components/button/CustomButton";
import AllTable from "./AllTable";
import ReferralTable from "./ReferralTable";
const TABS = [
  "All",
  "Swap",
  "Trading History",
  "Raffle",
  "Referral",
  "Subscription Payments",
];

function TransactionHistory() {
  const [tabIndex, setTabIndex] = useState(0);
  const [currentDate, setCurrentDate] = useState<any>();

  const [date, setDate] = useState<any>(null);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [limit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [selectedTab, setSelectedTab] = useState(TABS[0].toUpperCase()); // Initialize with the first tab name
  const [search, setSearch] = useState("");
  const [sortingKey, setSortingKey] = useState("");
  const [sortingOrder, setSortingOrder] = useState("");
  const { data: recentTransactions, isLoading: transactionLoading }: any =
    useGetTransactions(
      limit,
      offset,
      search,
      selectedTab,
      sortingKey,
      sortingOrder,
      0,
      date
    );
  const exportTransactions = useExportTransactionsInCSV();
  const handleExportCSV = async () => {
    try {
      await exportTransactions.mutateAsync({
        search,
        transactionType: selectedTab,
        sortingKey,
        sortingOrder,
        time: 0,
        date,
      }); // Call the export function
    } catch (error) {
      console.error("Error exporting CSV:", error);
    }
  };

  const formatedDate = () => {
    const dateObj = new Date(date);
    return dateObj.toLocaleDateString();
  };

  const handleTabChange = (index: any) => {
    setTabIndex(index);
    if (tabIndex === 3) {
      setSelectedTab("SUBSCRIPTION FEE PAID");
    } else if (tabIndex === 1) {
      setSelectedTab("DEPOSIT,WITHDRAW");
    } else {
      setSelectedTab(TABS[index]?.toUpperCase()); // Update selectedTab with the name of the clicked tab
    }
  };

  return (
    <Box
      p={{ base: "16px", lg: "24px" }}
      borderRadius={{ base: "16px", lg: "24px" }}
      bg="extsy.neutral1000"
      boxShadow="0px 3px 4px 0px rgba(0, 0, 0, 0.03)"
      minH="calc(100vh - 120px)"
    >
      <Flex
        align={{ lg: "center" }}
        justify="space-between"
        pb="20px"
        flexDir={{
          base: "column",
          lg: "row",
        }}
        gap="16px"
      >
        <CustomText
          text="Transaction History"
          size={{ base: "18px", lg: "24px" }}
          lh={{ lg: "34px" }}
          family="Metropolis"
          weight={{ base: "600", lg: "700" }}
          color="extsy.baseWhite"
        />
        <Flex
          w={{ lg: "80%" }}
          justify="end"
          flexDir={{
            base: "column",
            sm: "row",
          }}
          gap="10px"
          align={{ sm: "center" }}
        >
          <TableSearchInput
            placeholder="Search Transaction"
            minW={{
              base: "140px",
              lg: "250px",
            }}
            value={search}
            onChange={(e: any) => setSearch(e.target.value)}
          />
          <Flex gap="10px" align="center">
            <Box pos="relative">
              <Flex
                cursor="pointer"
                gap="16px"
                align="center"
                borderRadius="8px"
                bg="extsy.neutral900"
                boxShadow="0px 2.667px 26.667px 0px rgba(0, 0, 0, 0.15)"
                height="38px"
                ps="24px"
                pe="10px"
                onClick={() => setIsCalendarOpen(!isCalendarOpen)}
              >
                <CustomText
                  cursor
                  text={currentDate ? formatedDate() : "Date"}
                  size="12px"
                  color="extsy.baseWhite"
                />
                <IconCalendar size="16px" color="#fafafa" />
              </Flex>
              <CustomCalendar
                isCalendarOpen={isCalendarOpen}
                value={currentDate}
                onChange={(val: any) => {
                  const selectedDate = new Date(val);
                  // Adjust date to UTC midnight
                  const utcDate = new Date(
                    Date.UTC(
                      selectedDate.getFullYear(),
                      selectedDate.getMonth(),
                      selectedDate.getDate()
                    )
                  );
                  setCurrentDate(utcDate.toISOString());
                  setDate(utcDate.toISOString());
                  setIsCalendarOpen(false);
                }}
              />
            </Box>
            <CustomButton
              title="Export CSV"
              size="14px"
              color="extsy.baseWhite"
              bg="extsy.neutral700"
              height="38px"
              maxW="134px"
              onClick={handleExportCSV} // Add the click handler
              isLoading={exportTransactions?.isPending} // Optional: Show loading state
              isDisabled={
                !recentTransactions?.data?.length ||
                exportTransactions?.isPending
              }
            />
            <PrimaryButton
              title="Export PDF"
              size="14px"
              color="extsy.bg"
              height="38px"
              maxW="134px"
              onClick={handleExportCSV} // Add the click handler
              isLoading={exportTransactions?.isPending} // Optional: Show loading state
              isDisabled={
                !recentTransactions?.data?.length ||
                exportTransactions?.isPending
              }
            />
          </Flex>
        </Flex>
      </Flex>
      <Tabs onChange={(index) => setTabIndex(index)} defaultIndex={0}>
        <TabList
          borderBottom="1px solid"
          borderColor="extsy.neutral900"
          overflowX={{ base: "auto", sm: "inherit" }}
        >
          {TABS?.map((tab, index) => (
            <Tab
              key={index}
              px={{ base: "16px", lg: "32px" }}
              pt="10px"
              pb="14px"
              color="extsy.white"
              onClick={() => handleTabChange(index)} // Call handleTabChange on click
            >
              <CustomText
                text={tab}
                color={
                  index === tabIndex ? "extsy.baseWhite" : "extsy.neutral300"
                }
                cursor="pointer"
                wrap="nowrap"
              />
            </Tab>
          ))}
        </TabList>
        <TabPanels>
          <TabPanel px="0px" pt="24px">
            {!transactionLoading && !recentTransactions?.data?.length ? (
              <EmptyView tagline="No Transaction History Available" />
            ) : (
              <AllTable
                transactions={recentTransactions}
                isLoading={transactionLoading}
                limit={limit}
                offset={offset}
                setOffset={setOffset}
                setSortingKey={setSortingKey}
                setSortingOrder={setSortingOrder}
                sortingOrder={sortingOrder}
              />
            )}
          </TabPanel>
          <TabPanel px="0px" pt="24px">
            {!transactionLoading && !recentTransactions?.data?.length ? (
              <EmptyView tagline="No Transaction History Available" />
            ) : (
              <SwapTable
                transactions={recentTransactions}
                isLoading={transactionLoading}
                limit={limit}
                offset={offset}
                setOffset={setOffset}
                setSortingKey={setSortingKey}
                setSortingOrder={setSortingOrder}
                sortingOrder={sortingOrder}
              />
            )}
          </TabPanel>
          <TabPanel px="0px" pt="24px">
            {!transactionLoading && !recentTransactions?.data?.length ? (
              <EmptyView tagline="No Transaction History Available" />
            ) : (
              <HistoryTable
                transactions={recentTransactions}
                isLoading={transactionLoading}
                limit={limit}
                offset={offset}
                setOffset={setOffset}
                setSortingKey={setSortingKey}
                setSortingOrder={setSortingOrder}
                sortingOrder={sortingOrder}
              />
            )}
          </TabPanel>
          <TabPanel px="0px" pt="24px">
            {!transactionLoading && !recentTransactions?.data?.length ? (
              <EmptyView tagline="No Transaction History Available" />
            ) : (
              <RaffleTable
                transactions={recentTransactions}
                isLoading={transactionLoading}
                limit={limit}
                offset={offset}
                setOffset={setOffset}
                setSortingKey={setSortingKey}
                setSortingOrder={setSortingOrder}
                sortingOrder={sortingOrder}
              />
            )}
          </TabPanel>
          <TabPanel px="0px" pt="24px">
            {!transactionLoading && !recentTransactions?.data?.length ? (
              <EmptyView tagline="No Transaction History Available" />
            ) : (
              <ReferralTable
                transactions={recentTransactions}
                isLoading={transactionLoading}
                limit={limit}
                offset={offset}
                setOffset={setOffset}
                setSortingKey={setSortingKey}
                setSortingOrder={setSortingOrder}
                sortingOrder={sortingOrder}
              />
            )}
          </TabPanel>
          <TabPanel px="0px" pt="24px">
            {!transactionLoading && !recentTransactions?.data?.length ? (
              <EmptyView tagline="No Transaction History Available" />
            ) : (
              <PaymentTable
                transactions={recentTransactions}
                isLoading={transactionLoading}
                limit={limit}
                offset={offset}
                setOffset={setOffset}
                setSortingKey={setSortingKey}
                setSortingOrder={setSortingOrder}
                sortingOrder={sortingOrder}
              />
            )}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}

export default TransactionHistory;
