import { Text, Flex } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import CustomText from "../../components/text/CustomText";
import PrimaryButton from "../../components/button/PrimaryButton";

import CustomVerificationInput from "../../components/form/customVerificationInput";
import Countdown from "react-countdown";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  useForgotPassword,
  useForgotPasswordVerifyEmail,
} from "../../utils/api/auth.api";
import { useCustomAlert } from "../../utils/api/alert";

function VerifyEmail({ onNext }: { onNext: (payload: any) => void }) {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");

  const forgotPassword = useForgotPassword();
  const verifyForgotPassword = useForgotPasswordVerifyEmail();
  const alert = useCustomAlert();

  const [remainingTime, setRemainingTime] = useState(Date.now() + 120 * 1000);
  const [otp, setOtp] = useState("");

  const handleForgotPassword = () => {
    if (!otp) {
      alert?.mutateAsync({
        message: "Please enter OTP",
        status: "danger",
      });
      return;
    }
    if (otp.length < 6) {
      alert?.mutateAsync({
        message: "Please enter valid OTP",
        status: "danger",
      });
      return;
    }
    if (!email) {
      alert?.mutateAsync({
        message: "Email not found",
        status: "danger",
      });
      return;
    }
    verifyForgotPassword
      ?.mutateAsync({ email, otp })
      .then((result) => {
        alert?.mutateAsync({
          message: result?.message,
          status: "success",
        });
        onNext(result?.token?.access_token);
      })
      .catch((error) => {
        alert?.mutateAsync({
          message:
            error?.exception && Array.isArray(error.exception)
              ? error.exception[0]
              : error?.message,
          status: "danger",
        });
      });
  };

  const handleResendOTP = () => {
    if (forgotPassword?.isPending) return;
    if (!email) {
      alert?.mutate({
        message: "Email not found",
        status: "danger",
      });
      return;
    }
    forgotPassword
      ?.mutateAsync({ email })
      .then((result) => {
        alert?.mutate({
          message: result?.message,
          status: "success",
        });
        setRemainingTime(Date.now() + 120 * 1000);
      })
      .catch((error) => {
        alert?.mutate({
          message:
            error?.exception && Array.isArray(error.exception)
              ? error.exception[0]
              : error?.message,
          status: "danger",
        });
      });
  };

  const renderer = ({ minutes, seconds, completed }: any) => {
    return completed ? (
      <CustomText
        text={t("Resend OTP")}
        align="center"
        color="extsy.baseWhite"
        cursor="pointer"
        onClick={handleResendOTP}
      />
    ) : (
      <Flex flexDir="column" gap="16px">
        <CustomText
          text={t("Time remaining")}
          align="center"
          color="extsy.baseWhite"
        />
        <Flex justify="center" align="center" gap="5px">
          <Flex
            align="center"
            justify="center"
            bg="extsy.neutral1000"
            borderRadius="8px"
            px="10px"
            h="40px"
            color="extsy.primary"
            fontFamily={"Metropolis"}
          >
            {"0" + minutes}
          </Flex>
          <Text
            align="center"
            color="extsy.primary"
            fontSize="26px"
            fontWeight="500"
          >
            :
          </Text>
          <Flex
            align="center"
            justify="center"
            bg="extsy.neutral1000"
            borderRadius="8px"
            px="10px"
            h="40px"
            color="extsy.primary"
            fontFamily={"Metropolis"}
          >
            {seconds < 10 ? "0" + seconds : seconds}
          </Flex>
        </Flex>
      </Flex>
    );
  };

  useEffect(() => {
    if (!email) {
      navigate("/auth/forgot-password");
    }
  }, [email, navigate]);

  return (
    <>
      <form
        onSubmit={(e: any) => {
          e.preventDefault();
          handleForgotPassword();
        }}
      >
        <Flex flexDir="column" gap="16px">
          <Flex w="100%" flexDir={"column"} gap={"64px"}>
            <Flex align="center" flexDir="column" gap="32px">
              <CustomText
                text={t("Enter Verification Code")}
                family="Metropolis"
                size="24px"
                lh="28px"
                weight="700"
                align="center"
                color="extsy.baseWhite"
              />
              <CustomText
                text={
                  <Text color="extsy.neutral300">
                    {t("Enter the 6 digits code we have sent you on")}{" "}
                    <Text as="span" color="extsy.baseWhite">
                      {email}
                    </Text>
                  </Text>
                }
                align="center"
                color="extsy.neutral300"
              />
            </Flex>
            <Flex flexDir="column" align="center" justify="center" gap="40px">
              <CustomVerificationInput
                placeholder={0}
                value={otp}
                onChange={setOtp}
              />
              <Countdown
                date={remainingTime}
                renderer={renderer}
                key={Date.now()}
              />
            </Flex>
            <Flex flexDir="column" gap="24px" align="center">
              <PrimaryButton
                title={t("Verify")}
                type="submit"
                isLoading={verifyForgotPassword?.isPending}
              />
            </Flex>
          </Flex>
        </Flex>
      </form>
    </>
  );
}

export default VerifyEmail;
