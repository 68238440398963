import React from "react";

function CopyIcon({ onClick }: any) {
  return (
    <svg
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="23"
      viewBox="0 0 22 23"
      fill="none"
      style={{ cursor: "pointer" }}
    >
      <path
        d="M14.5379 7.98992V6.23519C14.5379 5.76981 14.353 5.32349 14.0239 4.99442C13.6948 4.66534 13.2485 4.48047 12.7831 4.48047H5.76425C5.29886 4.48047 4.85254 4.66534 4.52347 4.99442C4.19439 5.32349 4.00952 5.76981 4.00952 6.23519V13.2541C4.00952 13.7195 4.19439 14.1658 4.52347 14.4949C4.85254 14.8239 5.29886 15.0088 5.76425 15.0088H7.51897M7.51897 9.74464C7.51897 9.27926 7.70384 8.83294 8.03292 8.50387C8.36199 8.17479 8.80831 7.98992 9.2737 7.98992H16.2926C16.758 7.98992 17.2043 8.17479 17.5334 8.50387C17.8624 8.83294 18.0473 9.27926 18.0473 9.74464V16.7635C18.0473 17.2289 17.8624 17.6752 17.5334 18.0043C17.2043 18.3334 16.758 18.5183 16.2926 18.5183H9.2737C8.80831 18.5183 8.36199 18.3334 8.03292 18.0043C7.70384 17.6752 7.51897 17.2289 7.51897 16.7635V9.74464Z"
        stroke="url(#paint0_linear_2424_178036)"
        stroke-width="1.31604"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2424_178036"
          x1="18.0355"
          y1="9.5315"
          x2="8.60786"
          y2="3.86575"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F0C2DB" />
          <stop offset="0.25" stop-color="#F1C8D9" />
          <stop offset="0.6" stop-color="#EDE3E1" />
          <stop offset="1" stop-color="white" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default CopyIcon;
