import {
  Box,
  Flex,
  Image,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import CustomText from "../../components/text/CustomText";
import SocialLoginButton from "../../components/button/SocialLoginButton";
import GoogleIcon from "../../assets/images/google-ico.svg";
import AppleIcon from "../../assets/images/apple-ico.svg";
import CustomInput from "../../components/form/CustomInput";
import { LoginPayload } from "../../utils/types/types";
import GradientText from "../../components/text/GradientText";
import PrimaryButton from "../../components/button/PrimaryButton";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useLogin, useLoginWithGoogle } from "../../utils/api/auth.api";
import { useQueryClient } from "@tanstack/react-query";
import CustomModal from "../../components/modal/customModal";
import IdentityImg from "../../assets/images/identityImg.svg";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { IconEye, IconEyeOff } from "@tabler/icons-react";
import { useCustomAlert } from "../../utils/api/alert";
import { useGeeTest } from "react-geetest-v4";
import { WHITE_LISTED_EMAILS } from "../../utils/constants/constants";

function Login() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const auth = getAuth();
  const googleProvider = new GoogleAuthProvider();

  const queryClient = useQueryClient();
  const userLogin = useLogin();
  const googleLogin = useLoginWithGoogle();

  const alert = useCustomAlert();

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm<LoginPayload>();

  const {
    isOpen: isKycOpen,
    onOpen: openKyc,
    onClose: closeKyc,
  } = useDisclosure();
  const [isLessThan768] = useMediaQuery("(max-height: 799px)");
  const { captcha, state } = useGeeTest("4cf4ef62613aca348306dce9ba7f62c7", {
    product: "bind",
    protocol: "https://",
    // containerId: 'geetest-captcha',
  });

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const handleUserLogin = (payload: any) => {
    let formData;
    if (WHITE_LISTED_EMAILS.includes(payload?.email)) {
      formData = {
        ...payload,
        lot_number: "",
        captcha_output: "",
        pass_token: "",
        gen_time: "",
        captcha_id: "",
        sign_token: "",
      };
    } else {
      const captchaData: any = captcha ? captcha.getValidate() : {};
      if (!captchaData) {
        return;
      }
      formData = {
        ...payload,
        lot_number: captchaData.lot_number,
        captcha_output: captchaData.captcha_output,
        pass_token: captchaData.pass_token,
        gen_time: captchaData.gen_time,
        captcha_id: captchaData.captcha_id,
        sign_token: captchaData.sign_token,
      };
    }
    userLogin
      .mutateAsync(formData)
      .then((result) => {
        if (result?.user?.is_2fa_enabled && result?.user?.is_2fa_verified) {
          navigate(`/auth/security-code?email=${payload.email}`);
        } else if (!result?.user?.kyc_level || result?.user?.kyc_level === 0) {
          openKyc();
        } else {
          localStorage.setItem("token", result?.token?.access_token);
          queryClient.invalidateQueries({ queryKey: ["token"] });
          queryClient.invalidateQueries({ queryKey: ["getLoggedInUser"] });
          navigate("/");
        }
      })
      .catch((error) => {
        alert?.mutate({
          message:
            error?.exception && Array.isArray(error.exception)
              ? error.exception[0]
              : error?.message,
          status: "danger",
        });
      });
  };

  const handleGoogleSignIn = () => {
    googleProvider?.setCustomParameters({
      prompt: "select_account", // Forces Google to prompt user to select an account
    });
    signInWithPopup(auth, googleProvider)
      .then(async (result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        // const credential = GoogleAuthProvider.credentialFromResult(result);
        // const token = credential?.accessToken;
        // The signed-in user info.

        const user: any = result.user;

        await googleLogin
          ?.mutateAsync({ token: user?.accessToken })
          .then((result) => {
            if (result?.user?.is_2fa_enabled && result?.user?.is_2fa_verified) {
              navigate(`/auth/security-code?email=${result?.user?.email}`);
            } else if (
              !result?.user?.kyc_level ||
              result?.user?.kyc_level === 0
            ) {
              openKyc();
            } else {
              localStorage.setItem("token", result?.token?.access_token);
              queryClient.invalidateQueries({ queryKey: ["token"] });
              queryClient.invalidateQueries({ queryKey: ["getLoggedInUser"] });
              navigate("/");
            }
          })
          .catch((error) => {
            alert?.mutate({
              message:
                error?.exception && Array.isArray(error.exception)
                  ? error.exception[0]
                  : error?.message,
              status: "danger",
            });
          });
      })
      .catch((error) => {
        if (
          error?.message?.includes("closed") ||
          error?.message?.includes("cancelled")
        ) {
          return;
        }
        alert?.mutate({
          message:
            error?.exception && Array.isArray(error.exception)
              ? error.exception[0]
              : error?.message,
          status: "danger",
        });
      });
  };

  const storeToken = (from?: string) => {
    localStorage.setItem(
      "token",
      userLogin?.data?.token?.access_token ||
        googleLogin?.data?.token?.access_token
    );
    queryClient.invalidateQueries({ queryKey: ["token"] });
    queryClient.invalidateQueries({ queryKey: ["getLoggedInUser"] });
    if (from === "kyc") {
      navigate("/settings");
      closeKyc();
    } else {
      navigate("/");
    }
    reset();
  };

  const onSubmit = handleSubmit((data) => {
    handleUserLogin(data);
  });

  useEffect(() => {
    if (state === "success") {
      onSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return (
    <Flex flexDir="column" gap="16px" mx="auto">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (WHITE_LISTED_EMAILS.includes(getValues("email"))) {
            onSubmit();
            return;
          }
          if (state !== "success") {
            captcha?.showCaptcha();
            onSubmit();
          } else onSubmit();
        }}
      >
        <Flex
          px={{ base: "16px", md: "40px" }}
          py={isLessThan768 ? "24px" : "56px"}
          mx="auto"
          bg="rgba(255, 255, 255, 0.03)"
          borderRadius={{ base: "16px", md: "24px" }}
          maxW="480px"
          w="100%"
          flexDir="column"
          justify="space-between"
          gap="24px"
          minH={isLessThan768 ? "424px" : "742px"}
        >
          <Box>
            <Flex align="center" flexDir="column" gap="32px">
              <CustomText
                text={t("signin")}
                family="Metropolis"
                size="24px"
                lh="32px"
                weight="700"
                align="center"
                color="extsy.baseWhite"
              />
              <CustomText
                text={t("signinDescription")}
                align="center"
                color="extsy.neutral300"
              />
            </Flex>
            <Flex
              align="center"
              flexDir="column"
              mt={{ base: "24px", lg: "40px" }}
              gap="16px"
            >
              <CustomText
                text={t("continueWith")}
                color="extsy.neutral500"
                size="14px"
              />
              <Flex align="center" gap="16px">
                <SocialLoginButton
                  icon={GoogleIcon}
                  title="Google"
                  isLoading={googleLogin.isPending}
                  onClick={() => handleGoogleSignIn()}
                />
                <SocialLoginButton icon={AppleIcon} title="Apple" />
              </Flex>
              <Flex align="center" justify="center" gap="16px" w="100%">
                <Box bg="extsy.neutral900" height="1px" maxW="170px" w="100%" />
                <Box>
                  <CustomText
                    text={t("or")}
                    size="14px"
                    lh="14px"
                    color="extsy.neutral500"
                    wrap="nowrap"
                    width="120px"
                  />
                </Box>
                <Box bg="extsy.neutral900" height="1px" maxW="170px" w="100%" />
              </Flex>
            </Flex>
          </Box>

          <Box>
            <Flex flexDir="column" gap="24px">
              <Flex flexDir="column">
                <CustomText
                  text={t("email")}
                  color="extsy.neutral500"
                  size="12px"
                />
                <CustomInput
                  placeholder={t("enterYourEmail")}
                  type="email"
                  register={register}
                  name="email"
                  validation={{
                    required: "Email is required",
                    pattern: {
                      value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                      message: "Email must be valid",
                    },
                  }}
                  invalid={errors.email}
                  errorMsg={errors.email && errors.email.message}
                />
              </Flex>
              <Flex flexDir="column">
                <CustomText
                  text={t("password")}
                  color="extsy.neutral500"
                  size="12px"
                />
                <CustomInput
                  placeholder="**************"
                  register={register}
                  name="password"
                  validation={{
                    required: true,
                  }}
                  invalid={errors.password}
                  errorMsg={errors.password && "Password is required"}
                  type={isPasswordVisible ? "text" : "password"}
                  rightIco={
                    isPasswordVisible ? (
                      <IconEyeOff
                        onClick={() => {
                          setIsPasswordVisible(!isPasswordVisible);
                        }}
                        color="#fff"
                        cursor="pointer"
                      />
                    ) : (
                      <IconEye
                        onClick={() => {
                          setIsPasswordVisible(!isPasswordVisible);
                        }}
                        color="#fff"
                        cursor="pointer"
                      />
                    )
                  }
                />
              </Flex>
            </Flex>
            <GradientText
              text={t("forgotPassword")}
              cursor="pointer"
              bg="extsy.g1"
              lh="19px"
              mt="12px"
              align="right"
              onClick={() => {
                navigate("/auth/forgot-password");
              }}
            />
          </Box>
          <Flex flexDir="column" gap="24px" align="center">
            <PrimaryButton
              title={t("signIn")}
              type="submit"
              isLoading={userLogin.isPending}
            />
            <Flex align="center" gap="4px">
              <CustomText
                text={t("notRegistered")}
                color="extsy.neutral300"
                lh="19px"
              />
              <GradientText
                text={t("signUp")}
                weight="600"
                bg="extsy.g1"
                lh={{ md: "22px" }}
                cursor="pointer"
                onClick={() => {
                  navigate("/auth/signup");
                }}
              />
            </Flex>
          </Flex>
        </Flex>
      </form>

      <CustomModal
        isOpen={isKycOpen}
        onClose={() => storeToken("")}
        onSubmit={() => storeToken("kyc")}
        height={"450px"}
      >
        <Flex
          flexDir="column"
          align="center"
          gap={"32px"}
          minH="100%"
          px="24px"
          py="32px"
        >
          <Image
            src={IdentityImg}
            alt="checkicon"
            minH="104px"
            minW="67.69px"
          />
          <CustomText
            text={t("verifyYourIdentity")}
            color="extsy.baseWhite"
            lh="19.2px"
            size="24px"
            family="Metropolis"
            pb="8px"
          />
          <CustomText
            text={t("verificationDescription")}
            color="extsy.neutral300"
            align="center"
            lh="19.2px"
          />
        </Flex>
      </CustomModal>
    </Flex>
  );
}

export default Login;
