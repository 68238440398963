import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useCustomAlert = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ message, status }: any) => {
      // Store the alert data
      queryClient.setQueryData(["alert"], { message, status });

      // Clear the alert data after 3 seconds
      setTimeout(() => {
        queryClient.setQueryData(["alert"], null);
      }, 5000);
    },
  });
};
