import { Textarea } from "@chakra-ui/react";
import React from "react";

function CustomArea({
  value,
  onChange,
  placeholder,
  bg,
  radius,
  rows,
  maxW,
  color,
}: any) {
  return (
    <Textarea
      placeholder={placeholder}
      color={color || "extsy.neutral300"}
      p="19.5px 16x"
      value={value}
      onChange={onChange}
      maxW={maxW || "100%"}
      borderRadius={radius || "16px"}
      bg={bg || "extsy.neutral900"}
      rows={rows || 4}
      border="none"
      _placeholder={{
        color: "extsy.neutral300",
      }}
      _hover={{
        border: "none",
      }}
      _focus={{
        border: "none",
      }}
      _focusVisible={{
        border: "none",
      }}
    />
  );
}

export default CustomArea;
