import {
  Box,
  Circle,
  Flex,
  Image,
  Input,
  Skeleton,
  SkeletonText,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import CustomText from "../../components/text/CustomText";
import PrimaryButton from "../../components/button/PrimaryButton";
import { IconPhotoPlus, IconX } from "@tabler/icons-react";
import {
  useGetLoggedInUser,
  useUpdateRaffleName,
} from "../../utils/api/auth.api";
import { useUploadMedia } from "../../utils/api/media.api";
import { useCustomAlert } from "../../utils/api/alert";
import CustomButton from "../../components/button/CustomButton";

function RaffleAffliate({ onClose }: any) {
  const profileInputRef = useRef<HTMLInputElement>(null);

  const alert = useCustomAlert();

  const { data: loggedInUserData, isLoading } = useGetLoggedInUser();
  const uploadMediaMutation = useUploadMedia();
  const updateRaffleNameMutation = useUpdateRaffleName();

  const [raffleName, setRaffleName] = useState();
  const [raffleUrl, setRaffleUrl] = useState<any>();
  const [initialRaffleName, setInitialRaffleName] = useState(raffleName);
  const [initialRaffleUrl, setInitialRaffleUrl] = useState(raffleUrl);
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    const form = new FormData();
    form.append("file", file);

    try {
      const res = await uploadMediaMutation.mutateAsync(form);
      setRaffleUrl(res);
    } catch (error) {
      console.error("Upload error:", error);
    } finally {
      // Reset the file input to allow re-selection of the same file
      if (profileInputRef.current) {
        profileInputRef.current.value = "";
      }
    }
  };

  useEffect(() => {
    if (loggedInUserData) {
      setRaffleName(loggedInUserData?.raffle_name);
      setRaffleUrl(loggedInUserData?.raffle_avatar_url);
      setInitialRaffleName(loggedInUserData?.raffle_name);
      setInitialRaffleUrl(loggedInUserData?.raffle_avatar_url);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedInUserData]);

  useEffect(() => {
    const hasChanges =
      raffleName !== initialRaffleName || raffleUrl !== initialRaffleUrl;
    setIsSaveEnabled(hasChanges);
  }, [raffleName, raffleUrl, initialRaffleName, initialRaffleUrl]);

  return (
    <>
      <Flex gap={{ base: "16px", lg: "29px" }} flexDir="column">
        <Flex flexDir="column" gap="16px" zIndex="100">
          <CustomText text="Name" family="Metropolis" color="extsy.baseWhite" />
          {isLoading ? (
            <SkeletonText w="120px" noOfLines={1} />
          ) : (
            <Input
              fontSize="16px"
              fontWeight="400"
              color="extsy.baseWhite"
              fontFamily={"Metropolis"}
              h="56px"
              bg="extsy.neutral1000"
              borderRadius="8px"
              px="22px"
              border="none"
              onChange={(e: any) => {
                if (e.target.value.length <= 30) {
                  setRaffleName(e.target.value);
                }
              }}
              value={raffleName}
              _hover={{
                border: "none",
              }}
              _focus={{
                border: "none",
              }}
              _focusVisible={{ border: "none" }}
            />
          )}
          <CustomText
            text="Avatar"
            family="Metropolis"
            color="extsy.baseWhite"
            mt="8px"
          />
          <Flex
            gap={{ base: "16px", lg: "32px" }}
            align="center"
            flexDir={{ base: "column", lg: "row" }}
          >
            {uploadMediaMutation?.isPending ? (
              <Skeleton
                height="156px"
                width="156px"
                borderRadius="md"
                startColor="extsy.neutral800"
                endColor="extsy.neutral600"
              />
            ) : (
              <>
                {raffleUrl && (
                  <Box pos="relative" minH="156px" minW="156px">
                    <Image
                      src={raffleUrl}
                      alt="profile"
                      boxSize="156px"
                      draggable={false}
                    />
                    <Circle
                      bg="extsy.primary500"
                      border="5px solid"
                      borderColor="extsy.neutral900"
                      pos="absolute"
                      size="34px"
                      top="0"
                      right="0"
                      mt="-13px"
                      mr="-13px"
                      cursor={"pointer"}
                      onClick={() => setRaffleUrl("")}
                    >
                      <IconX size="14px" color="#FAFAFA" />
                    </Circle>
                  </Box>
                )}
              </>
            )}
            <Flex
              flexDir="column"
              gap="16px"
              align="center"
              justify="center"
              minH="156px"
              p="32px"
              borderRadius="12px"
              bg="rgba(11, 10, 10, 0.60)"
              border="1px dashed"
              borderColor="extsy.neutral700"
              w="100%"
              cursor="pointer"
              onClick={() => profileInputRef.current?.click()}
            >
              <IconPhotoPlus size="32px" color="#BAB8B8" />
              <CustomText text="Browse an image" size="14px" cursor />
            </Flex>
          </Flex>
        </Flex>
        <Flex align="center" gap="16px">
          <CustomButton
            title="Close"
            color="#fff"
            bg="rgba(255, 255, 255, 0.10)"
            onClick={onClose}
          />
          <PrimaryButton
            title="Save Changes"
            size="14px"
            onClick={async () => {
              try {
                if (!raffleName) {
                  alert?.mutate({
                    message: "Please enter a raffle name",
                    status: "danger",
                  });
                  return;
                }
                const formData = {
                  raffle_name: raffleName,
                  raffle_avatar_url: raffleUrl,
                };
                await updateRaffleNameMutation
                  .mutateAsync(formData)
                  .then((result) => {})
                  .catch((err) => {
                    const errorMessage = Array.isArray(err?.exception)
                      ? err.exception[0]
                      : err.message;
                    alert?.mutate({
                      message: errorMessage,
                      status: "danger",
                    });
                  });
              } catch (error: any) {
                console.log(error);
                const errorMessage = Array.isArray(error?.exception)
                  ? error?.exception[0]
                  : error?.message;
                alert?.mutate({
                  message: errorMessage,
                  status: "danger",
                });
              }
            }}
            isLoading={updateRaffleNameMutation.isPending}
            isDisabled={updateRaffleNameMutation.isPending || !isSaveEnabled}
          />
        </Flex>
      </Flex>
      <input
        accept="image/*"
        type="file"
        hidden
        ref={profileInputRef}
        onChange={handleFileChange} // Use the new handler
      />
    </>
  );
}

export default RaffleAffliate;
