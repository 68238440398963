import { Flex, Image, Skeleton, SkeletonText } from "@chakra-ui/react";
import React from "react";
import CustomText from "../../components/text/CustomText";
import Countdown from "react-countdown";
import ColonIcon from "../../assets/images/colon-icon.svg";
import FlipBg from "../../assets/images/timer-flip-bg.svg";
import moment from "moment";
import GradientText from "../../components/text/GradientText";

function NextDraw({ isLoading, round }: any) {
  const endDate = moment(round?.end_time);
  const now = moment();

  const isAfterEndTime = now.isAfter(endDate);

  return (
    <Flex
      p={{ base: "20px 23px", lg: "20px 32px 20px 32px" }}
      borderRadius={{ base: "11px", lg: "16px" }}
      bg="extsy.bg"
      flexDir="column"
      align="center"
      justify="center"
      gap={isAfterEndTime ? "5px" : { base: "16px", lg: "24px" }}
      width="100%"
      zIndex="100"
      overflow="hidden"
      pos="relative"
    >
      {isLoading ? (
        <SkeletonText noOfLines={1} w="120px" mx="auto" />
      ) : (
        <CustomText
          text={
            isAfterEndTime ? "Waiting for next raffle" : "Next Draw Coming In"
          }
          size={{ base: "14px", lg: "20px" }}
          lh={{ lg: "30px" }}
          align="center"
          family="Metropolis"
          weight="600"
          color="extsy.neutral50"
        />
      )}
      {isLoading ? (
        <Flex gap="8px" justify="center" align="center">
          {[1, 2, 3, 4].map((_, index) => (
            <Flex flexDir="column" gap="8px" align="center">
              <Skeleton
                key={index}
                height={{ base: "45px", md: "53px", lg: "72px" }}
                width={{ base: "51px", md: "61px", lg: "83px" }}
                borderRadius="8px"
              />
              <SkeletonText noOfLines={1} w="40px" h="20px" />
            </Flex>
          ))}
        </Flex>
      ) : isAfterEndTime ? (
        <GradientText
          text="Starting Soon"
          size={{ base: "26px", md: "40px" }}
          lh={{ base: "30px", md: "50px" }}
          bg="extsy.g1"
          family="Metropolis"
          weight="700"
          transform="uppercase"
          align="center"
        />
      ) : (
        <Countdown
          date={endDate?.toString()}
          key={endDate.toString()}
          renderer={flipTimer}
        />
      )}
    </Flex>
  );
}

const flipTimer = ({ days, hours, minutes, seconds, completed }: any) => {
  if (completed) {
    return (
      <GradientText
        text="Starting Soon"
        size={{ base: "26px", md: "40px" }}
        lh={{ base: "30px", md: "50px" }}
        bg="extsy.g1"
        family="Metropolis"
        weight="700"
        transform="uppercase"
        align="center"
      />
    );
  } else {
    return (
      <Flex justify="center" align="center" gap="8px">
        <Flex flexDir="row" align="center" gap="8px">
          <Flex gap="8px" flexDir="column" align="center">
            <Flex
              bgImage={FlipBg}
              bgSize="cover"
              align="center"
              justify="center"
              height={{ base: "45px", md: "53px", lg: "72px" }}
              width={{ base: "51px", md: "61px", lg: "83px" }}
            >
              <CustomText
                text={days < 10 ? `0${days}` : days}
                size={{ base: "36px", md: "52px", lg: "70px" }}
                color="extsy.neutral50"
                family="Share Tech"
              />
            </Flex>
            <CustomText
              text="DAYS"
              size={{ base: "8px", lg: "12px" }}
              weight="600"
              color="extsy.neutral50"
            />
          </Flex>
          <Image src={ColonIcon} alt="colon-icon" mt="-15px" />
        </Flex>
        <Flex flexDir="row" align="center" gap="8px">
          <Flex gap="8px" flexDir="column" align="center">
            <Flex
              bgImage={FlipBg}
              bgSize="cover"
              align="center"
              justify="center"
              height={{ base: "45px", md: "53px", lg: "72px" }}
              width={{ base: "51px", md: "61px", lg: "83px" }}
            >
              <CustomText
                text={hours < 10 ? `0${hours}` : hours}
                size={{ base: "36px", md: "52px", lg: "70px" }}
                color="extsy.neutral50"
                family="Share Tech"
              />
            </Flex>
            <CustomText
              text="HOURS"
              size={{ base: "8px", lg: "12px" }}
              weight="600"
              color="extsy.neutral50"
            />
          </Flex>
          <Image src={ColonIcon} alt="colon-icon" mt="-15px" />
        </Flex>
        <Flex flexDir="row" align="center" gap="8px">
          <Flex gap="8px" flexDir="column" align="center">
            <Flex
              bgImage={FlipBg}
              bgSize="cover"
              align="center"
              justify="center"
              height={{ base: "45px", md: "53px", lg: "72px" }}
              width={{ base: "51px", md: "61px", lg: "83px" }}
            >
              <CustomText
                text={minutes < 10 ? `0${minutes}` : minutes}
                size={{ base: "36px", md: "52px", lg: "70px" }}
                color="extsy.neutral50"
                family="Share Tech"
              />
            </Flex>
            <CustomText
              text="MINUTES"
              size={{ base: "8px", lg: "12px" }}
              weight="600"
              color="extsy.neutral50"
            />
          </Flex>
          <Image src={ColonIcon} alt="colon-icon" mt="-15px" />
        </Flex>
        <Flex gap="8px" flexDir="column" align="center">
          <Flex
            bgImage={FlipBg}
            bgSize="cover"
            align="center"
            justify="center"
            height={{ base: "45px", md: "53px", lg: "72px" }}
            width={{ base: "51px", md: "61px", lg: "83px" }}
          >
            <CustomText
              text={seconds < 10 ? `0${seconds}` : seconds}
              size={{ base: "36px", md: "52px", lg: "70px" }}
              color="extsy.neutral50"
              family="Share Tech"
            />
          </Flex>
          <CustomText
            text="SECONDS"
            size={{ base: "8px", lg: "12px" }}
            weight="600"
            color="extsy.neutral50"
          />
        </Flex>
      </Flex>
    );
  }
};

export default NextDraw;
