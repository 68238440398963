import {
  Box,
  Flex,
  Image,
  SkeletonCircle,
  SkeletonText,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import CustomText from "../../components/text/CustomText";
import {
  IconArrowRight,
  IconArrowsExchange,
  IconChevronDown,
  IconChevronUp,
  IconHistory,
} from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import BuyGreenArrow from "../../assets/images/buy-green-arrow.svg";
import ArrowBack from "../../assets/images/black-left.svg";
import ArrowForward from "../../assets/images/black-right.svg";
import { useGetBalance } from "../../utils/api/trade.api";
import { Balance } from "../../utils/types/types";
import EmptyTrxIco from "../../assets/images/empty-trx-ico.svg";

function Balances({ tab, search }: { tab: string; search: string }) {
  const { t } = useTranslation();

  const [limit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState("");

  const TABLE_HEADER = [
    {
      label: "Coin",
      sortKey: "symbol",
      justify: "start",
      minW: { base: "140px", "1xl": "260px" },
    },
    {
      label: t("amount"),
      sortKey: tab === "spot" ? "balance_spot" : "balance_future",
      justify: "start",
      minW: { base: "190px", "1xl": "260px" },
    },
    {
      label: t("available"),
      sortKey:
        tab === "spot" ? "balance_spot_available" : "balance_future_available",
      justify: "end",
      minW: { base: "100px", "1xl": "260px" },
    },
    {
      label: t("actions"),
      sortKey: null,
      justify: "start",
      minW: "374px",
    },
  ];

  const {
    data: balances,
    isLoading,
    refetch: balanceRefetch,
  } = useGetBalance(limit, offset, search, sortBy, sortOrder);

  const totalBalances = balances?.total || 0; // Total number of transactions
  const totalPages = Math.ceil(totalBalances / limit); // Total number of pages
  const currentPage = Math.floor(offset / limit) + 1; // Current page number

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setOffset((prevOffset) => prevOffset + limit);
    }
  };

  // Handle going to the previous page
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setOffset((prevOffset) => prevOffset - limit);
    }
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      balanceRefetch();
    }, 1000); // 1s debounce time

    return () => {
      clearTimeout(handler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    balanceRefetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset, sortBy, sortOrder]);

  return (
    <Flex flexDir="column" gap="24px">
      <TableContainer>
        <Table border="1px solid" color="extsy.neutral900">
          <Thead bg="extsy.neutral900" height="40px">
            <Tr>
              {TABLE_HEADER.map(({ label, sortKey, justify, minW }, index) => (
                <Th
                  key={index}
                  borderColor="extsy.neutral900"
                  maxW="583px"
                  minW={minW}
                >
                  <Flex align="center" gap="4px" justify={justify}>
                    <CustomText
                      text={label}
                      size="13px"
                      color="extsy.baseWhite"
                      weight="500"
                      ls="-0.13px"
                    />
                    {sortKey && (
                      <Flex flexDir="column">
                        <IconChevronUp
                          cursor="pointer"
                          color={
                            sortBy === sortKey && sortOrder === "ASC"
                              ? "#EFA3D1"
                              : "#fafafa"
                          }
                          size="14px"
                          onClick={() => {
                            setSortBy(sortKey);
                            setSortOrder("ASC");
                          }}
                        />
                        <IconChevronDown
                          color={
                            sortBy === sortKey && sortOrder === "DESC"
                              ? "#EFA3D1"
                              : "#fafafa"
                          }
                          size="14px"
                          onClick={() => {
                            setSortBy(sortKey);
                            setSortOrder("DESC");
                          }}
                          cursor="pointer"
                          style={{
                            marginTop: -7,
                          }}
                        />
                      </Flex>
                    )}
                  </Flex>
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {isLoading
              ? [...Array(5)].map((_, index) => <LoadingRow key={index} />)
              : balances?.data?.map((balance: Balance, index: number) => (
                  <Tr key={index}>
                    <Td
                      height="54px"
                      borderColor="extsy.neutral900"
                      borderRight="1px solid"
                      py="0"
                    >
                      <Flex gap="16px" align="center">
                        <Image
                          src={balance?.logo_url}
                          alt={balance?.symbol_short}
                          boxSize="32px"
                        />
                        <CustomText
                          text={balance.symbol}
                          size="14px"
                          color="extsy.baseWhite"
                        />
                      </Flex>
                    </Td>
                    <Td borderColor="extsy.neutral900" borderRight="1px solid">
                      <Flex align="center" justify="space-between">
                        <CustomText
                          text={
                            tab === "spot"
                              ? balance.balance_spot
                              : balance?.balance_future
                          }
                          size="14px"
                        />
                        <CustomText
                          text={`~$${
                            tab === "spot"
                              ? balance.balance_spot_usd
                              : balance?.balance_future_usd
                          } USD`}
                          size="14px"
                          color="extsy.neutral600"
                        />
                      </Flex>
                    </Td>
                    <Td borderColor="extsy.neutral900" borderRight="1px solid">
                      <Flex align="center" justify="flex-end" gap="4px">
                        <CustomText
                          text={
                            tab === "spot"
                              ? balance.balance_spot_available
                              : balance.balance_future_available
                          }
                          size="14px"
                        />
                        <CustomText text={balance.symbol} size="14px" />
                      </Flex>
                    </Td>
                    <Td borderColor="extsy.neutral900" borderRight="1px solid">
                      <Flex gap="16px" align="center">
                        <Flex cursor="pointer" gap="4px" align="center">
                          <Image
                            src={BuyGreenArrow}
                            alt="buy"
                            height="18px"
                            width="18px"
                          />
                          <CustomText
                            text={t("buy")}
                            size="10px"
                            color="#15B097"
                            cursor="pointer"
                          />
                        </Flex>
                        <Box bg="extsy.neutral900" height="9px" width="1px" />
                        <Flex cursor="pointer" gap="4px" align="center">
                          <IconArrowRight size="18px" color="#FFE9EB" />
                          <CustomText
                            text={t("sell")}
                            size="10px"
                            color="#ED3D4E"
                            cursor="pointer"
                          />
                        </Flex>
                        <Box bg="extsy.neutral900" height="9px" width="1px" />
                        <Flex cursor="pointer" gap="4px" align="center">
                          <IconArrowsExchange size="18px" color="#908B8B" />
                          <CustomText
                            text={"Trade"}
                            size="10px"
                            color="extsy.baseWhite"
                            cursor="pointer"
                          />
                        </Flex>
                        <Box bg="extsy.neutral900" height="9px" width="1px" />
                        <Flex cursor="pointer" gap="4px" align="center">
                          <IconHistory size="18px" color="#908B8B" />
                          <CustomText
                            text={"History"}
                            size="10px"
                            color="extsy.baseWhite"
                            cursor="pointer"
                          />
                        </Flex>
                      </Flex>
                    </Td>
                  </Tr>
                ))}
          </Tbody>
        </Table>
        {!isLoading && !balances?.data?.length && (
          <Flex
            minH="230px"
            align="center"
            justify="center"
            flexDir="column"
            gap="16px"
          >
            <Image src={EmptyTrxIco} alt="empty" />
            <CustomText
              text="No balances found"
              size="14px"
              color="extsy.neutral400"
            />
          </Flex>
        )}
      </TableContainer>
      <Flex
        gap="16px"
        px="20px"
        align="center"
        justify="flex-end"
        display={balances?.data?.length ? "flex" : "none"}
      >
        {/* Showing range of items */}
        <CustomText
          text={`${offset + 1}-${Math.min(offset + limit, totalBalances)} ${t(
            "of"
          )} ${totalBalances}`}
          size="13px"
          weight="500"
          color="extsy.neutral300"
        />

        <Flex gap="8px">
          {/* Previous Page Button */}
          <Image
            src={ArrowBack}
            alt="arrow-back"
            cursor={currentPage > 1 ? "pointer" : "not-allowed"}
            opacity={currentPage > 1 ? 1 : 0.5}
            onClick={handlePrevPage}
          />

          {/* Page Numbers */}
          {[...Array(totalPages)].map((_, index) => (
            <Flex
              key={index}
              flexDir="column"
              align="center"
              justify="center"
              bg={
                currentPage === index + 1 ? "extsy.neutral900" : "transparent"
              }
              borderRadius="6px"
              height="30px"
              width="30px"
              cursor="pointer"
              onClick={() => setOffset(index * limit)}
            >
              <CustomText text={index + 1} size="14px" weight="500" />
            </Flex>
          ))}

          {/* Next Page Button */}
          <Image
            src={ArrowForward}
            alt="arrow-forward"
            cursor={currentPage < totalPages ? "pointer" : "not-allowed"}
            opacity={currentPage < totalPages ? 1 : 0.5}
            onClick={handleNextPage}
          />
        </Flex>
      </Flex>
    </Flex>
  );
}

const LoadingRow = () => {
  return (
    <Tr>
      <Td
        height="54px"
        borderColor="extsy.neutral900"
        borderRight="1px solid"
        py="0"
      >
        <Flex gap="16px" align="center">
          <SkeletonCircle size="32px" />
          <SkeletonText noOfLines={1} w="100px" />
        </Flex>
      </Td>
      <Td borderColor="extsy.neutral900" borderRight="1px solid">
        <Flex align="center" justify="space-between">
          <SkeletonText noOfLines={1} w="60px" />
          <SkeletonText noOfLines={1} w="50px" />
        </Flex>
      </Td>
      <Td borderColor="extsy.neutral900" borderRight="1px solid">
        <Flex align="center" justify="flex-end" gap="4px">
          <SkeletonText noOfLines={1} w="60px" />
          <SkeletonText noOfLines={1} w="50px" />
        </Flex>
      </Td>
      <Td borderColor="extsy.neutral900" borderRight="1px solid">
        <Flex gap="16px" align="center">
          <Flex cursor="pointer" gap="4px" align="center">
            <Image src={BuyGreenArrow} alt="buy" height="18px" width="18px" />
            <SkeletonText noOfLines={1} w="30px" />
          </Flex>
          <Box bg="extsy.neutral900" height="9px" width="1px" />
          <Flex cursor="pointer" gap="4px" align="center">
            <IconArrowRight size="18px" color="#FFE9EB" />
            <SkeletonText noOfLines={1} w="30px" />
          </Flex>
          <Box bg="extsy.neutral900" height="9px" width="1px" />
          <Flex cursor="pointer" gap="4px" align="center">
            <IconArrowsExchange size="18px" color="#908B8B" />
            <SkeletonText noOfLines={1} w="30px" />
          </Flex>
          <Box bg="extsy.neutral900" height="9px" width="1px" />
          <Flex cursor="pointer" gap="4px" align="center">
            <IconHistory size="18px" color="#908B8B" />
            <SkeletonText noOfLines={1} w="30px" />
          </Flex>
        </Flex>
      </Td>
    </Tr>
  );
};

export default Balances;
