import {
  Flex,
  Image,
  SkeletonText,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React, { useState } from "react";
import CustomText from "../text/CustomText";
import { IconCaretDownFilled, IconTrashFilled } from "@tabler/icons-react";
import EmptyTrx from "../../assets/images/empty-trx-ico.svg";
import { useTranslation } from "react-i18next";
import ArrowBack from "../../assets/images/black-left.svg";
import ArrowForward from "../../assets/images/black-right.svg";
import { useCancelOrder, useGetOpenOrders } from "../../utils/api/trade.api";
import moment from "moment";
import { formatNumber } from "../../utils/functions/formatNumber";

function OpenOrders() {
  const { t } = useTranslation();

  const [limit] = useState(5);
  const [offset, setOffset] = useState(0);

  const { data: orders, isLoading } = useGetOpenOrders(limit, offset, true);
  const cancelOrder = useCancelOrder();

  const totalTransactions = orders?.total || 0; // Total number of transactions
  const totalPages = Math.ceil(totalTransactions / limit); // Total number of pages
  const currentPage = Math.floor(offset / limit) + 1; // Current page number

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setOffset((prevOffset) => prevOffset + limit);
    }
  };

  // Handle going to the previous page
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setOffset((prevOffset) => prevOffset - limit);
    }
  };

  return (
    <TableContainer>
      {!isLoading && !orders?.data?.length ? (
        <Flex
          minH="150px"
          flexDir="column"
          gap="16px"
          align="center"
          justify="center"
        >
          <Image src={EmptyTrx} alt="empty-trx" />
          <CustomText
            text="You have no open orders"
            size="16px"
            color="extsy.neutral400"
          />
        </Flex>
      ) : (
        <Table>
          <Thead>
            <Tr>
              <Th border="0" py="16px">
                <CustomText text="Date" color="extsy.neutral400" size="13px" />
              </Th>
              <Th border="0" py="16px">
                <CustomText text="Pair" color="extsy.neutral400" size="13px" />
              </Th>
              <Th border="0" py="16px">
                <Flex align="center" gap="4px">
                  <CustomText
                    text="Type"
                    color="extsy.neutral400"
                    size="13px"
                  />
                  {/* <IconCaretDownFilled size="16px" color="#848E9C" /> */}
                </Flex>
              </Th>
              <Th border="0" py="16px">
                <Flex align="center" gap="4px">
                  <CustomText
                    text="Side"
                    color="extsy.neutral400"
                    size="13px"
                  />
                  {/* <IconCaretDownFilled size="16px" color="#848E9C" /> */}
                </Flex>{" "}
              </Th>
              <Th border="0" py="16px">
                <CustomText text="Price" color="extsy.neutral400" size="13px" />
              </Th>
              <Th border="0" py="16px">
                <CustomText
                  text="Amount"
                  color="extsy.neutral400"
                  size="13px"
                />
              </Th>
              <Th border="0" py="16px">
                <CustomText
                  text="Filled"
                  color="extsy.neutral400"
                  size="13px"
                />
              </Th>
              <Th border="0" py="16px">
                <CustomText text="Total" color="extsy.neutral400" size="13px" />
              </Th>
              <Th border="0" py="16px">
                <Flex align="center" gap="4px">
                  <CustomText text="Cancel" color="#F0B90B" size="13px" />
                  <IconCaretDownFilled size="16px" color="#848E9C" />
                </Flex>
              </Th>
            </Tr>
          </Thead>

          <Tbody>
            {isLoading
              ? [1, 2, 3, 4, 5].map((index) => <LoadingRow key={index} />)
              : orders?.data?.map((order: any, index: number) => (
                  <Tr key={index}>
                    <Td border="0" py="16px">
                      <CustomText
                        text={moment(order?.created_at).format(
                          "DD-MM-YYYY HH:mm A"
                        )}
                        color="extsy.neutral400"
                        size="13px"
                      />
                    </Td>
                    <Td border="0" py="16px">
                      <CustomText
                        text={order?.pair_ticker || order?.pair_ticker_binance}
                        color="extsy.neutral400"
                        size="13px"
                      />
                    </Td>
                    <Td border="0" py="16px">
                      <CustomText
                        text={order?.orderType}
                        color="extsy.neutral400"
                        size="13px"
                      />
                    </Td>
                    <Td border="0" py="16px">
                      <CustomText
                        text={order?.side}
                        color={order?.side === "BUY" ? "#0ECB81" : "#F6465D"}
                        size="13px"
                      />
                    </Td>
                    <Td border="0" py="16px">
                      <CustomText
                        text={formatNumber(order?.price, 6)}
                        color="extsy.neutral400"
                        size="13px"
                      />
                    </Td>
                    <Td border="0" py="16px">
                      <CustomText
                        text={`${formatNumber(order?.quantity, 6)} ${
                          order?.pair_ticker?.split("-")?.[0] || ""
                        }`}
                        color="extsy.neutral400"
                        size="13px"
                      />
                    </Td>
                    <Td border="0" py="16px">
                      <CustomText
                        text={`${formatNumber(order?.quantity_filled, 6)} ${
                          order?.pair_ticker?.split("-")?.[0] || ""
                        }`}
                        color="extsy.neutral400"
                        size="13px"
                      />
                    </Td>
                    <Td border="0" py="16px">
                      <CustomText
                        text={formatNumber(
                          order?.quantity_filled * order?.price,
                          6
                        )}
                        color="extsy.neutral400"
                        size="13px"
                      />
                    </Td>
                    <Td border="0" py="16px">
                      <IconTrashFilled
                        size="16px"
                        color="#FAFAFA"
                        cursor="pointer"
                        onClick={() => {
                          cancelOrder.mutate({
                            id: order?.id,
                            isDummy: false,
                          });
                        }}
                      />
                    </Td>
                  </Tr>
                ))}
          </Tbody>
          <Flex
            gap="16px"
            px="20px"
            py="14px"
            align="center"
            justify="flex-end"
          >
            {/* Showing range of items */}
            <CustomText
              text={`${offset + 1}-${Math.min(
                offset + limit,
                totalTransactions
              )} ${t("of")} ${totalTransactions}`}
              size="13px"
              weight="500"
              color="extsy.neutral300"
            />

            <Flex gap="8px">
              {/* Previous Page Button */}
              <Image
                src={ArrowBack}
                alt="arrow-back"
                cursor={currentPage > 1 ? "pointer" : "not-allowed"}
                opacity={currentPage > 1 ? 1 : 0.5}
                onClick={handlePrevPage}
              />

              {/* Page Numbers */}
              {[...Array(totalPages)].map((_, index) => (
                <Flex
                  key={index}
                  flexDir="column"
                  align="center"
                  justify="center"
                  bg={
                    currentPage === index + 1
                      ? "extsy.neutral900"
                      : "transparent"
                  }
                  borderRadius="6px"
                  height="30px"
                  width="30px"
                  cursor="pointer"
                  onClick={() => setOffset(index * limit)}
                >
                  <CustomText text={index + 1} size="14px" weight="500" />
                </Flex>
              ))}

              {/* Next Page Button */}
              <Image
                src={ArrowForward}
                alt="arrow-forward"
                cursor={currentPage < totalPages ? "pointer" : "not-allowed"}
                opacity={currentPage < totalPages ? 1 : 0.5}
                onClick={handleNextPage}
              />
            </Flex>
          </Flex>
        </Table>
      )}
    </TableContainer>
  );
}

const LoadingRow = () => (
  <Tr>
    <Td border="0" py="16px">
      <SkeletonText noOfLines={1} w="60px" />
    </Td>
    <Td border="0" py="16px">
      <SkeletonText noOfLines={1} w="60px" />
    </Td>
    <Td border="0" py="16px">
      <SkeletonText noOfLines={1} w="60px" />{" "}
    </Td>
    <Td border="0" py="16px">
      <SkeletonText noOfLines={1} w="60px" />{" "}
    </Td>
    <Td border="0" py="16px">
      <SkeletonText noOfLines={1} w="60px" />
    </Td>
    <Td border="0" py="16px">
      <SkeletonText noOfLines={1} w="60px" />
    </Td>
    <Td border="0" py="16px">
      <SkeletonText noOfLines={1} w="60px" />
    </Td>
    <Td border="0" py="16px">
      <SkeletonText noOfLines={1} w="60px" />{" "}
    </Td>
    <Td border="0" py="16px">
      <SkeletonText noOfLines={1} w="60px" />{" "}
    </Td>
  </Tr>
);

export default OpenOrders;
