// Function to wait for transaction confirmation
export const waitForTransactionConfirmation = async (
  transactionId: string,
  tronWeb: any
) => {
  const maxRetries = 20; // Maximum number of retries
  let retries = 0;
  const interval = 2500; // Time interval between each check (2.5 seconds)

  return new Promise((resolve, reject) => {
    const intervalId = setInterval(async () => {
      try {
        // Fetch the transaction info using the transaction ID
        const txInfo = await tronWeb.trx.getTransactionInfo(transactionId);
        // console.log(txInfo, "tex------------info");
        if (txInfo && txInfo.receipt) {
          clearInterval(intervalId);
          if (txInfo.receipt.result === "SUCCESS") {
            resolve(txInfo); // Transaction successful
          } else {
            reject(new Error("Transaction failed: " + txInfo.receipt.result));
          }
        }
      } catch (error) {
        retries++;
        if (retries >= maxRetries) {
          clearInterval(intervalId);
          reject(new Error("Transaction not found after maximum retries."));
        }
        // If the transaction is not found yet, just continue polling
      }
    }, interval);
  });
};
