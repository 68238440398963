import { Box, Flex, SkeletonText } from "@chakra-ui/react";
import React from "react";
import CustomText from "../text/CustomText";
import { useTranslation } from "react-i18next";
import { useGetCashbackStats } from "../../utils/api/dashboard.api";
import { formatNumber } from "../../utils/functions/formatNumber";

function ExtsyStats() {
  const { t } = useTranslation();

  const { data: statData, isLoading: statsLoading } = useGetCashbackStats();
  console.log(statData);
  return (
    <Flex
      flexDir="column"
      gap={{ base: "40px", md: "40px" }}
      borderRadius={{ base: "16px", lg: "24px" }}
      border="1px solid"
      borderColor="extsy.neutral1000"
      bg="extsy.neutral1000"
      p={{ base: "16px", lg: "32px" }}
      pos="relative"
      overflow="hidden"
    >
      <Box zIndex="100">
        <CustomText
          text={t("stats")}
          size={{ base: "18px", lg: "24px" }}
          weight={{ base: "600", lg: "700" }}
          color="extsy.white"
        />
      </Box>
      <Flex
        gap={{ base: "16px", lg: "40px" }}
        align={{ lg: "center" }}
        zIndex="100"
        wrap={{ base: "wrap", lg: "nowrap" }}
        // flexDir={{
        //   lg: "row",
        //   base: "column",
        // }}
      >
        <Flex flexDir="column" gap="8px" w={{ base: "230px", lg: "100%" }}>
          <CustomText
            text={t("totalEarned")}
            color="extsy.baseWhite"
            size="14px"
          />
          {statsLoading ? (
            <SkeletonText noOfLines={1} width="120px" h="25px" mt="10px" />
          ) : (
            <CustomText
              text={`${formatNumber(statData?.totalEarned, 6) || 0} BTC`}
              size={{ base: "24px", lg: "32px" }}
              weight={{ base: "600", lg: "700" }}
              color="extsy.baseWhite"
            />
          )}
          <CustomText
            text={`~ $ ${formatNumber(statData?.currentCashbackInUSD, 3) || 0}`}
            size="14px"
            color="extsy.baseWhite"
          />
        </Flex>
        <Box
          height="88px"
          width="1px"
          bg="extsy.neutral900"
          display={{
            base: "none",
            lg: "block",
          }}
        />
        <Flex flexDir="column" gap="8px" w={{ base: "230px", lg: "100%" }}>
          <CustomText
            text={t("available")}
            color="extsy.baseWhite"
            size="14px"
          />
          {statsLoading ? (
            <SkeletonText noOfLines={1} width="120px" h="25px" mt="10px" />
          ) : (
            <CustomText
              text={`${formatNumber(statData?.totalAvailable, 6) || 0} BTC`}
              size={{ base: "24px", lg: "32px" }}
              weight={{ base: "600", lg: "700" }}
              color="extsy.baseWhite"
            />
          )}
          <CustomText
            text={`~ $ ${formatNumber(statData?.totalAvailableInUSD, 3)}`}
            size="14px"
            color="extsy.baseWhite"
          />
        </Flex>
        <Box
          height="88px"
          width="1px"
          bg="extsy.neutral900"
          display={{
            base: "none",
            lg: "block",
          }}
        />
        <Flex flexDir="column" gap="8px" w={{ base: "230px", lg: "100%" }}>
          <CustomText
            text={t("currentCashback")}
            color="extsy.baseWhite"
            size="14px"
          />
          {statsLoading ? (
            <SkeletonText noOfLines={1} width="120px" h="25px" mt="10px" />
          ) : (
            <CustomText
              text={`${formatNumber(statData?.currentCashback, 6) || 0} BTC`}
              size={{ base: "24px", lg: "32px" }}
              weight={{ base: "600", lg: "700" }}
              color="extsy.baseWhite"
            />
          )}
          <CustomText
            text={t("ofTheVolume")}
            size="14px"
            color="extsy.baseWhite"
          />
        </Flex>
      </Flex>
    </Flex>
  );
}

export default ExtsyStats;
