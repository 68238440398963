import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { client } from "./api-client";

//* 2FA

export const useEnable2FA = () => {
  const queryClient = useQueryClient();
  return useMutation<any, Error, any>({
    mutationFn: async (payload) => {
      const response = await client(`settings/enable2fa`, {
        method: "POST",
        data: payload,
      });

      if (response.error) {
        throw new Error(response.error);
      }
      queryClient.invalidateQueries({ queryKey: ["getLoggedInUser"] });
      return response;
    },
  });
};

export const useCreatePaymentIntentForSubscription = () => {
  return useMutation<any, Error, any>({
    mutationFn: async (id) => {
      //console.log("data in end-point", payload);
      const response = await client(
        `settings/createPaymentIntentForSubscription/${id}`,
        {
          method: "POST",
          // data: id,
        }
      );
      return response;
    },
  });
};

export const useGetPaymentMethod = () => {
  return useQuery<any, Error>({
    queryKey: ["getPaymentMethod"],
    queryFn: async () => {
      const response = await client(`settings/getPaymentMethod`);
      if (response.error) {
        throw new Error(response.error);
      }
      return response;
    },
  });
};

export const useUpdatePaymentMethod = () => {
  const queryClient = useQueryClient();
  return useMutation<any, Error, any>({
    mutationFn: async (payload) => {
      const response = await client(`settings/editPaymentMethod`, {
        method: "POST",
        data: payload,
      });
      queryClient.invalidateQueries({ queryKey: ["getPaymentMethod"] });
      if (response.error) {
        throw new Error(response.error);
      }
      return response;
    },
  });
};

export const useVerify2FA = () => {
  const queryClient = useQueryClient();

  return useMutation<any, Error, any>({
    mutationFn: async (payload) => {
      const response = await client(`settings/verify2fa`, {
        method: "POST",
        data: payload,
      });

      if (response.error) {
        throw new Error(response.error);
      }
      queryClient.invalidateQueries({ queryKey: ["getLoggedInUser"] });
      return response;
    },
  });
};

//* PLANS

export const useGetSubscriptionPlans = () => {
  return useQuery<any, Error>({
    queryKey: ["getSubscriptionPlans"],
    queryFn: async () => {
      const response = await client(`settings/getSubscriptionPlans`);
      if (response.error) {
        throw new Error(response.error);
      }
      return response;
    },
    staleTime: 1000 * 60 * 60 * 24, // 24 hours
  });
};

export const useGetSubscriptionPlanById = () => {
  return useQuery<any, Error, any>({
    queryKey: ["getSubscriptionPlanById"],
    queryFn: async (id) => {
      const response = await client(`settings/getSubscriptionPlanById/${id}`);
      if (response.error) {
        throw new Error(response.error);
      }
      return response;
    },
  });
};

export const useCancelSubscription = () => {
  const queryClient = useQueryClient();
  return useMutation<any, Error>({
    mutationFn: async () => {
      const response = await client(`settings/cancelSubscription`, {
        method: "POST",
      });

      if (response.error) {
        throw new Error(response.error);
      }
      queryClient.invalidateQueries({ queryKey: ["getLoggedInUser"] });
      return response;
    },
  });
};

//* Device Tokens For Notificaitons

export const useGetDeviceTokens = () => {
  return useQuery<any, Error>({
    queryKey: ["getDeviceTokens"],
    queryFn: async () => {
      const response = await client(`settings/getDeviceTokens`);
      if (response.error) {
        throw new Error(response.error);
      }
      return response;
    },
    staleTime: 1000 * 60 * 5, // 5 minutes
  });
};

export const useCreateDeviceToken = () => {
  const queryClient = useQueryClient();
  return useMutation<any, Error, any>({
    mutationFn: async (payload) => {
      const response = await client(`settings/createDeviceToken`, {
        method: "POST",
        data: payload,
      });

      if (response.error) {
        throw new Error(response.error);
      }
      queryClient.invalidateQueries({ queryKey: ["getDeviceTokens"] });
      return response;
    },
  });
};

export const useUpdateDeviceToken = () => {
  const queryClient = useQueryClient();
  return useMutation<any, Error, any>({
    mutationFn: async (payload) => {
      const response = await client(
        `settings/updateDeviceToken/${payload?.id}`,
        {
          method: "POST",
          data: payload,
        }
      );

      if (response.error) {
        throw new Error(response.error);
      }
      queryClient.invalidateQueries({ queryKey: ["getDeviceTokens"] });
      return response;
    },
  });
};

export const useDeleteDeviceToken = () => {
  return useMutation<any, Error, any>({
    mutationFn: async (payload) => {
      const response = await client(`settings/deleteDeviceToken`, {
        method: "POST",
        data: payload,
      });

      if (response.error) {
        throw new Error(response.error);
      }
      return response;
    },
  });
};

// * Notifications

export const useGetNotifications = () => {
  return useQuery<any, Error>({
    queryKey: ["getNotifications"],
    queryFn: async () => {
      const response = await client(`settings/getNotifications`);
      if (response.error) {
        throw new Error(response.error);
      }
      return response;
    },
  });
};

export const useMarkNotificationAsRead = () => {
  const queryClient = useQueryClient();
  return useMutation<any, Error, any>({
    mutationFn: async (id) => {
      const response = await client(`settings/markNotificationAsRead/${id}`, {
        method: "POST",
      });

      if (response.error) {
        throw new Error(response.error);
      }
      queryClient.invalidateQueries({ queryKey: ["getNotifications"] });
      return response;
    },
  });
};

export const useMarkAllNotificationsAsRead = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async () => {
      const response = await client(`settings/markAllNotificationsAsRead`, {
        method: "POST",
      });

      if (response.error) {
        throw new Error(response.error);
      }
      queryClient.invalidateQueries({ queryKey: ["getNotifications"] });
      return response;
    },
  });
};
