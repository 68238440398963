import { Flex, useMediaQuery, Img } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import CustomText from "../../components/text/CustomText";
import SecurityImg from "../../assets/images/auth-security-Img.svg";
import PrimaryButton from "../../components/button/PrimaryButton";
import CustomVerificationInput from "../../components/form/customVerificationInput";
import CustomModal from "../../components/modal/customModal";
import IdentityImg from "../../assets/images/identityImg.svg";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useValidate2FA } from "../../utils/api/auth.api";
import { useQueryClient } from "@tanstack/react-query";
import { useCustomAlert } from "../../utils/api/alert";

function LoginSecurityCode() {
  const queryClient = useQueryClient();

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");

  const validate2FA = useValidate2FA();
  const alert = useCustomAlert();

  const [isLessThan768] = useMediaQuery("(max-height: 799px)");
  const [open, setOpen] = useState(false);
  const [otp, setOtp] = useState("");

  const handleModalOpen = () => {
    setOpen(!open);
  };

  const handleModalClose = () => {
    setOpen(false);
  };

  const handle2FAValidation = (payload: any) => {
    if (!otp) {
      alert?.mutate({
        message: "Please enter OTP",
        status: "danger",
      });
      return;
    }
    if (otp.length < 6) {
      alert?.mutate({
        message: "Please enter valid OTP",
        status: "danger",
      });
      return;
    }
    if (!email) {
      alert?.mutate({
        message: "Email not found",
        status: "danger",
      });
      return;
    }
    validate2FA
      ?.mutateAsync({ email, otp })
      .then((result) => {
        alert?.mutate({
          message: result?.message,
          status: "success",
        });
        if (
          result?.user?.kyc_level === 0 ||
          !result?.user?.kyc_level ||
          result?.user?.kyc_status !== "APPROVED"
        ) {
          handleModalOpen();
        } else {
          localStorage.setItem("token", result?.token?.access_token);
          queryClient.invalidateQueries({ queryKey: ["token"] });
          queryClient.invalidateQueries({ queryKey: ["getLoggedInUser"] });
          navigate("/");
        }
      })
      .catch((error) => {
        alert?.mutate({
          message:
            error?.exception && Array.isArray(error.exception)
              ? error.exception[0]
              : error?.message,
          status: "danger",
        });
      });
  };

  const storeToken = (from?: string) => {
    localStorage.setItem("token", validate2FA?.data?.token?.access_token);
    queryClient.invalidateQueries({ queryKey: ["token"] });
    queryClient.invalidateQueries({ queryKey: ["getLoggedInUser"] });
    if (from === "kyc") {
      navigate("/settings");
      handleModalClose();
    } else {
      navigate("/");
    }
  };

  useEffect(() => {
    if (!email) {
      navigate("/auth/login");
    }
  }, [email, navigate]);

  return (
    <>
      <Flex flexDir="column" gap="16px" maxW="480px" mx="auto">
        <Flex
          px={{ base: "16px", md: "40px" }}
          py={isLessThan768 ? "40px" : "56px"}
          bg="rgba(255, 255, 255, 0.03)"
          borderRadius="16px"
          w="100%"
          flexDir="column"
          justify="space-between"
          gap={isLessThan768 ? "24px" : "0px"}
          minH={isLessThan768 ? "524px" : "742px"}
        >
          <Flex align="center" flexDir="column" gap="24px">
            <CustomText
              text={t("enterSecurityCode")}
              family="Metropolis"
              size="24px"
              lh="28px"
              weight="700"
              align="center"
              color="extsy.baseWhite"
            />
            <CustomText
              text={t("securityCodeDescription")}
              align="center"
              color="extsy.neutral300"
            />
          </Flex>

          <Flex flexDir="column" align="center" justify="center">
            <Img src={SecurityImg} alt="img" minW={"139px"} minH={"120px"} />
          </Flex>
          <Flex flexDir="column" align="center" justify="center">
            <CustomVerificationInput
              placeholder={0}
              value={otp}
              onChange={setOtp}
            />
          </Flex>
          <Flex align="center">
            <PrimaryButton
              title={t("verify")}
              onClick={handle2FAValidation}
              isLoading={validate2FA.isPending}
            />{" "}
          </Flex>
        </Flex>
      </Flex>

      <CustomModal
        isOpen={open}
        onClose={() => storeToken()}
        onSubmit={() => storeToken("kyc")}
        height={"450px"}
      >
        <Flex
          flexDir="column"
          align="center"
          gap={"32px"}
          minH="100%"
          px="24px"
          py="32px"
        >
          <Img src={IdentityImg} alt="checkicon" minH="104px" minW="67.69px" />
          <CustomText
            text={t("verifyYourIdentity")}
            color="extsy.baseWhite"
            lh="19.2px"
            size="24px"
            family="Metropolis"
            pb="8px"
          />
          <CustomText
            text={t("verificationDescription")}
            color="extsy.neutral300"
            align="center"
            lh="19.2px"
          />
        </Flex>
      </CustomModal>
    </>
  );
}

export default LoginSecurityCode;
