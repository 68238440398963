import {
  Box,
  Circle,
  Flex,
  Image,
  SkeletonCircle,
  SkeletonText,
} from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import CustomText from "../text/CustomText";
import GradientText from "../text/GradientText";
import ExtsyCircleIco from "../../assets/images/extsy-circle-ico.svg";
import SwapIco from "../../assets/images/swap-notification-ico.svg";
import TrxSuccessIco from "../../assets/images/notifications/trx-successfull.svg";
import SubscriptionIco from "../../assets/images/notifications/extsy-pro.svg";
import WithdrawIco from "../../assets/images/notifications/withdraw-icon.svg";
import DepositIco from "../../assets/images/notifications/deposit-confirmed.svg";
import WelcomeIco from "../../assets/images/notifications/welcome-partner.svg";
import CardActivationIco from "../../assets/images/notifications/card-activation.svg";
import PasswordChangeIco from "../../assets/images/notifications/password-update.svg";
import KYCIco from "../../assets/images/notifications/kyc-approved.svg";
import Top3Ico from "../../assets/images/notifications/in-top-3.svg";
import PayoutIco from "../../assets/images/notifications/payout-processed.svg";
import OrderConfirmedIco from "../../assets/images/notifications/order-confirmed.svg";
import NoNotify from "../../assets/images/no-notify.svg";
import WinIco from "../../assets/images/raffle-win-ico.svg";
import moment from "moment";
import { NOTIFICATION_TYPES } from "../../utils/types/enums";
import { useMarkAllNotificationsAsRead } from "../../utils/api/settings.api";

function Notifications({ notifications, isLoading }: any) {
  const { t } = useTranslation();

  const markAllAsRead = useMarkAllNotificationsAsRead();

  const handleMarkAllRead = () => {
    if (markAllAsRead?.isPending || isLoading) {
      return;
    }
    markAllAsRead.mutateAsync();
  };

  const getNotificationIco = (type: string) => {
    switch (NOTIFICATION_TYPES?.[type]) {
      case "Transaction Successful!":
        return TrxSuccessIco;
      case "Subscription":
        return SubscriptionIco;
      case "Withdraw":
        return WithdrawIco;
      case "Deposit":
        return DepositIco;
      case "Swap Crypto":
        return SwapIco;
      case "Raffle of the month":
        return WinIco;
      case "Welcome, Partner!":
        return WelcomeIco;
      case "Card Activated!":
        return CardActivationIco;
      case "Password Changed!":
        return PasswordChangeIco;
      case "KYC Approved":
        return KYCIco;
      case "You're in the Top 3!":
        return Top3Ico;
      case "Payout Processed!":
        return PayoutIco;
      case "Order Confirmed!":
        return OrderConfirmedIco;
      default:
        return ExtsyCircleIco;
    }
  };

  return (
    <Box
      pos="absolute"
      // maxW="513px"
      w={{ base: "310px", sm: "358px", lg: "513px" }}
      p={{ base: "24px 16px", lg: "24px" }}
      borderRadius={{ base: "16px", lg: "24px" }}
      border="1px solid"
      borderColor="extsy.neutral900"
      bg="extsy.neutral1000"
      top="0"
      mt="50px"
      right="0"
      mr={{ base: "-40px", sm: "0" }}
      zIndex="10000"
    >
      <Flex justify="space-between" align="center" pb="12px">
        <Flex align="center" gap="6px">
          <CustomText
            text={t("notifications")}
            color="extsy.baseWhite"
            weight="600"
          />
          {isLoading ? (
            <SkeletonCircle size="24px" />
          ) : (
            <Circle
              bg="extsy.g1"
              size="24px"
              display={Number(notifications?.totalUnread) > 0 ? "flex" : "none"}
            >
              <CustomText
                text={notifications?.totalUnread || 0}
                color="extsy.bg"
                size="14px"
              />
            </Circle>
          )}
        </Flex>
        <GradientText
          text={t("markAllAsRead")}
          size="12px"
          weight="500"
          bg="extsy.g1"
          cursor="pointer"
          onClick={handleMarkAllRead}
        />
      </Flex>
      {isLoading ? (
        new Array(3)?.fill(1)?.map((_, idx) => <NotificationLoader key={idx} />)
      ) : (
        <Box overflowY={"auto"} maxH="383px">
          {notifications?.data?.length > 0 ? (
            notifications?.data?.map((notifi: any, idx: number) => (
              <Flex
                gap="12px"
                p={{ base: "16px 0px", lg: "16px 16px 16px 24px" }}
                borderTop="1px solid"
                borderColor="extsy.neutral900"
              >
                <Image
                  src={getNotificationIco(notifi?.type)}
                  alt="win"
                  boxSize="32px"
                  borderRadius="50%"
                />
                <Flex flexDir="column" gap="12px">
                  <Flex flexDir="column" gap="8px">
                    <CustomText
                      text={notifi?.title}
                      size="14px"
                      weight="600"
                      color="extsy.baseWhite"
                    />
                    <CustomText text={notifi?.body} size="12px" weight="600" />
                  </Flex>
                  <CustomText
                    text={moment(notifi?.created_at).fromNow()}
                    size="12px"
                    color="extsy.neutral300"
                  />
                </Flex>
              </Flex>
            ))
          ) : (
            <Flex
              flexDir="column"
              justify="center"
              align="center"
              py={"36px"}
              maxH="383px" // Ensures it takes up the entire container
            >
              <Image src={NoNotify} alt="No notifications" />
            </Flex>
          )}
          {/* <Flex
            gap="12px"
            p={{ base: "16px 0px", lg: "16px 16px 16px 24px" }}
            borderTop="1px solid"
            borderColor="extsy.neutral900"
          >
            <Image src={WinIco} alt="win" boxSize="32px" borderRadius="50%" />
            <Flex flexDir="column" gap="12px">
              <Flex flexDir="column" gap="8px">
                <CustomText
                  text="Raffle of the month August"
                  size="14px"
                  weight="600"
                  color="extsy.baseWhite"
                />
                <CustomText
                  text={
                    <span>
                      You have won{" "}
                      <span style={{ color: "#fff", fontSize: "12px" }}>
                        $54,978.00
                      </span>{" "}
                      prize. collect now.
                    </span>
                  }
                  size="12px"
                  weight="600"
                />
              </Flex>
              <Button
                bg="extsy.g1"
                display="flex"
                alignItems="center"
                justifyContent="center"
                p="0"
                height="32px"
                width="108px"
                borderRadius="8px"
                _hover={{
                  bg: "extsy.g1",
                }}
              >
                <CustomText
                  text={t("collectPrize")}
                  size="14px"
                  weight="500"
                  color="extsy.bg"
                />
              </Button>
              <CustomText
                text="8 min ago"
                size="12px"
                color="extsy.neutral300"
              />
            </Flex>
          </Flex>
          <Flex
            gap="12px"
            p={{ base: "16px 0px", lg: "16px 16px 16px 24px" }}
            borderTop="1px solid"
            borderColor="extsy.neutral900"
          >
            <Image src={SwapIco} alt="win" boxSize="32px" borderRadius="50%" />
            <Flex flexDir="column" gap="12px">
              <Flex flexDir="column" gap="8px">
                <CustomText
                  text="Swap Crypto"
                  size="14px"
                  weight="600"
                  color="extsy.baseWhite"
                />
                <CustomText
                  text={
                    <span>
                      You have swapped{" "}
                      <span style={{ color: "#fff", fontSize: "12px" }}>
                        5 BTC
                      </span>{" "}
                      in to{" "}
                      <span style={{ color: "#fff", fontSize: "12px" }}>
                        USDT
                      </span>
                    </span>
                  }
                  size="12px"
                  weight="600"
                />
              </Flex>
              <CustomText
                text="45 min ago"
                size="12px"
                color="extsy.neutral300"
              />
            </Flex>
          </Flex>
          <Flex
            gap="12px"
            p={{ base: "16px 0px", lg: "16px 16px 16px 24px" }}
            borderTop="1px solid"
            borderColor="extsy.neutral900"
          >
            <Image
              src={ExtsyCircleIco}
              alt="win"
              boxSize="32px"
              borderRadius="50%"
            />
            <Flex flexDir="column" gap="12px">
              <Flex flexDir="column" gap="8px">
                <CustomText
                  text="Extsy Support"
                  size="14px"
                  weight="600"
                  color="extsy.baseWhite"
                />
                <CustomText
                  text={
                    <span>
                      Responded to your ticket{" "}
                      <span style={{ color: "#fff", fontSize: "12px" }}>
                        #36472
                      </span>{" "}
                    </span>
                  }
                  size="12px"
                  weight="600"
                />
              </Flex>
              <Flex gap="12px" align="center">
                <Box border="1px solid #FEF9CC" py="8px" />
                <CustomText
                  text="We have received your case our team is working on it."
                  color="extsy.neutral300"
                  size="12px"
                  weight="500"
                />
              </Flex>
              <CustomText
                text="1 day ago"
                size="12px"
                color="extsy.neutral300"
              />
            </Flex>
          </Flex> */}
        </Box>
      )}
    </Box>
  );
}

const NotificationLoader = () => (
  <Flex
    gap="12px"
    p={{ base: "16px 0px", lg: "16px 16px 16px 24px" }}
    borderTop="1px solid"
    borderColor="extsy.neutral900"
    w="100%"
  >
    <SkeletonCircle size="32px" />
    <Flex flexDir="column" gap="12px">
      <Flex flexDir="column" gap="8px">
        <SkeletonText noOfLines={1} w="250px" />
        <SkeletonText noOfLines={2} w="150px" />
      </Flex>
      <SkeletonText noOfLines={1} w="100px" />
    </Flex>
  </Flex>
);

export default Notifications;
