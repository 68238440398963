import { Box, PinInput, PinInputField } from "@chakra-ui/react";
import React from "react";

function CustomVerificationInput({
  type,
  placeholder,
  value,
  onChange,
  w,
  gap,
}: any) {
  // const [isLessThan768] = useMediaQuery("(max-width: 799px)");
  const pinInputFieldStyle = {
    borderBottom: "1px solid",
    borderColor: "extsy.neutral700",
    borderTop: "none",
    borderLeft: "none",
    borderRight: "none",
    borderRadius: "0",
    color: "extsy.baseWhite",
    fontSize: "25px",
    fontFamily: "Metropolis",
    pl: "0",
    mx: gap || "4px",
    _placeholder: {
      color: "extsy.text",
      fontSize: "25px",
      fontWeight: "400",
      fontFamily: "Metropolis",
    },
    _hover: {
      borderColor: "extsy.secondary",
    },
    _focusVisible: {
      border: "none",
      borderBottom: "1px solid",
      borderColor: "extsy.secondary",
    },
  };
  return (
    <Box pos="relative">
      <PinInput
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      >
        {Array.from({ length: 6 }).map((_, index) => (
          <PinInputField
            w={{ base: "33px", md: w || "53px" }}
            key={index}
            {...pinInputFieldStyle}
          />
        ))}
      </PinInput>
    </Box>
  );
}

export default CustomVerificationInput;
