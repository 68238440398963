import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { client } from "./api-client";

export const useSignUp = () => {
  return useMutation<any, Error, any>({
    mutationFn: async (signUpData) => {
      const response = await client(`auth/signup`, {
        method: "POST",
        data: signUpData,
      });

      if (response.error) {
        throw new Error(response.error);
      }
      return response;
    },
  });
};

export const useGetSumsubToken = () => {
  return useQuery({
    queryKey: ["getSumsubToken"],
    queryFn: async () => {
      const response = await client("auth/getSumsubAccessToken");
      return response;
    },
    staleTime: 1000 * 60 * 10,
  });
};

export const useLoginWithGoogle = () => {
  return useMutation<any, Error, any>({
    mutationFn: async (googleData) => {
      const response = await client(`auth/loginWithGoogle`, {
        method: "POST",
        data: googleData,
      });

      if (response.error) {
        throw new Error(response.error);
      }
      return response;
    },
  });
};

export const useLogin = () => {
  return useMutation<any, Error, any>({
    mutationFn: async (loginData) => {
      const response = await client(`auth/login`, {
        method: "POST",
        data: loginData,
      });

      if (response.error) {
        throw new Error(response.error);
      }
      return response;
    },
  });
};

export const useGetLoginToken = () => {
  return useMutation<any, Error, any>({
    mutationFn: async (loginData) => {
      const response = await client(`auth/loginWithToken`, {
        method: "GET",
        data: loginData,
      });

      if (response.error) {
        throw new Error(response.error);
      }
      return response;
    },
  });
};

export const useValidate2FA = () => {
  return useMutation<any, Error, any>({
    mutationFn: async (payload) => {
      const response = await client(`auth/validate2faForLogin`, {
        method: "POST",
        data: payload,
      });

      if (response.error) {
        throw new Error(response.error);
      }
      return response;
    },
  });
};

export const useVerifyEmail = () => {
  return useMutation<any, Error, any>({
    mutationFn: async (payload) => {
      const response = await client(`auth/verifyEmail`, {
        method: "POST",
        data: payload,
      });

      if (response.error) {
        throw new Error(response.error);
      }
      return response;
    },
  });
};

export const useResendOTP = () => {
  return useMutation<any, Error, any>({
    mutationFn: async (payload) => {
      const response = await client(`auth/resendOtp`, {
        method: "POST",
        data: payload,
      });

      if (response.error) {
        throw new Error(response.error);
      }
      return response;
    },
  });
};

export const useForgotPassword = () => {
  return useMutation<any, Error, any>({
    mutationFn: async (payload) => {
      const response = await client(`auth/forgotPassword`, {
        method: "POST",
        data: payload,
      });

      if (response.error) {
        throw new Error(response.error);
      }
      return response;
    },
  });
};

export const useForgotPasswordVerifyEmail = () => {
  return useMutation<any, Error, any>({
    mutationFn: async (payload) => {
      const response = await client(`auth/verifyEmailForForgotPassword`, {
        method: "POST",
        data: payload,
      });

      if (response.error) {
        throw new Error(response.error);
      }
      return response;
    },
  });
};

export const useResetPassword = () => {
  return useMutation<any, Error, any>({
    mutationFn: async (payload) => {
      const response = await client(`auth/resetPassword`, {
        method: "POST",
        data: payload,
      });

      if (response.error) {
        throw new Error(response.error);
      }
      return response;
    },
  });
};

export const useChangePassword = () => {
  return useMutation<any, Error, any>({
    mutationFn: async (payload) => {
      const response = await client(`auth/changePassword`, {
        method: "POST",
        data: payload,
      });

      if (response.error) {
        throw new Error(response.error);
      }
      return response;
    },
  });
};

export const useGetLoggedInUser = () =>
  useQuery({
    queryKey: ["getLoggedInUser"],
    queryFn: async () => {
      const token = localStorage.getItem("token");
      if (token) {
        const response = await client(`auth/getLoggedInUser`);
        return response;
      } else {
        return null;
      }
    },
  });

export const useLogout = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async () => {
      localStorage.removeItem("token");
      queryClient.invalidateQueries();
      queryClient.invalidateQueries({ queryKey: ["getLoggedInUser"] });
      queryClient.invalidateQueries({ queryKey: ["token"] });
      return "";
    },
  });
};

export const useUpdateRaffleName = () => {
  const queryClient = useQueryClient();
  return useMutation<any, Error, any>({
    mutationFn: async (payload) => {
      const response = await client(`auth/updateRaffleName`, {
        method: "POST",
        data: payload,
      });

      if (response.error) {
        throw new Error(response.error);
      }
      queryClient.invalidateQueries({ queryKey: ["getLoggedInUser"] });
      return response;
    },
  });
};

export const useUpdateTronWalletAddress = () => {
  const queryClient = useQueryClient();
  return useMutation<any, Error, any>({
    mutationFn: async (payload) => {
      const response = await client(`auth/updateTronWalletAddress`, {
        method: "POST",
        data: payload,
      });

      if (response.error) {
        throw new Error(response.error);
      }
      queryClient.invalidateQueries({ queryKey: ["getLoggedInUser"] });
      return response;
    },
  });
};
