import { Box, Flex, VStack } from "@chakra-ui/react";
import logoIcon from "../../assets/images/logo-icon.svg";
import extsyLogo from "../../assets/images/extsy-logo.svg";
import CustomText from "../../components/text/CustomText";
import { useNavigate } from "react-router-dom";

const ComingSoon = () => {
  const navigate = useNavigate();
  return (
    <Box
      minH="100vh"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      alignItems="center"
      bg="extsy.bg"
      color="extsy.baseWhite"
      px="20px"
    >
      <Flex
        as="header"
        alignItems="center"
        justify="center"
        mb={8}
        pt={5}
        gap={2}
        cursor="pointer"
        onClick={() => navigate("/")}
      >
        <img src={logoIcon} alt="Logo Icon" height="30px" width="30px" />
        <img src={extsyLogo} alt="Extsy Logo" height="40px" />
      </Flex>

      {/* Main Content */}
      <VStack
        spacing={6}
        flexGrow={1}
        justifyContent="center"
        maxW={{ md: "850px" }}
      >
        <CustomText
          text="DESIGN IN PROCESS"
          size={{ base: "32px", md: "72px" }}
          color="extsy.baseWhite"
          weight="700"
          family="Metropolis"
        />
        <CustomText
          text="Design for this resolution is currently being developed. Please refer to the design for the resolution 1920x1024 in the meantime."
          size={{ base: "16px", md: "20px" }}
          align="center"
          family="Metropolis"
          color="extsy.neutral50"
        />
      </VStack>
      <Box />
    </Box>
  );
};

export default ComingSoon;
