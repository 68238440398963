import { Avatar, Box, Flex, Image, Stack } from "@chakra-ui/react";
import React, { useState } from "react";
import BackArrowYellow from "../../../assets/images/back-arrow-yellow.svg";
import CustomText from "../../../components/text/CustomText";
import { useLocation, useNavigate } from "react-router-dom";
import OrderTable from "../orders/OrderTable";
import { useMyOrderDetailListings } from "../../../utils/api/p2p.api";
import NoOrder from "../orders/NoOrder";

const AdDetail = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const order = location.state;
  const [offset, setOffset] = useState(0);
  const [limit] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [sortBy, setSortBy] = useState("");
  const [sortDirection, setSortDirection] = useState("ASC");

  const { data: orderData, isLoading: orderLoading } = useMyOrderDetailListings(
    pageNumber,
    limit,
    sortBy,
    sortDirection
  );
  return (
    <Box
      p={{ base: "16px", "2xl": "32px" }}
      bg="extsy.neutral1000"
      borderRadius={{ base: "16px", lg: "24px" }}
      border="1px solid"
      borderColor="extsy.neutral1000"
    >
      <Flex align="center" justify="space-between">
        <Flex
          //   align={{ lg: "center" }}
          gap={{ base: "16px", lg: "32px" }}
          flexDir={"column"}
          w={{ base: "100%", lg: "100%" }}
        >
          <Stack gap={"16px"}>
            <Flex
              align={"center"}
              gap={"8px"}
              cursor={"pointer"}
              onClick={() => navigate("/p2p")}
            >
              <Image src={BackArrowYellow} alt="back-arrow" />
              <CustomText
                text="Back"
                size="16px"
                lh="120%"
                weight="400"
                family="Metropolis"
                color="#FEF8C3"
              />
            </Flex>
            <CustomText
              text="Ad Detail"
              size="24px"
              lh="34px"
              weight="700"
              family="Metropolis"
              color="extsy.white"
            />
          </Stack>
          <Flex
            p={"24px"}
            align={"center"}
            justify={"space-between"}
            bg={"#222"}
            w={"100%"}
          >
            <CoinStack
              title={"Coin Type"}
              coinLogo={order?.coin_logo_url}
              coinName={order?.coin_name}
              coinSymbol={order?.coin_symbol_short}
            />
            <CoinStack title={"Chain"} coinName={order?.order_chain} />
            <CoinStack
              title={"Total Amount"}
              amount={order?.order_totalTradingAmount}
              amountSymbol="USDT"
            />
            <CoinStack
              title={"Available"}
              amount={order?.order_availableTradingAmount}
              amountSymbol="USDT"
            />
            <CoinStack
              title={"BTC Value"}
              amount="0.00"
              amountSymbol="BTC"
              btc={"0.00"}
            />
          </Flex>
          {orderData?.data?.length === 0 ? (
            <NoOrder />
          ) : (
            <OrderTable
              status="All"
              data={orderData}
              isLoading={orderLoading}
              sortDirection={sortDirection}
              setSortBy={setSortBy}
              setSortDirection={setSortDirection}
              offset={offset}
              setPageCount={setPageNumber}
              limit={limit}
              pageCount={pageNumber}
              setOffset={setOffset}
            />
          )}
        </Flex>
      </Flex>
    </Box>
  );
};

export default AdDetail;

const CoinStack = ({
  title,
  coinLogo,
  coinName,
  coinSymbol,
  amount,
  amountSymbol,
  btc,
}: any) => {
  return (
    <Stack gap={"20px"} align={"flex-start"} flex="1 0 0">
      <CustomText
        text={title}
        color="#FAFAFA"
        size="13px"
        family="Inter"
        weight="500"
        lh="14px"
      />
      {coinName && (
        <Flex gap="12px" align="center">
          <Avatar src={coinLogo} boxSize="24px" />
          <Flex gap="8px" flexDir="column">
            <CustomText text={coinName} size="14px" color="extsy.baseWhite" />
            {coinSymbol && (
              <CustomText text={coinSymbol} size="12px" color="extsy.text5" />
            )}
          </Flex>
        </Flex>
      )}
      {amount && (
        <Flex gap="12px" align="center" justify={"space-between"} w={"220px"}>
          <CustomText
            text={`${amount} ${amountSymbol}`}
            size="14px"
            color="#BAB8B8"
            weight="500"
            lh="14px"
          />
          {btc && (
            <CustomText
              text={`≈ $${btc}`}
              size="14px"
              color="#908B8B"
              weight="500"
            />
          )}
        </Flex>
      )}
    </Stack>
  );
};
