import {
  Box,
  Flex,
  Image,
  SkeletonText,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useOutsideClick,
} from "@chakra-ui/react";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomText from "../text/CustomText";
import TableSearchInput from "../form/TableSearchInput";
import { IconCheck, IconChevronDown, IconChevronUp } from "@tabler/icons-react";
import EmptyTrxIco from "../../assets/images/empty-trx-ico.svg";
import ArrowBack from "../../assets/images/black-left.svg";
import ArrowForward from "../../assets/images/black-right.svg";
// import GradientText from "../text/GradientText";
import { useGetTransactions } from "../../utils/api/dashboard.api";

const SECONDS_IN_A_DAY = 86400000;

const getTimeForFilter = (filterId: any) => {
  let timeInSeconds = 0;

  switch (filterId) {
    case "thisWeek":
      // 7 days in seconds
      timeInSeconds = 7 * SECONDS_IN_A_DAY;
      break;

    case "last14Days":
      // 14 days in seconds
      timeInSeconds = 14 * SECONDS_IN_A_DAY;
      break;

    case "lastMonth":
      // Assuming last month as 30 days
      timeInSeconds = 30 * SECONDS_IN_A_DAY;
      break;

    case "last2Month":
      // Assuming last 2 months as 60 days
      timeInSeconds = 60 * SECONDS_IN_A_DAY;
      break;

    default:
      break;
  }

  return timeInSeconds;
};

function RecentTransactions({ minH, mt, recordLimit }: any) {
  const { t } = useTranslation();

  const filterRef = useRef(null);

  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [activeFilter, setActiveFilter] = useState("thisWeek");
  const [limit] = useState(recordLimit || 10);
  const [offset, setOffset] = useState(0);
  const [transactionType] = useState("ALL");
  const [sortingKey, setSortingKey] = useState("");
  const [sortingOrder, setSortingOrder] = useState("");
  const [time, setTime] = useState(getTimeForFilter("thisWeek"));
  const [search, setSearch] = useState("");

  const { data: recentTransactions, isLoading: transactionLoading }: any =
    useGetTransactions(
      limit,
      offset,
      search,
      transactionType,
      sortingKey,
      sortingOrder,
      time
    );

  const totalTransactions = recentTransactions?.total || 0; // Total number of transactions
  const totalPages = Math.ceil(totalTransactions / limit); // Total number of pages
  const currentPage = Math.floor(offset / limit) + 1; // Current page number

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setOffset((prevOffset) => prevOffset + limit);
    }
  };

  // Handle going to the previous page
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setOffset((prevOffset) => prevOffset - limit);
    }
  };

  const FILTER_ITEMS = [
    {
      id: "thisWeek",
      name: t("thisWeek"),
    },
    {
      id: "last14Days",
      name: t("last14Days"),
    },
    {
      id: "lastMonth",
      name: t("lastMonth"),
    },
    {
      id: "last2Month",
      name: t("last2Month"),
    },
  ];

  const getBgColor = (status: string) => {
    switch (status?.toLowerCase()) {
      case "completed":
        return "#56CE77";
      case "confirmed":
        return "#56CE77";
      case "pending":
        return "#FFA333";
      case "in_progress":
        return "#FFA333";
      case "cancel":
        return "#FF6574";
      case "failed":
        return "#FF6574";
      default:
        return "extsy.neutral300";
    }
  };

  const getCoinSymbol = (trx: any) => {
    switch (trx.transaction_type) {
      case "RAFFLE":
        return "USDT";
      case "SWAP":
        return trx?.amount_symbol || "$";
      case "SUBSCRIPTION FEE PAID":
        return "$";
      default:
        return "$";
    }
  };

  useOutsideClick({
    ref: filterRef,
    handler: () => {
      setIsFilterOpen(false);
    },
  });

  return (
    <Flex
      p={{ base: "16px", lg: "24px" }}
      gap="16px"
      flexDir="column"
      bg="extsy.neutral1000"
      borderRadius={{ base: "16px", lg: "24px" }}
      boxShadow="0px 3px 4px 0px rgba(0, 0, 0, 0.03)"
      marginTop={mt || ""}
    >
      <Flex
        align={{ md: "center" }}
        justify="space-between"
        pb="20px"
        flexDir={{ base: "column", md: "row" }}
        gap={{ base: "10px", lg: "20px" }}
      >
        <CustomText
          text={t("recentTransaction")}
          size={{ base: "16px", lg: "24px" }}
          weight={{ base: "400", lg: "700" }}
          color="extsy.baseWhite"
          family="Metropolis"
        />
        <Flex align="center" gap="10px">
          <TableSearchInput
            placeholder={t("searchTransactions")}
            maxW={{ base: "204px", lg: "409px" }}
            minW="140px"
            value={search}
            onChange={(e: any) => setSearch(e.target.value)}
          />
          <Box pos="relative">
            <Flex
              height="38px"
              gap="12px"
              minW={{ lg: "149px" }}
              align="center"
              justify="space-between"
              cursor="pointer"
              borderRadius="8px"
              bg="extsy.neutral900"
              boxShadow="0px 2.667px 26.667px 0px rgba(0, 0, 0, 0.15)"
              ps="24px"
              pe="10px"
              onClick={() => {
                setIsFilterOpen(!isFilterOpen);
              }}
            >
              <CustomText
                cursor="pointer"
                text={t(activeFilter)}
                size="12px"
                color="extsy.baseWhite"
                wrap="nowrap"
              />
              {isFilterOpen ? (
                <IconChevronUp color="#FAFAFA" size="16px" />
              ) : (
                <IconChevronDown color="#FAFAFA" size="16px" />
              )}
            </Flex>
            {isFilterOpen && (
              <Flex
                flexDir="column"
                gap="12px"
                p="16px"
                borderRadius="8px"
                border="1px solid"
                borderColor="extsy.neutral800"
                bg="extsy.neutral900"
                minW="150px"
                pos="absolute"
                top="0"
                right={{ base: "0", lg: "auto" }}
                marginTop="50px"
                mr={{ base: "10px", lg: "auto" }}
                ref={filterRef}
              >
                {FILTER_ITEMS.map((item, index) => (
                  <Flex
                    align="center"
                    justify="space-between"
                    py="8px"
                    cursor="pointer"
                    key={index}
                    onClick={() => {
                      setIsFilterOpen(false);
                      setActiveFilter(item.id);
                      const selectedTimeRange: any = getTimeForFilter(item.id);
                      setTime(selectedTimeRange); // Update time state with selected range
                    }}
                  >
                    <CustomText
                      text={item?.name}
                      size="14px"
                      color={
                        item?.id === activeFilter
                          ? "extsy.baseWhite"
                          : "extsy.neutral300"
                      }
                      cursor="pointer"
                    />
                    {item?.id === activeFilter && (
                      <IconCheck size="16px" color="#FAFAFA" />
                    )}
                  </Flex>
                ))}
              </Flex>
            )}
          </Box>
        </Flex>
      </Flex>
      {!transactionLoading && !recentTransactions?.data?.length ? (
        <Flex
          minH={minH || ""}
          flexDir="column"
          justify="center"
          align="center"
          gap="8px"
        >
          <Image alt="empty" src={EmptyTrxIco} />
          <CustomText
            text={t("noHistoryFound")}
            size="14px"
            color="extsy.neutral400"
          />
        </Flex>
      ) : (
        <Flex flexDir="column" gap="24px">
          <TableContainer minH={minH || ""}>
            <Table border="1px solid" color="extsy.neutral900">
              <Thead bg="extsy.neutral900" height="40px">
                <Tr>
                  <Th borderColor="extsy.neutral900" maxW="283px" minW="160px">
                    <Flex align="center" gap="4px">
                      <CustomText
                        text={t("id")}
                        size="13px"
                        color="extsy.baseWhite"
                        weight="500"
                        ls="-0.13px"
                      />
                      <Flex
                        flexDir="column"
                        onClick={() => {
                          setSortingKey("id");
                          if (sortingOrder === "ASC") {
                            setSortingOrder("DESC");
                          } else {
                            setSortingOrder("ASC");
                          }
                        }}
                      >
                        <IconChevronUp
                          cursor="pointer"
                          color="#FAFAFA"
                          size="14px"
                        />
                        <IconChevronDown
                          color="#FAFAFA"
                          size="14px"
                          cursor="pointer"
                          style={{
                            marginTop: -7,
                          }}
                        />
                      </Flex>
                    </Flex>
                  </Th>
                  <Th borderColor="extsy.neutral900" maxW="160px" minW="160px">
                    <Flex align="center" gap="4px">
                      <CustomText
                        text={t("amount")}
                        size="13px"
                        color="extsy.baseWhite"
                        weight="500"
                        ls="-0.13px"
                      />
                      <Flex
                        flexDir="column"
                        onClick={() => {
                          setSortingKey("amount");
                          if (sortingOrder === "ASC") {
                            setSortingOrder("DESC");
                          } else {
                            setSortingOrder("ASC");
                          }
                        }}
                      >
                        <IconChevronUp
                          cursor="pointer"
                          color="#FAFAFA"
                          size="14px"
                        />
                        <IconChevronDown
                          color="#FAFAFA"
                          size="14px"
                          cursor="pointer"
                          style={{
                            marginTop: -7,
                          }}
                        />
                      </Flex>
                    </Flex>
                  </Th>
                  <Th borderColor="extsy.neutral900" maxW="283px" minW="160px">
                    <Flex align="center" gap="4px">
                      <CustomText
                        text={t("type")}
                        size="13px"
                        color="extsy.baseWhite"
                        weight="500"
                        ls="-0.13px"
                      />
                      <Flex
                        flexDir="column"
                        onClick={() => {
                          setSortingKey("transaction_type");
                          if (sortingOrder === "ASC") {
                            setSortingOrder("DESC");
                          } else {
                            setSortingOrder("ASC");
                          }
                        }}
                      >
                        <IconChevronUp
                          cursor="pointer"
                          color="#FAFAFA"
                          size="14px"
                        />
                        <IconChevronDown
                          color="#FAFAFA"
                          size="14px"
                          cursor="pointer"
                          style={{
                            marginTop: -7,
                          }}
                        />
                      </Flex>
                    </Flex>
                  </Th>
                  <Th borderColor="extsy.neutral900" maxW="283px" minW="160px">
                    <Flex align="center" gap="4px">
                      <CustomText
                        text={t("date")}
                        size="13px"
                        color="extsy.baseWhite"
                        weight="500"
                        ls="-0.13px"
                      />
                      <Flex
                        flexDir="column"
                        onClick={() => {
                          setSortingKey("created_at");
                          if (sortingOrder === "ASC") {
                            setSortingOrder("DESC");
                          } else {
                            setSortingOrder("ASC");
                          }
                        }}
                      >
                        <IconChevronUp
                          cursor="pointer"
                          color="#FAFAFA"
                          size="14px"
                        />
                        <IconChevronDown
                          color="#FAFAFA"
                          size="14px"
                          cursor="pointer"
                          style={{
                            marginTop: -7,
                          }}
                        />
                      </Flex>
                    </Flex>
                  </Th>
                  <Th borderColor="extsy.neutral900" maxW="283px" minW="160px">
                    <Flex align="center" gap="4px">
                      <CustomText
                        text={t("status")}
                        size="13px"
                        color="extsy.baseWhite"
                        weight="500"
                        ls="-0.13px"
                      />
                      <Flex
                        flexDir="column"
                        onClick={() => {
                          setSortingKey("status");
                          if (sortingOrder === "ASC") {
                            setSortingOrder("DESC");
                          } else {
                            setSortingOrder("ASC");
                          }
                        }}
                      >
                        <IconChevronUp
                          cursor="pointer"
                          color="#FAFAFA"
                          size="14px"
                        />
                        <IconChevronDown
                          color="#FAFAFA"
                          size="14px"
                          cursor="pointer"
                          style={{
                            marginTop: -7,
                          }}
                        />
                      </Flex>
                    </Flex>
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {transactionLoading
                  ? [1, 2, 3, 4, 5].map((_, index) => (
                      <LoadingRow key={index} />
                    ))
                  : recentTransactions?.data?.map((trx: any, index: any) => (
                      <Tr key={index}>
                        <Td
                          height="54px"
                          borderColor="extsy.neutral900"
                          borderRight="1px solid"
                        >
                          <CustomText
                            text={trx?.short_id || trx.id}
                            size="14px"
                            color="extsy.baseWhite"
                          />
                        </Td>
                        <Td
                          borderColor="extsy.neutral900"
                          borderRight="1px solid"
                        >
                          <CustomText
                            text={`${getCoinSymbol(trx) === "$" ? "$" : ""}${
                              trx.amount
                            } ${
                              getCoinSymbol(trx) !== "$"
                                ? getCoinSymbol(trx)
                                : ""
                            }`}
                            size="14px"
                          />
                        </Td>
                        <Td
                          borderColor="extsy.neutral900"
                          borderRight="1px solid"
                        >
                          <CustomText text={trx.transaction_type} size="14px" />
                        </Td>
                        <Td
                          borderColor="extsy.neutral900"
                          borderRight="1px solid"
                        >
                          <CustomText
                            text={formatDate(trx.created_at)}
                            size="14px"
                          />
                        </Td>
                        <Td borderColor="extsy.neutral900">
                          <Flex
                            w="90px"
                            h="24px"
                            align="center"
                            justify="center"
                            borderRadius="30px"
                            bg={`${getBgColor(trx?.status)}1A`}
                          >
                            <CustomText
                              text={trx.status}
                              size="10px"
                              color={getBgColor(trx?.status)}
                            />
                          </Flex>
                        </Td>
                      </Tr>
                    ))}
              </Tbody>
            </Table>
          </TableContainer>

          <Flex
            gap="16px"
            px="20px"
            py="14px"
            align="center"
            justify="flex-end"
          >
            {/* Showing range of items */}
            <CustomText
              text={`${offset + 1}-${Math.min(
                offset + limit,
                totalTransactions
              )} ${t("of")} ${totalTransactions}`}
              size="13px"
              weight="500"
              color="extsy.neutral300"
            />

            <Flex gap="8px">
              {/* Previous Page Button */}
              <Image
                src={ArrowBack}
                alt="arrow-back"
                cursor={currentPage > 1 ? "pointer" : "not-allowed"}
                opacity={currentPage > 1 ? 1 : 0.5}
                onClick={handlePrevPage}
              />

              {/* Page Numbers */}
              {[...Array(totalPages)].map((_, index) => (
                <Flex
                  key={index}
                  flexDir="column"
                  align="center"
                  justify="center"
                  bg={
                    currentPage === index + 1
                      ? "extsy.neutral900"
                      : "transparent"
                  }
                  borderRadius="6px"
                  height="30px"
                  width="30px"
                  cursor="pointer"
                  onClick={() => setOffset(index * limit)}
                >
                  <CustomText text={index + 1} size="14px" weight="500" />
                </Flex>
              ))}

              {/* Next Page Button */}
              <Image
                src={ArrowForward}
                alt="arrow-forward"
                cursor={currentPage < totalPages ? "pointer" : "not-allowed"}
                opacity={currentPage < totalPages ? 1 : 0.5}
                onClick={handleNextPage}
              />
            </Flex>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
}

export default RecentTransactions;

const LoadingRow = () => {
  return (
    <Tr>
      <Td
        height="54px"
        borderColor="extsy.neutral900"
        borderRight="1px solid"
        py="0"
        pos="relative"
        overflow="hidden"
      >
        <SkeletonText noOfLines={1} w="80px" />
      </Td>
      <Td borderColor="extsy.neutral900" borderRight="1px solid">
        <Flex align="center" gap="12px">
          <SkeletonText noOfLines={1} w="40px" />
        </Flex>
      </Td>
      <Td borderColor="extsy.neutral900" borderRight="1px solid">
        <SkeletonText noOfLines={1} w="80px" />
      </Td>
      <Td borderColor="extsy.neutral900" borderRight="1px solid">
        <SkeletonText noOfLines={1} w="80px" />
      </Td>

      <Td borderColor="extsy.neutral900" borderRight="1px solid">
        <SkeletonText noOfLines={1} w="80px" />
      </Td>
    </Tr>
  );
};

const formatDate = (dateString: any) => {
  const date = new Date(dateString);
  return date.toLocaleDateString("en-GB", {
    day: "numeric",
    month: "short",
    year: "numeric",
  });
};
