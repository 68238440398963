import { Flex } from "@chakra-ui/react";
import React, { useState } from "react";
import CustomText from "../../../components/text/CustomText";
import TableSearchInput from "../../../components/form/TableSearchInput";
import P2PBalanceTable from "./P2PBalanceTable";
import EstimateBalanceCardP2P from "../../../components/cards/EstimateBalanceCardP2P";
import { useMyP2PBalance } from "../../../utils/api/p2p.api";
import EmptyView from "../../transactionHistory/EmptyView";

function P2PBalance() {
  // const [search, setSearch] = useState("");
  const [offset, setOffset] = useState(0);
  const [limit] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [sortBy, setSortBy] = useState("");
  const [sortDirection, setSortDirection] = useState("ASC");
  const [searchTerm, setSearchTerm] = useState("");

  const { data: p2pBalanceData, isLoading: p2pBalanceLoading } =
    useMyP2PBalance(limit, pageCount, searchTerm, sortBy, sortDirection);

  return (
    <Flex flexDir="column" gap={{ base: "16px", lg: "40px" }}>
      <EstimateBalanceCardP2P />
      <Flex align="center" justify="space-between" gap="20px">
        <CustomText
          text="P2P Balance"
          size={{ base: "18px", lg: "24px" }}
          weight={{ base: "600", lg: "700" }}
          lh={{ lg: "34px" }}
          family="Metropolis"
          color="extsy.baseWhite"
          wrap="nowrap"
        />
        <TableSearchInput
          placeholder="Search"
          minW={{ base: "120px", lg: "250px" }}
          maxW={{ base: "140px", sm: "250px" }}
          value={searchTerm}
          onChange={(e: any) => setSearchTerm(e.target.value)}
        />
      </Flex>
      {!p2pBalanceData?.total && !p2pBalanceLoading ? (
        <EmptyView tagline="No Data Available" />
      ) : (
        <P2PBalanceTable
          p2pBalanceData={p2pBalanceData}
          isLoading={p2pBalanceLoading}
          sortBy={sortBy}
          sortDirection={sortDirection}
          setSortBy={setSortBy}
          setSortDirection={setSortDirection}
          offset={offset}
          setPageCount={setPageCount}
          limit={limit}
          pageCount={pageCount}
          setOffset={setOffset}
        />
      )}
    </Flex>
  );
}

export default P2PBalance;
