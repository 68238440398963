import { Box, Flex, useMediaQuery } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import CustomText from "../../components/text/CustomText";
import SocialLoginButton from "../../components/button/SocialLoginButton";
import GoogleIcon from "../../assets/images/google-ico.svg";
import AppleIcon from "../../assets/images/apple-ico.svg";
import CustomInput from "../../components/form/CustomInput";
import GradientText from "../../components/text/GradientText";
import PrimaryButton from "../../components/button/PrimaryButton";
import { SignUpPayload } from "../../utils/types/types";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useLoginWithGoogle, useSignUp } from "../../utils/api/auth.api";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { useGeeTest } from "react-geetest-v4";
import { IconEye, IconEyeOff } from "@tabler/icons-react";
import { useCustomAlert } from "../../utils/api/alert";
import { useQueryClient } from "@tanstack/react-query";
import { WHITE_LISTED_EMAILS } from "../../utils/constants/constants";

function SignUp() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const auth = getAuth();
  const googleProvider = new GoogleAuthProvider();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<SignUpPayload>();

  const userRegister = useSignUp();
  const googleLogin = useLoginWithGoogle();
  const alert = useCustomAlert();

  const [isLessThan768] = useMediaQuery("(max-height: 799px)");

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const { captcha, state } = useGeeTest("4cf4ef62613aca348306dce9ba7f62c7", {
    product: "bind",
    protocol: "https://",
    // containerId: 'geetest-captcha',
  });

  const handleUserRegistration = (payload: any) => {
    let captchaData: any = {};
    if (!WHITE_LISTED_EMAILS.includes(payload.email)) {
      captchaData = captcha ? captcha.getValidate() : {};
      if (!captchaData) {
        return;
      }
    }
    const formData = {
      ...payload,
      lot_number: captchaData?.lot_number || "",
      captcha_output: captchaData?.captcha_output || "",
      pass_token: captchaData?.pass_token || "",
      gen_time: captchaData?.gen_time || "",
      captcha_id: captchaData?.captcha_id || "",
      sign_token: captchaData?.sign_token || "",
    };
    console.log(formData);
    userRegister
      .mutateAsync(formData)
      .then((result) => {
        alert?.mutate({
          message: "User registered successfully",
          status: "success",
        });
        navigate(`/auth/verification-code?email=${payload.email}`);
        reset();
        captcha?.destroy();
      })
      .catch((error) => {
        alert?.mutate({
          message:
            error?.exception && Array.isArray(error.exception)
              ? error.exception[0]
              : error?.message,
          status: "danger",
        });
      });
  };

  const handleGoogleSignIn = () => {
    googleProvider?.setCustomParameters({
      prompt: "select_account", // Forces Google to prompt user to select an account
    });
    signInWithPopup(auth, googleProvider)
      .then(async (result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        // const credential = GoogleAuthProvider.credentialFromResult(result);
        // const token = credential?.accessToken;
        // The signed-in user info.

        const user: any = result.user;

        await googleLogin
          ?.mutateAsync({ token: user?.accessToken })
          .then((result) => {
            localStorage.setItem("token", result?.token?.access_token);
            queryClient.invalidateQueries({ queryKey: ["token"] });
            queryClient.invalidateQueries({ queryKey: ["getLoggedInUser"] });
            navigate("/");
          })
          .catch((error) => {
            alert?.mutate({
              message:
                error?.exception && Array.isArray(error.exception)
                  ? error.exception[0]
                  : error?.message,
              status: "danger",
            });
          });
      })
      .catch((error) => {
        if (
          error?.message?.includes("closed") ||
          error?.message?.includes("cancelled")
        ) {
          return;
        }
        alert?.mutate({
          message:
            error?.exception && Array.isArray(error.exception)
              ? error.exception[0]
              : error?.message,
          status: "danger",
        });
      });
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const referralCode = params.get("referralCode");

    // If referralCode exists, set it in the form input
    if (referralCode) {
      setValue("referral_code", referralCode);
    }
  }, [setValue]);

  // Ensure form is submitted only after CAPTCHA validation is successful
  const onSubmit = handleSubmit((data) => {
    handleUserRegistration(data);
  });

  useEffect(() => {
    if (state === "success") {
      onSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return (
    <Flex flexDir="column" gap="16px" mx="auto">
      <Flex
        px={{ base: "16px", md: "40px" }}
        py={isLessThan768 ? "24px" : "56px"}
        mx="auto"
        bg="rgba(255, 255, 255, 0.03)"
        borderRadius={{ base: "16px", md: "24px" }}
        maxW="480px"
        w="100%"
        flexDir="column"
        justify="space-between"
        gap="24px"
        minH={isLessThan768 ? "424px" : "742px"}
      >
        <Box>
          <Flex align="center" flexDir="column" gap="32px">
            <CustomText
              text={t("signUp")}
              family="Metropolis"
              size="24px"
              lh="32px"
              weight="700"
              align="center"
              color="extsy.baseWhite"
            />
            <CustomText
              text={t("joinExtsyPro")}
              align="center"
              color="extsy.neutral300"
            />
          </Flex>
          <Flex
            align="center"
            flexDir="column"
            mt={{ base: "24px", md: "40px" }}
            gap="16px"
          >
            <CustomText
              text={t("continueWith")}
              color="extsy.neutral500"
              size="14px"
            />
            <Flex align="center" gap="16px">
              <SocialLoginButton
                icon={GoogleIcon}
                title="Google"
                isLoading={googleLogin.isPending}
                onClick={handleGoogleSignIn}
              />
              <SocialLoginButton icon={AppleIcon} title="Apple" />
            </Flex>
            <Flex align="center" justify="center" gap="16px" w="100%">
              <Box bg="extsy.neutral900" height="1px" maxW="170px" w="100%" />
              <Box>
                <CustomText
                  text={t("or")}
                  size="14px"
                  lh="14px"
                  color="extsy.neutral500"
                  wrap="nowrap"
                  width="120px"
                />
              </Box>
              <Box bg="extsy.neutral900" height="1px" maxW="170px" w="100%" />
            </Flex>
          </Flex>
        </Box>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            if (WHITE_LISTED_EMAILS.includes(getValues("email"))) {
              onSubmit();
              return;
            }
            if (state !== "success") {
              captcha?.showCaptcha();
              onSubmit();
            } else onSubmit();
          }}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            gap: "24px",
          }}
        >
          <Box>
            <Flex flexDir="column" gap="24px">
              <Flex flexDir="column">
                <CustomText
                  text={t("name")}
                  color="extsy.neutral500"
                  size="12px"
                />
                <CustomInput
                  placeholder={t("enterName")}
                  type="text"
                  register={register}
                  name="fullname"
                  validation={{
                    required: true,
                  }}
                  invalid={errors.fullname}
                  errorMsg={errors.fullname && "Name is required"}
                />
              </Flex>
              <Flex flexDir="column">
                <CustomText
                  text={t("email")}
                  color="extsy.neutral500"
                  size="12px"
                />
                <CustomInput
                  placeholder={t("enteryourEmail")}
                  type="email"
                  register={register}
                  name="email"
                  validation={{
                    required: "Email is required",
                    pattern: {
                      value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                      message: "Email must be valid",
                    },
                  }}
                  invalid={errors.email}
                  errorMsg={errors.email && errors.email.message}
                />
              </Flex>
              <Flex flexDir="column">
                <CustomText
                  text={t("password")}
                  color="extsy.neutral500"
                  size="12px"
                />
                <CustomInput
                  placeholder="**************"
                  register={register}
                  name="password"
                  validation={{
                    required: {
                      value: true,
                      message: "Password is required",
                    },
                    minLength: {
                      value: 8,
                      message: "Password must be at least 8 characters",
                    },
                    pattern: {
                      value:
                        /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                      message:
                        "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character",
                    },
                  }}
                  type={isPasswordVisible ? "text" : "password"}
                  rightIco={
                    isPasswordVisible ? (
                      <IconEyeOff
                        onClick={() => {
                          setIsPasswordVisible(!isPasswordVisible);
                        }}
                        color="#fff"
                        cursor="pointer"
                      />
                    ) : (
                      <IconEye
                        onClick={() => {
                          setIsPasswordVisible(!isPasswordVisible);
                        }}
                        color="#fff"
                        cursor="pointer"
                      />
                    )
                  }
                  invalid={errors.password}
                  errorMsg={
                    errors.password
                      ? errors.password.type === "required"
                        ? "Password is required"
                        : errors.password.type === "minLength"
                        ? "Password must be at least 8 characters long"
                        : errors.password.type === "pattern"
                        ? "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
                        : ""
                      : ""
                  }
                />
              </Flex>
              <Flex flexDir="column">
                <CustomText
                  text={t("referralCode")}
                  color="extsy.neutral500"
                  size="12px"
                />
                <CustomInput
                  placeholder={t("enterReferralCode")}
                  type="text"
                  register={register}
                  name="referral_code"
                  invalid={errors.referral_code}
                  errorMsg={errors.referral_code && "Referral code is required"}
                />
              </Flex>
            </Flex>
          </Box>
          <Flex flexDir="column" gap="24px" align="center">
            <PrimaryButton
              title={t("signUp")}
              type="submit"
              isLoading={userRegister.isPending}
              // isDisabled={isDisabled}
            />
            <Flex align="center" gap="4px">
              <CustomText
                text={t("alreadyHaveAccount")}
                color="extsy.neutral300"
                lh="19px"
              />
              <GradientText
                text={t("signIn")}
                weight="600"
                bg="extsy.g1"
                lh={{ md: "22px" }}
                cursor="pointer"
                onClick={() => navigate("/auth/login")}
              />
            </Flex>
          </Flex>
        </form>
      </Flex>
    </Flex>
  );
}

export default SignUp;
