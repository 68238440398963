import {
  Box,
  Flex,
  Grid,
  GridItem,
  Image,
  Tab,
  Table,
  TableContainer,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React, { useState } from "react";
import CustomText from "../text/CustomText";
import BookIco from "../../assets/images/book-ico.svg";
import {
  IconArrowDown,
  IconCaretDownFilled,
  IconCheck,
  IconChevronDown,
  IconDots,
  IconSwitchHorizontal,
} from "@tabler/icons-react";
import TradingViewChart from "./TradingViewChart";
import OrderBookIco1 from "../../assets/images/order-book-ico1.svg";
import OrderBookIco2 from "../../assets/images/order-book-ico2.svg";
import OrderBookIco3 from "../../assets/images/order-book-ico3.svg";
import OpenOrders from "./OpenOrders";
import OrderHistory from "./OrderHistory";
import TradeHistory from "./TradeHistory";
import TradingInput from "../form/TradingInput";
import CustomTradingSlider from "../form/CustomTradingSlider";
import PrimaryButton from "../button/PrimaryButton";
import CustomButton from "../button/CustomButton";
import Collateral from "./Collateral";
import Leverage from "./Leverage";

function Future() {
  const [tabIndex, setTabIndex] = useState(0);
  const [activeTab, setActiveTab] = useState("Limit");
  const [activeMoreTab, setActiveMoreTab] = useState("Stop Limit");
  const [isMoreTab, setIsMoreTab] = useState(false);
  const [isCollateralOpen, setIsCollateralOpen] = useState(false);
  const [isLeverageOpen, setIsLeverageOpen] = useState(false);

  const TABS = [
    {
      title: "Open Orders",
      key: "openOrders",
      number: "3",
    },
    {
      title: "Order History",
      key: "orderHistory",
      number: "0",
    },
    {
      title: "Trade History",
      key: "tradeHistory",
    },
  ];
  const MORE_TABS = [
    "Stop Limit",
    "Stop Market",
    "Trailing Stop",
    "Conditional",
    "Post Only",
  ];
  return (
    <Grid
      gap="16px"
      templateRows="repeat(1, 1fr)"
      templateColumns="repeat(12, 1fr)"
      justifyContent="space-between"
    >
      <GridItem colSpan={{ base: 12, lg: 7 }}>
        <Flex
          flexDir="column"
          gap="16px"
          px={{ base: "4px", lg: "auto" }}
          ps={{ lg: "16px" }}
        >
          <Flex
            gap={{ base: "16px", lg: "24px", "2xl": "38px" }}
            align={{ lg: "center" }}
            flexDir={{
              base: "column",
              lg: "row",
            }}
          >
            <Flex
              py="12px"
              gap={{ base: "16px", "2xl": "28.6px" }}
              ps={{ lg: "8px" }}
              justify={{ base: "space-between", lg: "flex-start" }}
            >
              <Flex
                flexDir="column"
                gap="4px"
                pe="29.8px"
                borderRight="1px solid"
                borderColor="#252930"
              >
                <CustomText
                  text="BTC/USDT"
                  size="22.62px"
                  color="extsy.text2"
                />
                <Flex gap="6px" align="center">
                  <Image src={BookIco} alt="book" />
                  <CustomText
                    text="Bitcoin Price"
                    underline
                    color="extsy.neutral400"
                    cursor="pointer"
                  />
                </Flex>
              </Flex>
              <Flex flexDir="column" gap="4px">
                <CustomText
                  text="19,965.74"
                  size="18px"
                  lh="22px"
                  color="#F6465D"
                />
                <CustomText
                  text="₦9,236,949.95"
                  size="13px"
                  color="extsy.text2"
                />
              </Flex>
            </Flex>
            <Flex
              gap={{ base: "16px", "2xl": "25px" }}
              mt="-8px"
              wrap={{ base: "wrap", "2xl": "nowrap" }}
              ps={{ base: "16px", lg: "auto" }}
            >
              <Flex flexDir="column" gap="10px">
                <CustomText
                  text="24h Change"
                  size="13px"
                  lh="19px"
                  color="extsy.neutral400"
                />
                <Flex gap="5px">
                  <CustomText
                    text="-1,498.25"
                    size="13px"
                    color="extsy.danger"
                  />
                  <CustomText text="-6.98%" size="13px" color="extsy.danger" />
                </Flex>
              </Flex>
              <Flex flexDir="column" gap="10px">
                <CustomText
                  text="24h High"
                  size="13px"
                  lh="19px"
                  color="extsy.neutral400"
                />
                <CustomText text="21,491.86" size="13px" color="extsy.text2" />
              </Flex>
              <Flex flexDir="column" gap="10px">
                <CustomText
                  text="24h Low"
                  size="13px"
                  lh="19px"
                  color="extsy.neutral400"
                />
                <CustomText text="19,549.09" size="13px" color="extsy.text2" />
              </Flex>
              <Flex flexDir="column" gap="10px">
                <CustomText
                  text="24h Volume(BTC)"
                  size="13px"
                  lh="19px"
                  color="extsy.neutral400"
                />
                <CustomText text="715,559.40" size="13px" color="extsy.text2" />
              </Flex>
              <Flex flexDir="column" gap="10px">
                <CustomText
                  text="24h Volume(USDT)"
                  size="13px"
                  lh="19px"
                  color="extsy.neutral400"
                />
                <CustomText
                  text="14,430,472,197.94"
                  size="13px"
                  color="extsy.text2"
                />
              </Flex>
            </Flex>
          </Flex>
          <TradingViewChart />
        </Flex>
      </GridItem>
      <GridItem colSpan={{ base: 12, lg: 5 }}>
        <Flex gap="12px" pe={{ lg: "16px" }}>
          <Box width="100%">
            <Flex
              align="center"
              justify={{ lg: "flex-start", base: "center" }}
              gap="16px"
              pos="relative"
            >
              <Collateral
                isCollateralOpen={isCollateralOpen}
                setIsCollateralOpen={() => {
                  setIsLeverageOpen(false);
                  setIsCollateralOpen(!isCollateralOpen);
                  setIsMoreTab(false);
                }}
              />
              <Leverage
                isLeverageOpen={isLeverageOpen}
                setIsLeverageOpen={() => {
                  setIsMoreTab(false);
                  setIsCollateralOpen(false);
                  setIsLeverageOpen(!isLeverageOpen);
                }}
              />
            </Flex>
            <Flex
              justify={{ base: "space-between", lg: "auto" }}
              align="center"
              borderBottom="1px solid"
              borderColor="extsy.neutral900"
            >
              <Box
                p={{ base: "14px", lg: "16px" }}
                borderBottom="2px solid"
                borderColor={
                  activeTab === "Limit" ? "extsy.primary100" : "transparent"
                }
                onClick={() => setActiveTab("Limit")}
              >
                <CustomText
                  text="Limit"
                  size="14px"
                  lh="20px"
                  cursor="pointer"
                  color={
                    activeTab === "Limit"
                      ? "extsy.baseWhite"
                      : "extsy.neutral300"
                  }
                />
              </Box>
              <Box
                p="16px 30px"
                borderBottom="2px solid"
                borderColor={
                  activeTab === "Market" ? "extsy.primary100" : "transparent"
                }
                onClick={() => setActiveTab("Market")}
              >
                <CustomText
                  text="Market"
                  size="14px"
                  lh="20px"
                  cursor="pointer"
                  color={
                    activeTab === "Market"
                      ? "extsy.baseWhite"
                      : "extsy.neutral300"
                  }
                />
              </Box>
              <Flex
                p="16px 30px"
                borderBottom="2px solid"
                borderColor={
                  activeTab === activeMoreTab
                    ? "extsy.primary100"
                    : "transparent"
                }
                align="center"
                gap="12px"
                cursor="pointer"
                onClick={() => {
                  setIsMoreTab(!isMoreTab);
                  if (!isMoreTab) {
                    setActiveTab(activeMoreTab);
                  }
                }}
                pos="relative"
              >
                <CustomText
                  text={activeMoreTab}
                  size="14px"
                  lh="20px"
                  cursor="pointer"
                  wrap="nowrap"
                  color={
                    activeTab === activeMoreTab
                      ? "extsy.baseWhite"
                      : "extsy.neutral300"
                  }
                />
                <IconChevronDown size="18px" color="#fafafa" cursor="pointer" />
                <Flex
                  p="16px"
                  gap="16px"
                  flexDir="column"
                  bg="extsy.neutral900"
                  border="1px solid"
                  borderColor="extsy.neutral800"
                  borderRadius="16px"
                  pos="absolute"
                  top="0"
                  right="0"
                  mt="45px"
                  mr="30px"
                  minW="162px"
                  zIndex="150"
                  display={isMoreTab ? "flex" : "none"}
                >
                  {MORE_TABS?.map((tab, index) => (
                    <Flex
                      gap="10px"
                      justify="space-between"
                      align="center"
                      cursor="pointer"
                    >
                      <CustomText
                        key={index}
                        text={tab}
                        size="14px"
                        lh="20px"
                        cursor="pointer"
                        color={activeMoreTab === tab ? "extsy.baseWhite" : ""}
                        onClick={() => {
                          setActiveTab(tab);
                          setActiveMoreTab(tab);
                          setIsMoreTab(false);
                        }}
                      />
                      {activeMoreTab === tab && (
                        <IconCheck
                          size="18px"
                          color="#fafafa"
                          cursor="pointer"
                        />
                      )}
                    </Flex>
                  ))}
                </Flex>
              </Flex>
            </Flex>
            <Flex
              p={{ base: "24px 16px", lg: "24px 16px 24px 0px" }}
              flexDir="column"
              gap="16px"
            >
              {activeTab === "Trailing Stop" && (
                <Flex gap="8px" align="center">
                  <TradingInput label="Callback Rate" currency="%" />
                  <CustomFlex text="1%" />
                  <CustomFlex text="3%" />
                </Flex>
              )}
              {(activeTab === "Stop Limit" ||
                activeTab === "Stop Market" ||
                activeTab === "Trailing Stop" ||
                activeTab === "Conditional") && (
                <TradingInput
                  label="Stop Price"
                  currency="Mark"
                  isCurrnecySelect
                />
              )}
              {activeTab === "Conditional" && (
                <Flex gap="8px" align="center">
                  <TradingInput label="Price" currency="USDT" />
                  <CustomFlex text="Limit" />
                </Flex>
              )}
              {(activeTab === "Limit" ||
                activeTab === "Market" ||
                activeTab === "Stop Limit") && (
                <Flex gap="8px" align="center">
                  <TradingInput label="Price" currency="USDT" />
                  <CustomFlex text="BBO" />
                </Flex>
              )}
              {activeTab === "Post Only" && (
                <TradingInput label="Price" currency="USDT" />
              )}
              <TradingInput label="Size" currency="USDT" isCurrnecySelect />
              <Box mt="8px">
                <CustomTradingSlider />
              </Box>
              <Flex justify="space-between" align="center" gap="24px">
                <Flex align="center" gap="8px">
                  <CustomText
                    text="Max Buy"
                    color="extsy.neutral400"
                    size="12px"
                  />
                  <CustomText text="0 BTC" color="extsy.text2" size="12px" />
                </Flex>
                <Flex align="center" gap="8px">
                  <CustomText
                    text="Max Sell"
                    color="extsy.neutral400"
                    size="12px"
                  />
                  <CustomText text="0 BTC" color="extsy.text2" size="12px" />
                </Flex>
              </Flex>
              <Flex align="center" gap="10px" mt="8px">
                <PrimaryButton title="Buy" height="38px" />
                <CustomButton
                  title="Sell"
                  height="38px"
                  bg="extsy.neutral900"
                  color="extsy.baseWhite"
                />
              </Flex>
            </Flex>
            <Flex
              gap="10px"
              pe={{ lg: "16px" }}
              px={{ base: "16px", lg: "0px" }}
              flexDir="column"
            >
              <Flex justify="space-between" align="center">
                <CustomText
                  text="Account"
                  color="extsy.neutral400"
                  size="13px"
                />
                <Flex
                  gap="10px"
                  align="center"
                  borderRadius="8px"
                  bg="extsy.neutral900"
                  p="8px"
                  cursor="pointer"
                >
                  <CustomText
                    text="Switch"
                    size="13px"
                    cursor="pointer"
                    color="extsy.baseWhite"
                  />
                  <IconSwitchHorizontal size="12px" color="#EFA3D1" />
                </Flex>
              </Flex>
              <Flex flexDir="column" gap="12px" py="16px">
                <Flex justify="space-between" gap="12px" align="center">
                  <CustomText
                    text="Margin Ratio"
                    color="extsy.neutral400"
                    size="12px"
                  />
                  <CustomText text="0.00%" color="extsy.text2" size="12px" />
                </Flex>
                <Flex justify="space-between" gap="12px" align="center">
                  <CustomText
                    text="Maintenance Margin"
                    color="extsy.neutral400"
                    size="12px"
                  />
                  <CustomText text="0 BTC" color="extsy.text2" size="12px" />
                </Flex>
                <Flex justify="space-between" gap="12px" align="center">
                  <CustomText
                    text="Margin Balance"
                    color="extsy.neutral400"
                    size="12px"
                  />
                  <CustomText text="0 BTC" color="extsy.text2" size="12px" />
                </Flex>
                <CustomButton
                  title="Single-Asset Mode"
                  height="30px"
                  bg="extsy.neutral900"
                  color="extsy.baseWhite"
                  size="12px"
                />
              </Flex>
              <Flex gap="6px" align="center">
                <CustomText text="USDT" color="extsy.neutral400" size="14px" />
                <IconCaretDownFilled color="#EFA3D1" size="18px" />
              </Flex>
              <Flex align="center" gap="8px">
                <CustomButton
                  title="Buy Crypto"
                  height="30px"
                  bg="extsy.neutral900"
                  color="extsy.baseWhite"
                  size="12px"
                />
                <CustomButton
                  title="Swap"
                  height="30px"
                  bg="extsy.neutral900"
                  color="extsy.baseWhite"
                  size="12px"
                />
                <CustomButton
                  title="Transfer"
                  height="30px"
                  bg="extsy.neutral900"
                  color="extsy.baseWhite"
                  size="12px"
                />
              </Flex>
            </Flex>
          </Box>
        </Flex>
      </GridItem>
      <GridItem colSpan={12}>
        <Flex px="16px" gap="24px" flexDir={{ base: "column", lg: "row" }}>
          <Box w="100%">
            <Flex justify="space-between" align="center" pe="16px">
              <CustomText
                text="Order Book"
                color="extsy.text2"
                size="15px"
                weight="700"
              />
              <IconDots color="#3D4653" size="20px" />
            </Flex>
            <Flex
              mt="16px"
              justify="space-between"
              gap="19px"
              align="center"
              pe="16px"
            >
              <Flex gap="19px">
                <Image src={OrderBookIco1} alt="ico" />
                <Image src={OrderBookIco2} alt="ico" />
                <Image src={OrderBookIco3} alt="ico" />
              </Flex>
              <Flex align="center" gap="6px">
                <CustomText text="0.01" size="13px" color="extsy.text2" />
                <IconCaretDownFilled color="#848E9C" size="19px" />
              </Flex>
            </Flex>
            <TableContainer mt="11px" pe="16px">
              <Table size="sm">
                <Thead>
                  <Tr>
                    <Th
                      minW={{ base: "70px", lg: "125px" }}
                      border="none"
                      ps="0"
                    >
                      <CustomText
                        text="Price(USDT)"
                        size="13px"
                        color="extsy.neutral400"
                      />
                    </Th>
                    <Th border="none" minW="90px">
                      <CustomText
                        text="Amount(BTC)"
                        size="13px"
                        color="extsy.neutral400"
                        align="end"
                      />
                    </Th>
                    <Th border="none" p="0" minW="80px">
                      <CustomText
                        text="Total"
                        size="13px"
                        color="extsy.neutral400"
                        align="end"
                      />
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {new Array(4)?.fill(1).map((pair, index) => (
                    <Tr key={index}>
                      <Td
                        h="23px"
                        minW={{ base: "70px", lg: "125px" }}
                        border="none"
                        p="0"
                      >
                        <Flex align="center" gap="2px">
                          <CustomText
                            text={"19,965.74"}
                            size="13px"
                            color="extsy.danger"
                          />
                        </Flex>
                      </Td>
                      <Td border="none" minW="90px">
                        <Flex align="center" gap="2px" justify="end">
                          <CustomText
                            text={"0.13624"}
                            size="13px"
                            color="extsy.text4"
                          />
                        </Flex>
                      </Td>
                      <Td border="none" p="0" minW="80px">
                        <Flex align="center" gap="2px" justify="end">
                          <CustomText
                            text={"19,965.74"}
                            size="13px"
                            color="extsy.text4"
                          />
                        </Flex>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
            <Flex
              justify="space-between"
              align="center"
              gap="19px"
              py="13px"
              pe="16px"
            >
              <Flex gap="6px" align="center">
                <CustomText text="19,965.74" size="22px" color="extsy.danger" />
                <IconArrowDown color="#F6465D" size="19px" />
                <CustomText
                  text="₦9,236,949.95"
                  size="13px"
                  color="extsy.neutral400"
                />
              </Flex>
              <CustomText text="More" size="14px" color="extsy.neutral400" />
            </Flex>
            <TableContainer pe="16px">
              <Table size="sm">
                <Tbody>
                  {new Array(7)?.fill(1).map((pair, index) => (
                    <Tr key={index}>
                      <Td
                        h="23px"
                        minW={{ base: "70px", lg: "125px" }}
                        border="none"
                        p="0"
                      >
                        <Flex align="center" gap="2px">
                          <CustomText
                            text={"19,965.74"}
                            size="13px"
                            color="#0ECB81"
                          />
                        </Flex>
                      </Td>
                      <Td border="none" minW="90px">
                        <Flex align="center" gap="2px" justify="end">
                          <CustomText
                            text={"0.13624"}
                            size="13px"
                            color="extsy.text4"
                          />
                        </Flex>
                      </Td>
                      <Td border="none" p="0" minW="80px">
                        <Flex align="center" gap="2px" justify="end">
                          <CustomText
                            text={"19,965.74"}
                            size="13px"
                            color="extsy.text4"
                          />
                        </Flex>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
          <Box w="100%">
            <Box
              p="16px"
              bg="extsy.dark100"
              ml="-16px"
              borderTop="1px solid"
              borderBottom="1px solid"
              borderColor="#313131"
            >
              <CustomText
                text="Trades"
                size="15px"
                weight="700"
                color="extsy.text2"
              />
            </Box>
            <TableContainer mt="11px" pe="16px">
              <Table size="sm">
                <Thead>
                  <Tr>
                    <Th
                      minW={{ base: "70px", lg: "125px" }}
                      border="none"
                      ps="0"
                    >
                      <CustomText
                        text="Price(USDT)"
                        size="13px"
                        color="extsy.neutral400"
                      />
                    </Th>
                    <Th border="none" minW="90px">
                      <CustomText
                        text="Amount(BTC)"
                        size="13px"
                        color="extsy.neutral400"
                        align="end"
                      />
                    </Th>
                    <Th border="none" p="0" minW="80px">
                      <CustomText
                        text="Time"
                        size="13px"
                        color="extsy.neutral400"
                        align="end"
                      />
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {new Array(13)?.fill(1).map((pair, index) => (
                    <Tr key={index}>
                      <Td
                        h="23px"
                        minW={{ base: "70px", lg: "125px" }}
                        border="none"
                        p="0"
                      >
                        <Flex align="center" gap="2px">
                          <CustomText
                            text={"19,965.74"}
                            size="13px"
                            color="#0ECB81"
                          />
                        </Flex>
                      </Td>
                      <Td border="none" minW="90px">
                        <Flex align="center" gap="2px" justify="end">
                          <CustomText
                            text={"0.13624"}
                            size="13px"
                            color="extsy.text4"
                          />
                        </Flex>
                      </Td>
                      <Td border="none" p="0" minW="80px">
                        <Flex align="center" gap="2px" justify="end">
                          <CustomText
                            text={"18:43:07"}
                            size="13px"
                            color="extsy.text4"
                          />
                        </Flex>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
        </Flex>
      </GridItem>
      <GridItem colSpan={12}>
        <Tabs
          onChange={(index) => setTabIndex(index)}
          defaultIndex={0}
          borderBottomRadius="16px"
        >
          <TabList borderBottom="1px solid" borderColor="extsy.neutral900">
            {TABS?.map((tab, index) => (
              <Tab
                key={index}
                color="#F0B90B"
                display="flex"
                gap="4px"
                px={{ base: "6px", sm: "16px" }}
              >
                <CustomText
                  text={tab?.title}
                  size={{
                    base: "14px",
                    lg: index === tabIndex ? "16px" : "15px",
                  }}
                  weight="400"
                  color={
                    index === tabIndex ? "extsy.white" : "extsy.neutral400"
                  }
                  cursor="pointer"
                />
                {tab?.number && (
                  <CustomText
                    text={`(${tab?.number || 0})`}
                    size={{ base: "14px", lg: "16px" }}
                    weight="400"
                    color={
                      index === tabIndex ? "extsy.white" : "extsy.neutral400"
                    }
                  />
                )}
              </Tab>
            ))}
          </TabList>
          <TabPanels>
            <TabPanel>
              <OpenOrders />
            </TabPanel>
            <TabPanel>
              <OrderHistory />
            </TabPanel>
            <TabPanel>
              <TradeHistory />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </GridItem>
    </Grid>
  );
}

const CustomFlex = ({ text }: any) => (
  <Flex
    p="8px 12px"
    cursor="pointer"
    bg="extsy.dark600"
    height="48px"
    borderRadius="4px"
    align="center"
    justify="center"
  >
    <CustomText text={text} cursor="pointer" size="13px" color="extsy.text2" />
  </Flex>
);

export default Future;
