import { Flex } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import CustomText from "../../components/text/CustomText";

import CustomInput from "../../components/form/CustomInput";
import PrimaryButton from "../../components/button/PrimaryButton";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useResetPassword } from "../../utils/api/auth.api";
import { IconEye, IconEyeOff } from "@tabler/icons-react";
import { useCustomAlert } from "../../utils/api/alert";

function UpdatePassword() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  const resetPassword = useResetPassword();
  const alert = useCustomAlert();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const [isVisible, setIsVisible] = useState({
    password: false,
    confirmPassword: false,
  });

  const handleSetPassowrd = (payload: any) => {
    if (payload.password !== payload.confirmPassword) {
      alert?.mutate({
        message: "Password and confirm password must be same",
        status: "danger",
      });
      return;
    }

    resetPassword
      .mutateAsync({ password: payload.password, token })
      .then((result) => {
        alert?.mutate({
          message: result?.message,
          status: "success",
        });
        navigate("/auth/login");
        reset();
      })
      .catch((error) => {
        alert?.mutate({
          message:
            error?.exception && Array.isArray(error.exception)
              ? error.exception[0]
              : error?.message,
          status: "danger",
        });
      });
  };

  useEffect(() => {
    if (!token) {
      navigate("/auth/forgot-password");
    }
  }, [token, navigate]);

  return (
    <form
      onSubmit={handleSubmit((data) => {
        handleSetPassowrd(data);
      })}
    >
      <Flex flexDir="column" gap="16px">
        <Flex w="100%" flexDir={"column"} gap={"64px"}>
          <Flex align="center" flexDir="column" gap="32px">
            <CustomText
              text={t("Add New Password")}
              family="Metropolis"
              size="24px"
              lh="24px"
              weight="400"
              align="center"
              color="extsy.baseWhite"
            />
            <CustomText
              text={t(
                "Choose a strong & secure alpha-numeric password with at least one uppercase letter, one special character, and a minimum length of 8 characters."
              )}
              align="center"
              color="extsy.neutral300"
              lh="19px"
            />
          </Flex>
          <Flex flexDir="column" gap="24px">
            <Flex flexDir="column">
              <CustomText
                text={t("New Password")}
                color="extsy.neutral500"
                size="12px"
              />
              <CustomInput
                placeholder="**************"
                register={register}
                name="password"
                invalid={errors.password}
                validation={{
                  required: {
                    value: true,
                    message: "Password is required",
                  },
                  minLength: {
                    value: 8,
                    message: "Password must be at least 8 characters",
                  },
                  pattern: {
                    value:
                      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                    message:
                      "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character",
                  },
                }}
                errorMsg={errors.password?.message}
                type={isVisible?.password ? "text" : "password"}
                rightIco={
                  isVisible?.password ? (
                    <IconEyeOff
                      onClick={() => {
                        setIsVisible({
                          ...isVisible,
                          password: !isVisible.password,
                        });
                      }}
                      color="#fff"
                      cursor="pointer"
                    />
                  ) : (
                    <IconEye
                      onClick={() => {
                        setIsVisible({
                          ...isVisible,
                          password: !isVisible.password,
                        });
                      }}
                      color="#fff"
                      cursor="pointer"
                    />
                  )
                }
              />
            </Flex>
            <Flex flexDir="column">
              <CustomText
                text={t("Repeat Password")}
                color="extsy.neutral500"
                size="12px"
              />
              <CustomInput
                placeholder="**************"
                register={register}
                name="confirmPassword"
                invalid={errors.confirmPassword}
                validation={{
                  required: {
                    value: true,
                    message: "Password is required",
                  },
                  minLength: {
                    value: 8,
                    message: "Password must be at least 8 characters",
                  },
                  pattern: {
                    value:
                      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                    message:
                      "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character",
                  },
                }}
                errorMsg={errors.confirmPassword?.message}
                type={isVisible?.confirmPassword ? "text" : "password"}
                rightIco={
                  isVisible?.confirmPassword ? (
                    <IconEyeOff
                      onClick={() => {
                        setIsVisible({
                          ...isVisible,
                          confirmPassword: !isVisible.confirmPassword,
                        });
                      }}
                      color="#fff"
                      cursor="pointer"
                    />
                  ) : (
                    <IconEye
                      onClick={() => {
                        setIsVisible({
                          ...isVisible,
                          confirmPassword: !isVisible.confirmPassword,
                        });
                      }}
                      color="#fff"
                      cursor="pointer"
                    />
                  )
                }
              />
            </Flex>
          </Flex>
          <Flex flexDir="column" gap="24px" align="center">
            <PrimaryButton
              title={t("Update Password")}
              type="submit"
              isLoading={resetPassword?.isPending}
            />
          </Flex>
        </Flex>
      </Flex>
    </form>
  );
}

export default UpdatePassword;
