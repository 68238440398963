import { Box, Flex, Grid, GridItem, Image } from "@chakra-ui/react";
import React, { useState } from "react";
import PlanCard from "../../components/cards/PlanCard";
import ExtsyStats from "../../components/cards/ExtsyStats";
import RequestPayment from "../../components/cards/RequestPayment";
import CustomText from "../../components/text/CustomText";
import TableSearchInput from "../../components/form/TableSearchInput";
import EmptyTableIco from "../../assets/images/empty-trx-ico.svg";
import FAQs from "../../components/cards/FAQs";
import { useGetSubscriptionPlans } from "../../utils/api/settings.api";
import PlanCardSkeleton from "../../components/skeletons/PlanCardSkeleton";
import { useQuery } from "@tanstack/react-query";
import { useGetUserPayouts } from "../../utils/api/dashboard.api";
import PayoutHistoryTable from "./PayoutHistoryTable";

function ExtsyPro() {
  const { data: subscriptionPlans, isLoading: plansLoading } =
    useGetSubscriptionPlans();
  const { data: user, isLoading: userLoading }: any = useQuery({
    queryKey: ["getLoggedInUser"],
  });

  const [offset, setOffset] = useState(0);
  const [limit] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [sortingKey, setSortingKey] = useState("");
  const [sortingOrder, setSortingOrder] = useState("ASC");
  const [search, setSearch] = useState("");

  const { data: payoutData, isLoading: payoutLoading } = useGetUserPayouts(
    limit,
    offset,
    "",
    search,
    sortingKey,
    sortingOrder
  );

  return (
    <Flex flexDir="column" gap={{ base: "16px", lg: "24px" }} mt="24px">
      <Flex
        gap={{ base: "16px", lg: "24px" }}
        wrap={{ base: "wrap", lg: "nowrap" }}
      >
        {plansLoading || userLoading
          ? new Array(3)
              ?.fill(1)
              ?.map((_, idx) => <PlanCardSkeleton key={idx} />)
          : subscriptionPlans?.map((plan: any, idx: number) => (
              <PlanCard key={idx} plan={plan} user={user} />
            ))}
      </Flex>
      <ExtsyStats />
      <Grid
        gap={{ base: "16px", lg: "24px" }}
        templateRows="repeat(1, 1fr)"
        templateColumns="repeat(12, 1fr)"
      >
        <GridItem colSpan={{ base: 12, lg: 5 }}>
          <RequestPayment />
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 7 }}>
          <Box
            p={{ base: "16px", lg: "24px" }}
            borderRadius={{ base: "16px", lg: "24px" }}
            bg="extsy.neutral1000"
            boxShadow="0px 3px 4px 0px rgba(0, 0, 0, 0.03)"
            height="100%"
          >
            <Flex align="center" justify="space-between">
              <CustomText
                text="Payout History"
                color="extsy.baseWhite"
                size={{ base: "18px", lg: "24px" }}
                weight={{ base: "600", lg: "700" }}
                family="Metropolis"
                lh="32px"
              />
              <TableSearchInput
                placeholder="Search Your Assets"
                minW={{ base: "168px", lg: "250px" }}
                maxW={{ base: "168px", lg: "250px" }}
                value={search}
                onChange={(e: any) => setSearch(e.target.value)}
              />
            </Flex>
            <Box mt={{ base: "16px", lg: "40px" }}>
              {payoutData?.data?.length <= 0 ? (
                <Flex
                  flexDir="column"
                  align="center"
                  justify="center"
                  gap="8px"
                  h="100%"
                  minH="326px"
                >
                  <Image
                    src={EmptyTableIco}
                    alt="empty"
                    height="32px"
                    width="30.43px"
                  />
                  <CustomText
                    text="No History Available"
                    size="14px"
                    color="extsy.neutral400"
                  />
                </Flex>
              ) : (
                <PayoutHistoryTable
                  data={payoutData}
                  isLoading={payoutLoading}
                  sortDirection={sortingOrder}
                  setSortBy={setSortingKey}
                  setSortDirection={setSortingOrder}
                  offset={offset}
                  setPageCount={setPageCount}
                  limit={limit}
                  pageCount={pageCount}
                  setOffset={setOffset}
                />
              )}
            </Box>
          </Box>
        </GridItem>
      </Grid>
      <FAQs />
    </Flex>
  );
}

export default ExtsyPro;
