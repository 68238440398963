import { Flex, Image, Stack } from "@chakra-ui/react";
import React from "react";
import CustomText from "../../../components/text/CustomText";
import Logo from "../../../assets/images/logo-icon.svg";

const HistorySection = () => {
  return (
    <Stack align={"flex-start"} gap={"24px"}>
      <CustomText text="Appeal History" color="#FAFAFA" />
      <Stack
        w={{ base: "100%", lg: "809px" }}
        p={"16px"}
        gap={"10px"}
        borderRadius={"16px"}
        bg="#383434"
      >
        <CustomText
          text="Oct 1, 2024 10:27 AM"
          color="#BAB8B8"
          size="10px"
          family="Inter"
          weight="500"
          lh="12px"
        />
        <Flex align={"center"} gap={"8px"}>
          <Image src={Logo} alt="backBtn" w={"32px"} h={"32px"} />
          <CustomText
            text="Extsy Support System"
            color="#FAFAFA"
            weight="500"
          />
        </Flex>
        <CustomText
          text="Dear customers we have cancelled the order. Thank you for understanding."
          color="#BAB8B8"
          size="12px"
          lh="130%"
        />
      </Stack>
    </Stack>
  );
};

export default HistorySection;
