import {
  Box,
  Flex,
  Image,
  SkeletonText,
  Slider,
  SliderFilledTrack,
  SliderTrack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import CustomText from "../../components/text/CustomText";
import SupremeIco from "../../assets/images/superme-ico.svg";
import QuantlaneIco from "../../assets/images/quantlane-ico.svg";
import PrimeIco from "../../assets/images/prime-ico.svg";
import { useGetAmlCheckCount } from "../../utils/api/dashboard.api";
import { useGetLoggedInUser } from "../../utils/api/auth.api";
import { useGetSubscriptionPlans } from "../../utils/api/settings.api";

function CheckCounter() {
  const { data: subscriptionPlans } = useGetSubscriptionPlans();
  const { data, isLoading } = useGetAmlCheckCount();
  const { data: loggedInUserData } = useGetLoggedInUser();
  const [, setPaymentInfoIntent] = useState<any>(null);
  const [, setId] = useState("");
  const [nextPlan, setNextPlan] = useState<any>(null);

  const getPlanByName = (name: any) => {
    return subscriptionPlans?.find((plan: any) => plan?.name === name);
  };

  useEffect(() => {
    if (!loggedInUserData || !subscriptionPlans) return; // Exit if data is not loaded

    const currentPlanName = loggedInUserData?.user_subscription?.plan?.name;
    if (currentPlanName === "Standard") {
      setNextPlan(getPlanByName("Premium"));
    } else if (currentPlanName === "Premium") {
      setNextPlan(getPlanByName("Ultra"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedInUserData, subscriptionPlans]); // Add dependencies here

  useEffect(() => {
    if (nextPlan) {
      setId(nextPlan?.id);
    }
  }, [nextPlan]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const paymentInfo = window?.localStorage?.getItem("paymentInfo");
      if (paymentInfo) {
        const payment = JSON.parse(paymentInfo);
        setPaymentInfoIntent(payment);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Flex
      minH={{ lg: "406px" }}
      width="100%"
      p={{ base: "16px", lg: "24px" }}
      flexDir="column"
      gap={{ base: "16px", lg: "40px" }}
      justify="space-between"
      borderRadius={{ base: "16px", lg: "24px" }}
      border="1px solid"
      borderColor="extsy.neutral900"
      bg="extsy.neutral1000"
      pos="relative"
      overflow="hidden"
    >
      <Flex gap={{ base: "16px", lg: "40px" }} flexDir="column" zIndex="100">
        <CustomText
          text="Check Counter"
          size={{ base: "18px", lg: "24px" }}
          weight={{ base: "600", lg: "700" }}
          family="Metropolis"
          color="extsy.baseWhite"
        />
        <Flex flexDir="column" gap="16px">
          <CustomText
            text="Your current Level"
            size="14px"
            color="extsy.baseWhite"
          />
          <Flex
            display={!loggedInUserData?.user_subscription ? "flex" : "none"}
          >
            <CustomText
              text="Free"
              size={{ base: "14px", lg: "18px" }}
              color="extsy.baseWhite"
            />
          </Flex>
          <Flex
            gap="12px"
            cursor="pointer"
            px="16px"
            height={{ base: "38px", lg: "56px" }}
            w={"fit-content"}
            align="center"
            justify="center"
            borderRadius="99px"
            bg="rgba(250, 250, 250, 0.10)"
            display={loggedInUserData?.user_subscription ? "flex" : "none"}
          >
            <Image
              src={
                loggedInUserData?.user_subscription?.plan?.name === "Standard"
                  ? PrimeIco
                  : loggedInUserData?.user_subscription?.plan?.name ===
                    "Premium"
                  ? SupremeIco
                  : QuantlaneIco
              }
              alt="prime ico"
              height={{ lg: "20px" }}
              width={{ lg: "23px" }}
            />
            {isLoading ? (
              <SkeletonText noOfLines={1} w={"60px"} />
            ) : (
              <CustomText
                text={loggedInUserData?.user_subscription?.plan?.name}
                size={{ lg: "18px" }}
                color="extsy.baseWhite"
              />
            )}
          </Flex>
          <Box display={loggedInUserData?.user_subscription ? "block" : "none"}>
            <Box mb="16px">
              <CustomText
                text="Check Counter"
                size="12px"
                color="extsy.baseWhite"
                family="Metropolis"
              />
            </Box>
            <CustomText
              text={
                <>
                  {isLoading ? (
                    <SkeletonText w={2} h={2} />
                  ) : (
                    <span
                      style={{
                        fontWeight: 600,
                        color: "#fafafa",
                      }}
                    >
                      {data?.totalAmlChecksThisMonth}{" "}
                    </span>
                  )}
                  <span>
                    check of{" "}
                    {loggedInUserData?.user_subscription?.plan?.name ===
                    "Standard"
                      ? "5"
                      : loggedInUserData?.user_subscription?.plan?.name ===
                        "Premium"
                      ? "100"
                      : "Unlimited"}
                  </span>
                </>
              }
              size="16px"
            />
            <Slider
              value={5}
              max={
                loggedInUserData?.user_subscription?.plan?.name === "Standard"
                  ? 5
                  : loggedInUserData?.user_subscription?.plan?.name ===
                    "Premium"
                  ? 100
                  : Number.MAX_SAFE_INTEGER
              }
            >
              <SliderTrack
                height="8px"
                bg="extsy.neutral700"
                borderRadius="99px"
              >
                <SliderFilledTrack bg="extsy.baseWhite" />
              </SliderTrack>
            </Slider>
          </Box>
        </Flex>
      </Flex>

      <Box
        height="447px"
        width="447px"
        borderRadius="447px"
        opacity="0.5"
        bg="extsy.primary500"
        filter="blur(82px)"
        pos="absolute"
        top="0"
        right="0"
        zIndex="1"
        mt="-340px"
        mr="-258px"
      />
    </Flex>
  );
}

export default CheckCounter;
