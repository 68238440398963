import { Center, Flex, Image, Stack } from "@chakra-ui/react";
import React from "react";
import CustomText from "../../../components/text/CustomText";
import { StatusCardProps } from "../../../utils/types/types";
import GreaterThan from "../../../assets/images/greater-than-filled.svg";

const AppealStepSection = () => {
  return (
    <Stack
      align={"flex-start"}
      gap={"40px"}
      flex={"1 0 0"}
      alignSelf={"stretch"}
    >
      <Center
        alignSelf={"stretch"}
        alignItems={{ md: "flex-start" }}
        justifyContent={"space-between"}
        gap={{ base: "16px", md: "none" }}
        flexDirection={{ base: "column", sm: "row" }}
        display={{ base: "none", xl: "flex" }}
      >
        <StatusCard
          text="1"
          text2="Appeal Submitted"
          status="Completed"
          stackWidth={{ base: "90px", md: "90px" }}
        />
        <Center w={{ base: "55px", md: "110px" }} p={"17px"}>
          <Image src={GreaterThan} alt="next" />
        </Center>

        <StatusCard
          stackWidth={{ base: "90px", md: "359px" }}
          centerBg="#EFA3D1"
          text="2"
          text2="Negotiate with counter party" // If you need a second text, modify the component to accept it
          status="01:20:27"
          statusBg="#4D3A24"
          statusColor="#FFA333"
        />
        <Center w={"55px"} p={"17px"}>
          <Image src={GreaterThan} alt="next" />
        </Center>

        <StatusCard
          stackWidth={{ base: "90px", md: "367px" }}
          centerBg="#383434"
          text="3"
          textColor="#BAB8B8"
          text2="CS Review" // If you need a second text, modify the component to accept it
          text2Color="#7A7474"
        />
        <Center w={"55px"} p={"17px"}>
          <Image src={GreaterThan} alt="next" />
        </Center>
        <StatusCard
          stackWidth={{ base: "90px", md: "116px" }}
          centerBg="#383434"
          text="4"
          textColor="#BAB8B8"
          text2="Appeal complete" // If you need a second text, modify the component to accept it
          text2Color="#7A7474"
        />
      </Center>
      <Flex
        alignSelf={"stretch"}
        gap={{ base: "16px", md: "none" }}
        // flexDirection={{ base: "column", sm: "row" }}
        flexWrap={"wrap"}
        align={"flex-start"}
        display={{ base: "flex", xl: "none" }}
      >
        <Flex align={"flex-start"}>
          <StatusCard
            text="1"
            text2="Appeal Submitted"
            status="Completed"
            stackWidth={"90px"}
          />
          <Center p={"17px"}>
            <Image src={GreaterThan} alt="next" />
          </Center>

          <StatusCard
            stackWidth={{ base: "90px", md: "150px", lg: "200px" }}
            centerBg="#EFA3D1"
            text="2"
            text2="Negotiate with counter party" // If you need a second text, modify the component to accept it
            status="01:20:27"
            statusBg="#4D3A24"
            statusColor="#FFA333"
          />
        </Flex>
        <Flex>
          <Center p={"17px"}>
            <Image src={GreaterThan} alt="next" />
          </Center>

          <StatusCard
            stackWidth={{ base: "90px", md: "150px", lg: "200px" }}
            centerBg="#383434"
            text="3"
            textColor="#BAB8B8"
            text2="CS Review" // If you need a second text, modify the component to accept it
            text2Color="#7A7474"
          />
          <Center p={"17px"}>
            <Image src={GreaterThan} alt="next" />
          </Center>
          <StatusCard
            stackWidth={{ base: "90px", md: "116px" }}
            centerBg="#383434"
            text="4"
            textColor="#BAB8B8"
            text2="Appeal complete" // If you need a second text, modify the component to accept it
            text2Color="#7A7474"
          />
        </Flex>
      </Flex>
    </Stack>
  );
};

export default AppealStepSection;

const StatusCard: React.FC<StatusCardProps> = ({
  stackWidth = "90px", // Default width if not provided
  centerBg = "#EFA3D1", // Default background if not provided
  text,
  textColor, // Default text color if not provided
  text2,
  text2Color = "#FAFAFA", // Default color for second text
  status,
  statusBg = "#1C312E", // Default background for status if not provided
  statusColor = "#56CE77", // Default color for status text if not provided
}) => {
  return (
    <Stack
      justify={"center"}
      align={"center"}
      gap={{ base: "8px", md: "12px" }}
      maxW={stackWidth}
    >
      <Center
        w={{ base: "24px", md: "32px" }}
        h={{ base: "24px", md: "32px" }}
        p={{ base: "6px", md: "10px" }}
        borderRadius={"88px"}
        bg={centerBg}
      >
        <CustomText
          text={text} // You can modify this to be a prop if needed
          size={{ base: "10px", md: "12px" }}
          color={textColor || "#000"}
          weight="700"
          lh="120%"
        />
      </Center>
      <CustomText
        text={text2}
        align="center"
        size={{ base: "12px", md: "16px" }}
        color={text2Color}
        lh="120%"
      />
      {status && (
        <Center
          w={{ base: "70px", md: "90px" }}
          h={"24px"}
          p={"6px 12px"}
          borderRadius={"30px"}
          bg={statusBg}
        >
          <CustomText
            text={status}
            color={statusColor}
            size={{ base: "8px", md: "10px" }}
            weight="500"
          />
        </Center>
      )}
    </Stack>
  );
};
