import { createWeb3Modal } from "@web3modal/wagmi/react";
import { defaultWagmiConfig } from "@web3modal/wagmi/react/config";
import { mainnet, polygon, bsc, fantom, base, avalanche } from "wagmi/chains";

// 1. Your Reown Cloud project ID
const projectId = process.env.REACT_APP_REOWN_PROJECT_ID || "";

// 2. Create wagmiConfig
const metadata = {
  name: "extsy-stg",
  description: "AppKit Example",
  url: "https://reown.com/appkit", // origin must match your domain & subdomain
  icons: ["https://assets.reown.com/reown-profile-pic.png"],
};

const chains = [mainnet, polygon, bsc, fantom, base, avalanche] as const;
export const config = defaultWagmiConfig({
  chains,
  projectId,
  metadata,
});

// 3. Create modal
createWeb3Modal({
  wagmiConfig: config,
  projectId,
  enableAnalytics: true, // Optional - defaults to your Cloud configuration
  enableOnramp: true, // Optional - false as default
});
