import React from "react";
import { useWallet } from "@tronweb3/tronwallet-adapter-react-hooks";
import { WalletActionButton } from "@tronweb3/tronwallet-adapter-react-ui";
import "@tronweb3/tronwallet-adapter-react-ui/style.css";

function ConnectComponent() {
  const { disconnect, select, connected, connect } = useWallet();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (connected) {
      disconnect();
    } else {
      select("TronLink" as any);
      connect();
    }
  };

  return (
    <WalletActionButton
      onClick={handleClick}
      className="custom-wallet-button" // Apply custom class
    >
      {connected ? "Disconnect Wallet" : "Connect Wallet"}
    </WalletActionButton>
  );
}

export default ConnectComponent;
