import { Box, Flex, Image, Input } from "@chakra-ui/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import CustomText from "../text/CustomText";
import CopyClipIco from "../../assets/images/copy-clip-ico.svg";
import PrimaryButton from "../button/PrimaryButton";
import { useWithdrawPayout } from "../../utils/api/dashboard.api";
import { useCustomAlert } from "../../utils/api/alert";

function RequestPayment() {
  const { t } = useTranslation();
  const withdrawPayoutMutation = useWithdrawPayout();
  const alert = useCustomAlert();

  const [address, setAddress] = useState("");
  const [amount, setAmount] = useState(0);

  return (
    <Flex
      flexDir="column"
      gap="40px"
      p={{ base: "16px", lg: "32px" }}
      borderRadius={{ base: "16px", lg: "24px" }}
      bg="extsy.neutral1000"
      border="1px solid"
      borderColor="extsy.neutral1000"
      h="100%"
    >
      <CustomText
        text={t("requestPayout")}
        size={{ base: "18px", lg: "24px" }}
        weight={{ base: "600", lg: "700" }}
        color="extsy.white"
        family="Metropolis"
        lh="32px"
      />
      <CustomText text={`${t("requestPaymentMinWithdrawal")} 0.000020 BTC.`} />
      <Flex flexDir="column" gap="24px">
        <Flex flexDir="column" gap="16px">
          <CustomText text={t("enterYourAddress")} family="Metropolis" />
          <WithdrawInput
            placeholder={t("enterYourAddress")}
            type="text"
            value={address}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setAddress(e.target.value)
            }
            icon={CopyClipIco}
            onCopyClick={async () => {
              try {
                const text = await navigator.clipboard.readText(); // Read text from clipboard
                setAddress(text); // Set the address state to the clipboard text
              } catch (err) {
                console.error("Failed to read clipboard contents: ", err);
              }
            }}
          />
        </Flex>
        <Flex flexDir="column" gap="16px">
          <CustomText text={t("amount")} family="Metropolis" />
          <WithdrawInput
            placeholder={t("withdrawAmount")}
            type="number"
            value={amount}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const value: any = e.target.value;

              setAmount(value); // Update state only if valid
            }}
          />
        </Flex>
        <CustomText text={`${t("minAmountIs")} 0.000020 BTC`} />
      </Flex>
      <PrimaryButton
        title={t("withdraw")}
        maxW={{ md: "272px" }}
        isDisabled={amount < 0.00002 || !address}
        isLoading={withdrawPayoutMutation?.isPending}
        onClick={async () => {
          const formData = {
            address,
            amount: Number(amount),
          };
          await withdrawPayoutMutation
            .mutateAsync(formData)
            .then((res) => {
              alert?.mutate({
                message: "Amount withdrawn successfully!",
                status: "success",
              });
              setAmount(0); // Reset the amount state after successful withdrawal
              setAddress(""); // Reset the address state after successful withdrawal
            })
            .catch((err) => {
              alert?.mutate({
                message:
                  err?.exception && Array.isArray(err?.exception)
                    ? err?.exception[0]
                    : err?.message,
                status: "danger",
              });
            });
        }}
      />
    </Flex>
  );
}

const WithdrawInput = ({
  placeholder,
  type,
  icon,
  value,
  onChange,
  onCopyClick,
}: any) => {
  return (
    <Box pos="relative">
      <Input
        pl="22px"
        pe="42px"
        height="56px"
        bg="extsy.neutral900"
        borderRadius="8px"
        fontSize="16px"
        color="extsy.white"
        fontFamily="Titilium Web"
        placeholder={placeholder}
        type={type}
        value={value}
        onChange={onChange}
        border="none"
        _focusVisible={{ border: "none" }}
        _placeholder={{
          color: "extsy.text",
        }}
      />
      {icon && (
        <Image
          src={icon}
          alt=""
          cursor="pointer"
          draggable={false}
          pos="absolute"
          top="0"
          right="0"
          mr="16px"
          mt="16px"
          height="24px"
          width="24px"
          onClick={onCopyClick}
        />
      )}
    </Box>
  );
};

export default RequestPayment;
