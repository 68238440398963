import {
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import React, { useState } from "react";
import CustomText from "../../components/text/CustomText";
import ReferralTable from "./ReferralTable";
import CashbackTable from "./CashbackTable";

function History() {
  const [tabIndex, setTabIndex] = useState(0);

  const TABS = ["Referral", "Cashback"];
  return (
    <Flex
      flexDir="column"
      gap="12px"
      bg="extsy.neutral1000"
      boxShadow="0px 3px 4px 0px rgba(0, 0, 0, 0.03)"
      borderRadius={{ base: "16px", lg: "24px" }}
    >
      <Flex align="center" justify="space-between" gap="20px">
        <CustomText
          text="History"
          size={{ base: "18px", lg: "24px" }}
          weight={{ base: "600", lg: "700" }}
          lh={{ lg: "34px" }}
          family="Metropolis"
          color="extsy.baseWhite"
          wrap="nowrap"
        />
      </Flex>
      <Tabs
        onChange={(index) => {
          setTabIndex(index);
        }}
        defaultIndex={0}
      >
        <TabList borderBottom="1px solid" borderColor="extsy.neutral900">
          {TABS?.map((tab, index) => (
            <Tab
              key={index}
              px="32px"
              pt="10px"
              pb="14px"
              w={{ base: "50%", md: "auto" }}
              color="extsy.secondary"
            >
              <CustomText
                text={tab}
                color={
                  index === tabIndex ? "extsy.baseWhite" : "extsy.neutral300"
                }
                cursor="pointer"
              />
            </Tab>
          ))}
        </TabList>
        <TabPanels>
          <TabPanel px="0px" pt="24px">
            <ReferralTable />
          </TabPanel>
          <TabPanel px="0px" pt="24px">
            <CashbackTable />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
}

export default History;
