import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { client } from "./api-client";

export const useGetTransactions = (
  limit: number,
  offset: number,
  search: string,
  transactionType: string,
  sortingKey: string,
  sortingOrder: string,
  time?: number,
  date?: string
) => {
  return useQuery<any, Error, any>({
    queryKey: [
      "getTransactions",
      limit,
      offset,
      time,
      date,
      search,
      transactionType,
      sortingKey,
      sortingOrder,
    ],
    queryFn: async () => {
      // Initialize URLSearchParams without time and date
      const queryParams = new URLSearchParams({
        limit: limit.toString(),
        offset: offset.toString(),
        search: search.toString(),
        // Use encodeURIComponent for transactionType
        transactionType: encodeURIComponent(transactionType),
        sortingKey: sortingKey.toString(),
        sortingOrder: sortingOrder.toString(),
      });

      // Add time and date if they are defined
      if (time) {
        queryParams.append("time", time.toString());
      }
      if (date) {
        queryParams.append("date", date.toString());
      }

      const response = await client(
        `dashboard/getTransactions?${queryParams.toString()}`
      );
      if (response.error) {
        throw new Error(response.error);
      }
      return response;
    },
  });
};

export const useExportTransactionsInCSV = () => {
  return useMutation<
    any,
    Error,
    {
      search: string;
      transactionType: string;
      sortingKey: string;
      sortingOrder: string;
      time?: number;
      date?: string;
    }
  >({
    mutationFn: async ({
      search,
      transactionType,
      sortingKey,
      sortingOrder,
      time,
      date,
    }) => {
      // Prepare the request body
      const params = new URLSearchParams({
        search: search.toString(),
        // Use encodeURIComponent for transactionType
        transactionType: transactionType.toString(),
        sortingKey: sortingKey.toString(),
        sortingOrder: sortingOrder.toString(),
      });

      if (time) {
        params.append("time", time.toString());
      }
      if (date) {
        params.append("date", date.toString());
      }

      // Use client to post the CSV export request
      const response = await client(
        `dashboard/exportTransactionsInCSV?${params.toString()}`,
        {
          method: "POST", // Specify the POST method
        }
      );

      // Check for errors in the response
      if (response.error) {
        throw new Error(response.error);
      }

      // Create a blob from the response
      const blob = new Blob([response], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);

      // Create a link element to trigger the download
      const link: any = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "transactions.csv"); // Specify the file name

      // Append to the body and trigger download
      document.body.appendChild(link);
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
    },
  });
};
export const useGetCashbackStats = () => {
  return useQuery<any, Error, any>({
    queryKey: ["getCashbackStats"],
    queryFn: async () => {
      const response = await client(`dashboard/getCashbackStats`);
      if (response.error) {
        throw new Error(response.error);
      }
      return response;
    },
  });
};

export const useGetUserPayouts = (
  limit: number,
  offset: number,
  status: string,
  search: string,
  sortingKey: string,
  sortingOrder: string
) => {
  return useQuery<any, Error, any>({
    queryKey: [
      "getUserPayouts",
      limit,
      offset,
      status,
      search,
      sortingKey,
      sortingOrder,
    ],
    queryFn: async () => {
      const queryParams = new URLSearchParams({
        limit: limit.toString(),
        offset: offset.toString(),
        status: status.toString(),
        search: search.toString(),
        sortingKey: sortingKey.toString(),
        sortingOrder: sortingOrder.toString(),
      });
      const response = await client(
        `dashboard/getUserPayouts?${queryParams.toString()}`
      );
      if (response.error) {
        throw new Error(response.error);
      }
      return response;
    },
  });
};

export const useWithdrawPayout = () => {
  const queryClient = useQueryClient();

  return useMutation<any, Error, any>({
    mutationFn: async (payload) => {
      const response = await client(`dashboard/withdrawPayout`, {
        method: "POST",
        data: payload,
      });

      if (response.error) {
        throw new Error(response.error);
      }
      queryClient.invalidateQueries({ queryKey: ["getUserPayouts"] });
      return response;
    },
  });
};

export const useCheckAml = () => {
  const queryClient = useQueryClient();

  return useMutation<any, Error, any>({
    mutationFn: async (payload) => {
      const response = await client(`dashboard/checkAml`, {
        method: "POST",
        data: payload,
      });

      if (response.error) {
        throw new Error(response.error);
      }
      queryClient.invalidateQueries({ queryKey: ["getAmlCheckHistory"] });
      queryClient.invalidateQueries({ queryKey: ["getAmlCheckCount"] });
      return response;
    },
  });
};

export const useGetAmlCheckCount = () => {
  return useQuery<any, Error, any>({
    queryKey: ["getAmlCheckCount"],
    queryFn: async () => {
      const response = await client(`dashboard/getAmlCheckCount`);
      if (response.error) {
        throw new Error(response.error);
      }
      return response;
    },
  });
};

export const useGetAmlCheckHistory = (
  limit: number,
  offset: number,
  search: string,
  sortingKey: string,
  sortingOrder: string
) => {
  return useQuery<any, Error, any>({
    queryKey: [
      "getAmlCheckHistory",
      limit,
      offset,
      search,
      sortingKey,
      sortingOrder,
    ],
    queryFn: async () => {
      const queryParams = new URLSearchParams({
        limit: limit.toString(),
        offset: offset.toString(),
        search: search.toString(),
        sortingKey: sortingKey.toString(),
        sortingOrder: sortingOrder.toString(),
      });

      // Add time and date if they are define
      const response = await client(
        `dashboard/getAmlCheckHistory?${queryParams.toString()}`
      );
      if (response.error) {
        throw new Error(response.error);
      }
      return response;
    },
  });
};
