import { Box, Flex, Tab, TabList, Tabs } from "@chakra-ui/react";
import React, { useMemo, useState } from "react";
import CustomText from "../../../components/text/CustomText";
import TableSearchInput from "../../../components/form/TableSearchInput";
import { IconCheck, IconChevronDown } from "@tabler/icons-react";
import CustomCalendar from "../../../components/form/CustomCalendar";
import NoOrder from "./NoOrder";
import OrderTable from "./OrderTable";
import { useGetFiats, useMyOrders } from "../../../utils/api/p2p.api";
import { useGetCoins } from "../../../utils/api/swap.api";
import OutsideClickHandler from "../../../hooks/CustomOutsideClick";

function MyOrders() {
  const tabMapping = ["ALL", "IN_PROCESS", "COMPLETED", "CANCELLED"];

  const [tabIndex, setTabIndex] = useState(0);
  const [selectedTab, setSelectedTab] = useState(tabMapping[0]);
  const [searchTerm, setSearchTerm] = useState("");
  const [offset, setOffset] = useState(0);
  const [limit] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [sortBy, setSortBy] = useState("");
  const [sortDirection, setSortDirection] = useState("ASC");

  const [isDropdownOpen, setIsDropdownOpen] = useState({
    isCurrencyOpen: false,
    isCoinOpen: false,
    isBuySell: false,
    isCalendarOpen: false,
  });
  const [filters, setFilters] = useState({
    currency: "ALL",
    coin: "ALL",
    buySell: "ALL",
    calendar: ["10-08-2024", "10-20-2300"],
  });
  const startDate = new Date(filters.calendar[0]).toISOString();
  const endDate = new Date(filters.calendar[1]).toISOString();

  const { data: currecyData } = useGetFiats();
  const { data: coinsData } = useGetCoins();

  const { data: orderData, isLoading: orderLoading } = useMyOrders(
    selectedTab,
    searchTerm,
    pageNumber,
    limit,
    sortBy,
    sortDirection,
    filters?.coin,
    filters?.currency,
    filters?.buySell,
    startDate,
    endDate
  );
  const currencies = useMemo(() => {
    if (!currecyData) return [];

    return [
      { id: "0", name: "All Currency" }, // Add the default "All Currency" option
      ...currecyData,
    ];
  }, [currecyData]);
  const coins = useMemo(() => {
    if (!coinsData) return [];

    return [
      { id: "0", symbol: "All Coins" }, // Add the default "All Currency" option
      ...coinsData,
    ];
  }, [coinsData]);

  const TABS = ["All", "In Progress", "Completed", "Cancelled"];
  const BUY_SELL = ["All", "Buy / Deposit", "Sell / Withdraw"];

  const handleTabChange = (index: any) => {
    setTabIndex(index);
    setSelectedTab(tabMapping[index]);
  };

  const toggleDropdown = (name: string) => {
    setIsDropdownOpen((prevState: any) => ({
      ...prevState,
      [name]: !prevState[name],
    }));
  };

  const closeDropdown = (name: string) => {
    setIsDropdownOpen((prevState: any) => ({ ...prevState, [name]: false }));
  };

  const formatedDate = () => {
    const dateObjStart = new Date(filters.calendar?.[0]);
    const dateObjEnd = new Date(filters.calendar?.[1]);
    return (
      dateObjStart.toLocaleDateString() +
      " - " +
      dateObjEnd.toLocaleDateString()
    );
  };

  return (
    <div>
      <Flex
        align="flex-start"
        justify="space-between"
        flexDir={{
          base: "column",
          "1xl": "row",
        }}
        gap="16px"
      >
        <Tabs onChange={(index) => handleTabChange(index)} defaultIndex={0}>
          <TabList
            borderBottom="1px solid"
            borderColor="extsy.neutral900"
            overflowX={{ base: "auto", sm: "inherit" }}
          >
            {TABS?.map((tab, index) => (
              <Tab
                key={index}
                px={{ base: "16px", md: "32px" }}
                pt="10px"
                pb="14px"
                color="extsy.secondary100"
              >
                <CustomText
                  text={tab}
                  color={
                    index === tabIndex ? "extsy.baseWhite" : "extsy.neutral300"
                  }
                  cursor="pointer"
                  wrap="nowrap"
                />
              </Tab>
            ))}
          </TabList>
        </Tabs>
        <Flex align="center" gap="10px" wrap="wrap">
          <TableSearchInput
            placeholder="Search"
            minW="157px"
            maxW={{ md: "157px" }}
            value={searchTerm}
            onChange={(e: any) => setSearchTerm(e.target.value)}
          />

          <Box pos="relative">
            <Flex
              align="center"
              gap="16px"
              h="38px"
              ps="24px"
              pe="10px"
              mb={isDropdownOpen?.isCoinOpen ? "-24px" : "0px"}
              borderRadius="666px"
              bg="extsy.neutral900"
              boxShadow="0px 2.667px 26.667px 0px rgba(0, 0, 0, 0.15)"
              onClick={() => {
                toggleDropdown("isCoinOpen");
              }}
              cursor="pointer"
            >
              <CustomText
                text={filters.coin === "ALL" ? "All Coins" : filters.coin}
                size="12px"
                color="extsy.baseWhite"
                cursor
              />
              <IconChevronDown size="16px" color="#fafafa" />
            </Flex>
            <OutsideClickHandler
              isOpen={isDropdownOpen.isCoinOpen}
              onClose={() => {
                setIsDropdownOpen((prevState: any) => ({
                  ...prevState,
                  isCoinOpen: false,
                }));
              }}
            >
              <Flex
                p="16px"
                borderRadius="8px"
                bg="extsy.bg"
                flexDir="column"
                gap="16px"
                w="150px"
                display={isDropdownOpen?.isCoinOpen ? "flex" : "none"}
                pos="absolute"
                right="0"
                top="0"
                mt="45px"
                zIndex="1000"
              >
                {coins?.map((coin: any, index) => (
                  <Flex
                    key={index}
                    align="center"
                    justify="space-between"
                    gap="8px"
                    cursor="pointer"
                    onClick={() => {
                      setFilters((prevState) => ({
                        ...prevState,
                        coin: coin?.symbol === "All Coins" ? "ALL" : coin.id, // Use ternary to conditionally set the value
                      }));
                      closeDropdown("isCoinOpen");
                    }}
                  >
                    <CustomText
                      text={coin?.symbol}
                      size="14px"
                      color={
                        coin.symbol === filters.coin
                          ? "extsy.baseWhite"
                          : "extsy.neutral300"
                      }
                      cursor
                    />
                    {coin.symbol === filters.coin && (
                      <IconCheck size="16px" color="#fafafa" />
                    )}
                  </Flex>
                ))}
              </Flex>
              |
            </OutsideClickHandler>
          </Box>

          <Box pos="relative">
            <Flex
              align="center"
              gap="16px"
              h="38px"
              ps="24px"
              pe="10px"
              borderRadius="666px"
              bg="extsy.neutral900"
              boxShadow="0px 2.667px 26.667px 0px rgba(0, 0, 0, 0.15)"
              onClick={() => {
                toggleDropdown("isCurrencyOpen");
              }}
              cursor="pointer"
            >
              <CustomText
                text={
                  filters.currency === "ALL" ? "All Currency" : filters.currency
                }
                size="12px"
                color="extsy.baseWhite"
                cursor
              />
              <IconChevronDown size="16px" color="#fafafa" />
            </Flex>
            <OutsideClickHandler
              isOpen={isDropdownOpen.isCurrencyOpen}
              onClose={() => {
                setIsDropdownOpen((prevState: any) => ({
                  ...prevState,
                  isCurrencyOpen: false,
                }));
              }}
            >
              <Flex
                p="16px"
                borderRadius="8px"
                bg="extsy.bg"
                flexDir="column"
                gap="16px"
                w="150px"
                display={isDropdownOpen?.isCurrencyOpen ? "flex" : "none"}
                pos="absolute"
                right="0"
                top="0"
                mt="45px"
                zIndex="1000"
              >
                {currencies?.map((currnecy: any) => (
                  <Flex
                    key={currnecy.id}
                    align="center"
                    justify="space-between"
                    gap="8px"
                    cursor="pointer"
                    onClick={() => {
                      setFilters((prevState) => ({
                        ...prevState,
                        currency:
                          currnecy.name === "All Currency"
                            ? "ALL"
                            : currnecy.name,
                      }));
                      closeDropdown("isCurrencyOpen");
                    }}
                  >
                    <CustomText
                      text={currnecy.name}
                      size="14px"
                      color={
                        currnecy.name === filters.currency
                          ? "extsy.baseWhite"
                          : "extsy.neutral300"
                      }
                      cursor
                    />
                    {currnecy.name === filters.currency && (
                      <IconCheck size="16px" color="#fafafa" />
                    )}
                  </Flex>
                ))}
              </Flex>
            </OutsideClickHandler>
          </Box>
          <Box pos="relative">
            <Flex
              align="center"
              gap="16px"
              h="38px"
              ps="24px"
              pe="10px"
              borderRadius="666px"
              bg="extsy.neutral900"
              boxShadow="0px 2.667px 26.667px 0px rgba(0, 0, 0, 0.15)"
              onClick={() => {
                toggleDropdown("isBuySell");
              }}
              cursor="pointer"
            >
              <CustomText
                text={filters.buySell}
                size="12px"
                color="extsy.baseWhite"
                cursor
              />
              <IconChevronDown size="16px" color="#fafafa" />
            </Flex>
            <OutsideClickHandler
              isOpen={isDropdownOpen.isBuySell}
              onClose={() => {
                setIsDropdownOpen((prevState: any) => ({
                  ...prevState,
                  isBuySell: false,
                }));
              }}
            >
              <Flex
                p="16px"
                borderRadius="8px"
                bg="extsy.bg"
                flexDir="column"
                gap="16px"
                w="150px"
                display={isDropdownOpen?.isBuySell ? "flex" : "none"}
                pos="absolute"
                right="0"
                top="0"
                mt="45px"
                zIndex="1000"
              >
                {BUY_SELL.map((buySell, index) => (
                  <Flex
                    key={index}
                    align="center"
                    justify="space-between"
                    gap="8px"
                    cursor="pointer"
                    onClick={() => {
                      setFilters((prevState) => ({
                        ...prevState,
                        buySell:
                          buySell === "Buy / Deposit"
                            ? "BUY"
                            : buySell === "Sell / Withdraw"
                            ? "SELL"
                            : buySell === "All"
                            ? "ALL"
                            : buySell,
                      }));
                      closeDropdown("isBuySell");
                    }}
                  >
                    <CustomText
                      text={buySell}
                      size="14px"
                      color={
                        buySell === filters.buySell
                          ? "extsy.baseWhite"
                          : "extsy.neutral300"
                      }
                      cursor
                    />
                    {buySell === filters.buySell && (
                      <IconCheck size="16px" color="#fafafa" />
                    )}
                  </Flex>
                ))}
              </Flex>
            </OutsideClickHandler>
          </Box>
          <Box pos="relative">
            <Flex
              align="center"
              gap="16px"
              h="38px"
              ps="24px"
              pe="10px"
              borderRadius="666px"
              bg="extsy.neutral900"
              boxShadow="0px 2.667px 26.667px 0px rgba(0, 0, 0, 0.15)"
              onClick={() => {
                toggleDropdown("isCalendarOpen");
              }}
              cursor="pointer"
            >
              <CustomText
                text={formatedDate()}
                size="12px"
                color="extsy.baseWhite"
                cursor
              />
              <IconChevronDown size="16px" color="#fafafa" />
            </Flex>
            <OutsideClickHandler
              isOpen={isDropdownOpen.isCalendarOpen}
              onClose={() => {
                setIsDropdownOpen((prevState: any) => ({
                  ...prevState,
                  isCalendarOpen: false,
                }));
              }}
            >
              <CustomCalendar
                isCalendarOpen={isDropdownOpen?.isCalendarOpen}
                value={filters.calendar}
                onChange={(val: any) => {
                  console.log("-value", val);
                  setFilters((prevState) => ({
                    ...prevState,
                    calendar: val,
                  }));
                  closeDropdown("isCalendarOpen");
                }}
                isRangePicker
              />
            </OutsideClickHandler>
          </Box>
        </Flex>
      </Flex>
      <Box mt="40px">
        {
          // Render the content based on the selected tab
          orderData?.data?.length === 0 ? (
            <NoOrder />
          ) : tabIndex === 0 ? (
            <OrderTable
              status="All"
              data={orderData}
              isLoading={orderLoading}
              sortDirection={sortDirection}
              setSortBy={setSortBy}
              setSortDirection={setSortDirection}
              offset={offset}
              setPageCount={setPageNumber}
              limit={limit}
              pageCount={pageNumber}
              setOffset={setOffset}
            />
          ) : tabIndex === 1 ? (
            <OrderTable
              status="In_Process"
              isLoading={orderLoading}
              data={orderData}
              sortDirection={sortDirection}
              setSortBy={setSortBy}
              setSortDirection={setSortDirection}
              offset={offset}
              setPageCount={setPageNumber}
              limit={limit}
              pageCount={pageNumber}
              setOffset={setOffset}
            />
          ) : tabIndex === 2 ? (
            <OrderTable
              status="Completed"
              data={orderData}
              isLoading={orderLoading}
              sortDirection={sortDirection}
              setSortBy={setSortBy}
              setSortDirection={setSortDirection}
              offset={offset}
              setPageCount={setPageNumber}
              limit={limit}
              pageCount={pageNumber}
              setOffset={setOffset}
            />
          ) : tabIndex === 3 ? (
            <OrderTable
              status="Cancelled"
              data={orderData}
              isLoading={orderLoading}
              sortDirection={sortDirection}
              setSortBy={setSortBy}
              setSortDirection={setSortDirection}
              offset={offset}
              setPageCount={setPageNumber}
              limit={limit}
              pageCount={pageNumber}
              setOffset={setOffset}
            />
          ) : null
        }
      </Box>
    </div>
  );
}

export default MyOrders;
