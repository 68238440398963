import {
  Flex,
  Image,
  SkeletonText,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import CustomText from "../../components/text/CustomText";
import { IconChevronDown, IconChevronUp } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import ArrowBack from "../../assets/images/black-left.svg";
import ArrowForward from "../../assets/images/black-right.svg";
import { useGetBalance } from "../../utils/api/trade.api";
import EmptyTrxIco from "../../assets/images/empty-trx-ico.svg";
import moment from "moment";

function CashbackTable() {
  const { t } = useTranslation();

  const [limit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState("");

  const TABLE_HEADER = [
    {
      label: "Name",
      sortKey: "name",
      justify: "start",
      minW: { base: "140px", "1xl": "260px" },
    },
    {
      label: "Email",
      sortKey: "email",
      justify: "start",
      minW: { base: "140px", "1xl": "260px" },
    },
    {
      label: "Swap Amount",
      sortKey: "amount",
      justify: "start",
      minW: { base: "140px", "1xl": "260px" },
    },
    {
      label: "Cashback",
      sortKey: "cashback",
      justify: "start",
      minW: { base: "140px", "1xl": "260px" },
    },
    {
      label: "Created",
      sortKey: "created_at",
      justify: "start",
      minW: "374px",
    },
  ];

  const {
    data: balances,
    isLoading,
    refetch: balanceRefetch,
  } = useGetBalance(limit, offset, "", sortBy, sortOrder);

  const totalBalances = balances?.total || 0; // Total number of transactions
  const totalPages = Math.ceil(totalBalances / limit); // Total number of pages
  const currentPage = Math.floor(offset / limit) + 1; // Current page number

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setOffset((prevOffset) => prevOffset + limit);
    }
  };

  // Handle going to the previous page
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setOffset((prevOffset) => prevOffset - limit);
    }
  };

  useEffect(() => {
    balanceRefetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset, sortBy, sortOrder]);

  return (
    <Flex flexDir="column" gap="24px">
      <TableContainer>
        <Table border="1px solid" color="extsy.neutral900">
          <Thead bg="extsy.neutral900" height="40px">
            <Tr>
              {TABLE_HEADER.map(({ label, sortKey, justify, minW }, index) => (
                <Th
                  key={index}
                  borderColor="extsy.neutral900"
                  maxW="583px"
                  minW={minW}
                >
                  <Flex align="center" gap="4px" justify={justify}>
                    <CustomText
                      text={label}
                      size="13px"
                      color="extsy.baseWhite"
                      weight="500"
                      ls="-0.13px"
                    />
                    {sortKey && (
                      <Flex flexDir="column">
                        <IconChevronUp
                          cursor="pointer"
                          color={
                            sortBy === sortKey && sortOrder === "ASC"
                              ? "#EFA3D1"
                              : "#fafafa"
                          }
                          size="14px"
                          onClick={() => {
                            setSortBy(sortKey);
                            setSortOrder("ASC");
                          }}
                        />
                        <IconChevronDown
                          color={
                            sortBy === sortKey && sortOrder === "DESC"
                              ? "#EFA3D1"
                              : "#fafafa"
                          }
                          size="14px"
                          onClick={() => {
                            setSortBy(sortKey);
                            setSortOrder("DESC");
                          }}
                          cursor="pointer"
                          style={{
                            marginTop: -7,
                          }}
                        />
                      </Flex>
                    )}
                  </Flex>
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {isLoading
              ? [...Array(5)].map((_, index) => <LoadingRow key={index} />)
              : balances?.data?.map((referral: any, index: number) => (
                  <Tr key={index}>
                    <Td borderColor="extsy.neutral900" borderRight="1px solid">
                      <CustomText text={referral?.name} size="14px" />
                    </Td>
                    <Td borderColor="extsy.neutral900" borderRight="1px solid">
                      <CustomText text={referral?.email} size="14px" />
                    </Td>
                    <Td borderColor="extsy.neutral900" borderRight="1px solid">
                      <CustomText text={referral?.amount} size="14px" />
                    </Td>
                    <Td borderColor="extsy.neutral900" borderRight="1px solid">
                      <CustomText text={referral?.cashback} size="14px" />
                    </Td>
                    <Td borderColor="extsy.neutral900" borderRight="1px solid">
                      <CustomText
                        text={moment(referral?.created_at).format(
                          "DD-MM-YYYY HH:mm A"
                        )}
                        size="14px"
                      />
                    </Td>
                  </Tr>
                ))}
          </Tbody>
        </Table>
        {!isLoading && !balances?.data?.length && (
          <Flex
            minH="230px"
            align="center"
            justify="center"
            flexDir="column"
            gap="16px"
          >
            <Image src={EmptyTrxIco} alt="empty" />
            <CustomText
              text="No history found"
              size="14px"
              color="extsy.neutral400"
            />
          </Flex>
        )}
      </TableContainer>
      <Flex
        gap="16px"
        px="20px"
        align="center"
        justify="flex-end"
        display={balances?.data?.length ? "flex" : "none"}
      >
        {/* Showing range of items */}
        <CustomText
          text={`${offset + 1}-${Math.min(offset + limit, totalBalances)} ${t(
            "of"
          )} ${totalBalances}`}
          size="13px"
          weight="500"
          color="extsy.neutral300"
        />

        <Flex gap="8px">
          {/* Previous Page Button */}
          <Image
            src={ArrowBack}
            alt="arrow-back"
            cursor={currentPage > 1 ? "pointer" : "not-allowed"}
            opacity={currentPage > 1 ? 1 : 0.5}
            onClick={handlePrevPage}
          />

          {/* Page Numbers */}
          {[...Array(totalPages)].map((_, index) => (
            <Flex
              key={index}
              flexDir="column"
              align="center"
              justify="center"
              bg={
                currentPage === index + 1 ? "extsy.neutral900" : "transparent"
              }
              borderRadius="6px"
              height="30px"
              width="30px"
              cursor="pointer"
              onClick={() => setOffset(index * limit)}
            >
              <CustomText text={index + 1} size="14px" weight="500" />
            </Flex>
          ))}

          {/* Next Page Button */}
          <Image
            src={ArrowForward}
            alt="arrow-forward"
            cursor={currentPage < totalPages ? "pointer" : "not-allowed"}
            opacity={currentPage < totalPages ? 1 : 0.5}
            onClick={handleNextPage}
          />
        </Flex>
      </Flex>
    </Flex>
  );
}

const LoadingRow = () => {
  return (
    <Tr>
      <Td borderColor="extsy.neutral900" borderRight="1px solid">
        <SkeletonText noOfLines={1} w="60px" />
      </Td>
      <Td borderColor="extsy.neutral900" borderRight="1px solid">
        <SkeletonText noOfLines={1} w="60px" />
      </Td>
      <Td borderColor="extsy.neutral900" borderRight="1px solid">
        <SkeletonText noOfLines={1} w="60px" />
      </Td>
      <Td borderColor="extsy.neutral900" borderRight="1px solid">
        <SkeletonText noOfLines={1} w="60px" />
      </Td>
      <Td borderColor="extsy.neutral900" borderRight="1px solid">
        <SkeletonText noOfLines={1} w="30px" />
      </Td>
    </Tr>
  );
};

export default CashbackTable;
