import {
  Flex,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  SkeletonText,
  Stack,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useState } from "react";
import CustomText from "../../components/text/CustomText";
import PrimaryButton from "../../components/button/PrimaryButton";
import FBShareIcon from "../../assets/images/fb-share-icon.svg";
import WhatsappShareIcon from "../../assets/images/whatsapp-share-icon.svg";
import TwitterShareIcon from "../../assets/images/twitter-share-icon.svg";
// import SkypeShareIcon from "../../assets/images/skype-share-icon.svg";
import LinkdlnShareIcon from "../../assets/images/linkdln-share-icon.svg";
// import InstaShareIcon from "../../assets/images/insta-share-icon.svg";
import LinkCopied from "../../assets/images/link-copied.svg";
import IconCopyText from "../../assets/images/icon-copy-text.svg";
import { useGetLoggedInUser } from "../../utils/api/auth.api";
import CustomModal from "../../components/modal/customModal";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import History from "./History";

function Referral() {
  const { data: loggedInUserData, isLoading } = useGetLoggedInUser();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [copied, setCopied] = useState(false);

  return (
    <Flex
      flexDir="column"
      gap="40px"
      p={{ base: "16px", lg: "32px" }}
      bg="extsy.neutral1000"
      border="1px solid"
      borderColor="extsy.neutral1000"
      borderRadius={{ base: "16px", lg: "24px" }}
    >
      <CustomText
        text="Refer a friend"
        size={{ base: "18px", lg: "24px" }}
        weight={{ base: "600", lg: "700" }}
        color="extsy.baseWhite"
      />
      <Flex
        flexDir={{ base: "column", lg: "row" }}
        w="100%"
        gap={{ base: "16px", lg: "64px" }}
      >
        <Flex
          flexDir="column"
          justify="space-between"
          gap="40px"
          p={{ base: "16px", lg: "40px" }}
          bg="extsy.neutral900"
          border="1px solid"
          borderColor="extsy.neutral900"
          borderRadius={{ base: "16px", lg: "24px" }}
          w="100%"
        >
          <CustomText
            text="Invite your friends and earn rewards"
            weight="600"
            color="extsy.baseWhite"
          />
          <Flex
            gap="16px"
            flexDir={{
              md: "row",
              base: "column",
            }}
          >
            {isLoading ? (
              <SkeletonText w="120px" noOfLines={1} />
            ) : (
              <Input
                fontSize="16px"
                fontWeight="400"
                color="extsy.baseWhite"
                fontFamily={"Metropolis"}
                h="48px"
                bg="extsy.neutral1000"
                borderRadius="8px"
                px="22px"
                border="none"
                _hover={{
                  border: "none",
                }}
                _focus={{
                  border: "none",
                }}
                _focusVisible={{ border: "none" }}
                value={`${window.origin}/auth/signup?referralCode=${loggedInUserData?.my_referral_code}`}
              />
            )}
            <PrimaryButton
              title="Share & Invite friends"
              size="14px"
              height="48px"
              maxW={{ md: "216px" }}
              onClick={onOpen}
            />
          </Flex>
        </Flex>
        <Flex
          flexDir="column"
          justify="space-between"
          align="center"
          gap="24px"
          p={{ base: "16px", lg: "40px" }}
          bg="extsy.neutral900"
          border="1px solid"
          borderColor="extsy.neutral900"
          borderRadius={{ base: "16px", lg: "24px" }}
          w={{ base: "100%", lg: "60%" }}
        >
          <CustomText
            text="Partner"
            size={{ base: "18px", md: "24px" }}
            weight={{ base: "600", md: "700" }}
            color="extsy.baseWhite"
          />
          <CustomText text="Be a partner in Extsy" />
          <PrimaryButton
            title="Become a Partner"
            size="14px"
            height="48px"
            maxW={{ md: "278px" }}
            onClick={onOpen}
          />
        </Flex>
      </Flex>
      <History />
      <CustomModal
        isOpen={isOpen}
        onClose={onClose}
        headerText={"Share & Invite your friends"}
        noFooter={true}
        width="sm"
        noCloseFooter={true}
        onSubmit={() => console.log("sd")}
      >
        <Stack
          p={"24px"}
          justify={"center"}
          align={"flex-start"}
          gap={"32px"}
          mt={"10px"}
        >
          <Stack align={"flex-start"} gap={"10px"} w={"100%"}>
            <CustomText text="Share link via" />
            <Flex justify={"space-between"} align={"center"} w={"100%"}>
              <FacebookShareButton
                hashtag={`${window.origin}/auth/signup?referralCode=${loggedInUserData?.my_referral_code}`}
                url={`${window.origin}/auth/signup?referralCode=${loggedInUserData?.my_referral_code}`}
              >
                <Image src={FBShareIcon} alt="social" />
              </FacebookShareButton>
              <WhatsappShareButton
                title={`${window.origin}/auth/signup?referralCode=${loggedInUserData?.my_referral_code}`}
                url={`${window.origin}/auth/signup?referralCode=${loggedInUserData?.my_referral_code}`}
              >
                <Image src={WhatsappShareIcon} alt="social" />
              </WhatsappShareButton>
              <TwitterShareButton
                title={`${window.origin}/auth/signup?referralCode=${loggedInUserData?.my_referral_code}`}
                url={`${window.origin}/auth/signup?referralCode=${loggedInUserData?.my_referral_code}`}
              >
                <Image src={TwitterShareIcon} alt="social" />
              </TwitterShareButton>

              {/* <Image src={SkypeShareIcon} alt="social" /> */}
              <LinkedinShareButton
                title={`${window.origin}/auth/signup?referralCode=${loggedInUserData?.my_referral_code}`}
                url={`${window.origin}/auth/signup?referralCode=${loggedInUserData?.my_referral_code}`}
              >
                <Image src={LinkdlnShareIcon} alt="social" />
              </LinkedinShareButton>

              {/* <Image src={InstaShareIcon} alt="social" /> */}
            </Flex>
          </Stack>
          <Stack align={"flex-start"} gap={"10px"} w={"100%"}>
            <CustomText text="or copy link" />
            <InputGroup
              display={"flex"}
              alignItems={"center"}
              bg="#000"
              h={"56px"}
              pr={"16px"}
              borderRadius="8px"
            >
              <Input
                fontSize="16px"
                fontWeight="400"
                color="extsy.baseWhite"
                fontFamily={"Metropolis"}
                h="56px"
                // px="22px"
                border="none"
                _hover={{
                  border: "none",
                }}
                _focus={{
                  border: "none",
                }}
                readOnly
                _focusVisible={{ border: "none" }}
                value={`${window.origin}/auth/signup?referralCode=${loggedInUserData?.my_referral_code}`}
              />
              <InputRightElement w={"fit-content"} h="56px" pr={"16px"}>
                <Image
                  src={copied ? LinkCopied : IconCopyText}
                  alt="copy"
                  cursor={"pointer"}
                  onClick={
                    copied
                      ? () => console.log("object")
                      : () => {
                          navigator.clipboard.writeText(
                            `${window.origin}/auth/signup?referralCode=${loggedInUserData?.my_referral_code}`
                          );
                          setCopied(true);
                        }
                  }
                />
              </InputRightElement>
            </InputGroup>
          </Stack>
        </Stack>
      </CustomModal>
    </Flex>
  );
}

export default Referral;
