import { Box, Input, Spinner } from "@chakra-ui/react";
import { IconAlertTriangleFilled } from "@tabler/icons-react";
import React from "react";

function SwapAmountInput({
  noBorder,
  fontSize,
  readOnly,
  value,
  onChange,
  color,
  borderColor,
  isLoading,
  error,
}: any) {
  return isLoading ? (
    <Box
      borderBottom={noBorder ? "0px solid" : "1px solid"}
      borderColor={borderColor || "extsy.neutral400"}
      height="40px"
    >
      <Spinner size="sm" color="#fff" />
    </Box>
  ) : (
    <>
      <Box
        display={error ? "flex" : "none"}
        position={"absolute"}
        gap={2}
        borderRadius={{ base: "4px", md: "8px" }}
        top={-5}
        justifyContent={"center"}
        alignItems={"center"}
        bg={"#b39225"}
        // h={{ base: "18px", md: "24px" }}
        px={3}
      >
        <IconAlertTriangleFilled size="12px" />
        <p className=" text-[10px] md:text-[12px] text-white">{error}</p>
      </Box>

      <Input
        type="number"
        border="none"
        borderRadius="0"
        borderBottom={noBorder ? "0px solid" : "1px solid"}
        borderColor={error ? "extsy.danger" : borderColor || "extsy.neutral400"}
        _hover={{ borderColor: borderColor || "extsy.neutral400" }}
        _focusVisible={{ borderColor: borderColor || "extsy.neutral400" }}
        pl="0px"
        fontSize={fontSize || "27.4px"}
        fontWeight="700"
        color={color || "extsy.white"}
        fontFamily="Metropolis"
        placeholder="-"
        isReadOnly={readOnly}
        value={value}
        onChange={onChange}
      />
    </>
  );
}

export default SwapAmountInput;
