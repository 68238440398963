import {
  Box,
  Flex,
  Image,
  SkeletonText,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import CustomText from "../../../components/text/CustomText";
import {
  IconArrowDownLeft,
  IconArrowUpRight,
  IconChevronDown,
  IconChevronUp,
} from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import ArrowBack from "../../../assets/images/black-left.svg";
import ArrowForward from "../../../assets/images/black-right.svg";
import ReactPaginate from "react-paginate";

function P2PBalanceTable({
  p2pBalanceData,
  isLoading,
  sortDirection,
  setSortBy,
  setSortDirection,
  offset,
  setPageCount,
  limit,
  pageCount,
  setOffset,
}: any) {
  const { t } = useTranslation();

  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * limit) % p2pBalanceData?.total;
    setOffset(newOffset);
  };

  useEffect(() => {
    if (p2pBalanceData?.total) {
      setPageCount(Math.ceil(p2pBalanceData?.total / limit));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [p2pBalanceData, limit]);

  return (
    <Flex flexDir="column" gap="24px">
      <TableContainer>
        <Table border="1px solid" color="extsy.neutral900">
          <Thead bg="extsy.neutral900" height="40px">
            <Tr>
              <Th borderColor="extsy.neutral900" maxW="583px" minW="160px">
                <Flex align="center" gap="4px">
                  <CustomText
                    text={"Coin"}
                    size="13px"
                    color="extsy.baseWhite"
                    weight="500"
                    ls="-0.13px"
                  />
                  <Flex
                    flexDir="column"
                    cursor={"pointer"}
                    onClick={() => {
                      setSortBy("coin");
                      if (sortDirection === "ASC") {
                        setSortDirection("DESC");
                      } else {
                        setSortDirection("ASC");
                      }
                    }}
                  >
                    <IconChevronUp
                      cursor="pointer"
                      color="#FAFAFA"
                      size="14px"
                    />
                    <IconChevronDown
                      color="#FAFAFA"
                      size="14px"
                      cursor="pointer"
                      style={{
                        marginTop: -7,
                      }}
                    />
                  </Flex>
                </Flex>
              </Th>
              <Th borderColor="extsy.neutral900" maxW="183px" minW="120px">
                <Flex align="center" gap="4px">
                  <CustomText
                    text={"Chain"}
                    size="13px"
                    color="extsy.baseWhite"
                    weight="500"
                    ls="-0.13px"
                  />
                  <Flex
                    flexDir="column"
                    cursor={"pointer"}
                    onClick={() => {
                      setSortBy("chain");
                      if (sortDirection === "ASC") {
                        setSortDirection("DESC");
                      } else {
                        setSortDirection("ASC");
                      }
                    }}
                  >
                    <IconChevronUp
                      cursor="pointer"
                      color="#FAFAFA"
                      size="14px"
                    />
                    <IconChevronDown
                      color="#FAFAFA"
                      size="14px"
                      cursor="pointer"
                      style={{
                        marginTop: -7,
                      }}
                    />
                  </Flex>
                </Flex>
              </Th>
              <Th borderColor="extsy.neutral900" maxW="583px" minW="240px">
                <Flex align="center" gap="4px">
                  <CustomText
                    text="Total Amount"
                    size="13px"
                    color="extsy.baseWhite"
                    weight="500"
                    ls="-0.13px"
                  />
                  <Flex
                    flexDir="column"
                    cursor={"pointer"}
                    onClick={() => {
                      setSortBy("amount");
                      if (sortDirection === "ASC") {
                        setSortDirection("DESC");
                      } else {
                        setSortDirection("ASC");
                      }
                    }}
                  >
                    <IconChevronUp
                      cursor="pointer"
                      color="#FAFAFA"
                      size="14px"
                    />
                    <IconChevronDown
                      color="#FAFAFA"
                      size="14px"
                      cursor="pointer"
                      style={{
                        marginTop: -7,
                      }}
                    />
                  </Flex>
                </Flex>
              </Th>
              <Th borderColor="extsy.neutral900" maxW="583px" minW="160px">
                <Flex align="center" gap="4px" justify="end">
                  <CustomText
                    text={"Available Amount"}
                    size="13px"
                    color="extsy.baseWhite"
                    weight="500"
                    ls="-0.13px"
                  />
                  <Flex
                    flexDir="column"
                    cursor={"pointer"}
                    onClick={() => {
                      setSortBy("available");
                      if (sortDirection === "ASC") {
                        setSortDirection("DESC");
                      } else {
                        setSortDirection("ASC");
                      }
                    }}
                  >
                    <IconChevronUp
                      cursor="pointer"
                      color="#FAFAFA"
                      size="14px"
                    />
                    <IconChevronDown
                      color="#FAFAFA"
                      size="14px"
                      cursor="pointer"
                      style={{
                        marginTop: -7,
                      }}
                    />
                  </Flex>
                </Flex>
              </Th>
              <Th borderColor="extsy.neutral900" maxW="583px" minW="160px">
                <Flex align="center" gap="4px" justify="end">
                  <CustomText
                    text={"Freeze Amount"}
                    size="13px"
                    color="extsy.baseWhite"
                    weight="500"
                    ls="-0.13px"
                  />
                  <Flex
                    flexDir="column"
                    cursor={"pointer"}
                    onClick={() => {
                      setSortBy("freeze");
                      if (sortDirection === "ASC") {
                        setSortDirection("DESC");
                      } else {
                        setSortDirection("ASC");
                      }
                    }}
                  >
                    <IconChevronUp
                      cursor="pointer"
                      color="#FAFAFA"
                      size="14px"
                    />
                    <IconChevronDown
                      color="#FAFAFA"
                      size="14px"
                      cursor="pointer"
                      style={{
                        marginTop: -7,
                      }}
                    />
                  </Flex>
                </Flex>
              </Th>
              <Th borderColor="extsy.neutral900" maxW="283px" minW="160px">
                <Flex align="center" gap="4px">
                  <CustomText
                    text={t("actions")}
                    size="13px"
                    color="extsy.baseWhite"
                    weight="500"
                    ls="-0.13px"
                  />
                </Flex>
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {isLoading
              ? [1, 2, 3, 4, 5].map((_, index) => <LoadingRow key={index} />)
              : p2pBalanceData?.balances?.map((balance: any, index: any) => (
                  <Tr key={index}>
                    <Td
                      height="54px"
                      borderColor="extsy.neutral900"
                      borderRight="1px solid"
                      py="0"
                    >
                      <Flex gap="16px" align="center">
                        <Image
                          src={balance?.coinlogo}
                          alt={balance?.coinsymbol}
                          boxSize="32px"
                        />
                        <CustomText
                          text={balance?.coinsymbol}
                          size="14px"
                          color="extsy.baseWhite"
                        />
                      </Flex>
                    </Td>
                    <Td borderColor="extsy.neutral900" borderRight="1px solid">
                      <CustomText text={balance?.chain || "N/A"} size="14px" />
                    </Td>
                    <Td borderColor="extsy.neutral900" borderRight="1px solid">
                      <Flex align="center" justify="space-between" gap="24px">
                        <CustomText
                          text={
                            balance?.pp_balanceSpot ||
                            0 + " " + balance?.coinsymbol
                          }
                          size="14px"
                        />
                        <CustomText
                          text={balance?.amountUsd || 0 + " USD"}
                          size="14px"
                          color="extsy.neutral600"
                        />
                      </Flex>
                    </Td>
                    <Td borderColor="extsy.neutral900" borderRight="1px solid">
                      <CustomText
                        text={
                          balance?.pp_balanceSpotAvailable ||
                          0 + " " + balance?.coinsymbol
                        }
                        size="14px"
                        align="end"
                      />
                    </Td>
                    <Td borderColor="extsy.neutral900" borderRight="1px solid">
                      <CustomText
                        text={balance?.pp_balanceWithdrawFreeze || 0}
                        size="14px"
                        align="end"
                      />
                    </Td>
                    <Td borderColor="extsy.neutral900" borderRight="1px solid">
                      <Flex gap="16px" align="center">
                        <Flex cursor="pointer" gap="4px" align="center">
                          <IconArrowDownLeft size="18px" color="#908B8B" />
                          <CustomText
                            text={"Deposit"}
                            size="10px"
                            cursor="pointer"
                            color="extsy.baseWhite"
                          />
                        </Flex>
                        <Box bg="extsy.neutral900" height="9px" width="1px" />
                        <Flex cursor="pointer" gap="4px" align="center">
                          <IconArrowUpRight size="18px" color="#908B8B" />
                          <CustomText
                            text={"Withdraw"}
                            size="10px"
                            cursor="pointer"
                            color="extsy.baseWhite"
                          />
                        </Flex>
                      </Flex>
                    </Td>
                  </Tr>
                ))}
          </Tbody>
        </Table>
      </TableContainer>
      <Flex gap="16px" px="20px" align="center" justify="flex-end">
        {isLoading ? (
          <SkeletonText noOfLines={1} w="120px" />
        ) : (
          <>
            <CustomText
              text={`${offset + 1}-${offset + p2pBalanceData?.total} ${t(
                "of"
              )} ${p2pBalanceData?.total}`}
              size="13px"
              weight="500"
              color="extsy.neutral300"
            />
            <ReactPaginate
              breakLabel="..."
              nextLabel={
                <Image
                  src={ArrowForward}
                  alt="arrow-forward"
                  cursor="pointer"
                />
              }
              previousLabel={
                <Image src={ArrowBack} alt="arrow-back" cursor="pointer" />
              }
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              pageCount={pageCount}
              renderOnZeroPageCount={null}
              containerClassName="react-pagination"
              activeClassName="active-pagination"
            />
          </>
        )}
      </Flex>
    </Flex>
  );
}

export default P2PBalanceTable;

const LoadingRow = () => {
  return (
    <Tr>
      <Td
        height="54px"
        borderColor="extsy.neutral900"
        borderRight="1px solid"
        py="0"
        pos="relative"
        overflow="hidden"
      >
        <SkeletonText noOfLines={1} w="40px" />
      </Td>
      <Td borderColor="extsy.neutral900" borderRight="1px solid">
        <Flex align="center" gap="12px">
          <SkeletonText noOfLines={1} w="40px" />
        </Flex>
      </Td>
      <Td borderColor="extsy.neutral900" borderRight="1px solid">
        <SkeletonText noOfLines={1} w="80px" />
      </Td>
      <Td borderColor="extsy.neutral900" borderRight="1px solid">
        <SkeletonText noOfLines={1} w="40px" />
      </Td>

      <Td borderColor="extsy.neutral900" borderRight="1px solid">
        <SkeletonText noOfLines={1} w="40px" />
      </Td>
      <Td borderColor="extsy.neutral900" borderRight="1px solid">
        <SkeletonText noOfLines={1} w="80px" />
      </Td>
    </Tr>
  );
};
