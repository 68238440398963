import React from "react";

function InitiateWallet() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="44"
      height="33"
      viewBox="0 0 44 33"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.24354 32.4288H39.3816C40.3232 32.4288 41.0868 31.6633 41.0868 30.7195V5.80989C41.0868 4.86367 40.3214 4.09668 39.3778 4.09668H1.34239C0.892111 4.09668 0.527344 4.46234 0.527344 4.91372V30.7084C0.527344 31.6585 1.29545 32.4288 2.24354 32.4288Z"
        fill="#2F2F2F"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.26911 32.4999H6.90451C6.94006 32.4999 6.96917 32.4719 6.96917 32.4374V1.21186C6.96917 1.1774 6.94006 1.14941 6.90451 1.14941H2.26911C1.30691 1.14941 0.527344 1.8988 0.527344 2.82375V30.8255C0.527344 31.7502 1.30691 32.4999 2.26911 32.4999Z"
        fill="#222222"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M31.7492 21.6747H43.4105C43.4449 21.6747 43.4731 21.6461 43.4731 21.611V14.5808C43.4731 14.5456 43.4449 14.5171 43.4105 14.5171H31.7492C30.8125 14.5171 30.0527 15.286 30.0527 16.2341V19.9577C30.0527 20.9061 30.8125 21.6747 31.7492 21.6747Z"
        fill="url(#paint0_linear_2424_109411)"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M34.3152 0.5H2.26152V0.504211C1.30054 0.52737 0.527344 1.31567 0.527344 2.28955C0.527344 3.26343 1.30054 4.05173 2.26152 4.07489V4.0788H36.2518C36.2867 4.0788 36.3151 4.05053 36.3151 4.01534V2.51482C36.3151 1.40199 35.4195 0.5 34.3152 0.5Z"
        fill="#908B8B"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M35.7186 18.0957C35.7186 18.9193 35.0511 19.5868 34.2275 19.5868C33.4039 19.5868 32.7363 18.9193 32.7363 18.0957C32.7363 17.272 33.4039 16.6045 34.2275 16.6045C35.0511 16.6045 35.7186 17.272 35.7186 18.0957Z"
        fill="#0B0A0A"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2424_109411"
          x1="43.4619"
          y1="17.0925"
          x2="38.0567"
          y2="11.0019"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F0C2DB" />
          <stop offset="0.25" stop-color="#F1C8D9" />
          <stop offset="0.6" stop-color="#EDE3E1" />
          <stop offset="1" stop-color="white" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default InitiateWallet;
