import {
  Box,
  Center,
  Flex,
  Image,
  Skeleton,
  SkeletonText,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import EmptyCard from "../../../assets/images/empty-card.svg";
import CustomText from "../../../components/text/CustomText";
import PrimaryButton from "../../../components/button/PrimaryButton";
import CustomModal from "../../../components/modal/customModal";
import VCardInput from "../../../components/form/vCardInput";
import BankOfAmerica from "../../../assets/images/bank-of-america.svg";
import {
  IconAlertTriangle,
  IconCalendar,
  IconDotsVertical,
  IconPencilMinus,
  IconPlus,
  IconTrash,
} from "@tabler/icons-react";
import CustomSelect from "../../../components/form/CustomSelect";
import {
  useAddBanks,
  useDeleteBanks,
  useGetBanks,
  useGetCountries,
  useSetNickname,
  useUpdateBanks,
} from "../../../utils/api/p2p.api";
import { useForm } from "react-hook-form";
import { useCustomAlert } from "../../../utils/api/alert";
import CustomCalendar from "../../../components/form/CustomCalendar";

function PaymentMethods() {
  const {
    register: nicknameRegister,
    handleSubmit: nicknameHandleSubmit,
    formState: { errors: nickNameError, isSubmitting: nickNameIsSubmitting },
  } = useForm();

  const {
    register: bankRegister,
    handleSubmit: bankHandleSubmit,
    reset: bankReset,
    formState: { errors: bankErrors, isSubmitting: bankIsSubmitting },
  } = useForm();

  const {
    register: bankEditRegister,
    handleSubmit: bankEditHandleSubmit,
    setValue, // <--- Add setValue to update form fields
    formState: { errors: bankEditErrors, isSubmitting: bankEditIsSubmitting },
  } = useForm();
  const alert = useCustomAlert();
  const [isMenuOpen, setIsMenuOpen] = useState(-1);
  const [selectedCountry, setSelectedCountry] = useState({ id: "", name: "" });
  const [currentDate, setCurrentDate] = useState<any>();
  const [bankId, setBankId] = useState("");
  const [date, setDate] = useState<any>(null);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const handleCountrySelect = (option: any) => {
    setSelectedCountry({ id: option.value, name: option.label });
  };
  const [selectedBank, setSelectedBank] = useState<any>(null);

  const { data: countriesData } = useGetCountries();
  const { data: banksData, isLoading: banksDataLoading } = useGetBanks();
  console.log(banksData);

  const setNickNameMutation = useSetNickname();
  const addBankMutation = useAddBanks();
  const deleteBankMutation = useDeleteBanks(); // Use the same mutation for both adding and deleting
  const editBankMutation = useUpdateBanks();

  const countryOptions = countriesData?.map((country: any) => ({
    value: country?.id, // Store the ID here
    label: country?.name, // Display the name
  }));
  const {
    isOpen: isNickNameModalOpen,
    onOpen: openNickNameModal,
    onClose: closeNickNameModal,
  } = useDisclosure();
  const {
    isOpen: isAddBankOpen,
    onOpen: openAddBank,
    onClose: closeAddBank,
  } = useDisclosure();
  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure();
  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDisclosure();

  const toggleTab = (index: number) => {
    setIsMenuOpen(isMenuOpen === index ? -1 : index);
  };

  const onSubmit = async (data: any) => {
    try {
      const payload = { nickName: data.nickName };
      await setNickNameMutation.mutateAsync(payload);
      closeNickNameModal();
      openAddBank();
      alert?.mutate({
        message: "NickName updated successfully",
        status: "success",
      });
      bankReset();
    } catch (error: any) {
      alert?.mutate({
        message:
          error?.exception && Array.isArray(error.exception)
            ? error.exception[0]
            : error?.message,
        status: "danger",
      });
    }
  };

  const formatedDate = () => {
    const dateObj = new Date(date);
    return dateObj.toLocaleDateString();
  };

  const onSubmitBank = async (data: any) => {
    try {
      const payload = {
        bankName: data.bankName,
        accountName: data.accountName,
        countryID: selectedCountry?.id,
        zipCode: data.zipCode,
        accountNo: data.accountNumber,
        expiryDate: date,
      };
      await addBankMutation.mutateAsync(payload);
      alert?.mutate({
        message: "Bank added successfully",
        status: "success",
      });

      closeAddBank();
    } catch (error: any) {
      alert?.mutate({
        message:
          error?.exception && Array.isArray(error.exception)
            ? error.exception[0]
            : error?.message,
        status: "danger",
      });
    }
  };

  const onEditSubmitBank = async (data: any) => {
    try {
      const payload = {
        bankID: selectedBank?.id,
        bankName: data.bankName,
        accountName: data.accountName,
        countryID: selectedCountry?.id,
        zipCode: data.zipCode,
        accountNo: data.accountNumber,
        expiryDate: date,
      };
      await editBankMutation.mutateAsync(payload);
      alert?.mutate({
        message: "Bank edited successfully",
        status: "success",
      });
      onEditClose();
      setSelectedCountry({ id: "", name: "" });
      setDate(null);
    } catch (error: any) {
      alert?.mutate({
        message:
          error?.exception && Array.isArray(error.exception)
            ? error.exception[0]
            : error?.message,
        status: "danger",
      });
    }
  };

  useEffect(() => {
    if (isEditOpen && selectedBank) {
      // Populate form fields with existing bank data
      setValue("bankName", selectedBank.bankName);
      setValue("accountName", selectedBank.accountName);
      setValue("zipCode", selectedBank.zipCode);
      setValue("accountNumber", selectedBank.accountNo);

      // Set country based on countryID
      const initialCountry = countryOptions?.find(
        (country: any) => country.value === selectedBank.countryID
      );
      if (initialCountry) {
        setSelectedCountry({
          id: initialCountry.value,
          name: initialCountry.label,
        });
      }

      // Handle date if in "YYYY-MM-DD" format
      if (selectedBank.expiryDate) {
        const parsedDate = new Date(selectedBank.expiryDate);
        setCurrentDate(parsedDate);
        setDate(parsedDate.toISOString());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditOpen, selectedBank]);
  return (
    <>
      {banksData?.length <= 0 ? (
        <Flex
          minH="418px"
          flexDir="column"
          gap="24px"
          align="center"
          justify="center"
        >
          <Image src={EmptyCard} alt="empty" w="100px" h="62px" />
          <CustomText text="No payment method added" color="extsy.neutral400" />
          <PrimaryButton
            title="Add Payment Method"
            maxW="222px"
            onClick={openNickNameModal}
          />
        </Flex>
      ) : (
        <>
          {banksDataLoading ? (
            <Flex gap="24px" align="center" wrap="wrap">
              {/* Repeat this Box component for as many skeletons as you need */}
              {[...Array(2)].map((_, index) => (
                <Box
                  key={index}
                  borderRadius="23px"
                  border="1px solid"
                  borderColor="extsy.neutral800"
                  bg="rgba(255, 255, 255, 0.07)"
                  backdropFilter="blur(17px)"
                  maxW="469px"
                  w="100%"
                  pos="relative"
                  overflow="hidden"
                >
                  {/* Skeleton for the header section */}
                  <Flex
                    justify="space-between"
                    align="center"
                    p="16px 24px"
                    borderBottom="1px solid"
                    borderColor="extsy.neutral800"
                    zIndex="100"
                    pos="relative"
                  >
                    <Flex gap="10px" align="center">
                      <Skeleton
                        boxSize="24px"
                        borderRadius="full"
                        startColor="extsy.neutral800"
                        endColor="extsy.neutral600"
                      />
                      <SkeletonText
                        noOfLines={1}
                        w="100px"
                        startColor="extsy.neutral800"
                        endColor="extsy.neutral600"
                      />
                    </Flex>
                    <Skeleton boxSize="20px" borderRadius="full" />
                  </Flex>

                  {/* Skeleton for the content section */}
                  <Flex
                    mt="10px"
                    zIndex="100"
                    flexDir="column"
                    gap="24px"
                    p="24px"
                  >
                    <Flex align="center" justify="space-between">
                      <SkeletonText noOfLines={1} w="120px" />
                      <SkeletonText noOfLines={1} w="100px" />
                    </Flex>
                    <Flex align="center" justify="space-between">
                      <SkeletonText noOfLines={1} w="120px" />
                      <SkeletonText noOfLines={1} w="100px" />
                    </Flex>
                    <Flex align="center" justify="space-between">
                      <SkeletonText noOfLines={1} w="120px" />
                      <SkeletonText noOfLines={1} w="100px" />
                    </Flex>
                  </Flex>

                  {/* Bottom blur effect */}
                  <Box
                    w="210px"
                    h="210px"
                    bg="extsy.primary500"
                    filter="blur(97px)"
                    pos="absolute"
                    bottom="0"
                    right="0"
                    mb="-216px"
                    mr="129px"
                  />
                </Box>
              ))}
            </Flex>
          ) : (
            <Flex gap="24px" align="center" wrap="wrap">
              {banksData?.map((bank: any, index: any) => (
                <Box
                  key={index}
                  borderRadius="23px"
                  border="1px solid"
                  borderColor="extsy.neutral800"
                  bg="rgba(255, 255, 255, 0.07)"
                  backdropFilter="blur(17px)"
                  maxW="469px"
                  w="100%"
                  pos="relative"
                  overflow="hidden"
                >
                  <Flex
                    justify="space-between"
                    align="center"
                    p="16px 24px"
                    borderBottom="1px solid"
                    borderColor="extsy.neutral800"
                    zIndex="100"
                    pos="relative"
                  >
                    <Flex gap="10px" align="center">
                      <Image src={BankOfAmerica} alt="bank" />
                      <CustomText
                        text={bank?.bankName}
                        size="18px"
                        weight="600"
                        color="extsy.baseWhite"
                      />
                    </Flex>
                    <IconDotsVertical
                      size={20}
                      color="#fafafa"
                      cursor="pointer"
                      onClick={() => toggleTab(index)}
                    />
                    <Flex
                      flexDir="column"
                      gap="24px"
                      p="16px"
                      borderRadius="8px"
                      bg="extsy.neutral900"
                      border="1px solid"
                      borderColor="extsy.neutral800"
                      pos="absolute"
                      right="0"
                      top="0"
                      zIndex="1000"
                      mr="40px"
                      mt="35px"
                      display={isMenuOpen === index ? "flex" : "none"}
                    >
                      <Flex
                        gap="12px"
                        align="flex-end"
                        cursor="pointer"
                        onClick={() => {
                          setSelectedBank(bank);
                          onEditOpen();
                          toggleTab(index);
                        }}
                      >
                        <IconPencilMinus size={20} color="#fafafa" />
                        <CustomText
                          text="Edit"
                          color="extsy.baseWhite"
                          size="14px"
                          cursor
                        />
                      </Flex>
                      <Flex
                        gap="12px"
                        align="flex-end"
                        cursor="pointer"
                        onClick={() => {
                          setBankId(bank?.id);
                          onDeleteOpen();
                          toggleTab(index);
                        }}
                      >
                        <IconTrash size={20} color="#ED3D4E" />
                        <CustomText
                          text="Remove Account"
                          color="extsy.baseWhite"
                          size="14px"
                          cursor
                        />
                      </Flex>
                    </Flex>
                  </Flex>
                  <Flex
                    mt="10px"
                    zIndex="100"
                    flexDir="column"
                    gap="24px"
                    p="24px"
                  >
                    <Flex align="center" justify="space-between">
                      <CustomText text="Account Name" />
                      <CustomText
                        text={bank?.accountName}
                        color="extsy.white"
                      />
                    </Flex>
                    <Flex align="center" justify="space-between">
                      <CustomText text="Account Number" />
                      <CustomText text={bank?.accountNo} color="extsy.white" />
                    </Flex>
                    <Flex align="center" justify="space-between">
                      <CustomText text="Expiry Date" />
                      <CustomText text={bank?.expiryDate} color="extsy.white" />
                    </Flex>
                  </Flex>
                  <Box
                    w="210px"
                    h="210px"
                    bg="extsy.primary500"
                    filter="blur(97px)"
                    pos="absolute"
                    bottom="0"
                    right="0"
                    mb="-216px"
                    mr="129px"
                  />
                </Box>
              ))}
              <Center
                gap={"16px"}
                w={"340px"}
                h={"219px"}
                borderRadius={"23px"}
                border={"2px dashed #908B8B"}
                bg={"rgba(255,255,255,0.07)"}
                backdropFilter={"blur(17px)"}
                cursor={"pointer"}
                onClick={openAddBank}
              >
                <IconPlus color="#FFF" />
                <CustomText
                  text="Add New Bank Account"
                  color="extsy.white"
                  weight="600"
                  size="16px"
                />
              </Center>
            </Flex>
          )}
        </>
      )}
      <CustomModal
        isOpen={isNickNameModalOpen}
        onClose={closeNickNameModal}
        onSubmit={nicknameHandleSubmit(onSubmit)}
        isLoading={nickNameIsSubmitting}
        isDisabled={nickNameIsSubmitting}
        headerText={"Set Nickname"}
        btnText={"Confirm"}
        closeBtntext={"Cancel"}
        width={"600px"}
      >
        <Flex
          flexDir={"column"}
          gap={"32px"}
          py={{ base: "16px", lg: "32px" }}
          px={{ base: "16px", lg: "24px" }}
        >
          <CustomText
            text={
              "It is recommended not to use your real name. Nickname can only be modified once every 180 days."
            }
          />
          <Flex flexDir="column" gap="8px">
            <CustomText text={"Nickname"} size="14px" />
            <VCardInput
              placeholder=""
              type="text"
              register={nicknameRegister}
              name="nickName"
              validation={{
                required: "Nickname is required",
                minLength: {
                  value: 3,
                  message: "Nickname must be at least 3 characters",
                },
              }}
              radius="8px"
              bg={"extsy.neutral1000"}
              invalid={!!nickNameError.nickName}
              errorMsg={nickNameError.nickName?.message}
            />
            <CustomText
              text='Supports numbers, English letters, and special characters ("-" / "_").'
              size="14px"
            />
          </Flex>
        </Flex>
      </CustomModal>
      <CustomModal
        isOpen={isAddBankOpen}
        onClose={() => {
          closeAddBank();
          bankReset();
        }}
        onSubmit={bankHandleSubmit(onSubmitBank)}
        isLoading={bankIsSubmitting || addBankMutation.isPending}
        isDisabled={bankIsSubmitting || addBankMutation.isPending}
        headerText={"Add Payment Method"}
        btnText={"Add"}
        closeBtntext={"Cancel"}
        width={"600px"}
        bgtopBlur="extsy.bgCardTopBlur"
        blurPos="right-top"
      >
        <Flex
          flexDir={"column"}
          gap={"32px"}
          py={{ base: "16px", lg: "32px" }}
          px={{ base: "16px", lg: "24px" }}
        >
          <Box>
            <CustomText text="Country Region" />
            <Flex
              mt="16px"
              gap="16px"
              align="center"
              flexDir={{
                base: "column",
                lg: "row",
              }}
            >
              <Flex flexDir="column" gap="12px" w="100%">
                <CustomText text="Country" size="14px" />
                <CustomSelect
                  options={countryOptions}
                  onSelect={handleCountrySelect}
                  placeholder={selectedCountry.name || "Select Country"}
                />
              </Flex>
              <Flex flexDir="column" gap="12px" w="100%">
                <CustomText text="Zip Code" size="14px" />
                <VCardInput
                  placeholder=""
                  type="text"
                  register={bankRegister}
                  name="zipCode"
                  validation={{
                    required: "Zip code is required",
                  }}
                  invalid={!!bankErrors.zipCode}
                  errorMsg={bankErrors.zipCode?.message}
                  bg={"extsy.neutral1000"}
                />
              </Flex>
            </Flex>
          </Box>
          <Box>
            <CustomText text="Card Information" />
            <Flex flexDir="column" mt="16px" gap="12px" w="100%">
              <CustomText text="Bank Name" size="14px" />
              <VCardInput
                placeholder=""
                type="text"
                register={bankRegister}
                name="bankName"
                validation={{
                  required: "Bank name is required",
                }}
                invalid={!!bankErrors.bankName}
                errorMsg={bankErrors.bankName?.message}
                bg={"extsy.neutral1000"}
              />
            </Flex>
            <Flex flexDir="column" mt="16px" gap="12px" w="100%">
              <CustomText text="Account Name" size="14px" />
              <VCardInput
                placeholder="account name"
                type="text"
                register={bankRegister}
                name="accountName"
                validation={{
                  required: "Account Name is required",
                }}
                invalid={!!bankErrors.accountName}
                errorMsg={bankErrors.accountName?.message}
                bg={"extsy.neutral1000"}
              />
            </Flex>
            <Flex flexDir="column" mt="16px" gap="12px" w="100%">
              <CustomText text="Account Number" size="14px" />
              <VCardInput
                placeholder="0000000000"
                type="text"
                register={bankRegister}
                name="accountNumber"
                validation={{
                  required: "Account number is required",
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "Account number must be a number",
                  },
                }}
                invalid={!!bankErrors.accountNumber}
                errorMsg={bankErrors.accountNumber?.message}
                bg={"extsy.neutral1000"}
              />
            </Flex>
            <Flex flexDir="column" mt="16px" gap="12px" w="100%">
              <CustomText text="Expiry Date" size="14px" />
              <Box pos="relative">
                <Flex
                  cursor="pointer"
                  gap="16px"
                  align="center"
                  borderRadius="12px"
                  bg="extsy.neutral900"
                  boxShadow="0px 2.667px 26.667px 0px rgba(0, 0, 0, 0.15)"
                  height="56px"
                  ps="24px"
                  pe="10px"
                  onClick={() => setIsCalendarOpen(!isCalendarOpen)}
                >
                  <CustomText
                    cursor
                    text={currentDate ? formatedDate() : "Date"}
                    size="12px"
                    color="extsy.baseWhite"
                  />
                  <IconCalendar size="16px" color="#fafafa" />
                </Flex>

                {isCalendarOpen && (
                  <CustomCalendar
                    isCalendarOpen={isCalendarOpen}
                    value={currentDate}
                    fromPayment={true}
                    onChange={(val: any) => {
                      const selectedDate = new Date(val);
                      // Adjust date to UTC midnight
                      const utcDate = new Date(
                        Date.UTC(
                          selectedDate.getFullYear(),
                          selectedDate.getMonth(),
                          selectedDate.getDate()
                        )
                      );
                      setCurrentDate(utcDate.toISOString());
                      setDate(utcDate.toISOString());
                      setIsCalendarOpen(false);
                    }}
                  />
                )}
              </Box>
            </Flex>
          </Box>
        </Flex>
      </CustomModal>
      <CustomModal
        isOpen={isDeleteOpen}
        onClose={onDeleteClose}
        onSubmit={async () => {
          try {
            const payload = {
              bankID: bankId,
            };
            await deleteBankMutation.mutateAsync(payload);
            alert?.mutate({
              message: "Bank account deleted successfully",
              status: "success",
            });
            onDeleteClose();
          } catch (error: any) {
            alert?.mutate({
              message: error?.message,
              status: "danger",
            });
          }
        }}
        isDisabled={deleteBankMutation.isPending}
        isLoading={deleteBankMutation.isPending}
        headerText={"Delete Account"}
        btnText={"Yes, Delete"}
        closeBtntext={"Cancel"}
        width={"600px"}
      >
        <Flex
          flexDir={"column"}
          gap={"32px"}
          py={"32px"}
          px={"24px"}
          align="center"
        >
          <IconAlertTriangle size={40} color="#ED3D4E" />
          <CustomText text="Are you sure you want to delete?" />
        </Flex>
      </CustomModal>
      <CustomModal
        isOpen={isEditOpen}
        onClose={() => {
          setSelectedCountry({ id: "", name: "" });
          setDate(null);
          onEditClose();
        }}
        onSubmit={bankEditHandleSubmit(onEditSubmitBank)}
        isLoading={bankEditIsSubmitting || editBankMutation.isPending}
        isDisabled={bankEditIsSubmitting || editBankMutation.isPending}
        headerText={"Edit Payment Method"}
        btnText={"Edit"}
        closeBtntext={"Cancel"}
        width={"600px"}
        bgtopBlur="extsy.bgCardTopBlur"
        blurPos="right-top"
      >
        <Flex
          flexDir={"column"}
          gap={"32px"}
          py={{ base: "16px", lg: "32px" }}
          px={{ base: "16px", lg: "24px" }}
        >
          <Box>
            <CustomText text="Country Region" />
            <Flex
              mt="16px"
              gap="16px"
              align="center"
              flexDir={{
                base: "column",
                lg: "row",
              }}
            >
              <Flex flexDir="column" gap="12px" w="100%">
                <CustomText text="Country" size="14px" />
                <CustomSelect
                  options={countryOptions}
                  onSelect={handleCountrySelect}
                  placeholder={selectedCountry.name || "Select Country"}
                />
              </Flex>
              <Flex flexDir="column" gap="12px" w="100%">
                <CustomText text="Zip Code" size="14px" />
                <VCardInput
                  placeholder=""
                  type="text"
                  register={bankEditRegister}
                  name="zipCode"
                  validation={{
                    required: "Zip code is required",
                  }}
                  invalid={!!bankEditErrors.zipCode}
                  errorMsg={bankEditErrors.zipCode?.message}
                  bg={"extsy.neutral1000"}
                />
              </Flex>
            </Flex>
          </Box>
          <Box>
            <CustomText text="Card Information" />
            <Flex flexDir="column" mt="16px" gap="12px" w="100%">
              <CustomText text="Bank Name" size="14px" />
              <VCardInput
                placeholder=""
                type="text"
                register={bankEditRegister}
                name="bankName"
                validation={{
                  required: "Bank name is required",
                }}
                invalid={!!bankEditErrors.bankName}
                errorMsg={bankEditErrors.bankName?.message}
                bg={"extsy.neutral1000"}
              />
            </Flex>
            <Flex flexDir="column" mt="16px" gap="12px" w="100%">
              <CustomText text="Account Name" size="14px" />
              <VCardInput
                placeholder="account name"
                type="text"
                register={bankEditRegister}
                name="accountName"
                validation={{
                  required: "Account Name is required",
                }}
                invalid={!!bankEditErrors.accountName}
                errorMsg={bankEditErrors.accountName?.message}
                bg={"extsy.neutral1000"}
              />
            </Flex>
            <Flex flexDir="column" mt="16px" gap="12px" w="100%">
              <CustomText text="Account Number" size="14px" />
              <VCardInput
                placeholder="0000000000"
                type="text"
                register={bankEditRegister}
                name="accountNumber"
                validation={{
                  required: "Account number is required",
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "Account number must be a number",
                  },
                }}
                invalid={!!bankEditErrors.accountNumber}
                errorMsg={bankEditErrors.accountNumber?.message}
                bg={"extsy.neutral1000"}
              />
            </Flex>
            <Flex flexDir="column" mt="16px" gap="12px" w="100%">
              <CustomText text="Expiry Date" size="14px" />
              <Box pos="relative">
                <Flex
                  cursor="pointer"
                  gap="16px"
                  align="center"
                  borderRadius="12px"
                  bg="extsy.neutral900"
                  boxShadow="0px 2.667px 26.667px 0px rgba(0, 0, 0, 0.15)"
                  height="56px"
                  ps="24px"
                  pe="10px"
                  onClick={() => setIsCalendarOpen(!isCalendarOpen)}
                >
                  <CustomText
                    cursor
                    text={currentDate ? formatedDate() : "Date"}
                    size="12px"
                    color="extsy.baseWhite"
                  />
                  <IconCalendar size="16px" color="#fafafa" />
                </Flex>

                {isCalendarOpen && (
                  <CustomCalendar
                    isCalendarOpen={isCalendarOpen}
                    value={currentDate}
                    fromPayment={true}
                    onChange={(val: any) => {
                      const selectedDate = new Date(val);
                      // Adjust date to UTC midnight
                      const utcDate = new Date(
                        Date.UTC(
                          selectedDate.getFullYear(),
                          selectedDate.getMonth(),
                          selectedDate.getDate()
                        )
                      );
                      setCurrentDate(utcDate.toISOString());
                      setDate(utcDate.toISOString());
                      setIsCalendarOpen(false);
                    }}
                  />
                )}
              </Box>
            </Flex>
          </Box>
        </Flex>
      </CustomModal>
    </>
  );
}

export default PaymentMethods;
