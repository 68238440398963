import { Box, Flex, useMediaQuery } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { Outlet, useLocation, useSearchParams } from "react-router-dom";
import Sidebar from "../components/sidebar";
import Navbar from "../components/Header/Navbar";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import SecondaryValidationAlert from "../components/alerts/SecondaryValidationAlert";
import { useGetLoginToken } from "../utils/api/auth.api";

function DashboardLayout() {
  const queryClient = useQueryClient();
  const { data: token, isLoading: tokenLoading } = useQuery({
    queryKey: ["token"],
    queryFn: () => localStorage.getItem("token"),
  });
  const loggedInWithToken = useGetLoginToken();

  const [searchParams] = useSearchParams();
  const tempAuthToken = searchParams.get("authToken");

  const location = useLocation(); // Get current location

  const [isLessThan1600] = useMediaQuery("(max-width: 1600px)");

  console.log("tempAuthToken", tempAuthToken);

  if (
    !token &&
    !tempAuthToken &&
    !loggedInWithToken?.isPending &&
    !tokenLoading
  ) {
    window?.open(
      `${process.env.REACT_APP_EXTSY_PRE_RELEASE_DOMAIN}/pages/auth/login`,
      "_self"
    );
  }

  // Check if the current path is "/p2p/appealDetail"
  const shouldShowSidebar = location.pathname !== "/p2p/appeal-detail";

  useEffect(() => {
    if (tempAuthToken) {
      loggedInWithToken
        .mutateAsync({ token: tempAuthToken })
        .then((result) => {
          localStorage.setItem("token", result?.token?.access_token);
          queryClient.invalidateQueries({ queryKey: ["token"] });
          queryClient.invalidateQueries({ queryKey: ["getLoggedInUser"] });
          searchParams.delete("authToken");
        })
        .catch((err) => {
          window?.open(
            `${process.env.REACT_APP_EXTSY_PRE_RELEASE_DOMAIN}/pages/auth/login`,
            "_self"
          );
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tempAuthToken]);

  return (
    <Flex
      p={{ base: "16px", lg: "24px" }}
      gap={isLessThan1600 ? "32px" : "48px"}
      alignItems="flex-start"
      bg="extsy.bg"
      minH="100vh"
      maxH="100vh"
      overflow="hidden"
      position={"relative"}
    >
      {shouldShowSidebar && ( // Conditionally render Sidebar
        <Box display={{ base: "none", lg: "block" }}>
          {/*     Hiding Sidebar to show Mobile Drawer on Mobile devices     */}
          <Sidebar />
        </Box>
      )}
      <Flex gap="24px" flexDir="column" width="100%">
        <Navbar />
        <Box
          overflowY="auto"
          maxH="calc(100vh - 120px)"
          // pb={{ base: "0px", lg: "0" }}
        >
          <Outlet />
        </Box>
      </Flex>
      <Box position={"absolute"} bottom={30} right={30}>
        <SecondaryValidationAlert />
      </Box>
    </Flex>
  );
}

export default DashboardLayout;
