import React, { useState } from "react";
import CustomModal from "./customModal";
import { Flex } from "@chakra-ui/react";
import CustomText from "../text/CustomText";
import { useLogout } from "../../utils/api/auth.api";
import { useDisconnect } from "wagmi";
import { useDeleteDeviceToken } from "../../utils/api/settings.api";
import { requestForToken } from "../../config/firebaseConfig";
import { getAuth, signOut } from "firebase/auth";
import { useQuery } from "@tanstack/react-query";

function LogoutModal({ isOpen, onClose }: any) {
  const auth = getAuth();

  const [isLoading, setIsLoading] = useState(false);

  const { disconnect } = useDisconnect();

  const { data: userInfo }: any = useQuery({
    queryKey: ["getLoggedInUser"],
  });
  const deleteDeviceToken = useDeleteDeviceToken();
  const logout = useLogout();

  const userLogout = async () => {
    if (userInfo?.auth_type !== "EMAIL") {
      await signOut(auth);
    }
    logout.mutateAsync();
    console.log(process.env.REACT_APP_EXTSY_PRE_RELEASE_DOMAIN);
    const stagingDomain = process.env.REACT_APP_EXTSY_PRE_RELEASE_DOMAIN;

    // Check if the environment variable is defined
    if (!stagingDomain) {
      console.error("REACT_APP_EXTSY_STAGING_DOMAIN is not defined.");
      return; // Optionally handle this case as needed
    }

    // Navigate to the staging domain
    window.location.href = stagingDomain;
    onClose();
    setIsLoading(false);
  };

  // Remove device token from the server on logout
  const removeToken = async () => {
    setIsLoading(true);
    try {
      const tokenPromise = requestForToken();
      const timeoutPromise = new Promise<string | null>((resolve) =>
        setTimeout(() => resolve(null), 5000)
      );

      const token = await Promise.race([tokenPromise, timeoutPromise]);

      if (token) {
        deleteDeviceToken
          .mutateAsync({ device_token: token })
          .then(() => {
            userLogout();
          })
          .catch(() => {
            userLogout();
          });
      } else {
        userLogout();
      }
    } catch (error: any) {
      userLogout();
    }
  };

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={async () => {
        disconnect();
        removeToken();
      }}
      headerText={"Logout"}
      btnText={"Confirm, Logout"}
      closeBtntext={"Cancel"}
      width={"600px"}
      bgtopBlur="extsy.primary"
      isLoading={isLoading}
    >
      <Flex p="32px" flexDir="column" align="center">
        <CustomText text="Are you sure you want to end this session?" />
      </Flex>
    </CustomModal>
  );
}

export default LogoutModal;
