import {
  Box,
  Flex,
  Image,
  SkeletonText,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import CustomText from "../../components/text/CustomText";
import {
  IconAlertHexagon,
  IconCalendar,
  IconCheck,
  IconChevronDown,
  IconChevronUp,
} from "@tabler/icons-react";
import ArrowBack from "../../assets/images/black-left.svg";
import ArrowForward from "../../assets/images/black-right.svg";
import { useTranslation } from "react-i18next";
import GradientText from "../../components/text/GradientText";
import TrophyCup from "../../assets/images/trophy-cup.svg";
import TableSearchInput from "../../components/form/TableSearchInput";
import CustomCalendar from "../../components/form/CustomCalendar";
import {
  useAfterTrxSuccess,
  useGetUserTickets,
} from "../../utils/api/raffle.api";
import ReactPaginate from "react-paginate";
import moment from "moment";
import NoRaffleIcon from "../../assets/images/no-raffle.svg";
import PrimaryButton from "../../components/button/PrimaryButton";
import {
  RAFFLE_CONTRACT_ABI,
  RAFFLE_CONTRACT_ADDRESS,
} from "../../utils/smartContracts/raffleSmartContract";
import { useWallet } from "@tronweb3/tronwallet-adapter-react-hooks";
import OutsideClickHandler from "../../hooks/CustomOutsideClick";
import CustomModal from "../../components/modal/customModal";
import { formatNumber } from "../../utils/functions/formatNumber";
import ConnectComponent from "./ConnectComponent";
import USDTTrophy from "../../assets/images/usdt-trophy.svg";
import { waitForTransactionConfirmation } from "../../utils/functions/trxConfirmation";
import { useCustomAlert } from "../../utils/api/alert";

function MyRaffleHistory() {
  const { t } = useTranslation();

  const { connected } = useWallet();

  const filterRef = useRef(null);

  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [date, setDate] = useState("");
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [activeFilter, setActiveFilter] = useState("All");
  const [search, setSearch] = useState("");
  const [offset, setOffset] = useState(0);
  const [limit] = useState(7);
  const [pageCount, setPageCount] = useState(0);
  const [sortKey, setSortKey] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [selectedRaffle, setSelectedRaffle] = useState<any>(null);
  const [isClaimLoading, setIsClaimLoading] = useState(false);
  const [isCollectOpen, setIsCollectOpen] = useState(false);
  const [tronWeb, setTronWeb] = useState<any>(undefined);

  const {
    data: userTickets,
    isLoading: userTicketsLoading,
    refetch,
  } = useGetUserTickets(
    limit,
    offset,
    activeFilter,
    date,
    search,
    sortKey,
    sortOrder
  );
  const afterTrxSuccess = useAfterTrxSuccess();
  const alert = useCustomAlert();

  const formatedDate = () => {
    const dateObj = new Date(date);
    if (dateObj.toString() === "Invalid Date") {
      return "";
    } else {
      return dateObj.toLocaleDateString();
    }
  };

  const FILTER_ITEMS = [
    {
      id: "All",
      name: "All",
    },
    {
      id: "Won",
      name: "Won",
    },
    {
      id: "Lost",
      name: "Lost",
    },
    {
      id: "Draw Pending",
      name: "Draw Pending",
    },
  ];

  const checkUnclaimTickets = (raffle: any) => {
    return raffle?.tickets?.find(
      (ticket: any) => !ticket?.is_claimed && Number(ticket?.won_amount) > 0
    );
  };

  const getWinAmount = (raffle: any) => {
    return raffle?.tickets?.reduce(
      (acc: any, ticket: any) => acc + ticket?.won_amount,
      0
    );
  };

  // Claim prize
  const claimPrize = async () => {
    setIsClaimLoading(true);
    try {
      const contract = await tronWeb.contract(
        RAFFLE_CONTRACT_ABI,
        RAFFLE_CONTRACT_ADDRESS
      );

      const result = await contract
        .claimPrize(selectedRaffle?.raffle_round)
        .send({
          feeLimit: tronWeb.toSun(2000),
        });
      console.log("-------result", result);
      await waitForTransactionConfirmation(result, tronWeb);

      if (result) {
        afterTrxSuccess
          .mutateAsync()
          .then((result) => {
            alert.mutate({
              message: "Claim successful!",
              status: "success",
            });
            setTimeout(() => {
              setIsClaimLoading(false);
              setIsCollectOpen(false);
            }, 5000);
          })
          .catch((err) => {
            alert.mutate({
              message: "Claim successfull",
              status: "success",
            });
            setTimeout(() => {
              setIsClaimLoading(false);
              setIsCollectOpen(false);
            }, 5000);
          });
      } else {
        alert.mutate({
          message: "Claim failed",
          status: "danger",
        });
        await afterTrxSuccess
          .mutateAsync()
          .then((result) => {
            setIsClaimLoading(false);
          })
          .catch((err) => {
            setIsClaimLoading(false);
          });
      }
    } catch (e: any) {
      setIsClaimLoading(false);
      alert.mutate({
        message: e.message || e?.error || e || "",
        status: "danger",
      });
      console.log("Error: ", e);
    }
  };

  const waitForTronWeb = async () => {
    while (!window.tronWeb) {
      await new Promise((resolve) => setTimeout(resolve, 500));
    }
    return window.tronWeb;
  };

  // Invoke when user click to request another page.
  const handlePageClick = (event: any) => {
    setOffset(event?.selected * limit);
  };

  const handleSortChange = (key: string, order: string) => {
    setSortKey(key);
    setSortOrder(order);
  };

  const getOrdinalSuffix = (number: number) => {
    // Convert number to an integer, just in case it's passed as a string
    const num = parseInt(number?.toString(), 10);

    // Get the last two digits to handle exceptions like 11th, 12th, 13th
    const lastTwoDigits = num % 100;

    // Get the last digit of the number
    const lastDigit = num % 10;

    // Special cases for 11, 12, 13
    if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
      return `${num}th`;
    }

    // General cases
    switch (lastDigit) {
      case 1:
        return `${num}st`;
      case 2:
        return `${num}nd`;
      case 3:
        return `${num}rd`;
      default:
        return `${num}th`;
    }
  };

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset, date, activeFilter, sortKey, sortOrder]);

  useEffect(() => {
    const refetchTimeOut = setTimeout(() => {
      refetch();
    }, 1000);
    return () => clearTimeout(refetchTimeOut);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    if (userTickets?.total) {
      setPageCount(Math.ceil(userTickets?.total / limit));
    }
  }, [userTickets, limit]);

  useEffect(() => {
    waitForTronWeb().then((result) => {
      setTronWeb(result);
    });
  }, []);

  return (
    <>
      <Flex
        flexDir={{
          lg: "row",
          base: "column",
        }}
        align={{ lg: "center" }}
        justify="space-between"
        gap="10px"
      >
        <CustomText
          text="Your Raffles"
          size={{ base: "18px", lg: "24px" }}
          weight={{ base: "600", lg: "700" }}
          lh={{ lg: "34px" }}
          family="Metropolis"
          color="extsy.baseWhite"
        />
        <Flex
          gap="10px"
          align="center"
          flexDir={{
            sm: "row",
            base: "column",
          }}
        >
          <TableSearchInput
            placeholder="Search Raffle"
            minW={{
              md: "250px",
            }}
            value={search}
            onChange={(e: any) => setSearch(e.target.value)}
          />
          <Flex gap="10px" align="center">
            <Box pos="relative">
              <Flex
                cursor="pointer"
                gap="16px"
                align="center"
                borderRadius="666px"
                bg="extsy.neutral900"
                boxShadow="0px 2.667px 26.667px 0px rgba(0, 0, 0, 0.15)"
                height="38px"
                minW="120px"
                ps="24px"
                pe="10px"
                onClick={() => setIsCalendarOpen(!isCalendarOpen)}
              >
                <CustomText
                  cursor
                  text={formatedDate() || "Select Date"}
                  size="12px"
                  color="extsy.baseWhite"
                />
                <IconCalendar
                  size="16px"
                  color="#fafafa"
                  style={{
                    marginLeft: "auto",
                  }}
                />
              </Flex>
              <OutsideClickHandler
                isOpen={isCalendarOpen}
                onClose={() => {
                  setIsCalendarOpen(false);
                }}
              >
                <CustomCalendar
                  isCalendarOpen={isCalendarOpen}
                  value={date}
                  onChange={(val: any) => {
                    setDate(val);
                    setIsCalendarOpen(false);
                  }}
                />
              </OutsideClickHandler>
            </Box>
            <Box pos="relative">
              <Flex
                height="38px"
                minW="149px"
                align="center"
                justify="space-between"
                cursor="pointer"
                borderRadius="666px"
                bg="extsy.neutral900"
                boxShadow="0px 2.667px 26.667px 0px rgba(0, 0, 0, 0.15)"
                ps="24px"
                pe="10px"
                onClick={() => {
                  setIsFilterOpen(!isFilterOpen);
                }}
              >
                <CustomText
                  cursor="pointer"
                  text={
                    FILTER_ITEMS.find((item) => item.id === activeFilter)?.name
                  }
                  size="12px"
                  color="extsy.baseWhite"
                />
                {isFilterOpen ? (
                  <IconChevronUp color="#FAFAFA" size="16px" />
                ) : (
                  <IconChevronDown color="#FAFAFA" size="16px" />
                )}
              </Flex>
              <OutsideClickHandler
                isOpen={isFilterOpen}
                onClose={() => {
                  setIsFilterOpen(false);
                }}
              >
                <Flex
                  flexDir="column"
                  gap="12px"
                  p="16px"
                  borderRadius="16px"
                  border="1px solid"
                  borderColor="extsy.neutral800"
                  bg="extsy.neutral900"
                  minW="150px"
                  pos="absolute"
                  top="0"
                  marginTop="50px"
                  zIndex="100"
                  ref={filterRef}
                >
                  {FILTER_ITEMS.map((item, index) => (
                    <Flex
                      align="center"
                      justify="space-between"
                      py="8px"
                      cursor="pointer"
                      key={index}
                      onClick={() => {
                        setIsFilterOpen(false);
                        setActiveFilter(item.id);
                      }}
                    >
                      <CustomText
                        text={item?.name}
                        size="14px"
                        color={
                          item?.id === activeFilter
                            ? "extsy.baseWhite"
                            : "extsy.neutral300"
                        }
                        cursor="pointer"
                      />
                      {item?.id === activeFilter && (
                        <IconCheck size="16px" color="#FAFAFA" />
                      )}
                    </Flex>
                  ))}
                </Flex>
              </OutsideClickHandler>
            </Box>
          </Flex>
        </Flex>
      </Flex>
      {!userTicketsLoading && !userTickets?.data?.length ? (
        <EmptyRaffle />
      ) : (
        <Flex flexDir="column" gap="24px">
          <TableContainer>
            <Table border="1px solid" color="extsy.neutral900">
              <Thead bg="extsy.neutral900" height="40px">
                <Tr>
                  {[
                    { label: "Raffle ID", key: "raffle_id" },
                    { label: "Ticket Number", key: "ticket_number" },
                    { label: "Date and Time", key: "date" },
                    { label: "Prize", key: "prize" },
                    { label: "Outcome", key: "status" },
                  ].map(({ label, key }) => (
                    <Th key={key} borderColor="extsy.neutral900">
                      <Flex align="center" gap="4px">
                        <CustomText
                          text={label}
                          size="13px"
                          color="extsy.baseWhite"
                          weight="500"
                          ls="-0.13px"
                        />
                        <Flex flexDir="column">
                          <IconChevronUp
                            cursor="pointer"
                            color={
                              key === sortKey && sortOrder === "ASC"
                                ? "#EFA3D1"
                                : "#fafafa"
                            }
                            size="14px"
                            onClick={() => handleSortChange(key, "ASC")}
                          />
                          <IconChevronDown
                            cursor="pointer"
                            color={
                              key === sortKey && sortOrder === "DESC"
                                ? "#EFA3D1"
                                : "#fafafa"
                            }
                            size="14px"
                            style={{ marginTop: -7 }}
                            onClick={() => handleSortChange(key, "DESC")}
                          />
                        </Flex>
                      </Flex>
                    </Th>
                  ))}
                </Tr>
              </Thead>
              <Tbody>
                {userTicketsLoading
                  ? [1, 2, 3, 4, 5].map((_, index) => (
                      <LoadingRow key={index} />
                    ))
                  : userTickets?.data?.map((raffle: any, index: number) => (
                      <Tr key={index}>
                        <Td
                          height="54px"
                          borderColor="extsy.neutral900"
                          borderRight="1px solid"
                          py="0"
                          pos="relative"
                          overflow="hidden"
                        >
                          <CustomText
                            text={raffle?.raffle_round}
                            size="14px"
                            weight="500"
                          />
                          {raffle?.isActive && (
                            <Box
                              h="53px"
                              w="53px"
                              borderRadius="53px"
                              filter="blur(20px)"
                              bg="extsy.primary500"
                              pos="absolute"
                              left="0"
                              bottom="0"
                              ml="-36px"
                            />
                          )}
                        </Td>
                        <Td
                          borderColor="extsy.neutral900"
                          borderRight="1px solid"
                        >
                          <Flex align="center" gap="12px">
                            {!raffle?.tickets?.length ? (
                              <CustomText
                                text={`0 Participation`}
                                size="14px"
                                weight="500"
                              />
                            ) : (
                              raffle?.tickets
                                ?.sort(
                                  (a: any, b: any) =>
                                    a?.ticket_id - b?.ticket_id
                                )
                                ?.map((ticket: any, idx: number) => (
                                  <>
                                    <Flex align="center" gap="8px">
                                      <CustomText
                                        key={idx}
                                        text={`#${ticket?.ticket_id}`}
                                        size="14px"
                                        weight="500"
                                        color={
                                          ticket?.isWon ? "extsy.baseWhite" : ""
                                        }
                                      />
                                      {Number(ticket?.won_amount) > 0 && (
                                        <Image
                                          src={TrophyCup}
                                          alt="trophy-cup"
                                          h="16px"
                                          w="12px"
                                        />
                                      )}
                                    </Flex>
                                    {raffle?.tickets?.length - 1 !== idx && (
                                      <Box
                                        w="1px"
                                        h="16px"
                                        bg="extsy.neutral800"
                                      />
                                    )}
                                    {/* <Box w="1px" h="16px" bg="extsy.neutral800" /> */}
                                  </>
                                ))
                            )}
                          </Flex>
                        </Td>
                        <Td
                          borderColor="extsy.neutral900"
                          borderRight="1px solid"
                        >
                          <CustomText
                            text={moment(raffle?.start_time).format(
                              "DD-MM-YYYY HH:mm A"
                            )}
                            size="14px"
                            weight="500"
                          />
                        </Td>
                        <Td
                          borderColor="extsy.neutral900"
                          borderRight="1px solid"
                        >
                          <CustomText
                            text={`${raffle?.prize_pool} USDT`}
                            size="14px"
                            weight="500"
                          />
                        </Td>
                        {checkUnclaimTickets(raffle) ? (
                          <Td
                            borderColor="extsy.neutral900"
                            borderRight="1px solid"
                            overflow="hidden"
                            pos="relative"
                          >
                            <PrimaryButton
                              title="Claim Prize"
                              height="28px"
                              maxW="95px"
                              size="12px"
                              weight="500"
                              onClick={() => {
                                setSelectedRaffle(raffle);
                                setIsCollectOpen(true);
                              }}
                            />
                          </Td>
                        ) : raffle?.status === "WON" ? (
                          <Td
                            borderColor="extsy.neutral900"
                            borderRight="1px solid"
                            overflow="hidden"
                            pos="relative"
                          >
                            <Flex justify="space-between" align="center">
                              <GradientText
                                text={"Won"}
                                size="14px"
                                weight="500"
                                bg="extsy.g1"
                              />
                              <Image
                                src={TrophyCup}
                                alt="trophy-cup"
                                h="22px"
                                w="17px"
                              />
                            </Flex>
                            <Box
                              w="63px"
                              h="63px"
                              pos="absolute"
                              borderRadius="63px"
                              filter="blur(20px)"
                              bg="#15B097"
                              bottom="0"
                              right="0"
                              mb="-45px"
                              mr="-18px"
                            />
                          </Td>
                        ) : (
                          <Td
                            borderColor="extsy.neutral900"
                            borderRight="1px solid"
                          >
                            <CustomText
                              text={raffle?.status}
                              size="14px"
                              weight="500"
                              transform="capitalize"
                            />
                          </Td>
                        )}
                      </Tr>
                    ))}
              </Tbody>
            </Table>
          </TableContainer>
          <Flex gap="16px" px="20px" align="center" justify="flex-end">
            {userTicketsLoading ? (
              <SkeletonText noOfLines={1} w="120px" />
            ) : (
              <>
                <CustomText
                  text={`${offset + 1}-${
                    offset + userTickets?.data?.length
                  } ${t("of")} ${userTickets?.total}`}
                  size="13px"
                  weight="500"
                  color="extsy.neutral300"
                />
                <ReactPaginate
                  breakLabel="..."
                  nextLabel={
                    <Image
                      src={ArrowForward}
                      alt="arrow-forward"
                      cursor="pointer"
                    />
                  }
                  previousLabel={
                    <Image src={ArrowBack} alt="arrow-back" cursor="pointer" />
                  }
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={3}
                  pageCount={pageCount}
                  renderOnZeroPageCount={null}
                  containerClassName="react-pagination"
                  activeClassName="active-pagination"
                />
              </>
            )}
          </Flex>
        </Flex>
      )}
      <CustomModal
        isOpen={isCollectOpen}
        onClose={() => setIsCollectOpen(false)}
        onSubmit={() => {
          setIsCollectOpen(false);
        }}
        headerText={"Collect Your Prize"}
        btnText={"Connect Wallet"}
        closeBtntext={"Close"}
        width={"700px"}
        bgtopBlur="#15B097"
        noFooter
      >
        <Flex p={{ base: "16px", lg: "24px" }} flexDir="column" gap="24px">
          <Flex flexDir="column" gap="16px" py="12px" align="center">
            <Image src={USDTTrophy} alt="trophy" w="141px" h="130px" />
            <GradientText
              text={`${formatNumber(getWinAmount(selectedRaffle), 2)} USDT`}
              size="32px"
              lh="40px"
              bg="extsy.g1"
              weight="700"
            />
            {selectedRaffle?.tickets
              ?.filter((ticket: any) => Number(ticket?.won_amount) > 0)
              ?.map((ticket: any, idx: number) => (
                <CustomText
                  key={idx}
                  text={`Ticket #${
                    ticket?.ticket_id
                  } secured ${getOrdinalSuffix(
                    ticket?.won_prize
                  )} position - ${formatNumber(ticket?.won_amount, 2)} USDT`}
                  size="14px"
                  weight="500"
                  color="extsy.neutral300"
                />
              ))}
            <CustomText
              text={`Follow the instructions below and collect your prize.`}
              align="center"
            />
          </Flex>
          <Flex
            gap="10px"
            p="16px"
            borderRadius="8px"
            bg="extsy.bg"
            align="center"
            h="66px"
          >
            <Box>
              <IconAlertHexagon size="20px" color="#E35CAD" />
            </Box>
            <CustomText
              text="Please make sure the connected wallet is for USDT TRC20, otherwise your funds may be lost."
              size="14px"
            />
          </Flex>

          {connected ? (
            <PrimaryButton
              isLoading={isClaimLoading || afterTrxSuccess?.isPending}
              title={"Claim"}
              onClick={() => {
                claimPrize();
              }}
            />
          ) : (
            <ConnectComponent />
          )}
        </Flex>
      </CustomModal>
    </>
  );
}

const LoadingRow = () => {
  return (
    <Tr>
      <Td
        height="54px"
        borderColor="extsy.neutral900"
        borderRight="1px solid"
        py="0"
        pos="relative"
        overflow="hidden"
      >
        <SkeletonText noOfLines={1} w="80px" />
      </Td>
      <Td borderColor="extsy.neutral900" borderRight="1px solid">
        <Flex align="center" gap="12px">
          {[1, 2, 3, 4].map((_, index) => (
            <SkeletonText noOfLines={1} w="40px" key={index} />
          ))}
        </Flex>
      </Td>
      <Td borderColor="extsy.neutral900" borderRight="1px solid">
        <SkeletonText noOfLines={1} w="80px" />
      </Td>
      <Td borderColor="extsy.neutral900" borderRight="1px solid">
        <SkeletonText noOfLines={1} w="80px" />
      </Td>

      <Td borderColor="extsy.neutral900" borderRight="1px solid">
        <SkeletonText noOfLines={1} w="80px" />
      </Td>
    </Tr>
  );
};

const EmptyRaffle = () => {
  return (
    <Flex
      minH={{ base: "250px", lg: "350px" }}
      flexDir="column"
      gap="12px"
      align="center"
      justify="center"
    >
      <Image src={NoRaffleIcon} alt="no-raffle" />
      <CustomText
        text="You have no raffle"
        size="14px"
        color="extsy.neutral500"
      />
    </Flex>
  );
};

export default MyRaffleHistory;
