export const RAFFLE_CONTRACT_ADDRESS = "TTmo6j3JDqLZ7YEr447SyVDt77BZcoKhKA";
export const RAFFLE_CONTRACT_ABI = [
  {
    inputs: [
      {
        internalType: "address",
        name: "_usdt",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "_startTime",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "_feeReceiver",
        type: "address",
      },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "owner",
        type: "address",
      },
    ],
    name: "OwnableInvalidOwner",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "OwnableUnauthorizedAccount",
    type: "error",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "feeReceiver",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "feeAmount",
        type: "uint256",
      },
    ],
    name: "FeeWithdrawn",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "previousOwner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "OwnershipTransferred",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "raffleRoundNumber",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "ticketNumber",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "address",
        name: "ticketOwner",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "wonPrize",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "wonAmount",
        type: "uint256",
      },
    ],
    name: "PrizeClaimed",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "raffleRoundNumber",
        type: "uint256",
      },
    ],
    name: "RaffleDrawn",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "raffleRoundNumber",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "endTime",
        type: "uint256",
      },
    ],
    name: "RaffleRoundEnded",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "raffleRoundNumber",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "startTime",
        type: "uint256",
      },
    ],
    name: "RaffleRoundStarted",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "raffleRoundNumber",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "ticketNumber",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "address",
        name: "ticketOwner",
        type: "address",
      },
    ],
    name: "TicketBought",
    type: "event",
  },
  {
    inputs: [],
    name: "FEE_PERCENTAGE",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "FEE_RECEIVER",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "FIRST_PRIZE_PERCENTAGE",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "RAFFLE_TIME",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "SECOND_PRIZE_PERCENTAGE",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "THIRD_PRIZE_PERCENTAGE",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "TOTAL_PERCENTAGE",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_ticketPlanPrice",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_ticketPlanTickets",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_isActive",
        type: "uint256",
      },
    ],
    name: "addTicketPlan",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_ticketPlanId",
        type: "uint256",
      },
    ],
    name: "buyTicket",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_raffleRoundNumber",
        type: "uint256",
      },
    ],
    name: "claimPrize",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "decimals",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_raffleRoundNumber",
        type: "uint256",
      },
    ],
    name: "drawRaffle",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "getCurrentRaffleRound",
    outputs: [
      {
        components: [
          {
            internalType: "uint256",
            name: "startTime",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "endTime",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "totalTicketsSold",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "prizePool",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "isDrawn",
            type: "uint256",
          },
          {
            internalType: "uint256[3]",
            name: "winningTickets",
            type: "uint256[3]",
          },
          {
            internalType: "address",
            name: "firstPrizeWinner",
            type: "address",
          },
          {
            internalType: "address",
            name: "secondPrizeWinner",
            type: "address",
          },
          {
            internalType: "address",
            name: "thirdPrizeWinner",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "firstPrizeAmount",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "secondPrizeAmount",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "thirdPrizeAmount",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "ticketStartNumber",
            type: "uint256",
          },
        ],
        internalType: "struct ExtsyRaffle.RaffleRound",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_raffleRoundNumber",
        type: "uint256",
      },
    ],
    name: "getRaffleRound",
    outputs: [
      {
        components: [
          {
            internalType: "uint256",
            name: "startTime",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "endTime",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "totalTicketsSold",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "prizePool",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "isDrawn",
            type: "uint256",
          },
          {
            internalType: "uint256[3]",
            name: "winningTickets",
            type: "uint256[3]",
          },
          {
            internalType: "address",
            name: "firstPrizeWinner",
            type: "address",
          },
          {
            internalType: "address",
            name: "secondPrizeWinner",
            type: "address",
          },
          {
            internalType: "address",
            name: "thirdPrizeWinner",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "firstPrizeAmount",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "secondPrizeAmount",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "thirdPrizeAmount",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "ticketStartNumber",
            type: "uint256",
          },
        ],
        internalType: "struct ExtsyRaffle.RaffleRound",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_raffleRoundNumber",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_ticketNumber",
        type: "uint256",
      },
    ],
    name: "getTicket",
    outputs: [
      {
        components: [
          {
            internalType: "uint256",
            name: "id",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "raffleRoundNumber",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "ticketNumber",
            type: "uint256",
          },
          {
            internalType: "address",
            name: "ticketOwner",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "wonPrize",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "wonAmount",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "isClaimed",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "buyTime",
            type: "uint256",
          },
        ],
        internalType: "struct ExtsyRaffle.Ticket",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_ticketPlanId",
        type: "uint256",
      },
    ],
    name: "getTicketPlan",
    outputs: [
      {
        components: [
          {
            internalType: "uint256",
            name: "ticketPlanId",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "ticketPlanPrice",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "ticketPlanTickets",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "isActive",
            type: "uint256",
          },
        ],
        internalType: "struct ExtsyRaffle.TicketPlans",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_user",
        type: "address",
      },
    ],
    name: "getUserData",
    outputs: [
      {
        components: [
          {
            internalType: "address",
            name: "user",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "totalTicketBought",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "totalAmountSpent",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "totalTicketWon",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "totalAmountWon",
            type: "uint256",
          },
        ],
        internalType: "struct ExtsyRaffle.UserData",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_user",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "_raffleRoundNumber",
        type: "uint256",
      },
    ],
    name: "getUserTickets",
    outputs: [
      {
        internalType: "uint256[]",
        name: "",
        type: "uint256[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "raffleRoundNumber",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    name: "raffleRounds",
    outputs: [
      {
        internalType: "uint256",
        name: "startTime",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "endTime",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "totalTicketsSold",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "prizePool",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "isDrawn",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "firstPrizeWinner",
        type: "address",
      },
      {
        internalType: "address",
        name: "secondPrizeWinner",
        type: "address",
      },
      {
        internalType: "address",
        name: "thirdPrizeWinner",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "firstPrizeAmount",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "secondPrizeAmount",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "thirdPrizeAmount",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "ticketStartNumber",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "renounceOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_fee",
        type: "uint256",
      },
    ],
    name: "setFee",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_feeReceiver",
        type: "address",
      },
    ],
    name: "setFeeReceiver",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_raffleTime",
        type: "uint256",
      },
    ],
    name: "setRaffleTime",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_usdt",
        type: "address",
      },
    ],
    name: "setUsdt",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_decimals",
        type: "uint256",
      },
    ],
    name: "setUsdtDecimals",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    name: "ticketPlans",
    outputs: [
      {
        internalType: "uint256",
        name: "ticketPlanId",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "ticketPlanPrice",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "ticketPlanTickets",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "isActive",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    name: "tickets",
    outputs: [
      {
        internalType: "uint256",
        name: "id",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "raffleRoundNumber",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "ticketNumber",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "ticketOwner",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "wonPrize",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "wonAmount",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "isClaimed",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "buyTime",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "totalFeeCollected",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "totalFeeWithdrawn",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "totalFirstPrizeAmount",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "totalPrizeAmount",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "totalPrizePool",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "totalSecondPrizeAmount",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "totalThirdPrizeAmount",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "totalTicketPlans",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "totalTicketsSold",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_ticketPlanId",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_ticketPlanPrice",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_ticketPlanTickets",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_isActive",
        type: "uint256",
      },
    ],
    name: "updateTicketPlan",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "usdt",
    outputs: [
      {
        internalType: "contract IERC20",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    name: "userData",
    outputs: [
      {
        internalType: "address",
        name: "user",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "totalTicketBought",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "totalAmountSpent",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "totalTicketWon",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "totalAmountWon",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    name: "userTickets",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];
