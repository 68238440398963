import { useMutation, useQuery } from "@tanstack/react-query";
import { client } from "./api-client";

export const useGetSupported = () =>
  useQuery({
    queryKey: ["getSupported"],
    queryFn: async () => {
      const response = await client(`onramper/supported`);
      return response;
    },
  });

export const useGetQuote = () => {
  return useMutation<any, Error, any>({
    mutationFn: async (payload) => {
      const response = await client(
        `onramper/quote/${payload?.from}/${payload?.to}?amount=${payload?.amount}&type=${payload?.type}`,
        {
          method: "GET",
        }
      );
      if (response.error) {
        throw new Error(response.error);
      }
      return response;
    },
  });
};

export const useCheckoutIntent = () =>
  useMutation<any, Error, any>({
    mutationFn: async (payload) => {
      const response = await client(`onramper/checkout/intent`, {
        method: "POST",
        data: payload,
      });

      if (response.error) {
        throw new Error(response.error);
      }
      return response;
    },
  });
