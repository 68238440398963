import { Box, Flex, useToast } from "@chakra-ui/react";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import React, { useState } from "react";
import CustomText from "../text/CustomText";
import CloseButton from "../button/closeButton";
import PrimaryButton from "../button/PrimaryButton";

function StripePayment({ task, onClose, callBackUrl }: any) {
  const toast = useToast();

  const [isLoading, setIsLoading] = useState(false);
  const [isPaymentElementReady, setIsPaymentElementReady] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmitPayment = async () => {
    setIsLoading(true);
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      setIsLoading(false);
      return;
    }

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: callBackUrl
          ? callBackUrl
          : `${window.location.origin}/extsy-pro`,
      },
    });
    setIsLoading(false);
    if (error) {
      toast({
        description: error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleOnReady = (e: any) => {
    setIsPaymentElementReady(true);
  };

  return (
    <div>
      <Box>
        <PaymentElement onReady={handleOnReady} />
      </Box>
      {/* row 4 Buttons */}
      <Flex
        align="center"
        justify="space-between"
        pt="24px"
        mt={"24px"}
        borderTop="1px solid"
        borderColor="extsy.neutral900"
      >
        <CustomText text="Total Amount" color="extsy.baseWhite" />
        <CustomText
          text={`$${task?.price}.00`}
          size="32px"
          lh="36px"
          weight="600"
          color="extsy.baseWhite"
        />
      </Flex>
      <Flex
        padding={"0px"}
        // borderTop={`1px solid `}
        // borderColor={"extsy.neutral900"}
      >
        <Flex
          padding={"16px 24px "}
          width={"100%"}
          gap={"16px"}
          justifyContent={"center"}
          alignItems={"center"}
          flexDir={{ base: "column", lg: "row" }}
        >
          <CloseButton
            title={"Close"}
            maxW={"100%"}
            onClick={onClose}
            order={{ base: "2", lg: "1" }}
            height={{ base: "48px", lg: "56px" }}
            isDisabled={isLoading || !isPaymentElementReady}
          />

          <PrimaryButton
            title={"Purchase Now"}
            padding="10px 32px "
            maxW={"100%"}
            //   onClick={!isForm ? onSubmit : () => {}}
            onClick={handleSubmitPayment}
            order={{ base: "1", lg: "2" }}
            height={{ base: "48px", lg: "56px" }}
            isLoading={isLoading}
            isDisabled={!isPaymentElementReady}
          />
        </Flex>
      </Flex>
    </div>
  );
}

export default StripePayment;
