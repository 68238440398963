import { Box, Flex, Image } from "@chakra-ui/react";
import CustomText from "../text/CustomText";
import SliderImg1 from "../../assets/images/sliderImg1.svg";
import SliderImg2 from "../../assets/images/sliderImg2.svg";
import SliderConfirmed from "../../assets/images/slider-confirmed.svg";
import { useTranslation } from "react-i18next";

function DashboardSlider({ active }: { active?: string }) {
  const { t } = useTranslation();

  return (
    <Flex
      minW={{ lg: "548px" }}
      justify={"center"}
      flexDir={"column"}
      align={"center"}
      width="100%"
    >
      <Flex align={"center"} justify={"center"}>
        <Flex flexDir={"column"}>
          <Image
            src={active === "pending" ? SliderImg1 : SliderConfirmed}
            alt="SliderImg1"
            width={"36px"}
            height={"36px"}
            className={active === "pending" ? "rotate-img" : ""}
          />
        </Flex>
        <Box
          bg={"extsy.neutral300"}
          w={{ base: "82px", lg: `220px` }}
          h="2px"
          borderRadius="full"
        ></Box>
        <Flex flexDir={"column"}>
          <Image
            src={
              active === "deposit"
                ? SliderImg1
                : active === "pending"
                ? SliderImg2
                : SliderConfirmed
            }
            alt="SliderImg2"
            width={"36px"}
            height={"36px"}
            className={active === "deposit" ? "rotate-img" : ""}
          />
        </Flex>

        <Box
          bg={"extsy.neutral300"}
          w={{ base: "82px", lg: `220px` }}
          h="2px"
          borderRadius="full"
        ></Box>
        <Flex flexDir={"column"} gap={"12px"}>
          <Image
            src={active !== "completed" ? SliderImg2 : SliderConfirmed}
            alt="SliderImg2"
            width={"36px"}
            height={"36px"}
          />
        </Flex>
      </Flex>
      <Flex
        justify={"space-between"}
        // width={{ md: "80%" }}
        align={"center"}
        mt={"10px"}
        gap={{ base: "60px", lg: "160px" }}
      >
        <CustomText
          text={t("waitingForDeposit")}
          size={{ base: "10px", lg: "18px" }}
          color={"extsy.baseWhite"}
          family="Metropolis"
          wrap="nowrap"
        />
        <CustomText
          text={t("confirmation")}
          size={{ base: "10px", lg: "18px" }}
          color={active === "pending" ? "extsy.neutral300" : "extsy.baseWhite"}
          family="Metropolis"
          wrap="nowrap"
        />
        <CustomText
          text={t("depositConfirmed")}
          size={{ base: "10px", lg: "18px" }}
          color={
            active !== "completed" ? "extsy.neutral300" : "extsy.baseWhite"
          }
          family="Metropolis"
          wrap="nowrap"
        />
      </Flex>
    </Flex>
  );
}

export default DashboardSlider;
