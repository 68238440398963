import {
  Box,
  Flex,
  Input,
  SkeletonText,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import {
  IconCaretDownFilled,
  IconCaretUpFilled,
  IconSearch,
  IconStarFilled,
} from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
import CustomText from "../text/CustomText";
// import ArrowsExchange from "../../assets/images/arrows-exchange.svg";
import { formatNumber } from "../../utils/functions/formatNumber";
import moment from "moment";
import { useSearchParams } from "react-router-dom";
import { socket } from "../../utils/sockets";
import {
  useGetTradeBaseAssets,
  useGetTradePairs,
} from "../../utils/api/trade.api";
import { useSelector } from "react-redux";

function TradePairs() {
  const [, setSearchParams] = useSearchParams();
  const { pairSymbol } = useSelector((state: any) => state.spot);
  // pairs states
  const [pairs, setPairs] = useState([]);
  const [activeBasePair, setActiveBasePair] = useState("USDT");
  const [searchPairs, setSearchPairs] = useState("");
  const [sortKey, setSortKey] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [isPairsLoading, setIsPairsLoading] = useState(false);
  const [isTradeLoading, setIsTradeLoading] = useState(false);

  // Trade stats
  const [trades, setTrades] = useState<any>([]);

  const { data: basePairs, isLoading: basePairsLoading } =
    useGetTradeBaseAssets();
  const {
    data: exPairs,
    isLoading: exPairsLoading,
    refetch: refetchTradePairs,
  } = useGetTradePairs(activeBasePair, searchPairs);

  const checkActiveSort = (key: string, order: string) => {
    if (sortKey === key && sortOrder === order) {
      return true;
    }
    return false;
  };

  const handlePairChange = (allPairs: any) => {
    setIsPairsLoading(true);
    const filteredPairs = allPairs?.map((pair: any) => ({
      symbol: pair?.pair_ticker,
      price: parseFloat(pair?.spot_rate_binance),
      change: parseFloat(pair?.spot_rate_binance_change),
    }));

    const finalPairs = filteredPairs?.filter((pair: any) =>
      exPairs?.some((exPair: any) => exPair.pair_ticker === pair.symbol)
    );

    const sortedPairs = finalPairs?.sort((a: any, b: any) => {
      if (!sortKey) return 0;
      return sortOrder === "asc"
        ? a[sortKey] > b[sortKey]
          ? 1
          : -1
        : a[sortKey] < b[sortKey]
        ? 1
        : -1;
    });

    setPairs(sortedPairs);
    setIsPairsLoading(false);
  };

  const handleTradeChange = (data: any) => {
    setIsTradeLoading(true);
    setTrades((prevTrades: any) => {
      if (prevTrades.length > 14) {
        prevTrades.pop();
      }
      return [data, ...prevTrades];
    });
    setIsTradeLoading(false);
  };

  useEffect(() => {
    setTrades([]);
    function onConnect() {
      socket.emit("join", { event: "trade", symbol: pairSymbol });
    }

    if (socket?.connected) {
      onConnect();
    }

    socket.on("connect", onConnect);
    socket.on(`trade${pairSymbol}`, (data) => {
      handleTradeChange(data);
    });

    return () => {
      socket.off(`trade${pairSymbol}`, (data) => {});
      socket.off("connect", () => {});
    };
    //@ts-ignore
  }, [pairSymbol]);

  useEffect(() => {
    function onConnect() {
      socket.emit("join", { event: "pairs" });
    }
    if (socket?.connected) {
      onConnect();
    }

    socket.on("connect", onConnect);
    socket.on(`pairs`, (data) => {
      handlePairChange(data);
    });

    return () => {
      socket.off(`pairs`, (data) => {});
      socket.off("connect", () => {});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeBasePair, sortKey, sortOrder, exPairs]);

  useEffect(() => {
    if (basePairs && !activeBasePair) {
      setActiveBasePair(basePairs[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basePairs]);

  useEffect(() => {
    refetchTradePairs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeBasePair, searchPairs]);

  return (
    <Box
      maxW="349px"
      width="100%"
      display={{
        base: "none",
        lg: "block",
      }}
    >
      <Box pos="relative">
        <IconSearch
          size="14px"
          color="#5E6673"
          cursor="pointer"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 1,
            marginLeft: "5px",
            marginTop: "6px",
          }}
        />
        <Input
          minW="100%"
          type="text"
          bg="extsy.dark400"
          borderRadius="4px"
          border="none"
          height="28px"
          placeholder="Search"
          fontSize="14px"
          py="0px"
          pl="25px"
          color="extsy.white"
          fontFamily="Metropolis"
          _placeholder={{
            color: "extsy.text3",
          }}
          value={searchPairs}
          onChange={(e) => setSearchPairs(e.target.value)}
        />
      </Box>
      <Flex
        gap="10px"
        align="center"
        mt="14.3px"
        pt="8px"
        pb="6px"
        ps="4px"
        pos="relative"
        overflowX="auto"
      >
        <IconStarFilled color="#848E9C" size="14.6px" />

        {basePairsLoading ? (
          <SkeletonText noOfLines={1} py="7px" w="150px" />
        ) : (
          basePairs?.map((tab: string, index: number) => (
            <CustomText
              key={index}
              text={tab}
              size="14.3px"
              cursor="pointer"
              color={activeBasePair === tab ? "#F0B90B" : "extsy.neutral400"}
              mt="3px"
              onClick={() => setActiveBasePair(tab)}
            />
          ))
        )}

        {/* <Flex
          bg="linear-gradient(270deg, #161A1E 42.24%, rgba(0, 0, 0, 0.00) 95.69%)"
          w="69px"
          justify="end"
          pos="absolute"
          right="0"
        >
          <IconChevronRight color="#76808F" size="23px" />
        </Flex> */}
      </Flex>
      <TableContainer>
        {!isPairsLoading && !pairs?.length ? (
          <CustomText
            text="No pairs found"
            size="13px"
            color="extsy.neutral400"
          />
        ) : (
          <Table size="sm">
            <Thead>
              <Tr>
                <Th minW="125px" border="none" ps="0" py="2px">
                  <Flex align="center" gap="2px">
                    <CustomText
                      text="Pair"
                      size="13px"
                      color="extsy.neutral400"
                    />
                    <Flex flexDir="column">
                      <IconCaretUpFilled
                        color={
                          checkActiveSort("symbol", "asc")
                            ? "#EFB80B"
                            : "#848E9C"
                        }
                        size="10px"
                        onClick={() => {
                          setSortKey("symbol");
                          setSortOrder("asc");
                        }}
                      />
                      <IconCaretDownFilled
                        color={
                          checkActiveSort("symbol", "desc")
                            ? "#EFB80B"
                            : "#848E9C"
                        }
                        size="10px"
                        style={{
                          marginTop: "-6px",
                        }}
                        onClick={() => {
                          setSortKey("symbol");
                          setSortOrder("desc");
                        }}
                      />
                    </Flex>
                  </Flex>
                </Th>
                <Th border="none" minW="90px">
                  <Flex align="center" gap="2px" justify="end">
                    <CustomText
                      text="Price"
                      size="13px"
                      color="extsy.neutral400"
                    />
                    <Flex flexDir="column">
                      <IconCaretUpFilled
                        color={
                          checkActiveSort("price", "asc")
                            ? "#EFB80B"
                            : "#848E9C"
                        }
                        size="10px"
                        onClick={() => {
                          setSortKey("price");
                          setSortOrder("asc");
                        }}
                      />
                      <IconCaretDownFilled
                        color={
                          checkActiveSort("price", "desc")
                            ? "#EFB80B"
                            : "#848E9C"
                        }
                        size="10px"
                        style={{
                          marginTop: "-6px",
                        }}
                        onClick={() => {
                          setSortKey("price");
                          setSortOrder("desc");
                        }}
                      />
                    </Flex>
                  </Flex>
                </Th>
                <Th border="none" p="0" minW="80px">
                  <Flex align="center" gap="2px" justify="end">
                    <CustomText
                      text="Change"
                      size="13px"
                      color="extsy.neutral400"
                    />
                    <Flex flexDir="column">
                      <IconCaretUpFilled
                        color={
                          checkActiveSort("change", "asc")
                            ? "#EFB80B"
                            : "#848E9C"
                        }
                        size="10px"
                        onClick={() => {
                          setSortKey("change");
                          setSortOrder("asc");
                        }}
                      />
                      <IconCaretDownFilled
                        color={
                          checkActiveSort("change", "desc")
                            ? "#EFB80B"
                            : "#848E9C"
                        }
                        size="10px"
                        style={{
                          marginTop: "-6px",
                        }}
                        onClick={() => {
                          setSortKey("change");
                          setSortOrder("desc");
                        }}
                      />
                    </Flex>
                    {/* <Image src={ArrowsExchange} alt="exchange" /> */}
                  </Flex>
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {isPairsLoading || exPairsLoading
                ? [1, 2, 3, 4, 5].map((index) => <LoadingRow key={index} />)
                : pairs
                    ?.filter((pair: any) =>
                      pair?.symbol
                        ?.toLowerCase()
                        ?.includes(searchPairs?.toLowerCase())
                    )
                    ?.slice(0, 14)
                    ?.map((pair: any, index) => (
                      <Tr
                        key={index}
                        cursor="pointer"
                        onClick={() => {
                          setSearchParams({
                            pair: pair.symbol?.replace("-", ""),
                            trade: "spot",
                          });
                        }}
                      >
                        <Td minW="125px" border="none" ps="0">
                          <Flex align="center" gap="2px">
                            <IconStarFilled
                              color="#848E9C"
                              size="14.6px"
                              style={{
                                marginTop: "-3px",
                              }}
                            />
                            <CustomText
                              text={
                                <>
                                  <span style={{ color: "#EAECEF" }}>
                                    {pair?.symbol?.split("-")?.[0]}
                                  </span>
                                  /{pair?.symbol?.split("-")?.[1]}
                                </>
                              }
                              cursor
                              size="13px"
                              color="extsy.neutral400"
                            />
                          </Flex>
                        </Td>
                        <Td border="none" minW="90px">
                          <Flex align="center" gap="2px" justify="end">
                            <CustomText
                              text={formatNumber(pair.price, 6)}
                              size="13px"
                              color="extsy.text4"
                            />
                          </Flex>
                        </Td>
                        <Td border="none" p="0" minW="80px">
                          <Flex align="center" gap="2px" justify="end">
                            <CustomText
                              text={`${pair.change}%`}
                              size="13px"
                              color={
                                pair?.change < 0 ? "extsy.danger" : "#0ECB81"
                              }
                            />
                          </Flex>
                        </Td>
                      </Tr>
                    ))}
            </Tbody>
          </Table>
        )}
      </TableContainer>
      <Box bg="extsy.neutral900" w="100%" my="9px" height="1px" />
      <TableContainer mt="5px">
        {!isTradeLoading && !trades?.length ? (
          <CustomText
            text="No trades found"
            size="13px"
            color="extsy.neutral400"
          />
        ) : (
          <Table size="sm">
            <Thead>
              <Tr>
                <Th minW="125px" border="none" ps="0">
                  <CustomText
                    text="Price"
                    size="13px"
                    color="extsy.neutral400"
                  />
                </Th>
                <Th border="none" minW="90px">
                  <CustomText
                    text="Amount"
                    size="13px"
                    color="extsy.neutral400"
                    align="end"
                  />
                </Th>
                <Th border="none" p="0" minW="80px">
                  <CustomText
                    text="Time"
                    size="13px"
                    color="extsy.neutral400"
                    align="end"
                  />
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {isTradeLoading
                ? [1, 2, 3, 4, 5].map((index) => <LoadingRow key={index} />)
                : trades?.map((trade: any, index: number) => (
                    <Tr key={index}>
                      <Td minW="125px" border="none" ps="0">
                        <Flex align="center" gap="2px">
                          <CustomText
                            text={formatNumber(trade?.price, 6)}
                            size="13px"
                            color="extsy.danger"
                          />
                        </Flex>
                      </Td>
                      <Td border="none" minW="90px">
                        <Flex align="center" gap="2px" justify="end">
                          <CustomText
                            text={formatNumber(trade?.quantity, 6)}
                            size="13px"
                            color="extsy.text4"
                          />
                        </Flex>
                      </Td>
                      <Td border="none" p="0" minW="80px">
                        <Flex align="center" gap="2px" justify="end">
                          <CustomText
                            text={moment(trade?.time).format("HH:mm:ss")}
                            size="13px"
                            color="extsy.text4"
                          />
                        </Flex>
                      </Td>
                    </Tr>
                  ))}
            </Tbody>
          </Table>
        )}
      </TableContainer>
    </Box>
  );
}

const LoadingRow = () => {
  return (
    <Tr>
      <Td minW="125px" border="none" ps="0">
        <SkeletonText w="70px" noOfLines={1} />
      </Td>
      <Td border="none" minW="90px">
        <SkeletonText w="40px" noOfLines={1} ml="auto" />
      </Td>
      <Td border="none" p="0" minW="80px">
        <SkeletonText w="40px" noOfLines={1} ml="auto" />
      </Td>
    </Tr>
  );
};

export default TradePairs;
