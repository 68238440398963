import {
  Avatar,
  Box,
  Flex,
  Image,
  Skeleton,
  SkeletonCircle,
  SkeletonText,
} from "@chakra-ui/react";
import { IconCheck, IconChevronDown } from "@tabler/icons-react";
import React, { useState } from "react";
import CustomText from "../text/CustomText";

function CustomSelect({
  options,
  onSelect,
  maxW,
  h,
  bg,
  radius,
  value,
  placeholder,
  ico,
  isLoading,
}: any) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Box pos="relative">
      <Flex
        align="center"
        justify="space-between"
        maxW={maxW || "100%"}
        w="100%"
        cursor="pointer"
        p="9.5px 19.5px"
        h={h || "56px"}
        borderRadius={radius || "16px"}
        bg={bg || "extsy.neutral1000"}
        onClick={() => {
          if (!isLoading) setIsOpen(!isOpen);
        }}
      >
        <Flex gap="8px" align="center">
          {ico && isLoading ? (
            <SkeletonCircle size="24px" />
          ) : ico ? (
            <Image src={ico} alt="ico" height="24px" width="24px" />
          ) : (
            <></>
          )}
          {isLoading ? (
            <SkeletonText noOfLines={1} w="120px" />
          ) : (
            <CustomText text={placeholder} size="14px" cursor />
          )}
        </Flex>
        {isLoading ? (
          <Skeleton h="10px" w="15px" />
        ) : (
          <IconChevronDown size={24} color="#fafafa" />
        )}
      </Flex>
      <Flex
        p="16px"
        flexDir="column"
        gap="16px"
        bg="extsy.bg"
        borderRadius="8px"
        w="100%"
        pos="absolute"
        top="0"
        mt="60px"
        zIndex="10000"
        maxH="200px"
        overflowY="auto"
        display={isOpen ? "flex" : "none"}
      >
        {options?.map((option: any) => (
          <Flex
            key={option.value}
            align="center"
            justify="space-between"
            cursor="pointer"
            onClick={() => {
              setIsOpen(false);
              onSelect(option);
            }}
          >
            <Flex align="center" gap="6px">
              {option.icon && <Avatar src={option.icon} boxSize="24px" />}
              <CustomText
                text={option.label}
                cursor
                color={option?.value === value ? "extsy.baseWhite" : ""}
              />
            </Flex>
            {option.value === value && <IconCheck color="#fafafa" size={20} />}
          </Flex>
        ))}
      </Flex>
    </Box>
  );
}

export default CustomSelect;
