import { Box, Flex, Image, Skeleton, Spinner } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import CustomText from "../../components/text/CustomText";
import CardBg from "../../assets/images/card-bg.svg";
import TicketVector from "../../assets/images/ticket-vector.svg";
import PrimaryButton from "../../components/button/PrimaryButton";
import { IconCheck, IconChevronDown, IconTicket } from "@tabler/icons-react";
import GradientText from "../../components/text/GradientText";
import CustomModal from "../../components/modal/customModal";
import USDTIco from "../../assets/images/usdt-ico.svg";
import ManCongrats from "../../assets/images/man-congrats.png";
import {
  useAfterTrxSuccess,
  useGetTicketPlans,
} from "../../utils/api/raffle.api";
import { TicketPlan } from "../../utils/types/types";
import ConnectComponent from "./ConnectComponent";
import { useWallet } from "@tronweb3/tronwallet-adapter-react-hooks";
import {
  USDT_CONTRACT_ABI,
  USDT_CONTRACT_ADDRESS,
} from "../../utils/smartContracts/usdtSmartContract";
import {
  RAFFLE_CONTRACT_ABI,
  RAFFLE_CONTRACT_ADDRESS,
} from "../../utils/smartContracts/raffleSmartContract";
import moment from "moment";
import { useQuery } from "@tanstack/react-query";
import { useUpdateTronWalletAddress } from "../../utils/api/auth.api";
import { formatNumber } from "../../utils/functions/formatNumber";
import { waitForTransactionConfirmation } from "../../utils/functions/trxConfirmation";
import { useCustomAlert } from "../../utils/api/alert";

// const tron = window.tron;
// const tronWeb: any = tron?.tronWeb;

function Tickets({ round }: any) {
  const { data: ticketPlans, isLoading: ticketPlanLoading } =
    useGetTicketPlans();
  const { data: userInfo }: any = useQuery({
    queryKey: ["getLoggedInUser"],
  });

  const updateWalletAddress = useUpdateTronWalletAddress();
  const afterTrxSuccess = useAfterTrxSuccess();
  const alert = useCustomAlert();

  const { address, connected, signMessage, disconnect } = useWallet();

  const endDate = moment(round?.end_time);
  const now = moment();

  const isAfterEndTime = now.isAfter(endDate);

  const [tronWeb, setTronWeb] = useState<any>(undefined);
  const [isPurchaseOpen, setIsPurchaseOpen] = useState(false);
  const [isPurchasedFailed, setIsPurchaseFailed] = useState(false);
  const [isPurchaseRaffleOpen, setIsPurchaseRaffleOpen] = useState(false);
  const [isAllowanceLoading, setIsAllowanceLoading] = useState(false);
  const [isPurchaseLoading, setIsPurchaseLoading] = useState(false);
  const [isTickerDropOpen, setIsTicketDropOpen] = useState(false);
  const [activeTicker, setActiveTicket] = useState("");
  const findActiveTicket = () => {
    return ticketPlans?.find((item: TicketPlan) => item.id === activeTicker);
  };

  const lastRoundWinAmount = round?.last_round_won_tickets?.reduce(
    (sum: any, ticket: any) => sum + ticket?.won_amount,
    0
  );

  const userSignMessage = () => {
    signMessage("Sign message to purchase ticket").then((result: any) => {
      if (result) {
        updateWalletAddress
          .mutateAsync({
            wallet_address: address,
            signature: result,
          })
          .then((result) => {
            if (!isAfterEndTime) {
              setIsPurchaseOpen(true);
            }
          })
          .catch((err) => {
            disconnect();
            alert?.mutate({
              message: err?.message,
              status: "danger",
            });
          });
      } else {
        alert?.mutate({
          message: "Sign message failed",
          status: "danger",
        });
      }
    });
  };

  const waitForTronWeb = async () => {
    while (!window.tronWeb) {
      await new Promise((resolve) => setTimeout(resolve, 500));
    }
    return window.tronWeb;
  };

  // Check Allowance of a user
  const checkAllowance = async () => {
    setIsAllowanceLoading(true);
    try {
      const contract = await tronWeb.contract(
        USDT_CONTRACT_ABI,
        USDT_CONTRACT_ADDRESS
      );
      const balance = await contract.balanceOf(address).call();
      if (balance?.toString() < findActiveTicket()?.ticket_plan_price) {
        alert?.mutate({
          message: "Insufficient balance",
          status: "danger",
        });
        setIsAllowanceLoading(false);
        return;
      }

      const allowance = await contract
        .allowance(address, RAFFLE_CONTRACT_ADDRESS)
        .call();
      const amount = await tronWeb.toSun(findActiveTicket()?.ticket_plan_price);
      if (allowance?.toString() < amount) {
        const result = await contract
          .approve(RAFFLE_CONTRACT_ADDRESS, amount)
          .send({
            shouldPollResponse: true,
          });
        console.log(result, "---------");
        if (result) {
          setIsPurchaseOpen(false);
          setIsPurchaseRaffleOpen(true);
          purchaseTicket();
        } else {
          alert?.mutate({
            message: "Approval failed",
            status: "danger",
          });
        }
      } else {
        setIsPurchaseOpen(false);
        setIsPurchaseRaffleOpen(true);
        purchaseTicket();
      }

      setIsAllowanceLoading(false);
    } catch (e: any) {
      if ((e.message || e.error || e)?.includes(`owner_address isn't set.`)) {
        alert?.mutate({
          message: "Please connect your wallet",
          status: "danger",
        });
      } else {
        alert?.mutate({
          message: e.message || e.error || e,
          status: "danger",
        });
      }
      console.log("Error: ", e);
      setIsAllowanceLoading(false);
    }
  };

  const purchaseTicket = async () => {
    setIsPurchaseFailed(false);
    setIsPurchaseLoading(true);
    try {
      const contract = await tronWeb.contract(
        RAFFLE_CONTRACT_ABI,
        RAFFLE_CONTRACT_ADDRESS
      );
      const result = await contract
        .buyTicket(findActiveTicket()?.ticket_plan_id)
        .send({
          feeLimit: tronWeb.toSun(2000),
        });
      await waitForTransactionConfirmation(result, tronWeb);
      if (result) {
        afterTrxSuccess
          .mutateAsync()
          .then((result) => {
            setIsPurchaseLoading(false);
            setIsPurchaseRaffleOpen(false);
          })
          .catch((err) => {
            setIsPurchaseLoading(false);
            setIsPurchaseRaffleOpen(false);
          });
      } else {
        alert?.mutate({
          message: "Purchase failed",
          status: "danger",
        });
        afterTrxSuccess
          .mutateAsync()
          .then((result) => {
            setIsPurchaseFailed(true);
            setIsPurchaseLoading(false);
          })
          .catch((err) => {
            setIsPurchaseFailed(true);
            setIsPurchaseLoading(false);
          });
      }
    } catch (e: any) {
      setIsPurchaseFailed(true);
      setIsPurchaseLoading(false);
      alert?.mutate({
        message: e.message || e.error || e,
        status: "danger",
      });
      console.log("Error: ", e);
    }
  };

  useEffect(() => {
    if (!activeTicker) {
      setActiveTicket(ticketPlans?.[0]?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticketPlans]);

  useEffect(() => {
    waitForTronWeb().then((result) => {
      setTronWeb(result);
    });
  }, []);

  return (
    <>
      <Flex
        maxW={{ "1xl": "423px" }}
        w="100%"
        minH="524px"
        flexDir="column"
        justify="space-between"
        p={{ base: "16px", lg: "32px" }}
        borderRadius={{ base: "16px", lg: "24px" }}
        border="1px solid"
        borderColor="extsy.neutral1000"
        bg="extsy.neutral1000"
        gap={{ base: "16px", lg: "24px" }}
        pos="relative"
        overflow="hidden"
      >
        <Box
          width="472px"
          height="88px"
          pos="absolute"
          top="0"
          right="0"
          mt="-45px"
          mr="-25px"
          bg="extsy.primary500"
          filter="blur(82px)"
          display={round?.last_round_won_tickets?.length ? "block" : "none"}
        />
        <Flex flexDir="column" zIndex="100">
          <CustomText
            text={
              round?.last_round_won_tickets?.length
                ? "Congratulations"
                : "Tickets"
            }
            size={{ base: "18px", lg: "24px" }}
            lh={{ lg: "34px" }}
            weight={{ base: "600", lg: "700" }}
            family="Metropolis"
            color="extsy.white"
            align={round?.last_round_won_tickets?.length ? "center" : "start"}
          />
          <Flex
            height="262px"
            width="100%"
            bgImage={`url(${CardBg})`}
            bgSize="cover"
            bgRepeat="no-repeat"
            bgPos="center"
            overflow="hidden"
            borderRadius="0px 0px 200px 200px"
            align={round?.last_round_won_tickets?.length ? "flex-end" : ""}
            justify="center"
            mt={round?.last_round_won_tickets?.length ? "44px" : "0px"}
          >
            <Image
              src={
                round?.last_round_won_tickets?.length
                  ? ManCongrats
                  : TicketVector
              }
              alt="ticket"
            />
          </Flex>
        </Flex>
        {round?.last_round_won_tickets?.length ? (
          <Flex flexDir="column" gap="24px" align="center">
            <CustomText
              text={`Ticket ${round?.last_round_won_tickets
                ?.sort((a: any, b: any) => a?.ticket_id - b?.ticket_id)
                ?.map((ticket: any) => `#${ticket?.ticket_number}`)
                ?.join(", ")} has won the raffle prize of`}
              align="center"
            />
            <GradientText
              text={`${formatNumber(lastRoundWinAmount, 2)} USDT`}
              bg="extsy.g1"
              size="32px"
              lh="40px"
              weight="700"
            />
          </Flex>
        ) : round?.tickets ? (
          <Flex
            bg="extsy.g1"
            height="65px"
            align="center"
            justify="space-between"
            px="16px"
            borderRadius="16px"
            cursor="pointer"
          >
            <CustomText
              text="Tickets Purchased this Month"
              size="14px"
              color="extsy.bg"
              cursor
            />
            <Flex align="center" gap="8px">
              <CustomText
                text={round?.total_ticket_bought || 0}
                size="20px"
                color="extsy.bg"
                weight="600"
              />
              <IconTicket color="#E35CAD" size="22px" />
            </Flex>
          </Flex>
        ) : (
          <>
            <Flex flexDir="column" gap="12px">
              <CustomText size="14px" text="Select Tickets" />
              {ticketPlanLoading ? (
                <Skeleton height="56px" borderRadius="16px" />
              ) : (
                <Box pos="relative">
                  <Flex
                    cursor="pointer"
                    borderRadius="16px"
                    bg="extsy.neutral900"
                    py="9.5px"
                    px="19.5px"
                    align="center"
                    justify="space-between"
                    height="56px"
                    onClick={() => setIsTicketDropOpen(!isTickerDropOpen)}
                  >
                    <CustomText
                      text={`${
                        findActiveTicket()?.ticket_plan_quantity
                      } Ticket for ${
                        findActiveTicket()?.ticket_plan_price
                      } USDT`}
                      size="14px"
                      cursor
                    />
                    <IconChevronDown size="16px" color="#fafafa" />
                  </Flex>
                  <Flex
                    flexDir="column"
                    gap="16px"
                    p="16px"
                    borderRadius="8px"
                    border="1px solid"
                    borderColor="extsy.neutral800"
                    bg="extsy.neutral900"
                    pos="absolute"
                    top="0"
                    mt="60px"
                    width="100%"
                    zIndex="1000"
                    display={isTickerDropOpen ? "flex" : "none"}
                  >
                    {ticketPlans?.map((ticket: TicketPlan, index: number) => (
                      <Flex
                        key={index}
                        align="center"
                        justify="space-between"
                        py="8px"
                        cursor="pointer"
                        onClick={() => {
                          setIsTicketDropOpen(false);
                          setActiveTicket(ticket.id);
                        }}
                      >
                        <CustomText
                          text={`${
                            ticket.ticket_plan_quantity || 0
                          } Ticket for ${ticket?.ticket_plan_price || 0} USDT`}
                          size="14px"
                          color={
                            ticket?.id === activeTicker
                              ? "extsy.baseWhite"
                              : "extsy.neutral300"
                          }
                          cursor
                        />
                        {ticket.id === activeTicker && (
                          <IconCheck size="16px" color="#fafafa" />
                        )}
                      </Flex>
                    ))}
                  </Flex>
                </Box>
              )}
              <CustomText
                size="12px"
                text="This is a one-time purchase, so you won’t be able to buy more tickets for this month. Make your choice carefully."
                color="extsy.neutral500"
              />
            </Flex>
            <Flex
              justify="space-between"
              align="center"
              py="16px"
              borderTop="1px solid"
              borderBottom="1px solid"
              borderColor="extsy.neutral900"
            >
              <CustomText
                text="Total Amount"
                size="14px"
                color="extsy.baseWhite"
              />
              <GradientText
                text={`${Number(
                  findActiveTicket()?.ticket_plan_price || 0
                )?.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })} USDT`}
                size="18px"
                weight="600"
                bg="extsy.g1"
              />
            </Flex>
            {connected ? (
              address !== userInfo?.connected_tron_wallet ? (
                <PrimaryButton
                  title="Sign Message"
                  isLoading={updateWalletAddress?.isPending}
                  onClick={() => {
                    userSignMessage();
                  }}
                />
              ) : (
                <PrimaryButton
                  title={
                    isAfterEndTime
                      ? "Raffle Starting Soon"
                      : "Purchase Raffle Ticket"
                  }
                  onClick={() => {
                    if (isAfterEndTime) {
                      return;
                    }
                    setIsPurchaseOpen(true);
                  }}
                />
              )
            ) : (
              <ConnectComponent />
            )}
          </>
        )}
      </Flex>
      <CustomModal
        isOpen={isPurchaseOpen}
        onClose={() => setIsPurchaseOpen(false)}
        onSubmit={() => {
          checkAllowance();
        }}
        headerText={"Purchase Raffle Ticket"}
        btnText={"Purchase"}
        closeBtntext={"Close"}
        width={"600px"}
        bgtopBlur="transparent"
        isLoading={isAllowanceLoading}
      >
        <Flex p={{ base: "16px", lg: "32px 24px" }} flexDir="column" gap="32px">
          <Flex flexDir="column" gap="12px">
            <CustomText text="Coin" size="14px" />
            <Flex
              gap="12px"
              align="center"
              px="19.5px"
              py="9.5px"
              h="56px"
              bg="extsy.neutral900"
              borderRadius="16px"
            >
              <Image src={USDTIco} alt="usdt" h="28px" w="28px" />
              <CustomText
                text="USDT TRC20"
                color="extsy.baseWhite"
                weight="600"
              />
            </Flex>
            <CustomText
              text={
                <>
                  <span>Don't have USDT? </span>
                  <span
                    style={{
                      color: "#fafafa",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      window.open(
                        `${process.env.REACT_APP_EXTSY_PRE_RELEASE_DOMAIN}/pages/buySell`,
                        "_blank"
                      );
                    }}
                  >
                    Buy now
                  </span>
                </>
              }
              align="end"
              mt="12px"
            />
          </Flex>
          <Flex
            align="center"
            justify="space-between"
            pt="24px"
            borderTop="1px solid"
            borderColor="extsy.neutral900"
          >
            <CustomText text="Total Amount" color="extsy.baseWhite" />
            <CustomText
              text={`${Number(
                findActiveTicket()?.ticket_plan_price || 0
              )?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })} USDT`}
              color="extsy.baseWhite"
              weight="500"
              size={{ base: "20px", lg: "32px" }}
            />
          </Flex>
        </Flex>
      </CustomModal>
      <CustomModal
        isOpen={isPurchaseRaffleOpen}
        onClose={() => {
          setIsPurchaseRaffleOpen(false);
          setIsPurchaseFailed(false);
        }}
        onSubmit={() => {
          setIsPurchaseRaffleOpen(false);
        }}
        headerText={"Purchase Raffle"}
        noFooter
        width={"600px"}
        bgtopBlur="transparent"
      >
        <Flex
          p={{ base: "16px", lg: "24px" }}
          flexDir="column"
          gap={{ base: "40px", lg: "48px" }}
        >
          <Flex flexDir="column" gap={{ base: "16px", lg: "40px" }}>
            <Flex align="center" justify="space-between" gap="12px">
              <CustomText
                text="Raffle Plan"
                size={{ base: "16px", lg: "18px" }}
              />
              <CustomText
                text={
                  findActiveTicket()?.ticket_plan_quantity +
                  " Tickets for " +
                  findActiveTicket()?.ticket_plan_price +
                  " USDT"
                }
                size={{ base: "16px", lg: "24px" }}
                weight="600"
                color="extsy.baseWhite"
              />
            </Flex>
            <Flex align="center" justify="space-between">
              <CustomText
                text="Total Amount"
                size={{ base: "16px", lg: "18px" }}
              />
              <CustomText
                text={`${Number(
                  findActiveTicket()?.ticket_plan_price
                )?.toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })} USDT`}
                color="extsy.baseWhite"
                weight="600"
                size={{ base: "16px", lg: "24px" }}
              />
            </Flex>
          </Flex>
          <Flex
            display={
              isPurchaseLoading || afterTrxSuccess?.isPending ? "flex" : "none"
            }
            gap="24px"
            align="center"
            justify="center"
          >
            <Spinner size="md" color="#FEF9CC" />
            <CustomText
              text="Loading..."
              size="18px"
              lh="22px"
              family="Metropolis"
            />
          </Flex>
          {isPurchasedFailed && (
            <PrimaryButton
              title="Retry"
              onClick={() => {
                purchaseTicket();
              }}
            />
          )}
        </Flex>
      </CustomModal>
    </>
  );
}

export default Tickets;
