import {
  Center,
  Flex,
  Grid,
  GridItem,
  Image,
  SkeletonText,
  Stack,
} from "@chakra-ui/react";
import React, { useState } from "react";
import CustomText from "../../components/text/CustomText";
import KycVerificationVector from "../../assets/images/kyc-verificaition-vector.svg";
import PrimaryButton from "../../components/button/PrimaryButton";
import VerifySuccessImg from "../../assets/images/verify-kyc-icon.svg";
import { useGetSumsubToken } from "../../utils/api/auth.api";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import SumsubWebSdk from "@sumsub/websdk-react";
import { OnboardModal } from "../../components/modal/onBoardModal";

function KYCVerification() {
  const queryClient: any = useQueryClient();

  const { data: userInfo, isLoading: userInfoLoading }: any = useQuery({
    queryKey: ["getLoggedInUser"],
  });

  const { data: sumsubToken } = useGetSumsubToken();
  const [isVerificationModalOpen, setIsVerificationModalOpen] = useState(false);
  const [isVerifySuccessOpen, setIsVerifySuccessOpen] = useState(false);

  const getKycBgColor = (status: any) => {
    if (status === "APPROVED") return "#1C312E";
    if (["APPLIED", "ON_HOLD"].includes(status)) return "#4D3A24";
    if (status === "REJECTED") return "#442024";
    return "";
  };

  const getKycStatusText = (status: any) => {
    if (status === "APPROVED") return "Verified";
    if (["APPLIED", "ON_HOLD"].includes(status)) return "In Process";
    if (status === "REJECTED") return "Rejected";
    return "";
  };

  const getKycTextColor = (status: any) => {
    if (status === "APPROVED") return "#56CE77";
    if (["APPLIED", "ON_HOLD"].includes(status)) return "#FFA333";
    if (status === "REJECTED") return "#FF6574";
    return "";
  };

  return (
    <>
      <Grid
        gap="24px"
        templateRows="repeat(1, 1fr)"
        templateColumns="repeat(12, 1fr)"
      >
        <GridItem colSpan={{ base: 12 }}>
          <Flex
            gap="12px"
            p={{ base: "16px", lg: "32px" }}
            px={{ base: "16px", lg: "100px" }}
            flexDir="column"
            borderRadius="16px"
            bg="extsy.neutral900"
            border="1px solid"
            borderColor="extsy.neutral1000"
            h="100%"
          >
            <Flex
              align={"center"}
              justify={"space-between"}
              gap={{ base: "24px", md: "0px" }}
              flexDirection={{ base: "column", md: "row" }}
              w={"100%"}
            >
              <Stack
                justify={"space-between"}
                align={"flex-start"}
                flex={"1 0 0"}
                alignSelf={"center"}
              >
                <Stack gap={"24px"}>
                  <Flex gap={"10px"} align={"center"}>
                    <CustomText
                      text="KYC Verification"
                      size="24px"
                      lh="34px"
                      weight="700"
                      family="Metropolis"
                      color="extsy.white"
                    />
                    {userInfoLoading ? (
                      <SkeletonText w={"60px"} noOfLines={1} />
                    ) : (
                      <>
                        {![null, "", "NOT_APPLIED"].includes(
                          userInfo?.kyc_status
                        ) && (
                          <Center
                            w={"90px"}
                            h={"24px"}
                            p={"6px 12px"}
                            gap={"4px"}
                            borderRadius={"30px"}
                            bg={getKycBgColor(userInfo?.kyc_status)}
                            display={userInfo?.kyc_status ? "flex" : "none"}
                          >
                            <CustomText
                              text={getKycStatusText(userInfo?.kyc_status)}
                              color={getKycTextColor(userInfo?.kyc_status)}
                              size="10px"
                              weight="500"
                              lh="12px"
                            />
                          </Center>
                        )}
                      </>
                    )}
                  </Flex>
                  {userInfoLoading ? (
                    <SkeletonText w={"60px"} noOfLines={1} />
                  ) : (
                    <CustomText
                      text={
                        userInfo?.kyc_status === "APPROVED"
                          ? "Your account has been verified."
                          : userInfo?.kyc_status === "REJECTED"
                          ? "Your KYC has been rejected. Kindly resubmit your authentic details and get verified."
                          : userInfo?.kyc_status === "APPLIED" ||
                            userInfo?.kyc_status === "ON_HOLD"
                          ? "Your details have been submitted for verification. Shortly you will be notified once verified or rejected."
                          : "Verify your identity through SumSub to get more from your current level."
                      }
                      lh="120%"
                      family="Metropolis"
                      color="#BAB8B8"
                      mt="12px"
                    />
                  )}
                </Stack>
                {userInfoLoading ? (
                  <SkeletonText w={"60px"} noOfLines={1} />
                ) : (
                  <>
                    {!["APPROVED"].includes(userInfo?.kyc_status) && (
                      <PrimaryButton
                        title={
                          userInfo?.kyc_status === "REJECTED"
                            ? "Re-submit"
                            : "Verify"
                        }
                        size="14px"
                        maxW={{ md: "216px" }}
                        onClick={() => setIsVerificationModalOpen(true)}
                      />
                    )}
                  </>
                )}
              </Stack>
              <Image
                src={KycVerificationVector}
                alt="kyc"
                w="310px"
                h="205px"
              />
            </Flex>
          </Flex>
        </GridItem>
      </Grid>
      <OnboardModal
        isOpen={isVerificationModalOpen}
        onClose={() => {
          setIsVerificationModalOpen(false);
        }}
        onSubmit={() => {
          setIsVerifySuccessOpen(true);
        }}
        headerDivider
        title={
          <>
            <CustomText
              text="Complete Verification"
              weight="600"
              size={{ base: "20px", md: "30px" }}
              isTruncated
              maxW={{ base: "86%", sm: "95%" }}
              pb="16px"
              color="extsy.white"
            />
            <CustomText
              text="Start your verification through Sumsub."
              size={{ base: "14px", md: "16px" }}
            />
          </>
        }
        isFooter={false}
      >
        {/* <Center py="40px" borderRadius={"20px"} mb="24px" w={"100%"}> */}
        <SumsubWebSdk
          accessToken={sumsubToken?.token}
          expirationHandler={() => sumsubToken?.token}
          config={{
            lang: "en",
          }}
          // options={options}
          onMessage={async (data: any, payload: any) => {
            // console.log("kyc---------sumsub", data, payload?.reviewStatus);
            if (
              payload?.reviewStatus === "completed" &&
              payload?.reviewResult?.reviewAnswer === "GREEN"
            ) {
              setIsVerificationModalOpen(false);
              setIsVerifySuccessOpen(true);
            }
          }}
          onError={(data: any) => console.log("onError", data)}
        />
        {/* <Image src={SumsubImg} /> */}
        {/* </Center> */}
      </OnboardModal>
      <OnboardModal
        isOpen={isVerifySuccessOpen}
        onClose={() => {
          setIsVerifySuccessOpen(false);
          queryClient.invalidateQueries(["getLoggedInUser"]);
        }}
        onSubmit={() => {
          setIsVerifySuccessOpen(false);
          queryClient.invalidateQueries(["getLoggedInUser"]);
        }}
        headerDivider
        size="sm"
        noHeader={true}
        // btnText="Create a Community"

        isFooter={false}
      >
        <Center
          py="30px"
          px={"20px"}
          borderRadius={"20px"}
          mb="24px"
          gap={"24px"}
          w={"100%"}
          flexDirection={"column"}
        >
          <Image src={VerifySuccessImg} m="24px auto 0px" mb={"24px"} />
          <CustomText
            text="Congratulation!"
            fontFamily="Power Grotesk"
            color="extsy.white"
            size={{ base: "22px", md: "24px" }}
          />
          <CustomText
            text="Your account has been verified successfully."
            size={{ base: "14px", md: "16px" }}
          />
          <PrimaryButton
            width="182px"
            height="56px"
            onClick={() => {
              setIsVerifySuccessOpen(false);
              queryClient.invalidateQueries(["getLoggedInUser"]);
            }}
            title={"Done"}
          />
        </Center>
      </OnboardModal>
    </>
  );
}

export default KYCVerification;
