import { Box, Flex, Input } from "@chakra-ui/react";
import React, { useState } from "react";
import CustomText from "../../../components/text/CustomText";
import CustomSelect from "../../../components/form/CustomSelect";
import { IconArrowRight, IconMinus, IconPlus } from "@tabler/icons-react";
import CustomRadio from "../../../components/form/CustomRadio";
import {
  useAssets,
  useGetFiats,
  useNetworks,
} from "../../../utils/api/p2p.api";
import { CoinOption, CryptoAsset } from "../../../utils/types/types";

function Step1({ adData, setAdData }: any) {
  const { data: coinData, isLoading: coinsLoading } = useAssets();
  const { data: chains, isLoading: chainLoading } = useNetworks();
  const { data: fiats, isLoading: fiatLoading } = useGetFiats();
  const [depositCoin, setDepositCoin] = useState<CoinOption | undefined>(
    undefined
  );
  const [depositChain, setDepositChain] = useState<CoinOption | undefined>(
    undefined
  );
  const [currency, setCurrency] = useState<CoinOption | undefined>(undefined);
  const [fixedPrice, setFixedPrice] = useState(260);

  return (
    <Flex flexDir="column" gap={{ base: "16px", md: "64px" }}>
      <Flex
        gap={{ base: "16px", md: "24px" }}
        align={{ md: "center" }}
        flexDir={{
          base: "column",
          md: "row",
        }}
      >
        <Flex flexDir="column" gap="8px" w="100%">
          <CustomText text="Assets" size="14px" />
          <CustomSelect
            placeholder={depositCoin?.label || "Select Coin"}
            ico={depositCoin?.icon}
            value={depositCoin?.value}
            options={coinData?.map((coin: CryptoAsset) => ({
              value: coin.id,
              label: coin.symbol,
              icon: coin.logo_url,
            }))}
            onSelect={(option: CoinOption) => {
              setDepositCoin(option);
              setAdData({ ...adData, assetID: option.value });
            }}
            isLoading={coinsLoading}
          />
        </Flex>
        <Box
          mt="20px"
          display={{
            base: "none",
            md: "block",
          }}
        >
          <IconArrowRight size={24} color="#E35CAD" />
        </Box>
        <Flex flexDir="column" gap="8px" w="100%">
          <CustomText text="With Fiat" size="14px" />
          <CustomSelect
            placeholder={currency?.label || "Select Currency"}
            ico={currency?.icon}
            value={currency?.value}
            options={fiats?.map((coin: any) => ({
              value: coin?.id,
              label: coin?.name,
              icon: coin?.logo_url,
            }))}
            onSelect={(option: CoinOption) => {
              setCurrency(option);
              setAdData({ ...adData, currency: option.value });
            }}
            isLoading={fiatLoading}
          />
        </Flex>
      </Flex>
      <Flex
        justify="space-between"
        gap={{ base: "16px", md: "24px" }}
        flexDir={{
          base: "column",
          md: "row",
        }}
      >
        <Flex flexDir="column" gap="8px" w="100%">
          <CustomText text="Chain" size="14px" />
          <CustomSelect
            placeholder={depositChain?.label || "Select Chain"}
            ico={depositChain?.icon}
            value={depositChain?.value}
            options={chains?.map((coin: any) => ({
              value: coin?.id,
              label: coin?.name,
              icon: coin?.logo_url,
            }))}
            onSelect={(option: CoinOption) => {
              console.log(option, "selected option");
              setDepositChain(option);
              setAdData({ ...adData, chain: option.value });
            }}
            isLoading={chainLoading}
          />
        </Flex>
        <Box w="46px" display={{ base: "none", md: "block" }} />
        <Flex flexDir="column" gap="24px" w="100%">
          <Flex
            justify="space-between"
            gap={{ base: "16px", md: "24px" }}
            flexDir={{
              base: "column",
              md: "row",
            }}
          >
            <Flex justify="space-between">
              <CustomRadio label="Fixed" isChecked />
            </Flex>
            <Flex flexDir="column" gap="16px">
              <Flex
                align="center"
                justify="space-between"
                gap="20px"
                h="56px"
                maxW={{ md: "419px" }}
                w="100%"
                p="8px 16px"
                borderRadius="12px"
                bg="extsy.neutral900"
              >
                <IconMinus
                  size={24}
                  color="#FAFAFA"
                  cursor="pointer"
                  onClick={() => {
                    setFixedPrice(fixedPrice - 1);
                    setAdData({ ...adData, price: fixedPrice - 1 });
                  }}
                />
                <Input
                  value={adData?.price}
                  onChange={(e) => {
                    let value: any = e.target.value;

                    // Allow only integer values if priceType is 'Fixed', otherwise allow floats

                    value = value.replace(/[^0-9]/g, ""); // Remove any non-integer values

                    // Set the state with the parsed value
                    setFixedPrice(value);
                    setAdData({ ...adData, price: Number(value) });
                  }}
                  type="string"
                  fontSize="16px"
                  fontWeight="600"
                  color="extsy.text2"
                  textAlign="center"
                  border="none"
                  userSelect="none"
                  _hover={{ border: "none" }}
                  _focus={{ border: "none" }}
                  _focusVisible={{ border: "none" }}
                />
                <IconPlus
                  size={24}
                  color="#FAFAFA"
                  cursor="pointer"
                  onClick={() => {
                    setFixedPrice(fixedPrice + 1);

                    setAdData({ ...adData, price: fixedPrice + 1 });
                  }}
                />
              </Flex>
              <CustomText
                text="Fixed price should be between Rs 220 - Rs 280."
                size="14px"
                color="extsy.primary"
              />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        gap={{ base: "16px", md: "24px" }}
        align={{ md: "center" }}
        flexDir={{
          base: "column",
          md: "row",
        }}
        mt={{ base: "24px", md: "0" }}
      >
        <Flex
          maxW={{ md: "339px" }}
          w="100%"
          flexDir={{ md: "column" }}
          align={{ base: "center", md: "auto" }}
          justify={{ base: "space-between", md: "auto" }}
          gap="16px"
        >
          <CustomText text="Your Price" size="14px" />
          <CustomText
            text={`$ ${adData?.price}`}
            size={{ base: "18px", md: "32px" }}
            weight={{ base: "600", md: "700" }}
            color="extsy.secondary"
          />
        </Flex>
        <Flex
          maxW={{ md: "339px" }}
          w="100%"
          flexDir={{ md: "column" }}
          align={{ base: "center", md: "auto" }}
          justify={{ base: "space-between", md: "auto" }}
          gap="16px"
        >
          <CustomText text="Highest Older Price" size="14px" />
          <CustomText
            text="$ 300.00"
            size={{ base: "18px", md: "32px" }}
            weight={{ base: "600", md: "700" }}
            color="extsy.secondary"
          />
        </Flex>
      </Flex>
    </Flex>
  );
}

export default Step1;
