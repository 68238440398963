import { Text, Flex, useMediaQuery, Img } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import CustomText from "../../components/text/CustomText";
import PrimaryButton from "../../components/button/PrimaryButton";
import CustomVerificationInput from "../../components/form/customVerificationInput";
import Countdown from "react-countdown";
import CustomModal from "../../components/modal/customModal";
import CheckMarkImg from "../../assets/images/correctIcon.svg";
import { useTranslation } from "react-i18next";
import { useResendOTP, useVerifyEmail } from "../../utils/api/auth.api";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { useCustomAlert } from "../../utils/api/alert";

function SecurityVerification() {
  const queryClient = useQueryClient();

  const { t } = useTranslation();

  const userVerifyEmail = useVerifyEmail();
  const userResendOTP = useResendOTP();
  const alert = useCustomAlert();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");

  const [isLessThan768] = useMediaQuery("(max-height: 799px)");
  const [open, setOpen] = useState(false);
  const [otp, setOtp] = useState("");
  const [remainingTime, setRemainingTime] = useState(Date.now() + 120 * 1000);

  const handleModalOpen = () => {
    setOpen(!open);
  };

  const handleModalClose = () => {
    setOpen(false);
  };

  const handleUserVerification = (payload: any) => {
    userVerifyEmail
      .mutateAsync(payload)
      .then((result) => {
        handleModalOpen();
      })
      .catch((error) => {
        alert?.mutate({
          message:
            error?.exception && Array.isArray(error.exception)
              ? error.exception[0]
              : error?.message,
          status: "danger",
        });
      });
  };

  const renderer = ({ minutes, seconds, completed }: any) => {
    if (completed) {
      return <Completionist />;
    } else {
      return (
        <Flex flexDir="column" gap="16px">
          <CustomText
            text={t("timeRemaining")}
            align="center"
            color="extsy.baseWhite"
          />
          <Flex justify="center" align="center" gap="5px">
            <Flex
              align="center"
              justify="center"
              bg="extsy.neutral1000"
              borderRadius="8px"
              px="10px"
              h="40px"
              color="extsy.primary"
              fontFamily={"Metropolis"}
            >
              {"0" + minutes}
            </Flex>
            <Text
              align="center"
              color="extsy.primary"
              fontSize="26px"
              fontWeight="500"
            >
              :
            </Text>
            <Flex
              align="center"
              justify="center"
              bg="extsy.neutral1000"
              borderRadius="8px"
              px="10px"
              h="40px"
              color="extsy.primary"
              fontFamily={"Metropolis"}
            >
              {seconds < 10 ? "0" + seconds : seconds}
            </Flex>
          </Flex>
        </Flex>
      );
    }
  };

  const Completionist = () => (
    <CustomText
      text={t("resendOTP")}
      align="center"
      color="extsy.baseWhite"
      cursor="pointer"
      onClick={() => {
        if (!email) {
          alert?.mutate({
            message: "Please enter a valid email",
            status: "danger",
          });
          return;
        }
        if (userResendOTP?.isPending) return;
        userResendOTP
          .mutateAsync({ email })
          .then((result) => {
            setRemainingTime(Date.now() + 120 * 1000);
            alert?.mutate({
              message: "OTP sent successfully",
              status: "success",
            });
          })
          .catch((err) => {
            alert?.mutate({
              message:
                err?.exception && Array.isArray(err.exception)
                  ? err.exception[0]
                  : err?.message,
              status: "danger",
            });
          });
      }}
    />
  );

  useEffect(() => {
    if (!email) {
      navigate("/auth/login");
    }
  }, [email, navigate]);

  return (
    <Flex mx="auto">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (otp?.length < 6) {
            alert?.mutate({
              message: "Please enter a valid OTP",
              status: "danger",
            });
            return;
          }
          if (!email) {
            alert?.mutate({
              message: "Please enter a valid email",
              status: "danger",
            });
            return;
          }
          handleUserVerification({ otp, email });
        }}
      >
        <Flex flexDir="column" gap="16px" mx="auto">
          <Flex
            px={{ base: "16px", md: "40px" }}
            py={isLessThan768 ? "24px" : "56px"}
            mx="auto"
            bg="rgba(255, 255, 255, 0.03)"
            borderRadius={{ base: "16px", md: "24px" }}
            maxW="480px"
            w="100%"
            flexDir="column"
            justify="space-between"
            gap={isLessThan768 ? "24px" : "0px"}
            minH={isLessThan768 ? "524px" : "742px"}
          >
            <Flex align="center" flexDir="column" gap="32px">
              <CustomText
                text={t("enterVerificationCode")}
                family="Metropolis"
                size="24px"
                lh="28px"
                weight="700"
                align="center"
                color="extsy.baseWhite"
              />
              <CustomText
                text={
                  <Text color="extsy.neutral300">
                    {t("verificationCodeDescription")}{" "}
                    <Text as="span" color="extsy.baseWhite">
                      {email}
                    </Text>
                  </Text>
                }
                align="center"
                color="extsy.neutral300"
              />
            </Flex>
            <Flex flexDir="column" align="center" justify="center" gap="40px">
              <CustomVerificationInput
                placeholder={0}
                value={otp}
                onChange={(val: any) => setOtp(val)}
              />
              <Countdown
                date={remainingTime}
                renderer={renderer}
                key={remainingTime}
              />
            </Flex>
            <Flex flexDir="column" gap="24px" align="center">
              <PrimaryButton
                title={t("verify")}
                type="submit"
                isLoading={userVerifyEmail?.isPending}
              />
              {/* <Flex align="center" gap="4px">
            <CustomText
              text={t("rememberPassword?")}
              color="extsy.neutral300"
              lh="19px"
            />
            <GradientText
              text={t("signIn")}
              weight="600"
              bg="extsy.g1"
              lh="22px"
              cursor="pointer"
              onClick={() => {
                navigate("/auth/login");
              }}
            />
          </Flex> */}
            </Flex>
          </Flex>
        </Flex>
      </form>

      <CustomModal
        isOpen={open}
        onClose={() => {}}
        onSubmit={() => {}}
        noFooter={true}
        noHeader={true}
        padding="56px"
      >
        <Flex flexDir="column" align="center" gap={"32px"} minH="100%">
          <Img src={CheckMarkImg} alt="checkicon" minH="80px" minW="79.99px" />
          <CustomText
            text={t("congratulations")} // Use translation key
            color="extsy.baseWhite"
            lh="19.2px"
            size="24px"
            family="Metropolis"
            pb="5px"
          />
          <CustomText
            text={t("accountRegisteredSuccess")} // Use translation key
            color="extsy.neutral300"
            size="13.2px"
          />
          <PrimaryButton
            title={t("continue")}
            onClick={() => {
              localStorage.setItem(
                "token",
                userVerifyEmail?.data?.token?.access_token
              );
              queryClient.invalidateQueries({ queryKey: ["token"] });
              queryClient.invalidateQueries({ queryKey: ["getLoggedInUser"] });
              handleModalClose();
              navigate("/");
            }}
          />{" "}
          {/* Use translation key */}
        </Flex>
      </CustomModal>
    </Flex>
  );
}

export default SecurityVerification;
