import { Box, Flex, Image, SkeletonText } from "@chakra-ui/react";
import React, { useEffect } from "react";
import CustomText from "../text/CustomText";
import { useTranslation } from "react-i18next";
import WalletIco from "../../assets/images/wallet-ico-gradient.svg";
import TradingIco from "../../assets/images/trading-ico-gradient.svg";
import P2PIco from "../../assets/images/p2p-ico-gradient.svg";
import { useAccount } from "wagmi";
import {
  useGetNetworks,
  useGetWalletBalance,
} from "../../utils/api/wallet.api";

function AvailableBalanceCard() {
  const { t } = useTranslation();

  const { data: networks } = useGetNetworks(20, 0);
  const walletBalance = useGetWalletBalance();

  const { address, chain, isConnected } = useAccount();

  // Trigger API call when wallet is connected and network ID is available
  useEffect(() => {
    // Determine which network ID to use, e.g., Ethereum
    const networkId = networks?.find(
      (network: any) => network?.name === chain?.name
    )?.id;
    if (isConnected && networkId) {
      walletBalance?.mutateAsync({
        wallet_address: address,
        network_id: networkId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnected, address, chain]);

  return (
    <Flex
      gap={{ base: "8px", lg: "24px" }}
      align="center"
      flexDir={{ base: "column", lg: "row" }}
    >
      <Flex
        minH={{ base: "72px", lg: "140px", "1xl": "220px" }}
        w="100%"
        gap={{ base: "16px", lg: "24px" }}
        pos="relative"
        flexDir={{ base: "row", lg: "column" }}
        overflow="hidden"
        align={{ base: "center", lg: "flex-start" }}
        justify={{ lg: "space-between" }}
        p={{ base: "16px", lg: "20px", "1xl": "40px" }}
        borderRadius={{ base: "16px", "1xl": "24px" }}
        bg="extsy.neutral1000"
      >
        <Box>
          <Flex
            align="center"
            justify="center"
            zIndex="100"
            bg="extsy.neutral900"
            borderRadius="8px"
            height="40px"
            width="40px"
          >
            <Image src={WalletIco} alt="wallet" height="24px" width="24px" />
          </Flex>
        </Box>
        <Flex
          flexDir={{ base: "row", lg: "column" }}
          align={{ base: "center", lg: "flex-start" }}
          justify={{ base: "space-between", lg: "flex-start" }}
          w="100%"
          gap="16px"
        >
          <CustomText text={"Wallet"} color="extsy.baseWhite" />
          {walletBalance?.isPending ? (
            <SkeletonText
              noOfLines={1}
              spacing="4"
              w="100px"
              h="24px"
              mt="12px"
            />
          ) : (
            <CustomText
              text={`$${walletBalance?.data?.total_usd || "0.00"}`}
              color="extsy.baseWhite"
              size={{ base: "24px", lg: "36px" }}
            />
          )}
        </Flex>
      </Flex>
      <Flex
        minH={{ base: "72px", lg: "140px", "1xl": "220px" }}
        w="100%"
        gap={{ base: "16px", lg: "24px" }}
        pos="relative"
        flexDir={{ base: "row", lg: "column" }}
        overflow="hidden"
        align={{ base: "center", lg: "flex-start" }}
        justify={{ lg: "space-between" }}
        p={{ base: "16px", lg: "20px", "1xl": "40px" }}
        borderRadius={{ base: "16px", "1xl": "24px" }}
        bg="extsy.neutral1000"
      >
        <Box>
          <Flex
            align="center"
            justify="center"
            zIndex="100"
            bg="extsy.neutral900"
            borderRadius="8px"
            height="40px"
            width="40px"
          >
            <Image src={TradingIco} alt="wallet" height="24px" width="24px" />
          </Flex>
        </Box>
        <Flex
          flexDir={{ base: "row", lg: "column" }}
          align={{ base: "center", lg: "flex-start" }}
          justify={{ base: "space-between", lg: "flex-start" }}
          w="100%"
          gap="16px"
        >
          <CustomText text={t("trading")} color="extsy.baseWhite" />
          <CustomText
            text={"$0.00"}
            color="extsy.baseWhite"
            size={{ base: "24px", lg: "36px" }}
          />
        </Flex>
      </Flex>
      <Flex
        minH={{ base: "72px", lg: "140px", "1xl": "220px" }}
        w="100%"
        gap={{ base: "16px", lg: "24px" }}
        pos="relative"
        flexDir={{ base: "row", lg: "column" }}
        overflow="hidden"
        align={{ base: "center", lg: "flex-start" }}
        justify={{ lg: "space-between" }}
        p={{ base: "16px", lg: "20px", "1xl": "40px" }}
        borderRadius={{ base: "16px", "1xl": "24px" }}
        bg="extsy.neutral1000"
      >
        <Box>
          <Flex
            align="center"
            justify="center"
            zIndex="100"
            bg="extsy.neutral900"
            borderRadius="8px"
            height="40px"
            width="40px"
          >
            <Image src={P2PIco} alt="wallet" height="24px" width="24px" />
          </Flex>
        </Box>
        <Flex
          flexDir={{ base: "row", lg: "column" }}
          align={{ base: "center", lg: "flex-start" }}
          justify={{ base: "space-between", lg: "flex-start" }}
          w="100%"
          gap="16px"
        >
          <CustomText text={t("p2p")} color="extsy.baseWhite" />
          <CustomText
            text={"$0.00"}
            color="extsy.baseWhite"
            size={{ base: "24px", lg: "36px" }}
          />
        </Flex>
      </Flex>
    </Flex>
  );
}

export default AvailableBalanceCard;
