import { Box, Flex, ListItem, UnorderedList } from "@chakra-ui/react";
import React from "react";
import CustomText from "../../components/text/CustomText";
import { useTranslation } from "react-i18next";
import SwapCard from "../../components/cards/SwapCard";

function Swap() {
  const { t } = useTranslation();
  return (
    <Flex flexDir="column" gap={{ base: "24px", lg: "32px" }}>
      <Flex
        gap={{ base: "24px", lg: "32px" }}
        h="100%"
        flexDir={{ base: "column", xl: "row" }}
      >
        <Box
          p={{ base: "16px", lg: "32px", "1xl": "56px 50px" }}
          borderRadius="16px"
          border="1px solid"
          borderColor="extsy.neutral1000"
          bg="extsy.neutral1000"
          maxW={{ xl: "760px" }}
          w="100%"
          h="100%"
        >
          <SwapCard swap />
        </Box>
        <Box
          p={{ base: "16px", lg: "32px", "1xl": "56px" }}
          borderRadius="16px"
          border="1px solid"
          borderColor="extsy.neutral1000"
          bg="extsy.neutral1000"
          minH="100%"
        >
          <CustomText
            text={t("usefulTips")}
            size={{ base: "16px", lg: "24px" }}
            weight={{ base: "400", lg: "700" }}
            lh="24px"
            family="Metropolis"
            color="extsy.white"
          />
          <UnorderedList mt={{ base: "16px", lg: "32px" }}>
            <ListItem
              fontSize={{ base: "14px", lg: "18px" }}
              fontFamily="Metropolis"
              color="extsy.neutral300"
              fontWeight="400"
            >
              <span
                style={{
                  color: "#fff",
                  fontWeight: 700,
                }}
              >
                {t("checkExchangeRate")}{" "}
              </span>
              {t("checkExchangeRateDetail")}
            </ListItem>
            <ListItem
              fontSize={{ base: "14px", lg: "18px" }}
              fontFamily="Metropolis"
              color="extsy.neutral300"
              fontWeight="400"
            >
              <span
                style={{
                  color: "#fff",
                  fontWeight: 700,
                }}
              >
                {t("considerNetworkFee")}{" "}
              </span>
              {t("considerNetworkFeeDetail")}
            </ListItem>
            <ListItem
              fontSize={{ base: "14px", lg: "18px" }}
              fontFamily="Metropolis"
              color="extsy.neutral300"
              fontWeight="400"
            >
              <span
                style={{
                  color: "#fff",
                  fontWeight: 700,
                }}
              >
                {t("doubleCheckWalletAddress")}{" "}
              </span>
              {t("doubleCheckWalletAddressDetail")}
            </ListItem>
            <ListItem
              fontSize={{ base: "14px", lg: "18px" }}
              fontFamily="Metropolis"
              color="extsy.neutral300"
              fontWeight="400"
            >
              <span
                style={{
                  color: "#fff",
                  fontWeight: 700,
                }}
              >
                {t("beAwareOfSwapTime")}{" "}
              </span>
              {t("beAwareOfSwapTimeDetail")}
            </ListItem>
            <ListItem
              fontSize={{ base: "14px", lg: "18px" }}
              fontFamily="Metropolis"
              color="extsy.neutral300"
              fontWeight="400"
            >
              <span
                style={{
                  color: "#fff",
                  fontWeight: 700,
                }}
              >
                {t("mindRateExpiration")}{" "}
              </span>
              {t("mindRateExpirationDetail")}
            </ListItem>
            <ListItem
              fontSize={{ base: "14px", lg: "18px" }}
              fontFamily="Metropolis"
              color="extsy.neutral300"
              fontWeight="400"
            >
              <span
                style={{
                  color: "#fff",
                  fontWeight: 700,
                }}
              >
                {t("useCorrectContractAddress")}{" "}
              </span>
              {t("useCorrectContractAddressDetail")}
            </ListItem>
          </UnorderedList>
        </Box>
      </Flex>
      <Box
        p={{ base: "16px", lg: "32px", "1xl": "56px" }}
        borderRadius="16px"
        border="1px solid"
        borderColor="extsy.neutral1000"
        bg="extsy.neutral1000"
        h="100%"
      >
        <CustomText
          text={t("howToCancelSwap")}
          size={{ base: "16px", lg: "24px" }}
          weight={{ base: "400", lg: "700" }}
          lh="24px"
          family="Metropolis"
          color="extsy.white"
        />
        <UnorderedList mt={{ base: "16px", lg: "24px" }}>
          <ListItem
            fontSize={{ base: "14px", lg: "18px" }}
            fontFamily="Metropolis"
            color="extsy.neutral300"
            fontWeight="400"
          >
            <span
              style={{
                color: "#fff",
                fontWeight: 700,
              }}
            >
              {t("noFundsSent")}{" "}
            </span>
            {t("noFundsSentDetail")}
          </ListItem>
          <ListItem
            fontSize={{ base: "14px", lg: "18px" }}
            fontFamily="Metropolis"
            color="extsy.neutral300"
            fontWeight="400"
          >
            <span
              style={{
                color: "#fff",
                fontWeight: 700,
              }}
            >
              {t("fundsAlreadySent")}{" "}
            </span>
            {t("fundsAlreadySentDetail")}
          </ListItem>
        </UnorderedList>
      </Box>
    </Flex>
  );
}

export default Swap;
