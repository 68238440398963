import {
  Box,
  Button,
  Flex,
  Image,
  SkeletonText,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import CustomText from "../../components/text/CustomText";
import { useTranslation } from "react-i18next";
import TableSearchInput from "../../components/form/TableSearchInput";
import EmptyTrxIco from "../../assets/images/empty-trx-ico.svg";
import { IconChevronDown, IconChevronUp, IconX } from "@tabler/icons-react";
import CardIco from "../../assets/images/dashboard/virtual-inactive.svg";
import SwapIco from "../../assets/images/dashboard/swap-inactive.svg";
import ArrowBack from "../../assets/images/black-left.svg";
import ArrowForward from "../../assets/images/black-right.svg";
import {
  useAddConnectWallet,
  useGetConnectedWalletsByAddress,
  useGetNetworks,
} from "../../utils/api/wallet.api";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { useAccount, useDisconnect } from "wagmi";
import PlaceHolderLogo from "../../assets/images/logo-icon.svg";
import CustomModal from "../../components/modal/customModal";
import { formatNumber } from "../../utils/functions/formatNumber";
import InitiateWallet from "../../assets/svgs/InitiateWallet";
import ArrowConnect from "../../assets/svgs/ArrowConnect";
import SelectWallet from "../../assets/svgs/SelectWallet";
import ListGradient from "../../assets/images/list-gradient.svg";
import GradientText from "../../components/text/GradientText";
import ConnectWalletVector from "../../assets/images/connect-wallet-vector.svg";

function BalanceTracker() {
  const { t } = useTranslation();
  const { disconnect } = useDisconnect();
  const { isConnected: walletConnected, address }: any = useAccount();
  const [isConnected, setIsConnected] = useState(
    localStorage.getItem("isConnected") || false
  );
  const [limit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [search, setSearch] = useState("");
  const [sortingKey, setSortingKey] = useState("");
  const [sortingOrder, setSortingOrder] = useState("ASC");

  const { data, isLoading } = useGetConnectedWalletsByAddress(
    address,
    limit,
    offset,
    search,
    sortingKey,
    sortingOrder
  );
  const {
    isOpen: isDisconnectOpen,
    onOpen: onDisconnectOpen,
    onClose: onDisconnectClose,
  } = useDisclosure();

  const totalTransactions = data?.total || 0; // Total number of transactions
  const totalPages = Math.ceil(totalTransactions / limit); // Total number of pages
  const currentPage = Math.floor(offset / limit) + 1; // Current page number

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setOffset((prevOffset) => prevOffset + limit);
    }
  };

  // Handle going to the previous page
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setOffset((prevOffset) => prevOffset - limit);
    }
  };

  // Update localStorage whenever walletConnected changes
  useEffect(() => {
    localStorage.setItem("isConnected", walletConnected ? "true" : "false");
    setIsConnected(walletConnected);
  }, [walletConnected]);
  return isConnected ? (
    <Flex
      flexDir="column"
      justify="space-between"
      p={{ base: "16px", lg: "24px" }}
      bg="extsy.neutral1000"
      borderRadius="16px"
      boxShadow="0px 3px 4px 0px rgba(0, 0, 0, 0.03)"
      minH="calc(100vh - 120px)"
    >
      <Box>
        <Flex align="center" justify="space-between" pb="20px">
          <CustomText
            text={t("balance")}
            family="Metropolis"
            size={{ base: "18px", lg: "20px" }}
            color="extsy.baseWhite"
            ls="1px"
          />
          <TableSearchInput
            placeholder={t("searchYourAsset")}
            onChange={(e: any) => setSearch(e.target.value)}
            minW={{ base: "130px", lg: "250px" }}
            maxW={{ base: "130px", lg: "250px" }}
          />
        </Flex>
        {isConnected ? (
          <>
            {data?.data?.length === 0 ? (
              <>
                <Flex
                  align="center"
                  justify="center"
                  flexDir="column"
                  gap="24px"
                  minH="401px"
                >
                  <Image src={EmptyTrxIco} alt="empty" />
                  <CustomText
                    text={t("noBalanceAvailable")}
                    color="extsy.neutral400"
                  />
                </Flex>
              </>
            ) : (
              <TableContainer minH="406px">
                <Table border="1px solid" color="extsy.neutral900">
                  <Thead bg="extsy.neutral900" height="40px">
                    <Tr>
                      <Th
                        borderColor="extsy.neutral900"
                        maxW="583px"
                        minW="260px"
                      >
                        <Flex align="center" gap="4px">
                          <CustomText
                            text={t("coinNUsdValue")}
                            size="13px"
                            color="extsy.baseWhite"
                            weight="500"
                            ls="-0.13px"
                          />
                          <Flex
                            flexDir="column"
                            onClick={() => {
                              setSortingKey("coinName");
                              if (sortingOrder === "DESC") {
                                setSortingOrder("ASC");
                              } else {
                                setSortingOrder("DESC");
                              }
                            }}
                          >
                            <IconChevronUp
                              cursor="pointer"
                              color="#FAFAFA"
                              size="14px"
                            />
                            <IconChevronDown
                              color="#FAFAFA"
                              size="14px"
                              cursor="pointer"
                              style={{
                                marginTop: -7,
                              }}
                            />
                          </Flex>
                        </Flex>
                      </Th>
                      <Th
                        borderColor="extsy.neutral900"
                        maxW="583px"
                        minW="260px"
                      >
                        <Flex align="center" gap="4px">
                          <CustomText
                            text={t("amount")}
                            size="13px"
                            color="extsy.baseWhite"
                            weight="500"
                            ls="-0.13px"
                          />
                          <Flex
                            flexDir="column"
                            onClick={() => {
                              setSortingKey("amount");
                              if (sortingOrder === "DESC") {
                                setSortingOrder("ASC");
                              } else {
                                setSortingOrder("DESC");
                              }
                            }}
                          >
                            <IconChevronUp
                              cursor="pointer"
                              color="#FAFAFA"
                              size="14px"
                            />
                            <IconChevronDown
                              color="#FAFAFA"
                              size="14px"
                              cursor="pointer"
                              style={{
                                marginTop: -7,
                              }}
                            />
                          </Flex>
                        </Flex>
                      </Th>
                      <Th
                        borderColor="extsy.neutral900"
                        maxW="283px"
                        minW="230px"
                      >
                        <Flex align="center" gap="4px">
                          <CustomText
                            text={t("actions")}
                            size="13px"
                            color="extsy.baseWhite"
                            weight="500"
                            ls="-0.13px"
                          />
                        </Flex>
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {isLoading
                      ? [1, 2, 3, 4, 5].map((_, index) => (
                          <LoadingRow key={index} />
                        ))
                      : data?.data?.map((balance: any, index: any) => (
                          <Tr key={index}>
                            <Td
                              height="54px"
                              borderColor="extsy.neutral900"
                              borderRight="1px solid"
                              py="0"
                            >
                              <Flex align="center" justify="space-between">
                                <Flex gap="16px" align="center">
                                  <Image
                                    src={
                                      balance?.coin?.logo_url || PlaceHolderLogo
                                    }
                                    alt={"logo_url"}
                                    boxSize="32px"
                                    onError={(e) => {
                                      e.currentTarget.src = PlaceHolderLogo; // Replace with your placeholder image URL
                                    }}
                                  />
                                  <CustomText
                                    text={balance?.coin?.symbol}
                                    size="14px"
                                    color="extsy.baseWhite"
                                  />
                                </Flex>
                                <Flex gap="8px" align="center">
                                  <CustomText
                                    text={balance?.coin?.price || 0}
                                    size="14px"
                                    color="extsy.neutral100"
                                  />
                                  <Flex align="center" gap="2px">
                                    <CustomText
                                      text={`(${
                                        formatNumber(
                                          balance?.coin?.price_change,
                                          2
                                        ) || 0
                                      }%)`}
                                      size="14px"
                                      color={
                                        balance?.coin?.price_change < 0
                                          ? "#ED3D4E"
                                          : "#15B097"
                                      }
                                      ls="1px"
                                    />
                                    {balance?.coin?.price_change < 0 ? (
                                      <IconChevronDown
                                        color="#ED3D4E"
                                        size="14px"
                                      />
                                    ) : (
                                      <IconChevronUp
                                        color="#15B097"
                                        size="14px"
                                      />
                                    )}
                                  </Flex>
                                </Flex>
                              </Flex>
                            </Td>
                            <Td
                              borderColor="extsy.neutral900"
                              borderRight="1px solid"
                            >
                              <Flex align="center" justify="space-between">
                                <CustomText
                                  text={balance?.balance || 0}
                                  size="14px"
                                />
                                <CustomText
                                  text={`~$${balance?.balance_usd || 0}`}
                                  size="14px"
                                  color="extsy.neutral600"
                                />
                              </Flex>
                            </Td>
                            <Td
                              borderColor="extsy.neutral900"
                              borderRight="1px solid"
                            >
                              <Flex gap="16px" align="center">
                                <Flex
                                  cursor="pointer"
                                  gap="4px"
                                  align="center"
                                  onClick={() => {
                                    window.open(
                                      `${process.env.REACT_APP_EXTSY_PRE_RELEASE_DOMAIN}/pages/buySell`,
                                      "_self"
                                    );
                                  }}
                                >
                                  <Image
                                    src={CardIco}
                                    alt="buy"
                                    height="18px"
                                    width="18px"
                                  />
                                  <CustomText
                                    text={t("buy")}
                                    size="10px"
                                    color="extsy.baseWhite"
                                    cursor="pointer"
                                  />
                                </Flex>
                                <Box
                                  bg="extsy.neutral900"
                                  height="9px"
                                  width="1px"
                                />
                                <Flex
                                  cursor="pointer"
                                  gap="4px"
                                  align="center"
                                  onClick={() => {
                                    window.open(
                                      `${process.env.REACT_APP_EXTSY_PRE_RELEASE_DOMAIN}/pages/buySell`,
                                      "_self"
                                    );
                                  }}
                                >
                                  <Image
                                    src={CardIco}
                                    alt="buy"
                                    height="18px"
                                    width="18px"
                                  />
                                  <CustomText
                                    text={t("sell")}
                                    size="10px"
                                    color="extsy.baseWhite"
                                    cursor="pointer"
                                  />
                                </Flex>
                                <Box
                                  bg="extsy.neutral900"
                                  height="9px"
                                  width="1px"
                                />
                                <Flex
                                  cursor="pointer"
                                  gap="4px"
                                  align="center"
                                  onClick={() => {
                                    window.open(
                                      `${process.env.REACT_APP_EXTSY_PRE_RELEASE_DOMAIN}/pages/swap?from=${balance?.coin_id}`,
                                      "_self"
                                    );
                                  }}
                                >
                                  <Image
                                    src={SwapIco}
                                    alt="swap"
                                    height="18px"
                                    width="18px"
                                  />
                                  <CustomText
                                    text={t("swap")}
                                    size="10px"
                                    color="extsy.baseWhite"
                                    cursor="pointer"
                                  />
                                </Flex>
                              </Flex>
                            </Td>
                          </Tr>
                        ))}
                  </Tbody>
                </Table>
              </TableContainer>
            )}
          </>
        ) : (
          <Flex
            align="center"
            justify="center"
            flexDir="column"
            gap="24px"
            minH="401px"
          >
            <Image src={EmptyTrxIco} alt="empty" />
            <CustomText
              text={t("noBalanceAvailable")}
              color="extsy.neutral400"
            />
            <UserConnectWallet
              isConnected={isConnected}
              setIsConnected={setIsConnected}
              onDisconnectOpen={onDisconnectOpen}
            />
          </Flex>
        )}
      </Box>
      {isConnected && (
        <Flex gap="16px" px="20px" py="14px" align="center" justify="flex-end">
          {/* Showing range of items */}
          <CustomText
            text={`${offset + 1}-${Math.min(
              offset + limit,
              totalTransactions
            )} ${t("of")} ${totalTransactions}`}
            size="13px"
            weight="500"
            color="extsy.neutral300"
          />

          <Flex gap="8px">
            {/* Previous Page Button */}
            <Image
              src={ArrowBack}
              alt="arrow-back"
              cursor={currentPage > 1 ? "pointer" : "not-allowed"}
              opacity={currentPage > 1 ? 1 : 0.5}
              onClick={handlePrevPage}
            />

            {/* Page Numbers */}
            {[...Array(totalPages)].map((_, index) => (
              <Flex
                key={index}
                flexDir="column"
                align="center"
                justify="center"
                bg={
                  currentPage === index + 1 ? "extsy.neutral900" : "transparent"
                }
                borderRadius="6px"
                height="30px"
                width="30px"
                cursor="pointer"
                onClick={() => setOffset(index * limit)}
              >
                <CustomText text={index + 1} size="14px" weight="500" />
              </Flex>
            ))}

            {/* Next Page Button */}
            <Image
              src={ArrowForward}
              alt="arrow-forward"
              cursor={currentPage < totalPages ? "pointer" : "not-allowed"}
              opacity={currentPage < totalPages ? 1 : 0.5}
              onClick={handleNextPage}
            />
          </Flex>
        </Flex>
      )}
      <CustomModal
        isOpen={isDisconnectOpen}
        onClose={() => {
          onDisconnectClose();
        }}
        onSubmit={() => {
          disconnect();
          localStorage.removeItem("isConnected");
          onDisconnectClose();
        }}
        headerText={t("disconnectWallet")}
        btnText={t("yesDisconnect")}
        closeBtntext={t("cancel")}
        width={"600px"}
      >
        <Flex p="32px 24px" flexDir="column" gap="32px" alignItems="center">
          <CustomText
            text={t("areYouSureDisconnectWallet")}
            align="center"
            color="extsy.neutral300"
          />
        </Flex>
      </CustomModal>
    </Flex>
  ) : (
    <Flex flexDir="column" gap="24px">
      <Flex
        p={{ base: "16px", lg: "24px" }}
        bg="extsy.neutral1000"
        borderRadius="16px"
        boxShadow="0px 3px 4px 0px rgba(0, 0, 0, 0.03)"
        align="center"
        justify="center"
        gap="8px"
        minH="220px"
        display={{ base: "none", lg: "flex" }}
      >
        <Flex flexDir="column" gap="24px" align="center" maxW="208px" w="100%">
          <Flex
            align="center"
            justify="center"
            borderRadius="8px"
            bg="extsy.neutral900"
            h="56px"
            w="56px"
          >
            <InitiateWallet />
          </Flex>
          <CustomText text="Initiate Connection" weight="600" />
        </Flex>
        <ArrowConnect />
        <Flex flexDir="column" gap="24px" align="center" maxW="208px" w="100%">
          <Flex
            align="center"
            justify="center"
            borderRadius="8px"
            bg="extsy.neutral900"
            h="56px"
            w="56px"
          >
            <SelectWallet />
          </Flex>
          <CustomText text="Select Your Wallet" weight="600" />
        </Flex>
        <ArrowConnect />
        <Flex flexDir="column" gap="24px" align="center" maxW="208px" w="100%">
          <Flex
            align="center"
            justify="center"
            borderRadius="8px"
            bg="extsy.neutral900"
            h="56px"
            w="56px"
          >
            <Image src={ListGradient} alt="list" />
          </Flex>
          <CustomText text="Track Your Balance" weight="600" />
        </Flex>
      </Flex>
      <Flex
        p={{ base: "24px 16px", lg: "24px" }}
        bg="extsy.neutral1000"
        borderRadius="16px"
        boxShadow="0px 3px 4px 0px rgba(0, 0, 0, 0.03)"
        flexDir={{ base: "column", lg: "row" }}
        align="center"
        justify="center"
        gap="32px"
        minH="442px"
      >
        <Flex
          flexDir="column"
          align={{ base: "center", lg: "flex-start" }}
          gap="32px"
          maxW="440px"
          w="100%"
        >
          <GradientText
            text="Connect Your Wallet"
            weight="700"
            bg="extsy.g1"
            size="24px"
            lh="32px"
          />
          <CustomText text="In just few clicks connect your crypto wallet." />
          <UserConnectWallet
            isConnected={isConnected}
            setIsConnected={setIsConnected}
            onDisconnectOpen={onDisconnectOpen}
          />
        </Flex>
        <Image
          src={ConnectWalletVector}
          alt="info"
          mx={{ base: "auto", lg: "0" }}
          maxW={{ base: "320px", lg: "420px" }}
        />
      </Flex>
    </Flex>
  );
}

export default BalanceTracker;

const LoadingRow = () => {
  return (
    <Tr>
      <Td
        height="54px"
        borderColor="extsy.neutral900"
        borderRight="1px solid"
        py="0"
        pos="relative"
        overflow="hidden"
      >
        <SkeletonText noOfLines={1} w="80px" />
      </Td>
      <Td borderColor="extsy.neutral900" borderRight="1px solid">
        <Flex align="center" gap="12px">
          <SkeletonText noOfLines={1} w="40px" />
        </Flex>
      </Td>

      <Td borderColor="extsy.neutral900" borderRight="1px solid">
        <SkeletonText noOfLines={1} w="80px" />
      </Td>
    </Tr>
  );
};

const UserConnectWallet = ({ onDisconnectOpen, setIsConnected }: any) => {
  const { t } = useTranslation();
  const { open } = useWeb3Modal();
  const { isConnected, address } = useAccount();
  const { mutate: addConnectWallet } = useAddConnectWallet();

  // Fetch network data
  const { data: networks } = useGetNetworks(10, 0); // Adjust limit and offset as needed

  // Determine which network ID to use, e.g., Ethereum
  const networkId = networks?.find(
    (network: any) => network?.name === "Ethereum"
  )?.id;

  // Trigger API call when wallet is connected and network ID is available
  useEffect(() => {
    if (isConnected && networkId) {
      addConnectWallet({ wallet_address: address, network_id: networkId });
      localStorage.setItem("isConnected", "true");
    }
  }, [isConnected, address, networkId, addConnectWallet]);
  return (
    <Button
      bg="extsy.g1"
      borderRadius="8px"
      height={{ base: "34px", lg: "48px" }}
      display="flex"
      w={"172px"}
      // justifyContent={"flex-start"}
      alignItems="center"
      gap="30px"
      _hover={{ bg: "extsy.g1" }}
      onClick={() => {
        if (isConnected) {
          onDisconnectOpen();
        } else {
          open();
        }
      }}
    >
      <CustomText
        text={
          isConnected
            ? address?.substring(0, 4) + "****" + address?.slice(-6)
            : t("connectWallet")
        }
        size={{ base: "12px", lg: "14px" }}
        weight={{ base: "400", lg: "600" }}
        family="Metropolis"
        color="extsy.bg"
        cursor="pointer"
        ls="0.28px"
      />
      {isConnected && <IconX size={18} color="#000" />}
    </Button>
  );
};
