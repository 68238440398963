import {
  Box,
  Flex,
  Image,
  Tooltip,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import LogoText from "../../assets/images/extsy-logo.svg";
import Logo from "../../assets/images/logo-icon.svg";
import ArrowCollapseIco from "../../assets/images/collapse-ico.svg";
import CollapseOpenIco from "../../assets/images/open-collapse-ico.svg";

import CustomText from "../text/CustomText";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  DASHBOARD_MENU,
  DASHBOARD_OTHER_MENU,
} from "../../utils/constants/constants";
import LogoutModal from "../modal/LogoutModal";

function Sidebar() {
  const { t } = useTranslation();

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [hoveredItem, setHoveredItem] = useState<any>(null); // Track hovered item
  const {
    isOpen: isLogoutOpen,
    onOpen: onLogoutOpen,
    onClose: onLogoutClose,
  } = useDisclosure();
  const [isLessThan1600] = useMediaQuery("(max-width: 1600px)");

  const [isCollapsed, setIsCollapsed] = useState(false);

  const handleNavigate = (path: any) => {
    navigate(path);
  };

  useEffect(() => {
    if (isLessThan1600) {
      setIsCollapsed(true);
    }
  }, [isLessThan1600]);

  const isActive = (path: string) => {
    const currentPath = new URL(path, window.location.origin)?.pathname;
    return pathname === currentPath;
  };

  return (
    <Flex
      p="24px"
      bg="extsy.neutral1000"
      w={isCollapsed ? "92px" : "280px"}
      borderRadius="16px"
      h="calc(100vh - 48px)"
      alignItems="flex-start"
      transition="width 0.3s"
      flexDir="column"
    >
      <Flex justify="space-between" align="center" w="100%">
        <Flex gap="6px" cursor="pointer">
          <Image src={Logo} alt="logo" width={"36px"} height={"26px"} />
          {!isCollapsed && (
            <Image src={LogoText} alt="logo" width="71px" height="25px" />
          )}
        </Flex>
        <Flex
          display={isLessThan1600 ? "none" : "flex"}
          cursor="pointer"
          height="30px"
          width="30px"
          bg="extsy.text"
          borderRadius="8px"
          align="center"
          justify="center"
          marginRight="-38px"
          userSelect="none"
          onClick={() => {
            setIsCollapsed(!isCollapsed);
          }}
        >
          <Image
            src={isCollapsed ? CollapseOpenIco : ArrowCollapseIco}
            alt="collapse"
          />
        </Flex>
      </Flex>
      <Flex
        mt="40px"
        flexDir="column"
        justify="space-between"
        gap="8px"
        overflowY="auto"
        h="100%"
      >
        <Box>
          <CustomText
            text={t("menu")}
            color="extsy.neutral200"
            size="12px"
            weight="600"
            ls="0.12px"
            align={isCollapsed ? "center" : "start"}
          />
          <Flex flexDir="column" gap="8px" mt="8px" width="100%">
            {DASHBOARD_MENU.map((item, index) => {
              return (
                <Tooltip
                  key={index}
                  label={
                    ["virtualCard"].includes(item.key)
                      ? "Coming Soon"
                      : t(item.key)
                  }
                  display={isCollapsed ? "flex" : "none"}
                  placement="right"
                  ml="10px"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="8px"
                  bg="extsy.white"
                  p="8px 24px"
                  color="extsy.bg"
                  fontFamily="Metropolis"
                  fontSize="16px"
                  fontWeight="400"
                >
                  <Flex
                    align="center"
                    justify={isCollapsed ? "center" : "flex-start"}
                    cursor={
                      ["virtualCard"].includes(item.key)
                        ? "not-allowed"
                        : "pointer"
                    }
                    p={isCollapsed ? "0px" : "12px"}
                    height="44px"
                    width={isCollapsed ? "44px" : "232px"}
                    gap="8px"
                    borderRadius="8px"
                    bg={isActive(item.path) ? "extsy.g1" : "transparent"}
                    onMouseEnter={() => {
                      if (["virtualCard"].includes(item.key)) {
                        setHoveredItem(item.key); // Set hovered item
                      }
                    }}
                    onMouseLeave={() => {
                      if (["virtualCard"].includes(item.key)) {
                        setHoveredItem(null); // Clear hovered item
                      }
                    }}
                    onClick={() => {
                      if (!["virtualCard"].includes(item.key)) {
                        handleNavigate(item.path);
                      }
                    }}
                  >
                    <item.icon
                      size="24px"
                      color={isActive(item.path) ? "#0B0A0A" : "#A5A1A1"}
                    />

                    {!isCollapsed && (
                      <CustomText
                        text={
                          hoveredItem === item.key ? "Coming Soon" : t(item.key)
                        }
                        color={
                          isActive(item.path)
                            ? "extsy.bg"
                            : hoveredItem === item.key
                            ? "extsy.white"
                            : "extsy.neutral300"
                        }
                        cursor={
                          hoveredItem === item.key ? "not-allowed" : "pointer"
                        }
                      />
                    )}
                  </Flex>
                </Tooltip>
              );
            })}
          </Flex>
        </Box>
        <Box>
          <CustomText
            text={t("others")}
            mt="20px"
            color="extsy.neutral200"
            size="12px"
            weight="600"
            ls="0.12px"
            align={isCollapsed ? "center" : "start"}
          />
          <Flex flexDir="column" gap="8px" mt="8px" width="100%">
            {DASHBOARD_OTHER_MENU.map((item, index) => (
              <Tooltip
                key={index}
                label={t(item?.key)}
                display={isCollapsed ? "flex" : "none"}
                placement="right"
                ml="10px"
                alignItems="center"
                justifyContent="center"
                borderRadius="8px"
                bg="extsy.white"
                p="8px 24px"
                color="extsy.bg"
                fontFamily="Metropolis"
                fontSize="16px"
                fontWeight="400"
              >
                <Flex
                  align="center"
                  justify={isCollapsed ? "center" : "flex-start"}
                  cursor="pointer"
                  p={isCollapsed ? "0px" : "12px"}
                  height="44px"
                  width={isCollapsed ? "44px" : "232px"}
                  gap="8px"
                  borderRadius="8px"
                  bg={isActive(item.path) ? "extsy.g1" : "transparent"}
                  onClick={() => {
                    if (item?.name === "Logout") {
                      onLogoutOpen();
                    } else if (item?.name === "Support") {
                      window.open("https://extsy.com/", "_blank");
                    } else {
                      handleNavigate(item?.path);
                    }
                  }}
                >
                  <item.icon
                    size="24px"
                    color={
                      item?.name === "Logout"
                        ? "#FF727F"
                        : isActive(item.path)
                        ? "#0B0A0A"
                        : "#A5A1A1"
                    }
                  />

                  {!isCollapsed && (
                    <CustomText
                      text={t(item.key)}
                      color={
                        isActive(item.path) ? "extsy.bg" : "extsy.neutral300"
                      }
                      cursor="pointer"
                    />
                  )}
                </Flex>
              </Tooltip>
            ))}
          </Flex>
        </Box>
      </Flex>
      <LogoutModal isOpen={isLogoutOpen} onClose={onLogoutClose} />
    </Flex>
  );
}

export default Sidebar;
