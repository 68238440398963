import { WalletConnectAdapter } from "@tronweb3/tronwallet-adapters";
export const tronWallet = new WalletConnectAdapter({
  network: "Shasta",
  options: {
    relayUrl: "wss://relay.walletconnect.com",
    projectId: process.env.REACT_APP_REOWN_PROJECT_ID || "",
    metadata: {
      name: "Extsy Staging",
      description: "AppKit Example",
      url: "https://extsy.com", // origin must match your domain & subdomain
      icons: ["https://assets.reown.com/reown-profile-pic.png"],
    },
  },
  web3ModalConfig: {
    /**
     * Recommended Wallets are fetched from WalletConnect explore api:
     * https://walletconnect.com/explorer?type=wallet&version=2.
     * You can copy these ids from the page.
     */
    explorerRecommendedWalletIds: [
      "1ae92b26df02f0abca6304df07debccd18262fdf5fe82daa81593582dac9a369",
      "4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0",
    ],
  },
});
