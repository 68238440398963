import { useMutation, useQuery } from "@tanstack/react-query";
import { client2 } from "./api-client2";

export const useGetCoins = (isPairs?: boolean) =>
  useQuery({
    queryKey: ["getCoins"],
    queryFn: async () => {
      const response = await client2(`swap/getCoins?isPairs=${isPairs}`);
      return response;
    },
  });

export const useGetSwapPairs = (coinId: string | undefined) => {
  return useQuery({
    queryKey: ["getSwapPairs", coinId],
    queryFn: async () => {
      const response = await client2(`swap/getCoins?from_coin_id=${coinId}`);
      return response;
    },
    enabled: !!coinId,
  });
};

export const useGetPairs = () =>
  useQuery({
    queryKey: ["getPairs"],
    queryFn: async () => {
      const response = await client2(`swap/getPairs`);
      return response;
    },
  });

export const useGetPairsSymbols = () =>
  useQuery({
    queryKey: ["getPairsSymbols"],
    queryFn: async () => {
      const response = await client2(`swap/getPairsSymbols`);
      return response;
    },
  });

export const useEstimateCashBack = () =>
  useMutation<any, Error, any>({
    mutationFn: async (payload) => {
      const response = await client2(`swap/estimateCashback`, {
        method: "POST",
        data: payload,
      });

      if (response.error) {
        throw new Error(response.error);
      }
      return response;
    },
  });

export const useValidateAddress = () =>
  useMutation<any, Error, any>({
    mutationFn: async (payload) => {
      const response = await client2(`swap/validateAddrress`, {
        method: "POST",
        data: payload,
      });

      if (response.error) {
        throw new Error(response.error);
      }
      return response;
    },
  });

export const useGetSwapQuoteDetails = (id?: string | undefined) =>
  useQuery({
    queryKey: ["getSwapQuoteDetails"],
    queryFn: async () => {
      const response = await client2(`swap/getSwapQuoteDetail/${id}`);
      return response;
    },
    enabled: !!id,
  });

export const useGetSwapQuote = () =>
  useMutation<any, Error, any>({
    mutationFn: async (payload) => {
      const response = await client2(`swap/getSwapQuote`, {
        method: "POST",
        data: payload,
      });

      if (response.error) {
        throw new Error(response.error);
      }
      return response;
    },
  });

export const useGetSwapDetailById = (swapId: string | string[] | undefined) =>
  useQuery({
    queryKey: ["getSwapDetailById"],
    queryFn: async () => {
      const response = await client2(`swap/getSwapDetail/${swapId}`);
      return response;
    },
    enabled: !!swapId,
  });

export const useSwapFixed = () =>
  useMutation<any, Error, any>({
    mutationFn: async (payload) => {
      const response = await client2(`swap/swapFixedPro`, {
        method: "POST",
        data: payload,
      });

      if (response.error) {
        throw new Error(response.error);
      }
      return response;
    },
  });

export const useSwap = () =>
  useMutation<any, Error, any>({
    mutationFn: async (payload) => {
      const response = await client2(`swap/swapPro`, {
        method: "POST",
        data: payload,
      });

      if (response.error) {
        throw new Error(response.error);
      }
      return response;
    },
  });
