import {
  Box,
  Center,
  Flex,
  Image,
  Input,
  Skeleton,
  SkeletonCircle,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import CustomText from "../../components/text/CustomText";
import PrimaryButton from "../../components/button/PrimaryButton";
import ArrowDown from "../../assets/images/arrow-down-circle.svg";
import { IconAlertTriangleFilled, IconCheck } from "@tabler/icons-react";
import { useCheckAml } from "../../utils/api/dashboard.api";
import { useCustomAlert } from "../../utils/api/alert";
import { useGetCoins, useValidateAddress } from "../../utils/api/swap.api";
import OutsideClickHandler from "../../hooks/CustomOutsideClick";
import {
  useGetLoggedInUser,
  useGetSumsubToken,
} from "../../utils/api/auth.api";
import { OnboardModal } from "../../components/modal/onBoardModal";
import SumsubWebSdk from "@sumsub/websdk-react";
import { useQueryClient } from "@tanstack/react-query";
import VerifySuccessImg from "../../assets/images/verify-kyc-icon.svg";

function AMLCard() {
  const queryClient: any = useQueryClient();

  const [isCoinOpen, setIsCoinOpen] = useState(false);
  const [address, setAddress] = useState("");
  const [coinId, setCoinId] = useState("");
  const [activeCoin, setActiveCoin] = useState<any>(null);
  const [isAddressValid, setIsAddressValid] = useState(true);
  const [error, setError] = useState(""); // State to store the error message
  const [isVerificationModalOpen, setIsVerificationModalOpen] = useState(false);
  const [isVerifySuccessOpen, setIsVerifySuccessOpen] = useState(false);

  const { data: sumsubToken } = useGetSumsubToken();
  const amlCheckMutation = useCheckAml();
  const alert = useCustomAlert();
  const validateAddress = useValidateAddress();
  const { data: coins, isLoading } = useGetCoins();
  const { data: user } = useGetLoggedInUser();

  const checkValidateAddress = () => {
    validateAddress
      .mutateAsync({
        coin_id: coinId,
        address: address,
      })
      .then((result) => {
        console.log(result);
        if (result?.result) {
          setIsAddressValid(true);
        } else {
          setError("Invalid Address");
          setIsAddressValid(false);
        }
      })
      .catch((err) => {
        setError(err.message);
        setIsAddressValid(false);
      });
  };

  useEffect(() => {
    const debounceInterval = setTimeout(() => {
      if (address !== "") {
        checkValidateAddress();
      }
    }, 1000);
    return () => {
      clearTimeout(debounceInterval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, coinId]);

  useEffect(() => {
    if (coins) {
      setActiveCoin(coins[0]); // Set the first coin as active by default
      setCoinId(coins[0]?.id); // Set the first coin's ID as the initial coinId
    }
  }, [coins]);

  useEffect(() => {
    if (address === "") {
      setError("");
      setIsAddressValid(true);
    }
  }, [address]);

  return (
    <Flex
      minH={{ lg: "406px" }}
      width="100%"
      p={{ base: "16px", lg: "24px" }}
      flexDir="column"
      gap={{ base: "16px", lg: "40px" }}
      justify="space-between"
      borderRadius={{ base: "16px", lg: "24px" }}
      border="1px solid"
      borderColor="extsy.neutral900"
      bg="extsy.neutral1000"
    >
      <Flex gap={{ base: "16px", lg: "40px" }} flexDir="column">
        <CustomText
          text="AML Check"
          size={{ base: "18px", lg: "24px" }}
          weight={{ base: "600", lg: "700" }}
          family="Metropolis"
          color="extsy.baseWhite"
        />
        <CustomText text="By using the AML service you can check suspicious wallets for illegal activity. This will help you protect your money from scammers." />
        <Flex
          p="24px"
          bg="extsy.neutral900"
          borderRadius="16px"
          gap={{ base: "16px", lg: "32px" }}
          flexDir={{
            base: "column",
            lg: "row",
          }}
        >
          <Flex flexDir="column" gap="10px" width="100%" position={"relative"}>
            <CustomText text="Enter Address" size="12px" color="extsy.white" />
            <Flex
              pos={"absolute"}
              display={!isAddressValid ? "flex" : "none"}
              borderRadius={{ base: "4px", md: "8px" }}
              top={0}
              mt={"-30px"}
              left={0}
              align={"center"}
              justify={"center"}
              bg={"#b39225"}
              px={2}
              py={1}
              gap={2}
            >
              <IconAlertTriangleFilled size="12px" />
              <CustomText text={error} size="12px" color="extsy.white" />
            </Flex>

            <Input
              bg="transparent"
              pl="0"
              width="100%"
              height="30px"
              fontSize="18px"
              fontFamily="Metropolis"
              color="extsy.white"
              border="none"
              borderRadius="0px"
              borderBottom="1px solid"
              borderColor={!isAddressValid ? "extsy.danger" : "extsy.white"}
              _hover={{
                borderColor: "extsy.white",
              }}
              _focus={{
                borderColor: "extsy.white",
              }}
              _focusVisible={{
                borderColor: "extsy.white",
              }}
              value={address}
              onChange={(e: any) => setAddress(e.target.value)}
            />
          </Flex>
          <Box pos="relative">
            <Flex
              justify="space-between"
              gap="12px"
              align="center"
              height="44px"
              width={{ lg: "170px" }}
              cursor="pointer"
              px="8px"
              borderRadius="8px"
              border="1px solid"
              borderColor="extsy.neutral800"
              onClick={() => setIsCoinOpen(!isCoinOpen)}
            >
              <Flex gap="12px" align="center">
                {isLoading ? (
                  <>
                    <SkeletonCircle boxSize="28px" />
                    <Skeleton height="20px" width="50px" />
                  </>
                ) : (
                  <>
                    <Image
                      src={activeCoin?.logo_url}
                      alt="btc"
                      boxSize="28px"
                    />
                    <CustomText
                      text={activeCoin?.symbol}
                      weight="600"
                      color="extsy.baseWhite"
                      cursor
                    />
                  </>
                )}
              </Flex>
              {isLoading ? (
                <SkeletonCircle boxSize="24px" />
              ) : (
                <Image src={ArrowDown} alt="arrow" boxSize="24px" />
              )}
            </Flex>
            <OutsideClickHandler
              isOpen={isCoinOpen}
              onClose={() => setIsCoinOpen(false)}
            >
              <Flex
                flexDir="column"
                gap="16px"
                p="16px"
                bg="#131313"
                borderRadius="16px"
                border="1px solid"
                borderColor="neutral800"
                pos="absolute"
                width={{ base: "100%", lg: "163px" }}
                top="0"
                right="0"
                mt="50px"
                zIndex="1000"
                display={isCoinOpen ? "flex" : "none"}
              >
                {coins?.map((coin: any, index: any) => (
                  <Flex
                    key={index}
                    gap="12px"
                    align="center"
                    justify="space-between"
                    cursor="pointer"
                    onClick={() => {
                      setActiveCoin(coin);
                      setCoinId(coin.id); // Update the coinId when a coin is clicked
                      setIsCoinOpen(false);
                    }}
                  >
                    <Flex gap="10px" align="center">
                      <Image
                        src={coin.logo_url}
                        alt={coin.symbol}
                        boxSize="24px"
                      />
                      <CustomText
                        text={coin.symbol}
                        color={
                          coin.symbol === activeCoin?.symbol
                            ? "extsy.baseWhite"
                            : ""
                        }
                        cursor
                      />
                    </Flex>
                    {coin.symbol === activeCoin?.symbol && (
                      <IconCheck size="16px" color="#FAFAFA" />
                    )}
                  </Flex>
                ))}
              </Flex>
            </OutsideClickHandler>
          </Box>
        </Flex>
      </Flex>
      <PrimaryButton
        title={
          user?.kyc_status !== "APPROVED" ? "Upgrade Kyc" : "Check Address"
        }
        maxW={{ md: "272px" }}
        isLoading={amlCheckMutation.isPending}
        isDisabled={
          user?.kyc_status === "APPROVED"
            ? !address ||
              !coinId ||
              amlCheckMutation?.isPending ||
              !isAddressValid
            : false
        }
        onClick={async () => {
          if (user?.kyc_status !== "APPROVED") {
            setIsVerificationModalOpen(true);
          } else {
            try {
              await amlCheckMutation.mutateAsync({
                address,
                currency: coinId,
              });
              alert.mutate({
                status: "success",
                message: `${address} is CLEAN. You can proceed with your transactions`,
              });
              setAddress("");
              setActiveCoin(coins[0]);
              setCoinId(coins[0]?.id);
            } catch (error: any) {
              alert.mutate({
                status: "danger",
                message: error?.message,
              });
            }
          }
        }}
      />
      <OnboardModal
        isOpen={isVerificationModalOpen}
        onClose={() => {
          setIsVerificationModalOpen(false);
        }}
        onSubmit={() => {
          setIsVerifySuccessOpen(true);
        }}
        headerDivider
        title={
          <>
            <CustomText
              text="Complete Verification"
              weight="600"
              size={{ base: "20px", md: "30px" }}
              isTruncated
              maxW={{ base: "86%", sm: "95%" }}
              pb="16px"
              color="extsy.white"
            />
            <CustomText
              text="Start your verification through Sumsub."
              size={{ base: "14px", md: "16px" }}
            />
          </>
        }
        isFooter={false}
      >
        {/* <Center py="40px" borderRadius={"20px"} mb="24px" w={"100%"}> */}
        <SumsubWebSdk
          accessToken={sumsubToken?.token}
          expirationHandler={() => sumsubToken?.token}
          config={{
            lang: "en",
          }}
          // options={options}
          onMessage={async (data: any, payload: any) => {
            // console.log("kyc---------sumsub", data, payload?.reviewStatus);
            if (
              payload?.reviewStatus === "completed" &&
              payload?.reviewResult?.reviewAnswer === "GREEN"
            ) {
              setIsVerificationModalOpen(false);
              setIsVerifySuccessOpen(true);
            }
          }}
          onError={(data: any) => console.log("onError", data)}
        />
        {/* <Image src={SumsubImg} /> */}
        {/* </Center> */}
      </OnboardModal>
      <OnboardModal
        isOpen={isVerifySuccessOpen}
        onClose={() => {
          setIsVerifySuccessOpen(false);
          queryClient.invalidateQueries(["getLoggedInUser"]);
        }}
        onSubmit={() => {
          setIsVerifySuccessOpen(false);
          queryClient.invalidateQueries(["getLoggedInUser"]);
        }}
        headerDivider
        size="sm"
        noHeader={true}
        // btnText="Create a Community"

        isFooter={false}
      >
        <Center
          py="30px"
          px={"20px"}
          borderRadius={"20px"}
          mb="24px"
          gap={"24px"}
          w={"100%"}
          flexDirection={"column"}
        >
          <Image src={VerifySuccessImg} m="24px auto 0px" mb={"24px"} />
          <CustomText
            text="Congratulation!"
            fontFamily="Power Grotesk"
            color="extsy.white"
            size={{ base: "22px", md: "24px" }}
          />
          <CustomText
            text="Your account has been verified successfully."
            size={{ base: "14px", md: "16px" }}
          />
          <PrimaryButton
            width="182px"
            height="56px"
            onClick={() => {
              setIsVerifySuccessOpen(false);
              queryClient.invalidateQueries(["getLoggedInUser"]);
            }}
            title={"Done"}
          />
        </Center>
      </OnboardModal>
    </Flex>
  );
}

export default AMLCard;
