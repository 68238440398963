import {
  Box,
  Flex,
  Grid,
  GridItem,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import CustomText from "../text/CustomText";
import TradingViewChart from "./TradingViewChart";
import BuySell from "./BuySell";
import OpenOrders from "./OpenOrders";
import OrderHistory from "./OrderHistory";
import TradeHistory from "./TradeHistory";
import TradeStats from "./TradeStats";
import TradePairs from "./TradePairs";
import OrderBook from "./OrderBook";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  setPairSymbol,
  setTickerStats,
} from "../../utils/store/slices/spot.slice";
import { socket } from "../../utils/sockets";

function Spot() {
  const TABS = [
    {
      title: "Open Orders",
      key: "openOrders",
      number: "3",
    },
    {
      title: "Order History",
      key: "orderHistory",
      number: "0",
    },
    {
      title: "Trade History",
      key: "tradeHistory",
    },
  ];

  const dispatch: any = useDispatch();

  const [searchParams] = useSearchParams();

  const [tabIndex, setTabIndex] = useState(0);

  const pairSymbol = searchParams.get("pair") || "";
  const previousPrice = useRef<any>(null);

  const handlePriceChange = async (data: any) => {
    let priceColor = "#fff";
    const currentPrice = parseFloat(data.c);
    // Check if previous price exists
    if (previousPrice.current) {
      if (currentPrice > previousPrice.current) {
        priceColor = "extsy.success";
      } else if (currentPrice < previousPrice.current) {
        priceColor = "extsy.danger";
      } else {
        priceColor = "#fff";
      }
    }

    // Update the previous price
    previousPrice.current = currentPrice;
    dispatch(
      setTickerStats({
        lastPrice: data.c, // Current price
        priceChangePercent: data.P, // 24-hour price change percentage
        volumeBTC: data.v, // 24-hour volume in BTC
        volumeUSDT: data.q, // 24-hour volume in USDT
        highPrice: data.h, // 24-hour high price
        lowPrice: data.l, // 24-hour low price
        priceColor,
      })
    );
  };

  useEffect(() => {
    dispatch(setPairSymbol(pairSymbol));
    function onConnect() {
      socket.emit("join", { event: "price", symbol: pairSymbol });
    }

    if (socket?.connected) {
      onConnect();
    }

    socket.on("connect", onConnect);
    socket.on(`price${pairSymbol}`, handlePriceChange);

    return () => {
      socket.off(`price${pairSymbol}`, handlePriceChange);
      socket.off("connect", onConnect);
    };
  }, [pairSymbol]);

  return (
    <Grid
      gap="16px"
      templateRows="repeat(1, 1fr)"
      templateColumns="repeat(12, 1fr)"
    >
      <GridItem colSpan={{ base: 12, xl: 9 }}>
        <Box ps={{ lg: "16px" }}>
          <TradeStats />
          <Flex gap="16px">
            <TradePairs />
            <Flex flexDir="column" gap="40px" w="100%">
              <TradingViewChart />
              <BuySell />
            </Flex>
          </Flex>
        </Box>
      </GridItem>
      <GridItem
        colSpan={{ base: 12, xl: 3 }}
        display={{ base: "none", lg: "flex" }}
      >
        <OrderBook />
      </GridItem>
      <GridItem colSpan={12}>
        <Tabs
          onChange={(index) => setTabIndex(index)}
          defaultIndex={0}
          borderBottomRadius="16px"
        >
          <TabList borderBottom="1px solid" borderColor="extsy.neutral900">
            {TABS?.map((tab, index) => (
              <Tab
                key={index}
                color="extsy.white"
                display="flex"
                gap="4px"
                px={{ base: "6px", sm: "16px" }}
              >
                <CustomText
                  text={tab?.title}
                  size={{
                    base: "14px",
                    lg: index === tabIndex ? "16px" : "15px",
                  }}
                  weight="400"
                  color={
                    index === tabIndex ? "extsy.white" : "extsy.neutral400"
                  }
                  cursor="pointer"
                />
                {/* {tab?.number && (
                  <CustomText
                    text={`(${tab?.number || 0})`}
                    size={{ base: "14px", lg: "16px" }}
                    weight="400"
                    color={
                      index === tabIndex ? "extsy.white" : "extsy.neutral400"
                    }
                  />
                )} */}
              </Tab>
            ))}
          </TabList>
          <TabPanels>
            <TabPanel>
              <OpenOrders />
            </TabPanel>
            <TabPanel>
              <OrderHistory />
            </TabPanel>
            <TabPanel>
              <TradeHistory />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </GridItem>
    </Grid>
  );
}

export default Spot;
