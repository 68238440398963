import { Flex, Image, SkeletonText } from "@chakra-ui/react";
import React from "react";
import BookIco from "../../assets/images/book-ico.svg";
import CustomText from "../text/CustomText";
import { formatNumber } from "../../utils/functions/formatNumber";
import { useSelector } from "react-redux";

function TradeStats() {
  const { tickerStats, pairSymbol } = useSelector((state: any) => state.spot);

  return (
    <Flex
      gap={{ base: "24px", xl: "38px" }}
      align={{ lg: "center" }}
      flexDir={{
        base: "column",
        lg: "row",
      }}
    >
      <Flex
        py="12px"
        gap={{ base: "16px", xl: "28.6px" }}
        justify={{ base: "space-between", lg: "flex-start" }}
        ps={{ lg: "8px" }}
      >
        <Flex
          flexDir="column"
          gap="4px"
          pe="29.8px"
          borderRight="1px solid"
          borderColor="#252930"
        >
          {!tickerStats ? (
            <SkeletonText w="104px" noOfLines={1} py="7px" />
          ) : (
            <CustomText text={pairSymbol} size="22.62px" color="extsy.text2" />
          )}
          <Flex gap="6px" align="center">
            <Image src={BookIco} alt="book" />
            <CustomText
              text="Price"
              underline
              color="extsy.neutral400"
              cursor="pointer"
            />
          </Flex>
        </Flex>
        <Flex flexDir="column" gap="4px">
          {!tickerStats ? (
            <>
              <SkeletonText w="60px" noOfLines={1} py="7px" />
              <SkeletonText w="60px" noOfLines={1} py="2px" />
            </>
          ) : (
            <>
              <CustomText
                text={formatNumber(tickerStats?.lastPrice, 6)}
                size="18px"
                lh="22px"
                color={tickerStats?.priceColor}
              />
              <CustomText
                text={`$${formatNumber(tickerStats?.lastPrice, 6)}`}
                size="13px"
                color="extsy.text2"
              />
            </>
          )}
        </Flex>
      </Flex>
      <Flex
        gap={{ base: "10px", lg: "16px", xl: "25px" }}
        px={{ base: "16px", xl: "0" }}
        mt="-8px"
        wrap={{ base: "wrap", xl: "nowrap" }}
      >
        <Flex flexDir="column" gap="10px">
          <CustomText
            text="24h Change"
            size="13px"
            lh="19px"
            color="extsy.neutral400"
          />
          <Flex gap="5px">
            {!tickerStats ? (
              <SkeletonText w="60px" noOfLines={1} py="5px" />
            ) : (
              <CustomText
                text={`${formatNumber(tickerStats?.priceChangePercent, 2)}%`}
                size="13px"
                color={
                  tickerStats?.priceChangePercent?.includes("-")
                    ? "extsy.danger"
                    : "extsy.success"
                }
              />
            )}
          </Flex>
        </Flex>
        <Flex flexDir="column" gap="10px">
          <CustomText
            text="24h High"
            size="13px"
            lh="19px"
            color="extsy.neutral400"
          />
          {!tickerStats ? (
            <SkeletonText w="60px" noOfLines={1} py="5px" />
          ) : (
            <CustomText
              text={formatNumber(tickerStats?.highPrice, 6)}
              size="13px"
              color="extsy.text2"
            />
          )}
        </Flex>
        <Flex flexDir="column" gap="10px">
          <CustomText
            text="24h Low"
            size="13px"
            lh="19px"
            color="extsy.neutral400"
          />
          {!tickerStats ? (
            <SkeletonText w="60px" noOfLines={1} py="5px" />
          ) : (
            <CustomText
              text={formatNumber(tickerStats?.lowPrice, 6)}
              size="13px"
              color="extsy.text2"
            />
          )}
        </Flex>
        <Flex flexDir="column" gap="10px">
          <CustomText
            text="24h Volume"
            size="13px"
            lh="19px"
            color="extsy.neutral400"
          />
          {!tickerStats ? (
            <SkeletonText w="60px" noOfLines={1} py="5px" />
          ) : (
            <CustomText
              text={formatNumber(tickerStats?.volumeBTC, 2)}
              size="13px"
              color="extsy.text2"
            />
          )}
        </Flex>
        <Flex flexDir="column" gap="10px">
          <CustomText
            text="24h Volume"
            size="13px"
            lh="19px"
            color="extsy.neutral400"
          />
          {!tickerStats ? (
            <SkeletonText w="60px" noOfLines={1} py="5px" />
          ) : (
            <CustomText
              text={formatNumber(tickerStats?.volumeUSDT, 2)}
              size="13px"
              color="extsy.text2"
            />
          )}
        </Flex>
      </Flex>
    </Flex>
  );
}

export default TradeStats;
