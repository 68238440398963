import {
  Divider,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import CloseButton from "../button/closeButton";
import PrimaryButton from "../button/PrimaryButton";

export const OnboardModal = ({
  children,
  isOpen,
  onClose,
  title,
  btnText,
  btnText2,
  size,
  btnDir,
  btnOrangeW,
  btnCancelW,
  onSubmit,
  headerDivider,
  onCloseSubmit,
  isFooter,
  noHeader,
}: any) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay backdropFilter={"blur(0.9px)"} />
      <ModalContent
        bg={"rgba(255, 255, 255, 0.07)"}
        color="extsy.baseWhite"
        border="1px solid"
        borderColor={"extsy.neutral800"}
        // h={height?height:"420px"}
        minW={{ base: "auto", lg: "600px" }}
        borderRadius="16px"
        pos="relative"
        overflow="hidden"
        mx="16px"
        backdropFilter={"blur(17px)"}
      >
        {noHeader ? (
          <></>
        ) : (
          <>
            <ModalHeader
              padding={"16px 24px"}
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              borderBottom={`1px solid `}
              borderColor={"extsy.neutral900"}
            >
              <Text
                fontSize={{ base: "16px", lg: "18px" }}
                fontWeight={"700"}
                lineHeight={"22px"}
                letterSpacing={"0.08px"}
                fontFamily={"Metropolis"}
              >
                {title}
              </Text>
            </ModalHeader>
            <ModalCloseButton
              _hover={{}}
              _active={{}}
              //   mt={{ base: "8px", md: "16px" }}
              //   py="32px"
            />
            {headerDivider && <Divider maxW="92.5%" mx="auto" />}
          </>
        )}
        <ModalBody padding="0px">{children}</ModalBody>
        <Divider maxW="92.5%" mt="16px" mx="auto" />
        {isFooter && (
          <ModalFooter
            flexDirection={btnDir ? btnDir : "row"}
            gap={"10px"}
            justifyContent={"space-between"}
            pb="32px"
          >
            {btnText2 && (
              <CloseButton
                maxW="100%"
                height="56px"
                width={btnCancelW ? btnCancelW : "100%"}
                onClick={() => {
                  if (onCloseSubmit) {
                    onClose();
                    onCloseSubmit();
                  } else {
                    onClose();
                  }
                }}
                btnText={btnText2}
              />
            )}
            {btnText && (
              <PrimaryButton
                width={btnOrangeW || "100%"}
                height="56px"
                onClick={() => {
                  if (onSubmit) {
                    onClose();
                    onSubmit();
                  } else {
                    onClose();
                  }
                }}
                btnText={btnText}
              />
            )}
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
};
